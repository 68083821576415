<section [tooltip]="measurement ? (disabled ? 'Complete the drawing' : '') : 'Add a measurement first'">
    <div class="btn-group me-2" [ngClass]="{ disabled: disabled || !measurement }" role="group" aria-label="takeoff-tools">
        <button
            *ngIf="config.canDrawShap"
            type="button"
            id="toPolygon"
            class="btn polygon"
            [disabled]="!measurement || disabled"
            [popover]="isDepthEnabled ? takeOffDepthTemplate : undefined"
            container="body"
            [outsideClick]="true"
            [ngClass]="{ active: config.shape === 'Polygon' }"
            tooltip="Polygon"
            placement="bottom"
            (click)="onChangeShape('Polygon')"></button>
        <button
            *ngIf="config.canDrawShap"
            type="button"
            id="toRectangle"
            class="btn rectangle"
            [disabled]="!measurement || disabled"
            [popover]="isDepthEnabled ? takeOffDepthTemplate : undefined"
            container="body"
            [outsideClick]="true"
            [ngClass]="{ active: config.shape === 'Rectangle' }"
            tooltip="Rectangle"
            placement="bottom"
            (click)="onChangeShape('Rectangle')"></button>
        <button
            *ngIf="config.canDrawShap"
            type="button"
            id="toEllipse"
            class="btn circle"
            [disabled]="!measurement || disabled"
            [popover]="isDepthEnabled ? takeOffDepthTemplate : undefined"
            container="body"
            [outsideClick]="true"
            [ngClass]="{ active: config.shape === 'Ellipse' }"
            tooltip="Ellipse"
            placement="bottom"
            (click)="onChangeShape('Ellipse')"></button>
        <button
            *ngIf="config.canDrawLine"
            type="button"
            id="toLine"
            class="btn line"
            [disabled]="!measurement || disabled"
            [popover]="isDepthEnabled ? takeOffDepthTemplate : undefined"
            container="body"
            [outsideClick]="true"
            [ngClass]="{ active: config.shape === 'Line' }"
            tooltip="Line"
            placement="bottom"
            (click)="onChangeShape('Line')"></button>
        <button
            *ngIf="config.canDrawPoint"
            type="button"
            id="toPoint"
            class="btn point"
            [disabled]="!measurement || disabled"
            [ngClass]="{ active: config.shape === 'Point' }"
            tooltip="Point"
            placement="bottom"
            (click)="onChangeShape('Point')"></button>
    </div>

    <div class="btn-group me-2" [ngClass]="{ disabled: disabled || !measurement }" role="group" aria-label="takeoff-tools">
        <button
            type="button"
            id="toDeduction"
            class="btn deduction"
            tooltip="Draws a deduction"
            placement="bottom"
            [ngClass]="{ active: config.negative }"
            (click)="onDeduct()"
            [disabled]="!measurement || disabled"></button>

        <bx-web-take-off-drawing-color-picker
            [selectedColor]="config.color"
            (colorChanges)="onColorChanged($event)"
            [disabled]="!measurement || disabled">
        </bx-web-take-off-drawing-color-picker>
    </div>

    <div class="btn-group" [ngClass]="{ disabled: disabled || !measurement }">
        <bx-web-take-off-pitch-dropdown
            *ngIf="config.canDrawLine"
            [degrees]="config.pitch"
            [ratio]="config.pitchRatio"
            (pitchChanged)="pitchChanged($event)"
            [disabled]="!measurement || disabled">
        </bx-web-take-off-pitch-dropdown>
    </div>
</section>

<ng-template #takeOffDepthTemplate>
    <bx-web-take-off-drawing-depth
        [shape]="config.shape"
        [unit]="config.uom.toLowerCase()"
        [depth]="config.depth"
        [depthUnit]="config.depthUnit"
        (depthChanges)="onDepthChanged($event)"
        (depthUnitChanges)="onDepthUnitChanged($event)">
    </bx-web-take-off-drawing-depth>
</ng-template>
