<div class="rfq">
    <h4 class="heading mb-3">Save your measurements</h4>
    <p>Add your information below to save this session. A link will be sent to your email.</p>

    <form class="rfq-form" [formGroup]="saveMesForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
            <label class="form-label" for="fullName">Full Name *</label>
            <input
                class="form-control"
                id="fullName"
                type="text"
                formControlName="fullName"
                required
                placeholder="Enter your full name here" />
            <div
                class="error ms-1"
                *ngIf="
                    saveMesForm.get('fullName')?.invalid &&
                    (saveMesForm.get('fullName')?.dirty || saveMesForm.get('fullName')?.touched || formSubmitted)
                ">
                <div *ngIf="saveMesForm.get('fullName')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label" for="emailAddress">Email Address*</label>
            <input
                class="form-control"
                id="emailAddress"
                type="email"
                formControlName="emailAddress"
                required
                placeholder="email@yourbusiness.com" />
            <div
                class="error ms-1"
                *ngIf="
                    saveMesForm.get('emailAddress')?.invalid &&
                    (saveMesForm.get('emailAddress')?.dirty || saveMesForm.get('emailAddress')?.touched || formSubmitted)
                ">
                <div *ngIf="saveMesForm.get('emailAddress')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
                <div *ngIf="saveMesForm.get('emailAddress')?.hasError('email')">Enter a valid email address</div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label" for="phoneNumber">Phone Number*</label>
            <input
                class="form-control"
                id="phoneNumber"
                type="text"
                formControlName="phoneNumber"
                required
                placeholder="04xx xxx xxx"
                maxlength="12" />
            <div
                class="error ms-1"
                *ngIf="
                    saveMesForm.get('phoneNumber')?.invalid &&
                    (saveMesForm.get('phoneNumber')?.dirty || saveMesForm.get('phoneNumber')?.touched || formSubmitted)
                ">
                <div *ngIf="saveMesForm.get('phoneNumber')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
                <div *ngIf="saveMesForm.get('phoneNumber')?.hasError('pattern')">Enter a valid phone number</div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label" for="postCode">{{ originator?.locationTitle }}*</label>
            <input
                class="form-control"
                id="postCode"
                type="text"
                formControlName="postCode"
                required
                maxlength="5"
                [placeholder]="originator?.locationExample" />
            <div
                class="error ms-1"
                *ngIf="
                    saveMesForm.get('postCode')?.invalid &&
                    (saveMesForm.get('postCode')?.dirty || saveMesForm.get('postCode')?.touched || formSubmitted)
                ">
                <div *ngIf="saveMesForm.get('postCode')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
                <div *ngIf="saveMesForm.get('postCode')?.hasError('pattern')">Please enter a valid {{ originator?.locationTitle }}</div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label" for="profession">Profession*</label>
            <select #profession class="form-select" id="profession" formControlName="profession">
                <option value="" hidden selected>Select your profession</option>
                <option *ngFor="let profession of professions" [ngValue]="profession">{{ profession }}</option>
            </select>
            <div
                class="error ms-1"
                *ngIf="saveMesForm.get('profession')?.invalid && (saveMesForm.get('profession')?.touched || formSubmitted)">
                Must select from available options.
            </div>
        </div>

        <div class="mb-3 form-check">
            <input class="form-check-input" type="checkbox" id="terms" formControlName="terms" />
            <label class="form-check-label" for="terms"
                >I accept the Buildxact
                <a href="https://www.buildxact.com/terms-and-conditions" title="Buildxact terms and conditions" target="_blank"
                    >Terms and Conditions
                </a>
                and I have read the
                <a href="https://www.buildxact.com/privacy-policy" title="Buildxact privacy policy" target="_blank">Privacy Policy</a>. I
                agree that I may be contacted about Buildxact products and offers and I can opt-out any time.
            </label>
            <div
                class="error"
                *ngIf="
                    saveMesForm.get('terms')?.invalid &&
                    (saveMesForm.get('terms')?.dirty || saveMesForm.get('terms')?.touched || formSubmitted)
                ">
                <div *ngIf="saveMesForm.get('terms')?.hasError('required')">Please accept terms and conditions.</div>
            </div>
        </div>

        <div class="btn-actions">
            <button id="btn-cancel-rfq" class="btn-bx-secondary-stroked me-2" type="button" (click)="onCancel()">Cancel</button>
            <button id="btn-send-rfq" class="btn-bx-primary" type="submit">Save measurements</button>
        </div>
    </form>
</div>
