<ng-container
    *ngIf="{
        states: states$ | async,
        hasPlans: hasPlans$ | async,
        supportedUnits: supportedUnits$ | async,
        selectedMeasurement: selectedMeasurement$ | async
    } as settings">
    <div *ngIf="settings.states" class="panel" [ngClass]="{ 'over-layer': viewSettings.isOverLayer }">
        <ng-container *ngIf="measurementCategories$ | async as measurementCategories">
            <!-- heading -->
            <div class="panel-heading border-light estimate-measurement-settings">
                <bx-web-estimate-measurements-settings
                    [disabled]="disabled"
                    [measurementsCount]="measurementCategories.length"
                    [maxMeasurementsLimit]="measurementLimit"
                    [utmErrorUrl]="originatorRFQ?.homeErrorUtm ?? ''"
                    (addMeasurementChanges)="onAddMeasurement()">
                </bx-web-estimate-measurements-settings>
            </div>

            <!-- measurements -->
            <ng-scrollbar [visibility]="'hover'" [ngClass]="{ partner: originatorRFQ?.hasPartner }">
                <div id="measurement-list" class="panel-body measurement-list">
                    <div
                        [class]="dragItemTypes.Category + '-container'"
                        *ngFor="let measurementCategory of measurementCategories"
                        [attr.data-index]="
                            (measurementCategory.measurements
                                ? dragItemTypes.Category + '_' + measurementCategory.id
                                : dragItemTypes.Measurement + '_null') +
                            '_' +
                            measurementCategory.order
                        ">
                        <ng-container>
                            <bx-web-estimate-measurement
                                [disabled]="disabled"
                                [estimateMeasurement]="measurementCategory"
                                [selectedMeasurement]="settings.selectedMeasurement"
                                [supportedUnits]="supportedUnits || settings.supportedUnits"
                                [hasPlans]="!!settings.hasPlans"
                                [states]="settings.states"
                                [viewSettings]="viewSettings"
                                (takeOffsChanges)="onTakeOffs($event)"
                                (updateMeasurementChanges)="onMeasurementUpdated($event)"
                                (toggleMeasurementChanges)="toggleTakeOffsForMeasurement($event)"
                                (deleteMeasurementChanges)="onDelete($event)">
                            </bx-web-estimate-measurement>
                        </ng-container>
                    </div>

                    <div class="no-measurements-container" *ngIf="measurementCategories.length === 0">
                        <span class="no-measurements-container__msg"
                            >Click on the + ADD button to add a measurement. A measurement can be an area, length, width or height.</span
                        >
                    </div>
                </div>
            </ng-scrollbar>

            <!-- help -->
            <bx-web-ui-help-tile
                [partnership]="originatorRFQ"
                [utmHomeUrl]="originatorRFQ?.homeUtm ?? ''"
                [utmSignUpUrl]="originatorRFQ?.signUpUtm ?? ''"></bx-web-ui-help-tile>
        </ng-container>
    </div>
</ng-container>
