import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EstimateMeasurementsRespones } from '../entities/estimate-measurements-respones.model';
import { EstimateMeasurement } from '@bx-web/takeoff-shared';

@Injectable({
    providedIn: 'root',
})
export class EstimateMeasurementApisService {
    private readonly ESTIMATE_MEASUREMENT_FIELDS = `
    iD_EstimateMeasurement  description         key     measurement
    expression              iD_PlanMeasurement  uOM     isDeleted
    parentId                isParent            note
    `;

    constructor(private readonly apollo: Apollo) {}

    public getEstimateMeasurements(estimateId: string) {
        const query = gql(`query($estimateId: Uuid!)
        {
          estimateMeasurements(
                estimateId: $estimateId,
            )
            {
                ${this.ESTIMATE_MEASUREMENT_FIELDS}
            }
        }`);

        return this.apollo.watchQuery<EstimateMeasurementsRespones>({
            query: query,
            variables: {
                estimateId: estimateId,
            },
            fetchPolicy: 'network-only',
        }).valueChanges;
    }

    public upsertEstimateMeasurement(estimateId: string, measurement: EstimateMeasurement) {
        const query = gql(`
            mutation ($estimateId: Uuid!, $model: CreateEstimateMeasurementModelInput!) {
                upsertEstimateMeasurement(estimateId: $estimateId, model: $model) {
                    ${this.ESTIMATE_MEASUREMENT_FIELDS}
                }
            }
        `);

        return this.apollo.mutate({
            mutation: query,
            variables: {
                estimateId: estimateId,
                model: measurement,
            },
            fetchPolicy: 'no-cache',
        });
    }

    public bulkUpsertEstimateMeasurements(estimateId: string, measurements: EstimateMeasurement[]) {
        const query = gql(`
            mutation ($estimateId: Uuid!, $models: [CreateEstimateMeasurementModelInput!]!) {
                bulkUpsertEstimateMeasurements(estimateId: $estimateId, models: $models) {
                    ${this.ESTIMATE_MEASUREMENT_FIELDS}
                }
            }
        `);

        return this.apollo.mutate({
            mutation: query,
            variables: {
                estimateId: estimateId,
                models: measurements,
            },
            fetchPolicy: 'no-cache',
        });
    }
}
