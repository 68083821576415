export var BlendMode;
(function (BlendMode) {
  BlendMode["Normal"] = "Normal";
  BlendMode["Multiply"] = "Multiply";
  BlendMode["Screen"] = "Screen";
  BlendMode["Overlay"] = "Overlay";
  BlendMode["Darken"] = "Darken";
  BlendMode["Lighten"] = "Lighten";
  BlendMode["ColorDodge"] = "ColorDodge";
  BlendMode["ColorBurn"] = "ColorBurn";
  BlendMode["HardLight"] = "HardLight";
  BlendMode["SoftLight"] = "SoftLight";
  BlendMode["Difference"] = "Difference";
  BlendMode["Exclusion"] = "Exclusion";
})(BlendMode || (BlendMode = {}));
