import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AddPlanMeasurement, AddPlanMeasurementResult, AddPlanMeasurementVariables } from './mutations/add-plan-measurement.generated';
import { Uuid } from '@bx-web/graphql';
import {
    DeletePlanMeasurements,
    DeletePlanMeasurementsResult,
    DeletePlanMeasurementsVariables,
} from './mutations/delete-plan-measurement.generated';
import {
    UpdatePlanMeasurements,
    UpdatePlanMeasurementsResult,
    UpdatePlanMeasurementsVariables,
} from './mutations/update-plan-measurement.generated';
import {
    SendTakeOffToolQuoteRequest,
    SendTakeOffToolQuoteRequestResult,
    SendTakeOffToolQuoteRequestVariables,
} from './mutations/send-rfq.generated';
import { TakeOffToolQuoteRequestInput } from '../entities/rfq-input.model';

@Injectable({
    providedIn: 'root',
})
export class TakeoffMeasurementsApiService {
    constructor(private readonly apollo: Apollo) {}

    addMeasurementforPlan(planInput: AddPlanInput | AddPlanInput[]) {
        return this.apollo.mutate<AddPlanMeasurementResult, AddPlanMeasurementVariables>({
            mutation: AddPlanMeasurement,
            variables: {
                model: planInput,
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
    }

    deleteMeasurementsforPlan(planMeasurementIds: Uuid[]) {
        return this.apollo.mutate<DeletePlanMeasurementsResult, DeletePlanMeasurementsVariables>({
            mutation: DeletePlanMeasurements,
            variables: {
                planMeasurementIds,
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
    }

    updateMeasurementsforPlan(updateModel: UpdatePlanMeasurementInput[]) {
        return this.apollo.mutate<UpdatePlanMeasurementsResult, UpdatePlanMeasurementsVariables>({
            mutation: UpdatePlanMeasurements,
            variables: {
                model: updateModel,
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
    }

    sendRfqForMeasurements(inputModel: TakeOffToolQuoteRequestInput) {
        return this.apollo.mutate<SendTakeOffToolQuoteRequestResult, SendTakeOffToolQuoteRequestVariables>({
            mutation: SendTakeOffToolQuoteRequest,
            variables: {
                input: { request: inputModel },
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
    }
}

export type AddPlanInput = {
    color?: string;
    depth: number;
    depthUnit?: string;
    description?: string;
    iD_Measurement?: Uuid;
    iD_Plan: Uuid;
    isNegative: boolean;
    measurement: number;
    measurementUnit?: string;
    pitch: number;
    points?: string;
    position?: string;
    shapeType?: string;
};

export type UpdatePlanMeasurementInput = Omit<AddPlanInput, 'iD_Plan'> & {
    iD_PlanMeasurement: Uuid;
};
