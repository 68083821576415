export interface PlanConvertJobResponse {
    jobId: string;
    status: PlanConvertJobStatus;
    result?: PlanConvertJobResult;
    error?: PlanConvertJobError;
}

export enum PlanConvertJobStatus {
    InProgress = 'InProgress',
    Completed = 'Completed',
    Error = 'Error',
}

export interface PlanConvertJobResult {
    files: PlanConvertJobFileResult[];
}

export interface PlanConvertJobFileResult {
    fileName: string;
    fileSize: number;
    url: string;
}

export interface PlanConvertJobError {
    message: string;
    code: PlanConvertJobErrorCode;
}

export enum PlanConvertJobErrorCode {
    ServiceError = 0,
    ServiceUnavailable = 1,
    ConversionTimeout = 2,
    ConversionFailed = 3,
    FileDamaged = 4,
    FilePasswordProtected = 5,
    InvalidPassword = 6,
    FormatNotSupported = 7,
    Unknown = 99,
}
