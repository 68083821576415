@if(url(); as videoUrl) {
<div
    #dragElement
    class="resizable-draggable"
    [style.width.px]="width"
    [style.height.px]="height"
    [class.active]="status === 1 || status === 2"
    [style.transform]="'translate3d(' + left + 'px,' + top + 'px,' + '0px)'"
    (mousedown)="setStatus($event, 2)"
    (window:mouseup)="setStatus($event, 0)">
    <div class="draggable-pip">
        <div class="grip-wrapper">
            <span class="grip"></span>
        </div>
        <div class="btn-expand">
            <i class="fa fa-expand fa-flip-horizontal" aria-hidden="true"></i>
        </div>
        <div class="close-container">
            <span class="close-link mb-1" (click)="close()">Close</span>
        </div>
        <iframe [src]="videoUrl" allow="autoplay" title="help-video" allowfullscreen class="video-container"></iframe>
    </div>
    <div class="resize-action" (mousedown)="setStatus($event, 1)"></div>
</div>
}
