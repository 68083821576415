import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BxPictureInPictureService } from '@bx-web/shared-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const enum Status {
    OFF = 0,
    RESIZE = 1,
    MOVE = 2,
}

type VideoLink = { link: string | null };
@UntilDestroy()
@Component({
    selector: 'bx-web-pic-in-pic',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './pic-in-pic.component.html',
    styleUrl: './pic-in-pic.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PicInPicComponent implements OnInit {
    url = signal<SafeResourceUrl | null>(null);
    @ViewChild('dragElement') dragElement?: ElementRef<HTMLDivElement>;
    width = 800;
    height = 450;
    left = 0;
    top = 0;
    mouse: { x: number; y: number } = { x: 0, y: 0 };
    status: Status = Status.OFF;
    boxPosition: { left: number; top: number } = { left: 0, top: 0 };
    mouseClick: { x: number; y: number; left: number; top: number } = { x: 0, y: 0, left: 0, top: 0 };

    constructor(public pictureService: BxPictureInPictureService, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.pictureService.videoUrl.pipe(untilDestroyed(this)).subscribe((videoLink: VideoLink) => {
            this.#setUrl(videoLink.link);
        });
    }

    #setUrl(link: string | null) {
        if (!link) this.url.set(null);
        else {
            this.url.set(this.sanitizer.bypassSecurityTrustResourceUrl(link));
            setTimeout(() => {
                this.loadVideoWindow();
            }, 1);
        }
    }

    loadVideoWindow() {
        if (!this.dragElement) return;
        const { left, top } = this.dragElement.nativeElement.getBoundingClientRect();
        this.boxPosition = { left, top };
    }

    close() {
        this.url.set(null);
        this.status = Status.OFF;
    }

    @HostListener('window:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        this.mouse = { x: event.clientX, y: event.clientY };
        if (this.status === Status.RESIZE) this.resize(event);
        else if (this.status === Status.MOVE) this.move();
    }

    isContentOutOfView(): boolean {
        const rect = this.dragElement?.nativeElement.getBoundingClientRect();
        const offset = 20,
            footerHeight = 60;

        if (!rect) {
            return false;
        }

        return (
            rect.top <= (rect.height - offset) * -1 ||
            rect.left <= (rect.width - offset) * -1 ||
            rect.left >= document.documentElement.clientWidth - offset ||
            rect.top >= document.documentElement.clientHeight - offset - footerHeight
        );
    }

    setStatus(event: MouseEvent, status: Status) {
        if (status === Status.RESIZE) event.stopPropagation();
        else if (status === Status.MOVE) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
        else this.loadVideoWindow();
        this.status = status;
    }

    resize(e: Event) {
        e.preventDefault();
        this.width = Number(this.mouse.x > this.boxPosition.left) ? this.mouse.x - this.boxPosition.left : 0;
        this.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
        if (this.width < 100 || this.height < 100) {
            this.width = 300;
            this.height = 300;
            this.status = Status.OFF;
        }
    }

    move() {
        if (!this.isContentOutOfView()) {
            this.left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
            this.top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
        } else {
            this.close();
            this.left = 0;
            this.top = 0;
        }
    }
}
