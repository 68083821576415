<section class="help-container">
    <div class="d-flex">
        <div class="d-flex flex-column text">
            <p class="mb-2">Create rich takeoffs, estimates, quotes and more with Buildxact!</p>
            <a class="link" id="bx-link" [href]="bxLink" target="_blank">What is Buildxact?</a>
        </div>
        <a id="free-trial" [href]="freeTrialLink" target="_blank" class="btn btn-free-trial">Free Trial</a>
    </div>

    @if(partnership?.hasPartner){
    <div class="help-container__partnership">
        <div class="help-container__bx-logo"></div>
        <div class="help-container__partner mt-1" [ngStyle]="{ 'background-image': 'url(' + partnership?.partnerUrl + ')' }">
            {{ partnership?.partnerText }}
        </div>
    </div>
    }
</section>
