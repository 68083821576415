<div class="take-off-drawing-info" *ngIf="measurement">
    <div class="d-flex" *ngIf="measurement.additionalMeasurements">
        <div class="additional-measurements-item">
            <label for="additional-measurement-lineal">Lineal:</label>

            <input
                type="checkbox"
                id="additional-measurement-lineal"
                name="additional-measurement-lineal"
                [(ngModel)]="measurement.additionalMeasurements.lineal.selected"
                (change)="onSelectAdditionalMeasurements()" />

            <span>{{ measurement.additionalMeasurements.lineal.total | number : '1.2-2' }}</span>
            <span>{{ measurement.additionalMeasurements.lineal.uom }}</span>
        </div>

        <div class="additional-measurements-item">
            <label>Area:</label>

            <input
                type="checkbox"
                id="additional-measurement-area"
                name="additional-measurement-area"
                [(ngModel)]="measurement.additionalMeasurements.area.selected"
                (change)="onSelectAdditionalMeasurements()" />

            <span>{{ measurement.additionalMeasurements.area.total | number : '1.2-2' }}</span>
            <span>{{ measurement.additionalMeasurements.area.uom }}</span>
        </div>

        <div class="additional-measurements-item">
            <label>Volume:</label>

            <input
                type="checkbox"
                id="additional-measurement-volume"
                name="additional-measurement-volume"
                [(ngModel)]="measurement.additionalMeasurements.volume.selected"
                (change)="onSelectAdditionalMeasurements()" />

            <span>{{ measurement.additionalMeasurements.volume.total | number : '1.2-2' }}</span>
            <span>{{ measurement.additionalMeasurements.volume.uom }}</span>
        </div>
    </div>

    <p class="text-orange canvas-setting-text">
        <strong *ngIf="!measurement.isImperial; else imperial">{{ measurement.total | number : '1.2-2' }}</strong>
        <ng-template #imperial>
            <strong>{{ measurement.total | imperial : true }}</strong>
        </ng-template>

        <span>
            {{ measurement.uom }} <span *ngIf="config.depth"> &#64; {{ config.depth }} {{ config.depthUnit }}</span></span
        >
    </p>
</div>
