import { Injectable } from '@angular/core';
// @ts-ignore TS7016
import { v4 as uuidv4 } from 'uuid';
import { IsImperial, TakeOff, TakeOffMeasurement } from '@bx-web/takeoff';
import { EstimatePlansV2StoresService, SharedTakeOffStoresService } from '@bx-web/takeoff-shared';

@Injectable({
    providedIn: 'root',
})
export class TakeOffV2MeasurementService {
    constructor(
        private readonly takeOffV2StoresService: SharedTakeOffStoresService,
        private estimatePlansV2StoresService: EstimatePlansV2StoresService
    ) {}

    public getTakeOffMeasurement(estimateMeasurement: any): TakeOffMeasurement {
        if (!estimateMeasurement) {
            // @ts-ignore TS2322
            return null;
        }

        // @ts-ignore TS2345
        const drawingsForMeasurement = this.getDrawingsForMeasurement(estimateMeasurement.id_PlanMeasurement);

        const takeOffMeasurement = this.calculateMeasurement(this.mapToTakeOffMeasurement(estimateMeasurement), drawingsForMeasurement);

        return {
            ...takeOffMeasurement,
            originalTotal: takeOffMeasurement.total,
            originalAdditionalMeasurements: takeOffMeasurement.additionalMeasurements
                ? JSON.parse(JSON.stringify(takeOffMeasurement.additionalMeasurements))
                : null,
        };
    }

    getDrawingsForMeasurement(id_PlanMeasurement: string): TakeOff.Drawing[] {
        // @ts-ignore TS7034
        let drawings: any[] = [];

        if (!id_PlanMeasurement) {
            // @ts-ignore TS7005
            return drawings;
        }
        const allPlandrawings = this.getPlanDrawings(this.estimatePlansV2StoresService.snapshotEstimatePlans, [id_PlanMeasurement]);

        allPlandrawings.forEach((planDrawings) => (drawings = [...drawings, ...planDrawings.drawings]));

        // @ts-ignore TS7005
        return drawings;
    }

    getPlanDrawings(plans: any[], measurementIds: any[] | null) {
        // @ts-ignore TS7006
        return plans.map((plan) => {
            return {
                drawings: this._getDrawings(plan, measurementIds),
            };
        });
    }

    _getDrawings(plan: any, measurementIds: any[] | null): TakeOff.Drawing[] | [] {
        const measurements = measurementIds
            ? // @ts-ignore TS7006
              plan.planmeasurements?.filter((pm) => !pm.isDeleted && measurementIds.some((id) => id === pm.iD_Measurement))
            : // @ts-ignore TS7006
              plan.planmeasurements?.filter((pm) => !pm.isDeleted);

        if (measurements) {
            // @ts-ignore TS7053
            const hScale = new TakeOff.Scale(TakeOff.ScaleUnit[plan.scaleUnit], plan.scale);
            // @ts-ignore TS7053
            const vScale = new TakeOff.Scale(TakeOff.ScaleUnit[plan.scaleUnit], plan.vScale);
            const scaleUnit = plan.scaleUnit;
            // @ts-ignore TS7006
            return measurements.map((planMeasurement) => {
                const drawing = new TakeOff.Drawing({
                    id: planMeasurement.iD_PlanMeasurement,
                    shape: planMeasurement.shapeType,
                    position: this._getPositionFromString(planMeasurement.position),
                    pitch: planMeasurement.pitch,
                    pitchRatio: planMeasurement.pitchRatio,
                    depth: planMeasurement.depth,
                    depthUnit: planMeasurement.depthUnit,
                    width: this._getPositionFromString(planMeasurement.points).x,
                    height: this._getPositionFromString(planMeasurement.points).y,
                    color: this._getColor(planMeasurement.color),
                    unit: planMeasurement.measurementUnit,
                    measurement: planMeasurement.measurement,
                    label: planMeasurement.description,
                    isNegative: planMeasurement.isNegative,
                    horizontalScale: hScale,
                    verticalScale: vScale,
                    planScaleUnit: scaleUnit,
                    // @ts-ignore TS2322
                    points: undefined,
                });
                drawing.stringToPoints(planMeasurement.points);
                return drawing;
            });
        } else return [];
    }

    private _getPositionFromString(val: string) {
        if (val && val.length > 0 && val.indexOf(',') !== -1) {
            return new TakeOff.Position(Number(val.toString().split(',')[0]), Number(val.toString().split(',')[1]));
        } else return new TakeOff.Position(0, 0);
    }

    private _getColor(val: string) {
        if (val) {
            return '#' + val.slice(-6);
        } else {
            return '#FF0000';
        }
    }
    private updateDrawingsForMeasurement(
        id_PlanMeasurement: string,
        newDrawing: TakeOff.Drawing | null,
        isRemovedDrawing = false
    ): TakeOff.Drawing[] {
        const allDrawings = this.getDrawingsForMeasurement(id_PlanMeasurement);

        if (!newDrawing) {
            return allDrawings;
        }

        const index = allDrawings.findIndex((d) => d.id === newDrawing.id);

        if (index < 0) {
            return [...allDrawings, newDrawing];
        }

        if (isRemovedDrawing) {
            allDrawings.splice(index, 1);
        } else {
            allDrawings.splice(index, 1, newDrawing);
        }

        return allDrawings;
    }

    private calculateMeasurement(
        takeOffMeasurement: TakeOffMeasurement,
        drawingsForMeasurement: TakeOff.Drawing[],
        isRemovedDrawing = false
    ): TakeOffMeasurement {
        if (!drawingsForMeasurement) {
            return takeOffMeasurement;
        }

        const isImperial = this.isImperial(takeOffMeasurement.uom);
        let total = 0,
            linealTotal = 0,
            areaTotal = 0,
            volumeTotal = 0;

        drawingsForMeasurement.forEach((drawing) => {
            total += !isImperial ? drawing.measurement : this.convertToInch(drawing.measurement);

            if (takeOffMeasurement.additionalMeasurements) {
                linealTotal += !isImperial
                    ? drawing.additionalMeasurements.lineal.total
                    : this.convertToInch(drawing.additionalMeasurements.lineal.total);

                areaTotal += !isImperial
                    ? drawing.additionalMeasurements.area.total
                    : this.convertToInch(drawing.additionalMeasurements.area.total);

                volumeTotal += !isImperial
                    ? drawing.additionalMeasurements.volume.total
                    : this.convertToInch(drawing.additionalMeasurements.volume.total);
            }
        });

        takeOffMeasurement.total = total;
        if (takeOffMeasurement.additionalMeasurements) {
            // When remove the drawing, should not re-create polyline measurement with 0 total
            if (isRemovedDrawing) {
                takeOffMeasurement.additionalMeasurements = undefined;
            } else {
                takeOffMeasurement.additionalMeasurements.lineal.total = linealTotal;
                takeOffMeasurement.additionalMeasurements.area.total = areaTotal;
                takeOffMeasurement.additionalMeasurements.volume.total = volumeTotal;
            }
        }

        return takeOffMeasurement;
    }

    public updateMeasurementForDrawing(newDrawing: TakeOff.Drawing, isRemovedDrawing = false) {
        const snapshotMeasurement = this.takeOffV2StoresService.snapshotSelectedMeasurement;
        if (!snapshotMeasurement) return;
        let newMeasurement: TakeOffMeasurement = {
            ...snapshotMeasurement,
            total: snapshotMeasurement.originalTotal,
            additionalMeasurements: snapshotMeasurement.originalAdditionalMeasurements
                ? JSON.parse(JSON.stringify(snapshotMeasurement.originalAdditionalMeasurements))
                : undefined,
        };

        const drawings = this.updateDrawingsForMeasurement(newMeasurement.measurementId, newDrawing, isRemovedDrawing);

        newMeasurement = this.calculateMeasurement(newMeasurement, drawings, isRemovedDrawing);

        this.takeOffV2StoresService.selectedMeasurement = newMeasurement;
    }

    public resetMeasurement() {
        const snapshotMeasurement = this.takeOffV2StoresService.snapshotSelectedMeasurement;
        if (!snapshotMeasurement) return;
        this.takeOffV2StoresService.selectedMeasurement = {
            ...snapshotMeasurement,
            total: 0,
            // @ts-ignore TS2322
            additionalMeasurements: undefined,
        };
    }

    //#region Help
    public findPlanMeasurementByDrawingId(plan: any, drawingId: string) {
        // @ts-ignore TS7006
        return plan.planmeasurements.find((pm) => pm.iD_PlanMeasurement === drawingId);
    }

    public findPlanMeasurementsByMeasurementId(plan: any, measurementId: string) {
        // @ts-ignore TS7006
        return plan.planmeasurements.filter((pm) => pm.iD_Measurement === measurementId);
    }

    public isImperial(uOM: string): boolean {
        return IsImperial(uOM);
    }

    private convertToInch(value: number): number {
        return Math.ceil(value * 12) / 12;
    }
    //#endregion Help

    //#region Mapping
    private mapToTakeOffMeasurement(measurement: any): TakeOffMeasurement {
        const takeOffMeasurement: TakeOffMeasurement = {
            referenceId: measurement.estimateId,
            Id: measurement.id,
            measurementId: measurement.id_PlanMeasurement || uuidv4(),
            description: measurement.description,
            // @ts-ignore TS2322
            uom: measurement.uom,
            total: 0,
            // @ts-ignore TS2345
            isImperial: this.isImperial(measurement.uom),
        };

        return takeOffMeasurement;
    }
    //#endregion Mapping
}
