import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TakeOffScalePlansV2Component } from '@bx-web/takeoff-scale';
import { PlanService } from './plan.service';
import { PlansStoreService } from './plans.store';
import { filter, first, tap } from 'rxjs/operators';
import { AppStoreService } from '../app.store';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'bx-erb-plan',
    standalone: true,
    imports: [CommonModule, TakeOffScalePlansV2Component],
    templateUrl: './plan.component.html',
    styleUrl: './plan.component.scss',
})
export class PlanComponent implements OnInit {
    #planStore = inject(PlansStoreService);
    #planService = inject(PlanService);
    #appStore = inject(AppStoreService);
    #activatedRoute = inject(ActivatedRoute);
    #router = inject(Router);

    plans$ = this.#planStore.currentBreezePlans.obs$;

    ngOnInit() {
        const estimateId = this.#appStore.currentSession.value()?.estimateID ?? '';
        if (!estimateId) throw new Error('Estimate Id is null');

        this.#planService
            .getPlansForEstimate(estimateId)
            .pipe(
                first(),
                filter((res) => !!res.data?.estimatePlans?.nodes),
                tap(() => {
                    if (this.#planStore.currentBreezePlans.value?.every((plan) => plan.HasScale)) {
                        this.navigateToMeasurements();
                    }
                })
            )
            .subscribe(({ errors }) => {
                if (errors?.length) throw new Error(errors[0]?.message ?? 'Error fetching estimate Plans');
            });
    }

    navigateToMeasurements() {
        const currentQueryParams = this.#activatedRoute.snapshot.queryParams;
        this.#router.navigate(['/measurements'], {
            queryParams: currentQueryParams,
        });
    }

    canDeactivate(nextState: string): boolean {
        return nextState.includes('/measurements') || confirm('Do you want to leave this page? Changes you made may not be saved.');
    }
}
