import { ApplicationConfig, APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { initializeThirdParty } from './app.initializer';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { graphqlProvider } from './graphql.provider';
import { HttpInterceptorService } from './http-interceptor.service';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ModalModule } from 'ngx-bootstrap/modal';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeThirdParty,
            multi: true,
            deps: [HttpClient],
        },
        graphqlProvider,
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        importProvidersFrom(ModalModule.forRoot()),
    ],
};
