import { Injectable } from '@angular/core';
import { EstimateMeasurementUpdateExpression } from './domain';
import { EstimateMeasurementStoresService, EstimateMeasurementVM } from '@bx-web/takeoff-shared';

@Injectable({
    providedIn: 'root',
})
export class EstimateMeasurementCalculatorService {
    // #region constructor
    constructor(private readonly estimateMeasurementStoresService: EstimateMeasurementStoresService) {}
    // #endregion constructor

    // #region calculate
    public recalculateForMeasurement(measurement: EstimateMeasurementVM, changed: EstimateMeasurementVM, categoryOrder: number): number {
        if (measurement.id === changed.id) {
            // @ts-ignore TS2322
            return changed.quantity;
        }

        const changedKeyReference = this.getExpressionKeyReference(categoryOrder, changed.order);
        // @ts-ignore TS2532
        if (!measurement.expression.includes(changedKeyReference)) {
            // @ts-ignore TS2322
            return measurement.quantity;
        }

        return 0;
    }

    // #endregion calculate

    // #region expression
    private expressionHeader = '[M';

    public updateExpressionForMeasurements(
        items: EstimateMeasurementVM[],
        changedKeyReferences: EstimateMeasurementUpdateExpression[]
    ): boolean {
        let hasListChanged = false;
        if (!changedKeyReferences?.length) {
            return hasListChanged;
        }

        items.forEach((item: EstimateMeasurementVM, itemIndex: number) => {
            if (item.measurements) {
                // Category
                item.measurements.forEach((measurement: EstimateMeasurementVM, index: number) => {
                    // @ts-ignore TS2345
                    const newExpression = this.updateKeyReferences(measurement.expression, changedKeyReferences);

                    if (newExpression !== measurement.expression) {
                        // @ts-ignore TS2532
                        item.measurements.splice(index, 1, { ...measurement, expression: newExpression });
                    }
                });
            } else {
                // Measurement
                // @ts-ignore TS2345
                const newExpression = this.updateKeyReferences(item.expression, changedKeyReferences);

                if (newExpression !== item.expression) {
                    hasListChanged = true;
                    items.splice(itemIndex, 1, { ...item, expression: newExpression });
                }
            }
        });
        return hasListChanged;
    }

    private updateKeyReferences(expression: string, changedKeyReferences: EstimateMeasurementUpdateExpression[]): string {
        let newExpression = '';

        while (expression) {
            const indexStart = expression.indexOf(this.expressionHeader);

            if (indexStart < 0) {
                newExpression += expression;
                expression = '';
                break;
            }

            const indexEnd = expression.indexOf(']');

            const keyRef = expression.slice(indexStart, indexEnd + 1);

            newExpression += expression.slice(0, indexStart) + (this.findChangedKeyReferences(keyRef, changedKeyReferences) || keyRef);
            expression = expression.slice(indexEnd + 1);
        }

        return newExpression;
    }

    private findChangedKeyReferences(keyRef: string, changedKeyReferences: EstimateMeasurementUpdateExpression[]): string {
        let newKeyRef = keyRef;
        changedKeyReferences.forEach((changedKeyReference) => {
            if (keyRef.includes(changedKeyReference.originKeyRef)) {
                newKeyRef = newKeyRef.replace(changedKeyReference.originKeyRef, changedKeyReference.newKeyRef);
            }
        });

        return newKeyRef;
    }

    public getExpressionKeyReference(categoryKey: number | null | undefined, key: number | null | undefined): string {
        const measurementRef = key ? `${key}]` : '';
        const categoryRef = categoryKey ? `${categoryKey}.` : '';

        return `${this.expressionHeader}${categoryRef}${measurementRef}`;
    }

    public convertMeasurementQuantityToExpression(quantity: number): string {
        return (Math.round(quantity * 100) / 100).toString();
    }
}
