import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ImperialConverterService } from '../services/imperial-converter.service';

@Directive({
    selector: 'input[bxWebNumeric]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumericInputDirective),
            multi: true,
        },
    ],
    standalone: true,
})
export class NumericInputDirective implements ControlValueAccessor, OnChanges {
    @Input() min?: number;
    @Input() max?: number;
    @Input() decimals?: number = 2;
    @Input() imperial?: boolean = false;
    @Input() formatting = true;
    @Input() imperialNullValue? = '0';

    decimalSeparator: string;
    groupSeparator: string;
    // Create new regular expression with current decimal separator.
    //let NUMBER_REGEXP = "^-?[0-9" + decimalSeparator + groupSeparator + "]*$"
    NUMBER_REGEXP = '^-?[0-9.,]*$';
    numberRegex: RegExp;
    IMPERIAL_REGEXP = '^-?[0-9\'"/ ]*$';
    imperialRegex: RegExp;
    maxInputLength = 16; // Maximum input length. Default max ECMA script.
    lastValidValue: any; // Last valid value.

    // @ts-ignore TS7008
    onChange;
    // @ts-ignore TS7008
    modelValue;
    onTouched?: () => void;

    constructor(private el: ElementRef, private renderer: Renderer2, private imperialConverter: ImperialConverterService) {
        const defaultLocale = 'en-au';
        this.decimalSeparator = getLocaleNumberSymbol(defaultLocale, NumberSymbol.Decimal);
        this.groupSeparator = getLocaleNumberSymbol(defaultLocale, NumberSymbol.Group);
        this.numberRegex = new RegExp(this.NUMBER_REGEXP);
        this.imperialRegex = new RegExp(this.IMPERIAL_REGEXP);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['min']) this.onMinChanged();
        if (changes['max']) this.onMaxChanged();
        if (changes['decimals']) this.onDecimalsChanged();
        if (changes['formatting']) this.onFormattingChanged();
        if (changes['imperial']) this.onImperialChanged();
    }

    onMinChanged() {
        if (this.imperial) return;
        if (this.min !== undefined) {
            this.lastValidValue = this.minParser(this.modelValue);
            this.renderModelValue();
        }
    }

    onMaxChanged() {
        if (this.imperial) return;
        if (this.max !== undefined) {
            this.maxInputLength = this.calculateMaxLength(this.max);
            this.lastValidValue = this.maxParser(this.modelValue);
            this.renderModelValue();
        }
    }

    onDecimalsChanged() {
        if (this.imperial) return;
        if (this.decimals !== undefined) {
            this.maxInputLength = this.calculateMaxLength(this.max);
            this.renderModelValue();
        }
    }

    onFormattingChanged() {
        if (this.imperial) return;
        this.renderModelValue();
    }

    onImperialChanged() {
        this.renderModelValue();
    }

    renderValue(value: string | undefined) {
        this.renderer.setProperty(this.el.nativeElement, 'value', value);
    }

    renderModelValue() {
        const value = this.modelValue;
        if (value !== undefined && !isNaN(value)) {
            if (this.imperial) {
                this.renderValue(this.formatViewValueImperial(value));
            } else {
                this.renderValue(this.formatPrecision(value));
            }
        }
    }

    @HostListener('focusout', ['$event']) onFocusOut() {
        this.renderModelValue();
    }

    @HostListener('focus', ['$event']) onFocus() {
        if (this.imperial) return;
        const value = this.modelValue;
        if (value !== null && value !== undefined && !isNaN(value)) {
            this.renderValue(value.toString().replace('.', this.decimalSeparator));
        }
    }

    @HostListener('input', ['$event.target.value'])
    // @ts-ignore TS7006
    onInput(value) {
        // parse in order then notify onChange
        let parsedValue = this.parseViewValue(value);
        parsedValue = this.minParser(parsedValue);
        parsedValue = this.maxParser(parsedValue);
        parsedValue = this.roundParser(parsedValue);
        this.modelValue = parsedValue;
        this.onChange(parsedValue);
    }

    writeValue(obj: any): void {
        // converts a model value to the view value
        let formattedValue = this.formatViewValue(obj);
        formattedValue = this.formatPrecisionParser(formattedValue);
        this.renderValue(formattedValue);
        this.modelValue = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
    }

    /**
     * Calculate the maximum input length in characters.
     * If no maximum the input will be limited to 16 the maximum ECMA script int.
     */
    // @ts-ignore TS7006
    calculateMaxLength(value) {
        let length = 16;
        if (value !== undefined) {
            length = Math.floor(value).toString().length;
        }
        // @ts-ignore TS2532
        if (this.decimals > 0) {
            // Add extra length for the decimals plus one for the decimal separator.
            // @ts-ignore TS2532
            length += this.decimals + 1;
        }
        // @ts-ignore TS2532
        if (this.min < 0) {
            // Add extra length for the - sign.
            length++;
        }
        return length;
    }

    // @ts-ignore TS7006
    roundParser(value) {
        if (this.imperial) return value;
        if (this.decimals === undefined || this.decimals < 0) return value;
        return this.round(value);
    }

    /**
     * Round the value to the closest decimal.
     */
    // @ts-ignore TS7006
    round(value) {
        if (value) {
            // @ts-ignore TS2345
            const d = Math.pow(10, this.decimals);
            return Math.round(value * d) / d;
        } else return value;
    }

    /**
     * Minimum value validator.
     */
    // @ts-ignore TS7006
    minParser(value) {
        if (this.imperial) return value;
        if (this.min !== undefined) {
            if (value && parseFloat(value) < this.min) {
                return this.min;
            } else {
                return value;
            }
        } else {
            return value;
        }
    }

    /**
     * Maximum value validator.
     */
    // @ts-ignore TS7006
    maxParser(value) {
        if (this.imperial) return value;
        if (this.max !== undefined) {
            if (value && parseFloat(value) > this.max) {
                return this.max;
            } else {
                return value;
            }
        } else {
            return value;
        }
    }

    // @ts-ignore TS7006
    formatPrecisionParser(value) {
        if (this.imperial) return value;
        // @ts-ignore TS2532
        if (this.decimals <= 0) return value;
        return this.formatPrecision(value);
    }

    /**
     * Format a number with the thousand group separator.
     */
    // @ts-ignore TS7006
    numberWithCommas(value) {
        if (this.formatting) {
            const parts = value.toString().split(this.decimalSeparator);
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.groupSeparator);
            return parts.join(this.decimalSeparator);
        } else {
            // No formatting applies.
            return value;
        }
    }

    /**
     * Format a value with thousand group separator and correct decimal char.
     */
    // @ts-ignore TS7006
    formatPrecision(value) {
        const v = value || '0';
        let formattedValue = parseFloat(v).toFixed(this.decimals);
        formattedValue = formattedValue.replace('.', this.decimalSeparator);
        return this.numberWithCommas(formattedValue);
    }

    /**
     * Parse the view value.
     */
    // @ts-ignore TS7006
    parseViewValue(value) {
        if (this.imperial) return this.parseViewValueImperial(value);

        if (value === undefined) {
            value = '';
        }
        value = value.toString().replace(this.decimalSeparator, '.');
        value = value.toString().replace(new RegExp(this.groupSeparator, 'g'), '');

        // Handle leading decimal point, like ".5"
        if (value.indexOf('.') === 0) {
            value = '0' + value;
        }

        // Allow "-" inputs only when min < 0
        if (value.indexOf('-') === 0) {
            // @ts-ignore TS2532
            if (this.min >= 0) {
                value = null;
                this.renderValue(this.formatViewValue(this.lastValidValue));
            }
        }

        if (value === '') {
            this.lastValidValue = '';
        } else {
            if (this.numberRegex.test(value) && value.length <= this.maxInputLength) {
                const v = parseFloat(value);
                // @ts-ignore TS2532
                if (v > this.max) {
                    this.lastValidValue = this.max;
                    // @ts-ignore TS2532
                } else if (v < this.min) {
                    this.lastValidValue = this.min;
                } else {
                    this.lastValidValue = value === '' ? null : parseFloat(value);
                }
            } else {
                // Render the last valid input in the field
                this.renderValue(this.formatViewValue(this.lastValidValue));
            }
        }

        return this.lastValidValue;
    }

    formatViewValue(value: string) {
        if (this.imperial) return this.formatViewValueImperial(value);
        return !value ? '' : '' + value;
    }

    formatViewValueImperial(value: string) {
        if (value) {
            // Format the model value.
            const imperial = this.imperialConverter.parse(value);
            return imperial.toString();
        } else return this.imperialNullValue;
    }

    // @ts-ignore TS7006
    parseViewValueImperial(value) {
        if (value === undefined) {
            value = '';
        }

        if (value === '') {
            this.lastValidValue = '';
        } else {
            if (this.imperialRegex.test(value)) {
                this.lastValidValue = value;
            } else {
                // Render the last valid input in the field
                this.renderValue(this.lastValidValue);
            }
        }

        const imperial = this.imperialConverter.parse(this.lastValidValue);
        return imperial.value();
    }
}
