import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorService } from '../error.service';

@Component({
    selector: 'bx-erb-error-banner',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './error-banner.component.html',
    styleUrl: './error-banner.component.scss',
})
export class ErrorBannerComponent {
    #errorService = inject(ErrorService);
    errorMsg$ = this.#errorService.currentErrorBannerMsg.obs$;
}
