import { LoggerService } from '@bx-web/shared-utils';
import { HttpErrorResponse, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PDFDocument } from 'pdf-lib';
import { Observable, Subject, Subscription, interval } from 'rxjs';
import { filter, switchMap, take, timeout } from 'rxjs/operators';
import { ImportFileTypes } from '../entities/import-file-types.enum';
import { PlanConvertFileFormat, PlanConvertImageFormat } from '../entities/plan-convert-format.enum';
import { PlanConvertJobError, PlanConvertJobResponse, PlanConvertJobStatus } from '../entities/plan-convert-job.models';
import { PlanConvertTrackingEvent } from '../entities/plan-convert-tracking-event';
import { PlanStorageFile } from '../entities/plan-storage-file.models';
import { EstimatePlanConvertApisService } from './estimate-plan-convert-apis.service';
import { getFileSizeInMB } from '../utils/file.functions';

export enum EstimatePlanConvertJobStatus {
    Pending = 0,
    InProgress = 1,
    Completed = 2,
    Error = 3,
}

export interface EstimatePlanConvertJobStatusEvent {
    jobId?: string | null;
    referenceId: string;
    status: EstimatePlanConvertJobStatus;
    error?: string | null;
    result?: PlanStorageFile[] | null;
    progress?: number | null;
    uploadStatus: EstimatePlanConvertJobStatus;
    uploadProgress?: number | null;
    convertStatus: EstimatePlanConvertJobStatus;
    saveStatus: EstimatePlanConvertJobStatus;
}

export class EstimatePlanConvertJob {
    public readonly status: Observable<EstimatePlanConvertJobStatusEvent>;
    private readonly statusSource = new Subject<EstimatePlanConvertJobStatusEvent>();

    // page counts are calculated async
    public readonly pageCount$: Observable<number>;
    private readonly pageCountSource = new Subject<number>();

    public readonly file: File;
    private readonly referenceId: string;
    private readonly pageLimit: number;
    private jobId: string | undefined;
    private storageFiles?: PlanStorageFile[];
    public readonly fileFormat: PlanConvertFileFormat;
    public imageFormat: PlanConvertImageFormat = PlanConvertImageFormat.png;
    public resolutionDpi = 300;
    public isEncrypted = false;
    public isValid?: boolean = undefined;
    public validationErrors: string[] = [];
    public pageCount = 0;
    public password: string | undefined;
    public pageRange?: string | undefined;

    private subscriptions: Subscription[] = [];
    private statusEventData: EstimatePlanConvertJobStatusEvent;

    private trackingEventData: PlanConvertTrackingEvent;

    constructor(
        file: File,
        referenceId: string,
        pageLimit: number,
        private api: EstimatePlanConvertApisService,
        private logger: LoggerService
    ) {
        this.status = this.statusSource.pipe(filter((v) => !!v));
        this.pageCount$ = this.pageCountSource.pipe(filter((n) => !!n));
        this.file = file;
        this.referenceId = referenceId;
        this.pageLimit = pageLimit;
        this.fileFormat = this.getFileFormat();
        this.statusEventData = {
            referenceId: this.referenceId,
            status: EstimatePlanConvertJobStatus.Pending,
            uploadStatus: EstimatePlanConvertJobStatus.Pending,
            convertStatus: EstimatePlanConvertJobStatus.Pending,
            saveStatus: EstimatePlanConvertJobStatus.Pending,
        };
        this.trackingEventData = new PlanConvertTrackingEvent(this.referenceId, this.file.size);
    }

    public getCurrentStatus() {
        return this.statusEventData;
    }

    private getFileFormat(): PlanConvertFileFormat {
        if (!this.file) throw new Error('File is null or undefined');
        if (this.file.type === ImportFileTypes.PDF) return PlanConvertFileFormat.pdf;
        const ext = this.file.name.split('.').pop();
        if (ext?.toLowerCase() === 'dwg') return PlanConvertFileFormat.dwg;
        throw new Error('File format is not supported');
    }

    public async validate() {
        this.validationErrors = [];
        this.isValid = undefined;
        this.validateFileSize();
        if (this.fileFormat === PlanConvertFileFormat.pdf) {
            await this.validatePDFFile();
        }
        this.isValid = this.validationErrors.length === 0;
    }

    private validateFileSize() {
        if (this.file.size > this.api.MAX_FILE_SIZE_BYTES) {
            this.validationErrors.push(`File cannot be larger than ${getFileSizeInMB(this.api.MAX_FILE_SIZE_BYTES)}MB`);
        }
    }

    private async validatePDFFile() {
        try {
            const data = await this.file.arrayBuffer();
            const doc = await PDFDocument.load(data, { ignoreEncryption: true }); // ignoreEncryption doesn't throw an error if it is encrypted.
            this.isEncrypted = doc.isEncrypted;
            if (doc.isEncrypted) {
                if (!this.password) {
                    this.validationErrors.push('PDF file is encrypted and requires a password');
                }
            } else {
                // appears valid, set metadata here
                this.pageCount = doc.getPageCount();
                this.pageCountSource.next(this.pageCount);
            }
        } catch (error) {
            this.validationErrors.push('File cannot be read, it may be corrupted.');
        }
    }

    private setStatus(
        type: 'upload' | 'convert' | 'save' | 'all',
        status: EstimatePlanConvertJobStatus,
        progress?: number | null,
        error?: string
    ) {
        switch (type) {
            case 'upload':
                this.statusEventData.uploadStatus = status;
                break;
            case 'convert':
                this.statusEventData.convertStatus = status;
                break;
            case 'save':
                this.statusEventData.saveStatus = status;
                break;
            case 'all':
                this.statusEventData.status = status;
                break;
        }
        if (progress !== undefined && progress !== null) this.statusEventData.progress = progress;
        this.statusEventData.error = error;
        this.statusEventData.result = this.storageFiles;
        if (status === EstimatePlanConvertJobStatus.Error) {
            this.statusEventData.status = EstimatePlanConvertJobStatus.Error;
        }
        this.statusEventData.jobId = this.jobId;
        this.statusSource.next(this.statusEventData);
    }

    /** Begins the upload and conversion process for this job */
    public start() {
        if (!this.isValid) throw new Error('Cannot start a plan convert job when the file is invalid');
        if (this.isEncrypted && !this.password) throw new Error('File is encrypted but no password provided');
        this.trackingEventData.start();
        this.trackingEventData.startUpload();
        this.setStatus('all', EstimatePlanConvertJobStatus.InProgress);
        this.setStatus('upload', EstimatePlanConvertJobStatus.InProgress, 0);

        if (!this.pageRange && this.pageLimit > 0) {
            this.pageRange = `1-${Math.min(this.pageLimit, this.pageCount)}`;
        }

        const s = this.api
            .postConvert(this.file, {
                password: this.password,
                referenceId: this.referenceId,
                planFormat: this.fileFormat,
                imageFormat: this.imageFormat,
                pageRange: this.pageRange,
            })
            .subscribe(
                (event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.onPostConvertProgress(event);
                    }
                    if (event.type === HttpEventType.Response) {
                        this.onPostConvertComplete(event);
                    }
                },
                (error) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 400 && error.error) {
                            this.setStatus('convert', EstimatePlanConvertJobStatus.Error, null, error.error);
                        } else {
                            this.setStatus(
                                'convert',
                                EstimatePlanConvertJobStatus.Error,
                                null,
                                'An error occurred trying to upload the file'
                            );
                        }
                    }
                }
            );
        this.subscriptions.push(s);
    }

    private onPostConvertProgress(event: HttpProgressEvent) {
        this.setStatus(
            'upload',
            EstimatePlanConvertJobStatus.InProgress,
            event.total ? Number((100.0 * event.loaded) / event.total?.valueOf()) / 3 : 0
        );
    }

    private onPostConvertComplete(event: HttpResponse<PlanConvertJobResponse>) {
        if (event.ok && event.body) {
            this.jobId = event.body.jobId;
            this.setStatus('upload', EstimatePlanConvertJobStatus.Completed);
            this.trackingEventData.finishUpload();
            this.waitForConvert();
        } else {
            this.setStatus('upload', EstimatePlanConvertJobStatus.Error, null, 'An error occurred uploading your file.');
            this.trackingEventData.finishUpload();
            this.onError('There was an error uploading the file', event.body);
        }
    }

    private waitForConvert() {
        this.setStatus('convert', EstimatePlanConvertJobStatus.InProgress, 60);
        this.trackingEventData.startConversion();
        this.waitForConvertUsingPolling();
    }

    private waitForConvertUsingPolling() {
        const s = interval(1000 * 10)
            .pipe(
                switchMap(() => this.api.getStatus(this.jobId as string)),
                filter((response) => {
                    return response.status === PlanConvertJobStatus.Completed || response.status === PlanConvertJobStatus.Error;
                }),
                take(1),
                timeout(1000 * 60 * 5)
            )
            .subscribe((event) => {
                if (event.status === PlanConvertJobStatus.Completed) {
                    this.onWaitForConvertCompleted();
                }
                if (event.status === PlanConvertJobStatus.Error) {
                    this.onWaitForConvertError(event.error);
                }
            });
        this.subscriptions.push(s);
    }

    private onWaitForConvertCompleted() {
        this.setStatus('convert', EstimatePlanConvertJobStatus.Completed);
        this.trackingEventData.finishConversion();
        this.save();
    }

    private onWaitForConvertError(error?: PlanConvertJobError) {
        this.setStatus('convert', EstimatePlanConvertJobStatus.Error, null, error?.message);
        this.trackingEventData.finishConversion();
        this.onError('There was an error during the conversion', error);
    }

    private save() {
        this.setStatus('save', EstimatePlanConvertJobStatus.InProgress, 80);
        this.trackingEventData.startSaving();
        const s = this.api.postUpload(this.jobId as string, this.referenceId).subscribe(
            (event) => {
                this.onSaveComplete(event);
            },
            (error) => {
                this.onSaveError(error);
            }
        );
        this.subscriptions.push(s);
    }

    private onSaveComplete(event: PlanStorageFile[]) {
        this.storageFiles = event;
        this.setStatus('save', EstimatePlanConvertJobStatus.Completed, 100);
        this.setStatus('all', EstimatePlanConvertJobStatus.Completed, 100);
        this.trackingEventData.finishSaving();
        this.deleteJob();
    }

    private onSaveError(error: Error) {
        this.setStatus('save', EstimatePlanConvertJobStatus.Error, null, error.message);
        this.trackingEventData.finishSaving();
        this.onError('There was an error saving the converted files', error.message);
    }

    private onError(message: string, error?: unknown) {
        this.logger.exception(message);
        if (error) {
            this.logger.exception(error);
        }
        this.finishTracking('failed');
        this.deleteJob();
    }

    private finishTracking(result: 'successful' | 'failed') {
        this.trackingEventData.finish();
        this.trackingEventData.result = result;
        const converter = 'ERB plan converter';
        const eventName = 'Plan Import';
        this.logger.event({ name: `${eventName} (${converter})`, properties: this.trackingEventData });
        this.logger.metric({ name: `${eventName} seconds (${converter})`, average: this.trackingEventData.totalDuration });
    }

    private deleteJob(): void {
        if (this.jobId) {
            this.api.deleteJob(this.jobId).subscribe({
                error: (error) => {
                    this.logger.exception(error);
                },
            });
        }
    }

    public dispose() {
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
        this.subscriptions = [];
    }
}

/**
 * This factory creates a new plan converter job. The job contains the file to be
 * converted and all other details. The job has a single public method start()
 * Once started, the file is uploaded, converted, and saved whilst providing
 * feedback through the observable status
 */
@Injectable({
    providedIn: 'root',
})
export class EstimatePlanConvertJobFactory {
    constructor(private api: EstimatePlanConvertApisService, private logger: LoggerService) {}

    public createJob(file: File, referenceId: string, pagesLimit: number) {
        return new EstimatePlanConvertJob(file, referenceId, pagesLimit, this.api, this.logger);
    }
}
