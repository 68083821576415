export var StandardFonts;
(function (StandardFonts) {
  StandardFonts["Courier"] = "Courier";
  StandardFonts["CourierBold"] = "Courier-Bold";
  StandardFonts["CourierOblique"] = "Courier-Oblique";
  StandardFonts["CourierBoldOblique"] = "Courier-BoldOblique";
  StandardFonts["Helvetica"] = "Helvetica";
  StandardFonts["HelveticaBold"] = "Helvetica-Bold";
  StandardFonts["HelveticaOblique"] = "Helvetica-Oblique";
  StandardFonts["HelveticaBoldOblique"] = "Helvetica-BoldOblique";
  StandardFonts["TimesRoman"] = "Times-Roman";
  StandardFonts["TimesRomanBold"] = "Times-Bold";
  StandardFonts["TimesRomanItalic"] = "Times-Italic";
  StandardFonts["TimesRomanBoldItalic"] = "Times-BoldItalic";
  StandardFonts["Symbol"] = "Symbol";
  StandardFonts["ZapfDingbats"] = "ZapfDingbats";
})(StandardFonts || (StandardFonts = {}));
