import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogParams, DialogService, SafeHtmlPipe, Originator } from '@bx-web/shared-utils';
import { TakeOffV2Service } from '../takeoff-measurements/take-off-v2.service';
import { TakeOffToolQuoteRequestInput } from '../takeoff-measurements/domain/entities/rfq-input.model';
import { first } from 'rxjs/operators';
import _ from 'lodash';
import { Uuid } from '@bx-web/graphql';

@Component({
    selector: 'bx-web-request-quote',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, SafeHtmlPipe],
    templateUrl: './request-quote.component.html',
    styleUrl: './request-quote.component.scss',
})
export class RequestQuoteComponent implements OnInit {
    action = new EventEmitter<{ action: boolean }>();
    originator?: Originator;
    sessionId?: Uuid;
    placeholderTextNotes = '';
    validationEmptyMsg = 'This field cannot be empty.';
    validationProfessionMsg = 'Must select from available options.';
    formSubmitted = false;
    professions = ['Builder - Residential', 'Builder - Commercial', 'Builder - Small reno/extensions', 'Carpenter', 'Landscaper', 'Other'];

    #fb = inject(FormBuilder);
    #bsModalRef = inject(BsModalRef);
    #dialogService = inject(DialogService);
    #takeoffService = inject(TakeOffV2Service);
    rfqForm = this.#fb.group({
        fullName: ['', Validators.required],
        emailAddress: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        postCode: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        profession: ['', [Validators.required]],
        accountNumber: [''],
        accountNumber2: [''],
        comments: [''],
        terms: [false, Validators.requiredTrue],
    });

    ngOnInit() {
        this.placeholderTextNotes = `Add your notes to ${this.originator?.originatorName} here`;
        this.updateValidators('accountNumber2', this.originator?.requireRef2 ?? false, this.originator?.refFieldRegEx2 ?? '');
        this.updateValidators('accountNumber', this.originator?.requireRef ?? false, this.originator?.refFieldRegEx ?? '');
    }

    updateValidators(controlName: string, required: boolean, pattern: string) {
        const control = this.rfqForm.get(controlName);
        if (control) {
            control.setValidators([required ? Validators.required : Validators.nullValidator, Validators.pattern(pattern ?? '^[0-9]*$')]);
        }
    }

    onSubmit() {
        this.formSubmitted = true;
        if (!this.sessionId || !this.rfqForm.valid) return;
        const reqIn: TakeOffToolQuoteRequestInput = {
            refValue: this.rfqForm.get('accountNumber')?.value ?? '',
            refValue2: this.rfqForm.get('accountNumber2')?.value ?? '',
            comments: _.escape(this.rfqForm.get('comments')?.value ?? ''),
            location: this.rfqForm.get('postCode')?.value ?? '',
            fullName: _.escape(this.rfqForm.get('fullName')?.value ?? ''),
            phone: this.rfqForm.get('phoneNumber')?.value ?? '',
            email: this.rfqForm.get('emailAddress')?.value ?? '',
            profession: this.rfqForm.get('profession')?.value ?? '',
            iD_Session: this.sessionId,
        };
        this.#takeoffService
            .sendRFQ(reqIn)
            .pipe(first())
            .subscribe((res) => {
                if (res.data?.sendTakeOffToolQuoteRequest.boolean) {
                    this.action.emit({ action: true });
                    this.showConfirmation();
                }
            });
    }

    showConfirmation() {
        const originatorName = this.originator?.originatorName;
        const title = this.originator?.hasSupplier
            ? `Your quote request has been submitted to ${originatorName}`
            : 'Your takeoff measurements have been sent';
        const text = this.originator?.hasSupplier
            ? `${originatorName} will respond to your quote request via email soon. A receipt with the quote details has also been sent to your email address.`
            : `<p>We've sent the plan measurements and any accompanying notes to your email:</p> <p>${
                  this.rfqForm.get('emailAddress')?.value
              }</p>`;
        const params: DialogParams = {
            title,
            showCancel: false,
            text,
            okText: 'Okay',
            type: 'success',
            allowHtml: true,
        };
        this.#dialogService.show(params);
        this.#bsModalRef.hide();
    }

    onCancel() {
        this.#bsModalRef.hide();
    }
}
