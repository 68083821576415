import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PageSelectionValidators {
    public static selectedPages(totalPage: number, maxPageCount: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            if (!/^[0-9,-\s]*$/.test(control.value)) {
                return { invalidRange: { value: control.value } };
            }

            let isInvalid = false,
                isMaxError = false,
                isOutofRange = false;

            control.value.split(',').forEach((pageRange: string) => {
                const ranges = pageRange.split('-');

                if (this.isInvalid(ranges)) {
                    isInvalid = true;
                    return;
                }

                if (this.isOutOfRange(ranges, totalPage)) {
                    isOutofRange = true;
                }
            });

            isMaxError = this.isTotalPagesWithinLimit(control.value, maxPageCount);

            if (isInvalid) {
                return { invalidRange: { value: control.value } };
            }

            if (isOutofRange) {
                return { outOfRange: { value: control.value } };
            }

            if (isMaxError) {
                return { maxError: { value: control.value } };
            }

            return null;
        };
    }

    private static isInvalid(pageRange: string[]): boolean {
        if (pageRange.length > 2) {
            return true;
        }

        const from = parseInt(pageRange[0] ? pageRange[0].trim() : '');
        if (isNaN(from) || !from) {
            return true;
        }

        if (pageRange.length === 1) {
            return false;
        }

        const to = parseInt(pageRange[1] ? pageRange[1].trim() : '');
        if (isNaN(to)) {
            return false;
        }

        return from > to;
    }

    private static isTotalPagesWithinLimit(pages: string, maxLimit: number): boolean {
        const pageRanges = pages.split(',');

        let totalPages = 0;

        for (const range of pageRanges) {
            const [startStr, endStr] = range.split('-');
            const start = parseInt(startStr ?? '');
            const end = endStr ? parseInt(endStr ?? '') : start;
            if (!isNaN(start) && !isNaN(end)) {
                totalPages += end - start + 1;
            } else if (!isNaN(start)) {
                totalPages++;
            }
        }
        return totalPages > maxLimit;
    }

    private static isOutOfRange(pageRange: string[], totalPage: number): boolean {
        const from = pageRange[0] ? pageRange[0].trim() : '';
        if (parseInt(from) > totalPage) {
            return true;
        }

        if (pageRange.length === 1) {
            return false;
        }

        const to = pageRange[1] ? pageRange[1].trim() : '';

        return parseInt(to) > totalPage;
    }

    public static pageRanges(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isSelectedAll = control.get('isSelectedAll')?.value;
            const selectedPages = control.get('selectedPages')?.value;
            return !isSelectedAll && !selectedPages ? { noRange: true } : null;
        };
    }
}
