import { CommonModule, DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Drawing, DrawingCanvas, DrawingCanvasEvent, DrawingMode, Scale } from '../core';
import { MatSliderChange, MatSliderModule } from '@angular/material/slider';

@Component({
    selector: 'bx-web-take-off-canvas',
    templateUrl: './take-off-canvas.component.html',
    styleUrls: ['./take-off-canvas.component.scss'],
    standalone: true,
    imports: [CommonModule, MatSliderModule],
})
export class TakeOffCanvasComponent implements OnInit, AfterViewInit, OnChanges {
    // @ts-ignore TS2564
    @ViewChild('canvasWrapper') canvasWrapper: ElementRef;

    // @ts-ignore TS2564
    drawingCanvas: DrawingCanvas;
    canvasId: string;
    // @ts-ignore TS2564
    zoomSliderValue: 0.25;

    @Input() delayActivate? = false;
    // @ts-ignore TS2564
    @Input() drawingMode: string;
    @Input() unit?: string;
    @Input() shape?: string;
    @Input() color?: string;
    @Input() showLabels = true;
    @Input() pitch?: number;
    @Input() pitchRatio?: string;
    @Input() depth?: number;
    @Input() depthUnit?: string;
    @Input() negative? = false;
    @Input() drawings?: Drawing[];
    @Input() imageUrl?: string;
    @Input() horizontalScale?: Scale;
    @Input() verticalScale?: Scale;
    @Input() planScaleUnit?: string;
    @Input() mouseScaleStep?: number = 0.015;
    @Input() label?: string;
    @Input() height?: number;
    @Input() ignoreDrawingChange? = false;
    @Input() showZoomSlider? = false;
    @Input() showZoomScaleSlider? = false;

    @Output() initialized: EventEmitter<TakeOffCanvasComponent> = new EventEmitter<TakeOffCanvasComponent>();
    @Output() drawingStart: EventEmitter<DrawingCanvasEvent> = new EventEmitter<DrawingCanvasEvent>();
    @Output() drawingContinue: EventEmitter<DrawingCanvasEvent> = new EventEmitter<DrawingCanvasEvent>();
    @Output() drawingFinish: EventEmitter<DrawingCanvasEvent> = new EventEmitter<DrawingCanvasEvent>();
    @Output() drawingLabelClick: EventEmitter<DrawingCanvasEvent> = new EventEmitter<DrawingCanvasEvent>();
    @Output() drawingRemove: EventEmitter<DrawingCanvasEvent> = new EventEmitter<DrawingCanvasEvent>();
    @Output() scaleChanged: EventEmitter<DrawingCanvasEvent> = new EventEmitter<DrawingCanvasEvent>();
    @Output() stageSizechanged: EventEmitter<DrawingCanvasEvent> = new EventEmitter<DrawingCanvasEvent>();
    @Output() activatedChanges = new EventEmitter();

    constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
        this.canvasId = 'canvas' + Math.random().toString().split('.')[1];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isChanged(changes, 'height')) this.onResize();
        if (this.isChanged(changes, 'drawingMode')) this.setDrawingMode();
        if (this.isChanged(changes, 'unit')) this.setUnit();
        if (this.isChanged(changes, 'shape')) this.setShape();
        if (this.isChanged(changes, 'color')) this.setColor();
        if (this.isChanged(changes, 'showLabels')) this.setShowLabels();
        if (this.isChanged(changes, 'pitch')) this.setPitch();
        if (this.isChanged(changes, 'pitchRatio')) this.setPitchRatio();
        if (this.isChanged(changes, 'depth')) this.setDepth();
        if (this.isChanged(changes, 'depthUnit')) this.setDepthUnit();
        if (this.isChanged(changes, 'negative')) this.setNegative();
        if (this.isChanged(changes, 'drawings')) this.setDrawings();
        if (this.isChanged(changes, 'imageUrl')) this.setImageUrl();
        if (this.isChanged(changes, 'horizontalScale')) this.setHorizontalScale();
        if (this.isChanged(changes, 'verticalScale')) this.setVerticalScale();
        if (this.isChanged(changes, 'planScaleUnit')) this.setPlanScaleUnit();
        if (this.isChanged(changes, 'mouseScaleStep')) this.setMouseScaleStep();
        if (this.isChanged(changes, 'label')) this.setLabel();
    }

    isChanged(changes: SimpleChanges, property: string) {
        // @ts-ignore TS2532
        return changes && changes[property] && changes[property].currentValue !== changes[property].previousValue;
    }

    ngAfterViewInit(): void {
        const child = this.document.createElement('div');
        child.id = this.canvasId;
        this.renderer.appendChild(this.canvasWrapper.nativeElement, child);
        if (!this.delayActivate) {
            setTimeout(() => {
                this.activate();
            }, 1000);
        }
    }

    ngOnInit(): void {
        this.initialized.emit(this);
    }

    @HostListener('window:resize')
    onResize() {
        if (this.drawingCanvas) this.drawingCanvas.setSize(this.getSize());
    }

    getSize() {
        const elementWidth = parseFloat(getComputedStyle(this.canvasWrapper.nativeElement, null).width.replace('px', ''));
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        return {
            width: elementWidth,
            height: this.height ? Number(this.height) : windowHeight - this.canvasWrapper.nativeElement.getBoundingClientRect().top,
        };
    }

    activate() {
        if (this.drawingCanvas) this.drawingCanvas.destroy();
        const size = this.getSize();
        this.drawingCanvas = new DrawingCanvas(this.canvasId, size.width, size.height);
        this.setDrawingMode();
        this.setUnit();
        this.setColor();
        this.setShape();
        this.setShowLabels();
        this.setPitch();
        this.setPitchRatio();
        this.setNegative();
        this.setDrawings();
        this.setImageUrl();
        this.setHorizontalScale();
        this.setVerticalScale();
        this.setPlanScaleUnit();
        this.setLabel();
        this.setDepth();
        this.setDepthUnit();
        this.setMouseScaleStep();

        // Plug in events
        this.drawingCanvas.on('drawingstart', (canvas, args) => {
            this.drawingStart.emit({ canvas, args });
        });

        this.drawingCanvas.on('drawingcontinue', (canvas, args) => {
            this.drawingContinue.emit({ canvas, args });
        });

        this.drawingCanvas.on('drawingfinish', (canvas, args) => {
            if (this.drawings) {
                this.drawings.push(args.drawing);
            }
            this.drawingFinish.emit({ canvas, args });
        });

        this.drawingCanvas.on('drawinglabelclick', (canvas, args) => {
            this.drawingLabelClick.emit({ canvas, args });
        });

        this.drawingCanvas.on('drawingremove', (canvas, args) => {
            if (this.drawings) {
                const index = this.drawings.indexOf(args.drawing);
                if (index !== -1) this.drawings.splice(index, 1);
            }
            this.drawingRemove.emit({ canvas, args });
        });

        this.drawingCanvas.on('scalechanged', (canvas, args) => {
            this.scaleChanged.emit({ canvas, args });
            this.zoomSliderValue = args.scale;
        });
        this.drawingCanvas.on('stageSizechanged', (canvas, args) => {
            this.stageSizechanged.emit({ canvas, args });
        });

        this.activatedChanges.emit();
    }

    removeDrawings() {
        if (this.drawingCanvas) this.drawingCanvas.removeAllDrawings();
    }

    removeDrawing(drawing: Drawing) {
        if (this.drawingCanvas) this.drawingCanvas.removeDrawing(drawing);
        if (this.drawings) {
            const i = this.drawings.indexOf(drawing);
            if (i !== -1) this.drawings.splice(i, 1);
        }
    }

    undo() {
        if (this.drawingCanvas) {
            this.drawingCanvas.undo();
        }
    }

    resizeCanvas(width: number, height: number) {
        if (this.drawingCanvas) this.drawingCanvas.setSize({ width, height });
    }

    undoLast() {
        if (this.drawingCanvas) this.drawingCanvas.undoLast();
    }

    fit() {
        if (this.drawingCanvas) this.drawingCanvas.scaleToStage();
    }

    render(drawing: Drawing) {
        if (drawing && this.drawingCanvas) this.drawingCanvas.render(drawing);
    }

    scaleIn() {
        if (this.drawingCanvas) this.drawingCanvas.scaleIn();
    }

    scaleOut() {
        if (this.drawingCanvas) this.drawingCanvas.scaleOut();
    }

    scale(val: number) {
        if (this.drawingCanvas) this.drawingCanvas.setScale(val);
    }

    private setDrawingMode() {
        if (this.drawingMode && this.drawingCanvas) {
            this.drawingCanvas.setDrawingMode(this.drawingMode);
            this.setCursor();
        }
    }

    private setUnit() {
        if (this.unit && this.drawingCanvas) {
            this.drawingCanvas.setMeasurementUnit(this.unit);
        }
    }

    private setColor() {
        if (this.color !== null && this.drawingCanvas) this.drawingCanvas.setDrawingColor(this.color);
    }

    private setShape() {
        if (this.shape !== null && this.drawingCanvas) {
            let u = this.drawingCanvas.undo();
            while (u) {
                u = this.drawingCanvas.undo();
            }
            // @ts-ignore TS2345
            this.drawingCanvas.setDrawingShape(this.shape);
        }
    }

    private setShowLabels() {
        if (this.showLabels !== null && this.drawingCanvas) this.drawingCanvas.setShowLabels(this.showLabels);
    }

    private setPitch() {
        // @ts-ignore TS2345
        if (this.pitch !== null && this.drawingCanvas) this.drawingCanvas.setPitch(this.pitch);
    }

    private setPitchRatio() {
        // pitch ratio can be null
        if (this.drawingCanvas) this.drawingCanvas.setPitchRatio(this.pitchRatio);
    }

    private setDepth() {
        // @ts-ignore TS2345
        if (this.depth !== null && this.drawingCanvas) this.drawingCanvas.setDepth(this.depth);
    }

    private setDepthUnit() {
        // @ts-ignore TS2345
        if (this.depthUnit !== null && this.drawingCanvas) this.drawingCanvas.setDepthUnit(this.depthUnit);
    }

    private setCursor() {
        if (this.drawingMode !== DrawingMode.None && this.drawingCanvas) this.drawingCanvas.setCursor('crosshair');
        else this.drawingCanvas.setCursor('default');
    }

    private setNegative() {
        // @ts-ignore TS2345
        if (this.negative !== null && this.drawingCanvas) this.drawingCanvas.setIsNegative(this.negative);
    }

    private setDrawings() {
        if (this.drawings && this.drawingCanvas && !this.ignoreDrawingChange) this.drawingCanvas.loadDrawings(this.drawings);
    }

    private setImageUrl() {
        if (this.imageUrl && this.drawingCanvas) this.drawingCanvas.setBackgroundImage(this.imageUrl);
    }

    private setHorizontalScale() {
        if (this.horizontalScale && this.drawingCanvas) this.drawingCanvas.setHorizontalScale(this.horizontalScale);
    }

    private setVerticalScale() {
        if (this.verticalScale && this.drawingCanvas) this.drawingCanvas.setVerticalScale(this.verticalScale);
    }

    private setPlanScaleUnit() {
        // @ts-ignore TS2345
        if (this.planScaleUnit !== null && this.drawingCanvas) this.drawingCanvas.setPlanScaleUnit(this.planScaleUnit);
    }

    private setMouseScaleStep() {
        // @ts-ignore TS2345
        if (this.mouseScaleStep !== null && this.drawingCanvas) this.drawingCanvas.setMouseScaleStep(this.mouseScaleStep);
    }

    private setLabel() {
        // @ts-ignore TS2345
        if (this.label !== null && this.drawingCanvas) this.drawingCanvas.setLabel(this.label);
    }

    onZoomSliderChange($event: MatSliderChange) {
        // @ts-ignore TS2345
        if (this.drawingCanvas) this.drawingCanvas.setScale($event.value);
    }

    onZoomScaleSliderChange($event: MatSliderChange) {
        // @ts-ignore TS2322
        this.mouseScaleStep = $event.value;
        this.setMouseScaleStep();
    }
}
