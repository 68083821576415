@if(($currentSession | async); as session) { @if(session.originator?.videoUrl; as url) {
<div class="takeoff-video-link">
    <a id="watch-video-link" (click)="showVideo(url)">
        To learn more, click here to watch the video
        <div class="player-icon ms-2">
            <span>Play</span>
        </div>
    </a>
</div>
}
<div class="takeoff-container">
    <div class="takeoff-welcome">
        <div class="takeoff-welcome__container">
            <a [href]="linkToBx" target="_blank">
                <div class="takeoff-welcome__logo"></div>
            </a>
            @if(session.originator; as sessionOriginator){

            <section class="takeoff-welcome__header">
                <h3 class="takeoff-welcome__heading">{{ sessionOriginator.homeMainTitle }}</h3>

                <p class="takeoff-welcome__description">
                    {{ sessionOriginator.homeMainText }}
                </p>
                @if(sessionOriginator.hasPartner){
                <p class="takeoff-welcome__partner" [ngStyle]="{ 'background-image': 'url(' + sessionOriginator.partnerUrl + ')' }">
                    {{ sessionOriginator.partnerText }}
                </p>
                }
            </section>

            <section class="takeoff-welcome__steps">
                <div class="takeoff-welcome__step">
                    <div class="takeoff-welcome__step--number">1</div>
                    <h6 class="takeoff-welcome__step--heading">{{ sessionOriginator.home1Title }}</h6>
                    <p class="takeoff-welcome__step--desc">{{ sessionOriginator.home1Text }}</p>
                </div>

                <div class="takeoff-welcome__step">
                    <div class="takeoff-welcome__step--number">2</div>
                    <h6 class="takeoff-welcome__step--heading">{{ sessionOriginator.home2Title }}</h6>
                    <p class="takeoff-welcome__step--desc">{{ sessionOriginator.home2Text }}</p>
                </div>

                <div class="takeoff-welcome__step">
                    <div class="takeoff-welcome__step--number">3</div>
                    <h6 class="takeoff-welcome__step--heading">{{ sessionOriginator.home3Title }}</h6>
                    <p class="takeoff-welcome__step--desc">
                        {{ sessionOriginator.home3Text }}
                    </p>
                </div>
            </section>
            }
        </div>
        <div class="takeoff-welcome__image"></div>
    </div>
    <div class="takeoff-upload">
        <bx-web-plan-import
            [estimateId]="session.estimateID"
            [pageLimit]="session.pagesLimit"
            (importComplete)="uploadComplete()"
            (newSession)="openNewSession()"></bx-web-plan-import>
    </div>
</div>
}
