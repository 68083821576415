import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    currentErrorBannerMsg = new CurrentErrorBannerMsg();
}

class CurrentErrorBannerMsg {
    #source = new BehaviorSubject<string | null>(null);
    readonly obs$ = this.#source.asObservable();
    set(val: string | null) {
        this.#source.next(val);
    }
    value() {
        return this.#source.value;
    }
}
