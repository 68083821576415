import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { OpenTakeoffSession, OpenTakeoffSessionResult, OpenTakeoffSessionVariables } from './open-session.generated';
import { Uuid } from '@bx-web/graphql';
import { CreateTakeOffSession, CreateTakeOffSessionResult, CreateTakeOffSessionVariables } from './create-session.generated';

@Injectable({
    providedIn: 'root',
})
export class SessionApiService {
    constructor(private readonly apollo: Apollo) {}

    openSession(sessionId: Uuid) {
        return this.apollo.mutate<OpenTakeoffSessionResult, OpenTakeoffSessionVariables>({
            mutation: OpenTakeoffSession,
            variables: {
                open: {
                    sessionId,
                },
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
    }

    createSession(origin: string) {
        return this.apollo.mutate<CreateTakeOffSessionResult, CreateTakeOffSessionVariables>({
            mutation: CreateTakeOffSession,
            variables: {
                create: {
                    originator: origin, // from query params for metrics
                },
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
    }
}
