<div class="file-info-container">
    <section class="d-flex file-info" [class.error]="job.isValid === false">
        <span class="file-info-summary ml-5">{{ job.file.name }}</span>

        <button type="button" [id]="'btn-' + id" class="btn-delete" (click)="onDelete()" [disabled]="isJobInProgressOrCompleted()">
            <fa-icon [icon]="faCircleXmark"></fa-icon>
        </button>
    </section>

    <section *ngIf="jobForm && !isJobInProgressOrCompleted() && job.isEncrypted">
        <label> <span class="attention">*</span> Password:</label>
        <form [formGroup]="jobForm">
            <div class="job-form-container">
                <input type="password" class="form-control job-form-control" formControlName="password" [id]="'password-' + index" />
            </div>
        </form>
    </section>
    <section *ngIf="job.isValid === undefined">
        <div class="validation validation-info">
            <p>Checking file...</p>
        </div>
    </section>
    <section *ngIf="job.isValid === false && job.validationErrors?.length">
        <div class="validation validation-error">
            <span *ngFor="let error of job.validationErrors">{{ error }}</span>
        </div>
    </section>
    <section class="mt-4" *ngIf="jobForm && !isJobInProgressOrCompleted() && job.isValid && !job.validationErrors?.length">
        <label class="mb-1">Pages to be uploaded (max of {{ maxPageCount }} pages)</label>
        <form [formGroup]="jobForm">
            <div class="job-form-container">
                <input
                    type="text"
                    class="form-control job-form-control"
                    [class.error]="jobForm && jobForm.controls['selectedPages']?.errors"
                    placeholder="e.g. 1-5, 8 or leave empty to upload first {{ maxPageCount }} pages"
                    formControlName="selectedPages"
                    [id]="'page-selection-' + index" />
            </div>
            <div class="validation validation-error" *ngIf="jobForm && jobForm.controls['selectedPages']?.errors">
                <span *ngIf="jobForm.controls['selectedPages']?.errors?.['invalidRange']">Invalid page range, use e.g. 1-5, 8.</span>
                <span *ngIf="jobForm.controls['selectedPages']?.errors?.['outOfRange']"
                    >Range must be a number between 1 and {{ job.pageCount }}.</span
                >
                <span *ngIf="jobForm.controls['selectedPages']?.errors?.['maxError']"
                    >Maximum page limit exceeded. Please upload only up to {{ maxPageCount }} pages.</span
                >
            </div>
        </form>
    </section>
    <section class="mt-3" *ngIf="job.status | async as jobStatus">
        <div *ngIf="jobStatus.status === convertJobStatus.InProgress">
            <!-- Further work required here, progress bars etc -->
            <div class="float-end" *ngIf="jobStatus.progress">
                <p>{{ math.floor(jobStatus.progress) }} %</p>
            </div>
            <div *ngIf="jobStatus.uploadStatus === convertJobStatus.InProgress"><p>Uploading ...</p></div>
            <div *ngIf="jobStatus.convertStatus === convertJobStatus.InProgress"><p>Converting ...</p></div>
            <div *ngIf="jobStatus.saveStatus === convertJobStatus.InProgress"><p>Saving ...</p></div>

            <progressbar class="medium" [striped]="true" [animate]="true" [value]="jobStatus.progress!"></progressbar>
        </div>
        <div *ngIf="jobStatus.status === convertJobStatus.Completed"><i class="fa fa-check-circle text-success"></i> Completed</div>
        <div *ngIf="jobStatus.status === convertJobStatus.Error">
            <div class="alert alert-danger mt-3">
                <p *ngIf="jobStatus.error?.includes('You can only upload one file per session'); else genericError">
                    You already have takeoff in progress.
                    <a class="error-link" (click)="newSession.emit()">Tap here to start a new takeoff.</a>
                    You will be unable to access your previous plans and measurements.
                </p>
                <ng-template #genericError>
                    {{ jobStatus.error || 'An unknown error occurred and the file could not be imported' }}
                </ng-template>
            </div>
        </div>
    </section>
</div>
