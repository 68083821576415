import { FormsModule } from '@angular/forms';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TakeOff, TakeOffCanvasComponent, ScaleRatio, TakeOffService, IsImperial, CalculateScaleForPdfPlan } from '@bx-web/takeoff';
import { DialogService, NumericInputDirective, SelectAllOnFocusDirective } from '@bx-web/shared-utils';
import { ScalePlansInput, ScalePlansService } from './domain/scale-plans-api.service';
import { Uuid } from '@bx-web/graphql';
import { first } from 'rxjs/operators';
import { NgScrollbarModule } from 'ngx-scrollbar';

type ApplyScaleTo = 'this' | 'selected' | 'all';
type ScalePlanStep = 'drawHorizontal' | 'enterHorizontal' | 'drawVertical' | 'enterVertical';

@Component({
    selector: 'bx-web-take-off-scale-plans-v2',
    templateUrl: './scale-plans-v2.component.html',
    styleUrls: ['./scale-plans-v2.component.scss'],
    standalone: true,
    imports: [CommonModule, FormsModule, TakeOffCanvasComponent, NumericInputDirective, SelectAllOnFocusDirective, NgScrollbarModule],
})
export class TakeOffScalePlansV2Component implements AfterViewInit {
    @ViewChild('takeOffCanvas') drawingCanvas: TakeOffCanvasComponent | undefined;

    @Input() set plans(value: Plan[]) {
        this._plans = value;
        if (this.plans?.length) {
            this.selectedPlan = this.plans[0];
        }
    }
    get plans(): Plan[] {
        return this._plans;
    }

    @Input() rescale = false;
    @Input() title = '';

    @Output() scaleComplete = new EventEmitter<boolean>();
    _plans: Plan[] = [];
    @Input() set selectedPlan(value: Plan | undefined) {
        if (this._selectedPlan !== value) {
            this._selectedPlan = value;
            if (this.selectedPlan) {
                this.onSelectedPlanChanged(this.selectedPlan);
            }
        }
    }
    get selectedPlan(): Plan | undefined {
        return this._selectedPlan;
    }
    _selectedPlan?: Plan = undefined;
    manualScaleDraw = false;

    public planLabel = '';
    selectedPlans: Plan[] = [];
    horizontalDrawing?: TakeOff.Drawing;
    verticalDrawing?: TakeOff.Drawing;
    public scaleUnit: string;
    public isImperial = false;
    public horizontalLength?: number;
    public verticalLength?: number;
    public scaleRatio?: ScaleRatio | null = null;
    public scaleMode: 'manual' | 'auto' = 'manual';
    horizontalScaleValue?: number;
    verticalScaleValue?: number;
    public supportedUnits: string[];
    public scaleRatios: ScaleRatio[];

    public scaleStep: ScalePlanStep = 'drawHorizontal';
    public showTestScaleMessage = false;
    TEST_SCALE_MSG_DEFAULT = 'Draw a line on the plan below to test the common scale.';
    TEST_SCALE_MSG_NEXT = 'If you are happy with the scaling for this plan, proceed to scaling the next one.';
    TEST_SCALE_MSG_START_MEASURING = "If you are happy with the scaling, click 'Start Measuring' to proceed to takeoffs.";
    testScaleMsg = this.TEST_SCALE_MSG_DEFAULT;

    // Whether imperial or not, scale take offs are always on a scale ratio of 1:1
    // Hence, unit, horizontal and vertical scale below should always be in metres
    // which will cause the line measurement to not be converted in any way.
    public takeOffConfig: any = {
        drawingMode: 'Single',
        shape: 'Line',
        showLabels: false,
        unit: this.takeOffService.getSystem() === TakeOff.System.imperial ? TakeOff.DrawingUnit.lf : TakeOff.DrawingUnit.lm,
        horizontalScale: new TakeOff.Scale(TakeOff.ScaleUnit.m, 1),
        verticalScale: new TakeOff.Scale(TakeOff.ScaleUnit.m, 1),
        mouseScaleStep: 0.015,
    };

    allPlansScaled = false;

    constructor(
        private scalePlansService: ScalePlansService,
        private takeOffService: TakeOffService,
        private dialogService: DialogService //private estimatePlansV2Service: EstimatePlansV2Service
    ) {
        this.scaleUnit = this.takeOffService.getDefaultScaleUnit();
        this.supportedUnits = this.takeOffService.getSupportedScaleUnits();
        this.scaleRatios = this.takeOffService.getSupportedScaleRatios();
        this.isImperial = this.takeOffService.getSystem() === TakeOff.System.imperial;
        this.takeOffConfig.mouseScaleStep = 0.015;
    }

    ngAfterViewInit(): void {
        if (this.drawingCanvas) {
            this.drawingCanvas.activate();
        }
        if (this.isAllPlansScaled()) this.allPlansScaled = true;
    }

    scaleUnitChanged() {
        this.isImperial = !!this.scaleUnit && this.scaleUnit === 'ft';
    }

    drawingStart(event: TakeOff.DrawingCanvasEvent) {
        this.manualScaleDraw = true;
        // Ensure only one line at a time when testing scale
        if (!this.rescale && this.drawingCanvas?.drawings) {
            for (let i = 0; i < this.drawingCanvas.drawings.length; i++) {
                const d = this.drawingCanvas.drawings[i] as TakeOff.Drawing;
                if (d !== event.args.drawing) this.drawingCanvas.removeDrawing(d);
            }
        }
    }

    drawingFinish(event: TakeOff.DrawingCanvasEvent) {
        if (!this.rescale && this.showTestScaleMessage) {
            if (this.allPlansScaled) {
                this.testScaleMsg = this.TEST_SCALE_MSG_START_MEASURING;
            } else {
                this.testScaleMsg = this.TEST_SCALE_MSG_NEXT;
            }
        }

        if (!this.rescale) return;
        // Validate that the line measurement is correct
        if (!event.args.drawing.measurement && this.drawingCanvas) {
            this.drawingCanvas.removeDrawings();
            return;
        }
        if (this.scaleStep === 'drawHorizontal') {
            this.horizontalDrawing = event.args.drawing;
            this.changeStep('enterHorizontal');
            return;
        }
        if (this.scaleStep === 'drawVertical') {
            this.verticalDrawing = event.args.drawing;
            this.changeStep('enterVertical');
            return;
        }
    }

    changeStep(to: ScalePlanStep, apply = true) {
        this.manualScaleDraw = false;
        this.scaleStep = to;
        if (apply) this.applyStepSettings();
    }

    applyStepSettings() {
        if (this.scaleStep === 'drawHorizontal' || this.scaleStep === 'drawVertical') {
            this.horizontalLength = undefined;
            this.verticalLength = undefined;
            this.takeOffConfig.drawingMode = 'Single';
            if (this.drawingCanvas !== undefined) this.drawingCanvas.removeDrawings();
        }
        if (this.scaleStep === 'enterHorizontal' || this.scaleStep === 'enterVertical') {
            this.takeOffConfig.drawingMode = 'None';
        }
    }

    centerCanvas() {
        if (this.drawingCanvas) this.drawingCanvas.fit();
    }

    isPlanScaled(plan?: Plan) {
        return plan && plan.Scale !== 0 && plan.VScale !== 0;
    }

    isPlanSelected(plan?: Plan) {
        return plan && this.selectedPlans.some((p) => p.ID_Plan === plan.ID_Plan);
    }

    canAutoScale() {
        return !this.selectedPlans.some((p) => !p.Metadata);
    }

    setSelectedPlan(plan: Plan, event?: MouseEvent) {
        this.showTestScaleMessage = false;
        this.testScaleMsg = this.TEST_SCALE_MSG_DEFAULT;
        this.manualScaleDraw = false;
        if (this.selectedPlan && event?.shiftKey) {
            // Add or remove from selected plans
            const i = this.selectedPlans.indexOf(plan);
            if (i === -1) this.selectedPlans.push(plan);
            else this.selectedPlans.splice(i, 1);
        } else {
            this.selectedPlan = plan;
        }
    }

    onSelectedPlanChanged(plan: Plan) {
        if (this.selectedPlan) {
            this.selectedPlans = [];
            this.selectedPlans.push(plan);
            this.scaleRatio = plan.ScaleRatio ? this.scaleRatios.find((r) => r.name === plan.ScaleRatio) : null;
            this.planLabel = this.selectedPlan.PlanLabel;
            this.rescale = !this.selectedPlan.HasScale;
            this.resetScaleStep();
        }
    }

    resetScaleStep() {
        this.drawingCanvas?.removeDrawings();
        this.scaleStep = 'drawHorizontal';
        this.applyStepSettings();
        // Setup canvas
        this.takeOffConfig.showLabels = false;
        this.takeOffConfig.unit = this.isImperial ? TakeOff.DrawingUnit.lf : TakeOff.DrawingUnit.lm;
        this.takeOffConfig.horizontalScale = new TakeOff.Scale(TakeOff.ScaleUnit.m, 1);
        this.takeOffConfig.verticalScale = new TakeOff.Scale(TakeOff.ScaleUnit.m, 1);
        this.takeOffConfig.planScaleUnit = undefined;
        if (this.selectedPlan && this.isPlanScaled(this.selectedPlan) && !this.rescale) {
            this.takeOffConfig.showLabels = true;
            this.takeOffConfig.horizontalScale = new TakeOff.Scale(
                TakeOff.ScaleUnit.getUnit(this.selectedPlan.ScaleUnit),
                this.selectedPlan.Scale
            );
            this.takeOffConfig.verticalScale = new TakeOff.Scale(
                TakeOff.ScaleUnit.getUnit(this.selectedPlan.ScaleUnit),
                this.selectedPlan.VScale
            );
            this.takeOffConfig.planScaleUnit = this.selectedPlan.ScaleUnit;
            this.takeOffConfig.unit = IsImperial(this.selectedPlan.ScaleUnit) ? TakeOff.DrawingUnit.lf : TakeOff.DrawingUnit.lm;
        }
    }

    doRescale() {
        this.rescale = true;
        this.scaleRatio = null;
        this.manualScaleDraw = false;
        this.resetScaleStep();
    }

    submitHorizontalScale() {
        this.horizontalScaleValue =
            this.horizontalLength && this.horizontalDrawing ? this.horizontalLength / this.horizontalDrawing.measurement : 0;
        this.changeStep('drawVertical');
    }

    submitVerticalScale(applyTo: ApplyScaleTo) {
        this.verticalScaleValue = this.verticalLength && this.verticalDrawing ? this.verticalLength / this.verticalDrawing.measurement : 0;
        this.applyManualScaleTo(applyTo);
    }

    copyManualScaleOfSelectedPlan(applyTo: ApplyScaleTo) {
        if (this.selectedPlan) {
            this.horizontalScaleValue = this.selectedPlan.Scale;
            this.verticalScaleValue = this.selectedPlan.VScale;
            this.scaleUnit = this.selectedPlan.ScaleUnit;
            this.applyManualScaleTo(applyTo);
        }
    }

    applyManualScaleTo(applyTo: ApplyScaleTo) {
        switch (applyTo) {
            case 'this':
                if (this.selectedPlan) this.applyManualScale([this.selectedPlan]);
                break;
            case 'selected':
                this.applyManualScale(this.selectedPlans);
                break;
            case 'all':
                this.applyManualScale(this.plans);
                break;
        }
    }

    applyManualScale(plans: Plan[]) {
        plans.forEach((plan) => {
            plan.ScaleRatio = null;
            plan.Scale = this.horizontalScaleValue ?? 0;
            plan.ScaleUnit = this.scaleUnit;
            plan.VScale = this.verticalScaleValue ?? 0;
            plan.HasScale = true;
        });
        const scalePlanIn: ScalePlansInput = {
            iD_Plans: plans.map((plan) => plan.ID_Plan as Uuid),
            scale: this.horizontalScaleValue ?? 0,
            scaleUnit: this.scaleUnit ?? '',
            vScale: this.verticalScaleValue ?? 0,
            scaleRatio: '',
        };
        this.#scale(false, scalePlanIn);
    }

    #scale(isAutoScale: boolean, scalePlanIn: ScalePlansInput) {
        this.scalePlansService
            .scalePlans(scalePlanIn)
            .pipe(first())
            .subscribe((res) => {
                if (res.data) {
                    this.rescale = false;
                    this.resetScaleStep();
                    if (isAutoScale) this.showTestScaleMessage = true;
                    if (this.isAllPlansScaled()) this.allPlansScaled = true;
                }
            });
    }

    isAllPlansScaled(): boolean {
        return this._plans.every((plan) => plan.HasScale);
    }

    submitScaleRatio(applyTo?: ApplyScaleTo) {
        this.dialogService
            .show({
                title: `Set scale for ${applyTo} plan${applyTo !== 'this' ? 's' : ''} to ${this.scaleRatio?.name}`,
                text: 'IMPORTANT: Ensure that you have verified the plan scale is correct, and that your plans are not scanned or modified in any way. You accept full responsibility of verifying that the common scale is valid for your plans.',
            })
            .then(
                () => {
                    switch (applyTo) {
                        case 'this':
                            if (this.selectedPlan) this.applyScaleRatio([this.selectedPlan]);
                            break;
                        case 'selected':
                            this.applyScaleRatio(this.selectedPlans);
                            break;
                        case 'all':
                            this.applyScaleRatio(this.plans);
                            break;
                    }
                },
                () => ({})
            );
    }

    applyScaleRatio(plans: Plan[]) {
        if (plans.length === 0) return;
        plans.forEach((plan) => {
            // Do the calculation for this page
            try {
                if (plan.Metadata && this.scaleRatio) {
                    const metadata = JSON.parse(plan.Metadata) as PlanMetadata;
                    const hScale = CalculateScaleForPdfPlan(metadata.PdfWidth, metadata.ImageDpi, this.scaleRatio);
                    const vScale = CalculateScaleForPdfPlan(metadata.PdfHeight, metadata.ImageDpi, this.scaleRatio);
                    plan.Scale = hScale?.value as number;
                    plan.ScaleUnit = hScale?.unit as string;
                    plan.VScale = vScale?.value as number;
                    plan.ScaleRatio = this.scaleRatio?.name;
                    plan.HasScale = true;
                } else {
                    throw new Error('Metadata could not be read or is missing');
                }
            } catch {
                throw new Error(`Common scale could not be applied for page ${plan.PlanOrder2}.`);
                //this.logger.error(`Common scale could not be applied for page ${plan.PlanOrder2}.`);
            }
        });

        const firstPlan = plans[0];
        const scalePlanIn: ScalePlansInput = {
            iD_Plans: plans.map((plan) => plan.ID_Plan as Uuid),
            scale: firstPlan?.Scale ?? 0,
            scaleUnit: firstPlan?.ScaleUnit ?? '',
            vScale: firstPlan?.VScale ?? 0,
            scaleRatio: this.scaleRatio?.name ?? '',
        };

        this.#scale(true, scalePlanIn);
    }

    startMeasuring() {
        this.dialogService
            .show({
                title: `Confirm Proceed?`,
                text: 'IMPORTANT: Once you proceed to measurement, the scale cannot be altered. Are you confirming your satisfaction with the current scales and ready to proceed to the measurements screen?',
            })
            .then(
                () => {
                    this.scaleComplete.emit(true);
                },
                () => ({})
            );
    }

    undoDrawingAndCancel(step: ScalePlanStep) {
        this.drawingCanvas?.undo();
        this.changeStep(step);
    }
}

export interface Plan {
    ID_Plan: string;
    ID_Reference: string;
    PlanLabel: string;
    PlanDescription: string;
    PlanOrder2: number;
    Scale: number;
    ScaleUnit: string;
    HScale?: number;
    VScale: number;
    ID_PageImage?: string;
    IsDeleted: boolean;
    Metadata?: string;
    ScaleRatio?: string | null;
    HasScale: boolean;
    CreatedAt?: Date;
    Estimate?: any;
    PlanUrl?: string;
    fileUrl?: string;
}

export interface PlanMetadata {
    PageNo: number;
    PdfHeight: number;
    PdfWidth: number;
    ImageDpi: number;
}
