import { CanDeactivateFn } from '@angular/router';

export interface ComponentWithDeactivation {
    canDeactivate: (nextState?: string) => boolean | Promise<boolean>;
}

export const canDeactivateFn: CanDeactivateFn<ComponentWithDeactivation> = (
    component: ComponentWithDeactivation,
    currentRoute,
    currentState,
    nextState?
) => {
    return component.canDeactivate ? component.canDeactivate(nextState?.url ?? '') : true;
};
