import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
//import { TranslateService } from '@ngx-translate/core';
import { ImperialConverterService } from '../services/imperial-converter.service';

@Pipe({ name: 'imperial', standalone: true })
export class ImperialPipe implements PipeTransform {
    constructor(
        //@Inject('appConfig') private appConfig: any,
        private imperialConverterService: ImperialConverterService //private translateService: TranslateService
    ) {}

    public transform(value: unknown, enabled?: boolean): string | unknown {
        //TODO : add Translate service.
        //const defaultLocale = this.translateService.currentLang || this.appConfig.config.DEFAULT_LOCALE;
        const defaultLocale = 'en-au';
        const decimalSeparator = getLocaleNumberSymbol(defaultLocale, NumberSymbol.Decimal);
        const groupSeparator = getLocaleNumberSymbol(defaultLocale, NumberSymbol.Group);
        if (enabled) {
            // BX-131 - We will need to make sure the operator is cast as String if we want to run the .replace String methods
            const cleanItem = String(value || 0)
                .replace(decimalSeparator, '.')
                .replace(groupSeparator, '');

            const i = this.imperialConverterService.parse(Number(cleanItem));
            return i.toString();
        } else return value;
    }
}
