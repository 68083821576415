export class PlanConvertTrackingEvent {
    importStartedAt?: string;
    fileSize: number;
    estimateId: string;
    result?: 'successful' | 'failed';
    uploadDuration = 0;
    conversionDuration = 0;
    savingDuration = 0;
    totalDuration = 0;
    isCustomPageRangeUsed?: boolean;
    originalPagesTotal?: number;
    uploadedPagesTotal?: number;

    importStartedAtTime = 0;
    uploadStartedAtTime = 0;
    conversionStartedAtTime = 0;
    savingStartedAtTime = 0;

    constructor(estimateId: string, fileSize: number) {
        this.estimateId = estimateId;
        this.fileSize = fileSize;
    }

    public start() {
        this.importStartedAt = new Date().toUTCString();
        this.importStartedAtTime = new Date().getTime();
    }

    public startUpload() {
        this.uploadStartedAtTime = new Date().getTime();
    }

    public finishUpload() {
        this.uploadDuration = (new Date().getTime() - this.uploadStartedAtTime) / 1000; // in seconds
    }

    public startConversion() {
        this.conversionStartedAtTime = new Date().getTime();
    }

    public finishConversion() {
        this.conversionDuration = (new Date().getTime() - this.conversionStartedAtTime) / 1000; // in seconds
    }

    public startSaving() {
        this.savingStartedAtTime = new Date().getTime();
    }

    public finishSaving() {
        this.savingDuration = (new Date().getTime() - this.savingStartedAtTime) / 1000; // in seconds
    }

    public finish() {
        this.totalDuration = (new Date().getTime() - this.importStartedAtTime) / 1000; // in seconds
    }
}
