<div class="error-container">
    <div class="error">
        <h3 class="fw-bold">Sign up for a free trial of Buildxact</h3>

        <p>
            The free 14 day trial gives you access to unlimited takeoffs, supplier price files, document versioning, job management and a
            lot more.
        </p>

        <a class="link" [href]="linkToBx" target="_blank">buildxact.com</a>
    </div>
</div>
