import { EstimateMeasurementViewTypes } from './estimate-measurement-view-types.enum';

export const estimateMeasurementViewsConfig = {
    [EstimateMeasurementViewTypes.Default]: {
        isOverLayer: false,
        isExpandable: false,
        isDragable: false,
        hasEmailActions: true,
        hasPrintActions: true,
        hasCalculator: true,
    },
    [EstimateMeasurementViewTypes.OverLayer]: {
        isOverLayer: true,
        isExpandable: true,
        isDragable: true,
        hasEmailActions: false,
        hasPrintActions: false,
        hasCalculator: false,
    },
};

export const slideInTopParams = {
    'measurement-list': {
        value: '',
        params: {
            time: '300ms',
            translateY: '-34px',
        },
    },
    'measurement-item': {
        value: '',
        params: {
            time: '300ms',
            translateY: '-14px',
        },
    },
};
