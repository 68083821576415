<ng-template #takeOffColorPopoverTemplate>
    <div class="choose-color">
        <a
            *ngFor="let color of drawingColors"
            [ngStyle]="{ 'background-color': color }"
            [ngClass]="{ selected: selectedColor === color }"
            (click)="onColorChanged(color)"></a>
    </div>
</ng-template>

<button
    type="button"
    id="toChangeColour"
    class="btn"
    [popover]="takeOffColorPopoverTemplate"
    tooltip="Drawing Color"
    [outsideClick]="true"
    container="body"
    [disabled]="disabled">
    <div class="color">
        <div class="color-circle" [ngStyle]="{ 'background-color': selectedColor, 'border-color': selectedColor }"></div>
    </div>
</button>
