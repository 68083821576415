var CharCodes;
(function (CharCodes) {
  CharCodes[CharCodes["Null"] = 0] = "Null";
  CharCodes[CharCodes["Backspace"] = 8] = "Backspace";
  CharCodes[CharCodes["Tab"] = 9] = "Tab";
  CharCodes[CharCodes["Newline"] = 10] = "Newline";
  CharCodes[CharCodes["FormFeed"] = 12] = "FormFeed";
  CharCodes[CharCodes["CarriageReturn"] = 13] = "CarriageReturn";
  CharCodes[CharCodes["Space"] = 32] = "Space";
  CharCodes[CharCodes["ExclamationPoint"] = 33] = "ExclamationPoint";
  CharCodes[CharCodes["Hash"] = 35] = "Hash";
  CharCodes[CharCodes["Percent"] = 37] = "Percent";
  CharCodes[CharCodes["LeftParen"] = 40] = "LeftParen";
  CharCodes[CharCodes["RightParen"] = 41] = "RightParen";
  CharCodes[CharCodes["Plus"] = 43] = "Plus";
  CharCodes[CharCodes["Minus"] = 45] = "Minus";
  CharCodes[CharCodes["Dash"] = 45] = "Dash";
  CharCodes[CharCodes["Period"] = 46] = "Period";
  CharCodes[CharCodes["ForwardSlash"] = 47] = "ForwardSlash";
  CharCodes[CharCodes["Zero"] = 48] = "Zero";
  CharCodes[CharCodes["One"] = 49] = "One";
  CharCodes[CharCodes["Two"] = 50] = "Two";
  CharCodes[CharCodes["Three"] = 51] = "Three";
  CharCodes[CharCodes["Four"] = 52] = "Four";
  CharCodes[CharCodes["Five"] = 53] = "Five";
  CharCodes[CharCodes["Six"] = 54] = "Six";
  CharCodes[CharCodes["Seven"] = 55] = "Seven";
  CharCodes[CharCodes["Eight"] = 56] = "Eight";
  CharCodes[CharCodes["Nine"] = 57] = "Nine";
  CharCodes[CharCodes["LessThan"] = 60] = "LessThan";
  CharCodes[CharCodes["GreaterThan"] = 62] = "GreaterThan";
  CharCodes[CharCodes["A"] = 65] = "A";
  CharCodes[CharCodes["D"] = 68] = "D";
  CharCodes[CharCodes["E"] = 69] = "E";
  CharCodes[CharCodes["F"] = 70] = "F";
  CharCodes[CharCodes["O"] = 79] = "O";
  CharCodes[CharCodes["P"] = 80] = "P";
  CharCodes[CharCodes["R"] = 82] = "R";
  CharCodes[CharCodes["LeftSquareBracket"] = 91] = "LeftSquareBracket";
  CharCodes[CharCodes["BackSlash"] = 92] = "BackSlash";
  CharCodes[CharCodes["RightSquareBracket"] = 93] = "RightSquareBracket";
  CharCodes[CharCodes["a"] = 97] = "a";
  CharCodes[CharCodes["b"] = 98] = "b";
  CharCodes[CharCodes["d"] = 100] = "d";
  CharCodes[CharCodes["e"] = 101] = "e";
  CharCodes[CharCodes["f"] = 102] = "f";
  CharCodes[CharCodes["i"] = 105] = "i";
  CharCodes[CharCodes["j"] = 106] = "j";
  CharCodes[CharCodes["l"] = 108] = "l";
  CharCodes[CharCodes["m"] = 109] = "m";
  CharCodes[CharCodes["n"] = 110] = "n";
  CharCodes[CharCodes["o"] = 111] = "o";
  CharCodes[CharCodes["r"] = 114] = "r";
  CharCodes[CharCodes["s"] = 115] = "s";
  CharCodes[CharCodes["t"] = 116] = "t";
  CharCodes[CharCodes["u"] = 117] = "u";
  CharCodes[CharCodes["x"] = 120] = "x";
  CharCodes[CharCodes["LeftCurly"] = 123] = "LeftCurly";
  CharCodes[CharCodes["RightCurly"] = 125] = "RightCurly";
  CharCodes[CharCodes["Tilde"] = 126] = "Tilde";
})(CharCodes || (CharCodes = {}));
export default CharCodes;
