<div *ngIf="isDepthEnabled">
    <label>{{ title }}</label>
    <form class="d-inline-flex align-items-center" [formGroup]="depthUnitForm">
        <input id="depth-imput" class="form-control me-2" type="number" formControlName="depth" />
        <div class="input-group-addon depth-span">
            <select name="depth-select" class="form-control" required formControlName="depthUnit">
                <option *ngFor="let depthUnit of depthUnits" [value]="depthUnit">{{ depthUnit }}</option>
            </select>
        </div>
    </form>
</div>
