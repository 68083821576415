import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ComponentChanges } from '@bx-web/shared-utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { GetDefaultDepthUnit, IsCubic, IsImperial, IsSquare, IsYard, DrawingShape, DepthUnit } from '@bx-web/takeoff';

@Component({
    selector: 'bx-web-take-off-drawing-depth',
    templateUrl: './take-off-drawing-depth.component.html',
    styleUrls: ['./take-off-drawing-depth.component.scss'],
})
export class TakeOffDrawingDepthComponent implements OnChanges, OnInit {
    @Input() shape = '';

    @Input() unit = '';

    @Input() depth = 0;

    @Input() depthUnit = '';

    @Output() depthChanges = new EventEmitter<number>();
    @Output() depthUnitChanges = new EventEmitter<string>();

    public isDepthEnabled = false;

    public title = '';

    public depthUnits: string[] = [];

    public depthUnitForm!: UntypedFormGroup;

    constructor(private readonly formBuilder: UntypedFormBuilder) {}

    ngOnChanges(changes: ComponentChanges<TakeOffDrawingDepthComponent>): void {
        if (changes.shape?.currentValue !== changes.shape?.previousValue) {
            this.isDepthEnabled = this.checkIsDepthEnabled(this.unit);
        }

        if (changes.unit?.currentValue) {
            this.isDepthEnabled = this.checkIsDepthEnabled(this.unit);
            this.title = IsCubic(this.unit) ? 'Depth' : 'Height';
            this.depthUnits = IsImperial(this.unit) || IsYard(this.unit) ? [DepthUnit.ft] : [DepthUnit.mm, DepthUnit.cm, DepthUnit.m];
        }

        if (this.depthUnitForm) {
            if (
                changes.depth?.currentValue &&
                changes.depth.currentValue >= 0 &&
                this.depthUnitForm.controls['depth'] &&
                this.depthUnitForm.controls['depth']?.value !== this.depth
            ) {
                this.depthUnitForm.controls['depth'].setValue(this.depth);
            }

            if (changes.depthUnit?.currentValue && this.depthUnitForm.controls['depthUnit']?.value !== this.depthUnit) {
                //needs testing
                //  this.depthUnitForm.controls['depthUnitName'].setValue(getUnitName());
            }
        }
    }

    ngOnInit(): void {
        this.initdepthUnitForm();
    }

    private initdepthUnitForm() {
        this.depthUnitForm = this.formBuilder.group({
            depth: [this.depth, { updateOn: 'blur' }],
            depthUnit: [this.getDepthUnit()],
        });

        this.depthUnitForm.controls['depth']?.valueChanges.pipe(distinctUntilChanged()).subscribe((depth: number) => {
            this.depthChanges.emit(depth);
        });

        this.depthUnitForm.controls['depthUnit']?.valueChanges.subscribe((depthUnit: string) => {
            this.depthUnitChanges.emit(depthUnit);
        });
    }

    private checkIsDepthEnabled(unit: string): boolean {
        return IsCubic(unit) || (IsSquare(unit) && this.shape === DrawingShape.Line);
    }

    private getDepthUnit(): string {
        if (IsImperial(this.unit) || IsYard(this.unit)) {
            return DepthUnit.ft;
        }

        return this.depthUnit || GetDefaultDepthUnit(this.unit);
    }
}

@NgModule({
    declarations: [TakeOffDrawingDepthComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [TakeOffDrawingDepthComponent],
})
export class TakeOffDrawingDepthModule {}
