import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { escape } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DialogParams, DialogService, Originator } from '@bx-web/shared-utils';
import { TakeOffV2Service } from '../takeoff-measurements/take-off-v2.service';
import { Uuid } from '@bx-web/graphql';
import { TakeOffToolQuoteRequestInput } from '../takeoff-measurements/domain/entities/rfq-input.model';
import { first } from 'rxjs';

@Component({
    selector: 'bx-web-save-measurements',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
    templateUrl: './save-measurements.component.html',
    styleUrl: '../request-quote/request-quote.component.scss',
})
export class SaveMeasurementsComponent {
    originator?: Originator;
    sessionId?: Uuid;
    validationEmptyMsg = 'This field cannot be empty.';
    formSubmitted = false;
    professions = ['Builder - Residential', 'Builder - Commercial', 'Builder - Small reno/extensions', 'Carpenter', 'Landscaper', 'Other'];

    #fb = inject(FormBuilder);
    #bsModalRef = inject(BsModalRef);
    #dialogService = inject(DialogService);
    #takeoffService = inject(TakeOffV2Service);
    saveMesForm = this.#fb.group({
        fullName: ['', Validators.required],
        emailAddress: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        postCode: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        profession: ['', [Validators.required]],
        terms: [false, Validators.requiredTrue],
    });

    onSubmit() {
        this.formSubmitted = true;
        if (!this.sessionId || !this.saveMesForm.valid) return;
        const reqIn: TakeOffToolQuoteRequestInput = {
            location: this.saveMesForm.get('postCode')?.value ?? '',
            fullName: escape(this.saveMesForm.get('fullName')?.value ?? ''),
            phone: this.saveMesForm.get('phoneNumber')?.value ?? '',
            email: this.saveMesForm.get('emailAddress')?.value ?? '',
            profession: this.saveMesForm.get('profession')?.value ?? '',
            iD_Session: this.sessionId,
            refValue: undefined,
            saveToBuilder: true,
        };
        this.#takeoffService
            .sendRFQ(reqIn)
            .pipe(first())
            .subscribe((res) => {
                if (res.data?.sendTakeOffToolQuoteRequest.boolean) {
                    this.showConfirmation();
                }
            });
    }

    onCancel() {
        this.#bsModalRef.hide();
    }

    showConfirmation() {
        const title = 'Your session has been saved and sent to your email';
        const text = `<p>To access and continue with your session later, we've sent you a link to your email:</p> <p>${
            this.saveMesForm.get('emailAddress')?.value
        }</p>`;
        const params: DialogParams = {
            title,
            showCancel: false,
            text,
            okText: 'Okay',
            type: 'success',
            allowHtml: true,
        };
        this.#dialogService.show(params);
        this.#bsModalRef.hide();
    }
}
