import Konva from 'konva';
import * as math from 'mathjs';
import tinycolor from 'tinycolor2';
import { AdditionalMeasurements } from './additionalmeasurements';
import { DepthUnit, DrawingShape, DrawingUnit, ScaleUnitName, Unit } from './constants';
import {
    ConvertBetweenUnits,
    GetBaseUnit,
    GetLinealForSquare,
    IsCubic,
    IsImperial,
    IsLineal,
    IsSquare,
    IsSquareOrCubic,
} from './functions';
import { Position } from './position';
import { Scale } from './scale';
import { ScaleConverter } from './scaleconverter';
import { ShapeCalculator } from './shapecalculator';
import _ from 'lodash';

export interface DrawingConfig {
    shape: string;
    position: Position;
    pitch?: number;
    pitchRatio?: string;
    pitchRatioX?: number;
    pitchRatioY?: number;
    points: Position[];
    width?: number;
    height?: number;
    color: string;
    unit: string;
    measurement?: number;
    isNegative: boolean;
    verticalScale?: Scale;
    horizontalScale?: Scale;
    planScaleUnit?: string;
    label: string;
    id?: any;
    depth?: number;
    depthUnit?: string;
    additionalMeasurements?: AdditionalMeasurements;
    isHidden?: boolean;
}

export class Drawing implements DrawingConfig {
    shape: string;
    position: Position;
    pitch: number;
    pitchRatio: string;
    pitchRatioX: number;
    pitchRatioY: number;
    points: Position[];
    width: number;
    height: number;
    color: string;
    unit: string;
    measurement: number;
    isNegative: boolean;
    verticalScale: Scale;
    horizontalScale: Scale;
    planScaleUnit: string;
    label: string;
    id: any;
    depth: number;
    depthUnit: string;
    additionalMeasurements: AdditionalMeasurements;
    isHidden?: boolean;

    // @ts-ignore TS2564
    labelObject: Konva.Label;
    // @ts-ignore TS2564
    shapeObject: Konva.Shape;

    constructor(config: DrawingConfig) {
        // defaults
        this.shape = DrawingShape.Polygon;
        this.position = new Position();
        this.pitch = 0;
        // @ts-ignore TS2322
        this.pitchRatio = null;
        // @ts-ignore TS2322
        this.pitchRatioX = null;
        // @ts-ignore TS2322
        this.pitchRatioY = null;
        this.points = [];
        this.width = 0;
        this.height = 0;
        this.color = '#FF0000';
        this.unit = DrawingUnit.m2;
        this.measurement = 0;
        this.isNegative = false;
        this.verticalScale = new Scale();
        this.horizontalScale = new Scale();
        this.planScaleUnit = ScaleUnitName.mm;
        this.label = '';
        this.id = undefined;
        this.depth = 0;
        this.depthUnit = DepthUnit.mm;
        this.additionalMeasurements = {
            lineal: { total: 0, uom: '' },
            area: { total: 0, uom: '' },
            volume: { total: 0, uom: '' },
        };
        this.isHidden = false;

        if (config) {
            for (const prop in config) {
                if (Object.prototype.hasOwnProperty.call(this, prop)) {
                    // @ts-ignore TS7053
                    this[prop] = config[prop];
                }
            }
        }
    }

    clearPoints() {
        this.points = [];
    }

    addPoint(point: Position) {
        if (this.points == null || this.points == undefined) this.points = [point];
        else this.points.push(point);
    }

    removePoint(point: Position) {
        if (this.points == null || this.points == undefined) return;
        else {
            const index = this.points.indexOf(point);
            if (index > -1) {
                this.points.splice(index, 1);
            }
        }
    }

    get fillColor() {
        if (IsLineal(this.unit)) return 'transparent';
        else return this.isNegative ? '#000000' : this.color;
    }

    get strokeColor() {
        if (this.fillColor === 'transparent') return this.isNegative ? '#000000' : this.color;
        else
            return tinycolor(this.isNegative ? '#000000' : this.color)
                .darken(20)
                .toString();
    }

    get lineWidth() {
        if (IsLineal(this.unit)) return 3;
        else return 2;
    }

    get opacity() {
        if (IsSquareOrCubic(this.unit)) return 0.5;
        else return 0.75;
    }

    clone() {
        return new Drawing(this);
    }

    /*
     * Recalculates the area/length of the shape and updates the Measurement property.
     */
    calculateMeasurement() {
        let pixelMeasurement = 0;
        if (this.unit == null || this.horizontalScale == null || this.verticalScale == null) this.measurement = 0;
        else {
            let m = 0;
            if (this.unit === DrawingUnit.ea) {
                pixelMeasurement = this._getCountMeasurement.apply(this);
                m = pixelMeasurement; // Don't use scale converter for count measurements
            } else if (IsLineal(this.unit)) {
                pixelMeasurement = this._getLinealMeasurement.apply(this);
                m = ScaleConverter.convert(pixelMeasurement, this.horizontalScale, this.unit, this.pitch, this.planScaleUnit);
            } else if (IsSquare(this.unit)) {
                if (this.shape === DrawingShape.Line) {
                    // For line measurements, calculate as lineal then apply depth (which is height) to calculate squared
                    const lineal = GetLinealForSquare(this.unit);
                    pixelMeasurement = this._getLinealMeasurement.apply(this);
                    m = ScaleConverter.convert(pixelMeasurement, this.horizontalScale, lineal, this.pitch, this.planScaleUnit);
                    m = this._applyDepthToMeasurement(m);
                } else {
                    pixelMeasurement = this._getSquaredMeasurement.apply(this);
                    m = ScaleConverter.convert(pixelMeasurement, this.horizontalScale, this.unit, this.pitch, this.planScaleUnit);
                }
            } else if (IsCubic(this.unit)) {
                pixelMeasurement = this._getSquaredMeasurement.apply(this);
                m = ScaleConverter.convert(pixelMeasurement, this.horizontalScale, this.unit, this.pitch, this.planScaleUnit);
                m = this._applyDepthToMeasurement(m);
            }

            // rounds to the next inch for imperial measurements
            if (this.isImperial()) {
                const inches = m * 12;
                const wholeInches = Math.ceil(inches);
                m = wholeInches / 12;
            }

            this.measurement = this.isNegative ? -Math.abs(m) : Math.abs(m);
        }
    }

    /*
     * Sets up parameter, area and volume Measurements
     */

    calculateAdditionalMeasurements() {
        this.additionalMeasurements.lineal.uom = this.isImperial() ? DrawingUnit.lf : DrawingUnit.lm;
        this.additionalMeasurements.area.uom = this.isImperial() ? DrawingUnit.sf : DrawingUnit.m2;
        this.additionalMeasurements.volume.uom = this.isImperial() ? DrawingUnit.cf : DrawingUnit.m3;

        const linealPixelMeasurement = this._getLinealMeasurement.apply(this);
        const linealMeasurement = ScaleConverter.convert(
            linealPixelMeasurement,
            this.horizontalScale,
            this.additionalMeasurements.lineal.uom,
            this.pitch,
            this.planScaleUnit
        );
        this.additionalMeasurements.lineal.total = this.getMeasurement(linealMeasurement);

        const areaPixelMeasurement = this._getSquaredMeasurement.apply(this);
        const areaMeasurement = ScaleConverter.convert(
            areaPixelMeasurement,
            this.horizontalScale,
            this.additionalMeasurements.area.uom,
            this.pitch,
            this.planScaleUnit
        );
        this.additionalMeasurements.area.total = this.getMeasurement(areaMeasurement);

        const volumeMeasurement = this._applyDepthToMeasurement(areaMeasurement);
        this.additionalMeasurements.volume.total = this.getMeasurement(volumeMeasurement);
    }

    /*
     *
     */
    getMeasurement(measurement: number) {
        if (this.isImperial()) {
            const inches = measurement * 12;
            const wholeInches = Math.ceil(inches);
            measurement = wholeInches / 12;
        }
        return this.isNegative ? -Math.abs(measurement) : Math.abs(measurement);
    }

    /*
     * Converts the point data contained in the Points collection to a string
     */
    pointsToString() {
        let retVal = '';
        if (this.points) {
            for (let i = 0; i < this.points.length; i++) {
                // @ts-ignore TS2532
                retVal += this.points[i].x.toString();
                retVal += ',';
                // @ts-ignore TS2532
                retVal += this.points[i].y.toString();
                if (i < this.points.length - 1) retVal += ';';
            }
        }
        return retVal;
    }

    /*
     * Converts the provided string to point data for this shape and raises the PointDataChanged event
     */
    stringToPoints(value: string) {
        this.clearPoints();
        if (value) {
            const pointStrings = value.split(';');
            for (let i = 0; i < pointStrings.length; i++) {
                const pointString = pointStrings[i];
                // @ts-ignore TS2532
                const x = pointString.split(',')[0].trim();
                // @ts-ignore TS2532
                const y = pointString.split(',')[1].trim();
                this.addPoint(new Position(x, y));
            }
        }
    }

    _getCountMeasurement() {
        // Count measurements will always return 1, representing the instance of this shape as 1.
        return 1;
    }

    /*
     * Returns the lineal measurement of this shape in pixels
     */
    _getLinealMeasurement() {
        switch (this.shape) {
            case DrawingShape.Ellipse:
                return this._getLinealMeasurementEllipse();
            case DrawingShape.Line:
                return this._getLinealMeasurementLine();
            case DrawingShape.Polygon:
                return this._getLinealMeasurementPolygon();
            case DrawingShape.Rectangle:
                return this._getLinealMeasurementRectangle();
            default:
                return 0;
        }
    }

    _getLinealMeasurementEllipse() {
        const scaledHeight = this._scaleVerticalLengthToHorizontal(this.height);
        return ShapeCalculator.calculateCircumferenceEllipse(this.width, scaledHeight);
    }

    _getLinealMeasurementLine() {
        if (this.points.length == 0) return 0;
        const lastPoint = this.points[this.points.length - 1];
        // @ts-ignore TS2532
        const scaledY = this._scaleVerticalLengthToHorizontal(lastPoint.y);
        // @ts-ignore TS2532
        return ShapeCalculator.calculateLineDistance(new Position(0, 0), new Position(lastPoint.x, scaledY));
    }

    _getLinealMeasurementPolygon() {
        if (this.points.length == 0) return 0;
        // Need to scale all vertical points
        const scaledPoints = [];
        for (let i = 0; i < this.points.length - 1; i++) {
            const thisPoint = this.points[i];
            let nextPoint = this.points[i + 1];
            // @ts-ignore TS2345
            if (this.isVerticalPath(thisPoint, nextPoint))
                // @ts-ignore TS2532
                nextPoint = new Position(nextPoint.x, this._scaleVerticalLengthToHorizontal(nextPoint.y));
            scaledPoints.push(thisPoint);
            scaledPoints.push(nextPoint);
        }
        // @ts-ignore TS2345
        return ShapeCalculator.calculatePerimiterPolygon(scaledPoints);
    }

    _getLinealMeasurementRectangle() {
        const scaledHeight = this._scaleVerticalLengthToHorizontal(this.height);
        return ShapeCalculator.calculatePerimiterRectangle(this.width, scaledHeight);
    }

    // Returns the squared measurement of this shape in pixels
    _getSquaredMeasurement() {
        switch (this.shape) {
            case DrawingShape.Ellipse:
                return this._getSquaredMeasurementEllipse();
            case DrawingShape.Line:
                return this._getSquaredMeasurementLine();
            case DrawingShape.Polygon:
                return this._getSquaredMeasurementPolygon();
            case DrawingShape.Rectangle:
                return this._getSquaredMeasurementRectangle();
            default:
                return 0;
        }
    }

    _getSquaredMeasurementEllipse() {
        const scaledHeight = this._scaleVerticalLengthToHorizontal(this.height);
        return ShapeCalculator.calculateAreaEllipse(this.width, scaledHeight);
    }

    _getSquaredMeasurementLine() {
        if (this.points.length == 0) return 0;
        if (this.isVerticalLine()) {
            // @ts-ignore TS2532
            const scaledY = this._scaleVerticalLengthToHorizontal(this.points[0].y);
            // @ts-ignore TS2532
            return ShapeCalculator.calculateLineDistance(new Position(0, 0), new Position(this.points[0].x, scaledY));
            // @ts-ignore TS2345
        } else return ShapeCalculator.calculateLineDistance(new Position(0, 0), this.points[0]);
    }

    _getSquaredMeasurementPolygon() {
        if (this.points.length == 0) return 0;
        // Need to scale all vertical points
        const scaledPoints = [];
        for (let i = 0; i < this.points.length; i++) {
            // @ts-ignore TS2532
            const scaledPoint = new Position(this.points[i].x, this._scaleVerticalLengthToHorizontal(this.points[i].y));
            scaledPoints.push(scaledPoint);
        }
        return ShapeCalculator.calculateAreaPolygon(scaledPoints);
    }

    _getSquaredMeasurementRectangle() {
        const scaledHeight = this._scaleVerticalLengthToHorizontal(this.height);
        return ShapeCalculator.calculateAreaRectangle(this.width, scaledHeight);
    }

    // Because vertical and horizontal scales can be different, this method
    // takes a vertical length measurement, and scales it out to match
    // the same in the horizontal scale
    _scaleVerticalLengthToHorizontal(length: number) {
        const scaleDiff = this.verticalScale.value / this.horizontalScale.value;
        return length * scaleDiff;
    }

    // Multiplies the given measurement by the depth value of the drawing
    _applyDepthToMeasurement(measurement: number) {
        const depth = this.depth || 0;
        const baseUnitForDrawing = GetBaseUnit(this.unit);
        const convertedDepth = ConvertBetweenUnits(depth, this.depthUnit as Unit, baseUnitForDrawing as Unit);
        let retVal = measurement;
        retVal = measurement * convertedDepth;
        return Number(retVal.toFixed(2));
    }

    /*
     * Returns True if this shape is considered a horizontal line
     */
    isHorizontalLine() {
        if (this.shape != DrawingShape.Line) return false;
        // @ts-ignore TS2345
        return this.isHorizontalPath(this.points[0], this.points[1]);
    }

    /*
     * Returns True if this shape is considered a vertical line
     */
    isVerticalLine() {
        if (this.shape != DrawingShape.Line) return false;
        // @ts-ignore TS2345
        return this.isVerticalPath(this.points[0], this.points[1]);
    }

    /*
     * Returns True if the path between the 2 given points is considered
     * a Horizontal path.
     */
    isHorizontalPath(pointA: Position, pointB: Position) {
        return Math.abs(pointB.x - pointA.x) > Math.abs(pointB.y - pointA.y); // X Path is greater than Y path
    }

    // Returns True if the path between the 2 given points is considered
    // a Vertical path.
    isVerticalPath(pointA: Position, pointB: Position) {
        return Math.abs(pointB.y - pointA.y) > Math.abs(pointB.x - pointA.x); // Y Path is greater than X path
    }

    measurementAsImperial() {
        return this._convertToImperial(this.measurement);
    }

    depthAsImperial() {
        return this._convertToImperial(this.depth);
    }

    isImperial() {
        return IsImperial(this.unit);
    }

    _convertToImperial(value: number) {
        let result: string;
        let negative: boolean;
        if (!value) return '';
        if (value < 0) {
            negative = true;
            value = Math.abs(value);
        }

        const u = 12;

        const totalInches = value * u;
        // @ts-ignore TS2345
        let wholeInches = parseFloat(totalInches.toString().split('.')[0]);
        const decimalInches = totalInches - wholeInches;
        let decimalFeet = wholeInches / 12;
        // @ts-ignore TS2345
        const wholeFeet = parseFloat(decimalFeet.toString().split('.')[0]);
        decimalFeet -= wholeFeet;
        wholeInches = math.round(decimalFeet * 12, 0);
        const fraction = math.fraction(decimalInches) as math.Fraction;
        const feet = wholeFeet;
        const inches = wholeInches;
        const numerator = fraction.n;
        const denominator = fraction.d;
        result = '';
        // @ts-ignore TS2454
        result += negative ? '-' : '';
        // eslint-disable-next-line @typescript-eslint/quotes
        result += feet ? feet.toString() + "'" : '';
        result += inches ? inches.toString() : '';
        result += numerator && denominator ? ' ' + numerator.toString() + '/' + denominator.toString() : '';
        result += inches || (numerator && denominator) ? '"' : '';
        return result;
    }
}

/**
 * Returns True if the properties that would affect the visual drawing are
 * different, otherwise false. Can be used to determine if a refresh/redraw is necessary
 */
export function compareDrawings(a: Drawing, b: Drawing): boolean {
    const ignoreProperties = ['labelObject', 'shapeObject'];
    for (const prop in a) {
        // @ts-ignore
        if (ignoreProperties.indexOf(prop) === -1 && !_.isEqual(a[prop], b[prop])) {
            return false;
        }
    }
    return true;
}
