import { Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperService } from '@bx-web/shared-utils';

@Component({
    selector: 'bx-web-ui-help-tile',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './ui-help-tile.component.html',
    styleUrl: './ui-help-tile.component.scss',
})
export class UiHelpTileComponent implements OnInit {
    @Input() utmHomeUrl?: string;
    @Input() utmSignUpUrl?: string;
    @Input() partnership?: Partnership | null;

    #helperService = inject(HelperService);
    bxLink = '';
    freeTrialLink = '';

    ngOnInit() {
        this.bxLink = this.#helperService.getLinkToBXWithUTM(this.utmHomeUrl);
        this.freeTrialLink = this.#helperService.getFreeTrialLink(this.utmSignUpUrl);
    }
}

type Partnership = {
    hasPartner: boolean;
    partnerText?: string | null;
    partnerUrl?: string | null;
};
