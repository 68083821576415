import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanImportComponent } from '@bx-web/takeoff-upload';
import { AppStoreService } from '../app.store';
import { BxPictureInPictureService, HelperService } from '@bx-web/shared-utils';

@Component({
    selector: 'bx-erb-takeoffs',
    standalone: true,
    imports: [CommonModule, PlanImportComponent],
    templateUrl: './takeoffs-welcome.component.html',
    styleUrl: './takeoffs-welcome.component.scss',
})
export class TakeoffsWelcomeComponent implements OnInit {
    linkToBx = '';

    #activatedRoute = inject(ActivatedRoute);
    #router = inject(Router);
    #appStore = inject(AppStoreService);
    #helperService = inject(HelperService);
    #picInPicService = inject(BxPictureInPictureService);
    $currentSession = this.#appStore.currentSession.obs$;

    ngOnInit(): void {
        const currentSession = this.#appStore.currentSession.value();
        this.linkToBx = this.#helperService.getLinkToBXWithUTM(currentSession?.originator?.homeUtm ?? '');
    }

    uploadComplete() {
        const currentSession = this.#appStore.currentSession.value();
        if (currentSession) {
            currentSession.hasPlans = true;
            this.#appStore.currentSession.set(currentSession);
        }

        const currentQueryParams = this.#activatedRoute.snapshot.queryParams;
        this.#router.navigate(['/scale'], {
            queryParams: currentQueryParams,
            queryParamsHandling: 'merge',
        });
    }

    openNewSession() {
        const originator = this.#appStore.currentSession.value()?.originator?.originator;
        const originQueryParam = originator ? `?origin=${originator}` : '';
        const newUrl = `${window.location.origin}${originQueryParam}`;
        window.location.href = newUrl;
    }

    showVideo(url: string) {
        this.#picInPicService.setVideoLink(url);
    }
}
