import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type VideoLink = { link: string | null; dismissForeverKey: string };
@Injectable({
    providedIn: 'root',
})
export class BxPictureInPictureService {
    private $videoLink: BehaviorSubject<VideoLink> = new BehaviorSubject<VideoLink>({ link: null, dismissForeverKey: '' });
    public readonly videoUrl: Observable<VideoLink> = this.$videoLink.asObservable();
    public static readonly pipDismissKey = 'PipDismiss';

    setVideoLink(link: string, dismissForeverKey = '') {
        this.$videoLink.next({ link, dismissForeverKey });
    }

    closeVideoLink() {
        this.$videoLink.next({ link: null, dismissForeverKey: '' });
    }
}
