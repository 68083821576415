import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { TakeOffMeasurement } from '@bx-web/takeoff';

@Component({
    selector: 'bx-web-take-off-v2-plan-info',
    templateUrl: './take-off-v2-plan-info.component.html',
    styleUrls: ['./take-off-v2-plan-info.component.scss'],
})
export class TakeOffV2PlanInfoComponent {
    @Input() scaleRatio = '';
    @Input() measurement?: TakeOffMeasurement;
}

@NgModule({
    declarations: [TakeOffV2PlanInfoComponent],
    imports: [CommonModule],
    exports: [TakeOffV2PlanInfoComponent],
})
export class TakeOffV2PlanInfoModule {}
