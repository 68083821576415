import { Injectable } from '@angular/core';
import { GetImperial, GetMetric, GetYards } from '../core/functions';
import * as TakeOff from '../core/index';

@Injectable({ providedIn: 'root' })
export class TakeOffService {
    getSystem() {
        return TakeOff.System.metric;
    }

    getSupportedUnits() {
        const results = [];
        switch (this.getSystem()) {
            case TakeOff.System.imperial:
                results.push(...GetImperial(), ...GetYards(), TakeOff.DrawingUnit.ea);
                results.push(...GetMetric());
                break;
            case TakeOff.System.metric:
            default:
                results.push(...GetMetric(), TakeOff.DrawingUnit.ea);
                results.push(...GetImperial(), ...GetYards());
                break;
        }
        return results;
    }

    getDefaultSquareUnit() {
        switch (this.getSystem()) {
            case TakeOff.System.imperial:
                return TakeOff.DrawingUnit.sf;
            case TakeOff.System.metric:
            default:
                return TakeOff.DrawingUnit.m2;
        }
    }

    getDefaultLinealUnit() {
        switch (this.getSystem()) {
            case TakeOff.System.imperial:
                return TakeOff.DrawingUnit.lf;
            case TakeOff.System.metric:
            default:
                return TakeOff.DrawingUnit.lm;
        }
    }

    getSupportedScaleUnits() {
        const results = [];
        switch (this.getSystem()) {
            case TakeOff.System.imperial:
                results.push(TakeOff.ScaleUnitName.ft);
                results.push(TakeOff.ScaleUnitName.mm, TakeOff.ScaleUnitName.cm, TakeOff.ScaleUnitName.m);
                break;
            case TakeOff.System.metric:
            default:
                results.push(TakeOff.ScaleUnitName.mm, TakeOff.ScaleUnitName.cm, TakeOff.ScaleUnitName.m);
                results.push(TakeOff.ScaleUnitName.ft);
                break;
        }
        return results;
    }

    getSupportedScaleRatios() {
        const results = [];
        switch (this.getSystem()) {
            case TakeOff.System.imperial:
                results.push(...TakeOff.ScaleRatiosImperial);
                results.push(...TakeOff.ScaleRatiosMetric);
                break;
            case TakeOff.System.metric:
            default:
                results.push(...TakeOff.ScaleRatiosMetric);
                results.push(...TakeOff.ScaleRatiosImperial);
                break;
        }
        return results;
    }

    getDefaultScaleUnit() {
        switch (this.getSystem()) {
            case TakeOff.System.imperial:
                return TakeOff.ScaleUnitName.ft;
            case TakeOff.System.metric:
            default:
                return TakeOff.ScaleUnitName.mm;
        }
    }
}
