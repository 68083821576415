import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TakeOffAdditionalMeasurements, TakeOffMeasurement, Drawing } from '@bx-web/takeoff';
import { TakeOffScaleSettings } from './entities/take-off-scale-settings.models';
import { TakeOffUpdateStatus } from './entities/take-off-update-status.enum';
import { BreezePlan } from '../plan/entities/plans.model';
import { EstimateMeasurementVM } from '../estimate-measurement/entities/estimate-measurement.model';
import { Uuid } from '@bx-web/graphql';

@Injectable({
    providedIn: 'root',
})
export class SharedTakeOffStoresService {
    //#region Measurement
    public readonly selectedMeasurementChanged$ = new Subject<EstimateMeasurementVM>();
    public readonly addPlanMeasurement$ = new Subject<boolean>();
    public readonly deletePlanMeasurement$ = new Subject<Uuid | null>();

    public readonly selectedMeasurement$: Observable<TakeOffMeasurement | null>;
    // @ts-ignore TS2345
    private selectedMeasurementSource = new BehaviorSubject<TakeOffMeasurement | null>(null);

    public set selectedMeasurement(measurement: TakeOffMeasurement | null) {
        if (!measurement) {
            this.selectedMeasurementSource.next(measurement);
            return;
        }

        const existing = this.selectedMeasurementSource.value;
        if (existing?.Id !== measurement.Id) {
            this.selectedMeasurementSource.next(measurement);
            return;
        }

        if (existing.additionalMeasurements && measurement.additionalMeasurements) {
            // @ts-ignore TS2532
            measurement.additionalMeasurements.lineal.selected = existing.additionalMeasurements.lineal.selected;
            // @ts-ignore TS2532
            measurement.additionalMeasurements.area.selected = existing.additionalMeasurements.area.selected;
            // @ts-ignore TS2532
            measurement.additionalMeasurements.volume.selected = existing.additionalMeasurements.volume.selected;
        }

        this.selectedMeasurementSource.next(measurement);
    }

    public get snapshotSelectedMeasurement(): TakeOffMeasurement | null {
        return this.selectedMeasurementSource.value;
    }

    public updateEstimatePlans(updatedPlan: BreezePlan) {
        if (!this.snapshotEstimatePlans) {
            return;
        }

        const index = this.snapshotEstimatePlans.findIndex((plan) => plan.ID_Plan === updatedPlan.ID_Plan);
        if (index !== -1) {
            this.snapshotEstimatePlans[index] = updatedPlan;
        }
    }

    public updateAdditionalMeasurements(additionalMeasurements: TakeOffAdditionalMeasurements) {
        const measurement = this.selectedMeasurementSource.value;
        if (!measurement) {
            return;
        }

        this.selectedMeasurementSource.next({ ...measurement, additionalMeasurements: additionalMeasurements });
    }
    //#endregion Measurement

    public readonly estimatePlans$: Observable<BreezePlan[]> | null = null;
    private estimatePlansSource = new BehaviorSubject<BreezePlan[] | null>(null);
    public get snapshotEstimatePlans(): BreezePlan[] | null {
        return this.estimatePlansSource.value;
    }
    public set estimatePlans(plan: BreezePlan[]) {
        this.estimatePlansSource.next(plan);
    }

    //#region Plan

    public readonly selectedPlan$: Observable<BreezePlan | null>;
    private selectedPlanSource = new BehaviorSubject<BreezePlan | null>(null);
    public set selectedPlan(plan: BreezePlan) {
        this.selectedPlanSource.next(plan);

        if (!this.takeOffUpdateStatusSource.value) {
            this.takeOffUpdateStatusSource.next(TakeOffUpdateStatus.Completed);
        }
    }
    public get snapshotSelectedPlan(): BreezePlan | null {
        return this.selectedPlanSource.value;
    }

    public readonly scaleSettings$: Observable<TakeOffScaleSettings>;
    // @ts-ignore TS2345
    private scaleSettingsSource = new BehaviorSubject<TakeOffScaleSettings>(null);
    public set scaleSettings(scaleSettings: TakeOffScaleSettings) {
        this.scaleSettingsSource.next(scaleSettings);
    }
    //#endregion Plan

    //#region Drawing
    public readonly selectedPlanDrawings$: Observable<Drawing[]>;
    // @ts-ignore TS2345
    private selectedPlanDrawingsSource = new BehaviorSubject<Drawing[]>(null);
    public get snapshotSelectedPlanDrawings(): Drawing[] {
        return this.selectedPlanDrawingsSource.value || [];
    }

    public set selectedPlanDrawings(drawings: Drawing[]) {
        this.selectedPlanDrawingsSource.next(drawings);
    }

    public get shownDrawingsForSelectedPlan$(): Observable<Drawing[]> {
        return this.selectedPlanDrawings$.pipe(map((drawings) => drawings.filter((drawing) => !drawing.isHidden)));
    }

    public addDrawing(newDrawing: Drawing) {
        if (!this.selectedPlanDrawingsSource.value) {
            this.selectedPlanDrawingsSource.next([newDrawing]);
        } else {
            this.selectedPlanDrawingsSource.value.push(newDrawing);
        }

        this.triggerTakeOffUpdateEvents();
    }

    public updateDrawing(newDrawing: Drawing) {
        const index = this.snapshotSelectedPlanDrawings.findIndex((drawing) => drawing.id === newDrawing.id);
        if (index < 0) {
            return;
        }
        this.snapshotSelectedPlanDrawings.splice(index, 1, newDrawing);

        this.triggerTakeOffUpdateEvents();
    }

    public removeDrawingAt(index: number) {
        this.snapshotSelectedPlanDrawings.splice(index, 1);
        this.triggerTakeOffUpdateEvents();
    }

    public removeAllDrawings() {
        this.selectedPlanDrawingsSource.next([]);
        this.triggerTakeOffUpdateEvents();
    }
    //#endregion Drawing

    //#region Events
    public readonly takeOffUpdateStatus$: Observable<TakeOffUpdateStatus>;
    // @ts-ignore TS2345
    private takeOffUpdateStatusSource = new BehaviorSubject<TakeOffUpdateStatus>(null);

    public triggerTakeOffUpdateEvents() {
        // weird RXJS issue, need timeout to set the value in right order
        setTimeout(() => {
            this.takeOffUpdateStatusSource.next(TakeOffUpdateStatus.StartPlanUpdated);
        }, 0);
    }

    public completePlanUpdate() {
        // weird RXJS issue, need timeout to set the value in right order
        setTimeout(() => {
            this.takeOffUpdateStatusSource.next(TakeOffUpdateStatus.StartMeasurementsUpdated);
        }, 0);
    }

    public completeMeasurementUpdate() {
        // weird RXJS issue, need timeout to set the value in right order
        setTimeout(() => {
            this.takeOffUpdateStatusSource.next(TakeOffUpdateStatus.Completed);
        }, 0);
    }
    //#endregion Events

    constructor() {
        this.selectedMeasurement$ = this.selectedMeasurementSource.pipe(
            filter((measurement) => measurement !== undefined),
            distinctUntilChanged()
        );

        this.selectedPlan$ = this.selectedPlanSource.pipe(
            filter((plan) => !!plan),
            distinctUntilChanged()
        );

        this.scaleSettings$ = this.scaleSettingsSource.pipe(filter((scaleSettings) => !!scaleSettings));
        this.selectedPlanDrawings$ = this.selectedPlanDrawingsSource.pipe(filter((drawings) => !!drawings));

        this.takeOffUpdateStatus$ = this.takeOffUpdateStatusSource.pipe(filter((status) => !!status));
    }

    public cleanUp() {
        // @ts-ignore TS2345
        this.selectedMeasurementSource.next(null);
        this.selectedPlanSource.next(null);
        // @ts-ignore TS2345
        this.selectedPlanDrawingsSource.next(null);
        // @ts-ignore TS2345
        this.takeOffUpdateStatusSource.next(null);
    }
}
