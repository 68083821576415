import { ErrorHandler, Injectable, inject } from '@angular/core';
import { LoggerService } from '@bx-web/shared-utils';

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
    #loggerService = inject(LoggerService);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any): void {
        this.#loggerService.exception(error);
        console.error(error);
    }
}
