import { Injectable } from '@angular/core';
import { Uuid } from '@bx-web/graphql';
import { Apollo } from 'apollo-angular';
import { GetEstimatePlans, GetEstimatePlansResult, GetEstimatePlansVariables } from './query/estimate-plans.generated';

@Injectable({
    providedIn: 'root',
})
export class EstimatePlansApiService {
    constructor(private readonly apollo: Apollo) {}

    getPlansForEstimate(estimateId: Uuid) {
        return this.apollo.query<GetEstimatePlansResult, GetEstimatePlansVariables>({
            query: GetEstimatePlans,
            variables: {
                estimateId,
                order: [{ planOrder2: 'ASC' }],
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
    }
}
