import { EstimateMeasurementStoresService } from './estimate-measurement.stores';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { SharedTakeOffStoresService } from '../takeoff-measurement/shared-take-off.stores';
import { TakeOffUpdateStatus } from '../takeoff-measurement/entities/take-off-update-status.enum';

@Injectable({
    providedIn: 'root',
})
export class SharedEstimateMeasurementTakeOffsService {
    // #region constructor
    constructor(
        private readonly takeOffV2StoresService: SharedTakeOffStoresService,
        private estimateMeasurementStoresService: EstimateMeasurementStoresService
    ) {
        this.watchMeasurementDrawingChanges();
        this.watchTakeOffsChanges();
    }
    // #endregion constructor

    //#region Watchers
    private watchMeasurementDrawingChanges() {
        this.takeOffV2StoresService.takeOffUpdateStatus$
            .pipe(filter((status) => status === TakeOffUpdateStatus.StartMeasurementsUpdated))
            .subscribe(() => {
                this.estimateMeasurementStoresService.drawingUpdated$.next(true);
            });
    }

    private isDrawingUpdating = false;
    public get isTakeOffUpdating(): boolean {
        return this.isDrawingUpdating;
    }

    private watchTakeOffsChanges() {
        this.takeOffV2StoresService.takeOffUpdateStatus$.subscribe((status: TakeOffUpdateStatus) => {
            this.isDrawingUpdating = status !== TakeOffUpdateStatus.Completed;
        });
    }
    //#endregion Watchers
}
