import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';
import { LoggerService } from './logger.service';

declare global {
    interface Window {
        env: string;
    }
}

export enum AsideModalSize {
    narrow,
    normal,
    medium,
    wide,
}

export enum AsideModalPosition {
    left,
    right,
}
@Injectable({ providedIn: 'root' })
export class HelperService {
    constructor(private modalService: BsModalService, private logger: LoggerService) {}

    setModalClassToBody(f: boolean) {
        const body = document.getElementsByTagName('body')[0];
        if (body) {
            if (f) {
                body.classList.add('modal-open');
            } else {
                body.classList.remove('modal-open');
            }
        }
    }

    /**
     * Shows a bootstrap modal to the side mimicking the ngx-aside functionality
     * Use this instead of ngx-aside. The benefit here is that you don't need to embed
     * the aside element in any parent component, this is done for you by the Bootstrap
     * modal service.
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    showModalAside<T = Object>(
        content:
            | string
            | TemplateRef<T>
            | {
                  new (...args: any[]): T;
              },
        config?: ModalOptions<T>,
        size?: AsideModalSize,
        position?: AsideModalPosition
        // @ts-ignore TS2366
    ): BsModalRef<T> {
        // Configure defauls for the modal
        const defaults: ModalOptions<T> = {
            ignoreBackdropClick: true,
            keyboard: false,
        };
        const newConfig = Object.assign(defaults, config);
        // Append aside class so the onShown listener in the constructor
        // will detect it and add the necessary classes to the parent container
        if (!newConfig.class) newConfig.class = 'bx-modal-aside';
        else if (newConfig.class.indexOf('bx-modal-aside') === -1) newConfig.class += ' bx-modal-aside';
        // Hook into onShown event to add aside classes to parent container

        this.modalService.onShown.pipe(first()).subscribe((modal) => {
            const asideModalElement = document.querySelector('.modal-dialog.bx-modal-aside:not([modal-id])');

            if (asideModalElement !== null) {
                asideModalElement.setAttribute('modal-id', modal.id);

                const parentContainer = asideModalElement.parentElement;

                // @ts-ignore TS2531
                parentContainer.classList.add(
                    'bx-modal-aside',
                    'horizontal',
                    // @ts-ignore TS2345
                    position ? AsideModalPosition[position] : 'left',
                    'aside-nav-v2'
                );

                if (size !== undefined) {
                    // @ts-ignore TS2531
                    parentContainer.classList.add(AsideModalSize[size]);
                }
                // If an element is fullscreen, move the modal container inside the fullscreen element,
                // otherwise the modal will not be visible.
                const fullscreenElement = document.fullscreenElement;
                if (fullscreenElement !== null) {
                    const modalBackdropElement = document.querySelector('.modal-backdrop');
                    if (modalBackdropElement !== null) fullscreenElement.appendChild(modalBackdropElement);
                    // @ts-ignore TS2345
                    fullscreenElement.appendChild(parentContainer);
                }
            }
        });

        try {
            return this.modalService.show(content, newConfig);
        } catch (error) {
            // @ts-ignore TS2345
            this.logger.error(error);
        }
    }

    /**
     * Updates the size of the currently open bootstrap aside modal
     */
    updateAsideModalSize(size: AsideModalSize): void {
        const asideModalElement = document.querySelector('.bx-modal-aside.aside-nav-v2');
        if (asideModalElement) {
            asideModalElement.classList.remove('medium', 'wide');
            switch (size) {
                case AsideModalSize.medium:
                    asideModalElement.classList.add('medium');
                    break;
                case AsideModalSize.wide:
                    asideModalElement.classList.add('wide');
                    break;
            }
        }
    }

    /**
     * Shows a bootstrap modal. Added here to legacy AJS services can show an Angular component
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    showModal<T = Object>(
        content:
            | string
            | TemplateRef<T>
            | {
                  new (...args: any[]): T;
              },
        config?: ModalOptions<T>
        // @ts-ignore TS2366
    ): BsModalRef<T> {
        // Configure defauls for the modal
        const defaults: ModalOptions<T> = {
            ignoreBackdropClick: true,
            keyboard: false,
        };
        const newConfig = Object.assign(defaults, config);
        // Hook into onShown event to move modal to fullscreen element if needed
        this.modalService.onShown.pipe(first()).subscribe(() => {
            const modalElement = document.querySelector('.modal-dialog');
            if (modalElement !== null) {
                const parentContainer = modalElement.parentElement;
                // If an element is fullscreen, move the modal container inside the fullscreen element,
                // otherwise the modal will not be visible.
                const fullscreenElement = document.fullscreenElement;
                if (fullscreenElement !== null) {
                    const modalBackdropElement = document.querySelector('.modal-backdrop');
                    if (modalBackdropElement !== null) fullscreenElement.appendChild(modalBackdropElement);
                    // @ts-ignore TS2345
                    fullscreenElement.appendChild(parentContainer);
                }
            }
        });
        try {
            return this.modalService.show(content, newConfig);
        } catch (error) {
            // @ts-ignore TS2345
            this.logger.error(error);
        }
    }

    preventReentry(owner: any, func: () => void, strict: boolean) {
        // First time around we initialize
        if (owner.inCalculate === undefined) {
            owner.inCalculate = false;
            owner.needCalculate = false;
        }

        // If not running the 'func', then do so now
        if (owner.inCalculate === false) {
            owner.inCalculate = true;
            owner.needCalculate = true;

            // Keep running the 'func' until it finishes without a re-entry attempt
            while (owner.needCalculate) {
                owner.needCalculate = false;
                func();
            }

            owner.inCalculate = false;
        } else if (strict) {
            // Request the 'func' we called again because of a re-entry attempt
            owner.needCalculate = true;
        }
    }

    getLinkToBXWithUTM(utm?: string) {
        let linkToBx = 'https://www.buildxact.com';

        if (window.env.toLowerCase() === 'production' && utm) {
            linkToBx += '?' + utm;
        }
        return linkToBx;
    }

    getFreeTrialLink(utm?: string) {
        let linkToTrial = 'https://app.buildxact.com/signup.html';

        if (window.env.toLowerCase() === 'production' && utm) {
            linkToTrial += '?' + utm;
        }
        return linkToTrial;
    }
}
