import { TakeoffMeasurementsApiService } from './domain/apis/takeoff-measurements-api.service';
import { Injectable } from '@angular/core';
import { TakeOffConfig } from './domain';
import { TakeOffV2PlanService } from './take-off-v2-plan.service';
import { IsCubic, IsLineal, IsSquare, TakeOff } from '@bx-web/takeoff';
import { TakeOffV2DrawingService } from './take-off-v2-drawing.service';
import { Uuid } from '@bx-web/graphql';
import { BreezePlan, SharedTakeOffStoresService, EstimatePlansV2StoresService, EstimateMeasurementVM } from '@bx-web/takeoff-shared';
import { TakeOffV2MeasurementService } from './take-off-v2-measurement.service';
import { TakeOffToolQuoteRequestInput } from './domain/entities/rfq-input.model';

@Injectable({
    providedIn: 'root',
})
export class TakeOffV2Service {
    //#region constructor
    constructor(
        private readonly sharedTakeOffStoresService: SharedTakeOffStoresService,
        private readonly takeOffV2PlanService: TakeOffV2PlanService,
        private readonly takeOffV2DrawingService: TakeOffV2DrawingService,
        private estimatePlansV2StoresService: EstimatePlansV2StoresService,
        private takeOffV2MeasurementService: TakeOffV2MeasurementService,
        private takeoffMeasurementsApiService: TakeoffMeasurementsApiService
    ) {
        this.sharedTakeOffStoresService.selectedMeasurementChanged$.subscribe((res) => {
            if (res) {
                this.selectedMeasurementChanged(res);
                if (res.description !== res.originalMeasurement?.description) this.updateMeasurementLabel(res);
            } else this.sharedTakeOffStoresService.selectedMeasurement = null;
        });
    }

    updateMeasurementLabel(selectedMeasurement: EstimateMeasurementVM) {
        if (selectedMeasurement.id_PlanMeasurement)
            this.takeOffV2DrawingService.updateDrawingLabel(selectedMeasurement.id_PlanMeasurement, selectedMeasurement.description);
    }

    selectedMeasurementChanged(selectedMeasurement: EstimateMeasurementVM) {
        this.#setSelectedPlanForMeasurement(selectedMeasurement.id_PlanMeasurement as Uuid);
        this.sharedTakeOffStoresService.selectedPlanDrawings = this.takeOffV2DrawingService.getDrawingsForPlan();
        this.sharedTakeOffStoresService.selectedMeasurement = this.takeOffV2MeasurementService.getTakeOffMeasurement(selectedMeasurement);
    }

    #setSelectedPlanForMeasurement(id_PlanMeasurement: Uuid) {
        const plans = this.sharedTakeOffStoresService.snapshotEstimatePlans ?? [];
        const selectedPlan = this.sharedTakeOffStoresService.snapshotSelectedPlan;
        if (id_PlanMeasurement) {
            const plansForMeasurement = plans.filter((plan) =>
                plan.planmeasurements?.some((planMeasurement) => planMeasurement.iD_Measurement === id_PlanMeasurement)
            );

            if (!plansForMeasurement.length) {
                return;
            }
            // if there are no drawings for the selected measurement in the selected plan,
            // set the selected plan with one for the measurement.
            if (!plansForMeasurement.some((plan) => plan.ID_Plan === selectedPlan?.ID_Plan)) {
                const selectedMeasurement = plansForMeasurement[0];
                if (selectedMeasurement) {
                    this.sharedTakeOffStoresService.selectedPlan = selectedMeasurement;
                    const measurement = this.estimatePlansV2StoresService.snapshotEstimatePlans.find(
                        (plan) => plan.iD_Plan === selectedMeasurement.ID_Plan
                    );
                    if (measurement)
                        this.estimatePlansV2StoresService.updateEstimatePlan({
                            ...measurement,
                            isSelected: true,
                        });
                }
            }
        }
    }

    setSelectedPlan(selectedPlan: BreezePlan) {
        this.sharedTakeOffStoresService.selectedPlan = selectedPlan;
        this.setPlanSettings(selectedPlan);
        this.sharedTakeOffStoresService.selectedPlanDrawings = this.takeOffV2DrawingService.getDrawingsForPlan();
    }

    setPlanSettings(plan: BreezePlan) {
        this.sharedTakeOffStoresService.scaleSettings = this.takeOffV2PlanService.getScaleSettingsByBreeze(plan);
    }

    //#region Config
    public getConfig(uom: string): TakeOffConfig {
        return {
            uom: uom,
            negative: false,
            pitch: 0,
            pitchRatio: null,
            depth: 0,
            mouseScaleStep: 0.015,
            showLabels: true,
            color: '#FF0000',

            // @ts-ignore TS2322
            shape: null,

            // @ts-ignore TS2322
            depthUnit: null,

            canDrawShap: false,
            canDrawLine: false,
            canDrawPoint: false,
        };
    }

    public getShapeByUOM(uOM: string) {
        if (IsLineal(uOM)) return { shape: TakeOff.DrawingShape.Line, canDrawShap: true, canDrawLine: true };
        if (IsSquare(uOM)) return { shape: TakeOff.DrawingShape.Polygon, canDrawShap: true, canDrawLine: true };
        if (IsCubic(uOM)) return { shape: TakeOff.DrawingShape.Polygon, canDrawShap: true };
        return { shape: TakeOff.DrawingShape.Point, canDrawPoint: true }; //ea
    }
    //#endregion Config

    sendRFQ(req: TakeOffToolQuoteRequestInput) {
        return this.takeoffMeasurementsApiService.sendRfqForMeasurements(req);
    }
}
