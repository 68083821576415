<button
    id="measurement-{{ estimateMeasurement.order }}"
    [disabled]="disabled"
    *ngIf="estimateMeasurement && measurementForm"
    class="estimate-measurement-container"
    [ngClass]="{ selected: selectedMeasurement?.id === estimateMeasurement.id }"
    (click)="!disabled && onFocusIn(estimateMeasurement)">
    <div class="d-flex align-items-center">
        <span class="order">{{ order }}.</span>

        <div class="condensed-col description" [ngClass]="{ invalid: isInvalidMeasurement }">
            <input
                id="measurement-desc-{{ estimateMeasurement.order }}"
                #measurementDescription
                type="text"
                [title]="estimateMeasurement.description"
                class="form-control"
                [formControl]="$any(measurementForm.controls['description'])"
                bxWebSelectAllOnFocus />
            <i *ngIf="isInvalidMeasurement" class="fa fa-exclamation-circle"></i>
        </div>

        <div class="condensed-col quantity">
            <input
                [name]="'Measurement' + estimateMeasurement.order"
                [readonly]="!states.canChangeMeasurements"
                type="text"
                class="form-control text-end"
                [(ngModel)]="displayedquantity"
                bxWebNumeric
                [min]="0"
                [decimals]="3"
                required
                [imperial]="isImperial"
                #quantity
                (focus)="quantity.select()"
                (change)="onquantityChanged(quantity.value)" />
        </div>

        <div class="condensed-col uom" [ngClass]="{ 'text-start': !!estimateMeasurement.id_PlanMeasurement }">
            <select
                *ngIf="!estimateMeasurement.id_PlanMeasurement; else uomText"
                class="form-control"
                [formControl]="$any(measurementForm.controls['uom'])">
                <option [value]="unit" *ngFor="let unit of supportedUnits">{{ unit }}</option>
            </select>
            <ng-template #uomText
                ><span class="uom__text">{{ estimateMeasurement.uom }}</span></ng-template
            >
        </div>

        <div class="condensed-col">
            <a class="btn-bx-secondary-stroked-transparent actions" (click)="!disabled && pop.show()">
                <fa-icon [icon]="faEllipsesVertical"></fa-icon>
            </a>
        </div>
        <span
            [popover]="moreActionsPopOver"
            [outsideClick]="true"
            placement="right"
            [adaptivePosition]="false"
            container="body"
            [containerClass]="'more-actions-popover'"
            #pop="bs-popover">
        </span>
    </div>

    <!-- NOTES -->
    <div *ngIf="estimateMeasurement.note || showAddNote" class="notes">
        <div
            [attr.contenteditable]="!disabled"
            id="measurement-note{{ estimateMeasurement.order }}"
            #note
            data-placeholder="Write here"
            class="form-control text-start note"
            (blur)="onNoteChange(note.innerText)"
            [textContent]="estimateMeasurement.note"></div>
    </div>
</button>

<ng-template #moreActionsPopOver>
    <ul class="more-actions">
        <ng-container *ngIf="!estimateMeasurement.note; else removeNote">
            <li class="icon icon--add-notes" (click)="addNote()">Add a note</li>
        </ng-container>
        <ng-template #removeNote>
            <li class="icon icon--remove-note" (click)="deleteNote()">Remove note</li>
        </ng-template>
        <li class="icon icon--delete" (click)="onDeleteMeasurement($event)">Delete measurement</li>
    </ul>
</ng-template>
