<section class="estimate-measurement-settings-container">
    <span class="estimate-measurement-settings-heading">MEASUREMENTS</span>
    <div class="estimate-measurement-settings-actions mx-1">
        <button
            [disabled]="disabled"
            type="button"
            id="btn-add-measurement-2"
            class="btn-bx-panel-action mr-2"
            (click)="addMeasurement()"
            [tooltip]="disabled ? 'Complete the drawing' : ''"
            placement="bottom"
            container="body">
            + Add
        </button>
    </div>
</section>
