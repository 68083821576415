import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperService } from '@bx-web/shared-utils';
import { AppStoreService } from '../app.store';

@Component({
    selector: 'bx-erb-web-error',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './error.component.html',
    styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit {
    #helperService = inject(HelperService);
    #appStore = inject(AppStoreService);

    linkToBx = '';

    ngOnInit() {
        const currentSession = this.#appStore.currentSession.value();
        this.linkToBx = this.#helperService.getLinkToBXWithUTM(currentSession?.originator?.homeUtm ?? '');
    }
}
