import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EstimateMeasurementApisService, EstimateMeasurementUpdateExpression } from './domain';
import { EstimateMeasurementCalculatorService } from './estimate-measurement-calculator.service';
import { EstimateMeasurementsService } from './estimate-measurements.service';
import {
    EstimateViewStoresService,
    EstimateMeasurementStoresService,
    EstimateMeasurementVM,
    EstimateMeasurement,
} from '@bx-web/takeoff-shared';

@Injectable({
    providedIn: 'root',
})
export class EstimateMeasurementCategoryService {
    constructor(
        private readonly estimateMeasurementStoresService: EstimateMeasurementStoresService,
        private readonly estimateMeasurementApisService: EstimateMeasurementApisService,
        private readonly estimateViewStoresService: EstimateViewStoresService,
        private readonly estimateMeasurementsService: EstimateMeasurementsService,
        private readonly estimateMeasurementCalculatorService: EstimateMeasurementCalculatorService
    ) {
        this.watchEstimateChanges();
    }

    //#region Delete
    public deleteMeasurementCategory(deleted: EstimateMeasurementVM, isSelectedInTakeOff: boolean): Observable<boolean> {
        if (!deleted.id) {
            this.removeCategoryInList(deleted, isSelectedInTakeOff);
            return of(true);
        }

        return this.estimateMeasurementApisService
            .upsertEstimateMeasurement(deleted.estimateId, this.mapToUpsertEstimateMeasurement(deleted))
            .pipe(
                map((result) => {
                    const estimateMeasurement = (<any>result.data).upsertEstimateMeasurement;
                    if (!estimateMeasurement) {
                        return false;
                    }

                    this.removeCategoryInList(deleted, isSelectedInTakeOff);
                    if (deleted.measurements?.length) {
                        this.estimateMeasurementStoresService.removeTotalMeasurements(deleted.measurements?.length);
                    }
                    return true;
                })
            );
    }

    private removeCategoryInList(deleted: EstimateMeasurementVM, isSelectedInTakeOff: boolean) {
        let changedKeyReferences: EstimateMeasurementUpdateExpression[] = [];
        const deletedPlanMeasurementIds: string[] = [];

        const allCategories = this.estimateMeasurementStoresService.snapshotMeasurementCategories;

        // Remove deleted
        const index = allCategories.findIndex((c) => this.isSameCategory(c, deleted));
        if (index >= 0) {
            if (deleted.measurements) {
                deleted.measurements.forEach((measurement: EstimateMeasurementVM) => {
                    changedKeyReferences.push({
                        originKeyRef: this.estimateMeasurementCalculatorService.getExpressionKeyReference(deleted.order, measurement.order),
                        // @ts-ignore TS2345
                        newKeyRef: this.estimateMeasurementCalculatorService.convertMeasurementQuantityToExpression(measurement.quantity),
                    });

                    if (measurement.id_PlanMeasurement) {
                        deletedPlanMeasurementIds.push(measurement.id_PlanMeasurement);
                    }
                });
            } else {
                changedKeyReferences.push({
                    originKeyRef: this.estimateMeasurementCalculatorService.getExpressionKeyReference(null, deleted.order),
                    // @ts-ignore TS2345
                    newKeyRef: this.estimateMeasurementCalculatorService.convertMeasurementQuantityToExpression(deleted.quantity),
                });

                if (deleted.id_PlanMeasurement) {
                    deletedPlanMeasurementIds.push(deleted.id_PlanMeasurement);
                }
            }

            allCategories.splice(index, 1);
        }

        // Reorder others
        changedKeyReferences = [...changedKeyReferences, ...this.reorderCategories(allCategories)];

        // Update expressions
        this.estimateMeasurementCalculatorService.updateExpressionForMeasurements(allCategories, changedKeyReferences);
        this.estimateMeasurementStoresService.measurementCategories = allCategories;

        // Remove plan measurement
        this.estimateMeasurementStoresService.deletedPlanMeasurementIds = deletedPlanMeasurementIds;

        // Reset selected measurement
        const hasSelected = this.estimateMeasurementStoresService.snapshotSelectedMeasurement.id === deleted.id;
        if (hasSelected && isSelectedInTakeOff) {
            const selectedItem = this.estimateMeasurementsService.updateSelectedMeasurement(allCategories);
            this.estimateMeasurementStoresService.selectedMeasurement = selectedItem;
            if (!selectedItem) return;
            const index = allCategories.findIndex((cat) => cat.id === selectedItem.id);
            if (index > -1) {
                allCategories[index] = selectedItem;
                this.estimateMeasurementStoresService.measurementCategories = [...allCategories];
            }
        }
    }
    //#endregion Delete

    private reorderCategories(categories: EstimateMeasurementVM[]): EstimateMeasurementUpdateExpression[] {
        const changedKeyReferences: EstimateMeasurementUpdateExpression[] = [];

        categories.reduce((newOrder: number, category: EstimateMeasurementVM, currentIndex: number) => {
            if (newOrder !== category.order) {
                changedKeyReferences.push({
                    originKeyRef: this.estimateMeasurementCalculatorService.getExpressionKeyReference(
                        category.measurements ? category.order : null,
                        category.measurements ? null : category.order
                    ),
                    newKeyRef: this.estimateMeasurementCalculatorService.getExpressionKeyReference(
                        category.measurements ? newOrder : null,
                        category.measurements ? null : newOrder
                    ),
                });
                categories.splice(currentIndex, 1, { ...category, order: newOrder });
            }

            return ++newOrder;
        }, 1);

        return changedKeyReferences;
    }
    //#endregion Order

    //#region Search

    private isCategoryChanged(category: EstimateMeasurementVM): boolean {
        if (!category.description) {
            return false;
        }

        if (category.isDeleted || !category.originalMeasurement) {
            return true;
        }

        return category.originalMeasurement.description !== category.description || category.originalMeasurement.key !== category.order;
    }

    private isSameCategory(categoryA: EstimateMeasurementVM, categoryB: EstimateMeasurementVM): boolean {
        return categoryA.originalMeasurement ? categoryA.id === categoryB.id : categoryA.order === categoryB.order;
    }
    //#endregion Search

    // #region Watchers
    private watchEstimateChanges() {
        this.estimateViewStoresService.estimate$
            .pipe(
                switchMap((estimate) =>
                    this.estimateMeasurementApisService.getEstimateMeasurements(estimate.ID_Estimate).pipe(
                        map((result) => ({
                            estimateId: estimate.ID_Estimate,
                            measurements: result.data.estimateMeasurements,
                        }))
                    )
                )
            )
            .subscribe((result) => {
                if (result.estimateId && result.estimateId !== this.estimateMeasurementStoresService.estimateId) {
                    this.estimateMeasurementStoresService.estimateId = result.estimateId;
                }

                this.estimateMeasurementStoresService.measurementCategories = this.mapToEstimateMeasurementCategories(
                    result.measurements,
                    result.estimateId
                );
            });
    }
    //#endregion Watchers

    //#region Mapping
    private mapToEstimateMeasurementCategories(measurements: EstimateMeasurement[], estimateId?: string): EstimateMeasurementVM[] {
        if (!measurements?.length) {
            return [];
        }

        let totalMeasurementsNum = 0;
        const measurementCategories: EstimateMeasurementVM[] = [];
        const parents = measurements.filter((m) => m.isParent || !m.parentId).sort((pa, pb) => pa.key - pb.key);
        const children = measurements.filter((m) => !!m.parentId).sort((ma, mb) => ma.key - mb.key);

        parents.forEach((measurement: EstimateMeasurement) => {
            measurementCategories.push(
                measurement.isParent
                    ? // @ts-ignore TS2345
                      this.mapToEstimateMeasurementCategory(estimateId, measurement)
                    : // @ts-ignore TS2345
                      this.estimateMeasurementsService.mapToEstimateMeasurement(estimateId, measurement)
            );

            if (!measurement.isParent) {
                totalMeasurementsNum++;
            }
        });

        children.forEach((measurement) => {
            const category = measurementCategories.find((c) => c.id === measurement.parentId);
            if (category) {
                // @ts-ignore TS2532
                category.measurements.push(this.estimateMeasurementsService.mapToEstimateMeasurement(estimateId, measurement));
                totalMeasurementsNum++;
            }
        });

        this.estimateMeasurementStoresService.totalMeasurements = totalMeasurementsNum;
        return measurementCategories;
    }

    private mapToEstimateMeasurementCategory(estimateId: string, measurement: EstimateMeasurement): EstimateMeasurementVM {
        return {
            id: measurement.iD_EstimateMeasurement,
            order: measurement.key,
            description: measurement.description,
            note: measurement.note,
            isExpanded: measurement.key === 1,
            estimateId: estimateId,
            measurements: [],
            originalMeasurement: measurement,
        };
    }

    public mapToUpsertEstimateMeasurement(category: EstimateMeasurementVM): EstimateMeasurement {
        if (!category.measurements) {
            return this.estimateMeasurementsService.mapToUpsertEstimateMeasurement({
                ...category,
                isDeleted: category.isDeleted,
            });
        }

        // @ts-ignore TS7034
        const measurements = [];

        category.measurements.forEach((measurement) => {
            const estimateMeasurement = this.estimateMeasurementsService.mapToUpsertEstimateMeasurement({
                ...measurement,
                isDeleted: category.isDeleted || measurement.isDeleted,
            });

            if (estimateMeasurement) {
                measurements.push(estimateMeasurement);
            }
        });

        if (!this.isCategoryChanged(category) && measurements.length === 0) {
            // @ts-ignore TS2322
            return null;
        }

        return {
            iD_EstimateMeasurement: category.id,
            description: category.description,
            key: category.order,
            measurement: 0,
            // @ts-ignore TS2322
            expression: null,
            // @ts-ignore TS2322
            iD_PlanMeasurement: null,
            // @ts-ignore TS2322
            uOM: null,
            isDeleted: !!category.isDeleted,
            isParent: true,
            // @ts-ignore TS2322
            parentId: null,
            // @ts-ignore TS7005
            measurements: measurements,
        };
    }
    //#endregion Mapping
}
