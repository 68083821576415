import { DrawingUnit } from './constants';
import { ConvertBetweenUnits, GetBaseUnit, IsLineal, IsSquareOrCubic } from './functions';
import { PitchFactors } from './pitchfactors';
import { Scale } from './scale';

export const ScaleConverter = {
    MetresToFeet: 3.28084,
    /** Converts the given pixels to a valid scale measurement.
     * @param {number} pixels = The total pixels to convert
     * @param {Scale} scale = The scale defines how many scale units are in 1 pixel
     * @param {string} unit = The unit of the final measurement. E.g. we want the result to be in m2 or metres squared.
     * @param {number} pitch = The degrees or angle of the given pixel measurement. E.g, the pixels might be 5000 as a flat, 2d value, but represent a sloped roof at an angle of 28 degrees.
     * @param {string} planUnit = The unit used to scale the plan in the first place. If an imperial unit was used, the result will be given as an imperial measurement.
     * @returns {number} The calculated result to 2 decimal places
     */
    convert: function (pixels: number, scale: Scale, unit: string, pitch: number, planUnit: string): number {
        // The int value of the MeasurementUnit defines the Sq factor
        let sqFactor = 0;
        if (IsSquareOrCubic(unit)) {
            sqFactor = 2;
        } else if (IsLineal(unit)) {
            sqFactor = 1;
        } else {
            sqFactor = 0;
        }

        // The int value of the ScaleUnit defines how many scale units are in 1 measurement unit
        const scaleFactor = scale.scaleunit;

        // If the plan has a different system of units from the take-off then need to convert between them
        let scaleValue = scale.value;
        if (unit != DrawingUnit.ea) {
            const planBaseUnit = GetBaseUnit(planUnit);
            const drawingBaseUnit = GetBaseUnit(unit);
            scaleValue = ConvertBetweenUnits(scaleValue, planBaseUnit, drawingBaseUnit);
        }

        // The scale value needs to take in to account the Sq factor of the MeasurementUnit we're converting to
        const sqScaleValue = Math.pow(scaleValue, sqFactor);

        // Multiply by the total pixels calculated
        const calcScaleValue = pixels * sqScaleValue;

        let result = calcScaleValue;

        // Divide the result into the scaleFactor which converts the value to the measurement unit scale.
        for (let i = 0; i < sqFactor; i++) {
            result = result / scaleFactor;
        }

        // Calculate the pitch factor if applicable
        if ((pitch ? pitch : 0) > 0) {
            const minPitch = PitchFactors.minPitch;
            const maxPitch = PitchFactors.maxPitch;
            if (pitch >= minPitch && pitch <= maxPitch) {
                const factor = PitchFactors.getPitchFactor(pitch);
                result = result * factor;
            }
        }

        // Round and return
        return +result.toFixed(2);
    },
};
