/** The format of the file to be converted */
export enum PlanConvertFileFormat {
    pdf = 'pdf',
    dwg = 'dwg',
}

/** The format of the images to be converted into */
export enum PlanConvertImageFormat {
    png = 'png',
    svg = 'svg',
}
