import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';

@Directive({
    selector: '[bxWebClickOutsidePanels]',
    standalone: true,
})
export class ClickOutsidePanelsDirective implements OnChanges {
    @Output() appClickOutsidePanels = new EventEmitter<void>();
    @Input() clickOutDelay = true;
    @Input() excludedClasses = '';
    @Input() excludeDatePicker = false;
    @Input() enableClickOut = false;

    clickOutInitialized = false;
    checkDatePicker = '';

    constructor(private _elementRef: ElementRef) {}

    ngOnChanges() {
        if (!this.enableClickOut) {
            this.clickOutInitialized = false;
        }
    }

    private clickedInside = false;

    @HostListener('click')
    clickInside() {
        // this click always runs before document:click
        this.clickedInside = true;
    }

    @HostListener('document:click', ['$event.target'])
    clickout(target: any) {
        //try to remove any from here

        if (this.enableClickOut) {
            if (!this.clickedInside) {
                const _classesExcluded = this.excludedClasses.split(',');
                let exemptElement = false;
                const checkEx = target;

                if (this.excludeDatePicker) {
                    this.checkDatePicker = target.attributes.bsdatepickerdaydecorator;
                }

                if (this.excludedClasses) {
                    for (const excluded of _classesExcluded) {
                        if (checkEx?.classList?.contains(excluded) || checkEx?.parentNode?.classList?.contains(excluded)) {
                            exemptElement = true;
                        }
                    }
                }

                if (!exemptElement && !this.checkDatePicker && !this.excludeWarnings(checkEx)) {
                    if (this.clickOutDelay) {
                        this.initClickOut();
                        if (this.clickOutInitialized) {
                            this.appClickOutsidePanels.emit();
                            this.disableClickOut();
                        }
                    } else {
                        this.appClickOutsidePanels.emit();
                    }
                }
            }
            this.clickedInside = false;
        }
    }

    public initClickOut() {
        setTimeout(() => {
            this.clickOutInitialized = true;
        });
    }

    public disableClickOut() {
        setTimeout(() => {
            this.clickOutInitialized = false;
        });
    }

    public excludeWarnings(element: HTMLElement) {
        let disableOnWarningBox = false;
        const warningClasses = element.classList.toString();
        const warningIds = element.id.toString();

        if (warningClasses.includes('swal2') || warningIds.includes('swal2')) {
            disableOnWarningBox = true;
        }

        return disableOnWarningBox;
    }
}
