import { Injectable } from '@angular/core';
import { TakeOffScaleSettings } from './domain';
import { TakeOff } from '@bx-web/takeoff';
import { TakeOffV2DrawingService } from './take-off-v2-drawing.service';
import { BreezePlan, SharedTakeOffStoresService } from '@bx-web/takeoff-shared';

@Injectable({
    providedIn: 'root',
})
export class TakeOffV2PlanService {
    constructor(
        private readonly takeOffV2StoresService: SharedTakeOffStoresService,
        private readonly takeOffV2DrawingService: TakeOffV2DrawingService
    ) {}

    public getScaleSettingsByBreeze(plan: BreezePlan): TakeOffScaleSettings {
        return {
            // @ts-ignore TS7053
            horizontalScale: new TakeOff.Scale(TakeOff.ScaleUnit[plan.ScaleUnit], plan.Scale),
            // @ts-ignore TS7053
            verticalScale: new TakeOff.Scale(TakeOff.ScaleUnit[plan.ScaleUnit], plan.VScale),
            planScaleUnit: plan.ScaleUnit,
        };
    }

    public selectPlan(selectedPlanId: string) {
        const plan = this.takeOffV2StoresService.snapshotEstimatePlans?.find((plan) => plan.ID_Plan === selectedPlanId);
        if (plan) {
            this.takeOffV2StoresService.selectedPlan = plan;
            this.takeOffV2StoresService.selectedPlanDrawings = this.takeOffV2DrawingService.getDrawingsForPlan();
        } else throw new Error('Could not find selected plan');
    }
}
