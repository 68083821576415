import { Injectable, inject } from '@angular/core';
import { Location } from '@angular/common';

import { TakeOffLimit, Uuid } from '@bx-web/graphql';
import { tap, BehaviorSubject } from 'rxjs';
import { AppStoreService, UserSession } from './app.store';
import { SessionApiService } from '@bx-web/session';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ErrorService } from './error/error.service';

export const missingOriginatorError = 'This tool can only be accessed via participating suppliers. Please contact your supplier.';
@Injectable({
    providedIn: 'root',
})
export class SessionService {
    #sessionApiService = inject(SessionApiService);
    #appStore = inject(AppStoreService);
    #location = inject(Location);
    #errorService = inject(ErrorService);
    loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    createTakeOffSession(originator: string) {
        return this.#sessionApiService.createSession(originator).pipe(
            tap(({ data }) => {
                if (data?.createTakeOffToolSession) {
                    const { takeOffToolCreateSession, errors } = data.createTakeOffToolSession;
                    if (takeOffToolCreateSession) {
                        if (!takeOffToolCreateSession.originator) {
                            this.#errorService.currentErrorBannerMsg.set(missingOriginatorError);
                            this.loading$.next(false);
                            return;
                        }
                        this.#appStore.currentSession.set({
                            sessionId: takeOffToolCreateSession.iD_Session,
                            estimateID: takeOffToolCreateSession.iD_Estimate,
                            tenantId: takeOffToolCreateSession.iD_TenantId,
                            useId: takeOffToolCreateSession.iD_UserId,
                            sendQuoteCount: 0,
                            measurementLimit: takeOffToolCreateSession.measurementLimit,
                            planMeasurementLimit: takeOffToolCreateSession.planMeasurementLimit,
                            sendQuoteLimit: takeOffToolCreateSession.sendQuoteLimit,
                            pagesLimit: takeOffToolCreateSession.pagesLimit,
                            originator: takeOffToolCreateSession.originator,
                        });
                        this.addSessionInfo();
                        this.#updateUrlWithSessionId(takeOffToolCreateSession.iD_Session);
                    } else if (errors) {
                        if (errors[0]?.__typename === 'TakeOffLimitError') {
                            if (errors[0].limitName === TakeOffLimit.Userdailysessions)
                                this.#errorService.currentErrorBannerMsg.set(`You've reached your maximum session limit for the day.`);
                            else
                                this.#errorService.currentErrorBannerMsg.set(
                                    'The site is experiencing high usage. Please try again later.'
                                );
                        } else {
                            const errorMsg = errors[0]?.message;
                            this.#errorService.currentErrorBannerMsg.set(errorMsg ?? 'Failed to create a new session. Please try again.');
                        }
                    }
                    this.loading$.next(false);
                }
            })
        );
    }

    #updateUrlWithSessionId(paramValue: string): void {
        const currentPath = this.#location.path();
        const hasQueryParams = currentPath.includes('?');
        const separator = hasQueryParams ? '&' : '?';
        const updatedUrl = `${currentPath}${separator}sessionId=${paramValue}`;
        this.#location.replaceState(updatedUrl);
    }

    openTakeOffSession(sessionId: Uuid) {
        return this.#sessionApiService.openSession(sessionId).pipe(
            tap(({ data }) => {
                if (data?.openTakeOffToolSession.takeOffToolOpenSession) {
                    const response = data.openTakeOffToolSession.takeOffToolOpenSession;
                    if (!response.originator) {
                        this.#errorService.currentErrorBannerMsg.set(missingOriginatorError);
                        this.loading$.next(false);
                        return;
                    } else {
                        this.#appStore.currentSession.set({
                            sessionId,
                            estimateID: response.iD_Estimate,
                            tenantId: response.iD_TenantId,
                            useId: response.iD_UserId,
                            hasPlans: response.hasPlans,
                            sendQuoteCount: response.sendQuoteCount,
                            measurementLimit: response.measurementLimit,
                            planMeasurementLimit: response.planMeasurementLimit,
                            sendQuoteLimit: response.sendQuoteLimit,
                            pagesLimit: response.pagesLimit,
                            originator: response.originator,
                        });
                        this.addSessionInfo();
                    }
                }
                if (data?.openTakeOffToolSession.errors) {
                    const err = data.openTakeOffToolSession.errors[0];
                    let errorMsg = 'Something went wrong. Please create a new session.';
                    if (err?.__typename === 'TakeOffSessionExpiredError') {
                        errorMsg = 'Your session has expired.';
                    } else if (err?.__typename === 'NotFoundError') {
                        errorMsg = 'Your session could not be found.';
                    }
                    this.#errorService.currentErrorBannerMsg.set(errorMsg);
                }
                if (data) this.loading$.next(false);
            })
        );
    }

    addSessionInfo() {
        const session = this.#appStore.currentSession.value();
        if (session) {
            this.initializeAppInsights(session);
            this.initializePendo(session.sessionId, session.estimateID, session.originator?.originatorName ?? '');
            this.initFullStory(session.sessionId, session.estimateID);
        }
    }

    initializeAppInsights(userSession: UserSession) {
        if (window.appInsights) {
            const userTelemetryInitializer = (envelope: ITelemetryItem) => {
                if (envelope.baseData) {
                    envelope.baseData['properties'] = envelope.baseData['properties'] || {};
                    envelope.baseData['properties'].userSessionId = userSession.sessionId;
                    envelope.baseData['properties'].estimateId = userSession.estimateID;
                }
            };

            window.appInsights.addTelemetryInitializer(userTelemetryInitializer);
            window.appInsights.setAuthenticatedUserContext(userSession.tenantId);
            window.appInsights.context.user.id = userSession.useId;
            window.appInsights.trackPageView();
        }
    }

    initializePendo(sessionId: Uuid, estimateId: Uuid, originator: string) {
        if (window.pendo) {
            window.pendo.initialize({
                visitor: { sessionId, originator },
                account: { estimateId },
            });
        }
    }

    initFullStory(sessionId: Uuid, estimateId: Uuid) {
        if (window.fullStory) {
            window.fullStory.identify(sessionId, {
                estimateId,
                userType: 'ERB',
            });
        }
    }
}
