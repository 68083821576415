import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, NavigationEnd, Router } from '@angular/router';
import { AppStoreService } from '../app.store';
import { filter } from 'rxjs';

export function sessionGuard(): CanActivateFn {
    return (route: ActivatedRouteSnapshot) => {
        const appStoreService = inject(AppStoreService);
        const router = inject(Router);
        if (appStoreService.currentSession.value()?.sessionId) {
            return true;
        }
        const queryParams = route.queryParams;
        const existingSessionId = queryParams['sessionId'] ?? null;
        router.navigate(['/'], { queryParams: { sessionId: existingSessionId, from: route.routeConfig?.path } });
        return false;
    };
}

let lastUrl = '';
export function lastUrlListener(router: Router): void {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
        lastUrl = (event as NavigationEnd).urlAfterRedirects;
    });
}

export function hasUploadedPlans(): CanActivateFn {
    return () => {
        const appStoreService = inject(AppStoreService);
        const router = inject(Router);
        const currentSession = appStoreService.currentSession.value();

        // cannot go back to scale page from measurements
        if (!currentSession || !currentSession.hasPlans || lastUrl.includes('/measurements')) {
            router.navigate(['/import'], {
                queryParams: { origin: currentSession?.originator?.originator, sessionId: currentSession?.sessionId },
            });
            return false;
        }

        return true;
    };
}
