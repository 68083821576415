import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { debounceTime, filter, take } from 'rxjs/operators';
import { ComponentChanges } from '@bx-web/shared-utils';
import { PageSelectionValidators } from '../domain';
import { EstimatePlanConvertJob, EstimatePlanConvertJobStatus } from '../domain/apis/estimate-plan-convert-job.factory';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@UntilDestroy()
@Component({
    selector: 'bx-web-plan-convert-job',
    templateUrl: './plan-convert-job.component.html',
    styleUrls: ['./plan-convert-job.component.scss'],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ProgressbarModule, FontAwesomeModule],
    standalone: true,
})
export class PlanConvertJobComponent implements OnChanges {
    @Input() index = 0;

    @Input() job!: EstimatePlanConvertJob;
    @Input() maxPageCount = 0;

    @Output() deleted = new EventEmitter();
    @Output() newSession = new EventEmitter();

    public math = Math;

    public id = '';
    faCircleXmark = faCircleXmark;

    jobForm = this.formBuilder.group({
        selectedPages: [''],
        password: [''],
    });

    public readonly convertJobStatus = EstimatePlanConvertJobStatus;

    constructor(private readonly formBuilder: UntypedFormBuilder) {}

    ngOnChanges(changes: ComponentChanges<PlanConvertJobComponent>): void {
        if (changes.job?.currentValue) {
            this.init();
        }
    }

    init() {
        this.id = `${this.job.file.name}_${this.job.file.size}`;

        this.jobForm = this.formBuilder.group({
            selectedPages: [''],
            password: [''],
        });

        this.jobForm.controls['password']?.valueChanges.subscribe((password) => {
            this.job.password = password;
            this.job.validate();
        });
        this.job.pageCount$.pipe(take(1)).subscribe((count) => {
            if (count) {
                this.initImportForm();
            }
        });
    }

    private initImportForm() {
        if (this.jobForm) {
            this.jobForm.controls['selectedPages']?.setValidators([
                PageSelectionValidators.selectedPages(this.job.pageCount, this.maxPageCount),
            ]);
            this.jobForm.setValidators([PageSelectionValidators.pageRanges()]);
        }

        this.jobForm.controls['selectedPages']?.valueChanges
            .pipe(
                debounceTime(500),
                untilDestroyed(this),

                filter(() => this.jobForm.controls['selectedPages']?.valid ?? false)
            )
            .subscribe((pageRange) => {
                const customPageRange = pageRange?.trim();
                this.job.pageRange = customPageRange;
            });
    }

    public isJobInProgressOrCompleted() {
        return (
            this.job.getCurrentStatus().status === EstimatePlanConvertJobStatus.InProgress ||
            this.job.getCurrentStatus().status === EstimatePlanConvertJobStatus.Completed
        );
    }

    public onDelete() {
        this.deleted.emit();
    }

    public onSelectedPagesKeyIn(event: KeyboardEvent) {
        if (event.key === '-' || event.key === ',') {
            return true;
        }

        const keyValue = parseInt(event.key);
        return keyValue >= 0 && keyValue <= 9;
    }
}
