import { Position } from './position';

/*
 * Namespace that contains methods to calculate the measurement
 * of a drawing shape
 */
export const ShapeCalculator = {
    /*
     * Calculates the total pixel area of a polygon using the provided vertices array.
     * The first point in the array MUST be the same as the last point (i.e: The shape must be complete)</param>
     * @param vertices = an array of TakeOff.Position objects that represent vertices on
     * a drawing canvas to plot a polygon.
     */
    calculateAreaPolygon: function (vertices: Position[]) {
        let area = 0;
        let mod: number;
        if (vertices == null) return area;
        const verticeCount = vertices.length;

        // Must ensure the last vertice is the same as the first.
        // @ts-ignore TS2532
        if (vertices[vertices.length - 1].x != vertices[0].x || vertices[vertices.length - 1].y != vertices[0].y) return area;

        for (let i = 0; i < verticeCount; i++) {
            mod = (i + 1) % verticeCount;
            // @ts-ignore TS2532
            area += vertices[i].x * vertices[mod].y - vertices[mod].x * vertices[i].y;
        }

        let result = area / 2;
        // Ensure calculation is always positive.
        if (result < 0) result = result * -1;

        return result;
    },

    /*
     * Calculates the total pixel perimiter of a polygon using the provided vertices array.
     * The first point in the array MUST be the same as the last point (i.e: The shape must be complete)</param>
     */
    calculatePerimiterPolygon: function (vertices: Position[]) {
        let result = 0;

        if (vertices == null) return result;
        const verticeCount = vertices.length;

        // Must ensure the last vertice is the same as the first.
        // @ts-ignore TS2532
        if (vertices[vertices.length - 1].x != vertices[0].x || vertices[vertices.length - 1].y != vertices[0].y) return result;

        for (let i = 1; i < verticeCount; i++) {
            const prevVert = vertices[i - 1];
            const thisVert = vertices[i];
            // @ts-ignore TS2345
            result += ShapeCalculator.calculateLineDistance(prevVert, thisVert);
        }

        // Ensure calculation is always positive.
        if (result < 0) result = result * -1;

        return result;
    },

    /*
     * Calculates the pixel area of an ellipse.
     */
    calculateAreaEllipse: function (width: number, height: number) {
        let area = 0;

        // Make sure width and height are valid
        if (width == 0 || height == 0) return area;

        const axisX = width / 2;
        const axisY = height / 2;

        area = Math.PI * axisX * axisY;

        return area;
    },

    /*
     * Calculates the pixel circumference of an ellipse.
     */
    calculateCircumferenceEllipse: function (width: number, height: number) {
        let circ = 0;

        // Make sure width and height are valid
        if (width == 0 || height == 0) return circ;

        const axisX = (width / 2) * (width / 2);
        const axisY = (height / 2) * (height / 2);

        const axis = 2 * (axisX + axisY);

        circ = Math.PI * Math.sqrt(axis);

        return circ;
    },

    /*
     * Calculates the pixel area of a rectangle given its width and height
     */
    calculateAreaRectangle: function (width: number, height: number) {
        let area = 0;

        // Make sure width and height are valid
        if (width == 0 || height == 0) return area;

        area = width * height;

        return area;
    },

    /*
     * Calculates the pixel perimiter of a rectangle.
     */
    calculatePerimiterRectangle: function (width: number, height: number) {
        return width * 2 + height * 2;
    },

    /*
     * // Calculates the pixel distance of a line in any direction.
     */
    calculateLineDistance: function (posA: Position, posB: Position) {
        let distance = 0;

        const aAbsolute = posA.x - posB.x;
        const bAbsolute = posA.y - posB.y;

        const aSq = aAbsolute * aAbsolute;
        const bSq = bAbsolute * bAbsolute;

        distance = Math.sqrt(aSq + bSq);

        return distance;
    },
};
