<ng-template #takeOffPitchPopoverTemplate>
    <div>
        <div>
            <label>Degrees</label>
            <input
                type="text"
                bxWebNumeric
                decimals="2"
                max="89"
                bxWebSelectAllOnFocus
                [(ngModel)]="degrees"
                [ngModelOptions]="{ standalone: true }"
                class="form-control width-60"
                (ngModelChange)="onDegreesChanged()" />
        </div>
        <div>
            <section class="d-inline-flex mt-3">
                <div>
                    <label>Rise</label>
                    <input
                        type="text"
                        bxWebNumeric
                        decimals="0"
                        max="99"
                        bxWebSelectAllOnFocus
                        [(ngModel)]="ratioX"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control width-60"
                        (ngModelChange)="onRatioXYChanged()" />
                </div>
                <div class="pt-3 m-2">:</div>
                <div>
                    <label>Run</label>
                    <input
                        type="text"
                        bxWebNumeric
                        decimals="0"
                        max="99"
                        bxWebSelectAllOnFocus
                        [(ngModel)]="ratioY"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control width-60"
                        (ngModelChange)="onRatioXYChanged()" />
                </div>
            </section>
            <p class="text-danger text-small">{{ ratioError }}</p>
        </div>
    </div>
</ng-template>

<button
    type="button"
    id="toPitchDropdown"
    class="btn"
    tooltip="Angle / Surface Pitch"
    placement="bottom"
    [popover]="takeOffPitchPopoverTemplate"
    [outsideClick]="true"
    popoverTitle="Enter the degrees or rise over run:"
    container="body"
    [disabled]="disabled"
    [ngClass]="{ 'btn-light-azure': degrees, 'btn-dark-blue': !degrees }">
    <strong>{{ degrees }}&deg;</strong>
</button>
