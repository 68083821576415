import { Injectable, inject } from '@angular/core';
import { EstimatePlansApiService } from './domain/estimate-plans-api.service';
import { PlansStoreService } from './plans.store';
import { BreezePlans, Plan } from './domain/types/plan.model';
import { Uuid } from '@bx-web/graphql';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PlanService {
    #estimatePlanService = inject(EstimatePlansApiService);
    #planStore = inject(PlansStoreService);

    getPlansForEstimate(estimateId: Uuid) {
        return this.#estimatePlanService.getPlansForEstimate(estimateId).pipe(
            tap((res) => {
                const nodes = res.data?.estimatePlans?.nodes as Plan[];
                if (nodes) {
                    this.#planStore.currentPlans.set(nodes);
                    const breezePlans: BreezePlans[] = nodes.map((plan: Plan) => this.#mapToBreezeObject(plan));
                    this.#planStore.currentBreezePlans.set(breezePlans);
                }
            })
        );
    }

    #mapToBreezeObject(plan: Plan): BreezePlans {
        return {
            ID_Plan: plan.iD_Plan,
            ID_Reference: plan.iD_Reference,
            PlanLabel: plan.planLabel,
            PlanDescription: plan.planDescription,
            PlanOrder2: plan.planOrder2,
            Scale: plan.scale,
            ScaleUnit: plan.scaleUnit,
            VScale: plan.vScale,
            ScaleRatio: plan.scaleRatio,
            HasScale: plan.scale > 0 && plan.vScale > 0,
            PlanUrl: plan.planStorageUrl,
            IsDeleted: plan.isDeleted,
            Metadata: plan.metadata,
            planmeasurements: plan.planmeasurements,
        };
    }
}
