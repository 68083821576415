import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'bx-web-take-off-v2-actions',
    templateUrl: './take-off-v2-actions.component.html',
    styleUrls: ['./take-off-v2-actions.component.scss'],
})
export class TakeOffV2ActionsComponent {
    @Input() hasDrawings = false;
    @Input() disabled = false;
    @Output() undoChanges = new EventEmitter();

    public onUndo() {
        this.undoChanges.emit();
    }
}

@NgModule({
    declarations: [TakeOffV2ActionsComponent],
    imports: [CommonModule, TooltipModule],
    exports: [TakeOffV2ActionsComponent],
})
export class TakeOffV2ActionsModule {}
