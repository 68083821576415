import { Injectable } from '@angular/core';
import _ from 'lodash';
import SweetAlert from 'sweetalert2';

type SyncOrAsync<T> = T | Promise<T>;

export interface DialogParams {
    title: string;
    type?: 'success' | 'error' | 'warning' | 'info' | 'question';
    allowHtml?: boolean;
    text: string;
    showCancel?: boolean;
    okText?: string;
    cancelText?: string;
    style?: string;
    background?: string;
    customClass?: string;
    width?: number | string;
    heightAuto?: boolean;
    imageUrl?: string;
    imageWidth?: number;
    imageHeight?: number;
    showLoaderOnConfirm?: boolean;
    input?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'range' | 'textarea' | 'select' | 'radio' | 'checkbox' | 'file' | 'url';
    inputPlaceholder?: string;
    dismissable?: boolean;
    preConfirm?(inputValue: any): SyncOrAsync<any | void>;
    inputValidator?(inputValue: string): SyncOrAsync<string | null>;
}

@Injectable({ providedIn: 'root' })
export class DialogService {
    show(params: DialogParams) {
        return new Promise<void>((resolve, reject) => {
            const type = params.type || 'warning';

            // @ts-ignore TS2769
            SweetAlert.fire({
                title: _.escape(params.title),
                html: params.allowHtml ? params.text : _.escape(params.text),
                type: type,
                reverseButtons: true,
                showCancelButton: params.showCancel === undefined ? true : params.showCancel,
                confirmButtonText: params.okText || 'Confirm',
                cancelButtonText: params.cancelText || 'Cancel',
                allowOutsideClick: false,
                animation: false,
                showLoaderOnConfirm: params.showLoaderOnConfirm,
                customClass: params.customClass ? params.customClass : `bx-dialog  bx-dialog-${params.style || type}`,
                timer: null,
                target: (document.fullscreenElement as HTMLElement) || 'body',
                input: params.input,
                inputPlaceholder: params.inputPlaceholder,
                background: params.background,
                width: params.width,
                heightAuto: params.heightAuto,
                imageUrl: params.imageUrl,
                imageWidth: params.imageWidth,
                imageHeight: params.imageHeight,
                preConfirm: params.preConfirm,
                allowEscapeKey: params.dismissable ?? true,
                inputValidator: params.inputValidator,
            }).then((result) => {
                if (result.value) {
                    resolve();
                } else {
                    reject();
                }
            });
        });
    }
}
