export const System = {
    metric: 'metric',
    imperial: 'imperial',
};

/*
 * Provides available units for drawing shapes to be measured in
 */
export const DrawingUnit = {
    m3: 'm3',
    m2: 'm2',
    lm: 'lm',
    ea: 'ea',
    cf: 'cf',
    sf: 'sf',
    lf: 'lf',
    cy: 'cy',
    sy: 'sy',
    ly: 'ly',
};

/*
 * Type of shapes available that can be drawn on the drawing canvas
 */
export const DrawingShape = {
    Polygon: 'Polygon',
    Rectangle: 'Rectangle',
    Ellipse: 'Ellipse',
    Line: 'Line',
    Point: 'Point',
};

/*
 * Drawing Modes available for the drawing canvas
 */
export const DrawingMode = {
    None: 'None', // no drawing allowed, only background images
    Single: 'Single', // single shape allowed only
    Multiple: 'Multiple', // multiple shapes allowed
};

/*
 * Defines the units for a given scale object and their scale factor
 */
export const ScaleUnit = {
    mm: 1000,
    cm: 100,
    m: 1,
    ft: 1,
    getUnitName: (val: number) => {
        for (const prop in ScaleUnit) {
            if (Object.prototype.hasOwnProperty.call(ScaleUnit, prop)) {
                // @ts-ignore TS7053
                if (ScaleUnit[prop] === val) return prop;
            }
        }
        return null;
    },
    getUnit: (key: string) => {
        for (const prop in ScaleUnit) {
            if (prop === key) {
                return ScaleUnit[prop as 'mm' | 'cm' | 'm' | 'ft'];
            }
        }
        return undefined;
    },
};

/*
 * Defines the names for the scale units
 */
export const ScaleUnitName = {
    mm: 'mm',
    cm: 'cm',
    m: 'm',
    ft: 'ft',
};

export const DepthUnit = {
    mm: 'mm',
    cm: 'cm',
    m: 'm',
    ft: 'ft',
};

export type Unit = 'm' | 'cm' | 'mm' | 'ft' | 'yd' | 'ea';

export const UnitConversionFactors = [
    { from: 'm', to: 'm', factor: 1 },
    { from: 'm', to: 'cm', factor: 100 },
    { from: 'm', to: 'mm', factor: 1000 },
    { from: 'm', to: 'ft', factor: 3.28084 },
    { from: 'm', to: 'yd', factor: 1.09361 },
    { from: 'm', to: 'ea', factor: 1 },
    { from: 'cm', to: 'm', factor: 0.01 },
    { from: 'cm', to: 'cm', factor: 1 },
    { from: 'cm', to: 'mm', factor: 10 },
    { from: 'cm', to: 'ft', factor: 0.0328084 },
    { from: 'cm', to: 'yd', factor: 0.0109361 },
    { from: 'cm', to: 'ea', factor: 1 },
    { from: 'mm', to: 'm', factor: 0.001 },
    { from: 'mm', to: 'cm', factor: 0.1 },
    { from: 'mm', to: 'mm', factor: 1 },
    { from: 'mm', to: 'ft', factor: 0.00328084 },
    { from: 'mm', to: 'yd', factor: 0.00109361 },
    { from: 'mm', to: 'ea', factor: 1 },
    { from: 'ft', to: 'm', factor: 0.3048 },
    { from: 'ft', to: 'cm', factor: 30.48 },
    { from: 'ft', to: 'mm', factor: 304.8 },
    { from: 'ft', to: 'ft', factor: 1 },
    { from: 'ft', to: 'yd', factor: 0.333333 },
    { from: 'ft', to: 'ea', factor: 1 },
    { from: 'yd', to: 'm', factor: 0.9144 },
    { from: 'yd', to: 'cm', factor: 91.44 },
    { from: 'yd', to: 'mm', factor: 914.4 },
    { from: 'yd', to: 'ft', factor: 3 },
    { from: 'yd', to: 'yd', factor: 1 },
    { from: 'yd', to: 'ea', factor: 1 },
    { from: 'ea', to: 'm', factor: 1 },
    { from: 'ea', to: 'cm', factor: 1 },
    { from: 'ea', to: 'mm', factor: 1 },
    { from: 'ea', to: 'ft', factor: 1 },
    { from: 'ea', to: 'yd', factor: 1 },
    { from: 'ea', to: 'ea', factor: 1 },
];

export interface ScaleRatio {
    name: string;
    value: string;
    imperial: boolean;
}

export const ScaleRatiosMetric: ScaleRatio[] = [
    { name: 'Full Scale', value: '1:1', imperial: false },
    { name: '1:20', value: '1:20', imperial: false },
    { name: '1:50', value: '1:50', imperial: false },
    { name: '1:100', value: '1:100', imperial: false },
    { name: '1:200', value: '1:200', imperial: false },
    { name: '1:250', value: '1:250', imperial: false },
    { name: '1:300', value: '1:300', imperial: false },
    { name: '1:400', value: '1:400', imperial: false },
    { name: '1:500', value: '1:500', imperial: false },
    { name: '1:1000', value: '1:1000', imperial: false },
];

export const ScaleRatiosImperial: ScaleRatio[] = [
    { name: '3":1\'', value: '1:4', imperial: true },
    { name: '1 1/2":1\'', value: '1:8', imperial: true },
    { name: '1":1\'', value: '1:12', imperial: true },
    { name: '3/4":1\'', value: '1:16', imperial: true },
    { name: '1/2":1\'', value: '1:24', imperial: true },
    { name: '3/8":1\'', value: '1:32', imperial: true },
    { name: '1/4":1\'', value: '1:48', imperial: true },
    { name: '3/16":1\'', value: '1:64', imperial: true },
    { name: '1/8":1\'', value: '1:96', imperial: true },
    { name: '3/32":1\'', value: '1:128', imperial: true },
    { name: '1/16":1\'', value: '1:192', imperial: true },
];
