import { TakeOffV2Component } from '@bx-web/takeoff-measurements';
import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanService } from '../plans/plan.service';
import { PlansStoreService } from '../plans/plans.store';
import { AppStoreService } from '../app.store';
import { first } from 'rxjs/operators';
import { Uuid } from '@bx-web/graphql';

@Component({
    selector: 'bx-erb-measurements',
    standalone: true,
    imports: [CommonModule, TakeOffV2Component],
    templateUrl: './measurements.component.html',
    styleUrl: './measurements.component.scss',
})
export class MeasurementsComponent implements OnInit {
    #planStore = inject(PlansStoreService);
    #planService = inject(PlanService);
    #appStore = inject(AppStoreService);

    plansBreeze$ = this.#planStore.currentBreezePlans.obs$;
    plans$ = this.#planStore.currentPlans.obs$;
    estimateId = '';
    sendQuoteCount = this.#appStore.currentSession.value()?.sendQuoteCount ?? 0;
    sendQuoteLimit = this.#appStore.currentSession.value()?.sendQuoteLimit ?? 0;
    measurementLimit = this.#appStore.currentSession.value()?.measurementLimit ?? 0;
    originator = this.#appStore.currentSession.value()?.originator;
    sessionId = this.#appStore.currentSession.value()?.sessionId;

    ngOnInit() {
        this.estimateId = this.#appStore.currentSession.value()?.estimateID ?? '';
        if (!this.estimateId) throw new Error('Estimate Id is null');

        if (!this.#planStore.currentBreezePlans.value) {
            this.#planService
                .getPlansForEstimate(this.estimateId as Uuid)
                .pipe(first())
                .subscribe(({ errors }) => {
                    if (errors?.length) throw new Error(errors[0]?.message ?? 'Error fetching estimate Plans');
                });
        }
    }

    canDeactivate(nextStateUrl: string): boolean {
        return nextStateUrl.includes('/import') || confirm('Do you want to leave this page? Changes you made may not be saved.');
    }
}
