/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstimateMeasurementApisService, EstimateMeasurementCategoryVM, EstimateMeasurementUpdateExpression } from './domain';
import { EstimateMeasurementCalculatorService } from './estimate-measurement-calculator.service';
import { EstimateMeasurementTakeOffsService } from './estimate-measurement-take-offs.service';
import { EstimateMeasurement, EstimateMeasurementStoresService, EstimateMeasurementVM } from '@bx-web/takeoff-shared';

@Injectable({
    providedIn: 'root',
})
export class EstimateMeasurementsService {
    // #region constructor
    constructor(
        private readonly estimateMeasurementApisService: EstimateMeasurementApisService,
        private readonly estimateMeasurementStoresService: EstimateMeasurementStoresService,
        private readonly estimateMeasurementTakeOffsService: EstimateMeasurementTakeOffsService,
        private readonly estimateMeasurementCalculatorService: EstimateMeasurementCalculatorService
    ) {}
    // #endregion constructor

    //#region Search
    public isSameMeasurement(measurementA: EstimateMeasurementVM, measurementB: EstimateMeasurementVM): boolean {
        return measurementA.originalMeasurement
            ? measurementA.id === measurementB.id
            : measurementA.parentId === measurementB.parentId && measurementA.order === measurementB.order;
    }

    private isMeasurementChanged(measurement: EstimateMeasurementVM): boolean {
        if (!measurement.description) {
            return false;
        }

        if (measurement.isDeleted || !measurement.originalMeasurement) {
            return true;
        }

        return (
            measurement.originalMeasurement.description !== measurement.description ||
            measurement.originalMeasurement.measurement !== measurement.quantity ||
            measurement.originalMeasurement.uOM !== measurement.uom ||
            measurement.originalMeasurement.expression !== measurement.expression ||
            measurement.originalMeasurement.key !== measurement.order ||
            measurement.originalMeasurement.iD_PlanMeasurement !== measurement.id_PlanMeasurement ||
            measurement.originalMeasurement.parentId !== measurement.parentId ||
            measurement.originalMeasurement.note !== measurement.note
        );
    }
    //#endregion Search

    // #region create measurement
    public generateMeasurement() {
        const allCategories = this.estimateMeasurementStoresService.snapshotMeasurementCategories;

        const newMeasurement: EstimateMeasurementVM = {
            // @ts-ignore TS2532
            id: null,
            order: allCategories.length + 1,
            description: 'Untitled measurement',
            quantity: 0,
            uom: this.estimateMeasurementTakeOffsService.defaultSquareUnit,
            expression: '0',
            id_PlanMeasurement: null,
            estimateId: this.estimateMeasurementStoresService.estimateId,
            originalMeasurement: null,
            parentId: null,
            note: null,
        };

        this.estimateMeasurementStoresService.measurementCategories = [...allCategories, newMeasurement];
        this.estimateMeasurementStoresService.selectedMeasurement = newMeasurement;
        return newMeasurement;
    }

    public createMeasurement(created: EstimateMeasurementVM) {
        this.estimateMeasurementApisService
            .upsertEstimateMeasurement(created.estimateId, this.mapToUpsertEstimateMeasurement(created))
            .subscribe((result) => {
                const estimateMeasurement = (<any>result.data).upsertEstimateMeasurement;
                if (!estimateMeasurement) {
                    return;
                }

                this.updateMeasurement({
                    ...created,
                    id: estimateMeasurement.iD_EstimateMeasurement,
                    originalMeasurement: estimateMeasurement,
                });

                this.estimateMeasurementStoresService.addTotalMeasurements();
            });
    }
    // #endregion create measurement

    // #region update measurement
    public updateMeasurement(changed: EstimateMeasurementVM) {
        let hasListChanged = false;
        const allItems = this.estimateMeasurementStoresService.snapshotMeasurementCategories;
        const categoryForChanged = changed.parentId
            ? this.estimateMeasurementStoresService.getMeasurementCategoryById(changed.parentId)
            : null;

        allItems.forEach((item: EstimateMeasurementVM, itemIndex: number) => {
            // Measurement
            if (this.isSameMeasurement(item, changed)) {
                allItems.splice(itemIndex, 1, changed);
                hasListChanged = true;
                return;
            }

            // @ts-ignore TS2345
            const newMeasurement = this.getUpdatedMeasurement(categoryForChanged, changed, item);
            if (newMeasurement) {
                allItems.splice(itemIndex, 1, newMeasurement);
                hasListChanged = true;
            }
        });

        if (hasListChanged) {
            this.estimateMeasurementStoresService.measurementCategories = allItems;
        }
        this.estimateMeasurementStoresService.selectedMeasurement = changed;
    }

    private getUpdatedMeasurement(
        categoryForChanged: EstimateMeasurementVM,
        changed: EstimateMeasurementVM,
        existing: EstimateMeasurementVM
    ): EstimateMeasurementVM {
        let isChanged = false;
        let newMeasurement = { ...existing };
        const existinsgIsSelected = this.estimateMeasurementStoresService.snapshotSelectedMeasurement.id === existing.id;
        if (existinsgIsSelected) {
            isChanged = true;
        }

        if (changed.isInTakeOff && existing.isInTakeOff) {
            newMeasurement = { ...newMeasurement, isInTakeOff: false };
            isChanged = true;
        }

        const newQuantity = this.estimateMeasurementCalculatorService.recalculateForMeasurement(
            existing,
            changed,
            categoryForChanged?.order
        );
        if (newQuantity !== existing.quantity) {
            newMeasurement = { ...newMeasurement, quantity: newQuantity };
            isChanged = true;
        }

        // @ts-ignore TS2322
        return isChanged ? newMeasurement : null;
    }

    public removeTakeOffsForMeasurements(measurements: EstimateMeasurementVM[], deletedPlanMeasurementIds: string[]) {
        measurements.forEach((measurement: EstimateMeasurementVM, index: number) => {
            if (!measurement.id_PlanMeasurement) {
                return;
            }

            if (
                deletedPlanMeasurementIds &&
                !deletedPlanMeasurementIds.some((measurementId: string) => measurementId === measurement.id_PlanMeasurement)
            ) {
                return;
            }

            const newMeasurement = { ...measurement, id_PlanMeasurement: null, isMeasurementShown: false };

            // @ts-ignore TS2345
            measurements.splice(index, 1, newMeasurement);
        });
    }

    public updateSelectedMeasurement(allCategories: EstimateMeasurementCategoryVM[]): EstimateMeasurementVM {
        const selectedCategory =
            // @ts-ignore TS2532
            allCategories.find((c) => c.measurements?.some((m) => m.isSelected)) ||
            allCategories.find((c) => c.isExpanded) ||
            allCategories[0];

        if (!selectedCategory) {
            // @ts-ignore TS2322
            return null;
        }

        // Update selected measurement
        let selectedIndex = 0;
        // @ts-ignore TS2322
        let selected: EstimateMeasurementVM = null;

        if (!selectedCategory.measurements) {
            selectedIndex = allCategories.findIndex((c) => c.id === selectedCategory.id);
            selected = { ...selectedCategory, isInTakeOff: true, isMeasurementShown: true };
            allCategories.splice(selectedIndex, 1, selected);
            return selected;
        }

        // @ts-ignore TS2322
        if (!selected) {
            if (!selectedCategory.measurements.length) {
                // @ts-ignore TS2322
                return null;
            }

            // @ts-ignore TS2322
            selected = { ...selectedCategory.measurements[0], isSelected: true, isInTakeOff: true };
        }

        // @ts-ignore TS2532
        selectedCategory.measurements.splice(selectedIndex, 1, selected);
        return selected;
    }
    //#endregion update measurement

    // #region delete measurement
    public deleteMeasurement(deleted: EstimateMeasurementVM, isSelectedInTakeOff: boolean): Observable<boolean> {
        if (!deleted.id) {
            this.removeMeasurementInCategory(deleted, isSelectedInTakeOff);
            return of(true);
        }

        return this.estimateMeasurementApisService
            .upsertEstimateMeasurement(deleted.estimateId, this.mapToUpsertEstimateMeasurement(deleted))
            .pipe(
                map((result) => {
                    const estimateMeasurement = (<any>result.data).upsertEstimateMeasurement;
                    if (!estimateMeasurement) {
                        return false;
                    }

                    this.removeMeasurementInCategory(deleted, isSelectedInTakeOff);
                    this.estimateMeasurementStoresService.removeTotalMeasurements();

                    //this.bxLogger.success('Measurement is deleted');

                    return true;
                })
            );
    }

    private removeMeasurementInCategory(deleted: EstimateMeasurementVM, isSelectedInTakeOff: boolean) {
        let changedKeyReferences: EstimateMeasurementUpdateExpression[] = [];

        const allCategories = this.estimateMeasurementStoresService.snapshotMeasurementCategories;

        const category = deleted.parentId ? this.estimateMeasurementStoresService.getMeasurementCategoryById(deleted.parentId) : null;

        // Remove deleted
        // @ts-ignore TS2531
        const index = category.measurements.findIndex((m) => this.isSameMeasurement(m, deleted));
        if (index >= 0) {
            changedKeyReferences.push({
                // @ts-ignore TS2531
                originKeyRef: this.estimateMeasurementCalculatorService.getExpressionKeyReference(category.order, deleted.order),
                // @ts-ignore TS2345
                newKeyRef: this.estimateMeasurementCalculatorService.convertMeasurementQuantityToExpression(deleted.quantity),
            });
            // @ts-ignore TS2531
            category.measurements.splice(index, 1);
        }

        // Reorder others
        // @ts-ignore TS2345
        changedKeyReferences = [...changedKeyReferences, ...this.reorderMeasurementsForCategory(category)];

        // Update expressions
        const hasListChanged = this.estimateMeasurementCalculatorService.updateExpressionForMeasurements(
            allCategories,
            changedKeyReferences
        );
        if (hasListChanged) {
            this.estimateMeasurementStoresService.measurementCategories = allCategories;
        }

        // Remove plan measurement
        this.estimateMeasurementStoresService.deletedPlanMeasurementIds = deleted.id_PlanMeasurement ? [deleted.id_PlanMeasurement] : [];

        // Reset selected measurement
        const isSelected = this.estimateMeasurementStoresService.snapshotSelectedMeasurement.id === deleted.id;
        if (isSelected && isSelectedInTakeOff) {
            this.estimateMeasurementStoresService.selectedMeasurement = this.updateSelectedMeasurement(allCategories);
        }
    }

    // #endregion delete measurement

    //#region order measurements
    public reorderMeasurementsForCategory(category: EstimateMeasurementCategoryVM): EstimateMeasurementUpdateExpression[] {
        const changedKeyReferences: EstimateMeasurementUpdateExpression[] = [];

        // @ts-ignore TS2532
        category.measurements.reduce((newOrder: number, measurement: EstimateMeasurementVM, currentIndex: number) => {
            let isChanged = false;
            let newMeasurement: EstimateMeasurementVM = { ...measurement };

            if (newOrder !== measurement.order) {
                isChanged = true;
                changedKeyReferences.push({
                    originKeyRef: this.estimateMeasurementCalculatorService.getExpressionKeyReference(category.order, measurement.order),
                    newKeyRef: this.estimateMeasurementCalculatorService.getExpressionKeyReference(category.order, newOrder),
                });
                newMeasurement = { ...newMeasurement, order: newOrder };
            }

            if (isChanged) {
                // @ts-ignore TS2532
                category.measurements.splice(currentIndex, 1, newMeasurement);
            }

            return ++newOrder;
        }, 1);

        return changedKeyReferences;
    }
    //#endregion order measurements

    // #region mappings
    public mapToEstimateMeasurement(estimateId: string, measurement: EstimateMeasurement): EstimateMeasurementVM {
        return {
            id: measurement.iD_EstimateMeasurement,
            order: measurement.key,
            description: measurement.description,
            quantity: measurement.measurement,
            uom: measurement.uOM,
            expression: measurement.expression,
            id_PlanMeasurement: measurement.iD_PlanMeasurement,
            parentId: measurement.parentId,
            note: measurement.note,
            estimateId: estimateId,
            originalMeasurement: measurement,
        };
    }

    public mapToUpsertEstimateMeasurement(measurement: EstimateMeasurementVM): EstimateMeasurement {
        if (!this.isMeasurementChanged(measurement)) {
            // @ts-ignore TS2322
            return null;
        }

        return {
            iD_EstimateMeasurement: measurement.id,
            description: measurement.description,
            key: measurement.order,
            // @ts-ignore TS2322
            measurement: measurement.quantity,
            // @ts-ignore TS2322
            expression: measurement.expression,
            // @ts-ignore TS2322
            iD_PlanMeasurement: measurement.id_PlanMeasurement,
            // @ts-ignore TS2322
            uOM: measurement.uom,
            isDeleted: !!measurement.isDeleted,

            isParent: false,
            // @ts-ignore TS2322
            parentId: measurement.parentId,
            // @ts-ignore TS2322
            measurements: null,
            note: measurement.note,
        };
    }
    // #endregion mappings

    // #region For other components
    public saveNewMeasurement(estimateId: string, newMeasurement: EstimateMeasurement): Observable<string> {
        // @ts-ignore TS2322
        return this.estimateMeasurementApisService.upsertEstimateMeasurement(estimateId, newMeasurement).pipe(
            map((result) => {
                const estimateMeasurement = (<any>result.data).upsertEstimateMeasurement;
                if (!estimateMeasurement) {
                    return null;
                }

                const created = this.mapToEstimateMeasurement(estimateId, estimateMeasurement);
                // @ts-ignore TS2345
                const categoryForChanged = this.estimateMeasurementStoresService.getMeasurementCategoryById(created.parentId);
                // @ts-ignore TS2532
                categoryForChanged.measurements.push(created);

                return this.estimateMeasurementCalculatorService.getExpressionKeyReference(categoryForChanged.order, created.order);
            })
        );
    }
    // #endregion For other components
}
