import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { Subscription, interval, BehaviorSubject } from 'rxjs';

@Component({
    selector: 'bx-web-loading',
    standalone: true,
    imports: [CommonModule, ProgressbarModule],
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnDestroy {
    @Output() done = new EventEmitter<boolean>();
    $progressValue: BehaviorSubject<number> = new BehaviorSubject(0);
    private intervalSubscription: Subscription;

    constructor() {
        const intervalTime = 100;
        const interval$ = interval(intervalTime);
        this.intervalSubscription = interval$.subscribe(() => {
            this.incrementProgressBar();
        });
    }

    ngOnDestroy() {
        this.stopInterval();
    }

    stopInterval() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    incrementProgressBar() {
        const currentValue = this.$progressValue.value;
        const incrementAmount = 20;
        const newValue = Math.min(currentValue + incrementAmount, 100);
        this.$progressValue.next(newValue);
        if (currentValue === 100) this.done.emit();
    }
}
