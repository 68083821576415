import * as Types from '@bx-web/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ScalePlansVariables = Types.Exact<{
  model?: Types.InputMaybe<Types.ScalePlansModelInput>;
}>;


export type ScalePlansResult = Pick<Types.Mutation, 'scalePlans'>;


export const ScalePlans = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ScalePlans"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"model"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ScalePlansModelInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"scalePlans"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"model"},"value":{"kind":"Variable","name":{"kind":"Name","value":"model"}}}]}]}}]} as unknown as DocumentNode<ScalePlansResult, ScalePlansVariables>;