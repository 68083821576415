import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LoggerService } from '@bx-web/shared-utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
    #loggerService = inject(LoggerService);
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        // for multipart form-data, the content type needs to be undefined to add the correct boundaries
        if (request.headers.get('Content-Type') === 'multipart/form-data') {
            request = request.clone({ headers: request.headers.delete('Content-Type', 'multipart/form-data') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return this.handleNext(request, next);
    }

    handleNext<T>(request: HttpRequest<T>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((err) => {
                this.#loggerService.exception(err);
                return throwError(() => err);
            })
        );
    }
}
