<div class="import">
    <section class="import__file">
        <div class="import__title mb-4">Get started by uploading your PDF plan</div>

        <bx-web-plan-file-dropper *ngIf="!isImporting" (selectedFilesChanges)="onFilesSelected($event)"> </bx-web-plan-file-dropper>

        <div *ngIf="error.length" class="validation validation-error">{{ error }}</div>

        <div class="mt-4" *ngIf="jobs?.length">
            <bx-web-plan-convert-job
                *ngFor="let job of jobs; let i = index"
                [job]="job"
                [index]="i"
                [maxPageCount]="pageLimit"
                (deleted)="onDeleted(job)"
                (newSession)="newSession.emit()">
            </bx-web-plan-convert-job>
        </div>
    </section>

    <section *ngIf="!hideUploadBtn" class="import-actions">
        <button
            type="button"
            id="btn-plan-import"
            class="btn-bx-primary btn-large"
            [disabled]="!allJobsValid() || isImporting"
            (click)="import()">
            {{ importFailed ? 'Retry' : 'Upload file' }}
        </button>
    </section>
</div>
