import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ImportFileTypes } from '../domain/entities/import-file-types.enum';
import { EstimatePlanConvertApisService } from '../domain';

@Component({
    selector: 'bx-web-plan-file-dropper',
    templateUrl: './plan-file-dropper.component.html',
    styleUrls: ['./plan-file-dropper.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class PlanFileDropperComponent implements OnInit {
    @Output() selectedFilesChanges = new EventEmitter<File[]>();
    @ViewChild('upload') upload?: ElementRef;

    public acceptedFormat = '';
    public maxFileSizeMB = 0;

    constructor(private readonly estimatePlanConvertApiService: EstimatePlanConvertApisService) {}

    ngOnInit(): void {
        this.acceptedFormat = `${ImportFileTypes.PDF}`;
        this.maxFileSizeMB = this.getFileSizeInMB(this.estimatePlanConvertApiService.MAX_FILE_SIZE_BYTES);
    }

    public onFileSelected(fileList: FileList | null) {
        if (!fileList) return;
        this.selectedFilesChanges.emit(Array.from(fileList));
        this.reset();
    }

    getFileSizeInMB(size: number) {
        return size / 1024 ** 2;
    }

    reset() {
        if (this.upload) this.upload.nativeElement.value = '';
    }
}
