import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlanConvertJobResponse } from '../entities/plan-convert-job.models';
import { PlanConvertOptions } from '../entities/plan-convert-options';
import { PlanStorageFile } from '../entities/plan-storage-file.models';

declare global {
    interface Window {
        globalApiEndpoint: string;
    }
}

@Injectable({
    providedIn: 'root',
})
export class EstimatePlanConvertApisService {
    public MAX_FILE_SIZE_BYTES = 1024 * 1024 * 128; // 128 MB
    globalApiEndpoint = window.globalApiEndpoint;
    constructor(private readonly httpClient: HttpClient) {}

    /** Begins a conversion job on the specified file */
    public postConvert(file: File, options: PlanConvertOptions) {
        if (file.size > this.MAX_FILE_SIZE_BYTES) throw Error(`Maximum file size for conversion is ${this.MAX_FILE_SIZE_BYTES}`);

        const headers = new HttpHeaders().set('X-HIDEOVERLAY', '1').set('Content-Type', 'multipart/form-data');

        const formData = new FormData();
        formData.append(file.name, file);

        let params = new HttpParams()
            .set('referenceId', options.referenceId)
            .set('imageResolutionDpi', options.imageResolutionDpi?.toString() || '300')
            .set('planFormat', options.planFormat.toString())
            .set('imageFormat', options.imageFormat.toString());
        if (options.password) params = params.set('password', options.password);
        if (options.pageRange) params = params.set('pageRange', options.pageRange);

        return this.httpClient.post<PlanConvertJobResponse>(`${this.globalApiEndpoint}estimates-v2/plan/convert`, formData, {
            headers,
            observe: 'events',
            reportProgress: true,
            params,
        });
    }

    /** Returns the status of the conversion job */
    public getStatus(jobId: string): Observable<PlanConvertJobResponse> {
        const headers = new HttpHeaders().set('X-HIDEOVERLAY', '1');
        return this.httpClient.get<PlanConvertJobResponse>(`${this.globalApiEndpoint}estimates-v2/plan/convert/${jobId}/status`, {
            headers,
        });
    }

    /** Completes the conversion job by saving the converted image files to storage and returning the results */
    public postUpload(jobId: string, referenceId: string) {
        const headers = new HttpHeaders().set('X-HIDEOVERLAY', '1');
        return this.httpClient.post<PlanStorageFile[]>(
            `${this.globalApiEndpoint}estimates-v2/plan/convert/${jobId}/upload/${referenceId}`,
            null,
            { headers }
        );
    }

    /** Deletes the conversion job if it is completed or has an error and is no longer required */
    public deleteJob(jobId: string) {
        const headers = new HttpHeaders().set('X-HIDEOVERLAY', '1');
        return this.httpClient.delete(`${this.globalApiEndpoint}estimates-v2/plan/convert/${jobId}`, { headers });
    }
}
