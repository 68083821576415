/** Moves an item in an array from one position to another */
export function moveInArray<T>(arr: Array<T>, from: T, to: T) {
    const fromIndex = arr.indexOf(from);
    const toIndex = arr.indexOf(to);
    arr.splice(fromIndex, 1);
    let newToIndex = arr.indexOf(to);
    if (toIndex > fromIndex) newToIndex++; // move AFTER if we're moving down in the order
    arr.splice(newToIndex, 0, from);
}

// @ts-ignore TS7006
export function mapToArray(map) {
    // @ts-ignore TS7034
    const arr = [];
    Object.keys(map).forEach((key) => {
        arr.push(map[key]);
    });
    // @ts-ignore TS7005
    return arr;
}

export function ArrayToMap<T, K extends keyof T>(array: Array<T>, key: K) {
    return new Map(array.map((obj) => [obj[key], obj]));
}
/**
 * We can pass the list of discriminated union key values like __typename and it will return type safe map.
 * @param array
 * @param key
 * @returns Map<keyValue,ListOfValues>
 */
export function groupByKey<T, K extends keyof T>(array: Array<T>, key: K) {
    type KeyValueObject<T, Key extends keyof T> = { [K in T[Key] & string]: Map<K, Array<T & { [x in Key]: K }>> };
    type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
    type DistributedMap<T, K extends keyof T> = UnionToIntersection<KeyValueObject<T, K>[keyof KeyValueObject<T, K>]>;

    const groupedMap = array.reduce((map, obj) => {
        map.get(obj[key])?.push(obj) ?? map.set(obj[key], [obj]);
        return map;
    }, new Map()) as DistributedMap<T, K>;
    return groupedMap;
}

/**
 * Compares two arrays and returns the delta (differences) between them.
 * @param o Old array
 * @param n New array
 * @param key Key used to compare
 * @param comparator Function to determine if objects are different
 */
export function getDelta<T, K extends keyof T>(o: Array<T>, n: Array<T>, key: K, comparator: (a: T, b: T) => boolean) {
    const oldMap = ArrayToMap<T, K>(o, key) as Map<K, T>;
    const newMap = ArrayToMap<T, K>(n, key) as Map<K, T>;

    const delta = {
        added: [] as { key: K; value: T }[],
        deleted: [] as { key: K; value: T }[],
        changed: [] as { key: K; oldValue: T; newValue: T }[],
    };

    const newKeys = new Set(newMap.keys());

    oldMap.forEach((oldValue, oldKey) => {
        newKeys.delete(oldKey);
        const newValue = newMap.get(oldKey);
        if (newValue == undefined) {
            delta.deleted.push({ key: oldKey, value: oldValue });
            return;
        } else if (!comparator(oldValue, newValue)) {
            delta.changed.push({ key: oldKey, oldValue: oldValue, newValue: newValue });
        }
    });

    newKeys.forEach((newKey) => {
        delta.added.push({ key: newKey, value: newMap.get(newKey) as T });
    });

    return delta;
}
