import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogService, DialogParams, HelperService } from '@bx-web/shared-utils';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'bx-web-estimate-measurements-settings',
    templateUrl: './estimate-measurements-settings.component.html',
    styleUrls: ['./estimate-measurements-settings.component.scss'],
    standalone: true,
    imports: [CommonModule, FormsModule, TooltipModule],
})
export class EstimateMeasurementsSettingsComponent {
    @Input() measurementsCount = 0;
    @Input() maxMeasurementsLimit = 0;
    @Input() disabled = false;
    @Input() utmErrorUrl?: string;
    @Output() addMeasurementChanges = new EventEmitter();

    limitExceeded = false;
    #dialogService = inject(DialogService);
    #helperService = inject(HelperService);

    #checkLimit() {
        this.limitExceeded = this.measurementsCount === this.maxMeasurementsLimit;
    }

    public addMeasurement() {
        this.#checkLimit();
        if (!this.limitExceeded) {
            this.addMeasurementChanges.emit();
        } else {
            const params: DialogParams = {
                title: `You’ve just reached the ${this.maxMeasurementsLimit} measurement limit.`,
                allowHtml: true,
                text: '<p>Upgrade to a paid plan to enable unlimited measurements and save them for whenever you need them.</p><p>If you subscribe today you get 14 days of free trial to explore Buildxact.</p>',
                type: 'success',
                cancelText: 'Got it',
                okText: 'Learn More',
            };
            this.#dialogService.show(params).then(
                () => {
                    const url = this.#helperService.getLinkToBXWithUTM(this.utmErrorUrl);
                    window.open(url, '_blank');
                },
                () => ({})
            );
        }
    }
}
