<div [ngStyle]="positionStyle" [class]="'take-off-drawing-details-container ' + selectedDrawingDetails.arrowPosition">
    <div class="arrow-up" *ngIf="selectedDrawingDetails.arrowPosition === 'top'"></div>

    <div class="take-off-drawing-details-card">
        <h5>Edit Shape</h5>

        <div class="take-off-drawing-details-content">
            <div class="form-group">
                <div class="input-group">
                    <input
                        id="drawingDetailsInput"
                        class="form-control"
                        type="text"
                        placeholder="Label..."
                        [formControl]="drawingLabelControl" />
                    <span class="input-group-addon">
                        {{ selectedDrawingDetails.measurement | number : '1.2-2' }} {{ selectedDrawingDetails.unit }}
                    </span>
                </div>
            </div>

            <div class="take-off-drawing-details-settings">
                <div class="form-group" *ngIf="selectedDrawingDetails.isDrawingDepthEnabled">
                    <bx-web-take-off-drawing-depth
                        [shape]="selectedDrawingDetails.shape"
                        [unit]="selectedDrawingDetails.unit"
                        [depth]="selectedDrawingDetails.depth"
                        [depthUnit]="selectedDrawingDetails.depthUnit"
                        (depthChanges)="onDepthChanged($event)"
                        (depthUnitChanges)="onDepthUnitChanged($event)">
                    </bx-web-take-off-drawing-depth>
                </div>

                <div class="form-group">
                    <bx-web-take-off-drawing-color-picker
                        class="mr-1"
                        [selectedColor]="selectedDrawingDetails.color"
                        (colorChanges)="onColorChanged($event)">
                    </bx-web-take-off-drawing-color-picker>

                    <bx-web-take-off-pitch-dropdown
                        *ngIf="config.canDrawLine"
                        class="btn padding-0 take-off-pitch-dropdown"
                        [degrees]="selectedDrawingDetails.pitch"
                        [ratio]="selectedDrawingDetails.pitchRatio"
                        (pitchChanged)="onPitchChanged($event)">
                    </bx-web-take-off-pitch-dropdown>
                </div>
            </div>

            <div class="form-group take-off-drawing-details-actions-container">
                <button type="button" id="drawingDetailsRemove" class="btn btn-danger" (click)="onRemove()">Remove</button>
                <button type="button" id="drawingDetailsClose" class="btn btn-primary" (click)="onClose()">Close</button>
            </div>
        </div>
    </div>

    <div class="arrow-down" *ngIf="selectedDrawingDetails.arrowPosition === 'bottom'"></div>
</div>
