import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { Observable, catchError, throwError } from 'rxjs';

export const initializeThirdParty = (httpClient: HttpClient): (() => Observable<AppConfig>) => {
    return () =>
        httpClient.get<AppConfig>('/assets/config/config.json').pipe(
            tap((config: AppConfig) => {
                window.globalApiEndpoint = config.globalApiEndpoint;
                window.env = config.environment;
                initializeAppInsights(config);
                initializeFullstory(config.environment);
                initializePendo(config.pendoApiKey);
            }),
            catchError((error) => {
                console.error('Error during initialization:', error);
                return throwError(() => new Error(error));
            })
        );
};

const initializeAppInsights = (config: AppConfig): void => {
    if (!config.appInsightsKey) return;

    window.appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: config.appInsightsKey,
            distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
            enableAutoRouteTracking: true,
            enableCorsCorrelation: true,
            disableFetchTracking: false,
            correlationHeaderExcludedDomains: ['*.product.buildxact.com'],
            correlationHeaderDomains: ['*.buildxact.com', '*.buildxact.com.au'],
        },
    });
    window.appInsights.loadAppInsights();
    // send app version on all telemetry
    const telemetryInitializer = (envelope: ITelemetryItem) => {
        if (envelope.baseData) {
            envelope.baseData['properties'] = envelope.baseData['properties'] || {};
            envelope.baseData['properties'].appVersion = config.appVersion;
        }
        if (envelope.tags) {
            envelope.tags['ai.cloud.role'] = 'Takeoff Widget';
        }
    };
    window.appInsights.addTelemetryInitializer(telemetryInitializer);
    window.appInsights.trackPageView();
};

const initializeFullstory = (env: string): void => {
    // insert fullstory scriptlet only for prod
    if (env.toLowerCase() === 'production' || env.toLowerCase() === 'staging') {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'assets/scripts/fullstory.js';
        document.head.appendChild(script);
    }
};

const initializePendo = (pendoApiKey: string): void => {
    if (!pendoApiKey) return;

    const pendoScriptOrigin = 'https://content.product.buildxact.com/agent/static/';
    const script = document.createElement('script');
    script.async = true;
    script.src = `${pendoScriptOrigin}${pendoApiKey}/pendo.js`;
    document.head.appendChild(script);
};

type AppConfig = {
    environment: string;
    appVersion: string;
    appInsightsKey: string;
    globalApiEndpoint: string;
    pendoApiKey: string;
};
