<div *ngIf="plansBreeze$ | async as plansBreeze">
    <bx-web-take-off-v2
        [plansDebreezed]="plans$ | async"
        [plans]="plansBreeze"
        [selectedPlan]="plansBreeze[0] ?? null"
        [estimateId]="estimateId"
        [sendQuoteCount]="sendQuoteCount"
        [sendQuoteLimit]="sendQuoteLimit"
        [measurementLimit]="measurementLimit"
        [originatorRFQ]="originator"
        [sessionId]="sessionId"></bx-web-take-off-v2>
</div>
