import { Injectable } from '@angular/core';
import { finalize, first, map, Observable, of, Subscription, timer } from 'rxjs';
import { EstimateMeasurementApisService, EstimateMeasurementCategoryVM } from './domain';

import { EstimateMeasurementCategoryService } from './estimate-measurement-category.service';
import {
    EstimateMeasurement,
    EstimateMeasurementStoresService,
    EstimateMeasurementVM,
    SharedEstimateMeasurementTakeOffsService,
} from '@bx-web/takeoff-shared';

@Injectable({
    providedIn: 'root',
})
export class EstimateMeasurementAutoSaveService {
    private isAutoSaving = false;
    // @ts-ignore TS2564
    private subWatchAutoSave: Subscription;

    constructor(
        private readonly estimateMeasurementStoresService: EstimateMeasurementStoresService,
        private readonly estimateMeasurementCategoryService: EstimateMeasurementCategoryService,
        private readonly estimateMeasurementTakeOffsService: SharedEstimateMeasurementTakeOffsService,
        private readonly estimateMeasurementApisService: EstimateMeasurementApisService
    ) {
        this.estimateMeasurementStoresService.saveMeasurement$.subscribe((updated) => {
            if (updated) {
                this.autoSave();
            }
        });
    }

    public startAutoSave() {
        if (!this.subWatchAutoSave || this.subWatchAutoSave.closed) {
            this.subWatchAutoSave = timer(0, 10000 * 1).subscribe(() => this.autoSave());
        }
    }

    public completeAutoSave() {
        this.autoSave();
        if (this.subWatchAutoSave && !this.subWatchAutoSave.closed) {
            this.subWatchAutoSave.unsubscribe();
        }
    }

    public autoSave() {
        this.saveMeasurements()
            .pipe(first())
            .subscribe((isSaved: boolean) => {
                if (isSaved) {
                    console.log('Measurements are auto saved');
                }
            });
    }

    public saveMeasurements(): Observable<boolean> {
        if (this.isAutoSaving || this.estimateMeasurementTakeOffsService.isTakeOffUpdating) {
            return of(false);
        }

        this.isAutoSaving = true;

        const changedEstimateMeasurements = this.getChangedEstimateMeasurements();
        if (!changedEstimateMeasurements?.length) {
            this.isAutoSaving = false;
            return of(false);
        }

        const estimateId = this.estimateMeasurementStoresService.estimateId;

        return this.estimateMeasurementApisService.bulkUpsertEstimateMeasurements(estimateId, changedEstimateMeasurements).pipe(
            first(),
            map((result) => {
                const measurements = (<any>result.data).bulkUpsertEstimateMeasurements;

                if (!measurements) {
                    return false;
                }

                this.syncChangedMeasurementCategories(measurements);
                return true;
            }),
            finalize(() => (this.isAutoSaving = false))
        );
    }

    private getChangedEstimateMeasurements(): EstimateMeasurement[] {
        const allCategories = this.estimateMeasurementStoresService.snapshotMeasurementCategories;

        // @ts-ignore TS7034
        const changedEstimateMeasurements = [];

        allCategories.forEach((item: EstimateMeasurementVM) => {
            const estimateMeasurement = this.estimateMeasurementCategoryService.mapToUpsertEstimateMeasurement(item);
            if (estimateMeasurement) {
                changedEstimateMeasurements.push(estimateMeasurement);
            }
        });

        // @ts-ignore TS7005
        return changedEstimateMeasurements;
    }

    private syncChangedMeasurementCategories(measurements: EstimateMeasurement[]) {
        const allCategories = this.estimateMeasurementStoresService.snapshotMeasurementCategories;

        measurements.forEach((changed: EstimateMeasurement) => {
            if (!changed.parentId) {
                this.updateCategoryByEstimateMeasurement(allCategories, changed);
            } else {
                this.updateMeasurementByEstimateMeasurement(allCategories, changed);
            }
        });
    }

    private updateCategoryByEstimateMeasurement(allItems: EstimateMeasurementVM[], changed: EstimateMeasurement) {
        const index = allItems.findIndex((c) =>
            c.id ? c.id === changed.iD_EstimateMeasurement : c.order === changed.key && !c.measurements && !changed.isParent
        );
        if (index < 0) {
            return;
        }

        if (changed.isDeleted) {
            allItems.splice(index, 1);
            return;
        }

        // @ts-ignore TS2532
        const newItem: EstimateMeasurementVM = { ...allItems[index], originalMeasurement: changed };
        allItems.splice(index, 1, newItem);
        // updating store here causes the measurements list to refresh causing the selected input to lose focus
        //this.estimateMeasurementStoresService.measurementCategories = [...allItems];
    }

    private updateMeasurementByEstimateMeasurement(allCategories: EstimateMeasurementCategoryVM[], changed: EstimateMeasurement) {
        const category = allCategories.find((c) => c.id === changed.parentId);
        if (!category) {
            return;
        }

        // @ts-ignore TS2532
        const index = category.measurements.findIndex((m) =>
            m.id ? m.id === changed.iD_EstimateMeasurement : m.parentId === changed.parentId && m.order === changed.key
        );
        if (index < 0) {
            return;
        }

        if (changed.isDeleted) {
            // @ts-ignore TS2532
            category.measurements.splice(index, 1);
            return;
        }

        // @ts-ignore TS2532
        const newMeasurement: EstimateMeasurementVM = {
            // @ts-ignore TS2532
            ...category.measurements[index],
            originalMeasurement: changed,
            // @ts-ignore TS2532
            id: category.measurements[index].id || changed.iD_EstimateMeasurement,
        };
        // @ts-ignore TS2532
        category.measurements.splice(index, 1, newMeasurement);

        const isSelected = this.estimateMeasurementStoresService.snapshotSelectedMeasurement.id === newMeasurement.id;
        if (isSelected) {
            this.estimateMeasurementStoresService.selectedMeasurement = newMeasurement;
        }
    }
}
