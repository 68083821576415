import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { EstimatePlansV2 } from './entities/plans.model';

@Injectable({
    providedIn: 'root',
})
export class EstimatePlansV2StoresService {
    public readonly estimatePlans$: Observable<EstimatePlansV2[]>;
    // @ts-ignore TS2345
    private estimatePlansSource = new BehaviorSubject<EstimatePlansV2[]>(null);
    public get snapshotEstimatePlans(): EstimatePlansV2[] {
        return this.estimatePlansSource.value;
    }
    public get selectedEstimatePlan$(): Observable<EstimatePlansV2> {
        // @ts-ignore TS2322
        return this.estimatePlans$.pipe(map((estimatePlans: EstimatePlansV2[]) => estimatePlans.find((plan) => plan.isSelected)));
    }
    public get snapshotSelectedEstimatePlan(): EstimatePlansV2 {
        // @ts-ignore TS2322
        return this.estimatePlansSource.value?.find((plan) => plan.isSelected);
    }

    public readonly deletedPlanMeasurementIds$: Observable<string[]>;
    // @ts-ignore TS2345
    private deletedPlanMeasurementIdsSource = new BehaviorSubject<string[]>(undefined);
    public set deletedPlanMeasurementIds(deletedPlanMeasurementIds: string[]) {
        this.deletedPlanMeasurementIdsSource.next(deletedPlanMeasurementIds);
    }

    public readonly refreshEstimatePlans$: Observable<boolean>;
    private refreshEstimatePlansSource = new BehaviorSubject<boolean>(false);

    // @ts-ignore TS2564
    private id_estimate: string;
    public get estimateId(): string {
        return this.id_estimate;
    }
    public set estimateId(id: string) {
        this.id_estimate = id;
    }

    constructor() {
        this.estimatePlans$ = this.estimatePlansSource.pipe(
            filter((estimatePlans) => !!estimatePlans),
            distinctUntilChanged()
        );

        this.deletedPlanMeasurementIds$ = this.deletedPlanMeasurementIdsSource.pipe(
            filter((deletedPlanMeasurementIds) => deletedPlanMeasurementIds !== undefined),
            distinctUntilChanged()
        );

        this.refreshEstimatePlans$ = this.refreshEstimatePlansSource.pipe(filter((refresh) => !!refresh));
    }

    public addEstimatePlans(newPlans: EstimatePlansV2[]) {
        const existingPlans = this.snapshotEstimatePlans || [];

        const mergedPlans = [
            ...existingPlans.map((plan) => (plan.isSelected ? { ...plan, isSelected: false } : plan)),
            ...newPlans.map((plan, index) => (index === 0 ? { ...plan, isSelected: true } : plan)),
        ];

        this.estimatePlansSource.next(mergedPlans);
    }

    // @ts-ignore TS2322
    public resetEstimatePlans(newPlans: EstimatePlansV2[], selectedId: string = null) {
        if (!newPlans.some((plan) => plan.iD_Plan === selectedId)) {
            // @ts-ignore TS2322
            selectedId = null;
        }

        const plans = newPlans.map((plan, index) =>
            (selectedId && plan.iD_Plan === selectedId) || (!selectedId && index === 0) ? { ...plan, isSelected: true } : plan
        );

        this.estimatePlansSource.next(plans);
    }

    public updateEstimatePlan(newEstimatePlan: EstimatePlansV2) {
        const estimatePlans = this.estimatePlansSource.value.map((plan) =>
            plan.iD_Plan === newEstimatePlan.iD_Plan
                ? newEstimatePlan
                : { ...plan, isSelected: false, isScaleCopied: newEstimatePlan.isScaleCopied ? false : plan.isScaleCopied }
        );

        this.estimatePlansSource.next(estimatePlans);
    }

    public deleteEstimatePlan(estimatePlan: EstimatePlansV2) {
        // @ts-ignore TS7034
        const estimatePlans = [];

        this.estimatePlansSource.value.reduce((newOrder: number, plan: EstimatePlansV2) => {
            if (plan.iD_Plan === estimatePlan.iD_Plan) {
                return newOrder;
            }

            estimatePlans.push({ ...plan, planOrder2: newOrder });
            return ++newOrder;
        }, 1);

        if (!estimatePlans.length) {
            // @ts-ignore TS7005
            this.estimatePlansSource.next(estimatePlans);
            return;
        }

        // @ts-ignore TS7005
        const newSelectedPlan = { ...estimatePlans[0], isSelected: true };
        // @ts-ignore TS7005
        estimatePlans.splice(0, 1);
        // @ts-ignore TS7005
        const newPlans = [newSelectedPlan, ...estimatePlans];

        this.estimatePlansSource.next(newPlans);
    }

    public selectEstimatePlanById(planId: string) {
        const selectedPlan = this.estimatePlansSource.value.find((plan) => plan.iD_Plan === planId);
        if (!selectedPlan) {
            return;
        }

        this.updateEstimatePlan({ ...selectedPlan, isSelected: true });
    }

    public cleanUpPlans() {
        this.estimatePlansSource.next([]);
    }

    public hasDrawingsByMeasurementId(measurementId: string): boolean {
        return this.snapshotEstimatePlans.some((plan: EstimatePlansV2) =>
            plan.planmeasurements?.some((measurement) => !measurement.isDeleted && measurement.iD_Measurement === measurementId)
        );
    }

    public refreshEstimatePlans() {
        this.refreshEstimatePlansSource.next(true);
    }
}
