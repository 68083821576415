import { Injectable } from '@angular/core';
import { EstimateMeasurementCategoryVM, TakeOffSupportedUnit, TakeOffSystemSettings } from './domain';
import { TakeOffMeasurement, TakeOffMeasurementAdditional, TakeOffService } from '@bx-web/takeoff';
import { EstimateMeasurementCalculatorService } from './estimate-measurement-calculator.service';
import {
    SharedTakeOffStoresService,
    EstimatePlansV2StoresService,
    EstimateMeasurementStoresService,
    EstimateMeasurementVM,
} from '@bx-web/takeoff-shared';
import { Uuid } from '@bx-web/graphql';

@Injectable({
    providedIn: 'root',
})
export class EstimateMeasurementTakeOffsService {
    // #region constructor
    constructor(
        private readonly estimateMeasurementStoresService: EstimateMeasurementStoresService,
        private readonly takeOffService: TakeOffService,
        private estimateMeasurementCalculatorService: EstimateMeasurementCalculatorService,
        private estimatePlansV2StoresService: EstimatePlansV2StoresService,
        private sharedTakeOffStoresService: SharedTakeOffStoresService
    ) {
        this.estimateMeasurementStoresService.supportedUnits = this.takeOffService.getSupportedUnits();
        this.estimateMeasurementStoresService.drawingUpdated$.subscribe((updated) => {
            if (updated) {
                if (this.estimateMeasurementStoresService.isSlowMode) {
                    this.updateDrawingsForMeasurementInSlowMode();
                } else {
                    this.updateDrawingsForMeasurement();
                }
                this.sharedTakeOffStoresService.completeMeasurementUpdate();
            }
        });
    }
    // #endregion constructor

    // #region supported units
    public get takeOffSystemSettings(): TakeOffSystemSettings {
        const systemName = this.takeOffService.getSystem();
        return {
            systemName: systemName,
            showUnits: true,
        };
    }
    // #endregion

    public get supportedUnits(): TakeOffSupportedUnit[] {
        return this.takeOffService.getSupportedUnits();
    }

    public get defaultSquareUnit(): string {
        return this.takeOffService.getDefaultSquareUnit();
    }

    public updateSupportedUnits() {
        this.estimateMeasurementStoresService.supportedUnits = this.takeOffService.getSupportedUnits();
    }
    // #endregion
    // #endregion supported units

    // #region take off
    private updateDrawingsForMeasurementInSlowMode() {
        const selectedMeasurement = this.estimateMeasurementStoresService.snapshotSelectedMeasurement;
        if (!selectedMeasurement) {
            return;
        }

        const takeOffMeasurement = this.sharedTakeOffStoresService.snapshotSelectedMeasurement;
        if (!takeOffMeasurement) return;
        const hasDrawings = this.estimatePlansV2StoresService.hasDrawingsByMeasurementId(takeOffMeasurement.measurementId);

        const changed = {
            ...selectedMeasurement,
            id_PlanMeasurement: !hasDrawings ? null : selectedMeasurement.id_PlanMeasurement || takeOffMeasurement.measurementId,
            isMeasurementShown: hasDrawings,
        };

        // Find measurement index
        const measurements = changed.parentId
            ? this.estimateMeasurementStoresService.getMeasurementCategoryById(changed.parentId).measurements
            : this.estimateMeasurementStoresService.snapshotMeasurementCategories;
        // @ts-ignore TS2532
        const changedIdex = measurements.findIndex((m) => m.id === changed.id);
        // @ts-ignore TS2532
        measurements.splice(changedIdex, 1, changed);

        if (!changed.parentId) {
            // @ts-ignore TS2532
            this.estimateMeasurementStoresService.measurementCategories = measurements;
        }

        // @ts-ignore TS2532
        this.estimateMeasurementStoresService.selectedMeasurement = changed;
    }

    public updateTakeoffsForMeasurementInSlowMode() {
        if (!this.estimateMeasurementStoresService.isSlowMode) {
            return;
        }

        this.updateDrawingsForMeasurement(false);
    }

    private updateDrawingsForMeasurement(needToSelectMeasurement = true) {
        const selectedMeasurement = this.estimateMeasurementStoresService.snapshotSelectedMeasurement;
        if (!selectedMeasurement) {
            return;
        }

        const takeOffMeasurement = this.sharedTakeOffStoresService.snapshotSelectedMeasurement;
        if (!takeOffMeasurement) return;
        const hasDrawings = this.estimatePlansV2StoresService.hasDrawingsByMeasurementId(takeOffMeasurement.measurementId);

        const changed = {
            ...selectedMeasurement,
            quantity: takeOffMeasurement.total,
            // @ts-ignore TS2532
            expression: takeOffMeasurement.total.toString(),
            id_PlanMeasurement: !hasDrawings ? null : selectedMeasurement.id_PlanMeasurement || takeOffMeasurement.measurementId,
            isMeasurementShown: hasDrawings,
        };

        // @ts-ignore TS2345
        const categoryForChanged = this.estimateMeasurementStoresService.getMeasurementCategoryById(changed.parentId);

        this.updateTakeOffedMeasurements(
            categoryForChanged,
            // @ts-ignore TS2345
            changed,
            this.getAdditionalMeasurements(
                takeOffMeasurement,
                // @ts-ignore TS2345
                changed,
                categoryForChanged
                    ? // @ts-ignore TS2532
                      categoryForChanged.measurements.length
                    : this.estimateMeasurementStoresService.snapshotMeasurementCategories.length
            )
        );

        if (needToSelectMeasurement) {
            // @ts-ignore TS2345
            this.estimateMeasurementStoresService.selectedMeasurement = changed;
        }
    }

    private getAdditionalMeasurements(
        takeOffMeasurement: TakeOffMeasurement,
        changed: EstimateMeasurementVM,
        measurementLength: number
    ): EstimateMeasurementVM[] {
        const additions: EstimateMeasurementVM[] = [];
        let order = measurementLength;

        if (!takeOffMeasurement.additionalMeasurements) {
            return additions;
        }

        Object.keys(takeOffMeasurement.additionalMeasurements).forEach((key: string) => {
            // @ts-ignore TS2532
            const addition = takeOffMeasurement.additionalMeasurements[key];

            if (!addition.selected) {
                return;
            }

            additions.push(this.convertAdditionalMeasurement(addition, changed, `${changed.description} ${key}`, ++order));
        });

        return additions;
    }

    private updateTakeOffedMeasurements(
        categoryForChanged: EstimateMeasurementCategoryVM,
        changed: EstimateMeasurementVM,
        addtions: EstimateMeasurementVM[]
    ) {
        let hasListChanged = false;
        const allItems = this.estimateMeasurementStoresService.snapshotMeasurementCategories;

        allItems.forEach((item: EstimateMeasurementVM, itemIndex: number) => {
            if (item.measurements) {
                // Category
                item.measurements.forEach((measurement: EstimateMeasurementVM, index: number) => {
                    if (measurement.id === changed.id) {
                        // @ts-ignore TS2532
                        item.measurements.splice(index, 1, changed);
                        return;
                    }

                    const newQuantity = this.estimateMeasurementCalculatorService.recalculateForMeasurement(
                        measurement,
                        changed,
                        categoryForChanged?.order
                    );

                    if (newQuantity !== measurement.quantity) {
                        // @ts-ignore TS2532
                        item.measurements.splice(index, 1, { ...measurement, quantity: newQuantity });
                    }
                });

                if (item.id === changed.parentId && addtions.length) {
                    // @ts-ignore TS2532
                    item.measurements.push(...addtions);
                }
            } else {
                // Measurement
                if (item.id === changed.id) {
                    allItems.splice(itemIndex, 1, changed);
                    hasListChanged = true;
                    return;
                }

                const newQuantity = this.estimateMeasurementCalculatorService.recalculateForMeasurement(
                    item,
                    changed,
                    categoryForChanged?.order
                );

                if (newQuantity !== item.quantity) {
                    allItems.splice(itemIndex, 1, { ...item, quantity: newQuantity });
                    hasListChanged = true;
                }
            }
        });

        if (!categoryForChanged && addtions.length) {
            allItems.push(...addtions);
            hasListChanged = true;
        }

        if (hasListChanged) {
            this.estimateMeasurementStoresService.measurementCategories = allItems;
        }
    }
    // #endregion take off

    //#region Mapping
    private convertAdditionalMeasurement(
        addition: TakeOffMeasurementAdditional,
        related: EstimateMeasurementVM,
        description: string,
        order: number
    ): EstimateMeasurementVM {
        return {
            // @ts-ignore TS2322
            id: null,
            order: order,
            description: description,
            quantity: addition.total,
            uom: addition.uom,
            expression: addition.total.toString(),
            // @ts-ignore TS2322
            id_PlanMeasurement: null,

            estimateId: this.estimateMeasurementStoresService.estimateId,
            // @ts-ignore TS2322
            originalMeasurement: null,
            parentId: related.parentId,
        };
    }
    //#endregion Mapping

    deleteDrawingsForMeasurement(measurementId: Uuid) {
        this.sharedTakeOffStoresService.deletePlanMeasurement$.next(measurementId);
    }
}
