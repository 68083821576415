<section class="take-off-container" *ngIf="{ config: takeOffConfig, scale: scaleSettings$ | async } as settings">
    <ng-container
        *ngIf="{
            selectedPlan: selectedPlan$ | async,
            selectedMeasurement: selectedMeasurement$ | async,
            drawings: selectedPlanDrawings$ | async,
            isCanvasActivated: isCanvasActivated$ | async
        } as data">
        <header class="takeoff-header-container">
            <h6 class="takeoff-file-name fw-bold">{{ fileName }}</h6>
            <div id="actions">
                <div class="take-off-actions-container">
                    <div class="take-off-actions">
                        <bx-web-take-off-drawing-actions
                            [disabled]="takeOffInprogress"
                            [config]="settings.config"
                            [measurement]="data.selectedMeasurement"
                            (centreChanges)="onCentreCanvas()"
                            (shapeChanges)="onShapeChanged($event)"
                            (colorChanges)="onColorChanged($event)"
                            (pitchChanges)="onPitchChanged($event)"
                            (deductionChanges)="onDeduct()"
                            (depthChanges)="onDepthChanged($event)"
                            (depthUnitChanges)="onDepthUnitChanged($event)">
                        </bx-web-take-off-drawing-actions>
                    </div>

                    <bx-web-take-off-v2-actions
                        [disabled]="takeOffInprogress"
                        [hasDrawings]="(hasDrawingsForSelectedMeasurement$ | async)!"
                        (undoChanges)="onUndoLastDrawing()">
                    </bx-web-take-off-v2-actions>
                </div>
            </div>

            <div class="take-off-user-action" id="user-action">
                @if(originatorRFQ?.hasSupplier){
                <button
                    id="save-measurements"
                    [disabled]="takeOffInprogress"
                    class="btn-bx-secondary-stroked btn-large"
                    (click)="saveMeasurement()">
                    <i class="fa-light fa-floppy-disk save-measurement"></i> Save
                </button>
                }

                <button
                    id="send-qr"
                    type="button"
                    [disabled]="takeOffInprogress"
                    class="btn-bx-primary btn-large send-quote"
                    (click)="sendQuoteRequest()">
                    {{ originatorRFQ?.sendButtonText }}
                </button>
            </div>
        </header>

        <div class="takeoffs-container">
            <div id="plans-container" class="plans-column">
                <ng-scrollbar [visibility]="'hover'">
                    <div class="plans-container">
                        <ul id="plans-list">
                            <li
                                *ngFor="let plan of plans; let i = index"
                                [ngClass]="{ disabled: takeOffInprogress }"
                                (click)="!takeOffInprogress && onSelectedPlanChanged(plan.ID_Plan)"
                                id="plan-{{ i }}">
                                <div
                                    id="plan-preview-{{ i }}"
                                    class="plan-container"
                                    [ngClass]="{ selected: data.selectedPlan?.ID_Plan === plan.ID_Plan }">
                                    <div class="plan-label">Page {{ i + 1 }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-scrollbar>
            </div>

            <div id="estimate-measurement-container" class="estimate-measurement-widget-container" *ngIf="measurementsPanelVisible">
                <bx-web-estimate-measurements-v2
                    [disabled]="takeOffInprogress"
                    [viewSettings]="measurementViewSettings"
                    [measurementLimit]="measurementLimit"
                    [originatorRFQ]="originatorRFQ"></bx-web-estimate-measurements-v2>
            </div>

            <div class="collapse-container">
                <button
                    id="toggle-measurement-panel"
                    type="button"
                    class="btn-collapse"
                    [ngClass]="{ collapsed: !measurementsPanelVisible }"
                    (click)="togglePanel()"></button>
            </div>

            <div
                id="takeoff-canvas-container"
                class="takeoff-canvas-container"
                bxWebClickOutsidePanels
                (appClickOutsidePanels)="saveDrawings()"
                [enableClickOut]="data.selectedMeasurement ? true : false"
                [excludedClasses]="'undo'"
                [clickOutDelay]="false">
                <div class="" *ngIf="data.selectedPlan">
                    <div class="take-off-summary-container">
                        <bx-web-take-off-v2-plan-info
                            [scaleRatio]="data.selectedPlan.ScaleRatio ?? ''"
                            [measurement]="data.selectedMeasurement!">
                        </bx-web-take-off-v2-plan-info>

                        <bx-web-take-off-v2-drawings-info
                            [config]="settings.config"
                            [measurement]="data.selectedMeasurement!"
                            (additionalMeasurementsChanges)="onSelectAdditionalMeasurements($event)">
                        </bx-web-take-off-v2-drawings-info>
                    </div>

                    <bx-web-take-off-canvas
                        #takeOffCanvas
                        [showZoomSlider]="true"
                        [showZoomScaleSlider]="true"
                        [drawingMode]="data.selectedMeasurement ? drawingMode.Multiple : drawingMode.None"
                        [shape]="settings?.config?.shape"
                        [unit]="data.selectedMeasurement?.uom!.toLowerCase()"
                        [horizontalScale]="settings.scale!.horizontalScale"
                        [verticalScale]="settings.scale!.verticalScale"
                        [planScaleUnit]="settings.scale!.planScaleUnit"
                        [showLabels]="settings?.config?.showLabels ?? false"
                        [negative]="settings.config.negative"
                        [color]="settings.config.color"
                        [pitch]="settings.config.pitch"
                        [pitchRatio]="settings.config.pitchRatio"
                        [depth]="settings.config.depth"
                        [depthUnit]="settings.config.depthUnit"
                        [mouseScaleStep]="settings.config.mouseScaleStep"
                        [label]="data.selectedMeasurement?.description"
                        [imageUrl]="data.selectedPlan.PlanUrl"
                        [drawings]="data.drawings!"
                        (drawingStart)="onDrawingStart()"
                        (activatedChanges)="onCanvasActivated()"
                        (drawingContinue)="onDrawingContinue($event.args.drawing)"
                        (drawingFinish)="onDrawingFinish($event.args.drawing)"
                        (drawingRemove)="onDrawingRemove()">
                    </bx-web-take-off-canvas>
                </div>

                <bx-web-take-off-drawing-details
                    *ngIf="selectedDrawingDetails"
                    [selectedDrawingDetails]="selectedDrawingDetails"
                    [config]="settings.config"
                    (drawingChanges)="onDrawingChanged($event)"
                    (drawingRemoved)="onDrawingRemoved()"
                    (switchOff)="onPopoverSwitchOff()">
                </bx-web-take-off-drawing-details>
            </div>
        </div>
    </ng-container>
</section>
