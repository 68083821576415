import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EstimateViewStoresService {
    public estimate$: Observable<EstimateDetails>;
    // @ts-ignore TS2345
    private estimateSource = new BehaviorSubject<EstimateDetails>(null);
    public set estimate(estimate: EstimateDetails) {
        this.estimateSource.next(estimate);
    }
    public get snapshotEstimate(): EstimateDetails {
        return this.estimateSource.value;
    }

    constructor() {
        this.estimate$ = this.estimateSource.pipe(
            filter((estimate) => !!estimate),
            distinctUntilChanged()
        );
    }

    public clearEstimate() {
        // @ts-ignore TS2345
        this.estimateSource.next(null);
    }
}

type EstimateDetails = {
    ID_Estimate: string;
};
