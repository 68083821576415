import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EstimateMeasurementViewSettings, TakeOffSupportedUnit } from '../domain';
import { EstimatePlansTakeOffsStatesV2 } from '../domain/entities/estimate-plans-takeoff-states.model';
import { IsImperial } from '@bx-web/takeoff';
import { NumericInputDirective, SelectAllOnFocusDirective } from '@bx-web/shared-utils';
import { EstimateMeasurementVM } from '@bx-web/takeoff-shared';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { PopoverModule, PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
    selector: 'bx-web-estimate-measurement',
    templateUrl: './estimate-measurement.component.html',
    styleUrls: ['./estimate-measurement.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NumericInputDirective,
        FontAwesomeModule,
        PopoverModule,
        SelectAllOnFocusDirective,
    ],
})
export class EstimateMeasurementComponent implements OnChanges, OnInit {
    // @ts-ignore TS2564
    @Input() hasPlans: boolean;
    // @ts-ignore TS2564
    @Input() states: EstimatePlansTakeOffsStatesV2;
    // @ts-ignore TS2564
    @Input() estimateMeasurement: EstimateMeasurementVM;

    @Input() selectedMeasurement?: EstimateMeasurementVM | null;
    // @ts-ignore TS2564
    @Input() supportedUnits: TakeOffSupportedUnit[];
    // @ts-ignore TS2564
    @Input() viewSettings: EstimateMeasurementViewSettings;
    @Input() parentOrder?: number;
    @Input() disabled = false;

    @Output() updateMeasurementChanges = new EventEmitter<EstimateMeasurementVM>();
    @Output() takeOffsChanges = new EventEmitter<EstimateMeasurementVM>();
    @Output() toggleMeasurementChanges = new EventEmitter<EstimateMeasurementVM>();
    @Output() deleteMeasurementChanges = new EventEmitter<EstimateMeasurementVM>();

    // @ts-ignore TS2564
    public measurementForm: UntypedFormGroup;

    // @ts-ignore TS2564
    public displayedquantity: number;
    // @ts-ignore TS2564
    public isImperial: boolean;

    public isInvalidMeasurement = false;

    // @ts-ignore TS2564
    public order: string;

    // @ts-ignore TS2564
    private expression: string;
    showAddNote = false;

    faEllipsesVertical = faEllipsisVertical;

    @ViewChild('note') private note?: ElementRef<HTMLElement>;
    @ViewChild('pop') private elPopover?: PopoverDirective;

    constructor(private readonly formBuilder: UntypedFormBuilder) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['states']?.currentValue) {
            this.updateMeasurementStates();
        }
    }

    onFocusIn(measurement: EstimateMeasurementVM) {
        if (measurement.id !== this.selectedMeasurement?.id) this.onTakeOffs();
    }

    ngOnInit(): void {
        this.initMeasurementForm();
        this.displayedquantity = this.estimateMeasurement.quantity ?? 0;
        this.isImperial = IsImperial(this.estimateMeasurement.uom ?? '');
        this.expression = this.estimateMeasurement.expression ?? '';
        this.validateMeasurement();
        this.order = this.parentOrder ? `${this.parentOrder}.${this.estimateMeasurement.order}` : this.estimateMeasurement.order.toString();
    }

    private initMeasurementForm() {
        this.measurementForm = this.formBuilder.group({
            description: [
                {
                    value: this.estimateMeasurement.description,
                    disabled: !this.states.canChangeMeasurements,
                },
                { validators: [Validators.required, Validators.maxLength(200)], updateOn: 'blur' },
            ],
            quantity: [
                {
                    value: this.estimateMeasurement.quantity,
                    disabled: !this.states.canChangeMeasurements,
                },
                { validators: [Validators.required] },
            ],
            uom: [
                {
                    value: this.estimateMeasurement.uom,
                    disabled: this.estimateMeasurement.id_PlanMeasurement || !this.states.canChangeMeasurements,
                },
            ],
        });

        // @ts-ignore TS2532
        this.measurementForm.controls.description.valueChanges.subscribe(() => this.onSubmitMeasurement());

        // @ts-ignore TS2532
        this.measurementForm.controls.quantity.valueChanges.subscribe((quantity) => {
            this.expression = quantity.toString();
            this.onSubmitMeasurement();
        });

        // @ts-ignore TS2532
        this.measurementForm.controls.uom.valueChanges.subscribe((uom) => {
            this.isImperial = IsImperial(uom);
            this.onSubmitMeasurement();
        });
    }

    private updateMeasurementStates() {
        if (!this.measurementForm) {
            return;
        }

        if (!this.states.canChangeMeasurements) {
            // @ts-ignore TS2532
            if (this.measurementForm.controls['description'].enable) {
                // @ts-ignore TS2532
                this.measurementForm.controls['description'].disable({ emitEvent: false });
            }

            // @ts-ignore TS2532
            if (this.measurementForm.controls['quantity'].enable) {
                // @ts-ignore TS2532
                this.measurementForm.controls['quantity'].disable({ emitEvent: false });
            }

            // @ts-ignore TS2532
            if (this.measurementForm.controls['uom'].enable) {
                // @ts-ignore TS2532
                this.measurementForm.controls['uom'].disable({ emitEvent: false });
            }
        } else if (this.states.canChangeMeasurements) {
            // @ts-ignore TS2532
            if (this.measurementForm.controls['description'].disabled) {
                // @ts-ignore TS2532
                this.measurementForm.controls['description'].enable({ emitEvent: false });
            }

            // @ts-ignore TS2532
            if (this.measurementForm.controls['quantity'].disabled) {
                // @ts-ignore TS2532
                this.measurementForm.controls['quantity'].enable({ emitEvent: false });
            }

            // @ts-ignore TS2532
            if (this.measurementForm.controls['uom'].disabled && !this.estimateMeasurement.id_PlanMeasurement) {
                // @ts-ignore TS2532
                this.measurementForm.controls['uom'].enable({ emitEvent: false });
            }
        }
    }

    public onquantityChanged(value: string) {
        let quantity = parseFloat(value);
        if (isNaN(quantity)) {
            quantity = 0;
        }

        const newquantity = Math.round(quantity * 1000) / 1000;
        // @ts-ignore TS2532
        this.measurementForm.controls.quantity.setValue(newquantity);
    }

    public onTakeOffs() {
        this.takeOffsChanges.emit({
            ...this.estimateMeasurement,
            isMeasurementShown: !!this.estimateMeasurement.id_PlanMeasurement,
            isInTakeOff: true,
        });
    }

    public onDeleteMeasurement(event: Event) {
        event.stopPropagation();
        this.deleteMeasurementChanges.emit({
            ...this.estimateMeasurement,
            isDeleted: true,
            newOrder: -1,
            isMeasurementShown: false,
        });
    }

    private onSubmitMeasurement() {
        this.validateMeasurement();

        if (this.isInvalidMeasurement) {
            return;
        }

        this.updateMeasurementChanges.emit({
            ...this.estimateMeasurement,
            // @ts-ignore TS2532
            description: this.measurementForm.controls.description.value,
            // @ts-ignore TS2532
            quantity: this.measurementForm.controls.quantity.value,
            // @ts-ignore TS2532
            uom: this.measurementForm.controls.uom.value,
            expression: this.expression,
            isInTakeOff: this.viewSettings.isOverLayer,
            note: this.note?.nativeElement.innerText ?? null,
            isMeasurementShown: true,
        });
    }

    private validateMeasurement() {
        // @ts-ignore TS2532
        this.isInvalidMeasurement = this.measurementForm.controls.description.invalid || this.measurementForm.controls.quantity.invalid;
    }

    addNote() {
        this.showAddNote = true;
        setTimeout(() => {
            this.note?.nativeElement?.focus();
            this.elPopover?.hide();
        });
    }

    deleteNote() {
        this.updateMeasurementChanges.emit({
            ...this.estimateMeasurement,
            description: this.measurementForm.controls['description']?.value,
            quantity: this.measurementForm.controls['quantity']?.value,
            uom: this.measurementForm.controls['uom']?.value,
            expression: this.expression,
            isInTakeOff: this.viewSettings.isOverLayer,
            note: null,
            isMeasurementShown: true,
        });
    }

    onNoteChange(note: string) {
        this.showAddNote = false;
        if (note === this.estimateMeasurement.note) return;
        this.onSubmitMeasurement();
    }
}
