import { Injectable } from '@angular/core';
import { Uuid } from '@bx-web/graphql';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppStoreService {
    currentSession = new CurrentSession();

    clearSession() {
        this.currentSession.set(null);
    }
}

class CurrentSession {
    #source = new BehaviorSubject<UserSession | null>(null);
    readonly obs$ = this.#source.asObservable();
    set(val: UserSession | null) {
        this.#source.next(val);
    }
    value() {
        return this.#source.value;
    }
}

export type UserSession = {
    sessionId: Uuid;
    estimateID: Uuid;
    tenantId: Uuid;
    useId: Uuid;
    hasPlans?: boolean;
    sendQuoteCount: number;
    measurementLimit: number;
    planMeasurementLimit: number;
    sendQuoteLimit: number;
    pagesLimit: number;
    originator?: Originator | null;
};

export type Originator = {
    locationExample?: string | null;
    locationTitle?: string | null;
    refFieldName?: string | null;
    refFieldName2?: string | null;
    refFieldRegEx?: string | null;
    refFieldRegEx2?: string | null;
    requireRef: boolean;
    requireRef2: boolean;
    sendQuoteDescription?: string | null;
    sendQuoteEmail?: string | null;
    sendQuoteTitle?: string | null;
    originatorName?: string | null;
    originator?: string | null;
    hasPartner: boolean;
    hasSupplier: boolean;
    home1Text?: string | null;
    home1Title?: string | null;
    home2Text?: string | null;
    home2Title?: string | null;
    home3Text?: string | null;
    home3Title?: string | null;
    homeErrorUtm?: string | null;
    homeMainText?: string | null;
    homeMainTitle?: string | null;
    homeUtm?: string | null;
    partnerText?: string | null;
    partnerUrl?: string | null;
    partnerWidth: number;
    sendButtonText?: string | null;
    signUpUtm?: string | null;
    videoUrl?: string | null;
};
