import { Injectable } from '@angular/core';
import { BreezePlans, Plan } from './domain/types/plan.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
    providedIn: 'root',
})
export class PlansStoreService {
    currentBreezePlans = new CurrentBreezePlans();
    currentPlans = new CurrentPlans();

    clearPlans() {
        this.currentBreezePlans.set(null);
    }
}

class CurrentBreezePlans {
    #source = new BehaviorSubject<BreezePlans[] | null>(null);
    readonly obs$ = this.#source.asObservable();
    set(val: BreezePlans[] | null) {
        this.#source.next(val);
    }
    get value() {
        return this.#source.value;
    }
}

class CurrentPlans {
    #source = new BehaviorSubject<Plan[] | null>(null);
    readonly obs$ = this.#source.asObservable();
    set(val: Plan[] | null) {
        this.#source.next(val);
    }
    get value() {
        return this.#source.value;
    }
}
