import { Route } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PlanComponent } from './plans/plan.component';
import { TakeoffsWelcomeComponent } from './welcome/takeoffs-welcome.component';
import { MeasurementsComponent } from './measurements/measurements.component';
import { ErrorComponent } from './error/error.component';
import { canDeactivateFn } from './route-guards/deactivate-route-guard';
import { hasUploadedPlans, sessionGuard } from './route-guards/activate-route-guard';

export const appRoutes: Route[] = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'import',
        pathMatch: 'full',
        canActivate: [sessionGuard()],
        component: TakeoffsWelcomeComponent,
    },
    {
        path: 'scale',
        canActivate: [sessionGuard(), hasUploadedPlans()],
        canDeactivate: [canDeactivateFn],
        component: PlanComponent,
    },
    {
        path: 'measurements',
        canActivate: [sessionGuard(), hasUploadedPlans()],
        canDeactivate: [canDeactivateFn],
        component: MeasurementsComponent,
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
];
