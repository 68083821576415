import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
    EstimateDragItemTypes,
    EstimateMeasurementCategoryVM,
    EstimateMeasurementViewSettings,
    slideInTopParams,
    TakeOffSupportedUnit,
    TakeOffSystemSettings,
} from './domain';
import { EstimateMeasurementAutoSaveService } from './estimate-measurement-auto-save.service';
import { EstimateMeasurementTakeOffsService } from './estimate-measurement-take-offs.service';
import { EstimateMeasurementsService } from './estimate-measurements.service';
import { EstimateMeasurementsSettingsComponent } from './ui-estimate-measurements-settings/estimate-measurements-settings.component';
import { EstimatePlansTakeOffsStatesV2 } from './domain/entities/estimate-plans-takeoff-states.model';
import { EstimateMeasurementComponent } from './ui-estimate-measurement/estimate-measurement.component';
import { EstimateMeasurementStoresService, EstimateMeasurementDrawingStatus, EstimateMeasurementVM } from '@bx-web/takeoff-shared';
import { EstimateMeasurementCategoryService } from './estimate-measurement-category.service';
import { Uuid } from '@bx-web/graphql';
import { UiHelpTileComponent } from './ui-help-tile/ui-help-tile.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Originator } from '@bx-web/shared-utils';

@Component({
    selector: 'bx-web-estimate-measurements-v2',
    templateUrl: './estimate-measurements-v2.component.html',
    styleUrls: ['./estimate-measurements-v2.component.scss'],
    standalone: true,
    imports: [CommonModule, EstimateMeasurementsSettingsComponent, EstimateMeasurementComponent, UiHelpTileComponent, NgScrollbarModule],
})
export class EstimateMeasurementsV2Component implements OnInit, OnDestroy, OnChanges {
    // @ts-ignore TS2564
    @Input() viewSettings: EstimateMeasurementViewSettings;
    @Input() measurementLimit = 0;
    @Input() disabled = false;
    @Input() originatorRFQ?: Originator | null;

    // @ts-ignore TS2564
    public states$: Observable<EstimatePlansTakeOffsStatesV2>;
    // @ts-ignore TS2564
    public hasPlans$: Observable<boolean>;
    // @ts-ignore TS2564
    public measurementCategories$: Observable<EstimateMeasurementCategoryVM[]>;
    // @ts-ignore TS2564
    public supportedUnits$: Observable<TakeOffSupportedUnit[]>;
    // @ts-ignore TS2564
    public supportedUnits: TakeOffSupportedUnit[];

    // @ts-ignore TS2564
    public measurementsDrawingStatus$: Observable<EstimateMeasurementDrawingStatus>;

    // @ts-ignore TS2564
    public takeOffSystemSettings: TakeOffSystemSettings;

    public isDragDisabled = true;

    public readonly slideInTopParams = slideInTopParams['measurement-list'];
    public readonly dragItemTypes = EstimateDragItemTypes;

    selectedMeasurement$ = this.estimateMeasurementStoresService.selectedMeasurement$;

    constructor(
        private readonly estimateMeasurementsService: EstimateMeasurementsService,
        private readonly estimateMeasurementTakeOffsService: EstimateMeasurementTakeOffsService,
        private readonly estimateMeasurementAutoSaveService: EstimateMeasurementAutoSaveService,
        private readonly estimateMeasurementStoresService: EstimateMeasurementStoresService,
        private estimateMeasurementCategoryService: EstimateMeasurementCategoryService
    ) {}

    ngOnInit(): void {
        this.takeOffSystemSettings = this.estimateMeasurementTakeOffsService.takeOffSystemSettings;

        this.hasPlans$ = of(true);
        this.states$ = of({
            canChangeMeasurements: true,
        });
        this.measurementCategories$ = this.estimateMeasurementStoresService.measurementCategories$;
        this.supportedUnits$ = this.estimateMeasurementStoresService.supportedUnits$;
        this.measurementsDrawingStatus$ = this.estimateMeasurementStoresService.measurementsDrawingStatus$;

        if (!this.viewSettings.isOverLayer) {
            this.estimateMeasurementAutoSaveService.startAutoSave();
        } else {
            this.estimateMeasurementAutoSaveService.completeAutoSave();
        }
    }

    ngOnDestroy(): void {
        if (!this.viewSettings.isOverLayer) {
            this.estimateMeasurementAutoSaveService.completeAutoSave();
        } else {
            // Update current measurement for take offs before move to another measurement
            this.estimateMeasurementTakeOffsService.updateTakeoffsForMeasurementInSlowMode();
            // enable auto save for take off measurement screen
            this.estimateMeasurementAutoSaveService.startAutoSave();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled']) {
            const newvalue = changes['disabled'].currentValue;
            this.states$ = of({
                canChangeMeasurements: !newvalue,
            });
        }
    }

    //#region Measurement
    public onAddMeasurement() {
        if (this.viewSettings.isOverLayer) {
            // Update current measurement for take offs before move to another measurement
            this.estimateMeasurementTakeOffsService.updateTakeoffsForMeasurementInSlowMode();
        }

        const measurement = this.estimateMeasurementsService.generateMeasurement();
        this.estimateMeasurementsService.updateMeasurement(measurement);
        this.createMeasurement(measurement);
    }

    private createMeasurement(newMeasurement: EstimateMeasurementVM) {
        this.estimateMeasurementsService.createMeasurement(newMeasurement);
    }

    public onMeasurementUpdated(newMeasurement: EstimateMeasurementVM) {
        if (this.viewSettings.isOverLayer) {
            // Update current measurement for take offs before move to another measurement
            this.estimateMeasurementTakeOffsService.updateTakeoffsForMeasurementInSlowMode();
        }

        if (!newMeasurement.originalMeasurement) {
            if (this.viewSettings.isOverLayer) {
                this.estimateMeasurementAutoSaveService.autoSave();
            }
            this.estimateMeasurementsService.createMeasurement(newMeasurement);
        } else {
            this.estimateMeasurementsService.updateMeasurement(newMeasurement);
            if (this.viewSettings.isOverLayer) {
                this.estimateMeasurementAutoSaveService.autoSave();
            }
        }
    }

    public onDelete(measurement: EstimateMeasurementVM) {
        this.deleteMeasurementCategory(measurement);
    }

    private deleteMeasurementCategory(measurementCategory: EstimateMeasurementVM) {
        this.estimateMeasurementCategoryService
            .deleteMeasurementCategory(measurementCategory, this.viewSettings.isOverLayer)
            .pipe(
                filter((isDeleted: boolean) => !!isDeleted && this.viewSettings.isOverLayer),
                take(1)
            )
            .subscribe(() => {
                if (measurementCategory.id_PlanMeasurement)
                    this.estimateMeasurementTakeOffsService.deleteDrawingsForMeasurement(measurementCategory.id_PlanMeasurement as Uuid);
                this.estimateMeasurementAutoSaveService.autoSave();
            });
    }

    public onTakeOffs(measurement: EstimateMeasurementVM) {
        if (this.viewSettings.isOverLayer) {
            // Update current measurement for take offs before move to another measurement
            this.estimateMeasurementTakeOffsService.updateTakeoffsForMeasurementInSlowMode();
        }

        // update the description to avoid the api to invoke save to update description
        if (measurement.originalMeasurement && measurement.originalMeasurement.description !== measurement.description) {
            const originalMeasurement = { ...measurement.originalMeasurement, description: measurement.description };
            measurement = { ...measurement, originalMeasurement };
        }
        // update the measurement category with isMeasurementShown
        const allItems = this.estimateMeasurementStoresService.snapshotMeasurementCategories;
        const index = allItems.findIndex((mes) => mes.id === measurement.id);
        if (index !== -1) allItems[index] = measurement;
        this.estimateMeasurementStoresService.selectedMeasurement = measurement;

        const isCompleteAutoSave = this.viewSettings.isOverLayer;

        if (isCompleteAutoSave) {
            this.estimateMeasurementAutoSaveService.autoSave();
        }
    }

    public toggleTakeOffsForMeasurement(measurement: EstimateMeasurementVM) {
        if (this.viewSettings.isOverLayer) {
            // Update current measurement for take offs before move to another measurement
            this.estimateMeasurementTakeOffsService.updateTakeoffsForMeasurementInSlowMode();
        }

        this.estimateMeasurementsService.updateMeasurement(measurement);

        if (this.viewSettings.isOverLayer) {
            this.estimateMeasurementAutoSaveService.autoSave();
        }
    }
}
