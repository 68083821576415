import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'bx-web-take-off-drawing-color-picker',
    templateUrl: './take-off-drawing-color-picker.component.html',
    styleUrls: ['./take-off-drawing-color-picker.component.scss'],
    standalone: true,
    imports: [CommonModule, TooltipModule, PopoverModule],
})
export class TakeOffDrawingColorPickerComponent {
    @Input() selectedColor!: string;
    @Input() disabled = false;
    @Output() colorChanges = new EventEmitter<string>();

    public readonly drawingColors = [
        '#FF0000',
        '#FFA500',
        '#FFFF00',
        '#9ACD32',
        '#90EE90',
        '#008000',
        '#7FFFD4',
        '#00FFFF',
        '#ADD8E6',
        '#0000FF',
        '#00008B',
        '#9400D3',
        '#EE82EE',
        '#800080',
        '#FFC0CB',
    ];

    public onColorChanged(color: string) {
        this.colorChanges.emit(color);
    }
}
