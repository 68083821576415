import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export interface BxLoggerEvent {
    name: string;
    properties?: {
        [key: string]: any;
    };
}

export interface BxLoggerMetric {
    name: string;
    average: number;
}

declare global {
    interface Window {
        appInsights: ApplicationInsights;
    }
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
    exception(error: any) {
        window.appInsights.trackException({ exception: error });
    }

    event(event: BxLoggerEvent) {
        window.appInsights.trackEvent(event);
    }

    metric(metric: BxLoggerMetric) {
        window.appInsights.trackMetric(metric);
    }

    /**
     * Technically, there is no need to call this as AppInsights SDK automatically
     * sends data at certain intervals, or when the buffer is full, and also
     * listens to the 'beforeunload' page event and auto flushes queued events and metrics.
     * This exists purely for those who want to be in control.
     */
    flush() {
        window.appInsights.flush(true);
    }

    /** Logs the given message to the console only */
    log(message: any) {
        console.log(message);
    }
}
