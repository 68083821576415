import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import * as TakeOff from '../core/index';
import { PitchChangedEventArgs } from './pitch-changed.model';
import { SelectAllOnFocusDirective } from '@bx-web/shared-utils';
@Component({
    selector: 'bx-web-take-off-pitch-dropdown',
    templateUrl: './pitch-dropdown.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, TooltipModule, PopoverModule, SelectAllOnFocusDirective],
})
export class TakeOffPitchDropdownComponent implements OnChanges {
    @Input() degrees = 0;
    @Input() ratio = '';
    @Input() disabled = false;
    @Output() pitchChanged: EventEmitter<PitchChangedEventArgs> = new EventEmitter<PitchChangedEventArgs>();

    // @ts-ignore TS2322
    ratioX: string = null;
    // @ts-ignore TS2322
    ratioY: string = null;
    // @ts-ignore TS2322
    ratioError: string = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ratio']) {
            const newRatio = changes['ratio'].currentValue;
            if (TakeOff.PitchFactors.isValidRatio(newRatio)) {
                this.ratioX = newRatio.split(':')[0];
                this.ratioY = newRatio.split(':')[1];
            } else {
                // @ts-ignore TS2322
                this.ratioX = null;
                // @ts-ignore TS2322
                this.ratioY = null;
            }
        }
    }

    onDegreesChanged = () => {
        this.degrees = +this.degrees || 0;
        // @ts-ignore TS2322
        this.ratioX = null;
        // @ts-ignore TS2322
        this.ratioY = null;
        // @ts-ignore TS2322
        this.pitchChanged.emit({ degrees: this.degrees, ratio: null });
    };

    onRatioXYChanged = () => {
        if (!this.ratioX && !this.ratioY) {
            this.ratioError = '';
            return;
        }
        this.ratio = `${this.ratioX}:${this.ratioY}`;
        if (!TakeOff.PitchFactors.isValidRatio(this.ratio)) {
            this.ratioError = 'Rise & run must be 2 whole numbers.';
        } else {
            this.ratioError = '';
            this.degrees = TakeOff.PitchFactors.ratioToDegrees(this.ratio);
            this.pitchChanged.emit({ degrees: this.degrees, ratio: this.ratio });
        }
    };
}
