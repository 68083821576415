import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { TakeOffMeasurement, IsCubic, IsSquare, Drawing, DrawingShape, TakeOff } from '@bx-web/takeoff';
import { TakeOffDrawingDetails } from './domain';
import { TakeOffV2MeasurementService } from './take-off-v2-measurement.service';
import {
    EstimateMeasurementStoresService,
    EstimatePlansV2StoresService,
    PlanMeasurement,
    SharedTakeOffStoresService,
} from '@bx-web/takeoff-shared';
import { AddPlanInput, TakeoffMeasurementsApiService, UpdatePlanMeasurementInput } from './domain/apis/takeoff-measurements-api.service';
import { Uuid } from '@bx-web/graphql';

@Injectable({
    providedIn: 'root',
})
export class TakeOffV2DrawingService {
    public get hasDrawingsForSelectedMeasurement$(): Observable<boolean> {
        return combineLatest([this.takeOffV2StoresService.selectedMeasurement$, this.takeOffV2StoresService.selectedPlan$]).pipe(
            map(([selectedMeasurement, selectedPlan]: [TakeOffMeasurement | null, any | null]) => {
                if (!selectedMeasurement || !selectedPlan) {
                    return false;
                }
                return selectedPlan.planmeasurements?.some((pm: any) => pm.iD_Measurement === selectedMeasurement.measurementId);
            })
        );
    }

    constructor(
        private readonly takeOffV2StoresService: SharedTakeOffStoresService,
        private readonly takeOffV2MeasurementService: TakeOffV2MeasurementService,
        private readonly estimateMeasurementStoresService: EstimateMeasurementStoresService,
        private readonly takeoffMeasurementsApiService: TakeoffMeasurementsApiService,
        private readonly estimatePlansV2StoresService: EstimatePlansV2StoresService
    ) {
        this.takeOffV2StoresService.deletePlanMeasurement$.subscribe((measurementId) => {
            if (measurementId) this.removeAllDrawingsForMeasurement(measurementId);
        });

        this.takeOffV2StoresService.addPlanMeasurement$.subscribe((add) => {
            if (add) this.addPlanMeasurement();
        });
    }

    public getDrawingsForPlan(): Drawing[] {
        const selectedPlan = this.takeOffV2StoresService.snapshotSelectedPlan;
        if (!selectedPlan) {
            return [];
        }
        const drawings: any[] = this.takeOffV2MeasurementService.getPlanDrawings([selectedPlan], null)[0]?.drawings ?? [];
        const measurements: any[] = this.estimateMeasurementStoresService.snapshotEstimateMeasurementsWithDrawings;
        return drawings.map((drawing: Drawing) => this.mapToDrawing(drawing, selectedPlan, measurements));
    }

    public updateDrawingByDetails(drawingDetails: TakeOffDrawingDetails): number {
        const drawing = this.takeOffV2StoresService.snapshotSelectedPlanDrawings.find((d) => d.id === drawingDetails.id);
        if (!drawing) {
            // @ts-ignore TS2322
            return;
        }

        const needToRecalculate =
            drawing.depth !== drawingDetails.depth ||
            drawing.depthUnit !== drawingDetails.depthUnit ||
            drawing.pitch !== drawingDetails.pitch;

        const newDrawing = new Drawing({
            ...drawing,
            label: drawingDetails.label,
            color: drawingDetails.color,
            pitch: drawingDetails.pitch,
            pitchRatio: drawingDetails.pitchRatio,
            depth: drawingDetails.depth,
            depthUnit: drawingDetails.depthUnit,
        });

        if (needToRecalculate) {
            newDrawing.calculateMeasurement();
        }

        this.takeOffV2MeasurementService.updateMeasurementForDrawing(newDrawing);
        this.takeOffV2StoresService.updateDrawing(newDrawing);

        return newDrawing.measurement;
    }

    public removeDrawingById(drawingId: string) {
        const index = this.takeOffV2StoresService.snapshotSelectedPlanDrawings.findIndex((drawing) => drawing.id === drawingId);
        if (index < 0) {
            return;
        }

        // @ts-ignore TS2345
        this.takeOffV2MeasurementService.updateMeasurementForDrawing(this.takeOffV2StoresService.snapshotSelectedPlanDrawings[index], true);
        this.removeDrawingFromPlan(index);
    }

    private removeDrawingFromPlan(index: number) {
        const measurementId = this.takeOffV2StoresService.snapshotSelectedPlanDrawings[index]?.id as Uuid;
        if (measurementId)
            this.takeoffMeasurementsApiService
                .deleteMeasurementsforPlan([measurementId])
                .pipe(first())
                .subscribe((res) => {
                    if (res.data?.deletePlanMeasurements) {
                        this.takeOffV2StoresService.snapshotSelectedPlanDrawings.splice(index, 1);
                        this.#updateStoresPostDelete(measurementId);
                        this.takeOffV2StoresService.completePlanUpdate();
                    }
                });
    }

    #updateStoresPostDelete(measurementId: Uuid) {
        const selectedPlan = this.estimatePlansV2StoresService.snapshotSelectedEstimatePlan;
        const planIndex = selectedPlan.planmeasurements?.findIndex((mes) => mes.iD_PlanMeasurement === measurementId) ?? -1;
        if (planIndex > -1) {
            selectedPlan.planmeasurements?.splice(planIndex, 1);
            this.estimatePlansV2StoresService.updateEstimatePlan({ ...selectedPlan, isSelected: true });
        }

        const selectedTakeOffPlan = this.takeOffV2StoresService.snapshotSelectedPlan;
        if (selectedTakeOffPlan) {
            const deletedDrawingIndex =
                selectedTakeOffPlan.planmeasurements?.findIndex((mes) => mes.iD_PlanMeasurement === measurementId) ?? -1;
            if (deletedDrawingIndex > -1) {
                selectedTakeOffPlan.planmeasurements?.splice(deletedDrawingIndex, 1);
                this.takeOffV2StoresService.selectedPlan = selectedTakeOffPlan;
                this.takeOffV2StoresService.updateEstimatePlans(selectedTakeOffPlan);
            }
        }
    }

    private removeAllDrawingsForMeasurement(measurementId: Uuid) {
        const ids =
            this.takeOffV2StoresService.snapshotSelectedPlan?.planmeasurements
                ?.filter((drawing) => drawing.iD_Measurement === measurementId)
                .map((drawing) => drawing.iD_PlanMeasurement) ?? [];
        if (ids.length)
            this.takeoffMeasurementsApiService
                .deleteMeasurementsforPlan(ids)
                .pipe(first())
                .subscribe((res) => {
                    if (res.data?.deletePlanMeasurements) {
                        const updatedList = this.takeOffV2StoresService.snapshotSelectedPlanDrawings.filter((drw) => !ids.includes(drw.id));
                        this.takeOffV2StoresService.selectedPlanDrawings = [...updatedList];
                    }
                });
    }

    public removeLastDrawing() {
        if (this.takeOffV2StoresService.snapshotSelectedPlan) {
            const planMeasurements = this.takeOffV2MeasurementService.findPlanMeasurementsByMeasurementId(
                this.takeOffV2StoresService.snapshotSelectedPlan,
                this.takeOffV2StoresService.snapshotSelectedMeasurement?.measurementId ?? ''
            );

            const index = planMeasurements.length - 1;
            if (index >= 0) {
                this.removeDrawingById(planMeasurements[index].iD_PlanMeasurement);
            }
        }
    }

    public isDrawingDepthEnabled(drawing: Drawing): boolean {
        return IsCubic(drawing.unit) || (drawing.shape === DrawingShape.Line && IsSquare(drawing.unit));
    }

    //#region Mapping
    private mapToDrawing(drawing: Drawing, selectedPlan: any, measurements: any[]): Drawing {
        const planMeasurement = this.takeOffV2MeasurementService.findPlanMeasurementByDrawingId(selectedPlan, drawing.id);

        const takeOffDrawing = new Drawing({
            ...drawing,
            isHidden:
                planMeasurement && !measurements.find((m) => m.id_PlanMeasurement === planMeasurement.iD_Measurement)?.isMeasurementShown,
        });

        return takeOffDrawing;
    }

    public mapToDrawingDetails(drawing: Drawing): TakeOffDrawingDetails {
        const position = drawing.labelObject.getAbsolutePosition();
        return {
            id: drawing.id,
            measurement: drawing.measurement,
            unit: drawing.unit,
            shape: drawing.shape,

            label: drawing.label,
            color: drawing.color,
            pitch: drawing.pitch,
            pitchRatio: drawing.pitchRatio,
            depth: drawing.depth,
            depthUnit: drawing.depthUnit,

            positionX: Math.round(position.x),
            positionY: Math.round(position.y),
            arrowPosition: position.y > 500 ? 'bottom' : 'top',

            isDrawingDepthEnabled: this.isDrawingDepthEnabled(drawing),
        };
    }
    //#endregion Mapping

    addPlanMeasurement() {
        const selectedDrawings = this.takeOffV2StoresService.snapshotSelectedPlanDrawings.filter((drawing) => !drawing.id);
        const planMeasurements = this.#getAddPlanInput(selectedDrawings);
        if (planMeasurements.length) {
            this.takeoffMeasurementsApiService
                .addMeasurementforPlan(planMeasurements)
                .pipe(first())
                .subscribe((res) => {
                    const addedPlanMeasurements = res.data?.addPlanMeasurements || [];
                    if (addedPlanMeasurements.length > 0) {
                        this.#postAddPlanMeasurement(addedPlanMeasurements as PlanMeasurement[]);
                    }
                });
        }
    }

    #postAddPlanMeasurement(addedPlanMeasurements: PlanMeasurement[]) {
        const [planMeasurement] = addedPlanMeasurements;
        const selectedPlan = this.estimatePlansV2StoresService.snapshotSelectedEstimatePlan;
        selectedPlan.planmeasurements = planMeasurement ? [...(selectedPlan.planmeasurements || []), planMeasurement] : [];
        this.estimatePlansV2StoresService.updateEstimatePlan({ ...selectedPlan, isSelected: true });
        const selectedTakeOffPlan = this.takeOffV2StoresService.snapshotSelectedPlan;
        if (selectedTakeOffPlan) {
            selectedTakeOffPlan.planmeasurements = planMeasurement
                ? [...(selectedTakeOffPlan.planmeasurements || []), planMeasurement]
                : [];
            this.takeOffV2StoresService.selectedPlan = { ...selectedTakeOffPlan };
            this.takeOffV2StoresService.updateEstimatePlans(selectedTakeOffPlan);
        }
        this.takeOffV2StoresService.completePlanUpdate();
    }

    #getAddPlanInput(selectedDrawings: TakeOff.Drawing[]): AddPlanInput[] {
        const planId = this.takeOffV2StoresService.snapshotSelectedPlan?.ID_Plan ?? '';
        const measurement = this.takeOffV2StoresService.snapshotSelectedMeasurement;
        const planMeasurements: AddPlanInput[] = [];
        selectedDrawings.forEach((selectedDrawing) => {
            if (selectedDrawing) {
                const planMeasurement = {
                    color: selectedDrawing.color,
                    depth: selectedDrawing.depth,
                    depthUnit: selectedDrawing.depthUnit,
                    description: selectedDrawing.label,
                    iD_Measurement: measurement?.measurementId as Uuid,
                    iD_Plan: planId as Uuid,
                    isNegative: selectedDrawing.isNegative,
                    measurement: selectedDrawing.measurement,
                    measurementUnit: selectedDrawing.unit,
                    pitch: selectedDrawing.pitch,
                    points: selectedDrawing.pointsToString(),
                    position: this._getStringFromPosition(selectedDrawing.position),
                    shapeType: selectedDrawing.shape,
                };
                if (selectedDrawing.width && selectedDrawing.width !== 0 && selectedDrawing.height && selectedDrawing.height !== 0) {
                    planMeasurement.points = selectedDrawing.width.toString() + ',' + selectedDrawing.height.toString();
                }
                planMeasurements.push(planMeasurement);
            }
        });
        return planMeasurements;
    }

    private _getStringFromPosition(val: { x: number; y: number }) {
        if (val?.x && val?.y) return val.x.toString() + ',' + val.y.toString();
        else return '';
    }

    updateDrawingLabel(planMeasurementId: string, newLabel: string) {
        const drawings = this.takeOffV2MeasurementService.getDrawingsForMeasurement(planMeasurementId);
        const updatedPlanMeasurements = this.#getPlanMeasurementUpdateInModel(drawings, newLabel);
        this.takeoffMeasurementsApiService
            .updateMeasurementsforPlan(updatedPlanMeasurements)
            .pipe(
                first(),
                catchError((err) => {
                    throw err;
                })
            )
            .subscribe((res) => {
                if (res.data?.updatePlanMeasurements) {
                    const updatedDrawingIds = (res.data.updatePlanMeasurements || [])
                        .map((mes) => mes?.iD_PlanMeasurement)
                        .filter((id): id is Uuid => !!id);
                    if (updatedDrawingIds.length) this.#updateDescription(updatedDrawingIds, newLabel);
                }
                if (res.errors) {
                    throw res.errors[0];
                }
            });
    }

    #getPlanMeasurementUpdateInModel(drawings: Drawing[], newLabel: string): UpdatePlanMeasurementInput[] {
        const updatedPlanMeasurements: UpdatePlanMeasurementInput[] = [];
        drawings.forEach((drawing) => {
            const updateMes: UpdatePlanMeasurementInput = {
                color: drawing.color,
                depth: drawing.depth,
                depthUnit: drawing.depthUnit,
                description: newLabel,
                isNegative: drawing.isNegative,
                measurement: drawing.measurement,
                measurementUnit: drawing.unit,
                pitch: drawing.pitch,
                points: drawing.pointsToString(),
                position: this._getStringFromPosition(drawing.position),
                shapeType: drawing.shape,
                iD_PlanMeasurement: drawing.id as Uuid,
            };
            updatedPlanMeasurements.push(updateMes);
        });
        return updatedPlanMeasurements;
    }

    #updateDescription(updatedDrawings: Uuid[], newLabel: string) {
        const updatedList = this.takeOffV2StoresService.snapshotSelectedPlanDrawings.map((drw) => {
            if (updatedDrawings.includes(drw.id)) {
                drw.label = newLabel;
            }
            return drw;
        });
        this.takeOffV2StoresService.selectedPlanDrawings = [...updatedList];

        //update plan store
        const selectedPlan = this.estimatePlansV2StoresService.snapshotSelectedEstimatePlan;
        if (selectedPlan) {
            selectedPlan.planmeasurements?.forEach((mes) => {
                if (updatedDrawings.includes(mes.iD_PlanMeasurement)) {
                    mes.description = newLabel;
                }
            });
            this.estimatePlansV2StoresService.updateEstimatePlan({ ...selectedPlan, isSelected: true });
        }

        //update takeoff store
        const selectedTakeOffPlan = this.takeOffV2StoresService.snapshotSelectedPlan;
        if (selectedTakeOffPlan) {
            selectedTakeOffPlan.planmeasurements?.forEach((mes) => {
                if (updatedDrawings.includes(mes.iD_PlanMeasurement)) {
                    mes.description = newLabel;
                }
            });
            this.takeOffV2StoresService.selectedPlan = { ...selectedTakeOffPlan };
            this.takeOffV2StoresService.updateEstimatePlans(selectedTakeOffPlan);
        }
    }
}
