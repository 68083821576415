import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TakeOffDrawingColorPickerComponent } from '../ui-take-off-drawing-color-picker/take-off-drawing-color-picker.component';
import { TakeOffDrawingDepthModule } from '../ui-take-off-drawing-depth/take-off-drawing-depth.component';
import { PitchChangedEventArgs, TakeOffPitchDropdownComponent } from '@bx-web/takeoff';
import { TakeOffConfig, TakeOffDrawingDetails } from '../takeoff-measurements/domain';

@Component({
    selector: 'bx-web-take-off-drawing-details',
    templateUrl: './take-off-drawing-details.component.html',
    styleUrls: ['./take-off-drawing-details.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TakeOffDrawingColorPickerComponent,
        TakeOffDrawingDepthModule,
        TakeOffPitchDropdownComponent,
    ],
})
export class TakeOffDrawingDetailsComponent implements OnChanges, OnInit {
    @Input() config!: TakeOffConfig;

    @Input() selectedDrawingDetails!: TakeOffDrawingDetails;

    @Output() drawingChanges = new EventEmitter<TakeOffDrawingDetails>();
    @Output() switchOff = new EventEmitter();
    @Output() drawingRemoved = new EventEmitter();

    public positionStyle: object | null = null;

    public drawingLabelControl!: UntypedFormControl;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedDrawingDetails']?.currentValue) {
            this.setDrawingDetails();

            this.updateDrawingLabelControl();
        }
    }

    private setDrawingDetails() {
        if (!this.selectedDrawingDetails) {
            return;
        }

        this.positionStyle = { left: `${this.selectedDrawingDetails.positionX}px`, top: `${this.selectedDrawingDetails.positionY}px` };
    }

    private updateDrawingLabelControl() {
        if (this.drawingLabelControl && this.drawingLabelControl.value !== this.selectedDrawingDetails.label) {
            this.drawingLabelControl.setValue(this.selectedDrawingDetails.label);
        }
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm() {
        this.drawingLabelControl = new UntypedFormControl(this.selectedDrawingDetails.label, {
            validators: [Validators.required, Validators.maxLength(50)],
            updateOn: 'blur',
        });

        this.drawingLabelControl.valueChanges.subscribe((newLabel: string) => this.watchLabelChanges(newLabel));
    }

    private watchLabelChanges(newLabel: string) {
        if (this.selectedDrawingDetails.label === newLabel) {
            return;
        }

        this.drawingChanges.emit({ ...this.selectedDrawingDetails, label: newLabel });
    }

    public onDepthChanged(newDepth: number) {
        if (this.selectedDrawingDetails.depth === newDepth) {
            return;
        }

        this.drawingChanges.emit({ ...this.selectedDrawingDetails, depth: newDepth });
    }

    public onDepthUnitChanged(newDepthUnit: string) {
        if (this.selectedDrawingDetails.depthUnit === newDepthUnit) {
            return;
        }

        this.drawingChanges.emit({ ...this.selectedDrawingDetails, depthUnit: newDepthUnit });
    }

    public onColorChanged(newColor: string) {
        if (this.selectedDrawingDetails.color === newColor) {
            return;
        }

        this.drawingChanges.emit({ ...this.selectedDrawingDetails, color: newColor });
    }

    public onPitchChanged(pitch: PitchChangedEventArgs) {
        if (this.selectedDrawingDetails.pitch === pitch.degrees && this.selectedDrawingDetails.pitchRatio === pitch.ratio) {
            return;
        }

        this.drawingChanges.emit({ ...this.selectedDrawingDetails, pitch: pitch.degrees, pitchRatio: pitch.ratio });
    }

    public onClose() {
        this.switchOff.emit();
    }

    public onRemove() {
        this.drawingRemoved.emit();
    }
}
