import Konva from 'konva';
import { DepthUnit, DrawingMode, DrawingShape, DrawingUnit, ScaleUnit, ScaleUnitName } from './constants';
import { compareDrawings, Drawing } from './drawing';
import { IsDepthUnitImperial, IsImperial } from './functions';
import { Position } from './position';
import { Scale } from './scale';
import { getDelta } from '@bx-web/shared-utils';

export interface DrawingCanvasEvent {
    canvas: DrawingCanvas;
    args: any;
}
export class DrawingCanvas {
    scale = 1;
    maxScale = 3.5;
    minScale = 0.05;
    horizontalScale = new Scale(ScaleUnit.mm, 1);
    verticalScale = new Scale(ScaleUnit.mm, 1);
    planScaleUnit = ScaleUnitName.mm;
    shape = DrawingShape.Polygon;
    color = '#FF0000';
    unit = DrawingUnit.m2;
    drawingOn = true;
    scaleStep = 0.05;
    mouseScaleStep = 0.015;
    origin = new Position();
    mouseDownPos = null;
    // @ts-ignore TS2322
    startPosition?: Position = null;
    // @ts-ignore TS2322
    currentDrawing?: Drawing = null;
    // @ts-ignore TS2322
    lastDrawing?: Drawing = null;
    currentShape = null;
    backgroundImage = null;
    isDrawing = false;
    isNegative = false;
    lastDist = 0;
    pitch = 0;
    pitchRatio = null;
    depth = 0;
    depthUnit = DepthUnit.mm;
    cursor = 'default';
    polygonCompletionThreshold = 30;
    drawingMode = DrawingMode.Multiple;
    showLabels = true;
    label = '';
    eventListeners = {};
    negativeColor = '#000000';
    stage: Konva.Stage;
    layer: Konva.Layer;
    group: Konva.Group;
    statusLayer: Konva.Layer;
    drawings: Drawing[] = [];

    constructor(container: string, width: number, height: number) {
        this.stage = new Konva.Stage({
            container: container,
            width: width,
            height: height,
            draggable: false,
        });
        this.layer = new Konva.Layer();
        this.layer.name('Plans');
        this.layer.id('Plans');
        this.group = new Konva.Group({
            draggable: true,
        });
        this.layer.add(this.group);
        this.stage.add(this.layer);
        this.statusLayer = new Konva.Layer();
        this.statusLayer.name('Status');
        this.statusLayer.id('Status');
        this.registerEvents();
    }

    // #region Events

    on = (evtStr: string, handler: (canvas: DrawingCanvas, args: any) => void) => {
        const events = evtStr.split(' ');

        /*
         * loop through types and attach event listeners to
         * each one.  eg. 'click mouseover mouseout'
         * will create three event bindings
         */
        for (let n = 0; n < events.length; n++) {
            const event = events[n];

            // create events array if it doesn't exist
            // @ts-ignore TS2538
            if (!this.eventListeners[event]) {
                // @ts-ignore TS2538
                this.eventListeners[event] = [];
            }

            // @ts-ignore TS2538
            this.eventListeners[event].push({
                name: event,
                handler: handler,
            });
        }
    };

    private registerEvents() {
        this.group.on('mousedown touchstart', () => {
            this.onMouseDown.apply(this);
        });

        this.group.on('mouseup touchend', (evt) => {
            this.onMouseUp.apply(this, [evt]);
        });

        this.group.on('mousemove', () => {
            this.onMouseMove.apply(this);
        });

        this.group.on('mouseenter', () => {
            if (this.drawingMode !== DrawingMode.None) this.stage.getContent().style.cursor = this.cursor;
        });

        this.group.on('mouseleave', () => {
            this.stage.getContent().style.cursor = 'default';
        });

        this.group.on('dragstart', () => {
            this.stage.getContent().style.cursor = 'move';
        });

        this.group.on('dragend', () => {
            this.stage.getContent().style.cursor = this.drawingMode !== DrawingMode.None ? this.cursor : 'default';
        });

        this.stage.getContent().addEventListener('wheel', (event) => {
            event.preventDefault();
            const evt = event as WheelEvent,
                zoom = 1 + this.mouseScaleStep - (evt.deltaY > 0 ? this.mouseScaleStep * 2 : 0),
                newScale = this.scale * zoom;
            const position = new Position();
            position.x = evt.offsetX - this.group.x();
            position.y = evt.offsetY - this.group.y();

            this.setScale.apply(this, [newScale, position]);
        });

        this.stage.getContent().addEventListener(
            'touchmove',
            (evt) => {
                const touch1 = evt.touches[0];
                const touch2 = evt.touches[1];

                evt.preventDefault(); // prevent iPad panning

                if (touch1 && touch2) {
                    const dist = this.getDistance(
                        {
                            x: touch1.clientX,
                            y: touch1.clientY,
                        },
                        {
                            x: touch2.clientX,
                            y: touch2.clientY,
                        }
                    );

                    if (!this.lastDist) {
                        this.lastDist = dist;
                    }

                    let touchX = 0;
                    let touchY = 0;
                    // We use this to calculate the center point between touch positions
                    touchX = Math.abs(touch2.clientX + (touch1.clientX - touch2.clientX) / 2) - this.group.x();
                    touchY = Math.abs(touch2.clientY + (touch1.clientY - touch2.clientY) / 2) - this.group.y();
                    const touchPos = new Position(touchX, touchY);
                    const scaleX = this.group.scale()?.x ?? 0;
                    const scale = (scaleX * dist) / this.lastDist;
                    this.setScale.apply(this, [scale, touchPos]);
                    this.lastDist = dist;
                }
            },
            false
        );

        this.stage.getContent().addEventListener(
            'touchend',
            () => {
                this.lastDist = 0;
            },
            false
        );
    }

    getDistance(p1: Position, p2: Position) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    // #endregion Stage Events

    // #region Methods

    destroy() {
        this.stage.destroyChildren();
        this.stage.destroy();
    }

    // @ts-ignore TS7006
    setSize = (s) => {
        if (s && s.width && s.height) this.stage.setSize(s);
        // this.layer.draw();
        this.fireEvent('stageSizechanged', {
            size: s,
        });
    };

    loadDrawingsFromJSON = (j: string) => {
        const d = JSON.parse(j);
        this.loadDrawings(d);
    };

    loadDrawings = (drawings?: Drawing[]) => {
        if (!drawings) return;
        this.loadDrawingsDelta(drawings);
        // this.loadDrawingsAll(drawings);
    };

    loadDrawingsAll = (drawings: Drawing[]) => {
        this.removeAllDrawings();
        drawings.forEach((drawing: Drawing) => {
            this.drawings.push(drawing);
        });
        this.renderAll();
    };

    /**
     * Loads drawings by using a delta function to get the differences
     * Much more performant than loadDrawingsAll.
     * Uses the 'id' of the drawing to compare changes
     * Drawings without an 'id' value are always added/removed.
     * @param drawings Array of drawings to load
     */
    loadDrawingsDelta = (drawings: Drawing[]) => {
        // changes are tracked by id
        // get changes for drawings with ids
        const oldDrawingsWithId = this.drawings.filter((d) => !!d.id);
        const newDrawingsWithId = drawings.filter((d) => !!d.id);
        const delta = getDelta<Drawing, 'id'>(oldDrawingsWithId, newDrawingsWithId, 'id', compareDrawings);
        // always remove old drawings without an id as we can't compare them
        const oldDrawingsWithoutId = this.drawings
            .filter((d) => !d.id)
            .map((d) => {
                return { key: d.id, value: d };
            });
        delta.deleted.push(...oldDrawingsWithoutId);
        // always add new drawings without an id as we can't compare them either
        const newDrawingsWithoutId = drawings
            .filter((d) => !d.id)
            .map((d) => {
                return { key: d.id, value: d };
            });
        delta.added.push(...newDrawingsWithoutId);

        // Now apply changes to this canvas
        delta.deleted.forEach((d) => {
            this.removeDrawing(d.value);
        });

        delta.changed.forEach((x) => {
            this.removeDrawing(x.oldValue);
            this.drawings.push(x.newValue);
            this.render(x.newValue);
        });

        delta.added.forEach((d) => {
            this.drawings.push(d.value);
            this.render(d.value);
        });
    };

    // @ts-ignore TS7006
    setCursor = (val) => {
        this.cursor = val;
    };

    setDrawingShape(val: string) {
        if (val !== undefined) this.shape = val;
    }

    getDrawingShape() {
        return this.shape;
    }

    setIsNegative(val: boolean) {
        this.isNegative = val;
    }

    getIsNegative() {
        return this.isNegative;
    }

    setMouseScaleStep(val: number) {
        if (val !== undefined) this.mouseScaleStep = val;
    }

    getMouseScaleStep() {
        return this.mouseScaleStep;
    }

    setDrawingMode(val: string) {
        if (val !== undefined) {
            this.drawingMode = val;
        }
    }

    getDrawingMode() {
        return this.drawingMode;
    }

    setDrawingColor(val?: string) {
        if (val !== undefined) this.color = val;
    }

    getDrawingColor() {
        return this.color;
    }

    // @ts-ignore TS7006
    setMeasurementUnit(val) {
        if (val !== undefined) this.unit = val;
    }

    getMeasurementUnit() {
        return this.unit;
    }

    setShowLabels(val?: boolean) {
        if (val !== undefined) {
            this.showLabels = val;
            this.drawings.forEach((drawing) => {
                if (drawing.labelObject) drawing.labelObject.visible(val);
            });
        }
    }

    getShowLabels() {
        return this.showLabels;
    }

    setLabel(val: string) {
        if (val !== undefined && val !== null) this.label = val.toString();
    }

    getLabel() {
        return this.label;
    }

    setPitch(val: number) {
        if (val !== undefined && val !== null) this.pitch = val;
    }

    getPitch() {
        return this.pitch;
    }

    // @ts-ignore TS7006
    setPitchRatio(val) {
        this.pitchRatio = val;
    }

    getPitchRatio() {
        return this.pitchRatio;
    }

    setDepth(val: number) {
        if (!isNaN(val)) this.depth = val;
    }

    getDepth() {
        return this.depth;
    }

    setDepthUnit(val: string) {
        if (val) this.depthUnit = val;
    }

    getDepthUnit() {
        return this.depthUnit;
    }

    setHorizontalScale(scale: Scale) {
        this.horizontalScale = scale;
    }

    setVerticalScale(scale: Scale) {
        this.verticalScale = scale;
    }

    setPlanScaleUnit(unit: string) {
        this.planScaleUnit = unit;
    }

    setScale = (newScale: number, position?: Position) => {
        if (!this.backgroundImage) return;

        // @ts-ignore TS2339
        const scaleX = this.stage.width() / this.backgroundImage.getWidth();
        // @ts-ignore TS2339
        const scaleY = this.stage.height() / this.backgroundImage.getHeight();
        this.minScale = scaleX < scaleY ? scaleX : scaleY;

        if (newScale < this.minScale || newScale > this.maxScale) return;

        // Default to the center of the stage as a scale/zoom point
        if (position === undefined) {
            position = new Position();
            position.x = this.stage.width() / 2 - this.group.x();
            position.y = this.stage.height() / 2 - this.group.y();
        }

        this.origin.x = position.x / this.scale + this.origin.x - position.x / newScale;
        this.origin.y = position.y / this.scale + this.origin.y - position.y / newScale;

        this.group.offset(this.origin);
        this.group.scale({
            x: newScale,
            y: newScale,
        });

        this.scale = newScale;

        this.fireEvent('scalechanged', {
            scale: newScale,
        });
    };

    setBackgroundImage = (imageUrl: string) => {
        if (!imageUrl) {
            // @ts-ignore TS2339
            if (this.backgroundImage) this.backgroundImage.setImage(null); // BX-14 Just clear the image out don't kill all bindings on the object.

            this.clearStatus();
            return;
        }

        // display loading text
        this.showStatus('Loading...', 'info');

        const imageObj = new Image();
        imageObj.crossOrigin = 'Anonymous';
        imageObj.addEventListener('load', () => {
            if (!this.backgroundImage) {
                // @ts-ignore TS2322
                this.backgroundImage = new Konva.Image({
                    x: 0,
                    y: 0,
                    image: imageObj,
                    name: 'bgImage',
                });
                // add the shape to the layer
                // @ts-ignore TS2345
                this.group.add(this.backgroundImage);
                // make sure it's the bottom object
                // @ts-ignore TS2531
                this.backgroundImage.moveToBottom();
                // @ts-ignore TS2339
            } else this.backgroundImage.setImage(imageObj);

            this.clearStatus();
            // default scale
            this.scaleToStage();
        });
        imageObj.addEventListener('error', () => {
            this.showStatus('Something went wrong trying to load image', 'error');
        });
        imageObj.src = imageUrl;
    };

    setWidth = (width: number) => {
        this.stage.width(width);
    };

    scaleIn = () => {
        const newScale = this.scale + this.scaleStep;
        this.setScale(newScale);
    };

    scaleOut = () => {
        const newScale = this.scale - this.scaleStep;
        this.setScale(newScale);
    };

    scaleToStage = () => {
        // @ts-ignore TS2531
        const scaleX = this.stage.width() / this.backgroundImage.getWidth();
        // @ts-ignore TS2531
        const scaleY = this.stage.height() / this.backgroundImage.getHeight();
        const newScale = scaleX < scaleY ? scaleX : scaleY;
        this.setScale(newScale, new Position());
        // center group on stage
        // @ts-ignore TS2531
        const scaledImageWidth = this.backgroundImage.getWidth() * this.scale;
        const stageCenter = this.stage.width() / 2;
        const imageCenter = scaledImageWidth / 2;
        const posX = stageCenter - imageCenter;
        this.group.position({
            x: posX,
            y: 0,
        });
        this.group.offset({
            x: 0,
            y: 0,
        });
        this.origin.x = 0;
        this.origin.y = 0;
    };

    scaleXToStage = () => {
        // @ts-ignore TS2531
        const newScale = this.stage.width() / this.backgroundImage.getWidth();
        this.setScale(newScale, new Position());
        this.group.position({
            x: 0,
            y: 0,
        });
        this.group.offset({
            x: 0,
            y: 0,
        });
        this.origin.x = 0;
        this.origin.y = 0;
    };

    scaleYToStage = () => {
        // @ts-ignore TS2531
        const newScale = this.stage.height() / this.backgroundImage.getHeight();
        this.setScale(newScale, new Position());
        this.group.position({
            x: 0,
            y: 0,
        });
        this.group.offset({
            x: 0,
            y: 0,
        });
        this.origin.x = 0;
        this.origin.y = 0;
    };

    onMouseDown = () => {
        // @ts-ignore TS2322
        this.mouseDownPos = new Position(this.group.x(), this.group.y());
    };

    // @ts-ignore TS7006
    onMouseUp = (evt) => {
        // Only proceed if the mousedown was registered
        if (this.mouseDownPos == null) return;
        if (this.drawingMode == DrawingMode.None) return;
        // If the user clicked a label and we're not drawing, don't start one
        if (evt.target.className === 'Text' && !this.isDrawing) return;

        const position = this.getScaledMousePosition();

        // Get the current group position
        const currentPos = new Position();
        currentPos.x = this.group.x();
        currentPos.y = this.group.y();

        // If the position of the group hasn't changed, then draw
        // It will change if the group was dragged
        // This prevents the drawing to continue when the user
        // has dragged the group to a new position
        // @ts-ignore TS2339
        if (currentPos.x == this.mouseDownPos.x && currentPos.y == this.mouseDownPos.y) {
            this.startOrContinueDrawing(position);
        }

        this.mouseDownPos = null;
    };

    onMouseMove = () => {
        const position = this.getScaledMousePosition();

        if (this.currentDrawing == null) return;

        this.moveDrawing(position);
    };

    // @ts-ignore TS7006
    fireEvent = (event, args) => {
        // @ts-ignore TS7053
        const events = this.eventListeners[event];

        if (events) {
            for (let i = 0; i < events.length; i++) {
                events[i].handler.call(null, this, args);
            }
        }
    };

    getScaledMousePosition = () => {
        // Get the correct mouse up position on the group
        const position = new Position();
        // @ts-ignore TS2531
        position.x = this.stage.getPointerPosition().x - this.group.x();
        // @ts-ignore TS2531
        position.y = this.stage.getPointerPosition().y - this.group.y();

        // Adjust for scale and offset
        position.x = Math.round(position.x / this.scale) + this.group.offsetX();
        position.y = Math.round(position.y / this.scale) + this.group.offsetY();

        return position;
    };

    removeAllDrawings = () => {
        // @ts-ignore TS2345
        while (this.drawings.length > 0) this.removeDrawing(this.drawings[this.drawings.length - 1]);
    };

    removeDrawing = (d: Drawing) => {
        if (d) {
            if (d.shapeObject) d.shapeObject.destroy();
            if (d.labelObject) d.labelObject.destroy();
        }
        const i = this.drawings.indexOf(d);
        if (i !== -1) {
            this.drawings.splice(i, 1);
        }
        this.fireEvent('drawingremove', {
            drawing: d,
        });
    };

    renderAll = () => {
        this.drawings.forEach((drawing) => {
            this.render(drawing);
        });
    };

    render = (drawing: Drawing) => {
        if (drawing) {
            if (drawing.shapeObject) drawing.shapeObject.destroy();
            if (drawing.labelObject) drawing.labelObject.destroy();
            // @ts-ignore TS2322
            drawing.shapeObject = undefined;
            // @ts-ignore TS2322
            drawing.labelObject = undefined;
            switch (drawing.shape) {
                case DrawingShape.Ellipse:
                    this.renderEllipse(drawing);
                    break;
                case DrawingShape.Line:
                    this.renderLine(drawing);
                    break;
                case DrawingShape.Point:
                    this.renderPoint(drawing);
                    break;
                case DrawingShape.Polygon:
                    this.renderPolygon(drawing);
                    break;
                case DrawingShape.Rectangle:
                    this.renderRectangle(drawing);
                    break;
            }
            this.renderLabel(drawing);
        }
    };

    undoLast = () => {
        if (!this.drawings.length) return;
        const last = this.drawings[this.drawings.length - 1];
        // @ts-ignore TS2345
        this.removeDrawing(last);
        return true;
    };

    undo = () => {
        if (!this.isDrawing) return false;
        if (!this.currentDrawing) return false;

        if (
            this.currentDrawing.shape !== 'Polygon' ||
            (this.currentDrawing.shape === 'Polygon' && this.currentDrawing.points.length <= 3)
        ) {
            // Only just started Polygon
            this.removeDrawing(this.currentDrawing);
            this.hidePolygonCompletionCircle();
            this.isDrawing = false;
            // @ts-ignore TS2322
            this.currentDrawing = null;
        } else {
            this.currentDrawing.points.splice(this.currentDrawing.points.length - 2, 1);
            this.currentDrawing.calculateMeasurement();
            this.renderPolygon(this.currentDrawing);
        }
        return true;
    };

    startOrContinueDrawing = (position: Position) => {
        if (!this.drawingOn) return;
        if (this.drawingMode == DrawingMode.Single) this.removeAllDrawings();

        if (this.currentDrawing != null) this.continueDrawing(position);
        else this.startDrawing(position);
    };

    continueDrawing = (position: Position) => {
        if (this.currentDrawing == null) return;

        switch (this.shape) {
            case DrawingShape.Polygon:
                this.continueOrCompletePolygon(position);
                break;
            case DrawingShape.Rectangle:
                this.continueOrCompleteRectangle(position);
                break;
            case DrawingShape.Ellipse:
                this.continueOrCompleteEllipse(position);
                break;
            case DrawingShape.Line:
                this.continueOrCompleteLine(position);
                break;
        }

        // Check if drawing for the current shape has finished
        if (!this.isDrawing) {
            this.drawings.push(this.currentDrawing);
            this.lastDrawing = this.currentDrawing;
            // Raise event to notify a shape has been drawn
            // @ts-ignore TS2322
            this.currentDrawing = null;
            this.fireEvent('drawingfinish', {
                drawing: this.lastDrawing,
            });
        } else {
            this.fireEvent('drawingcontinue', {
                drawing: this.currentDrawing,
            });
        }
    };

    startDrawing = (position: Position) => {
        this.startPosition = position;

        switch (this.shape) {
            case DrawingShape.Polygon:
                this.startPolygon(position);
                break;
            case DrawingShape.Rectangle:
                this.startRectangle(position);
                break;
            case DrawingShape.Ellipse:
                this.startEllipse(position);
                break;
            case DrawingShape.Line:
                this.startLine(position);
                break;
            case DrawingShape.Point:
                this.startPoint(position);
                break;
        }
        // Notify that we're drawing
        this.isDrawing = true;

        // Set defaults
        // @ts-ignore TS2532
        this.currentDrawing.horizontalScale = this.horizontalScale;
        // @ts-ignore TS2532
        this.currentDrawing.verticalScale = this.verticalScale;
        // @ts-ignore TS2532
        this.currentDrawing.planScaleUnit = this.planScaleUnit;
        // @ts-ignore TS2532
        this.currentDrawing.isNegative = this.isNegative;
        // @ts-ignore TS2532
        this.currentDrawing.pitch = this.pitch;
        // @ts-ignore TS2532
        this.currentDrawing.pitchRatio = this.pitchRatio;

        if (this.shape === DrawingShape.Point) {
            // No need to continue listening for point events
            // Finish it straight away
            // @ts-ignore TS2345
            this.drawings.push(this.currentDrawing);
            this.lastDrawing = this.currentDrawing;
            this.isDrawing = false;
            // @ts-ignore TS2322
            this.currentDrawing = null;
            this.fireEvent('drawingfinish', {
                drawing: this.lastDrawing,
            });
        } else {
            this.fireEvent('drawingstart', {
                drawing: this.currentDrawing,
            });
        }
    };

    moveDrawing = (position: Position) => {
        if (this.currentDrawing == null) return;
        if (!this.isDrawing) return;

        switch (this.shape) {
            case DrawingShape.Polygon:
                this.movePolygon(position);
                break;
            case DrawingShape.Rectangle:
                this.moveRectangle(position);
                break;
            case DrawingShape.Ellipse:
                this.moveEllipse(position);
                break;
            case DrawingShape.Line:
                this.moveLine(position);
                break;
        }

        this.fireEvent('drawingcontinue', {
            drawing: this.currentDrawing,
        });
    };

    // #region Polygon

    renderPolygon = (drawing: Drawing) => {
        if (!drawing.shapeObject) {
            drawing.shapeObject = new Konva.Line({
                points: this.pointsToFlatArray(drawing.points),
                fill: drawing.fillColor,
                stroke: drawing.strokeColor,
                strokeWidth: drawing.lineWidth,
                opacity: drawing.opacity,
                closed: true,
            });
            this.group.add(drawing.shapeObject);
        } else {
            (drawing.shapeObject as Konva.Line).points(this.pointsToFlatArray(drawing.points));
            drawing.shapeObject.fill(drawing.fillColor);
            drawing.shapeObject.stroke(drawing.strokeColor);
        }

        drawing.shapeObject.position(drawing.position);

        return drawing.shapeObject;
    };

    startPolygon = (position: Position) => {
        const points = [new Position()];
        this.currentDrawing = new Drawing({
            shape: this.shape,
            position,
            points,
            color: this.color,
            unit: this.unit,
            isNegative: this.isNegative,
            label: this.label,
            pitch: this.pitch,
            // @ts-ignore TS2322
            pitchRatio: this.pitchRatio,
            depth: this.depth,
            depthUnit: this.depthUnit,
        });
        // The smallest polygon is a triangle, so we need to initialize with 3 points
        this.currentDrawing.clearPoints();
        this.currentDrawing.addPoint(new Position());
        this.currentDrawing.addPoint(new Position());
        this.currentDrawing.addPoint(new Position());

        this.renderPolygon(this.currentDrawing);
        this.renderLabel(this.currentDrawing);
    };

    movePolygon = (position: Position) => {
        const relative = this.getRelativePointToStart(position);
        // Check to see if this position is within the threshold of the first position
        // @ts-ignore TS2532
        const firstPosition = this.currentDrawing.points[0];

        // @ts-ignore TS2345
        this.showPolygonCompletionCircle(this.startPosition);

        // @ts-ignore TS2345
        if (this.arePointsWithinPolygonCompletionThreshold(firstPosition, relative)) {
            // Snaps current position to the completion area
            // @ts-ignore TS2532
            relative.x = firstPosition.x;
            // @ts-ignore TS2532
            relative.y = firstPosition.y;
        }

        // We'll always be moving the SECOND last point (the last is always the same as the first)
        // @ts-ignore TS2532
        const secondLastPointIndex = this.currentDrawing.points.length - 2;
        // @ts-ignore TS2532
        this.currentDrawing.points[secondLastPointIndex] = relative;
        // Recalculate the shape measurement
        // @ts-ignore TS2532
        this.currentDrawing.calculateMeasurement();

        // @ts-ignore TS2345
        this.renderPolygon(this.currentDrawing);
        // @ts-ignore TS2345
        this.renderLabel(this.currentDrawing);
    };

    continueOrCompletePolygon = (position: Position) => {
        const relative = this.getRelativePointToStart(position);
        // Check to see if this position is within the threshold of the first position
        // @ts-ignore TS2532
        const firstPosition = this.currentDrawing.points[0];
        // @ts-ignore TS2345
        if (this.arePointsWithinPolygonCompletionThreshold(firstPosition, relative)) {
            // The 2nd last point may have been inserted whilst moving
            // If so, we remove it if it's also within the threshold
            // @ts-ignore TS2532
            const secondLastPointIndex = this.currentDrawing.points.length - 2;
            // @ts-ignore TS2532
            const lastPosition = this.currentDrawing.points[secondLastPointIndex];
            // @ts-ignore TS2345
            if (this.arePointsWithinPolygonCompletionThreshold(lastPosition, relative))
                // @ts-ignore TS2532
                this.currentDrawing.points.splice(secondLastPointIndex, 1);

            // Finish drawing
            this.hidePolygonCompletionCircle();
            this.isDrawing = false;
        } else {
            // @ts-ignore TS2532
            this.currentDrawing.points.splice(this.currentDrawing.points.length - 1, 0, relative);
        }

        // @ts-ignore TS2532
        this.currentDrawing.calculateMeasurement();
        // @ts-ignore TS2345
        this.renderPolygon(this.currentDrawing);
        // @ts-ignore TS2345
        this.renderLabel(this.currentDrawing);
    };

    showPolygonCompletionCircle = (position: Position) => {
        this.hidePolygonCompletionCircle(); // in case it already exists
        const c = new Konva.Ellipse({
            fill: 'green',
            stroke: 'darkgreen',
            strokeWidth: 1,
            opacity: 0.5,
            width: this.polygonCompletionThreshold,
            height: this.polygonCompletionThreshold,
            name: 'polygonCompleteEllipse',
            radiusX: this.polygonCompletionThreshold,
            radiusY: this.polygonCompletionThreshold,
        });

        c.position(position);
        this.group.add(c);
    };

    hidePolygonCompletionCircle = () => {
        const c = this.group.findOne('.polygonCompleteEllipse');
        if (c) {
            c.destroy();
        }
    };

    // #endregion Polygon

    // #region Rectangle

    renderRectangle = (drawing: Drawing) => {
        if (!drawing.shapeObject) {
            drawing.shapeObject = new Konva.Rect({
                fill: drawing.fillColor,
                stroke: drawing.strokeColor,
                strokeWidth: drawing.lineWidth,
                opacity: drawing.opacity,
            });

            this.group.add(drawing.shapeObject);
        }

        drawing.shapeObject.position(drawing.position);
        drawing.shapeObject.width(drawing.width);
        drawing.shapeObject.height(drawing.height);

        return drawing.shapeObject;
    };

    startRectangle = (position: Position) => {
        this.currentDrawing = new Drawing({
            shape: this.shape,
            position,
            points: [],
            color: this.color,
            unit: this.unit,
            isNegative: this.isNegative,
            label: this.label,
            pitch: this.pitch,
            // @ts-ignore TS2322
            pitchRatio: this.pitchRatio,
            depth: this.depth,
            depthUnit: this.depthUnit,
        });

        this.renderRectangle(this.currentDrawing);
        this.renderLabel(this.currentDrawing);
    };

    moveRectangle = (position: Position) => {
        this.setDrawingWidthHeightOnCanvas(position);
        // @ts-ignore TS2532
        this.currentDrawing.calculateMeasurement();
        // @ts-ignore TS2345
        this.renderRectangle(this.currentDrawing);
        // @ts-ignore TS2345
        this.renderLabel(this.currentDrawing);
    };

    continueOrCompleteRectangle = (position: Position) => {
        this.setDrawingWidthHeightOnCanvas(position);
        // @ts-ignore TS2532
        this.currentDrawing.calculateMeasurement();
        // @ts-ignore TS2345
        this.renderRectangle(this.currentDrawing);
        // @ts-ignore TS2345
        this.renderLabel(this.currentDrawing);
        // Finish drawing
        this.isDrawing = false;
    };

    // #endregion Rectangle

    // #region Ellipse

    renderEllipse = (drawing: Drawing) => {
        if (!drawing.shapeObject) {
            drawing.shapeObject = new Konva.Ellipse({
                fill: drawing.fillColor,
                stroke: drawing.strokeColor,
                strokeWidth: drawing.lineWidth,
                opacity: drawing.opacity,
                radiusX: 0,
                radiusY: 0,
            });

            this.group.add(drawing.shapeObject);
        }

        (drawing.shapeObject as Konva.Ellipse).fill(drawing.fillColor);
        (drawing.shapeObject as Konva.Ellipse).stroke(drawing.strokeColor);
        (drawing.shapeObject as Konva.Ellipse).position(drawing.position);
        this.setElipseRadius(drawing);

        return drawing.shapeObject;
    };

    startEllipse = (position: Position) => {
        this.currentDrawing = new Drawing({
            shape: this.shape,
            position,
            points: [],
            color: this.color,
            unit: this.unit,
            isNegative: this.isNegative,
            label: this.label,
            pitch: this.pitch,
            // @ts-ignore TS2322
            pitchRatio: this.pitchRatio,
            depth: this.depth,
            depthUnit: this.depthUnit,
        });

        this.renderEllipse(this.currentDrawing);
        this.renderLabel(this.currentDrawing);
    };

    moveEllipse = (position: Position) => {
        this.setDrawingWidthHeightOnCanvas(position);
        // @ts-ignore TS2532
        this.currentDrawing.calculateMeasurement();
        // @ts-ignore TS2345
        this.renderEllipse(this.currentDrawing);
        // @ts-ignore TS2345
        this.renderLabel(this.currentDrawing);
    };

    setElipseRadius = (drawing: Drawing) => {
        // Ellipse position in Konva is the centre
        const ePosX = drawing.position.x + drawing.width / 2;
        const ePosY = drawing.position.y + drawing.height / 2;
        const eRadX = drawing.width / 2;
        const eRadY = drawing.height / 2;
        const ePos = new Position(ePosX, ePosY);
        const eRad = new Position(eRadX, eRadY);
        (drawing.shapeObject as Konva.Ellipse).position(ePos);
        (drawing.shapeObject as Konva.Ellipse).radius(eRad);
    };

    continueOrCompleteEllipse = (position: Position) => {
        this.setDrawingWidthHeightOnCanvas(position);
        // @ts-ignore TS2532
        this.currentDrawing.calculateMeasurement();
        // @ts-ignore TS2345
        this.renderEllipse(this.currentDrawing);
        // @ts-ignore TS2345
        this.renderLabel(this.currentDrawing);
        // Finish drawing
        this.isDrawing = false;
    };

    // #endregion Ellipse

    // #region Line

    renderLine = (drawing: Drawing) => {
        if (!drawing.shapeObject) {
            drawing.shapeObject = new Konva.Line({
                x: drawing.position.x,
                y: drawing.position.y,
                stroke: drawing.isNegative ? this.negativeColor : drawing.color,
                strokeWidth: 3,
                points: this.pointsToFlatArray(drawing.points),
            });

            this.group.add(drawing.shapeObject);
        }

        drawing.shapeObject.position(drawing.position);
        (drawing.shapeObject as Konva.Line).points(this.pointsToFlatArray(drawing.points));

        return drawing.shapeObject;
    };

    startLine = (position: Position) => {
        const zeroPos = {
            x: 0,
            y: 0,
        };
        const points = [zeroPos, zeroPos];
        this.currentDrawing = new Drawing({
            shape: this.shape,
            position,
            points,
            color: this.color,
            unit: this.unit,
            isNegative: this.isNegative,
            label: this.label,
            depth: this.depth,
            depthUnit: this.depthUnit,
        });

        this.renderLine(this.currentDrawing);
        this.renderLabel(this.currentDrawing);
    };

    moveLine = (position: Position) => {
        const relative = this.getRelativePointToStart(position);
        // @ts-ignore TS2532
        this.currentDrawing.points.pop();
        // @ts-ignore TS2532
        this.currentDrawing.addPoint(relative);
        // @ts-ignore TS2532
        this.currentDrawing.calculateMeasurement();

        // @ts-ignore TS2345
        this.renderLine(this.currentDrawing);
        // @ts-ignore TS2345
        this.renderLabel(this.currentDrawing);
    };

    continueOrCompleteLine = (position: Position) => {
        const relative = this.getRelativePointToStart(position);
        // @ts-ignore TS2532
        this.currentDrawing.points.pop();
        // @ts-ignore TS2532
        this.currentDrawing.addPoint(relative);
        // @ts-ignore TS2532
        this.currentDrawing.calculateMeasurement();

        // @ts-ignore TS2345
        this.renderLine(this.currentDrawing);
        // @ts-ignore TS2345
        this.renderLabel(this.currentDrawing);
        this.isDrawing = false;
    };

    // #endregion Line

    // #region Point

    renderPoint = (drawing: Drawing) => {
        if (!drawing.shapeObject) {
            drawing.shapeObject = new Konva.Ellipse({
                fill: drawing.fillColor,
                stroke: drawing.strokeColor,
                strokeWidth: 2,
                opacity: 0.5,
                width: 50,
                height: 50,
                radiusX: 25,
                radiusY: 25,
            });
            drawing.shapeObject.cache();
            this.group.add(drawing.shapeObject);
        }

        drawing.shapeObject.position(drawing.position);

        return drawing.shapeObject;
    };

    startPoint = (position: Position) => {
        this.currentDrawing = new Drawing({
            shape: this.shape,
            position: position,
            points: [],
            color: this.color,
            unit: this.unit,
            label: this.label,
            isNegative: this.isNegative,
        });
        this.currentDrawing.calculateMeasurement();
        this.renderPoint(this.currentDrawing);
        this.renderLabel(this.currentDrawing);
    };

    // #endregion

    // #region Shape Labels

    renderLabel = (drawing: Drawing) => {
        let label;

        if (!drawing.labelObject) {
            // tooltip
            label = new Konva.Label({
                x: drawing.position.x,
                y: drawing.position.y,
                opacity: 0.75,
            });

            label.add(
                new Konva.Tag({
                    fill: 'black',
                    pointerDirection: 'down',
                    pointerWidth: 10,
                    pointerHeight: 10,
                    lineJoin: 'round',
                })
            );

            label.add(
                new Konva.Text({
                    text: 'Label',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: 50,
                    padding: 10,
                    fill: 'white',
                })
            );

            label.on('mouseup touchend', (evt) => {
                // Don't fire if we're in the middle of drawing
                if (this.isDrawing) return;
                this.drawings.forEach((d) => {
                    if (d.labelObject && d.labelObject === evt.currentTarget)
                        this.fireEvent('drawinglabelclick', {
                            drawing: d,
                        });
                });
            });

            label.on('mouseenter', () => {
                if (this.isDrawing) return;
                this.stage.getContent().style.cursor = 'text';
            });

            label.on('mouseleave', () => {
                if (this.isDrawing) return;
                this.stage.getContent().style.cursor = this.cursor;
            });

            drawing.labelObject = label;
            this.group.add(label);
        }

        label = drawing.labelObject;
        // Update position incase it changed
        let x = drawing.position.x;
        const y = drawing.position.y;
        // For ellipse, set the label to the middle top
        if (drawing.shape == DrawingShape.Ellipse) {
            x += drawing.width / 2;
        }

        // @ts-ignore TS2339
        label.setX(x);
        // @ts-ignore TS2339
        label.setY(y);

        const textObj = label.getText();

        const isImperial = IsImperial(drawing.unit);
        const isDepthImperial = IsDepthUnitImperial(drawing.depthUnit);

        let text = drawing.measurement + ' ' + drawing.unit;

        if (isImperial) text = drawing.measurementAsImperial() + ' ' + drawing.unit;

        if (drawing.pitch !== 0) text += ' (' + drawing.pitch + '°) ';
        if (drawing.depth !== 0) {
            if (isDepthImperial) text += ' @ ' + drawing.depthAsImperial();
            else text += ' @ ' + drawing.depth + drawing.depthUnit;
        }
        if (drawing.label && drawing.label.length > 0) text += ' : ' + drawing.label;
        if (drawing.shape === 'Point') text = drawing.label;

        textObj.setText(text);

        label.visible(this.showLabels);

        return label;
    };

    // #endregion

    // Sets width and height of the drawing relative to the start position
    // Accounts for times when the user will click to start a rectangle then move
    // the mouse up and to the left to complete it (backwards)
    setDrawingWidthHeightOnCanvas = (position: Position) => {
        if (this.currentDrawing === undefined || this.startPosition === undefined) {
            throw Error('Cannot set drawing width or height when currentDrawing or startPosition is undefined.');
        }

        const width = position.x - this.startPosition.x;
        const height = position.y - this.startPosition.y;

        if (width >= 0 && height >= 0) {
            // down/right
            this.currentDrawing.position = this.startPosition;
            this.currentDrawing.width = width;
            this.currentDrawing.height = height;
        } else if (width < 0 && height > 0) {
            // up/right
            this.currentDrawing.position = new Position(position.x, this.startPosition.y);
            this.currentDrawing.width = width * -1;
            this.currentDrawing.height = height;
        } else if (width > 0 && height < 0) {
            // down/left
            this.currentDrawing.position = new Position(this.startPosition.x, position.y);
            this.currentDrawing.width = width;
            this.currentDrawing.height = height * -1;
        } else if (width < 0 && height < 0) {
            // up/left
            this.currentDrawing.position = new Position(position.x, position.y);
            this.currentDrawing.width = width * -1;
            this.currentDrawing.height = height * -1;
        }
    };

    getRelativePointToStart = (position: Position) => {
        // @ts-ignore TS2532
        const distX = position.x - this.startPosition.x;
        // @ts-ignore TS2532
        const distY = position.y - this.startPosition.y;
        return new Position(distX, distY);
    };

    arePointsWithinPolygonCompletionThreshold = (position1: Position, position2: Position) => {
        const xPosDiff = Math.abs(position1.x - position2.x);
        const yPosDiff = Math.abs(position1.y - position2.y);

        return xPosDiff <= this.polygonCompletionThreshold && yPosDiff <= this.polygonCompletionThreshold;
    };

    /*
     * Returns all points as a flat array [X, Y, X, Y]
     */
    pointsToFlatArray = (points: Position[]) => {
        // @ts-ignore TS7034
        const a = [];
        points.forEach((p) => {
            a.push(p.x);
            a.push(p.y);
        });
        // @ts-ignore TS7005
        return a;
    };

    showStatus = (statusText: string, statusType: string) => {
        // Always clear first
        this.clearStatus();

        let fill = 'orange';

        switch (statusType) {
            case 'info':
                fill = 'orange';
                break;
            case 'error':
                fill = 'red';
                break;
            default:
                fill = 'orange';
                break;
        }

        // Setup a loading box to show when images are loading
        const statusLabel = new Konva.Label({
            x: 0,
            y: 0,
            opacity: 0.75,
        });

        statusLabel.add(
            new Konva.Tag({
                fill: fill,
            })
        );

        statusLabel.add(
            new Konva.Text({
                text: statusText,
                fontFamily: 'Inter, sans-serif',
                fontSize: 18,
                padding: 5,
                fill: 'black',
            })
        );

        this.statusLayer.add(statusLabel);
        this.stage.add(this.statusLayer);
    };

    clearStatus = () => {
        this.stage.removeName(this.statusLayer.name());
        this.statusLayer.removeChildren();
    };
}
