import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ImperialPipe } from '@bx-web/shared-utils';
import { TakeOffConfig } from '../takeoff-measurements/domain';
import { TakeOffAdditionalMeasurements, TakeOffMeasurement } from '@bx-web/takeoff';

@Component({
    selector: 'bx-web-take-off-v2-drawings-info',
    templateUrl: './take-off-v2-drawings-info.component.html',
    styleUrls: ['./take-off-v2-drawings-info.component.scss'],
})
export class TakeOffV2DrawingsInfoComponent {
    @Input() config!: TakeOffConfig;
    @Input() measurement!: TakeOffMeasurement;

    @Output() additionalMeasurementsChanges = new EventEmitter<TakeOffAdditionalMeasurements>();

    public onSelectAdditionalMeasurements() {
        this.additionalMeasurementsChanges.emit(this.measurement.additionalMeasurements);
    }
}

@NgModule({
    declarations: [TakeOffV2DrawingsInfoComponent],
    imports: [CommonModule, FormsModule, ImperialPipe],
    exports: [TakeOffV2DrawingsInfoComponent],
})
export class TakeOffV2DrawingsInfoModule {}
