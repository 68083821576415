import { ScaleUnit } from './constants';
/*
 * Represents a scale that can be applied to a drawing shape and used
 * in the calculation of the measurement
 * @param unit = the ScaleUnit for this scale object
 * @param val = the value of the scale
 */
export class Scale {
    scaleunit: number;
    value: number;

    constructor(unit?: number, val?: number) {
        this.scaleunit = unit ? unit : ScaleUnit.mm;
        this.value = val ? val : 0;
    }
}
