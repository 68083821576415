<div class="rfq" *ngIf="originator">
    <h4 class="heading mb-3">{{ originator.sendQuoteTitle }}</h4>
    <p [innerHTML]="originator.sendQuoteDescription ?? '' | safeHtml"></p>

    <form class="rfq-form" [formGroup]="rfqForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
            <label class="form-label" for="fullName">Full Name *</label>
            <input
                class="form-control"
                id="fullName"
                type="text"
                formControlName="fullName"
                required
                placeholder="Enter your full name here" />
            <div
                class="error ms-1"
                *ngIf="
                    rfqForm.get('fullName')?.invalid &&
                    (rfqForm.get('fullName')?.dirty || rfqForm.get('fullName')?.touched || formSubmitted)
                ">
                <div *ngIf="rfqForm.get('fullName')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label" for="emailAddress">Email Address*</label>
            <input
                class="form-control"
                id="emailAddress"
                type="email"
                formControlName="emailAddress"
                required
                placeholder="email@yourbusiness.com" />
            <div
                class="error ms-1"
                *ngIf="
                    rfqForm.get('emailAddress')?.invalid &&
                    (rfqForm.get('emailAddress')?.dirty || rfqForm.get('emailAddress')?.touched || formSubmitted)
                ">
                <div *ngIf="rfqForm.get('emailAddress')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
                <div *ngIf="rfqForm.get('emailAddress')?.hasError('email')">Enter a valid email address</div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label" for="phoneNumber">Phone Number*</label>
            <input
                class="form-control"
                id="phoneNumber"
                type="text"
                formControlName="phoneNumber"
                required
                placeholder="04xx xxx xxx"
                maxlength="12" />
            <div
                class="error ms-1"
                *ngIf="
                    rfqForm.get('phoneNumber')?.invalid &&
                    (rfqForm.get('phoneNumber')?.dirty || rfqForm.get('phoneNumber')?.touched || formSubmitted)
                ">
                <div *ngIf="rfqForm.get('phoneNumber')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
                <div *ngIf="rfqForm.get('phoneNumber')?.hasError('pattern')">Enter a valid phone number</div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label" for="postCode">{{ originator.locationTitle }}*</label>
            <input
                class="form-control"
                id="postCode"
                type="text"
                formControlName="postCode"
                required
                maxlength="5"
                [placeholder]="originator.locationExample" />
            <div
                class="error ms-1"
                *ngIf="
                    rfqForm.get('postCode')?.invalid &&
                    (rfqForm.get('postCode')?.dirty || rfqForm.get('postCode')?.touched || formSubmitted)
                ">
                <div *ngIf="rfqForm.get('postCode')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
                <div *ngIf="rfqForm.get('postCode')?.hasError('pattern')">Please enter a valid {{ originator.locationTitle }}</div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label" for="profession">Profession*</label>
            <select #profession class="form-select" id="profession" formControlName="profession">
                <option value="" hidden selected>Select your profession</option>
                <option *ngFor="let profession of professions" [ngValue]="profession">{{ profession }}</option>
            </select>
            <div class="error ms-1" *ngIf="rfqForm.get('profession')?.invalid && (rfqForm.get('profession')?.touched || formSubmitted)">
                {{ validationProfessionMsg }}
            </div>
        </div>

        <div class="mb-3" *ngIf="originator.hasSupplier">
            <label class="form-label" for="accountNumber">{{ originator.refFieldName }}{{ originator.requireRef ? '*' : '' }}</label>
            <input
                class="form-control"
                id="accountNumber"
                type="text"
                formControlName="accountNumber"
                [placeholder]="'Enter your ' + originator.refFieldName + ' here'" />
            <div
                class="error ms-1"
                *ngIf="
                    rfqForm.get('accountNumber')?.invalid &&
                    (rfqForm.get('accountNumber')?.dirty || rfqForm.get('accountNumber')?.touched || formSubmitted)
                ">
                <div *ngIf="rfqForm.get('accountNumber')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
                <div *ngIf="rfqForm.get('accountNumber')?.hasError('pattern')">Please enter a valid {{ originator.refFieldName }}</div>
            </div>
        </div>

        <!-- <div class="mb-3" *ngIf="originator.requireRef2">
            <label class="form-label" for="accountNumber2">{{ originator.refFieldName2 }}*</label>
            <input
                class="form-control"
                id="accountNumber2"
                type="text"
                formControlName="accountNumber2"
                [placeholder]="'Enter your ' + originator.refFieldName2 + ' here'" />
            <div
                class="error ms-1"
                *ngIf="
                    rfqForm.get('accountNumber2')?.invalid &&
                    (rfqForm.get('accountNumber2')?.dirty || rfqForm.get('accountNumber2')?.touched || formSubmitted)
                ">
                <div *ngIf="rfqForm.get('accountNumber2')?.hasError('required')">
                    {{ validationEmptyMsg }}
                </div>
                <div *ngIf="rfqForm.get('accountNumber2')?.hasError('pattern')">Please enter a valid {{ originator.refFieldName2 }}</div>
            </div>
        </div> -->

        <div class="mb-3" *ngIf="originator.hasSupplier">
            <label class="form-label" for="comments">Comments</label>
            <textarea
                class="form-control"
                id="comments"
                formControlName="comments"
                [placeholder]="placeholderTextNotes"
                maxlength="250"></textarea>
        </div>

        <div class="mb-3 form-check">
            <input class="form-check-input" type="checkbox" id="terms" formControlName="terms" />
            <label class="form-check-label" for="terms"
                >I accept the Buildxact
                <a href="https://www.buildxact.com/terms-and-conditions" title="Buildxact terms and conditions" target="_blank"
                    >Terms and Conditions
                </a>
                and I have read the
                <a href="https://www.buildxact.com/privacy-policy" title="Buildxact privacy policy" target="_blank">Privacy Policy</a>. I
                agree that I may be contacted about Buildxact products and offers and I can opt-out any time.
            </label>
            <div
                class="error"
                *ngIf="rfqForm.get('terms')?.invalid && (rfqForm.get('terms')?.dirty || rfqForm.get('terms')?.touched || formSubmitted)">
                <div *ngIf="rfqForm.get('terms')?.hasError('required')">Please accept terms and conditions.</div>
            </div>
        </div>

        <div class="btn-actions">
            <button id="btn-cancel-rfq" class="btn-bx-secondary-stroked me-2" type="button" (click)="onCancel()">Cancel</button>
            <button id="btn-send-rfq" class="btn-bx-primary" type="submit">Send request</button>
        </div>
    </form>
</div>
