import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    TakeOffCanvasComponent,
    Drawing,
    DrawingMode,
    DrawingShape,
    GetDefaultDepthUnit,
    TakeOffAdditionalMeasurements,
    TakeOffMeasurement,
    PitchChangedEventArgs,
    DrawingUnit,
} from '@bx-web/takeoff';
import { TakeOffConfig, TakeOffDrawingDetails, TakeOffScaleSettings, TakeOffUpdateStatus } from './domain';
import { TakeOffV2MeasurementService } from './take-off-v2-measurement.service';
import { TakeOffV2PlanService } from './take-off-v2-plan.service';
import { TakeOffV2Service } from './take-off-v2.service';
import { CommonModule } from '@angular/common';
import { TakeOffDrawingActionsComponent } from '../ui-take-off-drawing-actions/take-off-drawing-actions.component';
import { TakeOffDrawingDepthModule } from '../ui-take-off-drawing-depth/take-off-drawing-depth.component';
import { TakeOffV2ActionsModule } from '../ui-take-off-v2-actions/take-off-v2-actions.component';
import { TakeOffDrawingDetailsComponent } from '../ui-take-off-drawing-details/take-off-drawing-details.component';
import { TakeOffV2DrawingsInfoModule } from '../ui-take-off-v2-drawings-info/take-off-v2-drawings-info.component';
import { TakeOffV2PlanInfoModule } from '../ui-take-off-v2-plan-info/take-off-v2-plan-info.component';
import { TakeOffV2DrawingService } from './take-off-v2-drawing.service';
import {
    EstimateMeasurementsV2Component,
    EstimateMeasurementViewTypes,
    estimateMeasurementViewsConfig,
} from '@bx-web/estimate-measurement';
import {
    EstimateMeasurementStoresService,
    Plan,
    SharedTakeOffService,
    SharedTakeOffStoresService,
    BreezePlan,
} from '@bx-web/takeoff-shared';
import { ClickOutsidePanelsDirective, DialogParams, DialogService, HelperService, Originator } from '@bx-web/shared-utils';
import { RequestQuoteComponent } from '../request-quote/request-quote.component';

import { Uuid } from '@bx-web/graphql';
import { take } from 'rxjs/operators';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SaveMeasurementsComponent } from '../save-measurement/save-measurements.component';

@UntilDestroy()
@Component({
    selector: 'bx-web-take-off-v2',
    templateUrl: './take-off-v2.component.html',
    styleUrls: ['./take-off-v2.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TakeOffDrawingActionsComponent,
        TakeOffDrawingDepthModule,
        TakeOffV2ActionsModule,
        TakeOffV2PlanInfoModule,
        TakeOffCanvasComponent,
        TakeOffV2DrawingsInfoModule,
        TakeOffDrawingDetailsComponent,
        EstimateMeasurementsV2Component,
        ClickOutsidePanelsDirective,
        RequestQuoteComponent,
        NgScrollbarModule,
    ],
})
export class TakeOffV2Component implements OnInit, OnDestroy {
    public showMeasurementOverlayer = true;
    @Input() plans: BreezePlan[] = [];
    @Input() selectedPlan: BreezePlan | null = null;
    @Input() estimateId = '';
    @Input() sendQuoteCount = 0;
    @Input() sendQuoteLimit = 0;
    @Input() measurementLimit = 0;
    @Input() plansDebreezed: Plan[] | null = null;
    @Input() originatorRFQ?: Originator | null;
    @Input() sessionId?: Uuid;

    public action = new EventEmitter<boolean>();

    public isCanvasActivated$ = new BehaviorSubject<boolean>(false);
    // @ts-ignore TS2564
    public takeOffConfig: TakeOffConfig;
    // @ts-ignore TS2564
    public selectedPlan$: Observable<BreezePlan | null>;
    // @ts-ignore TS2564
    public selectedPlanDrawings$: Observable<Drawing[]>;
    // @ts-ignore TS2564
    public scaleSettings$: Observable<TakeOffScaleSettings>;
    // @ts-ignore TS2564
    public selectedMeasurement$: Observable<TakeOffMeasurement | null>;
    // @ts-ignore TS2564
    public hasDrawingsForSelectedMeasurement$: Observable<boolean>;

    // @ts-ignore TS2322
    public selectedDrawingDetails: TakeOffDrawingDetails = null;

    measurementsPanelVisible = true;

    public readonly measurementViewSettings = estimateMeasurementViewsConfig[EstimateMeasurementViewTypes.OverLayer];
    public readonly estimateMeasurementViewTypes = EstimateMeasurementViewTypes;
    public readonly drawingMode = DrawingMode;
    fileName = '';
    saveDrawingToMeasurement = false;
    takeOffInprogress = false;
    // @ts-ignore TS2564
    @ViewChild('takeOffCanvas') private readonly drawingCanvas: TakeOffCanvasComponent;

    constructor(
        private readonly takeOffV2Service: TakeOffV2Service,
        private readonly takeOffV2StoresService: SharedTakeOffStoresService,
        private readonly takeOffV2PlanService: TakeOffV2PlanService,
        private readonly takeOffV2DrawingService: TakeOffV2DrawingService,
        private readonly takeOffV2MeasurementService: TakeOffV2MeasurementService,
        private readonly sharedTakeoffService: SharedTakeOffService,
        private estimateMeasurementStoresService: EstimateMeasurementStoresService,
        private helperService: HelperService,
        private dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.sharedTakeoffService.init(this.estimateId, this.plans, this.plansDebreezed);
        if (this.selectedPlan) this.takeOffV2Service.setSelectedPlan(this.selectedPlan);
        this.selectedPlan$ = this.takeOffV2StoresService.selectedPlan$;
        this.selectedPlanDrawings$ = this.takeOffV2StoresService.shownDrawingsForSelectedPlan$;
        this.scaleSettings$ = this.takeOffV2StoresService.scaleSettings$;
        this.selectedMeasurement$ = this.takeOffV2StoresService.selectedMeasurement$;
        this.hasDrawingsForSelectedMeasurement$ = this.takeOffV2DrawingService.hasDrawingsForSelectedMeasurement$;
        this.#setDefaultConfig();
        this.#setFileName();
        this.selectedMeasurement$.pipe(untilDestroyed(this)).subscribe((measurement: TakeOffMeasurement | null) => {
            if (measurement) this.getTakeOffConfig(measurement);
        });

        // for click outside save
        this.takeOffV2StoresService.takeOffUpdateStatus$.subscribe((status) => {
            if (status === TakeOffUpdateStatus.Completed) this.saveDrawingToMeasurement = true;
        });
    }

    #setFileName() {
        this.fileName = this.plans.find((plan) => plan.PlanOrder2 === 1)?.PlanLabel ?? '';
    }

    #setDefaultConfig() {
        const config = this.takeOffV2Service.getConfig(DrawingUnit.m2);
        const shapInfo = this.takeOffV2Service.getShapeByUOM(DrawingUnit.m2);
        this.takeOffConfig = {
            ...config,
            uom: DrawingUnit.m2,
            depth: 0,
            depthUnit: GetDefaultDepthUnit(DrawingUnit.m2),
            shape: shapInfo.shape,
            canDrawShap: shapInfo.canDrawShap ?? false,
            canDrawLine: shapInfo.canDrawLine ?? false,
            canDrawPoint: shapInfo.canDrawPoint ?? false,
        };
    }

    private getTakeOffConfig(measurement: TakeOffMeasurement) {
        if (!measurement || this.takeOffConfig?.uom === measurement.uom) {
            return;
        }

        const config = this.takeOffConfig || this.takeOffV2Service.getConfig(measurement.uom);

        const shapInfo = this.takeOffV2Service.getShapeByUOM(measurement.uom);

        this.takeOffConfig = {
            ...config,
            uom: measurement.uom,
            depth: 0,
            depthUnit: GetDefaultDepthUnit(measurement.uom),
            // @ts-ignore TS2532
            shape: shapInfo.shape,
            // @ts-ignore TS2322
            canDrawShap: shapInfo.canDrawShap,
            // @ts-ignore TS2322
            canDrawLine: shapInfo.canDrawLine,
            // @ts-ignore TS2322
            canDrawPoint: shapInfo.canDrawPoint,
        };
    }

    ngOnDestroy(): void {
        this.sharedTakeoffService.cleanUp();
    }

    public onCanvasActivated() {
        this.isCanvasActivated$.next(true);
    }

    public onCentreCanvas() {
        this.drawingCanvas.fit();
    }

    public onShapeChanged(shape: string) {
        this.takeOffConfig = { ...this.takeOffConfig, shape: shape };
    }

    public onColorChanged(color: string) {
        this.takeOffConfig = { ...this.takeOffConfig, color: color };
    }

    public onPitchChanged(pitch: PitchChangedEventArgs) {
        this.takeOffConfig = { ...this.takeOffConfig, pitch: pitch.degrees, pitchRatio: pitch.ratio };
    }

    public onDeduct() {
        this.takeOffConfig = { ...this.takeOffConfig, negative: !this.takeOffConfig.negative };
    }

    public onDepthChanged(depth: number) {
        this.takeOffConfig = { ...this.takeOffConfig, depth: depth };
    }

    public onDepthUnitChanged(depthUnit: string) {
        this.takeOffConfig = { ...this.takeOffConfig, depthUnit: depthUnit };
    }

    public onSelectedPlanChanged(selectedPlanId: string) {
        this.takeOffV2PlanService.selectPlan(selectedPlanId);
    }

    public onSelectAdditionalMeasurements(additionalMeasurements: TakeOffAdditionalMeasurements) {
        this.takeOffV2StoresService.updateAdditionalMeasurements(additionalMeasurements);
    }

    public onDrawingContinue(drawing: Drawing) {
        this.takeOffV2MeasurementService.updateMeasurementForDrawing(drawing);
    }

    public onDrawingFinish(drawing: Drawing) {
        this.takeOffInprogress = false;
        // Point won't trigger drawing start or continue
        if (drawing.shape === DrawingShape.Point) {
            this.onDrawingContinue(drawing);
        }

        this.takeOffV2StoresService.addDrawing(drawing);
    }

    public onDrawingChanged(drawingDetails: TakeOffDrawingDetails) {
        const measurement = this.takeOffV2DrawingService.updateDrawingByDetails(drawingDetails);
        this.selectedDrawingDetails = { ...drawingDetails, measurement };
    }

    public onDrawingRemoved() {
        const drawingId = this.selectedDrawingDetails.id;
        // @ts-ignore TS2322
        this.selectedDrawingDetails = null;
        this.takeOffV2DrawingService.removeDrawingById(drawingId);
    }

    public onPopoverSwitchOff() {
        // @ts-ignore TS2322
        this.selectedDrawingDetails = null;
    }

    public onUndoLastDrawing() {
        this.takeOffV2DrawingService.removeLastDrawing();
    }

    saveDrawings() {
        //auto save on clicking outside the panel for a measurement
        if (this.saveDrawingToMeasurement && this.estimateMeasurementStoresService.snapshotSelectedMeasurement.id) {
            this.estimateMeasurementStoresService.saveMeasurement$.next(true);
            this.saveDrawingToMeasurement = false;
        }
    }

    onDrawingStart() {
        this.takeOffInprogress = true;
    }

    onDrawingRemove() {
        this.takeOffInprogress = false;
    }

    @HostListener('document:keydown', ['$event'])
    keyDown(event: KeyboardEvent) {
        if (event.code === 'Escape') {
            // ESC
            this.drawingCanvas.undo();
            // @ts-ignore TS2345
            this.onDrawingContinue(null);
        }
    }

    saveMeasurement() {
        if (!this.originatorRFQ || !this.sessionId) return;
        if (!this.estimateMeasurementStoresService.snapshotMeasurementCategories.length) {
            this.showAddMeasurementsDialog(true);
            return;
        }
        this.helperService.showModal(SaveMeasurementsComponent, {
            class: 'bx-dialog-styles',
            animated: false,
            initialState: { originator: this.originatorRFQ, sessionId: this.sessionId },
        });
    }

    sendQuoteRequest() {
        if (!this.originatorRFQ || !this.sessionId) return;
        if (!this.estimateMeasurementStoresService.snapshotMeasurementCategories.length) {
            this.showAddMeasurementsDialog();
            return;
        }
        if (this.sendQuoteCount >= this.sendQuoteLimit) {
            this.showSendQuoteLimitDialog();
        } else {
            const modalRef = this.helperService.showModal(RequestQuoteComponent, {
                class: 'bx-dialog-styles',
                animated: false,
                initialState: { originator: this.originatorRFQ, sessionId: this.sessionId },
            });
            modalRef.content?.action.pipe(take(1)).subscribe(() => this.sendQuoteCount++);
        }
    }

    showAddMeasurementsDialog(isSave = false) {
        const text =
            this.originatorRFQ?.hasSupplier && !isSave
                ? 'Please add measurements before sending a quote request to your supplier.'
                : 'Please add measurements before sending yourself the takeoff measurements.';
        const params: DialogParams = {
            title: 'You missed a step!',
            showCancel: false,
            text,
            okText: 'Okay',
            type: 'warning',
        };
        this.dialogService.show(params);
    }

    showSendQuoteLimitDialog() {
        const trialText =
            '<p>The 14 day trial gives you access to unlimited takeoffs, supplier price files, document versioning, job management and a lot more.</p>';
        const text = this.originatorRFQ?.hasSupplier
            ? `<p>We allow up to ${this.sendQuoteLimit} quote requests per session.</p><p>To send more requests, sign up for a free trial of Buildxact.</p>`
            : `<p>We allow up to ${this.sendQuoteLimit} emailed measurements per session.</p><p>To access the complete tool, sign up for a free trial of Buildxact.</p>`;
        const params: DialogParams = {
            title: `You've hit your limit.`,
            allowHtml: true,
            text: text + trialText,
            type: 'success',
            cancelText: 'Got it',
            okText: 'Learn More',
        };
        this.dialogService.show(params).then(
            () => {
                window.open('https://www.buildxact.com', '_blank');
            },
            () => ({})
        );
    }

    togglePanel() {
        this.measurementsPanelVisible = !this.measurementsPanelVisible;
        this.#setCanvasHeight();
    }

    #setCanvasHeight() {
        // Get the width and height of the container and resize canvas.
        const element = document.getElementById('takeoff-canvas-container');
        if (element) {
            const mesContainerWidth = document.getElementById('estimate-measurement-container')?.offsetWidth ?? 450;
            const plansContainerWidth = document.getElementById('plans-container')?.offsetWidth ?? 0;
            const measurementsContainer = this.measurementsPanelVisible ? mesContainerWidth : 0;
            const width = window.innerWidth - plansContainerWidth - measurementsContainer;
            this.drawingCanvas.resizeCanvas(width, element.offsetHeight);
        }
    }
}
