<div class="upload-zone">
    <p class="upload-zone__dropper-text">Drag & drop a .pdf file or <span class="upload-zone__link">Browse</span></p>
    <p class="upload-zone__max-size">Add a file that is less than {{ maxFileSizeMB }}MB</p>

    <input
        type="file"
        id="importPlans"
        class="upload-zone__input"
        #upload
        [accept]="acceptedFormat"
        (change)="onFileSelected(upload.files)" />
</div>
