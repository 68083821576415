import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DrawingShape, IsCubic, IsSquare, PitchChangedEventArgs, TakeOffMeasurement, TakeOffPitchDropdownComponent } from '@bx-web/takeoff';
import { TakeOffDrawingColorPickerComponent } from '../ui-take-off-drawing-color-picker/take-off-drawing-color-picker.component';
import { TakeOffConfig } from '../takeoff-measurements/domain';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TakeOffDrawingDepthModule } from '../ui-take-off-drawing-depth/take-off-drawing-depth.component';
import { ComponentChanges } from '@bx-web/shared-utils';

@Component({
    selector: 'bx-web-take-off-drawing-actions',
    templateUrl: './take-off-drawing-actions.component.html',
    styleUrls: ['./take-off-drawing-actions.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TooltipModule,
        TakeOffPitchDropdownComponent,
        TakeOffDrawingColorPickerComponent,
        PopoverModule,
        TakeOffDrawingDepthModule,
    ],
})
export class TakeOffDrawingActionsComponent implements OnChanges {
    @Input() config!: TakeOffConfig;
    @Input() measurement: TakeOffMeasurement | null = null;
    @Input() disabled = false;

    @Output() centreChanges = new EventEmitter();
    @Output() shapeChanges = new EventEmitter<string>();
    @Output() colorChanges = new EventEmitter<string>();
    @Output() pitchChanges = new EventEmitter<PitchChangedEventArgs>();
    @Output() deductionChanges = new EventEmitter();
    @Output() toggleLabelChanges = new EventEmitter();
    @Output() depthChanges = new EventEmitter<number>();
    @Output() depthUnitChanges = new EventEmitter<string>();

    isDepthEnabled = false;

    ngOnChanges(changes: ComponentChanges<TakeOffDrawingActionsComponent>): void {
        if (
            changes.config?.currentValue.shape !== changes.config?.previousValue?.shape ||
            changes.config?.currentValue.uom !== changes.config?.previousValue?.uom
        ) {
            this.isDepthEnabled = this.#checkIsDepthEnabled(this.config.uom.toLowerCase());
        }
    }

    public onCenterCanvas() {
        this.centreChanges.emit();
    }

    public onChangeShape(shape: string) {
        this.shapeChanges.emit(shape);
    }

    public onColorChanged(color: string) {
        this.colorChanges.emit(color);
    }

    public pitchChanged(pitch: PitchChangedEventArgs) {
        this.pitchChanges.emit(pitch);
    }

    public onDeduct() {
        this.deductionChanges.emit();
    }

    public onToggleLabel() {
        this.toggleLabelChanges.emit();
    }

    public onDepthChanged(depth: number) {
        this.depthChanges.emit(depth);
    }

    onDepthUnitChanged(unit: string) {
        this.depthUnitChanges.emit(unit);
    }

    #checkIsDepthEnabled(unit: string): boolean {
        return IsCubic(unit) || (IsSquare(unit) && this.config.shape === DrawingShape.Line);
    }
}
