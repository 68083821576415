import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as math from 'mathjs';

class imperial {
    feet = 0;
    inches = 0;
    numerator = 0;
    denominator = 0;
    negative = false;

    toString() {
        let value = '';
        value += this.negative ? '-' : '';
        value += this.feet ? this.feet.toString() + "'" : '';
        value += this.inches ? this.inches.toString() : '';
        value += this.numerator && this.numerator ? ' ' + this.numerator.toString() + '/' + this.denominator.toString() : '';
        value += this.inches || (this.numerator && this.denominator) ? '"' : '';
        return value || '0';
    }

    value(unit?: string) {
        let u = 12;
        if (unit && (unit === 'inch' || unit === 'i')) u = 1;
        const feet = this.feet || 0;
        const inches = this.inches || 0;
        const numerator = this.numerator || 0;
        const denominator = this.denominator || 0;
        let result = feet * 12 + inches;
        if (numerator && denominator) {
            let fraction = numerator / denominator;
            fraction = _closestFraction(fraction) ?? 0;
            result += fraction;
        }
        return (result / u) * (this.negative ? -1 : 1);
    }
}

const fractions = [
    0,
    0.015625, // 1⁄64”
    0.03125, // 1⁄32”
    0.046875, // 3⁄64”
    0.0625, // 1⁄16”
    0.078125, // 5⁄64”
    0.09375, // 3⁄32”
    0.109375, // 7⁄64”
    0.125, // 1⁄8”
    0.140625, // 9⁄64”
    0.15625, // 5⁄32”
    0.171875, // 11⁄64”
    0.1875, // 3⁄16”
    0.203125, // 13⁄64”
    0.21875, // 7⁄32”
    0.234375, // 15⁄64”
    0.25, // 1⁄4”
    0.265625, // 17⁄64”
    0.28125, // 9⁄32”
    0.296875, // 19⁄64”
    0.3125, // 5⁄16”
    0.328125, // 21⁄64”
    0.34375, // 11⁄32”
    0.359375, // 23⁄64”
    0.375, // 3⁄8”
    0.390625, // 25⁄64”
    0.40625, // 13⁄32”
    0.421875, // 27⁄64”
    0.4375, // 7⁄16”
    0.453125, // 29⁄64”
    0.46875, // 15⁄32”
    0.484375, // 31⁄64”
    0.5, // 1⁄2”
    0.515625, // 33⁄64”
    0.53125, // 17⁄32”
    0.546875, // 35⁄64”
    0.5625, // 9⁄16”
    0.578125, // 37⁄64”
    0.59375, // 19⁄32”
    0.609375, // 39⁄64”
    0.625, // 5⁄8”
    0.640625, // 41⁄64”
    0.65625, // 21⁄32”
    0.671875, // 43⁄64”
    0.6875, // 11⁄16”
    0.703125, // 45⁄64”
    0.71875, // 23⁄32”
    0.734375, // 47⁄64”
    0.75, // 3⁄4”
    0.765625, // 49⁄64”
    0.78125, // 25⁄32”
    0.796875, // 51⁄64”
    0.8125, // 13⁄16”
    0.828125, // 53⁄64”
    0.84375, // 27⁄32”
    0.859375, // 55⁄64”
    0.875, // 7⁄8”
    0.890625, // 57⁄64”
    0.90625, // 29⁄32”
    0.921875, // 59⁄64”
    0.9375, // 15⁄16”
    0.953125, // 61⁄64”
    0.96875, // 31⁄32”
    0.984375, // 63⁄64”
    1, // 1/1"
];

// @ts-ignore TS7006
function _closestFraction(val) {
    let curr = fractions[0];
    // @ts-ignore TS2532
    let diff = Math.abs(val - curr);
    for (let v = 0; v < fractions.length; v++) {
        // @ts-ignore TS2532
        const newdiff = Math.abs(val - fractions[v]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = fractions[v];
        }
    }
    return curr;
    /*
                let closest = Math.max.apply(null, fractions) //Get the highest number in arr in case it match nothing.

                for(let i = 0 i < fractions.length i++){ //Loop the array
                    if(fractions[i] >= val && fractions[i] < closest) closest = fractions[i] //Check if it's higher than your number, but lower than your closest value
                }

                return closest // return the value
    */
}

@Injectable({ providedIn: 'root' })
export class ImperialConverterService {
    service = {};
    cmPerInch = 2.54;
    mmPerInch = 25.4;
    decimalSeparator;

    constructor(@Inject(LOCALE_ID) public locale: string) {
        this.decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
    }

    parse(value: string | number, unit?: string) {
        if (typeof value === 'string') return this.parseString(value);
        else return this.parseValue(value, unit);
    }

    parseString(value: string) {
        const result = new imperial();

        let cn = ''; // current number
        let skip = false;
        let negative = false;

        if (value) {
            if (value[0] === '-') {
                negative = true;
                value = value.substr(1, value.length - 1);
            }

            value = value.replace(/''/g, '"');

            for (let i = 0; i < value.length; i++) {
                const c = value[i];
                if (c && this._isNumeric(c)) {
                    cn += c.toString();
                    skip = false;
                } else {
                    if (!skip) {
                        if (c === "'") {
                            if (cn) result.feet = parseInt(cn);
                        }
                        if (c === '"') {
                            if (cn) result.inches = parseInt(cn);
                        }
                        if (c === '/') {
                            if (cn) {
                                result.numerator = parseInt(cn);
                                cn = '';
                                const remainder = value.substr(i, value.length - 1).trim();
                                for (let r = 0; r < remainder.length; r++) {
                                    const rc = remainder[r];
                                    if (this._isNumeric(rc)) cn += rc;
                                }

                                if (cn) result.denominator = parseInt(cn);
                                cn = '';
                                break;
                            }
                        }
                        if (c === ' ') {
                            if (cn) {
                                if (result.feet === null) result.feet = parseInt(cn);
                                else if (result.inches === null) result.inches = parseInt(cn);
                                else if (result.numerator !== null && result.denominator === null) result.denominator = parseInt(cn);
                            }
                        }
                        skip = true;
                    }
                    cn = '';
                }
            }

            if (cn) {
                if (result.feet === null) result.feet = parseInt(cn);
                else if (result.inches === null) result.inches = parseInt(cn);
                else if (result.numerator !== null && result.denominator === null) result.denominator = parseInt(cn);
            }
        }

        result.feet = result.feet || 0;

        result.inches = result.inches || 0;

        result.numerator = result.numerator || 0;

        result.denominator = result.denominator || 0;
        result.negative = negative;

        return result;
    }

    parseValue(value: number, unit?: string) {
        const result = new imperial();
        if (!value) return result;
        let negative = false;
        if (value < 0) {
            negative = true;
            value = Math.abs(value);
        }

        let u = 12;
        if (unit && (unit === 'inch' || unit === 'i')) u = 1;

        const totalInches = value * u;

        let wholeInches = parseFloat(totalInches.toString().split(this.decimalSeparator)[0] ?? '');
        const decimalInches = totalInches - wholeInches;
        let decimalFeet = wholeInches / 12;

        let wholeFeet = parseFloat(decimalFeet.toString().split(this.decimalSeparator)[0] ?? '');
        decimalFeet -= wholeFeet;
        wholeInches = math.round(decimalFeet * 12, 0);

        const fraction: any = math.fraction(_closestFraction(decimalInches) ?? 0);
        if (fraction.n === 1 && fraction.d === 1) {
            fraction.n = 0;
            fraction.d = 0;
            if (wholeInches < 11) {
                wholeInches += 1;
            } else {
                wholeInches = 0;
                wholeFeet += 1;
            }
        }

        result.feet = wholeFeet;

        result.inches = wholeInches;
        result.numerator = fraction.n;
        result.denominator = fraction.d;
        result.negative = negative;
        return result;
    }

    _isNumeric(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
}
