import { filesize } from 'filesize';

export function getFileSizeInMB(size: number) {
    return size / 1024 ** 2;
}

export function getFileSizeInKB(size: number) {
    return Math.round((size / 1024) * 100) / 100;
}

export function getFileSizeFriendly(size: number) {
    return filesize(size);
}
