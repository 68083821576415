import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ScalePlans, ScalePlansResult, ScalePlansVariables } from './mutations/scale-plans.generated';
import { Uuid } from '@bx-web/graphql';

@Injectable({
    providedIn: 'root',
})
export class ScalePlansService {
    constructor(private readonly apollo: Apollo) {}

    scalePlans(scalePlansIn: ScalePlansInput) {
        return this.apollo.mutate<ScalePlansResult, ScalePlansVariables>({
            mutation: ScalePlans,
            variables: {
                model: scalePlansIn,
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
    }
}

export type ScalePlansInput = {
    iD_Plans: Uuid[];
    scale: number;
    scaleRatio: string;
    scaleUnit: string;
    vScale: number;
};
