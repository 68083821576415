import { Injectable } from '@angular/core';
import { Subscription, combineLatest, filter } from 'rxjs';
import { BreezePlan, Plan } from '../plan/entities/plans.model';
import { EstimateMeasurementStoresService } from '../estimate-measurement/estimate-measurement.stores';
import { TakeOffUpdateStatus } from './entities/take-off-update-status.enum';
import { SharedTakeOffStoresService } from './shared-take-off.stores';
import { EstimateViewStoresService } from '../estimate-measurement/estimate-view.stores';
import { EstimatePlansV2StoresService } from '../plan/estimate-plans-v2.stores';
import { EstimateMeasurementVM } from '../estimate-measurement/entities/estimate-measurement.model';

@Injectable({
    providedIn: 'root',
})
export class SharedTakeOffService {
    //#region constructor
    constructor(
        private sharedTakeOffStoresService: SharedTakeOffStoresService,
        private readonly estimateViewStoresService: EstimateViewStoresService,
        private estimatePlansV2StoresService: EstimatePlansV2StoresService,
        private estimateMeasurementStoresService: EstimateMeasurementStoresService
    ) {}

    public init(estimateId: string, breezePlans: BreezePlan[], plans: Plan[] | null) {
        this.estimateViewStoresService.estimate = { ID_Estimate: estimateId };
        this.sharedTakeOffStoresService.estimatePlans = breezePlans;
        if (plans) this.estimatePlansV2StoresService.addEstimatePlans(plans);
        this.watchSelectedEstimateMeasurementChanges();
        this.watchMeasurementDrawingChanges();
    }

    // @ts-ignore TS2564
    private subWatchSelectedEstimateMeasurement: Subscription;
    private watchSelectedEstimateMeasurementChanges() {
        this.subWatchSelectedEstimateMeasurement = combineLatest([
            this.estimateMeasurementStoresService.selectedMeasurement$,
            this.sharedTakeOffStoresService.takeOffUpdateStatus$,
        ]).subscribe(([selectedMeasurement, status]: [EstimateMeasurementVM, TakeOffUpdateStatus]) => {
            if (status !== TakeOffUpdateStatus.Completed) {
                return;
            }
            this.sharedTakeOffStoresService.selectedMeasurementChanged$.next(selectedMeasurement);
        });
    }

    public cleanUp() {
        if (this.subWatchSelectedEstimateMeasurement) {
            this.subWatchSelectedEstimateMeasurement.unsubscribe();
        }

        this.sharedTakeOffStoresService.cleanUp();
    }
    //#endregion Watchers

    private watchMeasurementDrawingChanges() {
        this.sharedTakeOffStoresService.takeOffUpdateStatus$
            .pipe(filter((status) => status === TakeOffUpdateStatus.StartPlanUpdated))
            .subscribe(() => {
                this.sharedTakeOffStoresService.addPlanMeasurement$.next(true);
            });
    }
}
