/* eslint-disable */
export type Uuid = string & {readonly [__brand: string]:"Uuid"}
export type DateString =  string & {readonly [__brand: string]:"Date"}
export type DateTime = DateString | Date
export type URL = string & {readonly [__brand: string]:"URL"}
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: { input: unknown; output: unknown; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: DateTime; output: DateTime; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: DateTime; output: DateTime; }
  /** The `Decimal` scalar type represents a decimal floating-point number. */
  Decimal: { input: number; output: number; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: number; output: number; }
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: { input: unknown; output: unknown; }
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: { input: unknown; output: unknown; }
  URL: { input: URL; output: URL; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File; }
  Uuid: { input: Uuid; output: Uuid; }
};

export type AccountManager = {
  __typename?: 'AccountManager';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_User: Scalars['Uuid']['output'];
  surname?: Maybe<Scalars['String']['output']>;
};

export type AccountingAttachment = {
  __typename?: 'AccountingAttachment';
  attachmentId: Scalars['Uuid']['output'];
  contentLength: Scalars['Int']['output'];
  fileName: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AccountingAttachmentFilterInput = {
  and?: InputMaybe<Array<AccountingAttachmentFilterInput>>;
  attachmentId?: InputMaybe<UuidOperationFilterInput>;
  contentLength?: InputMaybe<IntOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  mimeType?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountingAttachmentFilterInput>>;
  url?: InputMaybe<StringOperationFilterInput>;
};

export type AccountingAttachmentSortInput = {
  attachmentId?: InputMaybe<SortEnumType>;
  contentLength?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  mimeType?: InputMaybe<SortEnumType>;
  url?: InputMaybe<SortEnumType>;
};

export type AccountingContactDto = {
  __typename?: 'AccountingContactDto';
  contactId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type AccountingContactDtoFilterInput = {
  and?: InputMaybe<Array<AccountingContactDtoFilterInput>>;
  contactId?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AccountingContactDtoFilterInput>>;
};

export type AccountingContactDtoSortInput = {
  contactId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type AccountingInvoiceDto = {
  __typename?: 'AccountingInvoiceDto';
  amountCredited?: Maybe<Scalars['Decimal']['output']>;
  amountDue?: Maybe<Scalars['Decimal']['output']>;
  amountPaid?: Maybe<Scalars['Decimal']['output']>;
  contact?: Maybe<AccountingContactDto>;
  currencyCode: Scalars['String']['output'];
  currencyRate?: Maybe<Scalars['Decimal']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  hasAttachments: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  /** @deprecated Deprecated after release/191.0.0, use JobForInvoice. */
  job?: Maybe<Job>;
  jobForInvoice?: Maybe<JobForInvoice>;
  lineItems: Array<AccountingLineItemDto>;
  status: Scalars['String']['output'];
  subTotal?: Maybe<Scalars['Decimal']['output']>;
  total?: Maybe<Scalars['Decimal']['output']>;
  totalTax?: Maybe<Scalars['Decimal']['output']>;
  updatedDateUtc?: Maybe<Scalars['DateTime']['output']>;
};

export type AccountingInvoiceDtoFilterInput = {
  amountCredited?: InputMaybe<DecimalOperationFilterInput>;
  amountDue?: InputMaybe<DecimalOperationFilterInput>;
  amountPaid?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<AccountingInvoiceDtoFilterInput>>;
  contact?: InputMaybe<AccountingContactDtoFilterInput>;
  currencyCode?: InputMaybe<StringOperationFilterInput>;
  currencyRate?: InputMaybe<DecimalOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  dueDate?: InputMaybe<DateTimeOperationFilterInput>;
  hasAttachments?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  invoiceNumber?: InputMaybe<StringOperationFilterInput>;
  lineItems?: InputMaybe<ListFilterInputTypeOfAccountingLineItemDtoFilterInput>;
  or?: InputMaybe<Array<AccountingInvoiceDtoFilterInput>>;
  status?: InputMaybe<StringOperationFilterInput>;
  subTotal?: InputMaybe<DecimalOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  totalTax?: InputMaybe<DecimalOperationFilterInput>;
  updatedDateUtc?: InputMaybe<DateTimeOperationFilterInput>;
};

export type AccountingInvoiceDtoPage = {
  __typename?: 'AccountingInvoiceDtoPage';
  hasNextPage: Scalars['Boolean']['output'];
  invoices: Array<AccountingInvoiceDto>;
  nextPageNumber: Scalars['Int']['output'];
};

export type AccountingInvoiceDtoSortInput = {
  amountCredited?: InputMaybe<SortEnumType>;
  amountDue?: InputMaybe<SortEnumType>;
  amountPaid?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<AccountingContactDtoSortInput>;
  currencyCode?: InputMaybe<SortEnumType>;
  currencyRate?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  dueDate?: InputMaybe<SortEnumType>;
  hasAttachments?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  invoiceNumber?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  subTotal?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  totalTax?: InputMaybe<SortEnumType>;
  updatedDateUtc?: InputMaybe<SortEnumType>;
};

export type AccountingLineItemDto = {
  __typename?: 'AccountingLineItemDto';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  jobItem?: Maybe<JobItem>;
  jobNumber?: Maybe<Scalars['String']['output']>;
  lineAmount?: Maybe<Scalars['Decimal']['output']>;
  quantity?: Maybe<Scalars['Decimal']['output']>;
  taxAmount?: Maybe<Scalars['Decimal']['output']>;
  taxRate?: Maybe<Scalars['String']['output']>;
  unitAmount?: Maybe<Scalars['Decimal']['output']>;
};

export type AccountingLineItemDtoFilterInput = {
  and?: InputMaybe<Array<AccountingLineItemDtoFilterInput>>;
  category?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jobNumber?: InputMaybe<StringOperationFilterInput>;
  lineAmount?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<AccountingLineItemDtoFilterInput>>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  taxAmount?: InputMaybe<DecimalOperationFilterInput>;
  taxRate?: InputMaybe<StringOperationFilterInput>;
  unitAmount?: InputMaybe<DecimalOperationFilterInput>;
};

/** A segment of a collection. */
export type AccountingOutgoingInvoicesCollectionSegment = {
  __typename?: 'AccountingOutgoingInvoicesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<AccountingInvoiceDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type AccountingSyncSettingType = {
  __typename?: 'AccountingSyncSettingType';
  defaultInvoiceStatus?: Maybe<Scalars['String']['output']>;
  importAccountCodesList: Array<Scalars['String']['output']>;
  importAttachmentsWithInvoice: Scalars['Boolean']['output'];
  importJobCategoryTrackingCategory?: Maybe<Scalars['String']['output']>;
  importJobTrackingCategory?: Maybe<Scalars['String']['output']>;
  invoiceAccountCode?: Maybe<Scalars['String']['output']>;
  isImportEnabled: Scalars['Boolean']['output'];
  isInvoicesSync: Scalars['Boolean']['output'];
  isPurchaseOrderAccountCodeSeparate: Scalars['Boolean']['output'];
  isPurchaseOrderAttachmentsSync: Scalars['Boolean']['output'];
  isPurchaseOrdersSync: Scalars['Boolean']['output'];
  jobTrackingCategory?: Maybe<Scalars['String']['output']>;
  lastSyncDate?: Maybe<Scalars['DateTime']['output']>;
  organisationName?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  purchaseOrderAccountCode?: Maybe<Scalars['String']['output']>;
  purchaseOrderAccountCodeContractor?: Maybe<Scalars['String']['output']>;
  syncStartDate?: Maybe<Scalars['DateTime']['output']>;
  taxRateExpenseFree?: Maybe<Scalars['String']['output']>;
  taxRateExpenseTaxable?: Maybe<Scalars['String']['output']>;
  taxRateIncomeFree?: Maybe<Scalars['String']['output']>;
  taxRateIncomeTaxable?: Maybe<Scalars['String']['output']>;
  taxSettings?: Maybe<Scalars['String']['output']>;
};

export type ActivityCreateModelInput = {
  activityFeedId?: InputMaybe<Scalars['Uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Will need to be changed into a required field */
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  /** Use only when wanting to override a listed date of 'Now' */
  listedDate?: InputMaybe<Scalars['DateTime']['input']>;
  sourceEntity: SourceEntity;
  sourceId: Scalars['Uuid']['input'];
};

export type ActivityFeed = {
  __typename?: 'ActivityFeed';
  activityFeedId: Scalars['Uuid']['output'];
  activityFeedImages?: Maybe<ActivityFeedImageCollectionSegment>;
  commentInfo: CommentInfo;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Uuid']['output'];
  creator?: Maybe<ActivityFeedUser>;
  creatorType: CreatorType;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  editedAt?: Maybe<Scalars['DateTime']['output']>;
  guestUserFullName?: Maybe<Scalars['String']['output']>;
  isPublished: Scalars['Boolean']['output'];
  likeInfo?: Maybe<LikeInfo>;
  listedDate: Scalars['DateTime']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  sourceEntity: SourceEntity;
  sourceId: Scalars['Uuid']['output'];
  status: ActivityFeedStatus;
  tenantId: Scalars['Uuid']['output'];
};


export type ActivityFeedActivityFeedImagesArgs = {
  order?: InputMaybe<Array<ActivityFeedImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityFeedImageFilterInput>;
};

export type ActivityFeedAggregate = {
  __typename?: 'ActivityFeedAggregate';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  status: ActivityFeedStatus;
};

export type ActivityFeedAggregateSortInput = {
  count?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ActivityFeedCollectionSegment = {
  __typename?: 'ActivityFeedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ActivityFeed>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ActivityFeedComment = {
  __typename?: 'ActivityFeedComment';
  activityFeed: ActivityFeed;
  activityFeedCommentId: Scalars['Uuid']['output'];
  activityFeedId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Uuid']['output'];
  creator?: Maybe<ActivityFeedUser>;
  creatorType: CreatorType;
  description: Scalars['String']['output'];
  guestUserFullName?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  tenantId: Scalars['Uuid']['output'];
};

/** A segment of a collection. */
export type ActivityFeedCommentCollectionSegment = {
  __typename?: 'ActivityFeedCommentCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ActivityFeedComment>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ActivityFeedCommentFilterInput = {
  activityFeed?: InputMaybe<ActivityFeedFilterInput>;
  activityFeedCommentId?: InputMaybe<UuidOperationFilterInput>;
  activityFeedId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<ActivityFeedCommentFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  creatorType?: InputMaybe<CreatorTypeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  guestUserFullName?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ActivityFeedCommentFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type ActivityFeedCommentSortInput = {
  activityFeed?: InputMaybe<ActivityFeedSortInput>;
  activityFeedCommentId?: InputMaybe<SortEnumType>;
  activityFeedId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  creatorType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  guestUserFullName?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type ActivityFeedFilterInput = {
  activityFeedComments?: InputMaybe<ListFilterInputTypeOfActivityFeedCommentFilterInput>;
  activityFeedId?: InputMaybe<UuidOperationFilterInput>;
  activityFeedImages?: InputMaybe<ListFilterInputTypeOfActivityFeedImageFilterInput>;
  activityFeedLikes?: InputMaybe<ListFilterInputTypeOfActivityFeedLikeFilterInput>;
  and?: InputMaybe<Array<ActivityFeedFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  creatorType?: InputMaybe<CreatorTypeOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  editedAt?: InputMaybe<DateTimeOperationFilterInput>;
  guestUserFullName?: InputMaybe<StringOperationFilterInput>;
  isPublished?: InputMaybe<BooleanOperationFilterInput>;
  listedDate?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ActivityFeedFilterInput>>;
  sourceEntity?: InputMaybe<SourceEntityOperationFilterInput>;
  sourceId?: InputMaybe<UuidOperationFilterInput>;
  status?: InputMaybe<ActivityFeedStatusOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type ActivityFeedImage = {
  __typename?: 'ActivityFeedImage';
  activityFeedId: Scalars['Uuid']['output'];
  activityFeedImageId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Uuid']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  tenantId: Scalars['Uuid']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** A segment of a collection. */
export type ActivityFeedImageCollectionSegment = {
  __typename?: 'ActivityFeedImageCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ActivityFeedImage>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ActivityFeedImageError = Error & {
  __typename?: 'ActivityFeedImageError';
  imageId?: Maybe<Scalars['Uuid']['output']>;
  isExtensionInvalid: Scalars['Boolean']['output'];
  isFileTooLarge: Scalars['Boolean']['output'];
  maxFileSize: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  validImageExtensions: Array<ValidImageExtensions>;
};

export type ActivityFeedImageFilterInput = {
  activityFeed?: InputMaybe<ActivityFeedFilterInput>;
  activityFeedId?: InputMaybe<UuidOperationFilterInput>;
  activityFeedImageId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<ActivityFeedImageFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ActivityFeedImageFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  thumbnailUrl?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
};

export type ActivityFeedImageSortInput = {
  activityFeed?: InputMaybe<ActivityFeedSortInput>;
  activityFeedId?: InputMaybe<SortEnumType>;
  activityFeedImageId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  thumbnailUrl?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  url?: InputMaybe<SortEnumType>;
};

export type ActivityFeedLike = {
  __typename?: 'ActivityFeedLike';
  activityFeed: ActivityFeed;
  activityFeedId: Scalars['Uuid']['output'];
  activityFeedLikeId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Uuid']['output'];
  creator?: Maybe<ActivityFeedUser>;
  creatorType: CreatorType;
  guestUserFullName?: Maybe<Scalars['String']['output']>;
  isLiked: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  tenantId: Scalars['Uuid']['output'];
};

/** A segment of a collection. */
export type ActivityFeedLikeCollectionSegment = {
  __typename?: 'ActivityFeedLikeCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ActivityFeedLike>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ActivityFeedLikeFilterInput = {
  activityFeed?: InputMaybe<ActivityFeedFilterInput>;
  activityFeedId?: InputMaybe<UuidOperationFilterInput>;
  activityFeedLikeId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<ActivityFeedLikeFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  creatorType?: InputMaybe<CreatorTypeOperationFilterInput>;
  guestUserFullName?: InputMaybe<StringOperationFilterInput>;
  isLiked?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ActivityFeedLikeFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type ActivityFeedLikeSortInput = {
  activityFeed?: InputMaybe<ActivityFeedSortInput>;
  activityFeedId?: InputMaybe<SortEnumType>;
  activityFeedLikeId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  creatorType?: InputMaybe<SortEnumType>;
  guestUserFullName?: InputMaybe<SortEnumType>;
  isLiked?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type ActivityFeedShareModelInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  images: Array<CopyActivityFeedImageModelInput>;
  originalActivityFeedId: Scalars['Uuid']['input'];
  sharedActivityFeedId: Scalars['Uuid']['input'];
};

export type ActivityFeedSortInput = {
  activityFeedId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  creatorType?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  editedAt?: InputMaybe<SortEnumType>;
  guestUserFullName?: InputMaybe<SortEnumType>;
  isPublished?: InputMaybe<SortEnumType>;
  listedDate?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  sourceEntity?: InputMaybe<SortEnumType>;
  sourceId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export const ActivityFeedStatus = {
  NotShared: 'NOT_SHARED',
  ReadyToShare: 'READY_TO_SHARE',
  SharedToClientPortal: 'SHARED_TO_CLIENT_PORTAL'
} as const;

export type ActivityFeedStatus = typeof ActivityFeedStatus[keyof typeof ActivityFeedStatus];
export type ActivityFeedStatusError = Error & {
  __typename?: 'ActivityFeedStatusError';
  message: Scalars['String']['output'];
  validStatues: Array<ActivityFeedStatus>;
};

export type ActivityFeedStatusOperationFilterInput = {
  eq?: InputMaybe<ActivityFeedStatus>;
  in?: InputMaybe<Array<ActivityFeedStatus>>;
  neq?: InputMaybe<ActivityFeedStatus>;
  nin?: InputMaybe<Array<ActivityFeedStatus>>;
};

export type ActivityFeedUser = BxUser | GuestUser;

export type ActivityUpdateModelInput = {
  activityFeedId: Scalars['Uuid']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  listedDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AddBuilderToClientProjectResponse = {
  __typename?: 'AddBuilderToClientProjectResponse';
  projectId?: Maybe<Scalars['Uuid']['output']>;
  signupRequired: Scalars['Boolean']['output'];
  tokenForSignup?: Maybe<Scalars['Uuid']['output']>;
};

export type AddCatalogueAccessControlModelInput = {
  externalRef?: InputMaybe<Scalars['String']['input']>;
  iD_Catalogue: Scalars['Uuid']['input'];
  iD_CatalogueAccessControl: Scalars['Uuid']['input'];
  isPreApproved: Scalars['Boolean']['input'];
};

export type AddCatalogueCategoryModelInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  iD_CatalogueCategory: Scalars['Uuid']['input'];
  iD_Parent?: InputMaybe<Scalars['Uuid']['input']>;
  orderNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type AddCatalogueItemModelInput = {
  availability: CatalogueItemAvailability;
  costBreakdown?: InputMaybe<Scalars['String']['input']>;
  costItemType?: InputMaybe<Scalars['String']['input']>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  iD_Catalogue: Scalars['Uuid']['input'];
  iD_CatalogueCategory?: InputMaybe<Scalars['Uuid']['input']>;
  iD_CatalogueItem: Scalars['Uuid']['input'];
  iD_CatalogueSubCategory?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Image?: InputMaybe<Scalars['Uuid']['input']>;
  imageThumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  instructionsUrl?: InputMaybe<Scalars['String']['input']>;
  isRecipe: Scalars['Boolean']['input'];
  itemDetailsUrl?: InputMaybe<Scalars['String']['input']>;
  manufCode?: InputMaybe<Scalars['String']['input']>;
  manufDescription?: InputMaybe<Scalars['String']['input']>;
  manufRRP?: InputMaybe<Scalars['Decimal']['input']>;
  manufRange?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  recipeItems?: InputMaybe<Array<InputMaybe<AddRecipeItemModelInput>>>;
  status: CatalogueItemStatus;
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  supplierMetadata?: InputMaybe<Scalars['String']['input']>;
  tempItemCode?: InputMaybe<Scalars['String']['input']>;
  uOM?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1Conversion?: InputMaybe<Scalars['Decimal']['input']>;
  uOMAlt2?: InputMaybe<Scalars['String']['input']>;
  uOMAlt2Conversion?: InputMaybe<Scalars['Decimal']['input']>;
  uOMMinOrderQty?: InputMaybe<Scalars['Decimal']['input']>;
  uOMOrderIncrements?: InputMaybe<Scalars['Decimal']['input']>;
  unitCost?: InputMaybe<Scalars['Decimal']['input']>;
};

export type AddCatalogueItemPriceModelInput = {
  iD_CatalogueItem: Scalars['Uuid']['input'];
  iD_CatalogueItemPrice: Scalars['Uuid']['input'];
  price: Scalars['Decimal']['input'];
};

export type AddCatalogueModelInput = {
  catalogueType: CatalogueType;
  colour?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  iD_Catalogue: Scalars['Uuid']['input'];
  iD_Contact?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Related?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Supplier?: InputMaybe<Scalars['Uuid']['input']>;
  isCategorized: Scalars['Boolean']['input'];
  logoThumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  scope: CatalogueScope;
  status: CatalogueStatus;
  syncEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  syncMapping?: InputMaybe<Scalars['String']['input']>;
  syncTimeOfDayHour?: InputMaybe<Scalars['Short']['input']>;
  syncTimeOfDayMinute?: InputMaybe<Scalars['Short']['input']>;
  syncUrl?: InputMaybe<Scalars['String']['input']>;
  syncUseMapping?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddCatalogueSubCategoryModelInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  iD_CatalogueCategory: Scalars['Uuid']['input'];
  iD_CatalogueSubCategory: Scalars['Uuid']['input'];
};

export type AddImportedInvoiceMappingError = ValidationError;

export type AddImportedInvoiceMappingInput = {
  invoiceId: Scalars['String']['input'];
  lineItemId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['Uuid']['input']>;
  provider: IntegrationProviderEnum;
  status: ImportedInvoiceMappingStatus;
};

export type AddImportedInvoiceMappingPayload = {
  __typename?: 'AddImportedInvoiceMappingPayload';
  errors?: Maybe<Array<AddImportedInvoiceMappingError>>;
  importedInvoiceMapping?: Maybe<ImportedInvoiceMapping>;
};

export type AddIntegrationProviderModelInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalRefIsPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  iD_IntegrationProvider: Scalars['Uuid']['input'];
  iD_Tenant?: InputMaybe<Scalars['Uuid']['input']>;
  isActive: Scalars['Boolean']['input'];
  isDisplayed: Scalars['Boolean']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  refFieldName?: InputMaybe<Scalars['String']['input']>;
  refFieldName2?: InputMaybe<Scalars['String']['input']>;
  refFieldRegEx?: InputMaybe<Scalars['String']['input']>;
  refFieldRegEx2?: InputMaybe<Scalars['String']['input']>;
  requireRef: Scalars['Boolean']['input'];
  requireRef2: Scalars['Boolean']['input'];
  requiresOtpVerification?: InputMaybe<Scalars['Boolean']['input']>;
  smallLogoUrl?: InputMaybe<Scalars['String']['input']>;
  supplierIdentifier?: InputMaybe<Scalars['String']['input']>;
  supportsLivePricing: Scalars['Boolean']['input'];
};

export type AddNonWorkingDayError = DuplicateError | ValidationError;

export type AddNonWorkingDayInput = {
  date: Scalars['DateTime']['input'];
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type AddNonWorkingDayPayload = {
  __typename?: 'AddNonWorkingDayPayload';
  errors?: Maybe<Array<AddNonWorkingDayError>>;
  nonWorkingDay?: Maybe<NonWorkingDay>;
};

export type AddPlanMeasurementModelInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  depth: Scalars['Float']['input'];
  depthUnit?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  iD_Measurement?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Plan: Scalars['Uuid']['input'];
  isNegative: Scalars['Boolean']['input'];
  measurement: Scalars['Float']['input'];
  measurementUnit?: InputMaybe<Scalars['String']['input']>;
  pitch: Scalars['Float']['input'];
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  shapeType?: InputMaybe<Scalars['String']['input']>;
};

export type AddRecipeItemModelInput = {
  costCategory?: InputMaybe<Scalars['String']['input']>;
  costItemType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  iD_CatalogueItem?: InputMaybe<Scalars['Uuid']['input']>;
  iD_ParentCatalogueItem: Scalars['Uuid']['input'];
  iD_RecipeItem: Scalars['Uuid']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  manufCode?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  quantity: Scalars['Decimal']['input'];
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  supplierMetadata?: InputMaybe<Scalars['String']['input']>;
  totalCost: Scalars['Decimal']['input'];
  uOM?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1Conversion?: InputMaybe<Scalars['Decimal']['input']>;
  uOMAlt2?: InputMaybe<Scalars['String']['input']>;
  uOMAlt2Conversion?: InputMaybe<Scalars['Decimal']['input']>;
  uOMMinOrderQty?: InputMaybe<Scalars['Decimal']['input']>;
  uOMOrderIncrements?: InputMaybe<Scalars['Decimal']['input']>;
  unitCost: Scalars['Decimal']['input'];
  unitsExpression?: InputMaybe<Scalars['String']['input']>;
};

export type AddScheduleTaskContactError = DuplicateError | EntityNotFoundError | ScheduleTaskContactNotFound | ScheduleTaskNotFound | ValidationError;

export type AddScheduleTaskContactInput = {
  model: Array<ScheduleTaskContactCreateModelInput>;
};

export type AddScheduleTaskContactPayload = {
  __typename?: 'AddScheduleTaskContactPayload';
  errors?: Maybe<Array<AddScheduleTaskContactError>>;
  scheduleTaskContact?: Maybe<Array<ScheduleTaskContact>>;
};

export type AddSpecificationPresetV3Error = NotFoundError | PropertyError | ValidationError;

export type AddSpecificationPresetV3Input = {
  model: CreateSpecificationPresetInput;
};

export type AddSpecificationPresetV3Payload = {
  __typename?: 'AddSpecificationPresetV3Payload';
  errors?: Maybe<Array<AddSpecificationPresetV3Error>>;
  specification?: Maybe<Specification>;
};

export type AddressModel = {
  __typename?: 'AddressModel';
  address1?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type AddressModelFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AddressModelFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AddressModelFilterInput>>;
  postcode?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
};

export type AddressModelInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address1?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cityTown?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Decimal']['input']>;
  longitude?: InputMaybe<Scalars['Decimal']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type AddressModelSortInput = {
  address1?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  postcode?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
};

export const AggregateMetricRangeEnum = {
  Lastquarter: 'LASTQUARTER',
  Lastyear: 'LASTYEAR',
  Thisquarter: 'THISQUARTER',
  Thisyear: 'THISYEAR'
} as const;

export type AggregateMetricRangeEnum = typeof AggregateMetricRangeEnum[keyof typeof AggregateMetricRangeEnum];
/** A segment of a collection. */
export type AllCollectionSegment = {
  __typename?: 'AllCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScheduleTask>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type AllowanceModelForMergeTags = {
  __typename?: 'AllowanceModelForMergeTags';
  description?: Maybe<Scalars['String']['output']>;
  displayOrderNumber?: Maybe<Scalars['String']['output']>;
  isMarkupRedistributed: Scalars['Boolean']['output'];
  itemNumber?: Maybe<Scalars['Int']['output']>;
  markup: Scalars['Float']['output'];
  markupTotal: Scalars['Decimal']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  totalExcTax?: Maybe<Scalars['Decimal']['output']>;
  totalIncTax?: Maybe<Scalars['Decimal']['output']>;
  totalIncTaxAndMarkup?: Maybe<Scalars['Decimal']['output']>;
  totalTax?: Maybe<Scalars['Decimal']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['Decimal']['output']>;
};

export type AlreadyLikedError = Error & {
  __typename?: 'AlreadyLikedError';
  message: Scalars['String']['output'];
};

export type AlreadyUnlikedError = Error & {
  __typename?: 'AlreadyUnlikedError';
  message: Scalars['String']['output'];
};

export type AnalyticFilterOptions = {
  __typename?: 'AnalyticFilterOptions';
  location?: Maybe<Scalars['String']['output']>;
  radius: Scalars['Int']['output'];
  range: AggregateMetricRangeEnum;
};

export type AnalyticFilterUpdateOptionsInput = {
  location?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  range?: InputMaybe<AggregateMetricRangeEnum>;
};

export type AnalyticLocation = {
  __typename?: 'AnalyticLocation';
  description?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
};

export const AnalyticsDataSet = {
  Buildingtypes: 'BUILDINGTYPES',
  Estimateswon: 'ESTIMATESWON',
  Jobprogress: 'JOBPROGRESS',
  Material: 'MATERIAL'
} as const;

export type AnalyticsDataSet = typeof AnalyticsDataSet[keyof typeof AnalyticsDataSet];
export type AppliedScheduleTemplate = {
  __typename?: 'AppliedScheduleTemplate';
  dependencies?: Maybe<ScheduleTaskDependencyCollectionSegment>;
  tasks?: Maybe<ScheduleTaskCollectionSegment>;
};


export type AppliedScheduleTemplateDependenciesArgs = {
  order?: InputMaybe<Array<ScheduleTaskDependencySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskDependencyFilterInput>;
};


export type AppliedScheduleTemplateTasksArgs = {
  order?: InputMaybe<Array<ScheduleTaskSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};

/** Defines when a policy shall be executed. */
export const ApplyPolicy = {
  /** After the resolver was executed. */
  AfterResolver: 'AFTER_RESOLVER',
  /** Before the resolver was executed. */
  BeforeResolver: 'BEFORE_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation: 'VALIDATION'
} as const;

export type ApplyPolicy = typeof ApplyPolicy[keyof typeof ApplyPolicy];
export type ApplyScheduleTemplateError = ReferenceIdError | ScheduleLockedError | ScheduleTaskTemplateNotFound | ValidationError;

export type ApplyScheduleTemplateInput = {
  model: ScheduleTemplateApplyModelInput;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type ApplyScheduleTemplatePayload = {
  __typename?: 'ApplyScheduleTemplatePayload';
  appliedScheduleTemplate?: Maybe<AppliedScheduleTemplate>;
  errors?: Maybe<Array<ApplyScheduleTemplateError>>;
};

/** A segment of a collection. */
export type AssigneesCollectionSegment = {
  __typename?: 'AssigneesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScheduleTaskContact>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type AutoCcSearchResultModel = {
  __typename?: 'AutoCcSearchResultModel';
  name: Scalars['String']['output'];
  quoteRequestEmail: Scalars['String']['output'];
  sourceEmail: Scalars['String']['output'];
};

export type BadgesDto = {
  __typename?: 'BadgesDto';
  applicationIntegrations: Scalars['Int']['output'];
  catalogues: Scalars['Int']['output'];
  conversations: Scalars['Int']['output'];
  estimates: Scalars['Int']['output'];
  integrations: Scalars['Int']['output'];
  jobs: Scalars['Int']['output'];
  rfqs: Scalars['Int']['output'];
};

export type BaseEstimateModelInput = {
  buildingType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  estimateId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BuildingType = {
  __typename?: 'BuildingType';
  description: Scalars['String']['output'];
  iD_BuildingType: Scalars['Uuid']['output'];
  isForeignOwned: Scalars['Boolean']['output'];
};

export type BuildingTypeCreateModelInput = {
  buildingTypeId?: InputMaybe<Scalars['Uuid']['input']>;
  description: Scalars['String']['input'];
};

export type BuildingTypeFilterInput = {
  and?: InputMaybe<Array<BuildingTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_BuildingType?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  isSeedData?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<BuildingTypeFilterInput>>;
};

export type BuildingTypeMetrics = {
  __typename?: 'BuildingTypeMetrics';
  builders: Scalars['Decimal']['output'];
  buildingType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  jobs: Scalars['Decimal']['output'];
  location?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type BuildingTypeMetricsConnection = {
  __typename?: 'BuildingTypeMetricsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BuildingTypeMetricsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<BuildingTypeMetrics>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BuildingTypeMetricsEdge = {
  __typename?: 'BuildingTypeMetricsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<BuildingTypeMetrics>;
};

export type BuildingTypeMetricsFilterInput = {
  and?: InputMaybe<Array<BuildingTypeMetricsFilterInput>>;
  builders?: InputMaybe<DecimalOperationFilterInput>;
  buildingType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  jobs?: InputMaybe<DecimalOperationFilterInput>;
  location?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BuildingTypeMetricsFilterInput>>;
};

export type BuildingTypeMetricsSortInput = {
  builders?: InputMaybe<SortEnumType>;
  buildingType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  jobs?: InputMaybe<SortEnumType>;
  location?: InputMaybe<SortEnumType>;
};

export type BuildingTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  iD_BuildingType?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  isSeedData?: InputMaybe<SortEnumType>;
};

export type BuildingTypeUpdateModelInput = {
  buildingTypeId: Scalars['Uuid']['input'];
  description: Scalars['String']['input'];
};

/** A segment of a collection. */
export type BuildingTypesCollectionSegment = {
  __typename?: 'BuildingTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<BuildingType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type BxUser = {
  __typename?: 'BxUser';
  avatarImageUrl?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_Tenant: Scalars['Uuid']['output'];
  iD_User: Scalars['Uuid']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isMobileVisibleToOthers: Scalars['Boolean']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  mobileVerified: Scalars['Boolean']['output'];
  surname?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']['input']>;
  gt?: InputMaybe<Scalars['Byte']['input']>;
  gte?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  lt?: InputMaybe<Scalars['Byte']['input']>;
  lte?: InputMaybe<Scalars['Byte']['input']>;
  neq?: InputMaybe<Scalars['Byte']['input']>;
  ngt?: InputMaybe<Scalars['Byte']['input']>;
  ngte?: InputMaybe<Scalars['Byte']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  nlt?: InputMaybe<Scalars['Byte']['input']>;
  nlte?: InputMaybe<Scalars['Byte']['input']>;
};

export type Catalogue = {
  __typename?: 'Catalogue';
  activeItemCount?: Maybe<Scalars['Decimal']['output']>;
  canExport: Scalars['Boolean']['output'];
  catalogueType: CatalogueType;
  colour?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  dateModified: Scalars['DateTime']['output'];
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  deletedByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['DateTime']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  fromClassification?: Maybe<CatalogueClassification>;
  fromSupplier: Scalars['Boolean']['output'];
  fromTenantName?: Maybe<Scalars['String']['output']>;
  iD_Catalogue: Scalars['Uuid']['output'];
  iD_Contact?: Maybe<Scalars['Uuid']['output']>;
  iD_Related?: Maybe<Scalars['Uuid']['output']>;
  iD_Supplier?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  importProgress?: Maybe<Scalars['Decimal']['output']>;
  isCategorized: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  lastImport?: Maybe<CatalogueImport>;
  logoThumbnailUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  rowVersion: Scalars['String']['output'];
  scope: CatalogueScope;
  scopeHiddenBy?: Maybe<Scalars['Uuid']['output']>;
  scopeHiddenByUsername?: Maybe<Scalars['String']['output']>;
  scopeHiddenDate?: Maybe<Scalars['DateTime']['output']>;
  scopeManagedBy?: Maybe<Scalars['Uuid']['output']>;
  scopeManagedByUsername?: Maybe<Scalars['String']['output']>;
  scopeManagedDate?: Maybe<Scalars['DateTime']['output']>;
  scopePublicBy?: Maybe<Scalars['Uuid']['output']>;
  scopePublicByUsername?: Maybe<Scalars['String']['output']>;
  scopePublicDate?: Maybe<Scalars['DateTime']['output']>;
  shortCode?: Maybe<Scalars['String']['output']>;
  status: CatalogueStatus;
  statusArchivedBy?: Maybe<Scalars['Uuid']['output']>;
  statusArchivedByUsername?: Maybe<Scalars['String']['output']>;
  statusArchivedDate?: Maybe<Scalars['DateTime']['output']>;
  statusDraftBy?: Maybe<Scalars['Uuid']['output']>;
  statusDraftByUsername?: Maybe<Scalars['String']['output']>;
  statusDraftDate?: Maybe<Scalars['DateTime']['output']>;
  statusExpiredBy?: Maybe<Scalars['Uuid']['output']>;
  statusExpiredByUsername?: Maybe<Scalars['String']['output']>;
  statusExpiredDate?: Maybe<Scalars['DateTime']['output']>;
  statusPublishedBy?: Maybe<Scalars['Uuid']['output']>;
  statusPublishedByUsername?: Maybe<Scalars['String']['output']>;
  statusPublishedDate?: Maybe<Scalars['DateTime']['output']>;
  supplierDescription?: Maybe<Scalars['String']['output']>;
  syncEnabled?: Maybe<Scalars['Boolean']['output']>;
  syncMapping?: Maybe<Scalars['String']['output']>;
  syncTimeOfDayHour?: Maybe<Scalars['Short']['output']>;
  syncTimeOfDayMinute?: Maybe<Scalars['Short']['output']>;
  syncUrl?: Maybe<Scalars['String']['output']>;
  syncUseMapping?: Maybe<Scalars['Boolean']['output']>;
};

export type CatalogueAccessControl = {
  __typename?: 'CatalogueAccessControl';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  externalRef?: Maybe<Scalars['String']['output']>;
  iD_Catalogue: Scalars['Uuid']['output'];
  iD_CatalogueAccessControl: Scalars['Uuid']['output'];
  isActive: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPreApproved: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
};

export type CatalogueCategory = {
  __typename?: 'CatalogueCategory';
  code?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  deletedByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_CatalogueCategory: Scalars['Uuid']['output'];
  iD_Parent?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['Int']['output']>;
};

export type CatalogueCategoryFilterInput = {
  and?: InputMaybe<Array<CatalogueCategoryFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_CatalogueCategory?: InputMaybe<UuidOperationFilterInput>;
  iD_Parent?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CatalogueCategoryFilterInput>>;
  orderNumber?: InputMaybe<IntOperationFilterInput>;
};

export type CatalogueCategoryItem = {
  __typename?: 'CatalogueCategoryItem';
  costItemCategory?: Maybe<Scalars['String']['output']>;
  costItemCategoryId?: Maybe<Scalars['Uuid']['output']>;
  costItemSubCategory?: Maybe<Scalars['String']['output']>;
  costItemSubCategoryId?: Maybe<Scalars['Uuid']['output']>;
  itemCount?: Maybe<Scalars['Int']['output']>;
};

export type CatalogueCategorySortInput = {
  code?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  iD_CatalogueCategory?: InputMaybe<SortEnumType>;
  iD_Parent?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  orderNumber?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  searchDescription?: InputMaybe<SortEnumType>;
};

export const CatalogueClassification = {
  Defaulttenant: 'DEFAULTTENANT',
  Franchisearea: 'FRANCHISEAREA',
  Integrationprovider: 'INTEGRATIONPROVIDER',
  Nulltenant: 'NULLTENANT',
  Referencetenant: 'REFERENCETENANT',
  Tenant: 'TENANT'
} as const;

export type CatalogueClassification = typeof CatalogueClassification[keyof typeof CatalogueClassification];
/** A connection to a list of items. */
export type CatalogueConnection = {
  __typename?: 'CatalogueConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CatalogueEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Catalogue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CatalogueEdge = {
  __typename?: 'CatalogueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Catalogue>;
};

export type CatalogueFilterInput = {
  and?: InputMaybe<Array<CatalogueFilterInput>>;
  canExport?: InputMaybe<BooleanOperationFilterInput>;
  catalogueType?: InputMaybe<CatalogueTypeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  effectiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  expiryDate?: InputMaybe<DateTimeOperationFilterInput>;
  fromClassification?: InputMaybe<NullableOfCatalogueClassificationOperationFilterInput>;
  fromSupplier?: InputMaybe<BooleanOperationFilterInput>;
  fromTenantName?: InputMaybe<StringOperationFilterInput>;
  iD_Catalogue?: InputMaybe<UuidOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_Related?: InputMaybe<UuidOperationFilterInput>;
  iD_Supplier?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CatalogueFilterInput>>;
  scope?: InputMaybe<CatalogueScopeOperationFilterInput>;
  scopeHiddenBy?: InputMaybe<UuidOperationFilterInput>;
  scopeHiddenDate?: InputMaybe<DateTimeOperationFilterInput>;
  scopeManagedBy?: InputMaybe<UuidOperationFilterInput>;
  scopeManagedDate?: InputMaybe<DateTimeOperationFilterInput>;
  scopePublicBy?: InputMaybe<UuidOperationFilterInput>;
  scopePublicDate?: InputMaybe<DateTimeOperationFilterInput>;
  shortCode?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<CatalogueStatusOperationFilterInput>;
  statusArchivedBy?: InputMaybe<UuidOperationFilterInput>;
  statusArchivedDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusDraftBy?: InputMaybe<UuidOperationFilterInput>;
  statusDraftDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusExpiredBy?: InputMaybe<UuidOperationFilterInput>;
  statusExpiredDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusPublishedBy?: InputMaybe<UuidOperationFilterInput>;
  statusPublishedDate?: InputMaybe<DateTimeOperationFilterInput>;
  syncEnabled?: InputMaybe<BooleanOperationFilterInput>;
  syncMapping?: InputMaybe<StringOperationFilterInput>;
  syncTimeOfDayHour?: InputMaybe<ShortOperationFilterInput>;
  syncTimeOfDayMinute?: InputMaybe<ShortOperationFilterInput>;
  syncUrl?: InputMaybe<StringOperationFilterInput>;
  syncUseMapping?: InputMaybe<BooleanOperationFilterInput>;
};

export type CatalogueImport = {
  __typename?: 'CatalogueImport';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  failureReason?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  firstWebhook?: Maybe<Scalars['DateTime']['output']>;
  iD_Batch: Scalars['Uuid']['output'];
  iD_Catalogue: Scalars['Uuid']['output'];
  iD_CatalogueImport: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  iD_User: Scalars['Uuid']['output'];
  importType: CatalogueImportType;
  importedRows: Scalars['Int']['output'];
  importingRows: Scalars['Int']['output'];
  jobId?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  originalRows: Scalars['Int']['output'];
  overwrite: Scalars['Boolean']['output'];
  overwriteItemVersion?: Maybe<Scalars['String']['output']>;
  overwriteRecipeVersion?: Maybe<Scalars['String']['output']>;
  previousId?: Maybe<Scalars['Uuid']['output']>;
  spaceId?: Maybe<Scalars['String']['output']>;
  status: CatalogueImportStatus;
};

/** A connection to a list of items. */
export type CatalogueImportConnection = {
  __typename?: 'CatalogueImportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CatalogueImportEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CatalogueImport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CatalogueImportEdge = {
  __typename?: 'CatalogueImportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CatalogueImport>;
};

export type CatalogueImportFilterInput = {
  and?: InputMaybe<Array<CatalogueImportFilterInput>>;
  completedAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  failureReason?: InputMaybe<StringOperationFilterInput>;
  filename?: InputMaybe<StringOperationFilterInput>;
  firstWebhook?: InputMaybe<DateTimeOperationFilterInput>;
  iD_Batch?: InputMaybe<UuidOperationFilterInput>;
  iD_Catalogue?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueImport?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  iD_User?: InputMaybe<UuidOperationFilterInput>;
  importType?: InputMaybe<CatalogueImportTypeOperationFilterInput>;
  importedRows?: InputMaybe<IntOperationFilterInput>;
  importingRows?: InputMaybe<IntOperationFilterInput>;
  jobId?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CatalogueImportFilterInput>>;
  originalRows?: InputMaybe<IntOperationFilterInput>;
  overwrite?: InputMaybe<BooleanOperationFilterInput>;
  previousId?: InputMaybe<UuidOperationFilterInput>;
  spaceId?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<CatalogueImportStatusOperationFilterInput>;
};

export type CatalogueImportSortInput = {
  completedAt?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  failureReason?: InputMaybe<SortEnumType>;
  filename?: InputMaybe<SortEnumType>;
  firstWebhook?: InputMaybe<SortEnumType>;
  iD_Batch?: InputMaybe<SortEnumType>;
  iD_Catalogue?: InputMaybe<SortEnumType>;
  iD_CatalogueImport?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  iD_User?: InputMaybe<SortEnumType>;
  importType?: InputMaybe<SortEnumType>;
  importedRows?: InputMaybe<SortEnumType>;
  importingRows?: InputMaybe<SortEnumType>;
  jobId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  originalRows?: InputMaybe<SortEnumType>;
  overwrite?: InputMaybe<SortEnumType>;
  overwriteItemVersion?: InputMaybe<SortEnumType>;
  overwriteRecipeVersion?: InputMaybe<SortEnumType>;
  previousId?: InputMaybe<SortEnumType>;
  spaceId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export const CatalogueImportStatus = {
  Completed: 'COMPLETED',
  Failed: 'FAILED',
  Importing: 'IMPORTING'
} as const;

export type CatalogueImportStatus = typeof CatalogueImportStatus[keyof typeof CatalogueImportStatus];
export type CatalogueImportStatusOperationFilterInput = {
  eq?: InputMaybe<CatalogueImportStatus>;
  in?: InputMaybe<Array<CatalogueImportStatus>>;
  neq?: InputMaybe<CatalogueImportStatus>;
  nin?: InputMaybe<Array<CatalogueImportStatus>>;
};

export const CatalogueImportType = {
  Accesscontrol: 'ACCESSCONTROL',
  Catalogue: 'CATALOGUE',
  Recipe: 'RECIPE'
} as const;

export type CatalogueImportType = typeof CatalogueImportType[keyof typeof CatalogueImportType];
export type CatalogueImportTypeOperationFilterInput = {
  eq?: InputMaybe<CatalogueImportType>;
  in?: InputMaybe<Array<CatalogueImportType>>;
  neq?: InputMaybe<CatalogueImportType>;
  nin?: InputMaybe<Array<CatalogueImportType>>;
};

export type CatalogueItem = {
  __typename?: 'CatalogueItem';
  availability: CatalogueItemAvailability;
  catalogue?: Maybe<Catalogue>;
  catalogueCategory?: Maybe<CatalogueCategory>;
  catalogueDescription?: Maybe<Scalars['String']['output']>;
  catalogueName?: Maybe<Scalars['String']['output']>;
  catalogueSubCategory?: Maybe<CatalogueSubCategory>;
  costBreakdown?: Maybe<Scalars['String']['output']>;
  costItemType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  datasheetUrl?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  deletedByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  iD_Catalogue?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueCategory?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueItem: Scalars['Uuid']['output'];
  iD_CatalogueSubCategory?: Maybe<Scalars['Uuid']['output']>;
  iD_Image?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  imageThumbnailUrl?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  instructionsUrl?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isRecipe: Scalars['Boolean']['output'];
  itemDetailsUrl?: Maybe<Scalars['String']['output']>;
  itemPrice?: Maybe<CatalogueItemPrice>;
  manufCode?: Maybe<Scalars['String']['output']>;
  manufDescription?: Maybe<Scalars['String']['output']>;
  manufRRP?: Maybe<Scalars['Decimal']['output']>;
  manufRange?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Decimal']['output']>;
  recipeItems?: Maybe<Array<RecipeItem>>;
  rowVersion: Scalars['String']['output'];
  status: CatalogueItemStatus;
  supplierCode?: Maybe<Scalars['String']['output']>;
  supplierMetadata?: Maybe<Scalars['String']['output']>;
  tempItemCode?: Maybe<Scalars['String']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  uOMAlt1?: Maybe<Scalars['String']['output']>;
  uOMAlt1Conversion?: Maybe<Scalars['Decimal']['output']>;
  uOMAlt2?: Maybe<Scalars['String']['output']>;
  uOMAlt2Conversion?: Maybe<Scalars['Decimal']['output']>;
  uOMMinOrderQty?: Maybe<Scalars['Decimal']['output']>;
  uOMOrderIncrements?: Maybe<Scalars['Decimal']['output']>;
  unitCost?: Maybe<Scalars['Decimal']['output']>;
};

export const CatalogueItemAvailability = {
  Byrequest: 'BYREQUEST',
  Orderable: 'ORDERABLE',
  Referenceonly: 'REFERENCEONLY'
} as const;

export type CatalogueItemAvailability = typeof CatalogueItemAvailability[keyof typeof CatalogueItemAvailability];
export type CatalogueItemAvailabilityOperationFilterInput = {
  eq?: InputMaybe<CatalogueItemAvailability>;
  in?: InputMaybe<Array<CatalogueItemAvailability>>;
  neq?: InputMaybe<CatalogueItemAvailability>;
  nin?: InputMaybe<Array<CatalogueItemAvailability>>;
};

/** A connection to a list of items. */
export type CatalogueItemConnection = {
  __typename?: 'CatalogueItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CatalogueItemEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CatalogueItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CatalogueItemEdge = {
  __typename?: 'CatalogueItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CatalogueItem>;
};

export type CatalogueItemFilterInput = {
  and?: InputMaybe<Array<CatalogueItemFilterInput>>;
  availability?: InputMaybe<CatalogueItemAvailabilityOperationFilterInput>;
  catalogueCategory?: InputMaybe<CatalogueCategoryFilterInput>;
  catalogueSubCategory?: InputMaybe<CatalogueSubCategoryFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  details?: InputMaybe<StringOperationFilterInput>;
  iD_Catalogue?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueCategory?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueItem?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueSubCategory?: InputMaybe<UuidOperationFilterInput>;
  iD_Image?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isRecipe?: InputMaybe<BooleanOperationFilterInput>;
  manufCode?: InputMaybe<StringOperationFilterInput>;
  manufDescription?: InputMaybe<StringOperationFilterInput>;
  manufRRP?: InputMaybe<DecimalOperationFilterInput>;
  manufRange?: InputMaybe<StringOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CatalogueItemFilterInput>>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  recipeItems?: InputMaybe<ListFilterInputTypeOfRecipeItemFilterInput>;
  status?: InputMaybe<CatalogueItemStatusOperationFilterInput>;
  supplierCode?: InputMaybe<StringOperationFilterInput>;
  supplierMetadata?: InputMaybe<StringOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  uOMAlt1?: InputMaybe<StringOperationFilterInput>;
  uOMAlt1Conversion?: InputMaybe<DecimalOperationFilterInput>;
  uOMAlt2?: InputMaybe<StringOperationFilterInput>;
  uOMAlt2Conversion?: InputMaybe<DecimalOperationFilterInput>;
  uOMMinOrderQty?: InputMaybe<DecimalOperationFilterInput>;
  uOMOrderIncrements?: InputMaybe<DecimalOperationFilterInput>;
  unitCost?: InputMaybe<DecimalOperationFilterInput>;
};

export type CatalogueItemPrice = {
  __typename?: 'CatalogueItemPrice';
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  iD_CatalogueItem: Scalars['Uuid']['output'];
  iD_CatalogueItemPrice: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  price: Scalars['Decimal']['output'];
  rowVersion: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type CatalogueItemPriceConnection = {
  __typename?: 'CatalogueItemPriceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CatalogueItemPriceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CatalogueItemPrice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CatalogueItemPriceEdge = {
  __typename?: 'CatalogueItemPriceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CatalogueItemPrice>;
};

export type CatalogueItemPriceFilterInput = {
  and?: InputMaybe<Array<CatalogueItemPriceFilterInput>>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  iD_CatalogueItem?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueItemPrice?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CatalogueItemPriceFilterInput>>;
  price?: InputMaybe<DecimalOperationFilterInput>;
};

export const CatalogueItemPriceOperationType = {
  Decrease: 'DECREASE',
  Divide: 'DIVIDE',
  Increase: 'INCREASE',
  Multiply: 'MULTIPLY'
} as const;

export type CatalogueItemPriceOperationType = typeof CatalogueItemPriceOperationType[keyof typeof CatalogueItemPriceOperationType];
export type CatalogueItemPriceSortInput = {
  createdBy?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  iD_CatalogueItem?: InputMaybe<SortEnumType>;
  iD_CatalogueItemPrice?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
};

export type CatalogueItemReference = {
  __typename?: 'CatalogueItemReference';
  estimates: Scalars['Boolean']['output'];
  iD_CatalogueItem: Scalars['Uuid']['output'];
  purchaseOrders: Scalars['Boolean']['output'];
  recipes: Scalars['Boolean']['output'];
};

export type CatalogueItemSortInput = {
  availability?: InputMaybe<SortEnumType>;
  catalogueCategory?: InputMaybe<CatalogueCategorySortInput>;
  catalogueSubCategory?: InputMaybe<CatalogueSubCategorySortInput>;
  costItemType?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  details?: InputMaybe<SortEnumType>;
  iD_Catalogue?: InputMaybe<SortEnumType>;
  iD_CatalogueCategory?: InputMaybe<SortEnumType>;
  iD_CatalogueItem?: InputMaybe<SortEnumType>;
  iD_CatalogueSubCategory?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isRecipe?: InputMaybe<SortEnumType>;
  manufCode?: InputMaybe<SortEnumType>;
  manufDescription?: InputMaybe<SortEnumType>;
  manufRRP?: InputMaybe<SortEnumType>;
  manufRange?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  supplierCode?: InputMaybe<SortEnumType>;
  supplierMetadata?: InputMaybe<SortEnumType>;
  uOM?: InputMaybe<SortEnumType>;
  uOMAlt1?: InputMaybe<SortEnumType>;
  uOMAlt1Conversion?: InputMaybe<SortEnumType>;
  uOMAlt2?: InputMaybe<SortEnumType>;
  uOMAlt2Conversion?: InputMaybe<SortEnumType>;
  uOMMinOrderQty?: InputMaybe<SortEnumType>;
  uOMOrderIncrements?: InputMaybe<SortEnumType>;
  unitCost?: InputMaybe<SortEnumType>;
};

export const CatalogueItemStatus = {
  Available: 'AVAILABLE',
  Deprecated: 'DEPRECATED',
  Unavailable: 'UNAVAILABLE'
} as const;

export type CatalogueItemStatus = typeof CatalogueItemStatus[keyof typeof CatalogueItemStatus];
export type CatalogueItemStatusOperationFilterInput = {
  eq?: InputMaybe<CatalogueItemStatus>;
  in?: InputMaybe<Array<CatalogueItemStatus>>;
  neq?: InputMaybe<CatalogueItemStatus>;
  nin?: InputMaybe<Array<CatalogueItemStatus>>;
};

export const CatalogueScope = {
  Hidden: 'HIDDEN',
  Managed: 'MANAGED',
  Public: 'PUBLIC'
} as const;

export type CatalogueScope = typeof CatalogueScope[keyof typeof CatalogueScope];
export type CatalogueScopeOperationFilterInput = {
  eq?: InputMaybe<CatalogueScope>;
  in?: InputMaybe<Array<CatalogueScope>>;
  neq?: InputMaybe<CatalogueScope>;
  nin?: InputMaybe<Array<CatalogueScope>>;
};

export type CatalogueSearchMatchResult = {
  __typename?: 'CatalogueSearchMatchResult';
  iD_EstimateItem: Scalars['Uuid']['output'];
  productId?: Maybe<Scalars['Int']['output']>;
};

export type CatalogueSearchResult = {
  __typename?: 'CatalogueSearchResult';
  matches: Array<CatalogueSearchMatchResult>;
  productList: Array<ProductSearchResult>;
};

export type CatalogueSortInput = {
  canExport?: InputMaybe<SortEnumType>;
  catalogueType?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  expiryDate?: InputMaybe<SortEnumType>;
  fromClassification?: InputMaybe<SortEnumType>;
  fromSupplier?: InputMaybe<SortEnumType>;
  fromTenantName?: InputMaybe<SortEnumType>;
  iD_Catalogue?: InputMaybe<SortEnumType>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_Related?: InputMaybe<SortEnumType>;
  iD_Supplier?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  scope?: InputMaybe<SortEnumType>;
  scopeHiddenBy?: InputMaybe<SortEnumType>;
  scopeHiddenDate?: InputMaybe<SortEnumType>;
  scopeManagedBy?: InputMaybe<SortEnumType>;
  scopeManagedDate?: InputMaybe<SortEnumType>;
  scopePublicBy?: InputMaybe<SortEnumType>;
  scopePublicDate?: InputMaybe<SortEnumType>;
  shortCode?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusArchivedBy?: InputMaybe<SortEnumType>;
  statusArchivedDate?: InputMaybe<SortEnumType>;
  statusDraftBy?: InputMaybe<SortEnumType>;
  statusDraftDate?: InputMaybe<SortEnumType>;
  statusExpiredBy?: InputMaybe<SortEnumType>;
  statusExpiredDate?: InputMaybe<SortEnumType>;
  statusPublishedBy?: InputMaybe<SortEnumType>;
  statusPublishedDate?: InputMaybe<SortEnumType>;
  syncEnabled?: InputMaybe<SortEnumType>;
  syncMapping?: InputMaybe<SortEnumType>;
  syncTimeOfDayHour?: InputMaybe<SortEnumType>;
  syncTimeOfDayMinute?: InputMaybe<SortEnumType>;
  syncUrl?: InputMaybe<SortEnumType>;
  syncUseMapping?: InputMaybe<SortEnumType>;
};

export const CatalogueStatus = {
  Archived: 'ARCHIVED',
  Draft: 'DRAFT',
  Expired: 'EXPIRED',
  Published: 'PUBLISHED'
} as const;

export type CatalogueStatus = typeof CatalogueStatus[keyof typeof CatalogueStatus];
export type CatalogueStatusOperationFilterInput = {
  eq?: InputMaybe<CatalogueStatus>;
  in?: InputMaybe<Array<CatalogueStatus>>;
  neq?: InputMaybe<CatalogueStatus>;
  nin?: InputMaybe<Array<CatalogueStatus>>;
};

export type CatalogueSubCategory = {
  __typename?: 'CatalogueSubCategory';
  code?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_CatalogueCategory: Scalars['Uuid']['output'];
  iD_CatalogueSubCategory: Scalars['Uuid']['output'];
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  rowVersion: Scalars['String']['output'];
};

export type CatalogueSubCategoryFilterInput = {
  and?: InputMaybe<Array<CatalogueSubCategoryFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_CatalogueCategory?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueSubCategory?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CatalogueSubCategoryFilterInput>>;
};

export type CatalogueSubCategorySortInput = {
  code?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  iD_CatalogueCategory?: InputMaybe<SortEnumType>;
  iD_CatalogueSubCategory?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  searchDescription?: InputMaybe<SortEnumType>;
};

export type CatalogueSubscription = {
  __typename?: 'CatalogueSubscription';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  iD_Catalogue: Scalars['Uuid']['output'];
  iD_CatalogueSubscription: Scalars['Uuid']['output'];
  iD_ClientTenant: Scalars['Uuid']['output'];
  iD_IntegrationConnection: Scalars['Uuid']['output'];
  iD_IntegrationProvider?: Maybe<Scalars['Uuid']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  status: CatalogueSubscriptionStatus;
};

/** A connection to a list of items. */
export type CatalogueSubscriptionConnection = {
  __typename?: 'CatalogueSubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CatalogueSubscriptionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<CatalogueSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CatalogueSubscriptionEdge = {
  __typename?: 'CatalogueSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CatalogueSubscription>;
};

export type CatalogueSubscriptionFilterInput = {
  and?: InputMaybe<Array<CatalogueSubscriptionFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  iD_Catalogue?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueSubscription?: InputMaybe<UuidOperationFilterInput>;
  iD_ClientTenant?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationConnection?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationProvider?: InputMaybe<UuidOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CatalogueSubscriptionFilterInput>>;
  status?: InputMaybe<CatalogueSubscriptionStatusOperationFilterInput>;
};

export type CatalogueSubscriptionSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  iD_Catalogue?: InputMaybe<SortEnumType>;
  iD_CatalogueSubscription?: InputMaybe<SortEnumType>;
  iD_ClientTenant?: InputMaybe<SortEnumType>;
  iD_IntegrationConnection?: InputMaybe<SortEnumType>;
  iD_IntegrationProvider?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export const CatalogueSubscriptionStatus = {
  Subscribed: 'SUBSCRIBED',
  Unsubscribed: 'UNSUBSCRIBED'
} as const;

export type CatalogueSubscriptionStatus = typeof CatalogueSubscriptionStatus[keyof typeof CatalogueSubscriptionStatus];
export type CatalogueSubscriptionStatusOperationFilterInput = {
  eq?: InputMaybe<CatalogueSubscriptionStatus>;
  in?: InputMaybe<Array<CatalogueSubscriptionStatus>>;
  neq?: InputMaybe<CatalogueSubscriptionStatus>;
  nin?: InputMaybe<Array<CatalogueSubscriptionStatus>>;
};

export const CatalogueType = {
  Catalogue: 'CATALOGUE',
  Recipe: 'RECIPE'
} as const;

export type CatalogueType = typeof CatalogueType[keyof typeof CatalogueType];
export type CatalogueTypeCount = {
  __typename?: 'CatalogueTypeCount';
  catalogueType: CatalogueType;
  count: Scalars['Int']['output'];
};

export type CatalogueTypeOperationFilterInput = {
  eq?: InputMaybe<CatalogueType>;
  in?: InputMaybe<Array<CatalogueType>>;
  neq?: InputMaybe<CatalogueType>;
  nin?: InputMaybe<Array<CatalogueType>>;
};

export type CataloguesGraphQlVersion = {
  __typename?: 'CataloguesGraphQLVersion';
  isNewVersion: Scalars['Boolean']['output'];
};

export type CategoriesWithAllowances = {
  __typename?: 'CategoriesWithAllowances';
  categories?: Maybe<Array<Maybe<CategoryWithAllowances>>>;
  total: Scalars['Decimal']['output'];
  totalIncTax: Scalars['Decimal']['output'];
  totalIncTaxAndMarkup: Scalars['Decimal']['output'];
};

export type CategoryItemModelForMergeTags = {
  __typename?: 'CategoryItemModelForMergeTags';
  description?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  itemCode?: Maybe<Scalars['String']['output']>;
  itemNumber?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  recipeItems?: Maybe<Array<Maybe<RecipeItem>>>;
  totalExcTax?: Maybe<Scalars['Decimal']['output']>;
  totalIncTax?: Maybe<Scalars['Decimal']['output']>;
  totalTax?: Maybe<Scalars['Decimal']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  unitCost?: Maybe<Scalars['Decimal']['output']>;
  unitCostIncMarkup?: Maybe<Scalars['Decimal']['output']>;
  units?: Maybe<Scalars['Decimal']['output']>;
};

export type CategoryModelForMergeTags = {
  __typename?: 'CategoryModelForMergeTags';
  categoryNumber?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  totalExcTax?: Maybe<Scalars['Decimal']['output']>;
  totalExcTaxAndMarkup?: Maybe<Scalars['Decimal']['output']>;
  totalIncTax?: Maybe<Scalars['Decimal']['output']>;
  totalTax?: Maybe<Scalars['Decimal']['output']>;
};

export type CategoryWithAllowances = {
  __typename?: 'CategoryWithAllowances';
  allowances?: Maybe<Array<Maybe<AllowanceModelForMergeTags>>>;
  description?: Maybe<Scalars['String']['output']>;
  index: Scalars['Int']['output'];
  total: Scalars['Decimal']['output'];
  totalIncTax: Scalars['Decimal']['output'];
  totalIncTaxAndMarkup: Scalars['Decimal']['output'];
};

export type CategoryWithItems = {
  __typename?: 'CategoryWithItems';
  categoryItems?: Maybe<Array<Maybe<CategoryItemModelForMergeTags>>>;
  categoryNumber?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  index: Scalars['Int']['output'];
  totalExcTax?: Maybe<Scalars['Decimal']['output']>;
  totalExcTaxAndMarkup?: Maybe<Scalars['Decimal']['output']>;
  totalIncTax?: Maybe<Scalars['Decimal']['output']>;
  totalTax?: Maybe<Scalars['Decimal']['output']>;
};

/** A segment of a collection. */
export type ChildrenCollectionSegment = {
  __typename?: 'ChildrenCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EstimateSpecification>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type CircularDependencyError = Error & {
  __typename?: 'CircularDependencyError';
  message: Scalars['String']['output'];
};

export type ClientConnectionIdStatus = {
  __typename?: 'ClientConnectionIdStatus';
  id: Scalars['Uuid']['output'];
  status: IntegrationConnectionStatus;
};

export type ClientConnectionIdStatusInput = {
  id: Scalars['Uuid']['input'];
  status: IntegrationConnectionStatus;
};

export type ClientEstimateDto = {
  __typename?: 'ClientEstimateDto';
  acceptedDate?: Maybe<Scalars['DateTime']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  buildingType?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  calculationMethod: Scalars['Int']['output'];
  canCopy: Scalars['Boolean']['output'];
  customerCreatedDate?: Maybe<Scalars['DateTime']['output']>;
  datePrepared?: Maybe<Scalars['DateTime']['output']>;
  defaultInvoiceDays?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  iD_Customer?: Maybe<Scalars['Uuid']['output']>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_Job?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  iD_TenantShare?: Maybe<Scalars['Uuid']['output']>;
  isAccepted: Scalars['Boolean']['output'];
  isAccessible: Scalars['Boolean']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isPCPSRedistDefault: Scalars['Boolean']['output'];
  isSampleData: Scalars['Boolean']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  taxRate: Scalars['Float']['output'];
  taxRatesDescription?: Maybe<Scalars['String']['output']>;
  taxRatesShortCode?: Maybe<Scalars['String']['output']>;
  totalIncMarkupTax?: Maybe<Scalars['Float']['output']>;
  totalIncMarkupTaxRange?: Maybe<Scalars['String']['output']>;
  workLocation?: Maybe<Scalars['String']['output']>;
};

export type ClientPortalSyncError = Error & {
  __typename?: 'ClientPortalSyncError';
  message: Scalars['String']['output'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type CommentInfo = {
  __typename?: 'CommentInfo';
  total: Scalars['Int']['output'];
};

export const CommunicationType = {
  Clientportal: 'CLIENTPORTAL'
} as const;

export type CommunicationType = typeof CommunicationType[keyof typeof CommunicationType];
export type Contact = {
  __typename?: 'Contact';
  accountingId?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_Contact?: Maybe<Scalars['Uuid']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** --FUSION ONLY PROPERTY-- Navigates to metadata */
  scheduleTasksDetails?: Maybe<ScheduleTasksGroupedByContact>;
};

export type ContactCardFilterInput = {
  and?: InputMaybe<Array<ContactCardFilterInput>>;
  cardDetails?: InputMaybe<StringOperationFilterInput>;
  cardName?: InputMaybe<StringOperationFilterInput>;
  cardType?: InputMaybe<StringOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  expiry?: InputMaybe<DateTimeOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_ContactCard?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ContactCardFilterInput>>;
};

export type ContactFilterInput = {
  aBN?: InputMaybe<StringOperationFilterInput>;
  accountCode?: InputMaybe<StringOperationFilterInput>;
  accountingId?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ContactFilterInput>>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  cardExpiryNext?: InputMaybe<DateTimeOperationFilterInput>;
  colorCode?: InputMaybe<StringOperationFilterInput>;
  contactCards?: InputMaybe<ListFilterInputTypeOfContactCardFilterInput>;
  contactGroup?: InputMaybe<StringOperationFilterInput>;
  contactPersons?: InputMaybe<ListFilterInputTypeOfContactPersonFilterInput>;
  contactType?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  deputyEmployeeID?: InputMaybe<LongOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  homePhone?: InputMaybe<StringOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  insuranceNotes?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  isRegisteredForTax?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  logo?: InputMaybe<ListByteOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactFilterInput>>;
  postCode?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  suburb?: InputMaybe<StringOperationFilterInput>;
  workPhone?: InputMaybe<StringOperationFilterInput>;
};

export type ContactPersonFilterInput = {
  and?: InputMaybe<Array<ContactPersonFilterInput>>;
  contact?: InputMaybe<ContactFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_ContactPerson?: InputMaybe<UuidOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactPersonFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
};

export type ContactSortInput = {
  aBN?: InputMaybe<SortEnumType>;
  accountCode?: InputMaybe<SortEnumType>;
  accountingId?: InputMaybe<SortEnumType>;
  address?: InputMaybe<SortEnumType>;
  businessName?: InputMaybe<SortEnumType>;
  cardExpiryNext?: InputMaybe<SortEnumType>;
  colorCode?: InputMaybe<SortEnumType>;
  contactGroup?: InputMaybe<SortEnumType>;
  contactType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  deputyEmployeeID?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  homePhone?: InputMaybe<SortEnumType>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  insuranceNotes?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGlobalSynced?: InputMaybe<SortEnumType>;
  isRegisteredForTax?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  postCode?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  suburb?: InputMaybe<SortEnumType>;
  workPhone?: InputMaybe<SortEnumType>;
};

export type ContactView = {
  __typename?: 'ContactView';
  businessName?: Maybe<Scalars['String']['output']>;
  iD_Contact: Scalars['Uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ContactViewFilterInput = {
  aBN?: InputMaybe<StringOperationFilterInput>;
  accountingId?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ContactViewFilterInput>>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  businessNumber?: InputMaybe<StringOperationFilterInput>;
  contactGroup?: InputMaybe<StringOperationFilterInput>;
  contactType?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  homePhone?: InputMaybe<StringOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  insuranceNotes?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  isRegisteredForTax?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactViewFilterInput>>;
  postCode?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  suburb?: InputMaybe<StringOperationFilterInput>;
  workPhone?: InputMaybe<StringOperationFilterInput>;
};

export type ContactViewSortInput = {
  aBN?: InputMaybe<SortEnumType>;
  accountingId?: InputMaybe<SortEnumType>;
  address?: InputMaybe<SortEnumType>;
  businessName?: InputMaybe<SortEnumType>;
  businessNumber?: InputMaybe<SortEnumType>;
  contactGroup?: InputMaybe<SortEnumType>;
  contactType?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  homePhone?: InputMaybe<SortEnumType>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  insuranceNotes?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGlobalSynced?: InputMaybe<SortEnumType>;
  isRegisteredForTax?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  postCode?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  suburb?: InputMaybe<SortEnumType>;
  workPhone?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ContactsCollectionSegment = {
  __typename?: 'ContactsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ConversationModel = {
  __typename?: 'ConversationModel';
  clientName?: Maybe<Scalars['String']['output']>;
  iD_Customer?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant: Scalars['Uuid']['output'];
  id: Scalars['Uuid']['output'];
  lastMessageAt?: Maybe<Scalars['DateTime']['output']>;
  lastMessageUserName?: Maybe<Scalars['String']['output']>;
  /** --FUSION ONLY PROPERTY-- */
  lead?: Maybe<LeadModel>;
  leadId?: Maybe<Scalars['Uuid']['output']>;
  leadNumber?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type ConvertEstimateToTemplateError = ValidationError;

export type ConvertEstimateToTemplateInput = {
  estimateId: Scalars['Uuid']['input'];
};

export type ConvertEstimateToTemplatePayload = {
  __typename?: 'ConvertEstimateToTemplatePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<ConvertEstimateToTemplateError>>;
};

export type CopiedAccountingAttachment = {
  __typename?: 'CopiedAccountingAttachment';
  attachmentId: Scalars['Uuid']['output'];
  fileName: Scalars['String']['output'];
  savedUrl?: Maybe<Scalars['URL']['output']>;
  temporaryUrl?: Maybe<Scalars['URL']['output']>;
};

export type CopyActivityFeedImageModelInput = {
  activityFeedImageId: Scalars['Uuid']['input'];
  originalActivityFeedImageId: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
};

export type CopyAttachmentError = ValidationError;

export type CopyAttachmentInput = {
  attachmentId: Scalars['Uuid']['input'];
  invoiceId: Scalars['String']['input'];
  provider: IntegrationProviderEnum;
};

export type CopyAttachmentPayload = {
  __typename?: 'CopyAttachmentPayload';
  accountingAttachment?: Maybe<AccountingAttachment>;
  errors?: Maybe<Array<CopyAttachmentError>>;
};

export type CopyAttachmentsFromExternalInvoicesError = ValidationError;

export type CopyAttachmentsFromExternalInvoicesInput = {
  invoiceId: Scalars['String']['input'];
  provider: IntegrationProviderEnum;
  purchaseOrderId: Scalars['Uuid']['input'];
};

export type CopyAttachmentsFromExternalInvoicesPayload = {
  __typename?: 'CopyAttachmentsFromExternalInvoicesPayload';
  errors?: Maybe<Array<CopyAttachmentsFromExternalInvoicesError>>;
  partialSuccessOfCopiedAccountingAttachment?: Maybe<PartialSuccessOfCopiedAccountingAttachment>;
};

export type CopyEstimateItemToTemplateModelInput = {
  estimateId: Scalars['Uuid']['input'];
  estimateItemId: Scalars['Uuid']['input'];
  items?: InputMaybe<Array<InputMaybe<CopyEstimateItemToTemplateModelInput>>>;
  orderNumber: Scalars['Int']['input'];
};

export type CopyEstimateOptionsInput = {
  copyCategories: Scalars['Boolean']['input'];
  copyCategoriesFromJobId?: InputMaybe<Scalars['Uuid']['input']>;
  copyCategoryStatuses: Scalars['Boolean']['input'];
  copyDocuments: Scalars['Boolean']['input'];
  copyLineItemNotes: Scalars['Boolean']['input'];
  copyMeasurements: Scalars['Boolean']['input'];
  copyNotes: Scalars['Boolean']['input'];
  copyPlanBlobs: Scalars['Boolean']['input'];
  copyPlans: Scalars['Boolean']['input'];
  copyQuantities: Scalars['Boolean']['input'];
  copyQuoteContent: Scalars['Boolean']['input'];
  copySchedule: Scalars['Boolean']['input'];
  copySpecifications: Scalars['Boolean']['input'];
  copyTaxProfile: Scalars['Boolean']['input'];
  updateLinkedItems: Scalars['Boolean']['input'];
};

export type CopyItemsToTemplateModelInput = {
  items?: InputMaybe<Array<InputMaybe<CopyEstimateItemToTemplateModelInput>>>;
  template?: InputMaybe<BaseEstimateModelInput>;
};

export type CopyTemplateCategoriesToEstimateModelInput = {
  estimateId: Scalars['Uuid']['input'];
  estimateItemIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  quantityOption: CopyTemplateCategoriesToEstimateQuantityOption;
};

export const CopyTemplateCategoriesToEstimateQuantityOption = {
  Leavequantitiesliketemplate: 'LEAVEQUANTITIESLIKETEMPLATE',
  Settozero: 'SETTOZERO'
} as const;

export type CopyTemplateCategoriesToEstimateQuantityOption = typeof CopyTemplateCategoriesToEstimateQuantityOption[keyof typeof CopyTemplateCategoriesToEstimateQuantityOption];
export type CostItemView = {
  __typename?: 'CostItemView';
  cordellRegionId?: Maybe<Scalars['Int']['output']>;
  costItemCategory?: Maybe<Scalars['String']['output']>;
  costItemCategoryId?: Maybe<Scalars['Uuid']['output']>;
  costItemId: Scalars['Uuid']['output'];
  costItemSubCategory?: Maybe<Scalars['String']['output']>;
  costItemSubCategoryId?: Maybe<Scalars['Uuid']['output']>;
  costItemType?: Maybe<Scalars['String']['output']>;
  dateModified: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  imageId?: Maybe<Scalars['Uuid']['output']>;
  imageThumbnailUrl?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isRecipe: Scalars['Boolean']['output'];
  itemCode?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  priceListDescription?: Maybe<Scalars['String']['output']>;
  priceListId: Scalars['Uuid']['output'];
  supplier?: Maybe<Scalars['String']['output']>;
  supplierMetadata?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['Uuid']['output'];
  uOM?: Maybe<Scalars['String']['output']>;
  unitCost?: Maybe<Scalars['Decimal']['output']>;
};

export type CostItemViewFilterInput = {
  and?: InputMaybe<Array<CostItemViewFilterInput>>;
  cordellRegionId?: InputMaybe<IntOperationFilterInput>;
  costItemCategory?: InputMaybe<StringOperationFilterInput>;
  costItemCategoryId?: InputMaybe<UuidOperationFilterInput>;
  costItemId?: InputMaybe<UuidOperationFilterInput>;
  costItemSubCategory?: InputMaybe<StringOperationFilterInput>;
  costItemSubCategoryId?: InputMaybe<UuidOperationFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  imageThumbnailUrl?: InputMaybe<StringOperationFilterInput>;
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  isRecipe?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  logo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CostItemViewFilterInput>>;
  priceListDescription?: InputMaybe<StringOperationFilterInput>;
  priceListId?: InputMaybe<UuidOperationFilterInput>;
  supplier?: InputMaybe<StringOperationFilterInput>;
  supplierMetadata?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  unitCost?: InputMaybe<DecimalOperationFilterInput>;
};

export type CostItemViewSortInput = {
  cordellRegionId?: InputMaybe<SortEnumType>;
  costItemCategory?: InputMaybe<SortEnumType>;
  costItemCategoryId?: InputMaybe<SortEnumType>;
  costItemId?: InputMaybe<SortEnumType>;
  costItemSubCategory?: InputMaybe<SortEnumType>;
  costItemSubCategoryId?: InputMaybe<SortEnumType>;
  costItemType?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  imageId?: InputMaybe<SortEnumType>;
  imageThumbnailUrl?: InputMaybe<SortEnumType>;
  imageUrl?: InputMaybe<SortEnumType>;
  isRecipe?: InputMaybe<SortEnumType>;
  itemCode?: InputMaybe<SortEnumType>;
  logo?: InputMaybe<SortEnumType>;
  priceListDescription?: InputMaybe<SortEnumType>;
  priceListId?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SortEnumType>;
  supplierMetadata?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  uOM?: InputMaybe<SortEnumType>;
  unitCost?: InputMaybe<SortEnumType>;
};

export type CostingsBreakdownResultModel = {
  __typename?: 'CostingsBreakdownResultModel';
  costItemType?: Maybe<Scalars['String']['output']>;
  totalCost: Scalars['Decimal']['output'];
};

export type CreateActivityFeedCommentError = ActivityFeedStatusError | EmptyStringError | EntityNotFoundError | MaxLengthExceededError | UnpublishedActivityFeedError | ValidationError;

export type CreateActivityFeedCommentInput = {
  activityFeedId: Scalars['Uuid']['input'];
  commentId: Scalars['Uuid']['input'];
  description: Scalars['String']['input'];
};

export type CreateActivityFeedCommentPayload = {
  __typename?: 'CreateActivityFeedCommentPayload';
  activityFeedComment?: Maybe<ActivityFeedComment>;
  errors?: Maybe<Array<CreateActivityFeedCommentError>>;
};

export type CreateActivityFeedError = ActivityFeedImageError | ExceedingNoOfImagesError | InsufficientActivityFeedInfoError | ListedDateError | MaxLengthExceededError | SourceIdError | ValidationError;

export type CreateActivityFeedInput = {
  activityModel: ActivityCreateModelInput;
  imageDetails: Array<ImageCreateModelInput>;
};

export type CreateActivityFeedPayload = {
  __typename?: 'CreateActivityFeedPayload';
  activityFeed?: Maybe<ActivityFeed>;
  errors?: Maybe<Array<CreateActivityFeedError>>;
};

export type CreateBuildingTypesError = DuplicateIdentifierError;

export type CreateBuildingTypesInput = {
  models: Array<BuildingTypeCreateModelInput>;
};

export type CreateBuildingTypesPayload = {
  __typename?: 'CreateBuildingTypesPayload';
  buildingTypes?: Maybe<Array<BuildingType>>;
  errors?: Maybe<Array<CreateBuildingTypesError>>;
};

export type CreateCustomerOptionsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  cityTown?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['Uuid']['input']>;
  customerType?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalRef?: InputMaybe<Scalars['String']['input']>;
  externalRef2?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEmailSenderInput = {
  options: CreateEmailSenderOptionsInput;
};

export type CreateEmailSenderOptionsInput = {
  emailAddress: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateEmailSenderPayload = {
  __typename?: 'CreateEmailSenderPayload';
  emailSender?: Maybe<EmailSender>;
};

export type CreateEstimateFromSnapshotError = NotFoundError;

export type CreateEstimateFromSnapshotInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  estimateId: Scalars['Uuid']['input'];
  snapshotId: Scalars['Uuid']['input'];
};

export type CreateEstimateFromSnapshotPayload = {
  __typename?: 'CreateEstimateFromSnapshotPayload';
  errors?: Maybe<Array<CreateEstimateFromSnapshotError>>;
  uuid?: Maybe<Scalars['Uuid']['output']>;
};

export type CreateEstimateItemCategoryModelInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<InputMaybe<CreateEstimateItemModelInput>>>;
  markup: Scalars['Float']['input'];
  originalItemId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type CreateEstimateItemModelInput = {
  costItemType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  markup: Scalars['Float']['input'];
  orderNumber: Scalars['Int']['input'];
  originalItemId?: InputMaybe<Scalars['Uuid']['input']>;
  recipeItems?: InputMaybe<Array<InputMaybe<CreateEstimateRecipeItemModelInput>>>;
  uOM?: InputMaybe<Scalars['String']['input']>;
  unitCost: Scalars['Decimal']['input'];
  units: Scalars['Decimal']['input'];
};

export type CreateEstimateItemSupplierQuoteInput = {
  estimateId: Scalars['Uuid']['input'];
  estimateItemIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};

export type CreateEstimateMeasurementModelInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  expression?: InputMaybe<Scalars['String']['input']>;
  iD_EstimateMeasurement?: InputMaybe<Scalars['Uuid']['input']>;
  iD_PlanMeasurement?: InputMaybe<Scalars['Uuid']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isParent?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['Int']['input'];
  measurement?: InputMaybe<Scalars['Float']['input']>;
  measurements?: InputMaybe<Array<InputMaybe<CreateEstimateMeasurementModelInput>>>;
  note?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Uuid']['input']>;
  uOM?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEstimateModelInput = {
  buildingType?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<InputMaybe<CreateEstimateItemCategoryModelInput>>>;
  clientAddress?: InputMaybe<AddressModelInput>;
  copyOptions?: InputMaybe<CopyEstimateOptionsInput>;
  copyToWork: Scalars['Boolean']['input'];
  createCategories?: InputMaybe<Scalars['Boolean']['input']>;
  createClientOptions?: InputMaybe<CreateCustomerOptionsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  fromEstimateId?: InputMaybe<Scalars['Uuid']['input']>;
  importExcel: Scalars['Boolean']['input'];
  isPCPSRedistDefault: Scalars['Boolean']['input'];
  isSampleData?: InputMaybe<Scalars['Boolean']['input']>;
  leadId?: InputMaybe<Scalars['Uuid']['input']>;
  rounding?: InputMaybe<Scalars['String']['input']>;
  roundingAdjustment?: InputMaybe<Scalars['Float']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  template: Scalars['Boolean']['input'];
  variety?: InputMaybe<EstimateVariety>;
  worksLocation?: InputMaybe<AddressModelInput>;
};

export type CreateEstimateRecipeItemModelInput = {
  costItemType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  orderNumber: Scalars['Int']['input'];
  originalItemId?: InputMaybe<Scalars['Uuid']['input']>;
  uOM?: InputMaybe<Scalars['String']['input']>;
  uOMMinOrderQty?: InputMaybe<Scalars['Decimal']['input']>;
  uOMOrderIncrements?: InputMaybe<Scalars['Decimal']['input']>;
  uOMUnits?: InputMaybe<Scalars['Decimal']['input']>;
  unitCost: Scalars['Decimal']['input'];
  units: Scalars['Decimal']['input'];
};

export type CreateEstimateSpecificationCategoryInput = {
  iD_Estimate: Scalars['Uuid']['input'];
  iD_Image?: InputMaybe<Scalars['Uuid']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  orderNumber: Scalars['Float']['input'];
  specification: Scalars['String']['input'];
};

export type CreateEstimateSpecificationCategoryV3Error = ValidationError;

export type CreateEstimateSpecificationCategoryV3Input = {
  model: CreateEstimateSpecificationCategoryInput;
};

export type CreateEstimateSpecificationCategoryV3Payload = {
  __typename?: 'CreateEstimateSpecificationCategoryV3Payload';
  errors?: Maybe<Array<CreateEstimateSpecificationCategoryV3Error>>;
  estimateSpecification?: Maybe<EstimateSpecification>;
};

export type CreateEstimateSpecificationItemInput = {
  cost?: InputMaybe<Scalars['Decimal']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  iD_Estimate: Scalars['Uuid']['input'];
  iD_EstimateItem?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Image?: InputMaybe<Scalars['Uuid']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  itemDetailsUrl?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['Float']['input']>;
  parentId: Scalars['Uuid']['input'];
  specification: Scalars['String']['input'];
  supplierCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEstimateSpecificationItemV3Error = ValidationError;

export type CreateEstimateSpecificationItemV3Input = {
  model: CreateEstimateSpecificationItemInput;
};

export type CreateEstimateSpecificationItemV3Payload = {
  __typename?: 'CreateEstimateSpecificationItemV3Payload';
  errors?: Maybe<Array<CreateEstimateSpecificationItemV3Error>>;
  estimateSpecification?: Maybe<EstimateSpecification>;
};

export type CreateEstimateSpecificationModelInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  iD_Estimate: Scalars['Uuid']['input'];
  iD_EstimateItem?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Image?: InputMaybe<Scalars['Uuid']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['Float']['input']>;
  specification?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNoteError = ValidationError;

export type CreateNoteInput = {
  model: NoteCreateModelInput;
};

export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  errors?: Maybe<Array<CreateNoteError>>;
  note?: Maybe<Note>;
};

export type CreatePurchaseOrderError = ValidationError;

export type CreatePurchaseOrderInput = {
  createPurchaseOrderType: CreatePurchaseOrderTypeInput;
};

export type CreatePurchaseOrderInvoiceItemTypeInput = {
  costItemType?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
  taxContent?: InputMaybe<Scalars['String']['input']>;
  taxRates?: InputMaybe<Scalars['String']['input']>;
  totalCost: Scalars['Decimal']['input'];
  unitCost: Scalars['Decimal']['input'];
};

export type CreatePurchaseOrderInvoiceTypeInput = {
  accountingId?: InputMaybe<Scalars['String']['input']>;
  invoiceDate: Scalars['DateTime']['input'];
  invoiceType?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderInvoiceItems: Array<CreatePurchaseOrderInvoiceItemTypeInput>;
  reference?: InputMaybe<Scalars['String']['input']>;
  tax: Scalars['Decimal']['input'];
  total: Scalars['Decimal']['input'];
  totalIncTax: Scalars['Decimal']['input'];
};

export type CreatePurchaseOrderItemTypeInput = {
  description: Scalars['String']['input'];
  iD_JobItem?: InputMaybe<Scalars['Uuid']['input']>;
  order: Scalars['Int']['input'];
};

export type CreatePurchaseOrderPayload = {
  __typename?: 'CreatePurchaseOrderPayload';
  errors?: Maybe<Array<CreatePurchaseOrderError>>;
  purchaseOrder?: Maybe<PurchaseOrder>;
};

export type CreatePurchaseOrderTypeInput = {
  accountCode?: InputMaybe<Scalars['String']['input']>;
  costPlusOrder: Scalars['Int']['input'];
  deliveryCost: Scalars['Decimal']['input'];
  iD_Reference: Scalars['Uuid']['input'];
  invoiceTotalIncTax: Scalars['Decimal']['input'];
  isCostShownOnPrint: Scalars['Boolean']['input'];
  isPickup: Scalars['Boolean']['input'];
  isTaxFree: Scalars['Boolean']['input'];
  orderStatus: Scalars['String']['input'];
  orderTax: Scalars['Decimal']['input'];
  orderTotalExTax: Scalars['Decimal']['input'];
  orderTotalIncTax: Scalars['Decimal']['input'];
  orderType: Scalars['String']['input'];
  purchaseOrderInvoices: Array<CreatePurchaseOrderInvoiceTypeInput>;
  purchaseOrderItems: Array<CreatePurchaseOrderItemTypeInput>;
};

export type CreateScheduleTaskDependenciesError = CircularDependencyError | DependencyOnParentError | DuplicateError | ReferenceIdError | ScheduleLockedError | ScheduleTaskNotFound | ValidationError;

export type CreateScheduleTaskDependenciesInput = {
  model: ScheduleTaskDependenciesCreateModelInput;
};

export type CreateScheduleTaskDependenciesPayload = {
  __typename?: 'CreateScheduleTaskDependenciesPayload';
  errors?: Maybe<Array<CreateScheduleTaskDependenciesError>>;
  scheduleTaskDependencies?: Maybe<Array<ScheduleTaskDependency>>;
};

export type CreateScheduleTaskError = InvalidDateRange | InvalidNesting | InvalidTimeZoneId | ParentNotFound | PositiveOrNullError | ReferenceIdError | ScheduleLockedError | ValidationError;

export type CreateScheduleTaskInput = {
  model: ScheduleTaskCreateModelInput;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateScheduleTaskPayload = {
  __typename?: 'CreateScheduleTaskPayload';
  errors?: Maybe<Array<CreateScheduleTaskError>>;
  scheduleTaskCreateResult?: Maybe<ScheduleTaskCreateResult>;
};

export type CreateScheduleTemplateError = ReferenceIdError | ValidationError;

export type CreateScheduleTemplateInput = {
  model: ScheduleTemplateCreateModelInput;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateScheduleTemplatePayload = {
  __typename?: 'CreateScheduleTemplatePayload';
  errors?: Maybe<Array<CreateScheduleTemplateError>>;
  scheduleTaskTemplate?: Maybe<ScheduleTaskTemplate>;
};

export type CreateShareDtoInput = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  documentType?: InputMaybe<DocumentTypes>;
  identifiers: Array<IdentifierDtoInput>;
  isUsingDigitalStatus: Scalars['Boolean']['input'];
  items: Array<ShareItemDtoInput>;
  message: MessageDtoInput;
  recipients: Array<RecipientDtoInput>;
  senders: Array<SenderDtoInput>;
  shouldShareToMessages?: InputMaybe<Scalars['Boolean']['input']>;
  shouldShareToTimeline?: InputMaybe<Scalars['Boolean']['input']>;
  timelineDescription?: InputMaybe<Scalars['String']['input']>;
  type: ShareType;
};

export type CreateSmartEstimateSessionError = PropertyError | SmartEstimateLimitError;

export type CreateSmartEstimateSessionInput = {
  country: Scalars['String']['input'];
};

export type CreateSmartEstimateSessionPayload = {
  __typename?: 'CreateSmartEstimateSessionPayload';
  errors?: Maybe<Array<CreateSmartEstimateSessionError>>;
  smartEstimateCreateSession?: Maybe<SmartEstimateCreateSession>;
};

export type CreateSnapshotError = NotFoundError | PlanLimitError;

export type CreateSnapshotInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  estimateId: Scalars['Uuid']['input'];
};

export type CreateSnapshotPayload = {
  __typename?: 'CreateSnapshotPayload';
  errors?: Maybe<Array<CreateSnapshotError>>;
  snapshotSummary?: Maybe<SnapshotSummary>;
};

export type CreateSpecificationPresetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iD_Estimate: Scalars['Uuid']['input'];
};

export type CreateSupplierQuoteForEstimateItemsError = ValidationError;

export type CreateSupplierQuoteForEstimateItemsInput = {
  request?: InputMaybe<CreateEstimateItemSupplierQuoteInput>;
};

export type CreateSupplierQuoteForEstimateItemsPayload = {
  __typename?: 'CreateSupplierQuoteForEstimateItemsPayload';
  errors?: Maybe<Array<CreateSupplierQuoteForEstimateItemsError>>;
  supplierQuoteResponse?: Maybe<SupplierQuoteResponse>;
};

export type CreateTakeOffToolSessionError = TakeOffLimitError | TakeOffUnknownOriginError;

export type CreateTakeOffToolSessionInput = {
  originator: Scalars['String']['input'];
};

export type CreateTakeOffToolSessionPayload = {
  __typename?: 'CreateTakeOffToolSessionPayload';
  errors?: Maybe<Array<CreateTakeOffToolSessionError>>;
  takeOffToolCreateSession?: Maybe<TakeOffToolCreateSession>;
};

export type CreateToDoAssigneesError = ToDoItemsNotFound | ToDoJobCrewNotFound | ToDoUserNotFound;

export type CreateToDoAssigneesInput = {
  models: Array<ToDoAssigneeCreateModelInput>;
};

export type CreateToDoAssigneesPayload = {
  __typename?: 'CreateToDoAssigneesPayload';
  errors?: Maybe<Array<CreateToDoAssigneesError>>;
  toDoAssigneeCreateResult?: Maybe<ToDoAssigneeCreateResult>;
};

export type CreateToDoItemsError = RelatedEntityIdError;

export type CreateToDoItemsInput = {
  models: Array<ToDoItemCreateModelInput>;
};

export type CreateToDoItemsPayload = {
  __typename?: 'CreateToDoItemsPayload';
  errors?: Maybe<Array<CreateToDoItemsError>>;
  toDoItems?: Maybe<Array<ToDoItem>>;
};

export type CreateWebhookError = WebhookRegistrationInvalidEndpointError | WebhookRegistrationInvalidEventError | WebhookRegistrationLimitError;

export type CreateWebhookInput = {
  createWebhookType: CreateWebhookTypeInput;
};

export type CreateWebhookPayload = {
  __typename?: 'CreateWebhookPayload';
  errors?: Maybe<Array<CreateWebhookError>>;
  webhookRegistration?: Maybe<WebhookRegistration>;
};

export type CreateWebhookTypeInput = {
  description: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
  events: Array<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  secret: Scalars['String']['input'];
  shouldReceiveChildTenantEvents?: InputMaybe<Scalars['Boolean']['input']>;
};

export const CreatorType = {
  BuildxactUser: 'BUILDXACT_USER',
  GuestUser: 'GUEST_USER'
} as const;

export type CreatorType = typeof CreatorType[keyof typeof CreatorType];
export type CreatorTypeOperationFilterInput = {
  eq?: InputMaybe<CreatorType>;
  in?: InputMaybe<Array<CreatorType>>;
  neq?: InputMaybe<CreatorType>;
  nin?: InputMaybe<Array<CreatorType>>;
};

export type Customer = {
  __typename?: 'Customer';
  accountingId?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  cityTown?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  customerContacts?: Maybe<Array<Maybe<CustomerContact>>>;
  customerType?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  defaultInvoiceDays?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalRef?: Maybe<Scalars['String']['output']>;
  externalRef2?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_ClientTenant?: Maybe<Scalars['Uuid']['output']>;
  iD_Customer?: Maybe<Scalars['Uuid']['output']>;
  iD_IntegrationConnection?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant: Scalars['Uuid']['output'];
  isBusiness?: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isGlobalSynced: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CustomerContact = {
  __typename?: 'CustomerContact';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_Customer: Scalars['Uuid']['output'];
  iD_CustomerContact?: Maybe<Scalars['Uuid']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerContactDto = {
  __typename?: 'CustomerContactDto';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_Customer: Scalars['Uuid']['output'];
  iD_CustomerContact: Scalars['Uuid']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerContactFilterInput = {
  and?: InputMaybe<Array<CustomerContactFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_CustomerContact?: InputMaybe<UuidOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomerContactFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerContactSearchModel = {
  __typename?: 'CustomerContactSearchModel';
  customer?: Maybe<CustomerDtoModel>;
  customerContact?: Maybe<CustomerContactDto>;
};

export type CustomerContactView = {
  __typename?: 'CustomerContactView';
  customerName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_Customer: Scalars['Uuid']['output'];
  iD_CustomerContact: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerContactViewFilterInput = {
  and?: InputMaybe<Array<CustomerContactViewFilterInput>>;
  customerName?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_CustomerContact?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerContactViewFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerContactViewSortInput = {
  customerName?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  iD_CustomerContact?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
};

export type CustomerDtoModel = {
  __typename?: 'CustomerDtoModel';
  address?: Maybe<Scalars['String']['output']>;
  cityTown?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  iD_Customer: Scalars['Uuid']['output'];
  isBusiness?: Maybe<Scalars['Boolean']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CustomerDtoModelInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  cityTown?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  iD_Customer: Scalars['Uuid']['input'];
  isBusiness?: InputMaybe<Scalars['Boolean']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerFilterInput = {
  accountingId?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CustomerFilterInput>>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  businessNumber?: InputMaybe<StringOperationFilterInput>;
  cityTown?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  customerContacts?: InputMaybe<ListFilterInputTypeOfCustomerContactFilterInput>;
  customerType?: InputMaybe<StringOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  defaultInvoiceDays?: InputMaybe<IntOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  estimates?: InputMaybe<ListFilterInputTypeOfEstimateViewFilterInput>;
  externalRef?: InputMaybe<StringOperationFilterInput>;
  externalRef2?: InputMaybe<StringOperationFilterInput>;
  iD_ClientTenant?: InputMaybe<UuidOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationConnection?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isBusiness?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  jobs?: InputMaybe<ListFilterInputTypeOfJobViewFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  postCode?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  rowVersionN?: InputMaybe<LongOperationFilterInput>;
  source?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerIntegrationConnection = {
  __typename?: 'CustomerIntegrationConnection';
  cataloguesCustomer?: Maybe<CustomerView>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  erpField1?: Maybe<Scalars['String']['output']>;
  erpField2?: Maybe<Scalars['String']['output']>;
  iD_Customer: Scalars['Uuid']['output'];
  iD_CustomerIntegrationConnection: Scalars['Uuid']['output'];
  iD_IntegrationConnection: Scalars['Uuid']['output'];
  integrationConnection?: Maybe<IntegrationConnection>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
};

export type CustomerIntegrationConnectionRequestModelInput = {
  erpField1?: InputMaybe<Scalars['String']['input']>;
  erpField2?: InputMaybe<Scalars['String']['input']>;
  iD_Customer: Scalars['Uuid']['input'];
  iD_IntegrationConnection: Scalars['Uuid']['input'];
};

export type CustomerModel = {
  __typename?: 'CustomerModel';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_Customer?: Maybe<Scalars['Uuid']['output']>;
  isBusiness?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerModelFilterInput = {
  and?: InputMaybe<Array<CustomerModelFilterInput>>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  isBusiness?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerModelFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerModelForMergeTags = {
  __typename?: 'CustomerModelForMergeTags';
  address?: Maybe<Scalars['String']['output']>;
  cityTown?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CustomerModelInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  iD_Customer?: InputMaybe<Scalars['Uuid']['input']>;
  isBusiness?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerModelSortInput = {
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  isBusiness?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
};

export type CustomerSortInput = {
  accountingId?: InputMaybe<SortEnumType>;
  address?: InputMaybe<SortEnumType>;
  businessName?: InputMaybe<SortEnumType>;
  businessNumber?: InputMaybe<SortEnumType>;
  cityTown?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  customerType?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  defaultInvoiceDays?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  externalRef?: InputMaybe<SortEnumType>;
  externalRef2?: InputMaybe<SortEnumType>;
  iD_ClientTenant?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  iD_IntegrationConnection?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isBusiness?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGlobalSynced?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  postCode?: InputMaybe<SortEnumType>;
  rowVersionN?: InputMaybe<SortEnumType>;
  source?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
};

export type CustomerView = {
  __typename?: 'CustomerView';
  accountingId?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  cityTown?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  customerType?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  defaultInvoiceDays?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalRef?: Maybe<Scalars['String']['output']>;
  iD_ClientTenant?: Maybe<Scalars['Uuid']['output']>;
  iD_Customer: Scalars['Uuid']['output'];
  iD_IntegrationConnection?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant: Scalars['Uuid']['output'];
  isBusiness?: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isGlobalSynced: Scalars['Boolean']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CustomerViewFilterInput = {
  accountingId?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CustomerViewFilterInput>>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  businessNumber?: InputMaybe<StringOperationFilterInput>;
  cityTown?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  customerType?: InputMaybe<StringOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  defaultInvoiceDays?: InputMaybe<IntOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  externalRef?: InputMaybe<StringOperationFilterInput>;
  iD_ClientTenant?: InputMaybe<UuidOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationConnection?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isBusiness?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerViewFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  postCode?: InputMaybe<StringOperationFilterInput>;
  source?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerViewSortInput = {
  accountingId?: InputMaybe<SortEnumType>;
  address?: InputMaybe<SortEnumType>;
  businessName?: InputMaybe<SortEnumType>;
  businessNumber?: InputMaybe<SortEnumType>;
  cityTown?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  customerType?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  defaultInvoiceDays?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  externalRef?: InputMaybe<SortEnumType>;
  iD_ClientTenant?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  iD_IntegrationConnection?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isBusiness?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGlobalSynced?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  postCode?: InputMaybe<SortEnumType>;
  source?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type CustomersCollectionSegment = {
  __typename?: 'CustomersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<Customer>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type DailyTradingHours = {
  __typename?: 'DailyTradingHours';
  friday: OpeningHours;
  monday: OpeningHours;
  saturday: OpeningHours;
  sunday: OpeningHours;
  thursday: OpeningHours;
  tuesday: OpeningHours;
  wednesday: OpeningHours;
};

export type DailyTradingHoursFilterInput = {
  and?: InputMaybe<Array<DailyTradingHoursFilterInput>>;
  friday?: InputMaybe<OpeningHoursFilterInput>;
  monday?: InputMaybe<OpeningHoursFilterInput>;
  or?: InputMaybe<Array<DailyTradingHoursFilterInput>>;
  saturday?: InputMaybe<OpeningHoursFilterInput>;
  sunday?: InputMaybe<OpeningHoursFilterInput>;
  thursday?: InputMaybe<OpeningHoursFilterInput>;
  tuesday?: InputMaybe<OpeningHoursFilterInput>;
  wednesday?: InputMaybe<OpeningHoursFilterInput>;
};

export type DailyTradingHoursSortInput = {
  friday?: InputMaybe<OpeningHoursSortInput>;
  monday?: InputMaybe<OpeningHoursSortInput>;
  saturday?: InputMaybe<OpeningHoursSortInput>;
  sunday?: InputMaybe<OpeningHoursSortInput>;
  thursday?: InputMaybe<OpeningHoursSortInput>;
  tuesday?: InputMaybe<OpeningHoursSortInput>;
  wednesday?: InputMaybe<OpeningHoursSortInput>;
};

export type DashboardEstimateSummary = {
  __typename?: 'DashboardEstimateSummary';
  accepted: Scalars['Int']['output'];
  acceptedAverage: Scalars['Float']['output'];
  acceptedTotal: Scalars['Float']['output'];
  pending: Scalars['Int']['output'];
  pendingTotal: Scalars['Float']['output'];
  rejected: Scalars['Int']['output'];
  rejectedTotal: Scalars['Float']['output'];
  sent: Scalars['Int']['output'];
  sentTotal: Scalars['Float']['output'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DeleteActivityFeedError = ActivityFeedStatusError | EntityNotFoundError | UnpublishedActivityFeedError | ValidationError;

export type DeleteActivityFeedInput = {
  feedId: Scalars['Uuid']['input'];
};

export type DeleteActivityFeedPayload = {
  __typename?: 'DeleteActivityFeedPayload';
  activityFeed?: Maybe<ActivityFeed>;
  errors?: Maybe<Array<DeleteActivityFeedError>>;
};

export type DeleteAllScheduleTasksError = ValidationError;

export type DeleteAllScheduleTasksInput = {
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
};

export type DeleteAllScheduleTasksPayload = {
  __typename?: 'DeleteAllScheduleTasksPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<DeleteAllScheduleTasksError>>;
};

export type DeleteEmailSenderInput = {
  emailSenderId: Scalars['Uuid']['input'];
};

export type DeleteEmailSenderPayload = {
  __typename?: 'DeleteEmailSenderPayload';
  deleteEmailSenderSuccessful?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteEmailsInput = {
  options: DeleteEmailsOptionsInput;
};

export type DeleteEmailsOptionsInput = {
  messageIds: Array<Scalars['String']['input']>;
};

export type DeleteEmailsPayload = {
  __typename?: 'DeleteEmailsPayload';
  emailDeleteResultItem?: Maybe<Array<EmailDeleteResultItem>>;
};

/** A segment of a collection. */
export type DeleteEstimateSpecificationCategoryCollectionSegment = {
  __typename?: 'DeleteEstimateSpecificationCategoryCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<EstimateSpecificationModel>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type DeleteEstimateSpecificationCategoryV3Error = FrozenSpecificationError | NotFoundError | ValidationError;

export type DeleteEstimateSpecificationCategoryV3Input = {
  estimateSpecificationId: Scalars['Uuid']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type DeleteEstimateSpecificationCategoryV3Payload = {
  __typename?: 'DeleteEstimateSpecificationCategoryV3Payload';
  errors?: Maybe<Array<DeleteEstimateSpecificationCategoryV3Error>>;
  estimateSpecificationCollectionSegment?: Maybe<EstimateSpecificationCollectionSegment>;
};

/** A segment of a collection. */
export type DeleteEstimateSpecificationItemCollectionSegment = {
  __typename?: 'DeleteEstimateSpecificationItemCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<EstimateSpecificationModel>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type DeleteJobCrewError = ValidationError;

export type DeleteJobCrewInput = {
  jobCrewId: Scalars['Uuid']['input'];
  jobId: Scalars['Uuid']['input'];
};

export type DeleteJobCrewPayload = {
  __typename?: 'DeleteJobCrewPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<DeleteJobCrewError>>;
};

export type DeleteLegacyMessagesInput = {
  messageIdList?: InputMaybe<MessageIdListDtoInput>;
};

export type DeleteLegacyMessagesPayload = {
  __typename?: 'DeleteLegacyMessagesPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteNonWorkingDayError = NonWorkingDayNotFound | ValidationError;

export type DeleteNonWorkingDayInput = {
  nonWorkingDayId: Scalars['Uuid']['input'];
};

export type DeleteNonWorkingDayPayload = {
  __typename?: 'DeleteNonWorkingDayPayload';
  errors?: Maybe<Array<DeleteNonWorkingDayError>>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteNoteInput = {
  noteId: Scalars['Uuid']['input'];
  referenceId: Scalars['Uuid']['input'];
};

export type DeleteNotePayload = {
  __typename?: 'DeleteNotePayload';
  noteDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteScheduleTaskContactError = EntityNotFoundError | ValidationError;

export type DeleteScheduleTaskContactInput = {
  models: Array<ScheduleTaskContactDeleteModelInput>;
};

export type DeleteScheduleTaskContactPayload = {
  __typename?: 'DeleteScheduleTaskContactPayload';
  deletedIds?: Maybe<DeletedIds>;
  errors?: Maybe<Array<DeleteScheduleTaskContactError>>;
};

export type DeleteScheduleTaskDependenciesError = ScheduleLockedError | ScheduleTaskDependencyNotFound;

export type DeleteScheduleTaskDependenciesInput = {
  model: ScheduleTaskDependenciesDeleteModelInput;
};

export type DeleteScheduleTaskDependenciesPayload = {
  __typename?: 'DeleteScheduleTaskDependenciesPayload';
  deletedDependencyIds?: Maybe<Array<Scalars['Uuid']['output']>>;
  errors?: Maybe<Array<DeleteScheduleTaskDependenciesError>>;
};

export type DeleteScheduleTaskError = ScheduleLockedError | ScheduleTaskNotFound;

export type DeleteScheduleTaskInput = {
  model: ScheduleTaskDeleteModelInput;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteScheduleTaskPayload = {
  __typename?: 'DeleteScheduleTaskPayload';
  errors?: Maybe<Array<DeleteScheduleTaskError>>;
  scheduleTaskDeleteResult?: Maybe<ScheduleTaskDeleteResult>;
};

export type DeleteScheduleTemplateError = ScheduleTaskTemplateNotFound | ValidationError;

export type DeleteScheduleTemplateInput = {
  templateId: Scalars['Uuid']['input'];
};

export type DeleteScheduleTemplatePayload = {
  __typename?: 'DeleteScheduleTemplatePayload';
  errors?: Maybe<Array<DeleteScheduleTemplateError>>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteShareItemDtoInput = {
  binaryUrl: Scalars['String']['input'];
  sourceId: Scalars['Uuid']['input'];
  sourceType: SourceTypes;
  type: ShareItemType;
};

export type DeleteSnapshotError = ValidationError;

export type DeleteSnapshotInput = {
  estimateId: Scalars['Uuid']['input'];
  snapshotId: Scalars['Uuid']['input'];
};

export type DeleteSnapshotPayload = {
  __typename?: 'DeleteSnapshotPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<DeleteSnapshotError>>;
};

export type DeleteToDoAssigneesError = ValidationError;

export type DeleteToDoAssigneesInput = {
  models: Array<ToDoAssigneeDeleteModelInput>;
};

export type DeleteToDoAssigneesPayload = {
  __typename?: 'DeleteToDoAssigneesPayload';
  errors?: Maybe<Array<DeleteToDoAssigneesError>>;
  toDoAssigneeDeleteResult?: Maybe<ToDoAssigneeDeleteResult>;
};

export type DeleteToDoItemsError = ToDoItemsNotFound;

export type DeleteToDoItemsInput = {
  toDeleteIds: Array<Scalars['Uuid']['input']>;
};

export type DeleteToDoItemsPayload = {
  __typename?: 'DeleteToDoItemsPayload';
  errors?: Maybe<Array<DeleteToDoItemsError>>;
  itemsDeletedCount?: Maybe<Scalars['Int']['output']>;
};

export type DeleteWebhookInput = {
  webhookId: Scalars['Uuid']['input'];
};

export type DeleteWebhookPayload = {
  __typename?: 'DeleteWebhookPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type DeletedIds = {
  __typename?: 'DeletedIds';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['Uuid']['output']>;
};

export type DependencyOnParentError = Error & {
  __typename?: 'DependencyOnParentError';
  message: Scalars['String']['output'];
};

export const DependencyType = {
  FinishToStart: 'FINISH_TO_START',
  StartToStart: 'START_TO_START'
} as const;

export type DependencyType = typeof DependencyType[keyof typeof DependencyType];
export type DependencyTypeOperationFilterInput = {
  eq?: InputMaybe<DependencyType>;
  in?: InputMaybe<Array<DependencyType>>;
  neq?: InputMaybe<DependencyType>;
  nin?: InputMaybe<Array<DependencyType>>;
};

export const DocumentTypes = {
  ActivityFeed: 'ACTIVITY_FEED',
  Adjustment: 'ADJUSTMENT',
  Document: 'DOCUMENT',
  Invoice: 'INVOICE',
  Plan: 'PLAN',
  Quote: 'QUOTE',
  Variation: 'VARIATION'
} as const;

export type DocumentTypes = typeof DocumentTypes[keyof typeof DocumentTypes];
export type DuplicateError = Error & {
  __typename?: 'DuplicateError';
  message: Scalars['String']['output'];
};

export type DuplicateIdentifierError = Error & {
  __typename?: 'DuplicateIdentifierError';
  ids: Array<Scalars['Uuid']['output']>;
  message: Scalars['String']['output'];
};

export type DuplicateScheduleTaskError = ScheduleLockedError | ScheduleTaskNotFound;

export type DuplicateScheduleTaskInput = {
  model: ScheduleTaskDuplicateModelInput;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type DuplicateScheduleTaskPayload = {
  __typename?: 'DuplicateScheduleTaskPayload';
  errors?: Maybe<Array<DuplicateScheduleTaskError>>;
  scheduleTaskDuplicateResult?: Maybe<ScheduleTaskDuplicateResult>;
};

export type EditScheduleTemplateError = ScheduleTaskTemplateNotFound | ValidationError;

export type EditScheduleTemplateInput = {
  description: Scalars['String']['input'];
  templateId: Scalars['Uuid']['input'];
};

export type EditScheduleTemplatePayload = {
  __typename?: 'EditScheduleTemplatePayload';
  errors?: Maybe<Array<EditScheduleTemplateError>>;
  scheduleTaskTemplate?: Maybe<ScheduleTaskTemplate>;
};

export type EmailBlacklist = {
  __typename?: 'EmailBlacklist';
  reason: Scalars['String']['output'];
  tenantId: Scalars['Uuid']['output'];
  userId?: Maybe<Scalars['Uuid']['output']>;
};

export type EmailDeleteResultItem = {
  __typename?: 'EmailDeleteResultItem';
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  resultCode: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type EmailListItem = {
  __typename?: 'EmailListItem';
  attachmentStorageId?: Maybe<Scalars['Uuid']['output']>;
  attachments?: Maybe<Array<EmailMessageAttachment>>;
  cc?: Maybe<Array<Scalars['String']['output']>>;
  date: Scalars['DateTime']['output'];
  direction: EmailMessageDirection;
  error?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Array<Scalars['String']['output']>>;
  generatedByBuildxact: Scalars['Boolean']['output'];
  hasError: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  messageId?: Maybe<Scalars['String']['output']>;
  overallStatus: EmailTransactionStatusEnum;
  references?: Maybe<Array<EmailMessageReference>>;
  sendFrom?: Maybe<Scalars['String']['output']>;
  snippet?: Maybe<Scalars['String']['output']>;
  stringified?: Maybe<EmailMessageStringifiedContent>;
  subject?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['Uuid']['output'];
  to?: Maybe<Array<Scalars['String']['output']>>;
  trackingStatus: Array<KeyValuePairOfStringAndEmailTrackingStatus>;
  userId: Scalars['Uuid']['output'];
};

export type EmailMessage = {
  __typename?: 'EmailMessage';
  attachmentStorageId?: Maybe<Scalars['Uuid']['output']>;
  attachments?: Maybe<Array<EmailMessageAttachment>>;
  body?: Maybe<Scalars['String']['output']>;
  bodyAttachment?: Maybe<EmailMessageAttachment>;
  cc?: Maybe<Array<Scalars['String']['output']>>;
  date: Scalars['DateTime']['output'];
  dateEpoch: Scalars['Long']['output'];
  direction: EmailMessageDirection;
  error?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Array<Scalars['String']['output']>>;
  generatedByBuildxact: Scalars['Boolean']['output'];
  hasError: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  messageId?: Maybe<Scalars['String']['output']>;
  overallStatus: EmailTransactionStatusEnum;
  references?: Maybe<Array<EmailMessageReference>>;
  sendFrom?: Maybe<Scalars['String']['output']>;
  snippet?: Maybe<Scalars['String']['output']>;
  stringified?: Maybe<EmailMessageStringifiedContent>;
  subject?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['Uuid']['output'];
  to?: Maybe<Array<Scalars['String']['output']>>;
  trackingStatus: Array<KeyValuePairOfStringAndEmailTrackingStatus>;
  userId: Scalars['Uuid']['output'];
};

export type EmailMessageAttachment = {
  __typename?: 'EmailMessageAttachment';
  contentId?: Maybe<Scalars['String']['output']>;
  contentType: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  size: Scalars['Long']['output'];
  url: Scalars['String']['output'];
};

export const EmailMessageDirection = {
  In: 'IN',
  Out: 'OUT'
} as const;

export type EmailMessageDirection = typeof EmailMessageDirection[keyof typeof EmailMessageDirection];
export type EmailMessageDtoInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bcc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  cC?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cCUser: Scalars['Boolean']['input'];
  from?: InputMaybe<Scalars['String']['input']>;
  includeFooter: Scalars['Boolean']['input'];
  references?: InputMaybe<Array<InputMaybe<EmailMessageReferenceDtoInput>>>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EmailMessageReference = {
  __typename?: 'EmailMessageReference';
  id: Scalars['Uuid']['output'];
  type: Scalars['String']['output'];
};

export type EmailMessageReferenceDto = {
  __typename?: 'EmailMessageReferenceDto';
  id: Scalars['Uuid']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type EmailMessageReferenceDtoInput = {
  id: Scalars['Uuid']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type EmailMessageReferenceInput = {
  id: Scalars['Uuid']['input'];
  type: Scalars['String']['input'];
};

export type EmailMessageStringifiedContent = {
  __typename?: 'EmailMessageStringifiedContent';
  cc?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  snippet?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type EmailSendResult = {
  __typename?: 'EmailSendResult';
  code?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  isRetryable: Scalars['Boolean']['output'];
  isSuccessful: Scalars['Boolean']['output'];
  message?: Maybe<EmailMessage>;
  messageId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
};

export type EmailSender = {
  __typename?: 'EmailSender';
  confirmationSentAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
  emailSenderId: Scalars['Uuid']['output'];
  fromName: Scalars['String']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isShared: Scalars['Boolean']['output'];
  signature?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['Uuid']['output'];
  userId: Scalars['Uuid']['output'];
};

export type EmailTrackingStatus = {
  __typename?: 'EmailTrackingStatus';
  bouncedAt?: Maybe<Scalars['DateTime']['output']>;
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  firstOpenAt?: Maybe<Scalars['DateTime']['output']>;
  isFirstOpen?: Maybe<Scalars['Boolean']['output']>;
  lastOpenedAt?: Maybe<Scalars['DateTime']['output']>;
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<EmailTransactionStatusEnum>;
};

export const EmailTransactionStatusEnum = {
  Bounced: 'BOUNCED',
  Delivered: 'DELIVERED',
  Error: 'ERROR',
  Opened: 'OPENED',
  Processed: 'PROCESSED',
  Retryscheduled: 'RETRYSCHEDULED'
} as const;

export type EmailTransactionStatusEnum = typeof EmailTransactionStatusEnum[keyof typeof EmailTransactionStatusEnum];
export type EmptyStringError = Error & {
  __typename?: 'EmptyStringError';
  message: Scalars['String']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
};

export const EntityName = {
  ActivityFeed: 'ACTIVITY_FEED',
  ActivityFeedImage: 'ACTIVITY_FEED_IMAGE',
  Estimate: 'ESTIMATE',
  ScheduleTaskContact: 'SCHEDULE_TASK_CONTACT'
} as const;

export type EntityName = typeof EntityName[keyof typeof EntityName];
export type EntityNotFoundError = Error & {
  __typename?: 'EntityNotFoundError';
  entityName: EntityName;
  id?: Maybe<Scalars['Uuid']['output']>;
  message: Scalars['String']['output'];
};

export type ErpCustomerItemPriceSearchItemModelInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  iD_EstimateItem: Scalars['Uuid']['input'];
};

export type ErpHistoryRun = {
  __typename?: 'ErpHistoryRun';
  exceptionMessage?: Maybe<Scalars['String']['output']>;
  iD_EpicorHistoryRun: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  imageCount: Scalars['Int']['output'];
  importFinishedTime?: Maybe<Scalars['DateTime']['output']>;
  importGeneratedTime?: Maybe<Scalars['DateTime']['output']>;
  importPublishedTime?: Maybe<Scalars['DateTime']['output']>;
  isSuccess: Scalars['Boolean']['output'];
  lastSyncTimeToUse?: Maybe<Scalars['DateTime']['output']>;
  productCount: Scalars['Int']['output'];
  startTime: Scalars['DateTime']['output'];
  type: ErpHistoryRunType;
  userFriendlyMessage?: Maybe<Scalars['String']['output']>;
};

export type ErpHistoryRunFilterInput = {
  and?: InputMaybe<Array<ErpHistoryRunFilterInput>>;
  exceptionMessage?: InputMaybe<StringOperationFilterInput>;
  iD_EpicorHistoryRun?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  imageCount?: InputMaybe<IntOperationFilterInput>;
  importFinishedTime?: InputMaybe<DateTimeOperationFilterInput>;
  importGeneratedTime?: InputMaybe<DateTimeOperationFilterInput>;
  importPublishedTime?: InputMaybe<DateTimeOperationFilterInput>;
  isSuccess?: InputMaybe<BooleanOperationFilterInput>;
  lastSyncTimeToUse?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ErpHistoryRunFilterInput>>;
  productCount?: InputMaybe<IntOperationFilterInput>;
  startTime?: InputMaybe<DateTimeOperationFilterInput>;
  type?: InputMaybe<ErpHistoryRunTypeOperationFilterInput>;
  userFriendlyMessage?: InputMaybe<StringOperationFilterInput>;
};

export type ErpHistoryRunSortInput = {
  exceptionMessage?: InputMaybe<SortEnumType>;
  iD_EpicorHistoryRun?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  imageCount?: InputMaybe<SortEnumType>;
  importFinishedTime?: InputMaybe<SortEnumType>;
  importGeneratedTime?: InputMaybe<SortEnumType>;
  importPublishedTime?: InputMaybe<SortEnumType>;
  isSuccess?: InputMaybe<SortEnumType>;
  lastSyncTimeToUse?: InputMaybe<SortEnumType>;
  productCount?: InputMaybe<SortEnumType>;
  startTime?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  userFriendlyMessage?: InputMaybe<SortEnumType>;
};

export const ErpHistoryRunType = {
  Connectionverification: 'CONNECTIONVERIFICATION',
  Integration: 'INTEGRATION',
  Unknown: 'UNKNOWN'
} as const;

export type ErpHistoryRunType = typeof ErpHistoryRunType[keyof typeof ErpHistoryRunType];
export type ErpHistoryRunTypeOperationFilterInput = {
  eq?: InputMaybe<ErpHistoryRunType>;
  in?: InputMaybe<Array<ErpHistoryRunType>>;
  neq?: InputMaybe<ErpHistoryRunType>;
  nin?: InputMaybe<Array<ErpHistoryRunType>>;
};

/** A segment of a collection. */
export type ErpTenantHistoryRunsCollectionSegment = {
  __typename?: 'ErpTenantHistoryRunsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ErpHistoryRun>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ErpTenantSettingModelInput = {
  clientPassword?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  clientUrl?: InputMaybe<Scalars['String']['input']>;
  clientUsername?: InputMaybe<Scalars['String']['input']>;
  iD_IntegrationConnection?: InputMaybe<Scalars['Uuid']['input']>;
};

export type Error = {
  message: Scalars['String']['output'];
};

export type Estimate = {
  __typename?: 'Estimate';
  acceptedDate?: Maybe<Scalars['DateTime']['output']>;
  accessType?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  availabilityScope?: Maybe<EstimateAvailabilityScope>;
  availabilityStatus?: Maybe<EstimateAvailabilityStatus>;
  buildingType?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  calculationMethod: Scalars['Int']['output'];
  canCopy: Scalars['Boolean']['output'];
  categories?: Maybe<Array<Maybe<EstimateItem>>>;
  /** --FUSION ONLY PROPERTY-- */
  client?: Maybe<Customer>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  datePrepared?: Maybe<Scalars['DateTime']['output']>;
  defaultMarkupRedistMethod: Scalars['Int']['output'];
  deletionSource?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  estimateItems?: Maybe<Array<Maybe<EstimateItem>>>;
  estimateMeasurements?: Maybe<Array<Maybe<EstimateMeasurement>>>;
  estimateQuoteOptions?: Maybe<Array<Maybe<EstimateQuoteOption>>>;
  estimateSpecifications?: Maybe<Array<Maybe<EstimateSpecification>>>;
  estimateSuppliers?: Maybe<Array<Maybe<EstimateSupplier>>>;
  estimateType?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  franchiseFee: Scalars['Decimal']['output'];
  franchiseFeeTax: Scalars['Decimal']['output'];
  franchiseFeeTotal: Scalars['Decimal']['output'];
  franchiseFeeTotalIncTax: Scalars['Decimal']['output'];
  hasOneBuildId?: Maybe<Scalars['Boolean']['output']>;
  hasUnsyncedSpecifications: Scalars['Boolean']['output'];
  iD_Customer?: Maybe<Scalars['Uuid']['output']>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_Job?: Maybe<Scalars['Uuid']['output']>;
  iD_Lead?: Maybe<Scalars['Uuid']['output']>;
  iD_SourceEstimate?: Maybe<Scalars['Uuid']['output']>;
  iD_Specification?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  iD_TenantShare?: Maybe<Scalars['Uuid']['output']>;
  iD_UserShare?: Maybe<Scalars['Uuid']['output']>;
  indexer: Scalars['Long']['output'];
  isAccepted: Scalars['Boolean']['output'];
  isAccessible: Scalars['Boolean']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isDeletedRFQ?: Maybe<Scalars['Boolean']['output']>;
  isForeignOwned?: Maybe<Scalars['Boolean']['output']>;
  isGlobalSynced: Scalars['Boolean']['output'];
  isLegacySpecification: Scalars['Boolean']['output'];
  isNewRFQ?: Maybe<Scalars['Boolean']['output']>;
  isPCPSRedistDefault: Scalars['Boolean']['output'];
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  isReadOnlyRFQ?: Maybe<Scalars['Boolean']['output']>;
  isSampleData: Scalars['Boolean']['output'];
  isSharedWithClient: Scalars['Boolean']['output'];
  isTaxApplied?: Maybe<Scalars['Boolean']['output']>;
  isTemplate?: Maybe<Scalars['Boolean']['output']>;
  /** --FUSION ONLY PROPERTY-- */
  lead?: Maybe<LeadModel>;
  livePricingCheckedAt?: Maybe<Scalars['DateTime']['output']>;
  markup?: Maybe<Scalars['Float']['output']>;
  markupContent?: Maybe<Scalars['String']['output']>;
  markupPercent?: Maybe<Scalars['Float']['output']>;
  markupTax?: Maybe<Scalars['Float']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  plans?: Maybe<Array<Maybe<Plan>>>;
  postCode?: Maybe<Scalars['String']['output']>;
  quoteLastGeneratedAt?: Maybe<Scalars['DateTime']['output']>;
  quoteRequests?: Maybe<Array<Maybe<QuoteRequest>>>;
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  rounding?: Maybe<Scalars['String']['output']>;
  roundingAdjustment: Scalars['Float']['output'];
  rowVersion?: Maybe<Array<Scalars['Byte']['output']>>;
  rowVersionN?: Maybe<Scalars['Long']['output']>;
  scopeHiddenBy?: Maybe<Scalars['Uuid']['output']>;
  scopeHiddenDate?: Maybe<Scalars['DateTime']['output']>;
  scopeManagedBy?: Maybe<Scalars['Uuid']['output']>;
  scopeManagedDate?: Maybe<Scalars['DateTime']['output']>;
  scopePublicBy?: Maybe<Scalars['Uuid']['output']>;
  scopePublicDate?: Maybe<Scalars['DateTime']['output']>;
  sharedWithClientAt?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  specificationIntroduction?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusArchivedBy?: Maybe<Scalars['Uuid']['output']>;
  statusArchivedDate?: Maybe<Scalars['DateTime']['output']>;
  statusDraftBy?: Maybe<Scalars['Uuid']['output']>;
  statusDraftDate?: Maybe<Scalars['DateTime']['output']>;
  statusExpiredBy?: Maybe<Scalars['Uuid']['output']>;
  statusExpiredDate?: Maybe<Scalars['DateTime']['output']>;
  statusPublishedBy?: Maybe<Scalars['Uuid']['output']>;
  statusPublishedDate?: Maybe<Scalars['DateTime']['output']>;
  subscription?: Maybe<EstimateTemplateSubscription>;
  suburb?: Maybe<Scalars['String']['output']>;
  targetProfession?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  taxIncMarkup?: Maybe<Scalars['Float']['output']>;
  taxRate: Scalars['Float']['output'];
  taxRates?: Maybe<Scalars['String']['output']>;
  taxRatesDescription?: Maybe<Scalars['String']['output']>;
  taxRatesShortCode?: Maybe<Scalars['String']['output']>;
  tenantIsSupplier?: Maybe<Scalars['Boolean']['output']>;
  tenantLogoSmallUrl?: Maybe<Scalars['String']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalIncMarkup?: Maybe<Scalars['Float']['output']>;
  totalIncMarkupTax?: Maybe<Scalars['Float']['output']>;
  totalIncMarkupTaxRange?: Maybe<Scalars['String']['output']>;
  totalIncTax?: Maybe<Scalars['Float']['output']>;
  variety: EstimateVariety;
  worksLocation?: Maybe<Scalars['String']['output']>;
  worksLocationAddress?: Maybe<Scalars['String']['output']>;
  worksLocationLat?: Maybe<Scalars['Decimal']['output']>;
  worksLocationLng?: Maybe<Scalars['Decimal']['output']>;
  worksLocationMapUrl?: Maybe<Scalars['String']['output']>;
  worksLocationPostcode?: Maybe<Scalars['String']['output']>;
  worksLocationState?: Maybe<Scalars['String']['output']>;
  worksLocationSuburb?: Maybe<Scalars['String']['output']>;
};

export type EstimateAccessControl = {
  __typename?: 'EstimateAccessControl';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  estimate?: Maybe<Estimate>;
  externalRef?: Maybe<Scalars['String']['output']>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateAccessControl: Scalars['Uuid']['output'];
  isDeleted: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
};

/** A segment of a collection. */
export type EstimateAccessControlsCollectionSegment = {
  __typename?: 'EstimateAccessControlsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<QueryEstimateAccessControl>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export const EstimateAvailabilityScope = {
  Hidden: 'HIDDEN',
  Managed: 'MANAGED',
  Public: 'PUBLIC'
} as const;

export type EstimateAvailabilityScope = typeof EstimateAvailabilityScope[keyof typeof EstimateAvailabilityScope];
export type EstimateAvailabilityScopeOperationFilterInput = {
  eq?: InputMaybe<EstimateAvailabilityScope>;
  in?: InputMaybe<Array<EstimateAvailabilityScope>>;
  neq?: InputMaybe<EstimateAvailabilityScope>;
  nin?: InputMaybe<Array<EstimateAvailabilityScope>>;
};

export const EstimateAvailabilityStatus = {
  Archived: 'ARCHIVED',
  Draft: 'DRAFT',
  Expired: 'EXPIRED',
  Published: 'PUBLISHED'
} as const;

export type EstimateAvailabilityStatus = typeof EstimateAvailabilityStatus[keyof typeof EstimateAvailabilityStatus];
export type EstimateAvailabilityStatusOperationFilterInput = {
  eq?: InputMaybe<EstimateAvailabilityStatus>;
  in?: InputMaybe<Array<EstimateAvailabilityStatus>>;
  neq?: InputMaybe<EstimateAvailabilityStatus>;
  nin?: InputMaybe<Array<EstimateAvailabilityStatus>>;
};

export type EstimateAvailabilityUpdateModelInput = {
  availabilityScope?: InputMaybe<EstimateAvailabilityScope>;
  availabilityStatus?: InputMaybe<EstimateAvailabilityStatus>;
  effectiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  iD_Estimate: Scalars['Uuid']['input'];
};

export type EstimateDetailsModelForMergeTags = {
  __typename?: 'EstimateDetailsModelForMergeTags';
  buildingType?: Maybe<Scalars['String']['output']>;
  calculationMethod: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  iD_Estimate: Scalars['Uuid']['output'];
  markup?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  quoteExpiry?: Maybe<Scalars['String']['output']>;
  quoteGeneratedDate?: Maybe<Scalars['String']['output']>;
  quotedTotal?: Maybe<Scalars['Float']['output']>;
  quotedTotalExTax?: Maybe<Scalars['Float']['output']>;
  quotedTotalIncTax?: Maybe<Scalars['Float']['output']>;
  quotedTotalTax?: Maybe<Scalars['Float']['output']>;
  specificationIntroduction?: Maybe<Scalars['String']['output']>;
  taxLabel?: Maybe<Scalars['String']['output']>;
  worksLocationAddress?: Maybe<Scalars['String']['output']>;
  worksLocationPostcode?: Maybe<Scalars['String']['output']>;
  worksLocationState?: Maybe<Scalars['String']['output']>;
  worksLocationSuburb?: Maybe<Scalars['String']['output']>;
};

export type EstimateFilterInput = {
  acceptedDate?: InputMaybe<DateTimeOperationFilterInput>;
  accessType?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<EstimateFilterInput>>;
  availabilityScope?: InputMaybe<NullableOfEstimateAvailabilityScopeOperationFilterInput>;
  availabilityStatus?: InputMaybe<NullableOfEstimateAvailabilityStatusOperationFilterInput>;
  buildingType?: InputMaybe<StringOperationFilterInput>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  canCopy?: InputMaybe<BooleanOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  datePrepared?: InputMaybe<DateTimeOperationFilterInput>;
  defaultMarkupRedistMethod?: InputMaybe<IntOperationFilterInput>;
  deletionSource?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  effectiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  estimateItems?: InputMaybe<ListFilterInputTypeOfEstimateItemFilterInput>;
  estimateMeasurements?: InputMaybe<ListFilterInputTypeOfEstimateMeasurementFilterInput>;
  estimateQuoteOptions?: InputMaybe<ListFilterInputTypeOfEstimateQuoteOptionFilterInput>;
  estimateSpecifications?: InputMaybe<ListFilterInputTypeOfEstimateSpecificationFilterInput>;
  estimateSuppliers?: InputMaybe<ListFilterInputTypeOfEstimateSupplierFilterInput>;
  estimateType?: InputMaybe<StringOperationFilterInput>;
  expiryDate?: InputMaybe<DateTimeOperationFilterInput>;
  franchiseFee?: InputMaybe<DecimalOperationFilterInput>;
  franchiseFeeTax?: InputMaybe<DecimalOperationFilterInput>;
  franchiseFeeTotal?: InputMaybe<DecimalOperationFilterInput>;
  franchiseFeeTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_Lead?: InputMaybe<UuidOperationFilterInput>;
  iD_SourceEstimate?: InputMaybe<UuidOperationFilterInput>;
  iD_Specification?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  iD_TenantShare?: InputMaybe<UuidOperationFilterInput>;
  iD_UserShare?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  isAccepted?: InputMaybe<BooleanOperationFilterInput>;
  isAccessible?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  isLegacySpecification?: InputMaybe<BooleanOperationFilterInput>;
  isPCPSRedistDefault?: InputMaybe<BooleanOperationFilterInput>;
  isSampleData?: InputMaybe<BooleanOperationFilterInput>;
  isSharedWithClient?: InputMaybe<BooleanOperationFilterInput>;
  isTaxApplied?: InputMaybe<BooleanOperationFilterInput>;
  livePricingCheckedAt?: InputMaybe<DateTimeOperationFilterInput>;
  markup?: InputMaybe<FloatOperationFilterInput>;
  markupContent?: InputMaybe<StringOperationFilterInput>;
  markupPercent?: InputMaybe<FloatOperationFilterInput>;
  markupTax?: InputMaybe<FloatOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EstimateFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  plans?: InputMaybe<ListFilterInputTypeOfPlanFilterInput>;
  postCode?: InputMaybe<StringOperationFilterInput>;
  quoteLastGeneratedAt?: InputMaybe<DateTimeOperationFilterInput>;
  quoteRequests?: InputMaybe<ListFilterInputTypeOfQuoteRequestFilterInput>;
  readOnly?: InputMaybe<BooleanOperationFilterInput>;
  rounding?: InputMaybe<StringOperationFilterInput>;
  roundingAdjustment?: InputMaybe<FloatOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  rowVersionN?: InputMaybe<LongOperationFilterInput>;
  scopeHiddenBy?: InputMaybe<UuidOperationFilterInput>;
  scopeHiddenDate?: InputMaybe<DateTimeOperationFilterInput>;
  scopeManagedBy?: InputMaybe<UuidOperationFilterInput>;
  scopeManagedDate?: InputMaybe<DateTimeOperationFilterInput>;
  scopePublicBy?: InputMaybe<UuidOperationFilterInput>;
  scopePublicDate?: InputMaybe<DateTimeOperationFilterInput>;
  sharedWithClientAt?: InputMaybe<DateTimeOperationFilterInput>;
  source?: InputMaybe<StringOperationFilterInput>;
  specificationIntroduction?: InputMaybe<StringOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  statusArchivedBy?: InputMaybe<UuidOperationFilterInput>;
  statusArchivedDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusDraftBy?: InputMaybe<UuidOperationFilterInput>;
  statusDraftDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusExpiredBy?: InputMaybe<UuidOperationFilterInput>;
  statusExpiredDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusPublishedBy?: InputMaybe<UuidOperationFilterInput>;
  statusPublishedDate?: InputMaybe<DateTimeOperationFilterInput>;
  suburb?: InputMaybe<StringOperationFilterInput>;
  targetProfession?: InputMaybe<StringOperationFilterInput>;
  tax?: InputMaybe<FloatOperationFilterInput>;
  taxIncMarkup?: InputMaybe<FloatOperationFilterInput>;
  taxRate?: InputMaybe<FloatOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  taxRatesDescription?: InputMaybe<StringOperationFilterInput>;
  taxRatesShortCode?: InputMaybe<StringOperationFilterInput>;
  total?: InputMaybe<FloatOperationFilterInput>;
  totalIncMarkup?: InputMaybe<FloatOperationFilterInput>;
  totalIncMarkupTax?: InputMaybe<FloatOperationFilterInput>;
  totalIncMarkupTaxRange?: InputMaybe<StringOperationFilterInput>;
  totalIncTax?: InputMaybe<FloatOperationFilterInput>;
  variety?: InputMaybe<EstimateVarietyOperationFilterInput>;
  worksLocation?: InputMaybe<StringOperationFilterInput>;
  worksLocationAddress?: InputMaybe<StringOperationFilterInput>;
  worksLocationLat?: InputMaybe<DecimalOperationFilterInput>;
  worksLocationLng?: InputMaybe<DecimalOperationFilterInput>;
  worksLocationMapUrl?: InputMaybe<StringOperationFilterInput>;
  worksLocationPostcode?: InputMaybe<StringOperationFilterInput>;
  worksLocationState?: InputMaybe<StringOperationFilterInput>;
  worksLocationSuburb?: InputMaybe<StringOperationFilterInput>;
};

export type EstimateImport = {
  __typename?: 'EstimateImport';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  estimate?: Maybe<Estimate>;
  failureReason?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  firstWebhook?: Maybe<Scalars['DateTime']['output']>;
  iD_Batch: Scalars['Uuid']['output'];
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateImport: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  iD_User: Scalars['Uuid']['output'];
  importType: ImportType;
  importedRows: Scalars['Int']['output'];
  importingRows: Scalars['Int']['output'];
  jobId?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  originalRows: Scalars['Int']['output'];
  spaceId?: Maybe<Scalars['String']['output']>;
  status: EstimateImportStatus;
};

export const EstimateImportStatus = {
  Completed: 'COMPLETED',
  Failed: 'FAILED',
  Importing: 'IMPORTING'
} as const;

export type EstimateImportStatus = typeof EstimateImportStatus[keyof typeof EstimateImportStatus];
export type EstimateItem = {
  __typename?: 'EstimateItem';
  costCategory?: Maybe<Scalars['String']['output']>;
  costItem?: Maybe<CostItemView>;
  costItemType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayedOrderNumber?: Maybe<Scalars['String']['output']>;
  estimate?: Maybe<Estimate>;
  estimateSpecifications?: Maybe<Array<Maybe<EstimateSpecification>>>;
  iD_Contact?: Maybe<Scalars['Uuid']['output']>;
  iD_CostItem?: Maybe<Scalars['Uuid']['output']>;
  iD_CostSubItem?: Maybe<Scalars['Uuid']['output']>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateItem: Scalars['Uuid']['output'];
  iD_PlanMeasurement?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestRecipeItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestRecipient?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestRecipientItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestRecipientRecipeItem?: Maybe<Scalars['Uuid']['output']>;
  indexer: Scalars['Long']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isMarkupRedistributed: Scalars['Boolean']['output'];
  isOrdered?: Maybe<Scalars['Boolean']['output']>;
  isParent: Scalars['Boolean']['output'];
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  isRecipientAdded?: Maybe<Scalars['Boolean']['output']>;
  isTaxApplied: Scalars['Boolean']['output'];
  isUnitCostCopied: Scalars['Boolean']['output'];
  isUnitsCopied: Scalars['Boolean']['output'];
  itemCode?: Maybe<Scalars['String']['output']>;
  itemType: Scalars['Int']['output'];
  labourTotal?: Maybe<Scalars['Decimal']['output']>;
  livePricingStatus: Scalars['Int']['output'];
  markup: Scalars['Float']['output'];
  markupContent?: Maybe<Scalars['String']['output']>;
  markupTotal: Scalars['Decimal']['output'];
  materialsTotal?: Maybe<Scalars['Decimal']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  oneBuildSourceId?: Maybe<Scalars['Uuid']['output']>;
  orderNumber?: Maybe<Scalars['Int']['output']>;
  parentID?: Maybe<Scalars['Uuid']['output']>;
  redistributedMethod: Scalars['Int']['output'];
  showInSchedule: Scalars['Boolean']['output'];
  showInSpecifications: Scalars['Boolean']['output'];
  specifications?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  supplierCode?: Maybe<Scalars['String']['output']>;
  supplierOrderQuoteId?: Maybe<Scalars['String']['output']>;
  supplierQuoteError?: Maybe<SupplierQuoteResponseErrorCodes>;
  supplierQuoteErrorMessage?: Maybe<Scalars['String']['output']>;
  supplierQuoteIntegrationProviderId?: Maybe<Scalars['Uuid']['output']>;
  tax?: Maybe<Scalars['Decimal']['output']>;
  taxContent?: Maybe<Scalars['String']['output']>;
  taxRates?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Decimal']['output']>;
  totalIncMarkup: Scalars['Decimal']['output'];
  totalIncTax?: Maybe<Scalars['Decimal']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  uOMMinOrderQty?: Maybe<Scalars['Decimal']['output']>;
  uOMOrderIncrements?: Maybe<Scalars['Decimal']['output']>;
  uOMUnits?: Maybe<Scalars['Decimal']['output']>;
  unitCost?: Maybe<Scalars['Decimal']['output']>;
  units?: Maybe<Scalars['Decimal']['output']>;
  unitsExpression?: Maybe<Scalars['String']['output']>;
};

export type EstimateItemFilterInput = {
  and?: InputMaybe<Array<EstimateItemFilterInput>>;
  costCategory?: InputMaybe<StringOperationFilterInput>;
  costItem?: InputMaybe<CostItemViewFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  displayedOrderNumber?: InputMaybe<StringOperationFilterInput>;
  estimate?: InputMaybe<EstimateFilterInput>;
  estimateSpecifications?: InputMaybe<ListFilterInputTypeOfEstimateSpecificationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_CostItem?: InputMaybe<UuidOperationFilterInput>;
  iD_CostSubItem?: InputMaybe<UuidOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_PlanMeasurement?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipeItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipient?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipientItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipientRecipeItem?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isHidden?: InputMaybe<BooleanOperationFilterInput>;
  isMarkupRedistributed?: InputMaybe<BooleanOperationFilterInput>;
  isOrdered?: InputMaybe<BooleanOperationFilterInput>;
  isParent?: InputMaybe<BooleanOperationFilterInput>;
  isReadOnly?: InputMaybe<BooleanOperationFilterInput>;
  isRecipientAdded?: InputMaybe<BooleanOperationFilterInput>;
  isTaxApplied?: InputMaybe<BooleanOperationFilterInput>;
  isUnitCostCopied?: InputMaybe<BooleanOperationFilterInput>;
  isUnitsCopied?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  itemType?: InputMaybe<IntOperationFilterInput>;
  labourTotal?: InputMaybe<DecimalOperationFilterInput>;
  livePricingStatus?: InputMaybe<IntOperationFilterInput>;
  markup?: InputMaybe<FloatOperationFilterInput>;
  markupContent?: InputMaybe<StringOperationFilterInput>;
  markupTotal?: InputMaybe<DecimalOperationFilterInput>;
  materialsTotal?: InputMaybe<DecimalOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  oneBuildSourceId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<EstimateItemFilterInput>>;
  orderNumber?: InputMaybe<IntOperationFilterInput>;
  parentID?: InputMaybe<UuidOperationFilterInput>;
  redistributedMethod?: InputMaybe<IntOperationFilterInput>;
  showInSchedule?: InputMaybe<BooleanOperationFilterInput>;
  showInSpecifications?: InputMaybe<BooleanOperationFilterInput>;
  specifications?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  supplierCode?: InputMaybe<StringOperationFilterInput>;
  supplierOrderQuoteId?: InputMaybe<StringOperationFilterInput>;
  supplierQuoteError?: InputMaybe<NullableOfSupplierQuoteResponseErrorCodesOperationFilterInput>;
  supplierQuoteErrorMessage?: InputMaybe<StringOperationFilterInput>;
  supplierQuoteIntegrationProviderId?: InputMaybe<UuidOperationFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  taxContent?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  totalIncMarkup?: InputMaybe<DecimalOperationFilterInput>;
  totalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  uOMMinOrderQty?: InputMaybe<DecimalOperationFilterInput>;
  uOMOrderIncrements?: InputMaybe<DecimalOperationFilterInput>;
  uOMUnits?: InputMaybe<DecimalOperationFilterInput>;
  unitCost?: InputMaybe<DecimalOperationFilterInput>;
  units?: InputMaybe<DecimalOperationFilterInput>;
  unitsExpression?: InputMaybe<StringOperationFilterInput>;
};

export type EstimateItemSortInput = {
  costCategory?: InputMaybe<SortEnumType>;
  costItem?: InputMaybe<CostItemViewSortInput>;
  costItemType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  displayedOrderNumber?: InputMaybe<SortEnumType>;
  estimate?: InputMaybe<EstimateSortInput>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_CostItem?: InputMaybe<SortEnumType>;
  iD_CostSubItem?: InputMaybe<SortEnumType>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_EstimateItem?: InputMaybe<SortEnumType>;
  iD_PlanMeasurement?: InputMaybe<SortEnumType>;
  iD_QuoteRequest?: InputMaybe<SortEnumType>;
  iD_QuoteRequestItem?: InputMaybe<SortEnumType>;
  iD_QuoteRequestRecipeItem?: InputMaybe<SortEnumType>;
  iD_QuoteRequestRecipient?: InputMaybe<SortEnumType>;
  iD_QuoteRequestRecipientItem?: InputMaybe<SortEnumType>;
  iD_QuoteRequestRecipientRecipeItem?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isHidden?: InputMaybe<SortEnumType>;
  isMarkupRedistributed?: InputMaybe<SortEnumType>;
  isOrdered?: InputMaybe<SortEnumType>;
  isParent?: InputMaybe<SortEnumType>;
  isReadOnly?: InputMaybe<SortEnumType>;
  isRecipientAdded?: InputMaybe<SortEnumType>;
  isTaxApplied?: InputMaybe<SortEnumType>;
  isUnitCostCopied?: InputMaybe<SortEnumType>;
  isUnitsCopied?: InputMaybe<SortEnumType>;
  itemCode?: InputMaybe<SortEnumType>;
  itemType?: InputMaybe<SortEnumType>;
  labourTotal?: InputMaybe<SortEnumType>;
  livePricingStatus?: InputMaybe<SortEnumType>;
  markup?: InputMaybe<SortEnumType>;
  markupContent?: InputMaybe<SortEnumType>;
  markupTotal?: InputMaybe<SortEnumType>;
  materialsTotal?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  oneBuildSourceId?: InputMaybe<SortEnumType>;
  orderNumber?: InputMaybe<SortEnumType>;
  parentID?: InputMaybe<SortEnumType>;
  redistributedMethod?: InputMaybe<SortEnumType>;
  showInSchedule?: InputMaybe<SortEnumType>;
  showInSpecifications?: InputMaybe<SortEnumType>;
  specifications?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  supplierCode?: InputMaybe<SortEnumType>;
  supplierOrderQuoteId?: InputMaybe<SortEnumType>;
  supplierQuoteError?: InputMaybe<SortEnumType>;
  supplierQuoteErrorMessage?: InputMaybe<SortEnumType>;
  supplierQuoteIntegrationProviderId?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  taxContent?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  totalIncMarkup?: InputMaybe<SortEnumType>;
  totalIncTax?: InputMaybe<SortEnumType>;
  uOM?: InputMaybe<SortEnumType>;
  uOMMinOrderQty?: InputMaybe<SortEnumType>;
  uOMOrderIncrements?: InputMaybe<SortEnumType>;
  uOMUnits?: InputMaybe<SortEnumType>;
  unitCost?: InputMaybe<SortEnumType>;
  units?: InputMaybe<SortEnumType>;
  unitsExpression?: InputMaybe<SortEnumType>;
};

export type EstimateMeasurement = {
  __typename?: 'EstimateMeasurement';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  estimate?: Maybe<Estimate>;
  expression?: Maybe<Scalars['String']['output']>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateMeasurement: Scalars['Uuid']['output'];
  iD_PlanMeasurement?: Maybe<Scalars['Uuid']['output']>;
  indexer: Scalars['Long']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isInitialisedForCategory: Scalars['Boolean']['output'];
  isMeasurementCopied: Scalars['Boolean']['output'];
  isParent: Scalars['Boolean']['output'];
  key: Scalars['Int']['output'];
  measurement: Scalars['Float']['output'];
  measurementData?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
};

export type EstimateMeasurementFilterInput = {
  and?: InputMaybe<Array<EstimateMeasurementFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  estimate?: InputMaybe<EstimateFilterInput>;
  expression?: InputMaybe<StringOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateMeasurement?: InputMaybe<UuidOperationFilterInput>;
  iD_PlanMeasurement?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isInitialisedForCategory?: InputMaybe<BooleanOperationFilterInput>;
  isMeasurementCopied?: InputMaybe<BooleanOperationFilterInput>;
  isParent?: InputMaybe<BooleanOperationFilterInput>;
  key?: InputMaybe<IntOperationFilterInput>;
  measurement?: InputMaybe<FloatOperationFilterInput>;
  measurementData?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EstimateMeasurementFilterInput>>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
};

export type EstimateModelForMergeTags = {
  __typename?: 'EstimateModelForMergeTags';
  allowances?: Maybe<Array<Maybe<AllowanceModelForMergeTags>>>;
  categories?: Maybe<Array<Maybe<CategoryModelForMergeTags>>>;
  categoryItems?: Maybe<Array<Maybe<CategoryItemModelForMergeTags>>>;
  categoryWithAllowances?: Maybe<CategoriesWithAllowances>;
  categoryWithAllowancesForProvisionalSums?: Maybe<CategoriesWithAllowances>;
  categoryWithItems?: Maybe<Array<Maybe<CategoryWithItems>>>;
  customer?: Maybe<CustomerModelForMergeTags>;
  estimate?: Maybe<EstimateDetailsModelForMergeTags>;
  estimateSpecifications?: Maybe<Array<Maybe<EstimateSpecificationCategoryModel>>>;
  tenant?: Maybe<TenantModelForMergeTags>;
};

export type EstimateQuoteOption = {
  __typename?: 'EstimateQuoteOption';
  body?: Maybe<Scalars['String']['output']>;
  estimate?: Maybe<Estimate>;
  iD_Estimate?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteOptions: Scalars['Uuid']['output'];
  indexer: Scalars['Long']['output'];
  pCPrintOption: Scalars['Short']['output'];
  pSPrintOption: Scalars['Short']['output'];
  printBusinessInfo?: Maybe<Scalars['Boolean']['output']>;
  printCategoriesAndItems?: Maybe<Scalars['Boolean']['output']>;
  printCategoryTotals: Scalars['Boolean']['output'];
  printCategoryTotalsIncludeTax: Scalars['Boolean']['output'];
  printInitialsArea: Scalars['Boolean']['output'];
  printItemTotals: Scalars['Boolean']['output'];
  printItems: Scalars['Boolean']['output'];
  printLogo?: Maybe<Scalars['Boolean']['output']>;
  printQuantities?: Maybe<Scalars['Boolean']['output']>;
  printSpecificationNumbers: Scalars['Boolean']['output'];
  printSpecifications?: Maybe<Scalars['Boolean']['output']>;
  printUnitMeasures: Scalars['Boolean']['output'];
  printZeroDollarItems: Scalars['Boolean']['output'];
  showMarkup: Scalars['Boolean']['output'];
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  useExistingLetterhead: Scalars['Boolean']['output'];
  validDays?: Maybe<Scalars['Int']['output']>;
};

export type EstimateQuoteOptionFilterInput = {
  and?: InputMaybe<Array<EstimateQuoteOptionFilterInput>>;
  body?: InputMaybe<StringOperationFilterInput>;
  estimate?: InputMaybe<EstimateFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteOptions?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<EstimateQuoteOptionFilterInput>>;
  pCPrintOption?: InputMaybe<ShortOperationFilterInput>;
  pSPrintOption?: InputMaybe<ShortOperationFilterInput>;
  printBusinessInfo?: InputMaybe<BooleanOperationFilterInput>;
  printCategoriesAndItems?: InputMaybe<BooleanOperationFilterInput>;
  printCategoryTotals?: InputMaybe<BooleanOperationFilterInput>;
  printCategoryTotalsIncludeTax?: InputMaybe<BooleanOperationFilterInput>;
  printInitialsArea?: InputMaybe<BooleanOperationFilterInput>;
  printItemTotals?: InputMaybe<BooleanOperationFilterInput>;
  printItems?: InputMaybe<BooleanOperationFilterInput>;
  printLogo?: InputMaybe<BooleanOperationFilterInput>;
  printQuantities?: InputMaybe<BooleanOperationFilterInput>;
  printSpecificationNumbers?: InputMaybe<BooleanOperationFilterInput>;
  printSpecifications?: InputMaybe<BooleanOperationFilterInput>;
  printUnitMeasures?: InputMaybe<BooleanOperationFilterInput>;
  printZeroDollarItems?: InputMaybe<BooleanOperationFilterInput>;
  showMarkup?: InputMaybe<BooleanOperationFilterInput>;
  termsAndConditions?: InputMaybe<StringOperationFilterInput>;
  useExistingLetterhead?: InputMaybe<BooleanOperationFilterInput>;
  validDays?: InputMaybe<IntOperationFilterInput>;
};

export type EstimateSortInput = {
  acceptedDate?: InputMaybe<SortEnumType>;
  accessType?: InputMaybe<SortEnumType>;
  address?: InputMaybe<SortEnumType>;
  availabilityScope?: InputMaybe<SortEnumType>;
  availabilityStatus?: InputMaybe<SortEnumType>;
  buildingType?: InputMaybe<SortEnumType>;
  businessName?: InputMaybe<SortEnumType>;
  calculationMethod?: InputMaybe<SortEnumType>;
  canCopy?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  datePrepared?: InputMaybe<SortEnumType>;
  defaultMarkupRedistMethod?: InputMaybe<SortEnumType>;
  deletionSource?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  estimateType?: InputMaybe<SortEnumType>;
  expiryDate?: InputMaybe<SortEnumType>;
  franchiseFee?: InputMaybe<SortEnumType>;
  franchiseFeeTax?: InputMaybe<SortEnumType>;
  franchiseFeeTotal?: InputMaybe<SortEnumType>;
  franchiseFeeTotalIncTax?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_Job?: InputMaybe<SortEnumType>;
  iD_Lead?: InputMaybe<SortEnumType>;
  iD_SourceEstimate?: InputMaybe<SortEnumType>;
  iD_Specification?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  iD_TenantShare?: InputMaybe<SortEnumType>;
  iD_UserShare?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  isAccepted?: InputMaybe<SortEnumType>;
  isAccessible?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGlobalSynced?: InputMaybe<SortEnumType>;
  isLegacySpecification?: InputMaybe<SortEnumType>;
  isPCPSRedistDefault?: InputMaybe<SortEnumType>;
  isSampleData?: InputMaybe<SortEnumType>;
  isSharedWithClient?: InputMaybe<SortEnumType>;
  isTaxApplied?: InputMaybe<SortEnumType>;
  livePricingCheckedAt?: InputMaybe<SortEnumType>;
  markup?: InputMaybe<SortEnumType>;
  markupContent?: InputMaybe<SortEnumType>;
  markupPercent?: InputMaybe<SortEnumType>;
  markupTax?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  postCode?: InputMaybe<SortEnumType>;
  privateTotalIncMarkupTax?: InputMaybe<SortEnumType>;
  quoteLastGeneratedAt?: InputMaybe<SortEnumType>;
  readOnly?: InputMaybe<SortEnumType>;
  rounding?: InputMaybe<SortEnumType>;
  roundingAdjustment?: InputMaybe<SortEnumType>;
  rowVersionN?: InputMaybe<SortEnumType>;
  scopeHiddenBy?: InputMaybe<SortEnumType>;
  scopeHiddenDate?: InputMaybe<SortEnumType>;
  scopeManagedBy?: InputMaybe<SortEnumType>;
  scopeManagedDate?: InputMaybe<SortEnumType>;
  scopePublicBy?: InputMaybe<SortEnumType>;
  scopePublicDate?: InputMaybe<SortEnumType>;
  sharedWithClientAt?: InputMaybe<SortEnumType>;
  source?: InputMaybe<SortEnumType>;
  specificationIntroduction?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusArchivedBy?: InputMaybe<SortEnumType>;
  statusArchivedDate?: InputMaybe<SortEnumType>;
  statusDraftBy?: InputMaybe<SortEnumType>;
  statusDraftDate?: InputMaybe<SortEnumType>;
  statusExpiredBy?: InputMaybe<SortEnumType>;
  statusExpiredDate?: InputMaybe<SortEnumType>;
  statusPublishedBy?: InputMaybe<SortEnumType>;
  statusPublishedDate?: InputMaybe<SortEnumType>;
  suburb?: InputMaybe<SortEnumType>;
  targetProfession?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  taxIncMarkup?: InputMaybe<SortEnumType>;
  taxRate?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  taxRatesDescription?: InputMaybe<SortEnumType>;
  taxRatesShortCode?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  totalIncMarkup?: InputMaybe<SortEnumType>;
  totalIncMarkupTax?: InputMaybe<SortEnumType>;
  totalIncMarkupTaxRange?: InputMaybe<SortEnumType>;
  totalIncTax?: InputMaybe<SortEnumType>;
  variety?: InputMaybe<SortEnumType>;
  worksLocation?: InputMaybe<SortEnumType>;
  worksLocationAddress?: InputMaybe<SortEnumType>;
  worksLocationLat?: InputMaybe<SortEnumType>;
  worksLocationLng?: InputMaybe<SortEnumType>;
  worksLocationMapUrl?: InputMaybe<SortEnumType>;
  worksLocationPostcode?: InputMaybe<SortEnumType>;
  worksLocationState?: InputMaybe<SortEnumType>;
  worksLocationSuburb?: InputMaybe<SortEnumType>;
};

export type EstimateSpecification = {
  __typename?: 'EstimateSpecification';
  category?: Maybe<Scalars['String']['output']>;
  children?: Maybe<ChildrenCollectionSegment>;
  cost?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  customerContact?: Maybe<CustomerContactView>;
  customerContactId?: Maybe<Scalars['Uuid']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  estimate?: Maybe<Estimate>;
  estimateItem?: Maybe<EstimateItem>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_EstimateSpecification: Scalars['Uuid']['output'];
  iD_Image?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isLegacy: Scalars['Boolean']['output'];
  isParent: Scalars['Boolean']['output'];
  itemDetailsUrl?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['Float']['output'];
  parent?: Maybe<EstimateSpecification>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
  shareStatus: EstimateSpecificationShareStatus;
  sharedAt?: Maybe<Scalars['DateTime']['output']>;
  specification?: Maybe<Scalars['String']['output']>;
  supplierCode?: Maybe<Scalars['String']['output']>;
};


export type EstimateSpecificationChildrenArgs = {
  order?: InputMaybe<Array<EstimateSpecificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateSpecificationFilterInput>;
};

export type EstimateSpecificationCategoryModel = {
  __typename?: 'EstimateSpecificationCategoryModel';
  category?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['Int']['output'];
  specifications?: Maybe<Array<Maybe<EstimateSpecificationModelForMergeTags>>>;
};

export type EstimateSpecificationCategoryUpdateInput = {
  category: Scalars['String']['input'];
  iD_EstimateSpecification: Scalars['Uuid']['input'];
};

/** A segment of a collection. */
export type EstimateSpecificationCollectionSegment = {
  __typename?: 'EstimateSpecificationCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EstimateSpecification>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EstimateSpecificationFilterInput = {
  and?: InputMaybe<Array<EstimateSpecificationFilterInput>>;
  category?: InputMaybe<StringOperationFilterInput>;
  children?: InputMaybe<ListFilterInputTypeOfEstimateSpecificationFilterInput>;
  cost?: InputMaybe<DecimalOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  customerContact?: InputMaybe<CustomerContactViewFilterInput>;
  customerContactId?: InputMaybe<UuidOperationFilterInput>;
  dueDate?: InputMaybe<DateTimeOperationFilterInput>;
  estimate?: InputMaybe<EstimateFilterInput>;
  estimateItem?: InputMaybe<EstimateItemFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateSpecification?: InputMaybe<UuidOperationFilterInput>;
  iD_Image?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isLegacy?: InputMaybe<BooleanOperationFilterInput>;
  isParent?: InputMaybe<BooleanOperationFilterInput>;
  itemDetailsUrl?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EstimateSpecificationFilterInput>>;
  orderNumber?: InputMaybe<FloatOperationFilterInput>;
  parent?: InputMaybe<EstimateSpecificationFilterInput>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  shareStatus?: InputMaybe<NullableOfEstimateSpecificationShareStatusOperationFilterInput>;
  sharedAt?: InputMaybe<DateTimeOperationFilterInput>;
  specification?: InputMaybe<StringOperationFilterInput>;
  supplierCode?: InputMaybe<StringOperationFilterInput>;
};

export type EstimateSpecificationModel = {
  __typename?: 'EstimateSpecificationModel';
  category?: Maybe<Scalars['String']['output']>;
  estimateItem?: Maybe<EstimateItem>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_EstimateSpecification: Scalars['Uuid']['output'];
  iD_Image?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isLegacy?: Maybe<Scalars['Boolean']['output']>;
  isParent: Scalars['Boolean']['output'];
  orderNumber: Scalars['Float']['output'];
  parentId?: Maybe<Scalars['Uuid']['output']>;
  shareStatus: EstimateSpecificationShareStatus;
  sharedAt?: Maybe<Scalars['DateTime']['output']>;
  specification?: Maybe<Scalars['String']['output']>;
};

export type EstimateSpecificationModelFilterInput = {
  and?: InputMaybe<Array<EstimateSpecificationModelFilterInput>>;
  category?: InputMaybe<StringOperationFilterInput>;
  estimateItem?: InputMaybe<EstimateItemFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateSpecification?: InputMaybe<UuidOperationFilterInput>;
  iD_Image?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isLegacy?: InputMaybe<BooleanOperationFilterInput>;
  isParent?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EstimateSpecificationModelFilterInput>>;
  orderNumber?: InputMaybe<FloatOperationFilterInput>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  shareStatus?: InputMaybe<EstimateSpecificationShareStatusOperationFilterInput>;
  sharedAt?: InputMaybe<DateTimeOperationFilterInput>;
  specification?: InputMaybe<StringOperationFilterInput>;
};

export type EstimateSpecificationModelForMergeTags = {
  __typename?: 'EstimateSpecificationModelForMergeTags';
  imageUrl?: Maybe<Scalars['String']['output']>;
  shareStatus?: Maybe<Scalars['String']['output']>;
  specification?: Maybe<Scalars['String']['output']>;
  specificationNumber?: Maybe<Scalars['String']['output']>;
};

export type EstimateSpecificationModelSortInput = {
  category?: InputMaybe<SortEnumType>;
  estimateItem?: InputMaybe<EstimateItemSortInput>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_EstimateItem?: InputMaybe<SortEnumType>;
  iD_EstimateSpecification?: InputMaybe<SortEnumType>;
  iD_Image?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  imageUrl?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isLegacy?: InputMaybe<SortEnumType>;
  isParent?: InputMaybe<SortEnumType>;
  orderNumber?: InputMaybe<SortEnumType>;
  parentId?: InputMaybe<SortEnumType>;
  shareStatus?: InputMaybe<SortEnumType>;
  sharedAt?: InputMaybe<SortEnumType>;
  specification?: InputMaybe<SortEnumType>;
};

export type EstimateSpecificationPatchInput = {
  cost?: InputMaybe<Scalars['Decimal']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  iD_EstimateSpecification: Scalars['Uuid']['input'];
  iD_Image?: InputMaybe<Scalars['Uuid']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  itemDetailsUrl?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  shareStatus?: InputMaybe<EstimateSpecificationShareStatus>;
  specification?: InputMaybe<Scalars['String']['input']>;
  supplierCode?: InputMaybe<Scalars['String']['input']>;
};

export const EstimateSpecificationShareStatus = {
  Approved: 'APPROVED',
  Confirmed: 'CONFIRMED',
  Excludefromshare: 'EXCLUDEFROMSHARE',
  Notshared: 'NOTSHARED',
  Pendingapproval: 'PENDINGAPPROVAL',
  Rejected: 'REJECTED',
  Selected: 'SELECTED',
  Shared: 'SHARED'
} as const;

export type EstimateSpecificationShareStatus = typeof EstimateSpecificationShareStatus[keyof typeof EstimateSpecificationShareStatus];
export type EstimateSpecificationShareStatusOperationFilterInput = {
  eq?: InputMaybe<EstimateSpecificationShareStatus>;
  in?: InputMaybe<Array<EstimateSpecificationShareStatus>>;
  neq?: InputMaybe<EstimateSpecificationShareStatus>;
  nin?: InputMaybe<Array<EstimateSpecificationShareStatus>>;
};

export type EstimateSpecificationSortInput = {
  category?: InputMaybe<SortEnumType>;
  cost?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  customerContact?: InputMaybe<CustomerContactViewSortInput>;
  customerContactId?: InputMaybe<SortEnumType>;
  dueDate?: InputMaybe<SortEnumType>;
  estimate?: InputMaybe<EstimateSortInput>;
  estimateItem?: InputMaybe<EstimateItemSortInput>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_EstimateItem?: InputMaybe<SortEnumType>;
  iD_EstimateSpecification?: InputMaybe<SortEnumType>;
  iD_Image?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  imageUrl?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isLegacy?: InputMaybe<SortEnumType>;
  isParent?: InputMaybe<SortEnumType>;
  itemDetailsUrl?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  orderNumber?: InputMaybe<SortEnumType>;
  parent?: InputMaybe<EstimateSpecificationSortInput>;
  parentId?: InputMaybe<SortEnumType>;
  shareStatus?: InputMaybe<SortEnumType>;
  sharedAt?: InputMaybe<SortEnumType>;
  specification?: InputMaybe<SortEnumType>;
  supplierCode?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type EstimateSpecificationsCollectionSegment = {
  __typename?: 'EstimateSpecificationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<EstimateSpecificationModel>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type EstimateSpecificationsV3CollectionSegment = {
  __typename?: 'EstimateSpecificationsV3CollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EstimateSpecification>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EstimateSupplier = {
  __typename?: 'EstimateSupplier';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Uuid']['output'];
  iD_Estimate: Scalars['Uuid']['output'];
  iD_IntegrationProvider: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  isDeleted: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  supplierStore?: Maybe<SupplierStore>;
  supplierStoreNumber: Scalars['String']['output'];
};

export type EstimateSupplierFilterInput = {
  and?: InputMaybe<Array<EstimateSupplierFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  estimate?: InputMaybe<EstimateFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateSupplier?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationProvider?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<EstimateSupplierFilterInput>>;
  supplierStoreNumber?: InputMaybe<StringOperationFilterInput>;
};

export type EstimateSupplierSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  estimate?: InputMaybe<EstimateSortInput>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_EstimateSupplier?: InputMaybe<SortEnumType>;
  iD_IntegrationProvider?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  supplierStoreNumber?: InputMaybe<SortEnumType>;
};

export type EstimateTemplateSubscription = {
  __typename?: 'EstimateTemplateSubscription';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  estimate?: Maybe<Estimate>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateTemplateSubscription: Scalars['Uuid']['output'];
  iD_IntegrationConnection: Scalars['Uuid']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  status: EstimateTemplateSubscriptionStatus;
};

export const EstimateTemplateSubscriptionStatus = {
  Subscribed: 'SUBSCRIBED',
  Unsubscribed: 'UNSUBSCRIBED'
} as const;

export type EstimateTemplateSubscriptionStatus = typeof EstimateTemplateSubscriptionStatus[keyof typeof EstimateTemplateSubscriptionStatus];
/** A segment of a collection. */
export type EstimateTemplatesForIntegrationConnectionCollectionSegment = {
  __typename?: 'EstimateTemplatesForIntegrationConnectionCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<Estimate>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export const EstimateToolLimit = {
  Estimategenerations: 'ESTIMATEGENERATIONS',
  Totaldailysessions: 'TOTALDAILYSESSIONS'
} as const;

export type EstimateToolLimit = typeof EstimateToolLimit[keyof typeof EstimateToolLimit];
export const EstimateVariety = {
  Rfq: 'RFQ',
  Standard: 'STANDARD'
} as const;

export type EstimateVariety = typeof EstimateVariety[keyof typeof EstimateVariety];
export type EstimateVarietyOperationFilterInput = {
  eq?: InputMaybe<EstimateVariety>;
  in?: InputMaybe<Array<EstimateVariety>>;
  neq?: InputMaybe<EstimateVariety>;
  nin?: InputMaybe<Array<EstimateVariety>>;
};

export type EstimateViewFilterInput = {
  acceptedDate?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<EstimateViewFilterInput>>;
  buildingType?: InputMaybe<StringOperationFilterInput>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  clientAddress?: InputMaybe<StringOperationFilterInput>;
  clientCityTown?: InputMaybe<StringOperationFilterInput>;
  clientEmail?: InputMaybe<StringOperationFilterInput>;
  clientMobile?: InputMaybe<StringOperationFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  clientPhone?: InputMaybe<StringOperationFilterInput>;
  clientPostCode?: InputMaybe<StringOperationFilterInput>;
  clientState?: InputMaybe<StringOperationFilterInput>;
  datePrepared?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  franchiseFee?: InputMaybe<DecimalOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_Lead?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isAccepted?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  isSampleData?: InputMaybe<BooleanOperationFilterInput>;
  isSharedWithClient?: InputMaybe<BooleanOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EstimateViewFilterInput>>;
  rowVersionN?: InputMaybe<LongOperationFilterInput>;
  sharedWithClientAt?: InputMaybe<DateTimeOperationFilterInput>;
  source?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  taxIncMarkup?: InputMaybe<FloatOperationFilterInput>;
  taxRate?: InputMaybe<FloatOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  taxRatesDescription?: InputMaybe<StringOperationFilterInput>;
  taxRatesShortCode?: InputMaybe<StringOperationFilterInput>;
  totalIncMarkup?: InputMaybe<FloatOperationFilterInput>;
  totalIncMarkupTax?: InputMaybe<FloatOperationFilterInput>;
  worksLocation?: InputMaybe<StringOperationFilterInput>;
  worksLocationAddress?: InputMaybe<StringOperationFilterInput>;
  worksLocationPostcode?: InputMaybe<StringOperationFilterInput>;
  worksLocationState?: InputMaybe<StringOperationFilterInput>;
  worksLocationSuburb?: InputMaybe<StringOperationFilterInput>;
};

/** A segment of a collection. */
export type EstimatesByCustomerCollectionSegment = {
  __typename?: 'EstimatesByCustomerCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<Estimate>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type EstimatesCollectionSegment = {
  __typename?: 'EstimatesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<Estimate>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EstimatesWonMetric = {
  __typename?: 'EstimatesWonMetric';
  client?: Maybe<Scalars['String']['output']>;
  estimatesSent: Scalars['Decimal']['output'];
  estimatesWon: Scalars['Decimal']['output'];
  estimatesWonTotal: Scalars['Decimal']['output'];
  location?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type EstimatesWonMetricConnection = {
  __typename?: 'EstimatesWonMetricConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EstimatesWonMetricEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<EstimatesWonMetric>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstimatesWonMetricEdge = {
  __typename?: 'EstimatesWonMetricEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstimatesWonMetric>;
};

export type EstimatesWonMetricFilterInput = {
  and?: InputMaybe<Array<EstimatesWonMetricFilterInput>>;
  client?: InputMaybe<StringOperationFilterInput>;
  estimatesSent?: InputMaybe<DecimalOperationFilterInput>;
  estimatesWon?: InputMaybe<DecimalOperationFilterInput>;
  estimatesWonTotal?: InputMaybe<DecimalOperationFilterInput>;
  location?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EstimatesWonMetricFilterInput>>;
};

export type EstimatesWonMetricSortInput = {
  client?: InputMaybe<SortEnumType>;
  estimatesSent?: InputMaybe<SortEnumType>;
  estimatesWon?: InputMaybe<SortEnumType>;
  estimatesWonTotal?: InputMaybe<SortEnumType>;
  location?: InputMaybe<SortEnumType>;
};

export type EventRecord = {
  __typename?: 'EventRecord';
  eventRecordId: Scalars['Uuid']['output'];
  lastOccurred: Scalars['DateTime']['output'];
  name?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['Uuid']['output'];
  userId: Scalars['Uuid']['output'];
};

/** A segment of a collection. */
export type EventRecordCollectionSegment = {
  __typename?: 'EventRecordCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<EventRecord>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EventRecordFilterInput = {
  and?: InputMaybe<Array<EventRecordFilterInput>>;
  eventRecordId?: InputMaybe<UuidOperationFilterInput>;
  lastOccurred?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EventRecordFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
};

export type EventRecordSortInput = {
  eventRecordId?: InputMaybe<SortEnumType>;
  lastOccurred?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type ExceedingNoOfImagesError = Error & {
  __typename?: 'ExceedingNoOfImagesError';
  limit: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type ExportFile = {
  __typename?: 'ExportFile';
  size: Scalars['Long']['output'];
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ExportPdfRequestInput = {
  exportPdfType?: InputMaybe<ExportPdfType>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  referenceObjectId?: InputMaybe<Scalars['Uuid']['input']>;
  templateId: Scalars['Uuid']['input'];
};

export type ExportPdfResponse = {
  __typename?: 'ExportPdfResponse';
  htmlUrl?: Maybe<Scalars['String']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
};

export const ExportPdfType = {
  Estimatequote: 'ESTIMATEQUOTE'
} as const;

export type ExportPdfType = typeof ExportPdfType[keyof typeof ExportPdfType];
export type ExternalJob = {
  __typename?: 'ExternalJob';
  description?: Maybe<Scalars['String']['output']>;
  iD_Job: Scalars['Uuid']['output'];
  number?: Maybe<Scalars['String']['output']>;
};

export type FailedToCopyImageError = Error & {
  __typename?: 'FailedToCopyImageError';
  copyFromId: Scalars['Uuid']['output'];
  copyToId: Scalars['Uuid']['output'];
  message: Scalars['String']['output'];
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type FrozenSpecificationError = Error & {
  __typename?: 'FrozenSpecificationError';
  frozenSpecifications?: Maybe<FrozenSpecificationsCollectionSegment>;
  message: Scalars['String']['output'];
};


export type FrozenSpecificationErrorFrozenSpecificationsArgs = {
  order?: InputMaybe<Array<EstimateSpecificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateSpecificationFilterInput>;
};

/** A segment of a collection. */
export type FrozenSpecificationsCollectionSegment = {
  __typename?: 'FrozenSpecificationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EstimateSpecification>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type GenerateRecipeAddedItemModelInput = {
  deleted: Scalars['Boolean']['input'];
  iD_EstimateItem?: InputMaybe<Scalars['Uuid']['input']>;
  order: Scalars['Int']['input'];
};

export type GenerateRecipeAddedModelInput = {
  addedAsRecipe: Scalars['Boolean']['input'];
  iD_EstimateId?: InputMaybe<Scalars['Uuid']['input']>;
  iD_EstimateItemId?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Log: Scalars['Uuid']['input'];
  items?: InputMaybe<Array<InputMaybe<GenerateRecipeAddedItemModelInput>>>;
};

export type GenerateRecipeModelInput = {
  iD_Config?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Estimate?: InputMaybe<Scalars['Uuid']['input']>;
  quantity: Scalars['Decimal']['input'];
  uOM?: InputMaybe<Scalars['String']['input']>;
  userPrompt?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateRecipeOptionModel = {
  __typename?: 'GenerateRecipeOptionModel';
  connectionAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  connectionStatus?: Maybe<IntegrationConnectionStatus>;
  displayName?: Maybe<Scalars['String']['output']>;
  hasProviderConnection: Scalars['Boolean']['output'];
  iD_Config: Scalars['Uuid']['output'];
  iD_IntegrationConnection?: Maybe<Scalars['Uuid']['output']>;
  provider?: Maybe<GenerateRecipeProviderModel>;
};

export type GenerateRecipeOptionsModel = {
  __typename?: 'GenerateRecipeOptionsModel';
  examplePrompts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  options?: Maybe<Array<Maybe<GenerateRecipeOptionModel>>>;
};

export type GenerateRecipeProviderModel = {
  __typename?: 'GenerateRecipeProviderModel';
  description?: Maybe<Scalars['String']['output']>;
  externalRefIsPhoneNumber: Scalars['Boolean']['output'];
  iD_IntegrationProvider?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refFieldName?: Maybe<Scalars['String']['output']>;
  refFieldName2?: Maybe<Scalars['String']['output']>;
  refFieldRegEx?: Maybe<Scalars['String']['output']>;
  refFieldRegEx2?: Maybe<Scalars['String']['output']>;
  requireRef: Scalars['Boolean']['output'];
  requireRef2: Scalars['Boolean']['output'];
  requiresOtpVerification: Scalars['Boolean']['output'];
  smallLogoUrl?: Maybe<Scalars['String']['output']>;
};

export type GenerateSmartEstimateError = NotFoundError | PropertyError | SmartEstimateLimitError;

export type GenerateSmartEstimateInput = {
  model: SmartEstimateGenerateModelInput;
};

export type GenerateSmartEstimatePayload = {
  __typename?: 'GenerateSmartEstimatePayload';
  errors?: Maybe<Array<GenerateSmartEstimateError>>;
  smartEstimateGenerateReply?: Maybe<SmartEstimateGenerateReply>;
};

export type GenerateSmartParametersError = NotFoundError | PropertyError | SmartEstimateLimitError;

export type GenerateSmartParametersInput = {
  model: SmartEstimateParametersModelInput;
};

export type GenerateSmartParametersPayload = {
  __typename?: 'GenerateSmartParametersPayload';
  errors?: Maybe<Array<GenerateSmartParametersError>>;
  smartEstimateParametersReply?: Maybe<SmartEstimateParametersReply>;
};

export type GeneratedRecipeStartModel = {
  __typename?: 'GeneratedRecipeStartModel';
  iD_Log?: Maybe<Scalars['Uuid']['output']>;
  success: Scalars['Boolean']['output'];
  userErrorMessage?: Maybe<Scalars['String']['output']>;
};

export type GlobalSearchIndexSearchResult = {
  __typename?: 'GlobalSearchIndexSearchResult';
  areMoreResultsAvailable: Scalars['Boolean']['output'];
  currentMatches: Scalars['Int']['output'];
  matches?: Maybe<Array<Maybe<GlobalSearchItemModel>>>;
  objectType: GlobalSearchItemType;
  shortcut?: Maybe<Scalars['String']['output']>;
  totalMatches: Scalars['Int']['output'];
};

export type GlobalSearchItemModel = {
  __typename?: 'GlobalSearchItemModel';
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  objectType: Scalars['Int']['output'];
  objectTypeEnum: GlobalSearchItemType;
  searchLongDescription?: Maybe<Scalars['String']['output']>;
  searchShortDescription?: Maybe<Scalars['String']['output']>;
  searchTitle?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export const GlobalSearchItemType = {
  Estimates: 'ESTIMATES'
} as const;

export type GlobalSearchItemType = typeof GlobalSearchItemType[keyof typeof GlobalSearchItemType];
export type GrantCatalogueAccessControlModelInput = {
  externalRef?: InputMaybe<Scalars['String']['input']>;
  iD_Catalogue: Scalars['Uuid']['input'];
};

export type GuestUser = {
  __typename?: 'GuestUser';
  firstName?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
};

export type IdAndName = {
  __typename?: 'IdAndName';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type IdentifierDto = {
  __typename?: 'IdentifierDto';
  key: UriKey;
  value: Scalars['String']['output'];
};

export type IdentifierDtoInput = {
  key: UriKey;
  value: Scalars['String']['input'];
};

export type ImageCreateModelInput = {
  activityFeedImageId: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
};

export type ImageUpdateModelInput = {
  activityFeedImageId: Scalars['Uuid']['input'];
  status: ImageUpdateStatus;
  title: Scalars['String']['input'];
};

export const ImageUpdateStatus = {
  Add: 'ADD',
  Edit: 'EDIT',
  Remove: 'REMOVE'
} as const;

export type ImageUpdateStatus = typeof ImageUpdateStatus[keyof typeof ImageUpdateStatus];
export type ImportCatalogueItemModelInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  costItemType?: InputMaybe<Scalars['String']['input']>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  instructionsUrl?: InputMaybe<Scalars['String']['input']>;
  itemDeleted?: InputMaybe<Scalars['String']['input']>;
  itemDetailsUrl?: InputMaybe<Scalars['String']['input']>;
  manufCode?: InputMaybe<Scalars['String']['input']>;
  manufDescription?: InputMaybe<Scalars['String']['input']>;
  manufRRP?: InputMaybe<Scalars['String']['input']>;
  manufRange?: InputMaybe<Scalars['String']['input']>;
  subcategory?: InputMaybe<Scalars['String']['input']>;
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  supplierMetadata?: InputMaybe<Scalars['String']['input']>;
  uOM?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1Conversion?: InputMaybe<Scalars['String']['input']>;
  uOMAlt2?: InputMaybe<Scalars['String']['input']>;
  uOMAlt2Conversion?: InputMaybe<Scalars['String']['input']>;
  uOMMinOrderQty?: InputMaybe<Scalars['String']['input']>;
  uOMOrderIncrements?: InputMaybe<Scalars['String']['input']>;
  unitCost?: InputMaybe<Scalars['String']['input']>;
};

export const ImportType = {
  Accesscontrol: 'ACCESSCONTROL',
  Estimateitems: 'ESTIMATEITEMS'
} as const;

export type ImportType = typeof ImportType[keyof typeof ImportType];
export type ImportedInvoiceMapping = {
  __typename?: 'ImportedInvoiceMapping';
  bxOrderId?: Maybe<Scalars['Uuid']['output']>;
  importedInvoiceMappingId: Scalars['Uuid']['output'];
  invoiceId: Scalars['String']['output'];
  lineItemId?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  status: ImportedInvoiceMappingStatus;
  tenantId: Scalars['Uuid']['output'];
};

export const ImportedInvoiceMappingStatus = {
  Ignored: 'IGNORED',
  Mapped: 'MAPPED',
  Unknown: 'UNKNOWN'
} as const;

export type ImportedInvoiceMappingStatus = typeof ImportedInvoiceMappingStatus[keyof typeof ImportedInvoiceMappingStatus];
export type IncomingCatalogue = {
  __typename?: 'IncomingCatalogue';
  activeItemCount?: Maybe<Scalars['Decimal']['output']>;
  canExport: Scalars['Boolean']['output'];
  catalogueType: CatalogueType;
  colour?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  dateModified: Scalars['DateTime']['output'];
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  deletedByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['DateTime']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  fromClassification?: Maybe<CatalogueClassification>;
  fromSupplier: Scalars['Boolean']['output'];
  fromTenantName?: Maybe<Scalars['String']['output']>;
  iD_Catalogue: Scalars['Uuid']['output'];
  iD_CatalogueAccessControl?: Maybe<Scalars['Uuid']['output']>;
  iD_Contact?: Maybe<Scalars['Uuid']['output']>;
  iD_Related?: Maybe<Scalars['Uuid']['output']>;
  iD_Supplier?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  importProgress?: Maybe<Scalars['Decimal']['output']>;
  isActive: Scalars['Boolean']['output'];
  isCategorized: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPreApproved: Scalars['Boolean']['output'];
  lastImport?: Maybe<CatalogueImport>;
  logoThumbnailUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  rowVersion: Scalars['String']['output'];
  scope: CatalogueScope;
  scopeHiddenBy?: Maybe<Scalars['Uuid']['output']>;
  scopeHiddenByUsername?: Maybe<Scalars['String']['output']>;
  scopeHiddenDate?: Maybe<Scalars['DateTime']['output']>;
  scopeManagedBy?: Maybe<Scalars['Uuid']['output']>;
  scopeManagedByUsername?: Maybe<Scalars['String']['output']>;
  scopeManagedDate?: Maybe<Scalars['DateTime']['output']>;
  scopePublicBy?: Maybe<Scalars['Uuid']['output']>;
  scopePublicByUsername?: Maybe<Scalars['String']['output']>;
  scopePublicDate?: Maybe<Scalars['DateTime']['output']>;
  shortCode?: Maybe<Scalars['String']['output']>;
  status: CatalogueStatus;
  statusArchivedBy?: Maybe<Scalars['Uuid']['output']>;
  statusArchivedByUsername?: Maybe<Scalars['String']['output']>;
  statusArchivedDate?: Maybe<Scalars['DateTime']['output']>;
  statusDraftBy?: Maybe<Scalars['Uuid']['output']>;
  statusDraftByUsername?: Maybe<Scalars['String']['output']>;
  statusDraftDate?: Maybe<Scalars['DateTime']['output']>;
  statusExpiredBy?: Maybe<Scalars['Uuid']['output']>;
  statusExpiredByUsername?: Maybe<Scalars['String']['output']>;
  statusExpiredDate?: Maybe<Scalars['DateTime']['output']>;
  statusPublishedBy?: Maybe<Scalars['Uuid']['output']>;
  statusPublishedByUsername?: Maybe<Scalars['String']['output']>;
  statusPublishedDate?: Maybe<Scalars['DateTime']['output']>;
  supplierDescription?: Maybe<Scalars['String']['output']>;
  syncEnabled?: Maybe<Scalars['Boolean']['output']>;
  syncMapping?: Maybe<Scalars['String']['output']>;
  syncTimeOfDayHour?: Maybe<Scalars['Short']['output']>;
  syncTimeOfDayMinute?: Maybe<Scalars['Short']['output']>;
  syncUrl?: Maybe<Scalars['String']['output']>;
  syncUseMapping?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type IncomingCatalogueConnection = {
  __typename?: 'IncomingCatalogueConnection';
  /** A list of edges. */
  edges?: Maybe<Array<IncomingCatalogueEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<IncomingCatalogue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IncomingCatalogueEdge = {
  __typename?: 'IncomingCatalogueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IncomingCatalogue>;
};

export type IncomingCatalogueFilterInput = {
  and?: InputMaybe<Array<IncomingCatalogueFilterInput>>;
  canExport?: InputMaybe<BooleanOperationFilterInput>;
  catalogueType?: InputMaybe<CatalogueTypeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  effectiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  expiryDate?: InputMaybe<DateTimeOperationFilterInput>;
  fromClassification?: InputMaybe<NullableOfCatalogueClassificationOperationFilterInput>;
  fromSupplier?: InputMaybe<BooleanOperationFilterInput>;
  fromTenantName?: InputMaybe<StringOperationFilterInput>;
  iD_Catalogue?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueAccessControl?: InputMaybe<UuidOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_Related?: InputMaybe<UuidOperationFilterInput>;
  iD_Supplier?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isPreApproved?: InputMaybe<BooleanOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IncomingCatalogueFilterInput>>;
  scope?: InputMaybe<CatalogueScopeOperationFilterInput>;
  scopeHiddenBy?: InputMaybe<UuidOperationFilterInput>;
  scopeHiddenDate?: InputMaybe<DateTimeOperationFilterInput>;
  scopeManagedBy?: InputMaybe<UuidOperationFilterInput>;
  scopeManagedDate?: InputMaybe<DateTimeOperationFilterInput>;
  scopePublicBy?: InputMaybe<UuidOperationFilterInput>;
  scopePublicDate?: InputMaybe<DateTimeOperationFilterInput>;
  shortCode?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<CatalogueStatusOperationFilterInput>;
  statusArchivedBy?: InputMaybe<UuidOperationFilterInput>;
  statusArchivedDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusDraftBy?: InputMaybe<UuidOperationFilterInput>;
  statusDraftDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusExpiredBy?: InputMaybe<UuidOperationFilterInput>;
  statusExpiredDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusPublishedBy?: InputMaybe<UuidOperationFilterInput>;
  statusPublishedDate?: InputMaybe<DateTimeOperationFilterInput>;
  syncEnabled?: InputMaybe<BooleanOperationFilterInput>;
  syncMapping?: InputMaybe<StringOperationFilterInput>;
  syncTimeOfDayHour?: InputMaybe<ShortOperationFilterInput>;
  syncTimeOfDayMinute?: InputMaybe<ShortOperationFilterInput>;
  syncUrl?: InputMaybe<StringOperationFilterInput>;
  syncUseMapping?: InputMaybe<BooleanOperationFilterInput>;
};

export type IncomingCatalogueSortInput = {
  canExport?: InputMaybe<SortEnumType>;
  catalogueType?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  expiryDate?: InputMaybe<SortEnumType>;
  fromClassification?: InputMaybe<SortEnumType>;
  fromSupplier?: InputMaybe<SortEnumType>;
  fromTenantName?: InputMaybe<SortEnumType>;
  iD_Catalogue?: InputMaybe<SortEnumType>;
  iD_CatalogueAccessControl?: InputMaybe<SortEnumType>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_Related?: InputMaybe<SortEnumType>;
  iD_Supplier?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isPreApproved?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  scope?: InputMaybe<SortEnumType>;
  scopeHiddenBy?: InputMaybe<SortEnumType>;
  scopeHiddenDate?: InputMaybe<SortEnumType>;
  scopeManagedBy?: InputMaybe<SortEnumType>;
  scopeManagedDate?: InputMaybe<SortEnumType>;
  scopePublicBy?: InputMaybe<SortEnumType>;
  scopePublicDate?: InputMaybe<SortEnumType>;
  shortCode?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusArchivedBy?: InputMaybe<SortEnumType>;
  statusArchivedDate?: InputMaybe<SortEnumType>;
  statusDraftBy?: InputMaybe<SortEnumType>;
  statusDraftDate?: InputMaybe<SortEnumType>;
  statusExpiredBy?: InputMaybe<SortEnumType>;
  statusExpiredDate?: InputMaybe<SortEnumType>;
  statusPublishedBy?: InputMaybe<SortEnumType>;
  statusPublishedDate?: InputMaybe<SortEnumType>;
  syncEnabled?: InputMaybe<SortEnumType>;
  syncMapping?: InputMaybe<SortEnumType>;
  syncTimeOfDayHour?: InputMaybe<SortEnumType>;
  syncTimeOfDayMinute?: InputMaybe<SortEnumType>;
  syncUrl?: InputMaybe<SortEnumType>;
  syncUseMapping?: InputMaybe<SortEnumType>;
};

export type IncomingEstimateTemplateDto = {
  __typename?: 'IncomingEstimateTemplateDto';
  availabilityScope: EstimateAvailabilityScope;
  availabilityStatus: EstimateAvailabilityStatus;
  calculationMethod: Scalars['Int']['output'];
  canCopy: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['DateTime']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  hasAccess: Scalars['Boolean']['output'];
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateAccessControl?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  statusPublishedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type IncomingEstimateTemplateDtoFilterInput = {
  and?: InputMaybe<Array<IncomingEstimateTemplateDtoFilterInput>>;
  availabilityScope?: InputMaybe<EstimateAvailabilityScopeOperationFilterInput>;
  availabilityStatus?: InputMaybe<EstimateAvailabilityStatusOperationFilterInput>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  canCopy?: InputMaybe<BooleanOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  effectiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  expiryDate?: InputMaybe<DateTimeOperationFilterInput>;
  hasAccess?: InputMaybe<BooleanOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateAccessControl?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IncomingEstimateTemplateDtoFilterInput>>;
  readOnly?: InputMaybe<BooleanOperationFilterInput>;
  statusPublishedDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type IncomingEstimateTemplateDtoSortInput = {
  availabilityScope?: InputMaybe<SortEnumType>;
  availabilityStatus?: InputMaybe<SortEnumType>;
  calculationMethod?: InputMaybe<SortEnumType>;
  canCopy?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  expiryDate?: InputMaybe<SortEnumType>;
  hasAccess?: InputMaybe<SortEnumType>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_EstimateAccessControl?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  readOnly?: InputMaybe<SortEnumType>;
  statusPublishedDate?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type IncomingIntegrationConnectionEstimateTemplatesCollectionSegment = {
  __typename?: 'IncomingIntegrationConnectionEstimateTemplatesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<IncomingEstimateTemplateDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IndividualInviteStatusModel = {
  __typename?: 'IndividualInviteStatusModel';
  customerContactName?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  externalInvitationId: Scalars['Uuid']['output'];
  iD_CustomerContact: Scalars['Uuid']['output'];
  inviteSentDate?: Maybe<Scalars['DateTime']['output']>;
  inviteStatus?: Maybe<Scalars['String']['output']>;
};

export type InsufficientActivityFeedInfoError = Error & {
  __typename?: 'InsufficientActivityFeedInfoError';
  message: Scalars['String']['output'];
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationConnection = {
  __typename?: 'IntegrationConnection';
  aBN?: Maybe<Scalars['String']['output']>;
  aBNPrefix?: Maybe<Scalars['String']['output']>;
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  acceptedBy?: Maybe<Scalars['Uuid']['output']>;
  acceptedByUsername?: Maybe<Scalars['String']['output']>;
  accountManager?: Maybe<AccountManager>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  addressCityTown?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPostCode?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  allowSharingEstimateNotification: Scalars['Boolean']['output'];
  businessName?: Maybe<Scalars['String']['output']>;
  catalogueAutoSubscribe: Scalars['Boolean']['output'];
  clientAck: Scalars['Boolean']['output'];
  clientUrl?: Maybe<Scalars['String']['output']>;
  clientUsername?: Maybe<Scalars['String']['output']>;
  connectionType: IntegrationConnectionType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  externalRef?: Maybe<Scalars['String']['output']>;
  externalRef2?: Maybe<Scalars['String']['output']>;
  iD_AccountManager?: Maybe<Scalars['Uuid']['output']>;
  iD_Catalogue?: Maybe<Scalars['Uuid']['output']>;
  iD_ClientTenant?: Maybe<Scalars['Uuid']['output']>;
  iD_IntegrationConnection: Scalars['Uuid']['output'];
  iD_IntegrationProvider?: Maybe<Scalars['Uuid']['output']>;
  iD_IntegrationProviderCard?: Maybe<Scalars['Uuid']['output']>;
  iD_TargetTenant?: Maybe<Scalars['Uuid']['output']>;
  inactiveAt?: Maybe<Scalars['DateTime']['output']>;
  integrationProvider?: Maybe<IntegrationProvider>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  permissionToShareData: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  primaryContactEmailAddress?: Maybe<Scalars['String']['output']>;
  primaryContactName?: Maybe<Scalars['String']['output']>;
  quoteRequestEmail?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  rejectedBy?: Maybe<Scalars['Uuid']['output']>;
  rejectedByUsername?: Maybe<Scalars['String']['output']>;
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  revokedBy?: Maybe<Scalars['Uuid']['output']>;
  revokedByUsername?: Maybe<Scalars['String']['output']>;
  rowVersion: Scalars['String']['output'];
  status: IntegrationConnectionStatus;
  targetBusinessName?: Maybe<Scalars['String']['output']>;
  targetContactEmailAddress?: Maybe<Scalars['String']['output']>;
  targetContactName?: Maybe<Scalars['String']['output']>;
  targetPhoneNumber?: Maybe<Scalars['String']['output']>;
  verifiedPhoneNumber?: Maybe<Scalars['String']['output']>;
};

export type IntegrationConnectionClient = {
  __typename?: 'IntegrationConnectionClient';
  aBN?: Maybe<Scalars['String']['output']>;
  accountManager?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  connectionType: IntegrationConnectionType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  externalRef?: Maybe<Scalars['String']['output']>;
  externalRef2?: Maybe<Scalars['String']['output']>;
  iD_Customer?: Maybe<Scalars['Uuid']['output']>;
  iD_IntegrationConnection: Scalars['Uuid']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  status: IntegrationConnectionStatus;
  verifiedPhoneNumber?: Maybe<Scalars['String']['output']>;
};

export type IntegrationConnectionClientResponseModel = {
  __typename?: 'IntegrationConnectionClientResponseModel';
  clients?: Maybe<Array<Maybe<IntegrationConnectionClient>>>;
  totalCount: Scalars['Int']['output'];
};

/** A connection to a list of items. */
export type IntegrationConnectionConnection = {
  __typename?: 'IntegrationConnectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<IntegrationConnectionEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<IntegrationConnection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IntegrationConnectionEdge = {
  __typename?: 'IntegrationConnectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IntegrationConnection>;
};

export type IntegrationConnectionFilterInput = {
  aBN?: InputMaybe<StringOperationFilterInput>;
  aBNPrefix?: InputMaybe<StringOperationFilterInput>;
  acceptedAt?: InputMaybe<DateTimeOperationFilterInput>;
  acceptedBy?: InputMaybe<UuidOperationFilterInput>;
  accountManager?: InputMaybe<UserViewFilterInput>;
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  addressCityTown?: InputMaybe<StringOperationFilterInput>;
  addressCountry?: InputMaybe<StringOperationFilterInput>;
  addressPostCode?: InputMaybe<StringOperationFilterInput>;
  addressState?: InputMaybe<StringOperationFilterInput>;
  allowSharingEstimateNotification?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<IntegrationConnectionFilterInput>>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  catalogueAutoSubscribe?: InputMaybe<BooleanOperationFilterInput>;
  clientAck?: InputMaybe<BooleanOperationFilterInput>;
  clientPassword?: InputMaybe<StringOperationFilterInput>;
  clientSecret?: InputMaybe<StringOperationFilterInput>;
  clientUrl?: InputMaybe<StringOperationFilterInput>;
  clientUsername?: InputMaybe<StringOperationFilterInput>;
  connectionType?: InputMaybe<IntegrationConnectionTypeOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  externalRef?: InputMaybe<StringOperationFilterInput>;
  externalRef2?: InputMaybe<StringOperationFilterInput>;
  iD_AccountManager?: InputMaybe<UuidOperationFilterInput>;
  iD_Catalogue?: InputMaybe<UuidOperationFilterInput>;
  iD_ClientTenant?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationConnection?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationProvider?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationProviderCard?: InputMaybe<UuidOperationFilterInput>;
  iD_TargetTenant?: InputMaybe<UuidOperationFilterInput>;
  inactiveAt?: InputMaybe<DateTimeOperationFilterInput>;
  integrationProvider?: InputMaybe<IntegrationProviderFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationConnectionFilterInput>>;
  permissionToShareData?: InputMaybe<BooleanOperationFilterInput>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  primaryContactEmailAddress?: InputMaybe<StringOperationFilterInput>;
  primaryContactName?: InputMaybe<StringOperationFilterInput>;
  quoteRequestEmail?: InputMaybe<StringOperationFilterInput>;
  rejectedAt?: InputMaybe<DateTimeOperationFilterInput>;
  rejectedBy?: InputMaybe<UuidOperationFilterInput>;
  revokedAt?: InputMaybe<DateTimeOperationFilterInput>;
  revokedBy?: InputMaybe<UuidOperationFilterInput>;
  status?: InputMaybe<IntegrationConnectionStatusOperationFilterInput>;
  targetBusinessName?: InputMaybe<StringOperationFilterInput>;
  targetContactEmailAddress?: InputMaybe<StringOperationFilterInput>;
  targetContactName?: InputMaybe<StringOperationFilterInput>;
  targetPhoneNumber?: InputMaybe<StringOperationFilterInput>;
  verifiedPhoneNumber?: InputMaybe<StringOperationFilterInput>;
};

export type IntegrationConnectionModel = {
  __typename?: 'IntegrationConnectionModel';
  catalogue?: Maybe<SimpleCatalogueModel>;
  clientUrl?: Maybe<Scalars['String']['output']>;
  clientUsername?: Maybe<Scalars['String']['output']>;
  connectionType: IntegrationConnectionType;
  iD_Catalogue?: Maybe<Scalars['Uuid']['output']>;
  iD_ClientTenant?: Maybe<Scalars['Uuid']['output']>;
  iD_IntegrationConnection: Scalars['Uuid']['output'];
  iD_IntegrationProvider?: Maybe<Scalars['Uuid']['output']>;
  integrationProvider?: Maybe<IntegrationProviderResponseModel>;
  isSyncInProgress?: Maybe<Scalars['Boolean']['output']>;
  lastSync?: Maybe<ErpHistoryRun>;
  status: IntegrationConnectionStatus;
};

export type IntegrationConnectionQuoteRequestDetailsModel = {
  __typename?: 'IntegrationConnectionQuoteRequestDetailsModel';
  iD_IntegrationConnection: Scalars['Uuid']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quoteRequestEmail?: Maybe<Scalars['String']['output']>;
  smallLogoUrl?: Maybe<Scalars['String']['output']>;
};

export type IntegrationConnectionSortInput = {
  aBN?: InputMaybe<SortEnumType>;
  aBNPrefix?: InputMaybe<SortEnumType>;
  acceptedAt?: InputMaybe<SortEnumType>;
  acceptedBy?: InputMaybe<SortEnumType>;
  accountManager?: InputMaybe<UserViewSortInput>;
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  addressCityTown?: InputMaybe<SortEnumType>;
  addressCountry?: InputMaybe<SortEnumType>;
  addressPostCode?: InputMaybe<SortEnumType>;
  addressState?: InputMaybe<SortEnumType>;
  allowSharingEstimateNotification?: InputMaybe<SortEnumType>;
  businessName?: InputMaybe<SortEnumType>;
  catalogueAutoSubscribe?: InputMaybe<SortEnumType>;
  clientAck?: InputMaybe<SortEnumType>;
  clientPassword?: InputMaybe<SortEnumType>;
  clientSecret?: InputMaybe<SortEnumType>;
  clientUrl?: InputMaybe<SortEnumType>;
  clientUsername?: InputMaybe<SortEnumType>;
  connectionType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  emailAddress?: InputMaybe<SortEnumType>;
  externalRef?: InputMaybe<SortEnumType>;
  externalRef2?: InputMaybe<SortEnumType>;
  iD_AccountManager?: InputMaybe<SortEnumType>;
  iD_Catalogue?: InputMaybe<SortEnumType>;
  iD_ClientTenant?: InputMaybe<SortEnumType>;
  iD_IntegrationConnection?: InputMaybe<SortEnumType>;
  iD_IntegrationProvider?: InputMaybe<SortEnumType>;
  iD_IntegrationProviderCard?: InputMaybe<SortEnumType>;
  iD_TargetTenant?: InputMaybe<SortEnumType>;
  inactiveAt?: InputMaybe<SortEnumType>;
  integrationProvider?: InputMaybe<IntegrationProviderSortInput>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  permissionToShareData?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  primaryContactEmailAddress?: InputMaybe<SortEnumType>;
  primaryContactName?: InputMaybe<SortEnumType>;
  quoteRequestEmail?: InputMaybe<SortEnumType>;
  rejectedAt?: InputMaybe<SortEnumType>;
  rejectedBy?: InputMaybe<SortEnumType>;
  revokedAt?: InputMaybe<SortEnumType>;
  revokedBy?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  targetBusinessName?: InputMaybe<SortEnumType>;
  targetContactEmailAddress?: InputMaybe<SortEnumType>;
  targetContactName?: InputMaybe<SortEnumType>;
  targetPhoneNumber?: InputMaybe<SortEnumType>;
  verifiedPhoneNumber?: InputMaybe<SortEnumType>;
};

export const IntegrationConnectionStatus = {
  Connected: 'CONNECTED',
  Inactive: 'INACTIVE',
  Notconnected: 'NOTCONNECTED',
  Pending: 'PENDING',
  Requested: 'REQUESTED',
  Revoked: 'REVOKED'
} as const;

export type IntegrationConnectionStatus = typeof IntegrationConnectionStatus[keyof typeof IntegrationConnectionStatus];
export type IntegrationConnectionStatusCount = {
  __typename?: 'IntegrationConnectionStatusCount';
  count: Scalars['Int']['output'];
  status: IntegrationConnectionStatus;
};

export type IntegrationConnectionStatusOperationFilterInput = {
  eq?: InputMaybe<IntegrationConnectionStatus>;
  in?: InputMaybe<Array<IntegrationConnectionStatus>>;
  neq?: InputMaybe<IntegrationConnectionStatus>;
  nin?: InputMaybe<Array<IntegrationConnectionStatus>>;
};

export type IntegrationConnectionSuppliedDetails = {
  __typename?: 'IntegrationConnectionSuppliedDetails';
  aBN?: Maybe<Scalars['String']['output']>;
  aBNPrefix?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  addressCityTown?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPostCode?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  primaryContactEmailAddress?: Maybe<Scalars['String']['output']>;
  primaryContactName?: Maybe<Scalars['String']['output']>;
};

export const IntegrationConnectionType = {
  Clientinvitessupplier: 'CLIENTINVITESSUPPLIER',
  Clienttoprovider: 'CLIENTTOPROVIDER',
  Epicor: 'EPICOR',
  Manufacturerinvitessupplier: 'MANUFACTURERINVITESSUPPLIER',
  Supplierinvitesclient: 'SUPPLIERINVITESCLIENT',
  Supplierinvitesmanufacturer: 'SUPPLIERINVITESMANUFACTURER'
} as const;

export type IntegrationConnectionType = typeof IntegrationConnectionType[keyof typeof IntegrationConnectionType];
export type IntegrationConnectionTypeOperationFilterInput = {
  eq?: InputMaybe<IntegrationConnectionType>;
  in?: InputMaybe<Array<IntegrationConnectionType>>;
  neq?: InputMaybe<IntegrationConnectionType>;
  nin?: InputMaybe<Array<IntegrationConnectionType>>;
};

export type IntegrationProvider = {
  __typename?: 'IntegrationProvider';
  autoCcEnabled: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  currentCardConnection?: Maybe<IntegrationConnection>;
  currentConnection?: Maybe<IntegrationConnection>;
  description?: Maybe<Scalars['String']['output']>;
  displayFeatured?: Maybe<Scalars['Boolean']['output']>;
  externalRefIsPhoneNumber: Scalars['Boolean']['output'];
  featuredBackgroundColour?: Maybe<Scalars['String']['output']>;
  featuredTextColour?: Maybe<Scalars['String']['output']>;
  fieldName1?: Maybe<Scalars['String']['output']>;
  fieldName2?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  iD_IntegrationProvider: Scalars['Uuid']['output'];
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  isActive: Scalars['Boolean']['output'];
  isDisplayed: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isInternal: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refFieldName?: Maybe<Scalars['String']['output']>;
  refFieldName2?: Maybe<Scalars['String']['output']>;
  refFieldRegEx?: Maybe<Scalars['String']['output']>;
  refFieldRegEx2?: Maybe<Scalars['String']['output']>;
  regionalRestrictions?: Maybe<Array<Maybe<IntegrationProviderRegionalRestriction>>>;
  requireRef: Scalars['Boolean']['output'];
  requireRef2: Scalars['Boolean']['output'];
  requiresOtpVerification: Scalars['Boolean']['output'];
  rowVersion: Scalars['String']['output'];
  smallLogoUrl?: Maybe<Scalars['String']['output']>;
  specificAutoCcEnabled: Scalars['Boolean']['output'];
  specificAutoCcs?: Maybe<Array<Maybe<IntegrationProviderSpecificAutoCc>>>;
  supplierIdentifier?: Maybe<Scalars['String']['output']>;
  supplierOrderingBrandingLabel?: Maybe<Scalars['String']['output']>;
  supportsLivePricing: Scalars['Boolean']['output'];
  supportsOrderCreation: Scalars['Boolean']['output'];
  tenantName?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type IntegrationProviderConnection = {
  __typename?: 'IntegrationProviderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<IntegrationProviderEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<IntegrationProvider>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IntegrationProviderEdge = {
  __typename?: 'IntegrationProviderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IntegrationProvider>;
};

export const IntegrationProviderEnum = {
  Deputy: 'DEPUTY',
  Email: 'EMAIL',
  Myob: 'MYOB',
  Quickbooks: 'QUICKBOOKS',
  Xero: 'XERO'
} as const;

export type IntegrationProviderEnum = typeof IntegrationProviderEnum[keyof typeof IntegrationProviderEnum];
export type IntegrationProviderFilterInput = {
  and?: InputMaybe<Array<IntegrationProviderFilterInput>>;
  autoCcEnabled?: InputMaybe<BooleanOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  externalRefIsPhoneNumber?: InputMaybe<BooleanOperationFilterInput>;
  featuredBackgroundColour?: InputMaybe<StringOperationFilterInput>;
  featuredTextColour?: InputMaybe<StringOperationFilterInput>;
  fieldName1?: InputMaybe<StringOperationFilterInput>;
  fieldName2?: InputMaybe<StringOperationFilterInput>;
  group?: InputMaybe<StringOperationFilterInput>;
  iD_IntegrationProvider?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDisplayed?: InputMaybe<BooleanOperationFilterInput>;
  isFeatured?: InputMaybe<BooleanOperationFilterInput>;
  isInternal?: InputMaybe<BooleanOperationFilterInput>;
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationProviderFilterInput>>;
  refFieldName?: InputMaybe<StringOperationFilterInput>;
  refFieldName2?: InputMaybe<StringOperationFilterInput>;
  refFieldRegEx?: InputMaybe<StringOperationFilterInput>;
  refFieldRegEx2?: InputMaybe<StringOperationFilterInput>;
  regionalRestrictions?: InputMaybe<ListFilterInputTypeOfIntegrationProviderRegionalRestrictionFilterInput>;
  requireRef?: InputMaybe<BooleanOperationFilterInput>;
  requireRef2?: InputMaybe<BooleanOperationFilterInput>;
  requiresOtpVerification?: InputMaybe<BooleanOperationFilterInput>;
  smallLogoUrl?: InputMaybe<StringOperationFilterInput>;
  specificAutoCcEnabled?: InputMaybe<BooleanOperationFilterInput>;
  supplierIdentifier?: InputMaybe<StringOperationFilterInput>;
  supplierOrderingBrandingLabel?: InputMaybe<StringOperationFilterInput>;
  supportsLivePricing?: InputMaybe<BooleanOperationFilterInput>;
  supportsOrderCreation?: InputMaybe<BooleanOperationFilterInput>;
};

export type IntegrationProviderRegionalRestriction = {
  __typename?: 'IntegrationProviderRegionalRestriction';
  country: Scalars['String']['output'];
  iD_IntegrationProvider: Scalars['Uuid']['output'];
  iD_IntegrationProviderRegionalRestriction: Scalars['Uuid']['output'];
  integrationProvider?: Maybe<IntegrationProvider>;
  state?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type IntegrationProviderRegionalRestrictionFilterInput = {
  and?: InputMaybe<Array<IntegrationProviderRegionalRestrictionFilterInput>>;
  country?: InputMaybe<StringOperationFilterInput>;
  iD_IntegrationProvider?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationProviderRegionalRestriction?: InputMaybe<UuidOperationFilterInput>;
  integrationProvider?: InputMaybe<IntegrationProviderFilterInput>;
  or?: InputMaybe<Array<IntegrationProviderRegionalRestrictionFilterInput>>;
  state?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
};

export type IntegrationProviderResponseModel = {
  __typename?: 'IntegrationProviderResponseModel';
  fieldName1?: Maybe<Scalars['String']['output']>;
  fieldName2?: Maybe<Scalars['String']['output']>;
  group: Scalars['String']['output'];
  iD_IntegrationProvider: Scalars['Uuid']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type IntegrationProviderSortInput = {
  autoCcEnabled?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  externalRefIsPhoneNumber?: InputMaybe<SortEnumType>;
  featuredBackgroundColour?: InputMaybe<SortEnumType>;
  featuredTextColour?: InputMaybe<SortEnumType>;
  fieldName1?: InputMaybe<SortEnumType>;
  fieldName2?: InputMaybe<SortEnumType>;
  group?: InputMaybe<SortEnumType>;
  iD_IntegrationProvider?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isDisplayed?: InputMaybe<SortEnumType>;
  isFeatured?: InputMaybe<SortEnumType>;
  isInternal?: InputMaybe<SortEnumType>;
  logoUrl?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  refFieldName?: InputMaybe<SortEnumType>;
  refFieldName2?: InputMaybe<SortEnumType>;
  refFieldRegEx?: InputMaybe<SortEnumType>;
  refFieldRegEx2?: InputMaybe<SortEnumType>;
  requireRef?: InputMaybe<SortEnumType>;
  requireRef2?: InputMaybe<SortEnumType>;
  requiresOtpVerification?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  smallLogoUrl?: InputMaybe<SortEnumType>;
  specificAutoCcEnabled?: InputMaybe<SortEnumType>;
  supplierIdentifier?: InputMaybe<SortEnumType>;
  supplierOrderingBrandingLabel?: InputMaybe<SortEnumType>;
  supportsLivePricing?: InputMaybe<SortEnumType>;
  supportsOrderCreation?: InputMaybe<SortEnumType>;
};

export type IntegrationProviderSpecificAutoCc = {
  __typename?: 'IntegrationProviderSpecificAutoCc';
  email?: Maybe<Scalars['String']['output']>;
  integrationProvider?: Maybe<IntegrationProvider>;
  integrationProviderId: Scalars['Uuid']['output'];
  integrationProviderSpecificAutoCcId: Scalars['Uuid']['output'];
  tenantId: Scalars['Uuid']['output'];
};

export type InvalidDateRange = Error & {
  __typename?: 'InvalidDateRange';
  end?: Maybe<Scalars['DateTime']['output']>;
  message: Scalars['String']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type InvalidMovement = Error & {
  __typename?: 'InvalidMovement';
  message: Scalars['String']['output'];
};

export type InvalidNesting = Error & {
  __typename?: 'InvalidNesting';
  message: Scalars['String']['output'];
};

export type InvalidTimeZoneId = Error & {
  __typename?: 'InvalidTimeZoneId';
  message: Scalars['String']['output'];
};

export type InviteClientModelInput = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  clientAccountNumber?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  iD_Client?: InputMaybe<Scalars['Uuid']['input']>;
  iD_IntegrationConnection?: InputMaybe<Scalars['Uuid']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type InviteSupplierModelInput = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  clientAccountNumber?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  iD_IntegrationConnection?: InputMaybe<Scalars['Uuid']['input']>;
  permissionToShareData: Scalars['Boolean']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** A segment of a collection. */
export type InvoiceAttachmentsCollectionSegment = {
  __typename?: 'InvoiceAttachmentsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<AccountingAttachment>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ItemPageResponseOfEmailListItem = {
  __typename?: 'ItemPageResponseOfEmailListItem';
  continuationToken?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<EmailListItem>>>;
};

export type Job = {
  __typename?: 'Job';
  buildingType?: Maybe<Scalars['String']['output']>;
  clientEmail?: Maybe<Scalars['String']['output']>;
  clientMobile?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crew: Array<JobCrewDto>;
  description?: Maybe<Scalars['String']['output']>;
  hasPendingInvitations: Scalars['Boolean']['output'];
  iD_Estimate?: Maybe<Scalars['Uuid']['output']>;
  iD_Job: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  progressPercent: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  taxRates?: Maybe<Scalars['String']['output']>;
  worksLocationAddress?: Maybe<Scalars['String']['output']>;
  worksLocationLat?: Maybe<Scalars['Decimal']['output']>;
  worksLocationLng?: Maybe<Scalars['Decimal']['output']>;
  worksLocationPostcode?: Maybe<Scalars['String']['output']>;
  worksLocationState?: Maybe<Scalars['String']['output']>;
  worksLocationSuburb?: Maybe<Scalars['String']['output']>;
};

export type JobCopyOptionsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  buildingType?: InputMaybe<Scalars['String']['input']>;
  calculationMethod: TaxCalculationMethod;
  contractMarkup: Scalars['Decimal']['input'];
  contractPrice: Scalars['Decimal']['input'];
  contractTax: Scalars['Decimal']['input'];
  copyItems: Scalars['Boolean']['input'];
  copyOrders: Scalars['Boolean']['input'];
  copyTasks: Scalars['Boolean']['input'];
  costPlusPercentage: Scalars['Float']['input'];
  createDefaultInvoices: Scalars['Boolean']['input'];
  createDefaultTasks: Scalars['Boolean']['input'];
  createDepositInvoice: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  estimateId?: InputMaybe<Scalars['Uuid']['input']>;
  invoiceMethod: JobInvoiceMethod;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  retentionPaymentPercentage: Scalars['Float']['input'];
  retentionPercentage: Scalars['Float']['input'];
  retentionType: JobRetentionType;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type JobCreateOptionsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  buildingType?: InputMaybe<Scalars['String']['input']>;
  calculationMethod: TaxCalculationMethod;
  contractMarkup: Scalars['Decimal']['input'];
  contractPrice: Scalars['Decimal']['input'];
  contractTax: Scalars['Decimal']['input'];
  costPlusPercentage: Scalars['Float']['input'];
  createDefaultInvoices: Scalars['Boolean']['input'];
  createDefaultTasks: Scalars['Boolean']['input'];
  createDepositInvoice: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  estimateId?: InputMaybe<Scalars['Uuid']['input']>;
  invoiceMethod: JobInvoiceMethod;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  retentionPaymentPercentage: Scalars['Float']['input'];
  retentionPercentage: Scalars['Float']['input'];
  retentionType: JobRetentionType;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type JobCrewDto = {
  __typename?: 'JobCrewDto';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  goodworkUserId?: Maybe<Scalars['Uuid']['output']>;
  jobCrewId: Scalars['Uuid']['output'];
  jobId: Scalars['Uuid']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Uuid']['output']>;
  userSurname?: Maybe<Scalars['String']['output']>;
};

export type JobCrewDtoFilterInput = {
  and?: InputMaybe<Array<JobCrewDtoFilterInput>>;
  avatarUrl?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  goodworkUserId?: InputMaybe<UuidOperationFilterInput>;
  jobCrewId?: InputMaybe<UuidOperationFilterInput>;
  jobId?: InputMaybe<UuidOperationFilterInput>;
  lastname?: InputMaybe<StringOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobCrewDtoFilterInput>>;
  userFirstName?: InputMaybe<StringOperationFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
  userSurname?: InputMaybe<StringOperationFilterInput>;
};

export type JobCrewDtoSortInput = {
  avatarUrl?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  goodworkUserId?: InputMaybe<SortEnumType>;
  jobCrewId?: InputMaybe<SortEnumType>;
  jobId?: InputMaybe<SortEnumType>;
  lastname?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  userFirstName?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
  userSurname?: InputMaybe<SortEnumType>;
};

export type JobCrewFilterInput = {
  and?: InputMaybe<Array<JobCrewFilterInput>>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  iD_GoodworkUser?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobCrew?: InputMaybe<UuidOperationFilterInput>;
  iD_User?: InputMaybe<UuidOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  lastname?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobCrewFilterInput>>;
};

export const JobDeletionSource = {
  Estimate: 'ESTIMATE',
  Job: 'JOB',
  Lead: 'LEAD',
  Unknown: 'UNKNOWN'
} as const;

export type JobDeletionSource = typeof JobDeletionSource[keyof typeof JobDeletionSource];
export type JobFilterInput = {
  actualTax?: InputMaybe<DecimalOperationFilterInput>;
  actualTotal?: InputMaybe<DecimalOperationFilterInput>;
  actualTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<JobFilterInput>>;
  balance?: InputMaybe<DecimalOperationFilterInput>;
  buildingType?: InputMaybe<StringOperationFilterInput>;
  calculationMethod?: InputMaybe<TaxCalculationMethodOperationFilterInput>;
  clientAddress1?: InputMaybe<StringOperationFilterInput>;
  clientAddress2?: InputMaybe<StringOperationFilterInput>;
  clientCityTown?: InputMaybe<StringOperationFilterInput>;
  clientEmail?: InputMaybe<StringOperationFilterInput>;
  clientFax?: InputMaybe<StringOperationFilterInput>;
  clientMobile?: InputMaybe<StringOperationFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  clientPhone?: InputMaybe<StringOperationFilterInput>;
  clientPostCode?: InputMaybe<StringOperationFilterInput>;
  clientState?: InputMaybe<StringOperationFilterInput>;
  colorCode?: InputMaybe<StringOperationFilterInput>;
  committedTax?: InputMaybe<DecimalOperationFilterInput>;
  committedTotal?: InputMaybe<DecimalOperationFilterInput>;
  committedTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  completionDate?: InputMaybe<DateTimeOperationFilterInput>;
  contractMarkup?: InputMaybe<DecimalOperationFilterInput>;
  contractTax?: InputMaybe<DecimalOperationFilterInput>;
  contractTotal?: InputMaybe<DecimalOperationFilterInput>;
  contractTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  costPlusPercentage?: InputMaybe<FloatOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerViewFilterInput>;
  customerPortalEnabled?: InputMaybe<BooleanOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  deletionSource?: InputMaybe<NullableOfJobDeletionSourceOperationFilterInput>;
  depositTax?: InputMaybe<DecimalOperationFilterInput>;
  depositTotal?: InputMaybe<DecimalOperationFilterInput>;
  deputyLocationID?: InputMaybe<LongOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  estimatedTax?: InputMaybe<DecimalOperationFilterInput>;
  estimatedTotal?: InputMaybe<DecimalOperationFilterInput>;
  estimatedTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  franchiseFee?: InputMaybe<DecimalOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_Lead?: InputMaybe<UuidOperationFilterInput>;
  iD_Quote?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  invoiceDueDays?: InputMaybe<IntOperationFilterInput>;
  invoiceMethod?: InputMaybe<JobInvoiceMethodOperationFilterInput>;
  isCompleted?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  jobCrew?: InputMaybe<ListFilterInputTypeOfJobCrewFilterInput>;
  jobInvitations?: InputMaybe<ListFilterInputTypeOfJobInvitationFilterInput>;
  jobItems?: InputMaybe<ListFilterInputTypeOfJobItemFilterInput>;
  jobPayments?: InputMaybe<ListFilterInputTypeOfJobPaymentFilterInput>;
  jobVariations?: InputMaybe<ListFilterInputTypeOfJobVariationFilterInput>;
  mapReference?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobFilterInput>>;
  paymentTax?: InputMaybe<DecimalOperationFilterInput>;
  paymentTotal?: InputMaybe<FloatOperationFilterInput>;
  paymentTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  progressPercent?: InputMaybe<IntOperationFilterInput>;
  purchaseOrders?: InputMaybe<ListFilterInputTypeOfPurchaseOrderFilterInput>;
  retentionPaymentPercentage?: InputMaybe<FloatOperationFilterInput>;
  retentionPaymentTotal?: InputMaybe<DecimalOperationFilterInput>;
  retentionPercentage?: InputMaybe<FloatOperationFilterInput>;
  retentionTax?: InputMaybe<DecimalOperationFilterInput>;
  retentionTotal?: InputMaybe<DecimalOperationFilterInput>;
  retentionTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  retentionType?: InputMaybe<JobRetentionTypeOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  rowVersionN?: InputMaybe<LongOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  targetDate?: InputMaybe<DateTimeOperationFilterInput>;
  taxRate?: InputMaybe<FloatOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  taxRatesDescription?: InputMaybe<StringOperationFilterInput>;
  taxRatesShortCode?: InputMaybe<StringOperationFilterInput>;
  variationTax?: InputMaybe<DecimalOperationFilterInput>;
  variationTotal?: InputMaybe<DecimalOperationFilterInput>;
  variationTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  worksLocation?: InputMaybe<StringOperationFilterInput>;
  worksLocationAddress?: InputMaybe<StringOperationFilterInput>;
  worksLocationLat?: InputMaybe<DecimalOperationFilterInput>;
  worksLocationLng?: InputMaybe<DecimalOperationFilterInput>;
  worksLocationMapUrl?: InputMaybe<StringOperationFilterInput>;
  worksLocationPostcode?: InputMaybe<StringOperationFilterInput>;
  worksLocationState?: InputMaybe<StringOperationFilterInput>;
  worksLocationSuburb?: InputMaybe<StringOperationFilterInput>;
};

export type JobForInvoice = JobForInvoiceByDescription | JobForInvoiceByNumber;

export type JobForInvoiceByDescription = {
  __typename?: 'JobForInvoiceByDescription';
  crew: Array<JobCrewDto>;
  description?: Maybe<Scalars['String']['output']>;
  hasPendingInvitations: Scalars['Boolean']['output'];
  iD_Job: Scalars['Uuid']['output'];
  number?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type JobForInvoiceByNumber = {
  __typename?: 'JobForInvoiceByNumber';
  crew: Array<JobCrewDto>;
  description?: Maybe<Scalars['String']['output']>;
  hasPendingInvitations: Scalars['Boolean']['output'];
  iD_Job: Scalars['Uuid']['output'];
  number?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type JobInvitationDetailsDto = {
  __typename?: 'JobInvitationDetailsDto';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  buildingType?: Maybe<Scalars['String']['output']>;
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  invitedBy: Scalars['Uuid']['output'];
  inviteeFirstName?: Maybe<Scalars['String']['output']>;
  inviteeLastName?: Maybe<Scalars['String']['output']>;
  inviteeUserId?: Maybe<Scalars['Uuid']['output']>;
  isCompleted: Scalars['Boolean']['output'];
  jobId: Scalars['Uuid']['output'];
  jobInvitationId: Scalars['Uuid']['output'];
  lastNotificationSentAt?: Maybe<Scalars['DateTime']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: JobInvitationStatus;
  worksLocationAddress?: Maybe<Scalars['String']['output']>;
  worksLocationLat?: Maybe<Scalars['Decimal']['output']>;
  worksLocationLng?: Maybe<Scalars['Decimal']['output']>;
  worksLocationPostcode?: Maybe<Scalars['String']['output']>;
  worksLocationState?: Maybe<Scalars['String']['output']>;
  worksLocationSuburb?: Maybe<Scalars['String']['output']>;
};

export type JobInvitationDto = {
  __typename?: 'JobInvitationDto';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  buildingType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  invitedBy: Scalars['Uuid']['output'];
  inviteeFirstName?: Maybe<Scalars['String']['output']>;
  inviteeLastName?: Maybe<Scalars['String']['output']>;
  inviteeUserId?: Maybe<Scalars['Uuid']['output']>;
  jobId: Scalars['Uuid']['output'];
  jobInvitationId: Scalars['Uuid']['output'];
  lastNotificationSentAt?: Maybe<Scalars['DateTime']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  status: JobInvitationStatus;
};

export type JobInvitationDtoFilterInput = {
  and?: InputMaybe<Array<JobInvitationDtoFilterInput>>;
  avatarUrl?: InputMaybe<StringOperationFilterInput>;
  buildingType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  invitedBy?: InputMaybe<UuidOperationFilterInput>;
  inviteeFirstName?: InputMaybe<StringOperationFilterInput>;
  inviteeLastName?: InputMaybe<StringOperationFilterInput>;
  inviteeUserId?: InputMaybe<UuidOperationFilterInput>;
  jobId?: InputMaybe<UuidOperationFilterInput>;
  jobInvitationId?: InputMaybe<UuidOperationFilterInput>;
  lastNotificationSentAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<JobInvitationDtoFilterInput>>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<JobInvitationStatusOperationFilterInput>;
};

export type JobInvitationDtoSortInput = {
  avatarUrl?: InputMaybe<SortEnumType>;
  buildingType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  invitedBy?: InputMaybe<SortEnumType>;
  inviteeFirstName?: InputMaybe<SortEnumType>;
  inviteeLastName?: InputMaybe<SortEnumType>;
  inviteeUserId?: InputMaybe<SortEnumType>;
  jobId?: InputMaybe<SortEnumType>;
  jobInvitationId?: InputMaybe<SortEnumType>;
  lastNotificationSentAt?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type JobInvitationFilterInput = {
  and?: InputMaybe<Array<JobInvitationFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  iD_InvitedByUser?: InputMaybe<UuidOperationFilterInput>;
  iD_InviteeGoodworkUser?: InputMaybe<UuidOperationFilterInput>;
  iD_InviteeUser?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobInvitation?: InputMaybe<UuidOperationFilterInput>;
  inviteeFirstName?: InputMaybe<StringOperationFilterInput>;
  inviteeLastName?: InputMaybe<StringOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  lastNotificationSentAt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<JobInvitationFilterInput>>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<JobInvitationStatusOperationFilterInput>;
};

export type JobInvitationRequestV2Input = {
  inviteeEmail?: InputMaybe<Scalars['String']['input']>;
  inviteeFirstName: Scalars['String']['input'];
  inviteeLastName: Scalars['String']['input'];
  inviteePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['Uuid']['input'];
};

export const JobInvitationStatus = {
  Accepted: 'ACCEPTED',
  Declined: 'DECLINED',
  Pending: 'PENDING'
} as const;

export type JobInvitationStatus = typeof JobInvitationStatus[keyof typeof JobInvitationStatus];
export type JobInvitationStatusOperationFilterInput = {
  eq?: InputMaybe<JobInvitationStatus>;
  in?: InputMaybe<Array<JobInvitationStatus>>;
  neq?: InputMaybe<JobInvitationStatus>;
  nin?: InputMaybe<Array<JobInvitationStatus>>;
};

/** A segment of a collection. */
export type JobInvitationsForUserCollectionSegment = {
  __typename?: 'JobInvitationsForUserCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<JobInvitationDto>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export const JobInvoiceMethod = {
  Completionpercent: 'COMPLETIONPERCENT',
  Contractprice: 'CONTRACTPRICE',
  Costplus: 'COSTPLUS'
} as const;

export type JobInvoiceMethod = typeof JobInvoiceMethod[keyof typeof JobInvoiceMethod];
export type JobInvoiceMethodOperationFilterInput = {
  eq?: InputMaybe<JobInvoiceMethod>;
  in?: InputMaybe<Array<JobInvoiceMethod>>;
  neq?: InputMaybe<JobInvoiceMethod>;
  nin?: InputMaybe<Array<JobInvoiceMethod>>;
};

export type JobItem = {
  __typename?: 'JobItem';
  actualUnitCost: Scalars['Decimal']['output'];
  contactId?: Maybe<Scalars['Uuid']['output']>;
  costItemType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayedOrderNumber?: Maybe<Scalars['String']['output']>;
  estimatedCost: Scalars['Decimal']['output'];
  estimatedQty: Scalars['Decimal']['output'];
  estimatedUnitCost: Scalars['Decimal']['output'];
  iD_CostItem?: Maybe<Scalars['Uuid']['output']>;
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_Job: Scalars['Uuid']['output'];
  iD_JobItem: Scalars['Uuid']['output'];
  iD_QuoteRequest?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestItem?: Maybe<Scalars['Uuid']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  itemCode?: Maybe<Scalars['String']['output']>;
  job: Job;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<JobItem>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
  taxContent?: Maybe<Scalars['String']['output']>;
  taxRates?: Maybe<Scalars['String']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
};

export type JobItemFilterInput = {
  actualCost?: InputMaybe<DecimalOperationFilterInput>;
  actualCostLinked?: InputMaybe<BooleanOperationFilterInput>;
  actualQty?: InputMaybe<DecimalOperationFilterInput>;
  actualTax?: InputMaybe<DecimalOperationFilterInput>;
  actualTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  actualUnitCost?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<JobItemFilterInput>>;
  committedCost?: InputMaybe<DecimalOperationFilterInput>;
  committedQty?: InputMaybe<DecimalOperationFilterInput>;
  committedTax?: InputMaybe<DecimalOperationFilterInput>;
  committedTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  committedUnitCost?: InputMaybe<DecimalOperationFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  deputyAreaID?: InputMaybe<LongOperationFilterInput>;
  deputyTimesheetID?: InputMaybe<LongOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  displayedOrderNumber?: InputMaybe<StringOperationFilterInput>;
  estimatedCost?: InputMaybe<DecimalOperationFilterInput>;
  estimatedMarkup?: InputMaybe<DecimalOperationFilterInput>;
  estimatedQty?: InputMaybe<DecimalOperationFilterInput>;
  estimatedTax?: InputMaybe<DecimalOperationFilterInput>;
  estimatedTaxContent?: InputMaybe<StringOperationFilterInput>;
  estimatedTaxRates?: InputMaybe<StringOperationFilterInput>;
  estimatedTotalIncMarkupTax?: InputMaybe<DecimalOperationFilterInput>;
  estimatedUnitCost?: InputMaybe<DecimalOperationFilterInput>;
  iD_CostItem?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobItem?: InputMaybe<UuidOperationFilterInput>;
  iD_JobTask?: InputMaybe<UuidOperationFilterInput>;
  iD_JobVariationTask?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestItem?: InputMaybe<UuidOperationFilterInput>;
  invoiceTax?: InputMaybe<DecimalOperationFilterInput>;
  invoiceTotal?: InputMaybe<DecimalOperationFilterInput>;
  invoiceTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  itemType?: InputMaybe<IntOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobPaymentItems?: InputMaybe<ListFilterInputTypeOfJobPaymentItemFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobItemFilterInput>>;
  orderNumber?: InputMaybe<IntOperationFilterInput>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  taxContent?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  varianceCost?: InputMaybe<DecimalOperationFilterInput>;
  varianceCostCommitted?: InputMaybe<DecimalOperationFilterInput>;
};

export type JobItemSortInput = {
  actualCost?: InputMaybe<SortEnumType>;
  actualCostLinked?: InputMaybe<SortEnumType>;
  actualQty?: InputMaybe<SortEnumType>;
  actualTax?: InputMaybe<SortEnumType>;
  actualTotalIncTax?: InputMaybe<SortEnumType>;
  actualUnitCost?: InputMaybe<SortEnumType>;
  committedCost?: InputMaybe<SortEnumType>;
  committedQty?: InputMaybe<SortEnumType>;
  committedTax?: InputMaybe<SortEnumType>;
  committedTotalIncTax?: InputMaybe<SortEnumType>;
  committedUnitCost?: InputMaybe<SortEnumType>;
  costItemType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  deputyAreaID?: InputMaybe<SortEnumType>;
  deputyTimesheetID?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  displayedOrderNumber?: InputMaybe<SortEnumType>;
  estimatedCost?: InputMaybe<SortEnumType>;
  estimatedMarkup?: InputMaybe<SortEnumType>;
  estimatedQty?: InputMaybe<SortEnumType>;
  estimatedTax?: InputMaybe<SortEnumType>;
  estimatedTaxContent?: InputMaybe<SortEnumType>;
  estimatedTaxRates?: InputMaybe<SortEnumType>;
  estimatedTotalIncMarkupTax?: InputMaybe<SortEnumType>;
  estimatedUnitCost?: InputMaybe<SortEnumType>;
  iD_CostItem?: InputMaybe<SortEnumType>;
  iD_EstimateItem?: InputMaybe<SortEnumType>;
  iD_Job?: InputMaybe<SortEnumType>;
  iD_JobItem?: InputMaybe<SortEnumType>;
  iD_JobTask?: InputMaybe<SortEnumType>;
  iD_JobVariationTask?: InputMaybe<SortEnumType>;
  iD_QuoteRequest?: InputMaybe<SortEnumType>;
  iD_QuoteRequestItem?: InputMaybe<SortEnumType>;
  invoiceTax?: InputMaybe<SortEnumType>;
  invoiceTotal?: InputMaybe<SortEnumType>;
  invoiceTotalIncTax?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  itemCode?: InputMaybe<SortEnumType>;
  itemType?: InputMaybe<SortEnumType>;
  job?: InputMaybe<JobSortInput>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  orderNumber?: InputMaybe<SortEnumType>;
  parentId?: InputMaybe<SortEnumType>;
  taxContent?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  uOM?: InputMaybe<SortEnumType>;
  varianceCost?: InputMaybe<SortEnumType>;
  varianceCostCommitted?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type JobItemsByJobIdCollectionSegment = {
  __typename?: 'JobItemsByJobIdCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<JobItem>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type JobPaymentFilterInput = {
  accSyncData?: InputMaybe<StringOperationFilterInput>;
  accountCode?: InputMaybe<StringOperationFilterInput>;
  adjustment?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<JobPaymentFilterInput>>;
  autoSend?: InputMaybe<StringOperationFilterInput>;
  autoSendDaysBeforeDue?: InputMaybe<IntOperationFilterInput>;
  autoSendPercentage?: InputMaybe<DecimalOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  completionPercentTax?: InputMaybe<DecimalOperationFilterInput>;
  completionPercentTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  completionPercentValue?: InputMaybe<DecimalOperationFilterInput>;
  costPlusTax?: InputMaybe<DecimalOperationFilterInput>;
  costPlusValue?: InputMaybe<DecimalOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  depositPercentage?: InputMaybe<DecimalOperationFilterInput>;
  depositTaxContent?: InputMaybe<StringOperationFilterInput>;
  depositTaxRates?: InputMaybe<StringOperationFilterInput>;
  depositUsed?: InputMaybe<DecimalOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dueDate?: InputMaybe<DateTimeOperationFilterInput>;
  gST?: InputMaybe<FloatOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPayment?: InputMaybe<UuidOperationFilterInput>;
  iD_JobTask?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  invoiceDate?: InputMaybe<DateTimeOperationFilterInput>;
  invoiceNumber?: InputMaybe<IntOperationFilterInput>;
  isCostPlusShownSeparately?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isDepositInvoice?: InputMaybe<BooleanOperationFilterInput>;
  isRetentionInvoice?: InputMaybe<BooleanOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobPaymentItems?: InputMaybe<ListFilterInputTypeOfJobPaymentItemFilterInput>;
  jobPaymentPurchaseOrderItems?: InputMaybe<ListFilterInputTypeOfJobPaymentPurchaseOrderItemFilterInput>;
  jobPaymentReceipts?: InputMaybe<ListFilterInputTypeOfJobPaymentReceiptFilterInput>;
  jobVariations?: InputMaybe<ListFilterInputTypeOfJobVariationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<JobPaymentFilterInput>>;
  paymentOrder?: InputMaybe<IntOperationFilterInput>;
  paymentValue?: InputMaybe<FloatOperationFilterInput>;
  percentageOfTotal?: InputMaybe<FloatOperationFilterInput>;
  purchaseOrders?: InputMaybe<ListFilterInputTypeOfPurchaseOrderFilterInput>;
  receivedTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  receivedTotalTax?: InputMaybe<DecimalOperationFilterInput>;
  retentionPercentage?: InputMaybe<FloatOperationFilterInput>;
  retentionTax?: InputMaybe<DecimalOperationFilterInput>;
  retentionTotal?: InputMaybe<DecimalOperationFilterInput>;
  retentionTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  taxRatesDescription?: InputMaybe<StringOperationFilterInput>;
  taxRatesShortCode?: InputMaybe<StringOperationFilterInput>;
  totalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  variationTax?: InputMaybe<DecimalOperationFilterInput>;
  variationTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  variationsValue?: InputMaybe<DecimalOperationFilterInput>;
};

export type JobPaymentItemFilterInput = {
  and?: InputMaybe<Array<JobPaymentItemFilterInput>>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobItem?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPayment?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPaymentItem?: InputMaybe<UuidOperationFilterInput>;
  iD_Variation?: InputMaybe<UuidOperationFilterInput>;
  invoicePercentage?: InputMaybe<FloatOperationFilterInput>;
  invoiceTax?: InputMaybe<DecimalOperationFilterInput>;
  invoiceTotal?: InputMaybe<DecimalOperationFilterInput>;
  invoiceTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobItem?: InputMaybe<JobItemFilterInput>;
  jobPayment?: InputMaybe<JobPaymentFilterInput>;
  jobVariation?: InputMaybe<JobVariationFilterInput>;
  or?: InputMaybe<Array<JobPaymentItemFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  taxContent?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
};

export type JobPaymentPurchaseOrderItemFilterInput = {
  and?: InputMaybe<Array<JobPaymentPurchaseOrderItemFilterInput>>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPayment?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPaymentPurchaseOrderItem?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrder?: InputMaybe<UuidOperationFilterInput>;
  itemCost?: InputMaybe<DecimalOperationFilterInput>;
  itemTax?: InputMaybe<DecimalOperationFilterInput>;
  itemTotal?: InputMaybe<DecimalOperationFilterInput>;
  itemTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobPayment?: InputMaybe<JobPaymentFilterInput>;
  or?: InputMaybe<Array<JobPaymentPurchaseOrderItemFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  purchaseOrder?: InputMaybe<PurchaseOrderFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
  taxContent?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
};

export type JobPaymentReceiptFilterInput = {
  accSyncData?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<JobPaymentReceiptFilterInput>>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPayment?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPaymentReceipt?: InputMaybe<UuidOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobPaymentReceiptFilterInput>>;
  paymentType?: InputMaybe<StringOperationFilterInput>;
  receiptDate?: InputMaybe<DateTimeOperationFilterInput>;
  reference?: InputMaybe<StringOperationFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  totalIncTax?: InputMaybe<DecimalOperationFilterInput>;
};

export type JobPaymentSetting = {
  __typename?: 'JobPaymentSetting';
  iD_JobPaymentSetting: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  invoiceNumber: Scalars['Int']['output'];
  isTermsOnSeparatePage: Scalars['Boolean']['output'];
  paymentTerms?: Maybe<Scalars['String']['output']>;
  showCostPlusItemisation: Scalars['Boolean']['output'];
  showOrderInstructions: Scalars['Boolean']['output'];
};

export type JobPaymentSortInput = {
  accSyncData?: InputMaybe<SortEnumType>;
  accountCode?: InputMaybe<SortEnumType>;
  adjustment?: InputMaybe<SortEnumType>;
  autoSend?: InputMaybe<SortEnumType>;
  autoSendDaysBeforeDue?: InputMaybe<SortEnumType>;
  autoSendPercentage?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  completionPercentTax?: InputMaybe<SortEnumType>;
  completionPercentTotalIncTax?: InputMaybe<SortEnumType>;
  completionPercentValue?: InputMaybe<SortEnumType>;
  costPlusTax?: InputMaybe<SortEnumType>;
  costPlusValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  depositPercentage?: InputMaybe<SortEnumType>;
  depositTaxContent?: InputMaybe<SortEnumType>;
  depositTaxRates?: InputMaybe<SortEnumType>;
  depositUsed?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  dueDate?: InputMaybe<SortEnumType>;
  gST?: InputMaybe<SortEnumType>;
  iD_Job?: InputMaybe<SortEnumType>;
  iD_JobPayment?: InputMaybe<SortEnumType>;
  iD_JobTask?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  invoiceDate?: InputMaybe<SortEnumType>;
  invoiceNumber?: InputMaybe<SortEnumType>;
  isCostPlusShownSeparately?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isDepositInvoice?: InputMaybe<SortEnumType>;
  isRetentionInvoice?: InputMaybe<SortEnumType>;
  job?: InputMaybe<JobSortInput>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  paymentOrder?: InputMaybe<SortEnumType>;
  paymentValue?: InputMaybe<SortEnumType>;
  percentageOfTotal?: InputMaybe<SortEnumType>;
  receivedTotalIncTax?: InputMaybe<SortEnumType>;
  receivedTotalTax?: InputMaybe<SortEnumType>;
  retentionPercentage?: InputMaybe<SortEnumType>;
  retentionTax?: InputMaybe<SortEnumType>;
  retentionTotal?: InputMaybe<SortEnumType>;
  retentionTotalIncTax?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  taxRatesDescription?: InputMaybe<SortEnumType>;
  taxRatesShortCode?: InputMaybe<SortEnumType>;
  totalIncTax?: InputMaybe<SortEnumType>;
  variationTax?: InputMaybe<SortEnumType>;
  variationTotalIncTax?: InputMaybe<SortEnumType>;
  variationsValue?: InputMaybe<SortEnumType>;
};

export type JobProgressMetrics = {
  __typename?: 'JobProgressMetrics';
  client?: Maybe<Scalars['String']['output']>;
  job?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  progress: Scalars['Decimal']['output'];
};

/** A connection to a list of items. */
export type JobProgressMetricsConnection = {
  __typename?: 'JobProgressMetricsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<JobProgressMetricsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<JobProgressMetrics>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type JobProgressMetricsEdge = {
  __typename?: 'JobProgressMetricsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<JobProgressMetrics>;
};

export type JobProgressMetricsFilterInput = {
  and?: InputMaybe<Array<JobProgressMetricsFilterInput>>;
  client?: InputMaybe<StringOperationFilterInput>;
  job?: InputMaybe<StringOperationFilterInput>;
  location?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobProgressMetricsFilterInput>>;
  progress?: InputMaybe<DecimalOperationFilterInput>;
};

export type JobProgressMetricsSortInput = {
  client?: InputMaybe<SortEnumType>;
  job?: InputMaybe<SortEnumType>;
  location?: InputMaybe<SortEnumType>;
  progress?: InputMaybe<SortEnumType>;
};

export const JobRetentionType = {
  Excludingvariations: 'EXCLUDINGVARIATIONS',
  Includingvariations: 'INCLUDINGVARIATIONS'
} as const;

export type JobRetentionType = typeof JobRetentionType[keyof typeof JobRetentionType];
export type JobRetentionTypeOperationFilterInput = {
  eq?: InputMaybe<JobRetentionType>;
  in?: InputMaybe<Array<JobRetentionType>>;
  neq?: InputMaybe<JobRetentionType>;
  nin?: InputMaybe<Array<JobRetentionType>>;
};

export type JobSortInput = {
  actualTax?: InputMaybe<SortEnumType>;
  actualTotal?: InputMaybe<SortEnumType>;
  actualTotalIncTax?: InputMaybe<SortEnumType>;
  balance?: InputMaybe<SortEnumType>;
  buildingType?: InputMaybe<SortEnumType>;
  calculationMethod?: InputMaybe<SortEnumType>;
  clientAddress1?: InputMaybe<SortEnumType>;
  clientAddress2?: InputMaybe<SortEnumType>;
  clientCityTown?: InputMaybe<SortEnumType>;
  clientEmail?: InputMaybe<SortEnumType>;
  clientFax?: InputMaybe<SortEnumType>;
  clientMobile?: InputMaybe<SortEnumType>;
  clientName?: InputMaybe<SortEnumType>;
  clientPhone?: InputMaybe<SortEnumType>;
  clientPostCode?: InputMaybe<SortEnumType>;
  clientState?: InputMaybe<SortEnumType>;
  colorCode?: InputMaybe<SortEnumType>;
  committedTax?: InputMaybe<SortEnumType>;
  committedTotal?: InputMaybe<SortEnumType>;
  committedTotalIncTax?: InputMaybe<SortEnumType>;
  completionDate?: InputMaybe<SortEnumType>;
  contractMarkup?: InputMaybe<SortEnumType>;
  contractTax?: InputMaybe<SortEnumType>;
  contractTotal?: InputMaybe<SortEnumType>;
  contractTotalIncTax?: InputMaybe<SortEnumType>;
  costPlusPercentage?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerViewSortInput>;
  customerPortalEnabled?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  deletionSource?: InputMaybe<SortEnumType>;
  depositTax?: InputMaybe<SortEnumType>;
  depositTotal?: InputMaybe<SortEnumType>;
  deputyLocationID?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  estimatedTax?: InputMaybe<SortEnumType>;
  estimatedTotal?: InputMaybe<SortEnumType>;
  estimatedTotalIncTax?: InputMaybe<SortEnumType>;
  franchiseFee?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_Job?: InputMaybe<SortEnumType>;
  iD_Lead?: InputMaybe<SortEnumType>;
  iD_Quote?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  invoiceDueDays?: InputMaybe<SortEnumType>;
  invoiceMethod?: InputMaybe<SortEnumType>;
  isCompleted?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGlobalSynced?: InputMaybe<SortEnumType>;
  mapReference?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  paymentTax?: InputMaybe<SortEnumType>;
  paymentTotal?: InputMaybe<SortEnumType>;
  paymentTotalIncTax?: InputMaybe<SortEnumType>;
  progressPercent?: InputMaybe<SortEnumType>;
  retentionPaymentPercentage?: InputMaybe<SortEnumType>;
  retentionPaymentTotal?: InputMaybe<SortEnumType>;
  retentionPercentage?: InputMaybe<SortEnumType>;
  retentionTax?: InputMaybe<SortEnumType>;
  retentionTotal?: InputMaybe<SortEnumType>;
  retentionTotalIncTax?: InputMaybe<SortEnumType>;
  retentionType?: InputMaybe<SortEnumType>;
  rowVersionN?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  targetDate?: InputMaybe<SortEnumType>;
  taxRate?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  taxRatesDescription?: InputMaybe<SortEnumType>;
  taxRatesShortCode?: InputMaybe<SortEnumType>;
  variationTax?: InputMaybe<SortEnumType>;
  variationTotal?: InputMaybe<SortEnumType>;
  variationTotalIncTax?: InputMaybe<SortEnumType>;
  worksLocation?: InputMaybe<SortEnumType>;
  worksLocationAddress?: InputMaybe<SortEnumType>;
  worksLocationLat?: InputMaybe<SortEnumType>;
  worksLocationLng?: InputMaybe<SortEnumType>;
  worksLocationMapUrl?: InputMaybe<SortEnumType>;
  worksLocationPostcode?: InputMaybe<SortEnumType>;
  worksLocationState?: InputMaybe<SortEnumType>;
  worksLocationSuburb?: InputMaybe<SortEnumType>;
};

export type JobStatisticRanges = {
  __typename?: 'JobStatisticRanges';
  jobsInProgress?: Maybe<Scalars['String']['output']>;
  jobsInProgressValue?: Maybe<Scalars['String']['output']>;
  totalMaterialInProgressValue?: Maybe<Scalars['String']['output']>;
  yearlyCompletedJobs?: Maybe<Scalars['String']['output']>;
  yearlyCompletedJobsValue?: Maybe<Scalars['String']['output']>;
};

export const JobTypes = {
  Insurance: 'INSURANCE',
  Landscape: 'LANDSCAPE',
  Newbuild: 'NEWBUILD',
  Other: 'OTHER',
  Renovation: 'RENOVATION'
} as const;

export type JobTypes = typeof JobTypes[keyof typeof JobTypes];
export type JobVariation = {
  __typename?: 'JobVariation';
  acceptedDate?: Maybe<Scalars['DateTime']['output']>;
  amount?: Maybe<Scalars['Decimal']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  franchiseFeeTax: Scalars['Decimal']['output'];
  franchiseFeeTotal: Scalars['Decimal']['output'];
  franchiseFeeTotalIncTax: Scalars['Decimal']['output'];
  iD_Job: Scalars['Uuid']['output'];
  iD_JobPayment?: Maybe<Scalars['Uuid']['output']>;
  iD_Variation: Scalars['Uuid']['output'];
  invoiceTax: Scalars['Decimal']['output'];
  invoiceTotal: Scalars['Decimal']['output'];
  invoiceTotalIncTax: Scalars['Decimal']['output'];
  isAdjustment: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  rejectedDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tax: Scalars['Decimal']['output'];
  taxRates?: Maybe<Scalars['String']['output']>;
  taxRatesDescription?: Maybe<Scalars['String']['output']>;
  taxRatesShortCode?: Maybe<Scalars['String']['output']>;
  timeDelay?: Maybe<Scalars['Int']['output']>;
  totalIncTax: Scalars['Decimal']['output'];
  variationDate: Scalars['DateTime']['output'];
  variationNo: Scalars['Int']['output'];
};

export type JobVariationFilterInput = {
  acceptedDate?: InputMaybe<DateTimeOperationFilterInput>;
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<JobVariationFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  details?: InputMaybe<StringOperationFilterInput>;
  franchiseFeeTax?: InputMaybe<DecimalOperationFilterInput>;
  franchiseFeeTotal?: InputMaybe<DecimalOperationFilterInput>;
  franchiseFeeTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPayment?: InputMaybe<UuidOperationFilterInput>;
  iD_Variation?: InputMaybe<UuidOperationFilterInput>;
  invoiceTax?: InputMaybe<DecimalOperationFilterInput>;
  invoiceTotal?: InputMaybe<DecimalOperationFilterInput>;
  invoiceTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  isAdjustment?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobPayment?: InputMaybe<JobPaymentFilterInput>;
  jobPaymentItems?: InputMaybe<ListFilterInputTypeOfJobPaymentItemFilterInput>;
  jobVariationTasks?: InputMaybe<ListFilterInputTypeOfJobVariationTaskFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<JobVariationFilterInput>>;
  rejectedDate?: InputMaybe<DateTimeOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  taxRatesDescription?: InputMaybe<StringOperationFilterInput>;
  taxRatesShortCode?: InputMaybe<StringOperationFilterInput>;
  timeDelay?: InputMaybe<IntOperationFilterInput>;
  totalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  variationDate?: InputMaybe<DateTimeOperationFilterInput>;
  variationNo?: InputMaybe<IntOperationFilterInput>;
};

export type JobVariationSortInput = {
  acceptedDate?: InputMaybe<SortEnumType>;
  amount?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  details?: InputMaybe<SortEnumType>;
  franchiseFeeTax?: InputMaybe<SortEnumType>;
  franchiseFeeTotal?: InputMaybe<SortEnumType>;
  franchiseFeeTotalIncTax?: InputMaybe<SortEnumType>;
  iD_Job?: InputMaybe<SortEnumType>;
  iD_JobPayment?: InputMaybe<SortEnumType>;
  iD_Variation?: InputMaybe<SortEnumType>;
  invoiceTax?: InputMaybe<SortEnumType>;
  invoiceTotal?: InputMaybe<SortEnumType>;
  invoiceTotalIncTax?: InputMaybe<SortEnumType>;
  isAdjustment?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  job?: InputMaybe<JobSortInput>;
  jobPayment?: InputMaybe<JobPaymentSortInput>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  rejectedDate?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  taxRatesDescription?: InputMaybe<SortEnumType>;
  taxRatesShortCode?: InputMaybe<SortEnumType>;
  timeDelay?: InputMaybe<SortEnumType>;
  totalIncTax?: InputMaybe<SortEnumType>;
  variationDate?: InputMaybe<SortEnumType>;
  variationNo?: InputMaybe<SortEnumType>;
};

export type JobVariationTaskFilterInput = {
  and?: InputMaybe<Array<JobVariationTaskFilterInput>>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_CostItem?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_JobItem?: InputMaybe<UuidOperationFilterInput>;
  iD_JobVariationTask?: InputMaybe<UuidOperationFilterInput>;
  iD_ParentTask?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestItem?: InputMaybe<UuidOperationFilterInput>;
  iD_Variation?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobVariation?: InputMaybe<JobVariationFilterInput>;
  markup?: InputMaybe<FloatOperationFilterInput>;
  markupTotal?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<JobVariationTaskFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  parentTask?: InputMaybe<StringOperationFilterInput>;
  showInSchedule?: InputMaybe<BooleanOperationFilterInput>;
  taxContent?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  totalCost?: InputMaybe<DecimalOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  unitCost?: InputMaybe<DecimalOperationFilterInput>;
  units?: InputMaybe<DecimalOperationFilterInput>;
  unitsExpression?: InputMaybe<StringOperationFilterInput>;
};

/** A segment of a collection. */
export type JobVariationsCollectionSegment = {
  __typename?: 'JobVariationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<JobVariation>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type JobViewFilterInput = {
  actualTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<JobViewFilterInput>>;
  buildingType?: InputMaybe<StringOperationFilterInput>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  clientAddress1?: InputMaybe<StringOperationFilterInput>;
  clientAddress2?: InputMaybe<StringOperationFilterInput>;
  clientCityTown?: InputMaybe<StringOperationFilterInput>;
  clientEmail?: InputMaybe<StringOperationFilterInput>;
  clientMobile?: InputMaybe<StringOperationFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  clientPhone?: InputMaybe<StringOperationFilterInput>;
  clientPostCode?: InputMaybe<StringOperationFilterInput>;
  clientState?: InputMaybe<StringOperationFilterInput>;
  committedTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  completionDate?: InputMaybe<DateTimeOperationFilterInput>;
  contractTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  estimatedTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  invoiceMethod?: InputMaybe<IntOperationFilterInput>;
  invoiceMethodName?: InputMaybe<StringOperationFilterInput>;
  isCompleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobViewFilterInput>>;
  paymentTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  progressPercent?: InputMaybe<IntOperationFilterInput>;
  retentionTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  targetDate?: InputMaybe<DateTimeOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  variationTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  worksLocationAddress?: InputMaybe<StringOperationFilterInput>;
  worksLocationPostcode?: InputMaybe<StringOperationFilterInput>;
  worksLocationState?: InputMaybe<StringOperationFilterInput>;
  worksLocationSuburb?: InputMaybe<StringOperationFilterInput>;
};

/** A segment of a collection. */
export type JobsCollectionSegment = {
  __typename?: 'JobsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Job>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type KeyValuePairOfStringAndEmailTrackingStatus = {
  __typename?: 'KeyValuePairOfStringAndEmailTrackingStatus';
  key: Scalars['String']['output'];
  value: EmailTrackingStatus;
};

export type LatestOneBuildPricingModel = {
  __typename?: 'LatestOneBuildPricingModel';
  id: Scalars['Uuid']['output'];
  unitCost: Scalars['Decimal']['output'];
};

export type LayoutDto = {
  __typename?: 'LayoutDto';
  canBeDeleted?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isGlobal?: Maybe<Scalars['Boolean']['output']>;
  layoutId?: Maybe<Scalars['Uuid']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  referenceLayoutId?: Maybe<Scalars['Uuid']['output']>;
  referenceObjectId?: Maybe<Scalars['Uuid']['output']>;
  referenceObjectName?: Maybe<Scalars['String']['output']>;
  sections?: Maybe<Array<Maybe<SectionDto>>>;
  tenantId?: Maybe<Scalars['Uuid']['output']>;
  thumbnailContent?: Maybe<Scalars['String']['output']>;
};

export type LayoutDtoInput = {
  canBeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  layoutId?: InputMaybe<Scalars['Uuid']['input']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  referenceLayoutId?: InputMaybe<Scalars['Uuid']['input']>;
  referenceObjectId?: InputMaybe<Scalars['Uuid']['input']>;
  referenceObjectName?: InputMaybe<Scalars['String']['input']>;
  sections?: InputMaybe<Array<InputMaybe<SectionDtoInput>>>;
  tenantId?: InputMaybe<Scalars['Uuid']['input']>;
  thumbnailContent?: InputMaybe<Scalars['String']['input']>;
};

export type LayoutOrderDtoInput = {
  layoutId: Scalars['Uuid']['input'];
  sections?: InputMaybe<Array<InputMaybe<LayoutOrderSectionDtoInput>>>;
};

export type LayoutOrderSectionDtoInput = {
  layoutId: Scalars['Uuid']['input'];
  order: Scalars['Int']['input'];
  sectionId: Scalars['Uuid']['input'];
};

export type LayoutSelectionDto = {
  __typename?: 'LayoutSelectionDto';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  layoutId: Scalars['Uuid']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  referenceObjectId: Scalars['Uuid']['output'];
};

export type LayoutSelectionDtoInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  layoutId: Scalars['Uuid']['input'];
  modifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  referenceObjectId: Scalars['Uuid']['input'];
};

export type Lead = {
  __typename?: 'Lead';
  billingAddress?: Maybe<AddressModel>;
  client?: Maybe<Customer>;
  clientContact?: Maybe<CustomerContact>;
  clientName?: Maybe<Scalars['String']['output']>;
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  datePublished?: Maybe<Scalars['DateTime']['output']>;
  estimatedBudget?: Maybe<Scalars['Decimal']['output']>;
  iD_Customer: Scalars['Uuid']['output'];
  iD_CustomerContact?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['Uuid']['output'];
  isDeleted: Scalars['Boolean']['output'];
  jobType?: Maybe<JobTypes>;
  leadAgeInDays: Scalars['Int']['output'];
  leadNumber?: Maybe<Scalars['String']['output']>;
  projectAddress?: Maybe<AddressModel>;
  projectName?: Maybe<Scalars['String']['output']>;
  referral?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: LeadStatus;
};

/** A connection to a list of items. */
export type LeadConnection = {
  __typename?: 'LeadConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LeadEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Lead>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type LeadDetailsForConversation = {
  __typename?: 'LeadDetailsForConversation';
  clientName?: Maybe<Scalars['String']['output']>;
  iD_Customer: Scalars['Uuid']['output'];
  iD_Lead: Scalars['Uuid']['output'];
  leadNumber?: Maybe<Scalars['String']['output']>;
};

/** An edge in a connection. */
export type LeadEdge = {
  __typename?: 'LeadEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Lead;
};

export type LeadFilterInput = {
  and?: InputMaybe<Array<LeadFilterInput>>;
  billingAddress1?: InputMaybe<StringOperationFilterInput>;
  billingCity?: InputMaybe<StringOperationFilterInput>;
  billingCountry?: InputMaybe<StringOperationFilterInput>;
  billingPostcode?: InputMaybe<StringOperationFilterInput>;
  billingState?: InputMaybe<StringOperationFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  coverImageUrl?: InputMaybe<StringOperationFilterInput>;
  coverImageUrlExpiry?: InputMaybe<DateTimeOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  datePublishedToClientPortal?: InputMaybe<DateTimeOperationFilterInput>;
  dateUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  estimatedBudget?: InputMaybe<DecimalOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_CustomerContact?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isAutoCreated?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  isPublished?: InputMaybe<BooleanOperationFilterInput>;
  jobType?: InputMaybe<StringOperationFilterInput>;
  jobTypeNote?: InputMaybe<StringOperationFilterInput>;
  leadNumber?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LeadFilterInput>>;
  projectAddress1?: InputMaybe<StringOperationFilterInput>;
  projectCity?: InputMaybe<StringOperationFilterInput>;
  projectCountry?: InputMaybe<StringOperationFilterInput>;
  projectName?: InputMaybe<StringOperationFilterInput>;
  projectPostcode?: InputMaybe<StringOperationFilterInput>;
  projectState?: InputMaybe<StringOperationFilterInput>;
  referral?: InputMaybe<StringOperationFilterInput>;
  scopeOfWorks?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  status?: InputMaybe<LeadStatusOperationFilterInput>;
  submissionDeadline?: InputMaybe<DateTimeOperationFilterInput>;
};

/** A segment of a collection. */
export type LeadListCollectionSegment = {
  __typename?: 'LeadListCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Lead>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type LeadModel = {
  __typename?: 'LeadModel';
  billingAddress?: Maybe<AddressModel>;
  /** --FUSION ONLY PROPERTY-- */
  client?: Maybe<Customer>;
  /** --FUSION ONLY PROPERTY-- */
  clientContact?: Maybe<CustomerContact>;
  clientName?: Maybe<Scalars['String']['output']>;
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<CustomerModel>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  datePublished?: Maybe<Scalars['DateTime']['output']>;
  estimatedBudget?: Maybe<Scalars['Decimal']['output']>;
  iD_Customer?: Maybe<Scalars['Uuid']['output']>;
  iD_CustomerContact?: Maybe<Scalars['Uuid']['output']>;
  id?: Maybe<Scalars['Uuid']['output']>;
  isAutoCreated?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  jobType?: Maybe<JobTypes>;
  jobTypeNote?: Maybe<Scalars['String']['output']>;
  leadAgeInDays?: Maybe<Scalars['Int']['output']>;
  leadNumber?: Maybe<Scalars['String']['output']>;
  projectAddress?: Maybe<AddressModel>;
  projectName?: Maybe<Scalars['String']['output']>;
  referral?: Maybe<Scalars['String']['output']>;
  scopeOfWorks?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<LeadStatus>;
  submissionDeadline?: Maybe<Scalars['DateTime']['output']>;
};

/** A connection to a list of items. */
export type LeadModelConnection = {
  __typename?: 'LeadModelConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LeadModelEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<LeadModel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LeadModelEdge = {
  __typename?: 'LeadModelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LeadModel>;
};

export type LeadModelFilterInput = {
  and?: InputMaybe<Array<LeadModelFilterInput>>;
  billingAddress?: InputMaybe<AddressModelFilterInput>;
  clientName?: InputMaybe<StringOperationFilterInput>;
  coverImageUrl?: InputMaybe<StringOperationFilterInput>;
  customer?: InputMaybe<CustomerModelFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  datePublished?: InputMaybe<DateTimeOperationFilterInput>;
  estimatedBudget?: InputMaybe<DecimalOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_CustomerContact?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isAutoCreated?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  jobType?: InputMaybe<NullableOfJobTypesOperationFilterInput>;
  jobTypeNote?: InputMaybe<StringOperationFilterInput>;
  leadAgeInDays?: InputMaybe<IntOperationFilterInput>;
  leadNumber?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LeadModelFilterInput>>;
  projectAddress?: InputMaybe<AddressModelFilterInput>;
  projectName?: InputMaybe<StringOperationFilterInput>;
  referral?: InputMaybe<StringOperationFilterInput>;
  scopeOfWorks?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  status?: InputMaybe<NullableOfLeadStatusOperationFilterInput>;
  submissionDeadline?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LeadModelInput = {
  billingAddress?: InputMaybe<AddressModelInput>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<CustomerModelInput>;
  dateCreated?: InputMaybe<Scalars['DateTime']['input']>;
  dateDeleted?: InputMaybe<Scalars['DateTime']['input']>;
  datePublished?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedBudget?: InputMaybe<Scalars['Decimal']['input']>;
  iD_Customer?: InputMaybe<Scalars['Uuid']['input']>;
  iD_CustomerContact?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
  isAutoCreated?: InputMaybe<Scalars['Boolean']['input']>;
  jobType?: InputMaybe<JobTypes>;
  jobTypeNote?: InputMaybe<Scalars['String']['input']>;
  leadNumber?: InputMaybe<Scalars['String']['input']>;
  projectAddress?: InputMaybe<AddressModelInput>;
  projectName?: InputMaybe<Scalars['String']['input']>;
  referral?: InputMaybe<Scalars['String']['input']>;
  scopeOfWorks?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<LeadStatus>;
  submissionDeadline?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadModelSortInput = {
  billingAddress?: InputMaybe<AddressModelSortInput>;
  clientName?: InputMaybe<SortEnumType>;
  coverImageUrl?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerModelSortInput>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  datePublished?: InputMaybe<SortEnumType>;
  estimatedBudget?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  iD_CustomerContact?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isAutoCreated?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  jobType?: InputMaybe<SortEnumType>;
  jobTypeNote?: InputMaybe<SortEnumType>;
  leadAgeInDays?: InputMaybe<SortEnumType>;
  leadNumber?: InputMaybe<SortEnumType>;
  projectAddress?: InputMaybe<AddressModelSortInput>;
  projectName?: InputMaybe<SortEnumType>;
  referral?: InputMaybe<SortEnumType>;
  scopeOfWorks?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  submissionDeadline?: InputMaybe<SortEnumType>;
};

export type LeadSortInput = {
  billingAddress1?: InputMaybe<SortEnumType>;
  billingCity?: InputMaybe<SortEnumType>;
  billingCountry?: InputMaybe<SortEnumType>;
  billingPostcode?: InputMaybe<SortEnumType>;
  billingState?: InputMaybe<SortEnumType>;
  clientName?: InputMaybe<SortEnumType>;
  coverImageUrl?: InputMaybe<SortEnumType>;
  coverImageUrlExpiry?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  datePublishedToClientPortal?: InputMaybe<SortEnumType>;
  dateUpdated?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  estimatedBudget?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  iD_CustomerContact?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isAutoCreated?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGlobalSynced?: InputMaybe<SortEnumType>;
  isPublished?: InputMaybe<SortEnumType>;
  jobType?: InputMaybe<SortEnumType>;
  jobTypeNote?: InputMaybe<SortEnumType>;
  leadNumber?: InputMaybe<SortEnumType>;
  projectAddress1?: InputMaybe<SortEnumType>;
  projectCity?: InputMaybe<SortEnumType>;
  projectCountry?: InputMaybe<SortEnumType>;
  projectName?: InputMaybe<SortEnumType>;
  projectPostcode?: InputMaybe<SortEnumType>;
  projectState?: InputMaybe<SortEnumType>;
  referral?: InputMaybe<SortEnumType>;
  scopeOfWorks?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  submissionDeadline?: InputMaybe<SortEnumType>;
};

export const LeadStatus = {
  Lost: 'LOST',
  New: 'NEW',
  Qualifying: 'QUALIFYING',
  Quoting: 'QUOTING',
  Won: 'WON'
} as const;

export type LeadStatus = typeof LeadStatus[keyof typeof LeadStatus];
export type LeadStatusOperationFilterInput = {
  eq?: InputMaybe<LeadStatus>;
  in?: InputMaybe<Array<LeadStatus>>;
  neq?: InputMaybe<LeadStatus>;
  nin?: InputMaybe<Array<LeadStatus>>;
};

export type LeadStatusWithCount = {
  __typename?: 'LeadStatusWithCount';
  count: Scalars['Int']['output'];
  status: LeadStatus;
};

export type LeadStatusWithCountFilterInput = {
  and?: InputMaybe<Array<LeadStatusWithCountFilterInput>>;
  count?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LeadStatusWithCountFilterInput>>;
  status?: InputMaybe<LeadStatusOperationFilterInput>;
};

export type LeadStatusWithCountSortInput = {
  count?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type LegacyMessageDto = {
  __typename?: 'LegacyMessageDto';
  body?: Maybe<Scalars['String']['output']>;
  iD_Message: Scalars['Uuid']['output'];
  iD_Recipient?: Maybe<Scalars['Uuid']['output']>;
  iD_Reference?: Maybe<Scalars['Uuid']['output']>;
  iD_Sender?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  iD_Thread?: Maybe<Scalars['Uuid']['output']>;
  iD_User?: Maybe<Scalars['Uuid']['output']>;
  isArchived: Scalars['Boolean']['output'];
  isNew: Scalars['Boolean']['output'];
  postDate: Scalars['DateTime']['output'];
  referenceNumber?: Maybe<Scalars['String']['output']>;
  referenceType?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type LikeInfo = {
  __typename?: 'LikeInfo';
  isLikedByMe: Scalars['Boolean']['output'];
  likeId?: Maybe<Scalars['Uuid']['output']>;
  total: Scalars['Int']['output'];
};

export type ListByteOperationFilterInput = {
  all?: InputMaybe<ByteOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ByteOperationFilterInput>;
  some?: InputMaybe<ByteOperationFilterInput>;
};

export type ListDateTimeOperationFilterInput = {
  all?: InputMaybe<DateTimeOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DateTimeOperationFilterInput>;
  some?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ListFilterInputTypeOfAccountingLineItemDtoFilterInput = {
  all?: InputMaybe<AccountingLineItemDtoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AccountingLineItemDtoFilterInput>;
  some?: InputMaybe<AccountingLineItemDtoFilterInput>;
};

export type ListFilterInputTypeOfActivityFeedCommentFilterInput = {
  all?: InputMaybe<ActivityFeedCommentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ActivityFeedCommentFilterInput>;
  some?: InputMaybe<ActivityFeedCommentFilterInput>;
};

export type ListFilterInputTypeOfActivityFeedImageFilterInput = {
  all?: InputMaybe<ActivityFeedImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ActivityFeedImageFilterInput>;
  some?: InputMaybe<ActivityFeedImageFilterInput>;
};

export type ListFilterInputTypeOfActivityFeedLikeFilterInput = {
  all?: InputMaybe<ActivityFeedLikeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ActivityFeedLikeFilterInput>;
  some?: InputMaybe<ActivityFeedLikeFilterInput>;
};

export type ListFilterInputTypeOfContactCardFilterInput = {
  all?: InputMaybe<ContactCardFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ContactCardFilterInput>;
  some?: InputMaybe<ContactCardFilterInput>;
};

export type ListFilterInputTypeOfContactPersonFilterInput = {
  all?: InputMaybe<ContactPersonFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ContactPersonFilterInput>;
  some?: InputMaybe<ContactPersonFilterInput>;
};

export type ListFilterInputTypeOfCustomerContactFilterInput = {
  all?: InputMaybe<CustomerContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CustomerContactFilterInput>;
  some?: InputMaybe<CustomerContactFilterInput>;
};

export type ListFilterInputTypeOfEstimateItemFilterInput = {
  all?: InputMaybe<EstimateItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EstimateItemFilterInput>;
  some?: InputMaybe<EstimateItemFilterInput>;
};

export type ListFilterInputTypeOfEstimateMeasurementFilterInput = {
  all?: InputMaybe<EstimateMeasurementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EstimateMeasurementFilterInput>;
  some?: InputMaybe<EstimateMeasurementFilterInput>;
};

export type ListFilterInputTypeOfEstimateQuoteOptionFilterInput = {
  all?: InputMaybe<EstimateQuoteOptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EstimateQuoteOptionFilterInput>;
  some?: InputMaybe<EstimateQuoteOptionFilterInput>;
};

export type ListFilterInputTypeOfEstimateSpecificationFilterInput = {
  all?: InputMaybe<EstimateSpecificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EstimateSpecificationFilterInput>;
  some?: InputMaybe<EstimateSpecificationFilterInput>;
};

export type ListFilterInputTypeOfEstimateSupplierFilterInput = {
  all?: InputMaybe<EstimateSupplierFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EstimateSupplierFilterInput>;
  some?: InputMaybe<EstimateSupplierFilterInput>;
};

export type ListFilterInputTypeOfEstimateViewFilterInput = {
  all?: InputMaybe<EstimateViewFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EstimateViewFilterInput>;
  some?: InputMaybe<EstimateViewFilterInput>;
};

export type ListFilterInputTypeOfIntegrationProviderRegionalRestrictionFilterInput = {
  all?: InputMaybe<IntegrationProviderRegionalRestrictionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<IntegrationProviderRegionalRestrictionFilterInput>;
  some?: InputMaybe<IntegrationProviderRegionalRestrictionFilterInput>;
};

export type ListFilterInputTypeOfJobCrewFilterInput = {
  all?: InputMaybe<JobCrewFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobCrewFilterInput>;
  some?: InputMaybe<JobCrewFilterInput>;
};

export type ListFilterInputTypeOfJobInvitationFilterInput = {
  all?: InputMaybe<JobInvitationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobInvitationFilterInput>;
  some?: InputMaybe<JobInvitationFilterInput>;
};

export type ListFilterInputTypeOfJobItemFilterInput = {
  all?: InputMaybe<JobItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobItemFilterInput>;
  some?: InputMaybe<JobItemFilterInput>;
};

export type ListFilterInputTypeOfJobPaymentFilterInput = {
  all?: InputMaybe<JobPaymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobPaymentFilterInput>;
  some?: InputMaybe<JobPaymentFilterInput>;
};

export type ListFilterInputTypeOfJobPaymentItemFilterInput = {
  all?: InputMaybe<JobPaymentItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobPaymentItemFilterInput>;
  some?: InputMaybe<JobPaymentItemFilterInput>;
};

export type ListFilterInputTypeOfJobPaymentPurchaseOrderItemFilterInput = {
  all?: InputMaybe<JobPaymentPurchaseOrderItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobPaymentPurchaseOrderItemFilterInput>;
  some?: InputMaybe<JobPaymentPurchaseOrderItemFilterInput>;
};

export type ListFilterInputTypeOfJobPaymentReceiptFilterInput = {
  all?: InputMaybe<JobPaymentReceiptFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobPaymentReceiptFilterInput>;
  some?: InputMaybe<JobPaymentReceiptFilterInput>;
};

export type ListFilterInputTypeOfJobVariationFilterInput = {
  all?: InputMaybe<JobVariationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobVariationFilterInput>;
  some?: InputMaybe<JobVariationFilterInput>;
};

export type ListFilterInputTypeOfJobVariationTaskFilterInput = {
  all?: InputMaybe<JobVariationTaskFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobVariationTaskFilterInput>;
  some?: InputMaybe<JobVariationTaskFilterInput>;
};

export type ListFilterInputTypeOfJobViewFilterInput = {
  all?: InputMaybe<JobViewFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<JobViewFilterInput>;
  some?: InputMaybe<JobViewFilterInput>;
};

export type ListFilterInputTypeOfPlanFilterInput = {
  all?: InputMaybe<PlanFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PlanFilterInput>;
  some?: InputMaybe<PlanFilterInput>;
};

export type ListFilterInputTypeOfPlanMeasurementFilterInput = {
  all?: InputMaybe<PlanMeasurementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PlanMeasurementFilterInput>;
  some?: InputMaybe<PlanMeasurementFilterInput>;
};

export type ListFilterInputTypeOfPublicHolidayFilterInput = {
  all?: InputMaybe<PublicHolidayFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PublicHolidayFilterInput>;
  some?: InputMaybe<PublicHolidayFilterInput>;
};

export type ListFilterInputTypeOfPurchaseOrderFilterInput = {
  all?: InputMaybe<PurchaseOrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PurchaseOrderFilterInput>;
  some?: InputMaybe<PurchaseOrderFilterInput>;
};

export type ListFilterInputTypeOfPurchaseOrderInvoiceFilterInput = {
  all?: InputMaybe<PurchaseOrderInvoiceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PurchaseOrderInvoiceFilterInput>;
  some?: InputMaybe<PurchaseOrderInvoiceFilterInput>;
};

export type ListFilterInputTypeOfPurchaseOrderInvoiceItemFilterInput = {
  all?: InputMaybe<PurchaseOrderInvoiceItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PurchaseOrderInvoiceItemFilterInput>;
  some?: InputMaybe<PurchaseOrderInvoiceItemFilterInput>;
};

export type ListFilterInputTypeOfPurchaseOrderItemFilterInput = {
  all?: InputMaybe<PurchaseOrderItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PurchaseOrderItemFilterInput>;
  some?: InputMaybe<PurchaseOrderItemFilterInput>;
};

export type ListFilterInputTypeOfQuoteRequestFilterInput = {
  all?: InputMaybe<QuoteRequestFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuoteRequestFilterInput>;
  some?: InputMaybe<QuoteRequestFilterInput>;
};

export type ListFilterInputTypeOfQuoteRequestGroupFilterInput = {
  all?: InputMaybe<QuoteRequestGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuoteRequestGroupFilterInput>;
  some?: InputMaybe<QuoteRequestGroupFilterInput>;
};

export type ListFilterInputTypeOfQuoteRequestItemFilterInput = {
  all?: InputMaybe<QuoteRequestItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuoteRequestItemFilterInput>;
  some?: InputMaybe<QuoteRequestItemFilterInput>;
};

export type ListFilterInputTypeOfQuoteRequestRecipeItemFilterInput = {
  all?: InputMaybe<QuoteRequestRecipeItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuoteRequestRecipeItemFilterInput>;
  some?: InputMaybe<QuoteRequestRecipeItemFilterInput>;
};

export type ListFilterInputTypeOfQuoteRequestRecipientFilterInput = {
  all?: InputMaybe<QuoteRequestRecipientFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuoteRequestRecipientFilterInput>;
  some?: InputMaybe<QuoteRequestRecipientFilterInput>;
};

export type ListFilterInputTypeOfQuoteRequestRecipientGroupFilterInput = {
  all?: InputMaybe<QuoteRequestRecipientGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuoteRequestRecipientGroupFilterInput>;
  some?: InputMaybe<QuoteRequestRecipientGroupFilterInput>;
};

export type ListFilterInputTypeOfQuoteRequestRecipientItemFilterInput = {
  all?: InputMaybe<QuoteRequestRecipientItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuoteRequestRecipientItemFilterInput>;
  some?: InputMaybe<QuoteRequestRecipientItemFilterInput>;
};

export type ListFilterInputTypeOfQuoteRequestRecipientRecipeItemFilterInput = {
  all?: InputMaybe<QuoteRequestRecipientRecipeItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuoteRequestRecipientRecipeItemFilterInput>;
  some?: InputMaybe<QuoteRequestRecipientRecipeItemFilterInput>;
};

export type ListFilterInputTypeOfRecipeItemFilterInput = {
  all?: InputMaybe<RecipeItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RecipeItemFilterInput>;
  some?: InputMaybe<RecipeItemFilterInput>;
};

export type ListFilterInputTypeOfScheduleTaskTemplateItemDependencyFilterInput = {
  all?: InputMaybe<ScheduleTaskTemplateItemDependencyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ScheduleTaskTemplateItemDependencyFilterInput>;
  some?: InputMaybe<ScheduleTaskTemplateItemDependencyFilterInput>;
};

export type ListFilterInputTypeOfScheduleTaskTemplateItemFilterInput = {
  all?: InputMaybe<ScheduleTaskTemplateItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ScheduleTaskTemplateItemFilterInput>;
  some?: InputMaybe<ScheduleTaskTemplateItemFilterInput>;
};

export type ListFilterInputTypeOfSpecificationItemModelFilterInput = {
  all?: InputMaybe<SpecificationItemModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SpecificationItemModelFilterInput>;
  some?: InputMaybe<SpecificationItemModelFilterInput>;
};

export type ListFilterInputTypeOfToDoAssigneeFilterInput = {
  all?: InputMaybe<ToDoAssigneeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ToDoAssigneeFilterInput>;
  some?: InputMaybe<ToDoAssigneeFilterInput>;
};

export type ListFilterInputTypeOfToDoItemAssigneeNotificationFilterInput = {
  all?: InputMaybe<ToDoItemAssigneeNotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ToDoItemAssigneeNotificationFilterInput>;
  some?: InputMaybe<ToDoItemAssigneeNotificationFilterInput>;
};

export type ListUuidOperationFilterInput = {
  all?: InputMaybe<UuidOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UuidOperationFilterInput>;
  some?: InputMaybe<UuidOperationFilterInput>;
};

export type ListedDateError = Error & {
  __typename?: 'ListedDateError';
  message: Scalars['String']['output'];
};

export type LoggedInUserModel = {
  __typename?: 'LoggedInUserModel';
  messagingUserId?: Maybe<Scalars['String']['output']>;
  messagingUserToken?: Maybe<Scalars['String']['output']>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type MarkAsSharedError = ActivityFeedStatusError | UnpublishedActivityFeedError | ValidationError;

export type MarkAsSharedInput = {
  feedId: Scalars['Uuid']['input'];
};

export type MarkAsSharedPayload = {
  __typename?: 'MarkAsSharedPayload';
  activityFeed?: Maybe<ActivityFeed>;
  errors?: Maybe<Array<MarkAsSharedError>>;
};

export type MarkJobAsCompleteError = ValidationError;

export type MarkJobAsCompleteInput = {
  jobId: Scalars['Uuid']['input'];
};

export type MarkJobAsCompletePayload = {
  __typename?: 'MarkJobAsCompletePayload';
  errors?: Maybe<Array<MarkJobAsCompleteError>>;
  job?: Maybe<Job>;
};

export type MarkLegacyMessagesAsReadInput = {
  messageIdList?: InputMaybe<MessageIdListDtoInput>;
};

export type MarkLegacyMessagesAsReadPayload = {
  __typename?: 'MarkLegacyMessagesAsReadPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type MatchCatalogueItemModelInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iD_CatalogueItem?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Recipe?: InputMaybe<Scalars['Uuid']['input']>;
  iD_RecipeItem?: InputMaybe<Scalars['Uuid']['input']>;
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  uOM?: InputMaybe<Scalars['String']['input']>;
};

export type MatchCatalogueItemResponseModel = {
  __typename?: 'MatchCatalogueItemResponseModel';
  catalogueItem?: Maybe<MatchedCatalogueItemModel>;
  iD_CatalogueItem?: Maybe<Scalars['Uuid']['output']>;
  recipeItem?: Maybe<MatchedRecipeItemModel>;
  tag?: Maybe<Scalars['String']['output']>;
};

export type MatchCatalogueItemsModelInput = {
  matchCatalogues?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  matchItems?: InputMaybe<Array<InputMaybe<MatchCatalogueItemModelInput>>>;
};

export type MatchedCatalogueItemModel = {
  __typename?: 'MatchedCatalogueItemModel';
  costItemType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_Catalogue?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueCategory?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueItem: Scalars['Uuid']['output'];
  iD_CatalogueSubCategory?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  itemPrice?: Maybe<Scalars['Decimal']['output']>;
  quantity?: Maybe<Scalars['Decimal']['output']>;
  supplierCode?: Maybe<Scalars['String']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  unitCost?: Maybe<Scalars['Decimal']['output']>;
};

export type MatchedRecipeItemModel = {
  __typename?: 'MatchedRecipeItemModel';
  actualQuantity: Scalars['Decimal']['output'];
  description?: Maybe<Scalars['String']['output']>;
  iD_CatalogueItem?: Maybe<Scalars['Uuid']['output']>;
  iD_ParentCatalogueItem?: Maybe<Scalars['Uuid']['output']>;
  iD_RecipeItem: Scalars['Uuid']['output'];
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  quantity?: Maybe<Scalars['Decimal']['output']>;
  supplierCode?: Maybe<Scalars['String']['output']>;
  totalCost: Scalars['Decimal']['output'];
  uOM?: Maybe<Scalars['String']['output']>;
  uOMMinOrderQty?: Maybe<Scalars['Decimal']['output']>;
  uOMOrderIncrements?: Maybe<Scalars['Decimal']['output']>;
  unitCost: Scalars['Decimal']['output'];
};

export type MaterialMetrics = {
  __typename?: 'MaterialMetrics';
  builders: Scalars['Decimal']['output'];
  description?: Maybe<Scalars['String']['output']>;
  jobs: Scalars['Decimal']['output'];
  location?: Maybe<Scalars['String']['output']>;
  total: Scalars['Decimal']['output'];
};

/** A connection to a list of items. */
export type MaterialMetricsConnection = {
  __typename?: 'MaterialMetricsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<MaterialMetricsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<MaterialMetrics>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MaterialMetricsEdge = {
  __typename?: 'MaterialMetricsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MaterialMetrics>;
};

export type MaterialMetricsFilterInput = {
  and?: InputMaybe<Array<MaterialMetricsFilterInput>>;
  builders?: InputMaybe<DecimalOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  jobs?: InputMaybe<DecimalOperationFilterInput>;
  location?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MaterialMetricsFilterInput>>;
  total?: InputMaybe<DecimalOperationFilterInput>;
};

export type MaterialMetricsSortInput = {
  builders?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  jobs?: InputMaybe<SortEnumType>;
  location?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
};

export type MaxLengthExceededError = Error & {
  __typename?: 'MaxLengthExceededError';
  maxlength: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
};

export type MessageDto = {
  __typename?: 'MessageDto';
  body?: Maybe<Scalars['String']['output']>;
  bodyRichText?: Maybe<Scalars['String']['output']>;
  emailAccountId?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  includeFooter?: Maybe<Scalars['Boolean']['output']>;
  references?: Maybe<Array<EmailMessageReferenceDto>>;
  signatureRichText?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type MessageDtoInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  bodyRichText?: InputMaybe<Scalars['String']['input']>;
  emailAccountId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  includeFooter?: InputMaybe<Scalars['Boolean']['input']>;
  references?: InputMaybe<Array<EmailMessageReferenceDtoInput>>;
  signatureRichText?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type MessageIdListDtoInput = {
  ids?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};

export type MessagingUserModel = {
  __typename?: 'MessagingUserModel';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type MobileAppForceUpgradeDto = {
  __typename?: 'MobileAppForceUpgradeDto';
  isForceUpgrade: Scalars['Boolean']['output'];
  mobileAppMinimumVersion?: Maybe<Scalars['String']['output']>;
};

export type MobileAppUpgradeCheckDtoInput = {
  appRuntimeVersion?: InputMaybe<Scalars['String']['input']>;
  deviceUniqueId?: InputMaybe<Scalars['String']['input']>;
};

export type MobileDeviceDtoInput = {
  appVersion: Scalars['String']['input'];
  deviceInfo?: InputMaybe<Scalars['String']['input']>;
  deviceName: Scalars['String']['input'];
  deviceUniqueId: Scalars['String']['input'];
  fcmToken: Scalars['String']['input'];
  isPhysicalDevice: Scalars['Boolean']['input'];
  platform: MobileDevicePlatformEnum;
};

export const MobileDevicePlatformEnum = {
  Android: 'ANDROID',
  Ios: 'IOS'
} as const;

export type MobileDevicePlatformEnum = typeof MobileDevicePlatformEnum[keyof typeof MobileDevicePlatformEnum];
export type MoveScheduleTaskError = InvalidMovement | InvalidTimeZoneId | ParentNotFound | ScheduleLockedError | ScheduleTaskNotFound;

export type MoveScheduleTaskInput = {
  model: ScheduleTaskMoveModelInput;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type MoveScheduleTaskPayload = {
  __typename?: 'MoveScheduleTaskPayload';
  errors?: Maybe<Array<MoveScheduleTaskError>>;
  scheduleTaskMoveResult?: Maybe<ScheduleTaskMoveResult>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptIntegrationConnection?: Maybe<IntegrationConnection>;
  acceptIntegrationConnections?: Maybe<Array<Maybe<IntegrationConnection>>>;
  acknowledgeClientConnections?: Maybe<Array<Maybe<IntegrationConnection>>>;
  addCatalogueAccessControlById?: Maybe<CatalogueAccessControl>;
  addCatalogueById?: Maybe<Catalogue>;
  addCatalogueCategoryById?: Maybe<CatalogueCategory>;
  addCatalogueItemById?: Maybe<CatalogueItem>;
  addCatalogueItemPriceById?: Maybe<CatalogueItemPrice>;
  addCatalogueSubCategoryById?: Maybe<CatalogueSubCategory>;
  addEstimate?: Maybe<Estimate>;
  addEstimateSpecification?: Maybe<EstimateSpecificationModel>;
  addImportedInvoiceMapping: AddImportedInvoiceMappingPayload;
  addLead?: Maybe<LeadModel>;
  addNonWorkingDay: AddNonWorkingDayPayload;
  addOrUpdateUserQuickStartAreaSettings: Scalars['Int']['output'];
  addPlanMeasurements?: Maybe<Array<Maybe<PlanMeasurement>>>;
  addRecipeItemById?: Maybe<RecipeItem>;
  addScheduleTaskContact: AddScheduleTaskContactPayload;
  addSpecificationPreset?: Maybe<SpecificationModel>;
  addSpecificationPresetV3: AddSpecificationPresetV3Payload;
  addTenant?: Maybe<Tenant>;
  addUser: Scalars['String']['output'];
  applyScheduleTemplate: ApplyScheduleTemplatePayload;
  bulkUpsertEstimateMeasurements?: Maybe<Array<Maybe<EstimateMeasurement>>>;
  cloneRecipeCatalogueItem?: Maybe<CatalogueItem>;
  convertEstimateToTemplate: ConvertEstimateToTemplatePayload;
  copyAttachment: CopyAttachmentPayload;
  copyAttachmentsFromExternalInvoices: CopyAttachmentsFromExternalInvoicesPayload;
  copyItemsToEstimateTemplate?: Maybe<Estimate>;
  copyJob: Job;
  copyLayouts: Scalars['Boolean']['output'];
  copyTemplateCategoriesToEstimate: Scalars['Boolean']['output'];
  createActivityFeed: CreateActivityFeedPayload;
  createActivityFeedComment: CreateActivityFeedCommentPayload;
  createBuildingTypes: CreateBuildingTypesPayload;
  createClientsCustomer?: Maybe<Customer>;
  createConversation?: Maybe<Scalars['String']['output']>;
  createCustomer?: Maybe<Scalars['String']['output']>;
  createEmailSender: CreateEmailSenderPayload;
  createEstimateFromSnapshot: CreateEstimateFromSnapshotPayload;
  createEstimateSpecificationCategoryV3: CreateEstimateSpecificationCategoryV3Payload;
  createEstimateSpecificationItemV3: CreateEstimateSpecificationItemV3Payload;
  createIntegrationProvider?: Maybe<IntegrationProvider>;
  createJob: Job;
  createNote: CreateNotePayload;
  createOnboardingRecord: Scalars['Uuid']['output'];
  createPurchaseOrder: CreatePurchaseOrderPayload;
  createScheduleTask: CreateScheduleTaskPayload;
  createScheduleTaskDependencies: CreateScheduleTaskDependenciesPayload;
  createScheduleTemplate: CreateScheduleTemplatePayload;
  createShare: Scalars['Boolean']['output'];
  createSmartEstimateSession: CreateSmartEstimateSessionPayload;
  createSnapshot: CreateSnapshotPayload;
  createSupplierQuoteForEstimateItems: CreateSupplierQuoteForEstimateItemsPayload;
  createTakeOffToolSession: CreateTakeOffToolSessionPayload;
  createToDoAssignees: CreateToDoAssigneesPayload;
  createToDoItems: CreateToDoItemsPayload;
  createWebhook: CreateWebhookPayload;
  deleteActivityFeed: DeleteActivityFeedPayload;
  deleteAllScheduleTasks: DeleteAllScheduleTasksPayload;
  deleteCatalogueAccessControlById?: Maybe<CatalogueAccessControl>;
  deleteCatalogueById: Scalars['Boolean']['output'];
  deleteCatalogueCategoryById: Scalars['Boolean']['output'];
  deleteCatalogueItemById?: Maybe<CatalogueItem>;
  deleteCatalogueItemPriceById: Scalars['Boolean']['output'];
  deleteEmailSender: DeleteEmailSenderPayload;
  deleteEmails: DeleteEmailsPayload;
  deleteEstimate: Scalars['Boolean']['output'];
  deleteEstimateAccessControl?: Maybe<EstimateAccessControl>;
  deleteEstimateSpecificationCategory?: Maybe<DeleteEstimateSpecificationCategoryCollectionSegment>;
  deleteEstimateSpecificationCategoryV3: DeleteEstimateSpecificationCategoryV3Payload;
  deleteEstimateSpecificationItem?: Maybe<DeleteEstimateSpecificationItemCollectionSegment>;
  deleteJobCrew: DeleteJobCrewPayload;
  deleteJobInvitation: Scalars['Boolean']['output'];
  deleteLayout: Scalars['Boolean']['output'];
  deleteLayoutSelection: Scalars['Boolean']['output'];
  deleteLead: Scalars['Boolean']['output'];
  deleteLeadCoverImage: Scalars['Boolean']['output'];
  deleteLegacyMessages: DeleteLegacyMessagesPayload;
  deleteNonWorkingDay: DeleteNonWorkingDayPayload;
  deleteNote: DeleteNotePayload;
  deleteNotice: Scalars['Boolean']['output'];
  deleteNoticesForObject: Scalars['Boolean']['output'];
  deletePlan: Scalars['Boolean']['output'];
  deletePlanMeasurements: Scalars['Boolean']['output'];
  deletePlans: Scalars['Boolean']['output'];
  deleteQuoteRequest: Scalars['Boolean']['output'];
  deleteQuoteRequestRecipient: Scalars['Boolean']['output'];
  deleteRecipeItemById: Scalars['Boolean']['output'];
  deleteRecipeItemsById: Scalars['Boolean']['output'];
  deleteScheduleTask: DeleteScheduleTaskPayload;
  deleteScheduleTaskContact: DeleteScheduleTaskContactPayload;
  deleteScheduleTaskDependencies: DeleteScheduleTaskDependenciesPayload;
  deleteScheduleTemplate: DeleteScheduleTemplatePayload;
  deleteShare: Scalars['Boolean']['output'];
  deleteSnapshot: DeleteSnapshotPayload;
  deleteToDoAssignees: DeleteToDoAssigneesPayload;
  deleteToDoItems: DeleteToDoItemsPayload;
  deleteUser: Scalars['Boolean']['output'];
  deleteWebhook: DeleteWebhookPayload;
  digitallySignShareByRecipientId?: Maybe<ShareDto>;
  digitallySignShareViaItem?: Maybe<ShareDto>;
  disconnectErpIntegrationConnection: IntegrationConnectionModel;
  disconnectIntegrationConnection?: Maybe<IntegrationConnection>;
  duplicateScheduleTask: DuplicateScheduleTaskPayload;
  editScheduleTemplate: EditScheduleTemplatePayload;
  exportCatalogueToExcel?: Maybe<Scalars['String']['output']>;
  exportDataSet?: Maybe<Scalars['String']['output']>;
  exportPdf?: Maybe<ExportPdfResponse>;
  generateRecipe?: Maybe<GeneratedRecipeStartModel>;
  generateRecipeAdded: Scalars['Boolean']['output'];
  generateSmartEstimate: GenerateSmartEstimatePayload;
  generateSmartParameters: GenerateSmartParametersPayload;
  grantCatalogueAccessControlById?: Maybe<CatalogueAccessControl>;
  grantCatalogueExportById?: Maybe<Catalogue>;
  grantEstimateToTemplateAccessForConnection?: Maybe<EstimateAccessControl>;
  importCatalogueItems?: Maybe<CatalogueImport>;
  importSamplePlan?: Maybe<Array<Maybe<Plan>>>;
  initiateErpSyncOnDemand: SyncNowReponseModel;
  inviteClient?: Maybe<IntegrationConnection>;
  inviteSupplier?: Maybe<IntegrationConnection>;
  linkIntegrationConnections?: Maybe<Array<Maybe<IntegrationConnection>>>;
  markAllNoticesReadForObject?: Maybe<Array<Maybe<NoticeDto>>>;
  markAsShared: MarkAsSharedPayload;
  markJobAsComplete: MarkJobAsCompletePayload;
  markLegacyMessagesAsRead: MarkLegacyMessagesAsReadPayload;
  markNoticeRead?: Maybe<NoticeDto>;
  markNoticeUnread?: Maybe<NoticeDto>;
  markShareAsViewed?: Maybe<ShareDto>;
  markShareAsViewedByRecipientId?: Maybe<ShareDto>;
  moveScheduleTask: MoveScheduleTaskPayload;
  muteUserMfaWarning?: Maybe<Scalars['DateTime']['output']>;
  newInvoiceNumber?: Maybe<Scalars['Int']['output']>;
  newPurchaseOrderNumber?: Maybe<Scalars['Int']['output']>;
  newUnsubscribedSubscriptionByCatalogueId?: Maybe<CatalogueSubscription>;
  openTakeOffToolSession: OpenTakeOffToolSessionPayload;
  overwriteEstimateSpecificationWithPreset?: Maybe<OverwriteEstimateSpecificationWithPresetCollectionSegment>;
  overwriteEstimateSpecificationWithPresetV3: OverwriteEstimateSpecificationWithPresetV3Payload;
  patchEstimate?: Maybe<Estimate>;
  patchEstimateSpecificationV3: PatchEstimateSpecificationV3Payload;
  postSms: PostSmsPayload;
  previewLeadProject?: Maybe<AddBuilderToClientProjectResponse>;
  publishLeadToClientPortal: Scalars['Boolean']['output'];
  reassignIntegrationConnection?: Maybe<IntegrationConnection>;
  reassignIntegrationConnections?: Maybe<Array<Maybe<IntegrationConnection>>>;
  recordEvent: Scalars['Uuid']['output'];
  recoverCatalogueAccessControlById?: Maybe<CatalogueAccessControl>;
  recoverCatalogueById: Scalars['Boolean']['output'];
  recoverCatalogueItemById?: Maybe<CatalogueItem>;
  recoverEstimate: Scalars['Boolean']['output'];
  registerUserDevice: RegisterUserDevicePayload;
  rejectIntegrationConnection?: Maybe<IntegrationConnection>;
  rejectIntegrationConnections?: Maybe<Array<Maybe<IntegrationConnection>>>;
  removeBuildingTypes: RemoveBuildingTypesPayload;
  reorderEstimateSpecifications?: Maybe<ReorderEstimateSpecificationsCollectionSegment>;
  reorderEstimateSpecificationsCategoryV3: ReorderEstimateSpecificationsCategoryV3Payload;
  requestIntegrationConnection?: Maybe<IntegrationConnection>;
  requestIntegrationConnectionV2?: Maybe<IntegrationConnection>;
  requestLatestPrices: RequestLatestPricesResult;
  requestQuoteRequestDeadlineExtension: Scalars['Boolean']['output'];
  resendEmailSenderConfirmation: ResendEmailSenderConfirmationPayload;
  resetSectionTemplateToDefault?: Maybe<SectionTemplateDto>;
  resetTenantPostExpiry: Scalars['Boolean']['output'];
  respondToJobInvitation: JobInvitationDto;
  restoreEstimateAccessControl?: Maybe<EstimateAccessControl>;
  restoreLead: Scalars['Boolean']['output'];
  revokeCatalogueAccessControlById?: Maybe<CatalogueAccessControl>;
  revokeCatalogueExportById?: Maybe<Catalogue>;
  revokeEstimateFromTemplateAccessForConnection?: Maybe<EstimateAccessControl>;
  revokeIntegrationConnection?: Maybe<IntegrationConnection>;
  rotatePlan?: Maybe<Plan>;
  saveErpTenantSetting: IntegrationConnectionModel;
  saveLayout?: Maybe<LayoutDto>;
  saveLayoutOrder: Scalars['Boolean']['output'];
  saveLayoutSelection?: Maybe<LayoutSelectionDto>;
  saveSection?: Maybe<SectionDto>;
  saveSectionSettings?: Maybe<SectionSettingDto>;
  saveTemplateSelection: Scalars['Boolean']['output'];
  scalePlans: Scalars['Boolean']['output'];
  selectEstimateSupplierStore: SelectEstimateSupplierStorePayload;
  sendEmail: SendEmailPayload;
  sendJobInvitation: SendJobInvitationPayload;
  sendTakeOffToolQuoteRequest: SendTakeOffToolQuoteRequestPayload;
  sendTaskReminder: SendTaskReminderPayload;
  sendVerificationCode: SendVerificationCodeResponse;
  sendVerificationCodeToSpecifiedNumber: SendVerificationCodeToSpecifiedNumberPayload;
  setActivityFeedLike: SetActivityFeedLikePayload;
  setQuoteRequestRecipientAsDeclined: Scalars['Boolean']['output'];
  setQuoteRequestRecipientAsPending: Scalars['Boolean']['output'];
  setQuoteRequestRecipientAsQuoting: Scalars['Boolean']['output'];
  setUserAnalyticFilterOptions: Scalars['Boolean']['output'];
  setUserRole: Scalars['String']['output'];
  shareActivityFeed: ShareActivityFeedPayload;
  shareLeadWithClient: Scalars['Boolean']['output'];
  shareSpecificationsToClientPortal: ShareSpecificationsToClientPortalPayload;
  startCatalogueImport?: Maybe<CatalogueImport>;
  startCatalogueSync: Scalars['Boolean']['output'];
  startEstimateImport?: Maybe<EstimateImport>;
  submitRFQResponse?: Maybe<RfqSubmitResponseModel>;
  subscribeToCatalogueById?: Maybe<CatalogueSubscription>;
  subscribeToEstimateTemplate?: Maybe<EstimateTemplateSubscription>;
  syncEstimateCategorySpecifications: SyncEstimateCategorySpecificationsPayload;
  syncEstimateDocumentsToRFQFiles: Scalars['Boolean']['output'];
  syncExternalInvoicePayments: SyncExternalInvoicePaymentsPayload;
  syncRFQFilesToEstimateDocuments: Scalars['Boolean']['output'];
  syncScheduleWithEstimate: SyncScheduleWithEstimatePayload;
  unsubscribeFromCatalogueById?: Maybe<CatalogueSubscription>;
  updateAccountingSyncSetting: AccountingSyncSettingType;
  updateActivityFeed: UpdateActivityFeedPayload;
  updateAllScheduleTaskProgress: UpdateAllScheduleTaskProgressPayload;
  updateAvatarImage: UserAvatarDto;
  updateBuildingTypes: UpdateBuildingTypesPayload;
  updateCatalogueById?: Maybe<Catalogue>;
  updateCatalogueCategoryById?: Maybe<CatalogueCategory>;
  updateCatalogueItemById?: Maybe<CatalogueItem>;
  updateCatalogueItemPriceById?: Maybe<CatalogueItemPrice>;
  updateCatalogueItemPrices: Scalars['Boolean']['output'];
  updateCatalogueItemsCategories?: Maybe<Array<Maybe<CatalogueCategoryItem>>>;
  updateCatalogueItemsSubCategories?: Maybe<Array<Maybe<CatalogueCategoryItem>>>;
  updateCatalogueSubCategoryById?: Maybe<CatalogueSubCategory>;
  updateClientsCustomer: Scalars['Boolean']['output'];
  updateCustomerIntegrationConnections?: Maybe<CustomerIntegrationConnection>;
  updateEmailSender: UpdateEmailSenderPayload;
  updateEstimateAvailability?: Maybe<Estimate>;
  updateEstimateCustomer: Scalars['Boolean']['output'];
  updateEstimateCustomerDetails: Scalars['Boolean']['output'];
  updateEstimateDetails: Scalars['Boolean']['output'];
  updateEstimateSpecificationCategory?: Maybe<UpdateEstimateSpecificationCategoryCollectionSegment>;
  updateEstimateSpecificationCategoryNameV3: UpdateEstimateSpecificationCategoryNameV3Payload;
  updateEstimateSpecificationItem?: Maybe<EstimateSpecificationModel>;
  updateIntegrationConnectionAccountManager?: Maybe<IntegrationConnection>;
  updateIntegrationConnectionCatalogueAutoSubscribe?: Maybe<IntegrationConnection>;
  updateIntegrationConnectionExternalRef?: Maybe<IntegrationConnection>;
  updateIntegrationConnectionExternalRefV2?: Maybe<IntegrationConnection>;
  updateIntegrationConnectionNotification?: Maybe<IntegrationConnection>;
  updateIntegrationConnectionPermissions?: Maybe<IntegrationConnection>;
  updateIntegrationConnectionQuoteRequestEmail?: Maybe<IntegrationConnection>;
  updateIntegrationProviderById?: Maybe<IntegrationProvider>;
  updateInvoiceTermsAndConditions?: Maybe<JobPaymentSetting>;
  updateJobCustomer: Scalars['Boolean']['output'];
  updateJobCustomerDetails: Scalars['Boolean']['output'];
  updateLead?: Maybe<LeadModel>;
  updateLeadProjectName: Scalars['Boolean']['output'];
  updateOnboardSettings: Scalars['Boolean']['output'];
  updateOrderTermsAndConditions?: Maybe<PurchaseOrderSetting>;
  updatePlanMeasurements?: Maybe<Array<Maybe<PlanMeasurement>>>;
  updateQuoteRequestRecipient: Scalars['Decimal']['output'];
  updateQuoteRequestRecipientOpenDate: Scalars['Boolean']['output'];
  updateQuoteRequestRecipientV3?: Maybe<QuoteRequestRecipient>;
  updateRecipeCostsFromCatalogue: Scalars['Boolean']['output'];
  updateRecipeItemById?: Maybe<RecipeItem>;
  updateScheduleStartDate: UpdateScheduleStartDatePayload;
  updateScheduleTask: UpdateScheduleTaskPayload;
  updateSnapshotDescription: UpdateSnapshotDescriptionPayload;
  updateSpecificationPreset?: Maybe<SpecificationModel>;
  updateThumbnailForLayout: Scalars['Boolean']['output'];
  updateToDoItemStatuses: UpdateToDoItemStatusesPayload;
  updateToDoItems: UpdateToDoItemsPayload;
  updateWebhook: UpdateWebhookPayload;
  uploadFileToImagePlaceholder: UploadFileToImagePlaceholderPayload;
  upsertEstimateMeasurement?: Maybe<EstimateMeasurement>;
  userToggleIsMobileVisibleToOthers: Scalars['Boolean']['output'];
  verifyOtpCode: OtpVerifyResultEnum;
};


export type MutationAcceptIntegrationConnectionArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
};


export type MutationAcceptIntegrationConnectionsArgs = {
  integrationConnectionIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};


export type MutationAcknowledgeClientConnectionsArgs = {
  connections?: InputMaybe<Array<InputMaybe<ClientConnectionIdStatusInput>>>;
};


export type MutationAddCatalogueAccessControlByIdArgs = {
  model?: InputMaybe<AddCatalogueAccessControlModelInput>;
};


export type MutationAddCatalogueByIdArgs = {
  model?: InputMaybe<AddCatalogueModelInput>;
};


export type MutationAddCatalogueCategoryByIdArgs = {
  model?: InputMaybe<AddCatalogueCategoryModelInput>;
};


export type MutationAddCatalogueItemByIdArgs = {
  model?: InputMaybe<AddCatalogueItemModelInput>;
};


export type MutationAddCatalogueItemPriceByIdArgs = {
  model?: InputMaybe<AddCatalogueItemPriceModelInput>;
};


export type MutationAddCatalogueSubCategoryByIdArgs = {
  model?: InputMaybe<AddCatalogueSubCategoryModelInput>;
};


export type MutationAddEstimateArgs = {
  model?: InputMaybe<CreateEstimateModelInput>;
};


export type MutationAddEstimateSpecificationArgs = {
  model?: InputMaybe<CreateEstimateSpecificationModelInput>;
};


export type MutationAddImportedInvoiceMappingArgs = {
  input: AddImportedInvoiceMappingInput;
};


export type MutationAddLeadArgs = {
  autoPublishToClientPortal?: InputMaybe<Scalars['Boolean']['input']>;
  lead?: InputMaybe<LeadModelInput>;
};


export type MutationAddNonWorkingDayArgs = {
  input: AddNonWorkingDayInput;
};


export type MutationAddOrUpdateUserQuickStartAreaSettingsArgs = {
  settings: Array<UserQuickStartAreaSettingUpdateInput>;
};


export type MutationAddPlanMeasurementsArgs = {
  model?: InputMaybe<Array<InputMaybe<AddPlanMeasurementModelInput>>>;
};


export type MutationAddRecipeItemByIdArgs = {
  model?: InputMaybe<AddRecipeItemModelInput>;
};


export type MutationAddScheduleTaskContactArgs = {
  input: AddScheduleTaskContactInput;
};


export type MutationAddSpecificationPresetArgs = {
  model?: InputMaybe<CreateSpecificationPresetInput>;
};


export type MutationAddSpecificationPresetV3Args = {
  input: AddSpecificationPresetV3Input;
};


export type MutationAddTenantArgs = {
  model: NewTenantRequestInput;
};


export type MutationAddUserArgs = {
  model: NewUserRequestInput;
};


export type MutationApplyScheduleTemplateArgs = {
  input: ApplyScheduleTemplateInput;
};


export type MutationBulkUpsertEstimateMeasurementsArgs = {
  estimateId: Scalars['Uuid']['input'];
  models?: InputMaybe<Array<InputMaybe<CreateEstimateMeasurementModelInput>>>;
};


export type MutationCloneRecipeCatalogueItemArgs = {
  catalogueItemId: Scalars['Uuid']['input'];
  ownCatalogueId: Scalars['Uuid']['input'];
};


export type MutationConvertEstimateToTemplateArgs = {
  input: ConvertEstimateToTemplateInput;
};


export type MutationCopyAttachmentArgs = {
  input: CopyAttachmentInput;
};


export type MutationCopyAttachmentsFromExternalInvoicesArgs = {
  input: CopyAttachmentsFromExternalInvoicesInput;
};


export type MutationCopyItemsToEstimateTemplateArgs = {
  model?: InputMaybe<CopyItemsToTemplateModelInput>;
};


export type MutationCopyJobArgs = {
  jobId: Scalars['Uuid']['input'];
  options: JobCopyOptionsInput;
};


export type MutationCopyLayoutsArgs = {
  sourceReferenceObjectId: Scalars['Uuid']['input'];
  targetReferenceObjectId: Scalars['Uuid']['input'];
  targetReferenceObjectName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCopyTemplateCategoriesToEstimateArgs = {
  model?: InputMaybe<CopyTemplateCategoriesToEstimateModelInput>;
};


export type MutationCreateActivityFeedArgs = {
  input: CreateActivityFeedInput;
};


export type MutationCreateActivityFeedCommentArgs = {
  input: CreateActivityFeedCommentInput;
};


export type MutationCreateBuildingTypesArgs = {
  input: CreateBuildingTypesInput;
};


export type MutationCreateClientsCustomerArgs = {
  createOptions?: InputMaybe<CreateCustomerOptionsInput>;
};


export type MutationCreateConversationArgs = {
  leadId: Scalars['Uuid']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCustomerArgs = {
  customer?: InputMaybe<CustomerDtoModelInput>;
};


export type MutationCreateEmailSenderArgs = {
  input: CreateEmailSenderInput;
};


export type MutationCreateEstimateFromSnapshotArgs = {
  createEstimateFromSnapshot?: InputMaybe<CreateEstimateFromSnapshotInput>;
};


export type MutationCreateEstimateSpecificationCategoryV3Args = {
  input: CreateEstimateSpecificationCategoryV3Input;
};


export type MutationCreateEstimateSpecificationItemV3Args = {
  input: CreateEstimateSpecificationItemV3Input;
};


export type MutationCreateIntegrationProviderArgs = {
  model?: InputMaybe<AddIntegrationProviderModelInput>;
};


export type MutationCreateJobArgs = {
  options: JobCreateOptionsInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateOnboardingRecordArgs = {
  createOptions?: InputMaybe<OnboardingRecordCreateOptionsInput>;
};


export type MutationCreatePurchaseOrderArgs = {
  input: CreatePurchaseOrderInput;
};


export type MutationCreateScheduleTaskArgs = {
  input: CreateScheduleTaskInput;
};


export type MutationCreateScheduleTaskDependenciesArgs = {
  input: CreateScheduleTaskDependenciesInput;
};


export type MutationCreateScheduleTemplateArgs = {
  input: CreateScheduleTemplateInput;
};


export type MutationCreateShareArgs = {
  isGenericShare?: Scalars['Boolean']['input'];
  share: CreateShareDtoInput;
};


export type MutationCreateSmartEstimateSessionArgs = {
  input: CreateSmartEstimateSessionInput;
};


export type MutationCreateSnapshotArgs = {
  createEstimateSnapshot?: InputMaybe<CreateSnapshotInput>;
};


export type MutationCreateSupplierQuoteForEstimateItemsArgs = {
  input: CreateSupplierQuoteForEstimateItemsInput;
};


export type MutationCreateTakeOffToolSessionArgs = {
  input: CreateTakeOffToolSessionInput;
};


export type MutationCreateToDoAssigneesArgs = {
  input: CreateToDoAssigneesInput;
};


export type MutationCreateToDoItemsArgs = {
  input: CreateToDoItemsInput;
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationDeleteActivityFeedArgs = {
  input: DeleteActivityFeedInput;
};


export type MutationDeleteAllScheduleTasksArgs = {
  input: DeleteAllScheduleTasksInput;
};


export type MutationDeleteCatalogueAccessControlByIdArgs = {
  catalogueAccessControlId: Scalars['Uuid']['input'];
};


export type MutationDeleteCatalogueByIdArgs = {
  catalogueId: Scalars['Uuid']['input'];
};


export type MutationDeleteCatalogueCategoryByIdArgs = {
  catalogueCategoryId: Scalars['Uuid']['input'];
};


export type MutationDeleteCatalogueItemByIdArgs = {
  catalogueItemId: Scalars['Uuid']['input'];
};


export type MutationDeleteCatalogueItemPriceByIdArgs = {
  catalogueItemPriceId: Scalars['Uuid']['input'];
};


export type MutationDeleteEmailSenderArgs = {
  input: DeleteEmailSenderInput;
};


export type MutationDeleteEmailsArgs = {
  input: DeleteEmailsInput;
};


export type MutationDeleteEstimateArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type MutationDeleteEstimateAccessControlArgs = {
  estimateAccessControlId: Scalars['Uuid']['input'];
};


export type MutationDeleteEstimateSpecificationCategoryArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  estimateId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<EstimateSpecificationModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateSpecificationModelFilterInput>;
};


export type MutationDeleteEstimateSpecificationCategoryV3Args = {
  input: DeleteEstimateSpecificationCategoryV3Input;
};


export type MutationDeleteEstimateSpecificationItemArgs = {
  estimateSpecificationItemId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<EstimateSpecificationModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateSpecificationModelFilterInput>;
};


export type MutationDeleteJobCrewArgs = {
  input: DeleteJobCrewInput;
};


export type MutationDeleteJobInvitationArgs = {
  jobInvitationId: Scalars['Uuid']['input'];
};


export type MutationDeleteLayoutArgs = {
  layoutId: Scalars['Uuid']['input'];
};


export type MutationDeleteLayoutSelectionArgs = {
  referenceObjectId: Scalars['Uuid']['input'];
};


export type MutationDeleteLeadArgs = {
  leadId: Scalars['Uuid']['input'];
};


export type MutationDeleteLeadCoverImageArgs = {
  leadId: Scalars['Uuid']['input'];
};


export type MutationDeleteLegacyMessagesArgs = {
  input: DeleteLegacyMessagesInput;
};


export type MutationDeleteNonWorkingDayArgs = {
  input: DeleteNonWorkingDayInput;
};


export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};


export type MutationDeleteNoticeArgs = {
  noticeId: Scalars['Uuid']['input'];
};


export type MutationDeleteNoticesForObjectArgs = {
  objectId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeletePlanArgs = {
  planId: Scalars['Uuid']['input'];
  referenceId: Scalars['Uuid']['input'];
};


export type MutationDeletePlanMeasurementsArgs = {
  planMeasurementIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};


export type MutationDeletePlansArgs = {
  referenceId: Scalars['Uuid']['input'];
};


export type MutationDeleteQuoteRequestArgs = {
  quoteRequestId: Scalars['Uuid']['input'];
};


export type MutationDeleteQuoteRequestRecipientArgs = {
  quoteRequestRecipientId: Scalars['Uuid']['input'];
};


export type MutationDeleteRecipeItemByIdArgs = {
  recipeItemId: Scalars['Uuid']['input'];
};


export type MutationDeleteRecipeItemsByIdArgs = {
  recipeItemIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};


export type MutationDeleteScheduleTaskArgs = {
  input: DeleteScheduleTaskInput;
};


export type MutationDeleteScheduleTaskContactArgs = {
  input: DeleteScheduleTaskContactInput;
};


export type MutationDeleteScheduleTaskDependenciesArgs = {
  input: DeleteScheduleTaskDependenciesInput;
};


export type MutationDeleteScheduleTemplateArgs = {
  input: DeleteScheduleTemplateInput;
};


export type MutationDeleteShareArgs = {
  deleteShareItems: Array<DeleteShareItemDtoInput>;
};


export type MutationDeleteSnapshotArgs = {
  deleteSnapshot?: InputMaybe<DeleteSnapshotInput>;
};


export type MutationDeleteToDoAssigneesArgs = {
  input: DeleteToDoAssigneesInput;
};


export type MutationDeleteToDoItemsArgs = {
  input: DeleteToDoItemsInput;
};


export type MutationDeleteUserArgs = {
  userIdToDelete: Scalars['Uuid']['input'];
};


export type MutationDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};


export type MutationDigitallySignShareByRecipientIdArgs = {
  recipientId: Scalars['Uuid']['input'];
  shareItemUri: Scalars['String']['input'];
};


export type MutationDigitallySignShareViaItemArgs = {
  shareItemUri: Scalars['String']['input'];
};


export type MutationDisconnectIntegrationConnectionArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
};


export type MutationDuplicateScheduleTaskArgs = {
  input: DuplicateScheduleTaskInput;
};


export type MutationEditScheduleTemplateArgs = {
  input: EditScheduleTemplateInput;
};


export type MutationExportCatalogueToExcelArgs = {
  catalogueId: Scalars['Uuid']['input'];
};


export type MutationExportDataSetArgs = {
  dataSet: AnalyticsDataSet;
};


export type MutationExportPdfArgs = {
  exportPdfRequest?: InputMaybe<ExportPdfRequestInput>;
};


export type MutationGenerateRecipeArgs = {
  model?: InputMaybe<GenerateRecipeModelInput>;
};


export type MutationGenerateRecipeAddedArgs = {
  model?: InputMaybe<GenerateRecipeAddedModelInput>;
};


export type MutationGenerateSmartEstimateArgs = {
  input: GenerateSmartEstimateInput;
};


export type MutationGenerateSmartParametersArgs = {
  input: GenerateSmartParametersInput;
};


export type MutationGrantCatalogueAccessControlByIdArgs = {
  model?: InputMaybe<GrantCatalogueAccessControlModelInput>;
};


export type MutationGrantCatalogueExportByIdArgs = {
  catalogueId: Scalars['Uuid']['input'];
};


export type MutationGrantEstimateToTemplateAccessForConnectionArgs = {
  model?: InputMaybe<ToggleEstimateTemplateAccessModelInput>;
};


export type MutationImportCatalogueItemsArgs = {
  batchId: Scalars['Uuid']['input'];
  items?: InputMaybe<Array<InputMaybe<ImportCatalogueItemModelInput>>>;
};


export type MutationImportSamplePlanArgs = {
  estimateId: Scalars['Uuid']['input'];
  scaleUnit?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInviteClientArgs = {
  isManufacturer?: Scalars['Boolean']['input'];
  model?: InputMaybe<InviteClientModelInput>;
};


export type MutationInviteSupplierArgs = {
  isSupplier?: Scalars['Boolean']['input'];
  model?: InputMaybe<InviteSupplierModelInput>;
};


export type MutationMarkAllNoticesReadForObjectArgs = {
  objectId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMarkAsSharedArgs = {
  input: MarkAsSharedInput;
};


export type MutationMarkJobAsCompleteArgs = {
  input: MarkJobAsCompleteInput;
};


export type MutationMarkLegacyMessagesAsReadArgs = {
  input: MarkLegacyMessagesAsReadInput;
};


export type MutationMarkNoticeReadArgs = {
  noticeId: Scalars['Uuid']['input'];
};


export type MutationMarkNoticeUnreadArgs = {
  noticeId: Scalars['Uuid']['input'];
};


export type MutationMarkShareAsViewedArgs = {
  shareItemUri: Scalars['String']['input'];
};


export type MutationMarkShareAsViewedByRecipientIdArgs = {
  recipientId: Scalars['Uuid']['input'];
  shareItemUri: Scalars['String']['input'];
};


export type MutationMoveScheduleTaskArgs = {
  input: MoveScheduleTaskInput;
};


export type MutationNewUnsubscribedSubscriptionByCatalogueIdArgs = {
  model?: InputMaybe<SubscribeToCatalogueModelInput>;
};


export type MutationOpenTakeOffToolSessionArgs = {
  input: OpenTakeOffToolSessionInput;
};


export type MutationOverwriteEstimateSpecificationWithPresetArgs = {
  estimateId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<EstimateSpecificationModelSortInput>>;
  presetId: Scalars['Uuid']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateSpecificationModelFilterInput>;
};


export type MutationOverwriteEstimateSpecificationWithPresetV3Args = {
  input: OverwriteEstimateSpecificationWithPresetV3Input;
};


export type MutationPatchEstimateArgs = {
  model?: InputMaybe<PatchEstimateModelInput>;
};


export type MutationPatchEstimateSpecificationV3Args = {
  input: PatchEstimateSpecificationV3Input;
};


export type MutationPostSmsArgs = {
  input: PostSmsInput;
};


export type MutationPreviewLeadProjectArgs = {
  leadId: Scalars['Uuid']['input'];
};


export type MutationPublishLeadToClientPortalArgs = {
  leadId: Scalars['Uuid']['input'];
  messageBody?: InputMaybe<Scalars['String']['input']>;
  messageSubject?: InputMaybe<Scalars['String']['input']>;
  projectName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReassignIntegrationConnectionArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
  targetTenantId: Scalars['Uuid']['input'];
};


export type MutationReassignIntegrationConnectionsArgs = {
  integrationConnectionIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  targetTenantId: Scalars['Uuid']['input'];
};


export type MutationRecordEventArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRecoverCatalogueAccessControlByIdArgs = {
  catalogueAccessControlId: Scalars['Uuid']['input'];
};


export type MutationRecoverCatalogueByIdArgs = {
  catalogueId: Scalars['Uuid']['input'];
};


export type MutationRecoverCatalogueItemByIdArgs = {
  catalogueItemId: Scalars['Uuid']['input'];
};


export type MutationRecoverEstimateArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type MutationRegisterUserDeviceArgs = {
  input: RegisterUserDeviceInput;
};


export type MutationRejectIntegrationConnectionArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
};


export type MutationRejectIntegrationConnectionsArgs = {
  integrationConnectionIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};


export type MutationRemoveBuildingTypesArgs = {
  input: RemoveBuildingTypesInput;
};


export type MutationReorderEstimateSpecificationsArgs = {
  order?: InputMaybe<Array<EstimateSpecificationModelSortInput>>;
  reorderModel?: InputMaybe<Array<InputMaybe<ReorderEstimateSpecificationModelInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateSpecificationModelFilterInput>;
};


export type MutationReorderEstimateSpecificationsCategoryV3Args = {
  input: ReorderEstimateSpecificationsCategoryV3Input;
};


export type MutationRequestIntegrationConnectionArgs = {
  externalRef?: InputMaybe<Scalars['String']['input']>;
  integrationProviderCardId?: InputMaybe<Scalars['Uuid']['input']>;
  integrationProviderId: Scalars['Uuid']['input'];
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  otpCode?: InputMaybe<Scalars['String']['input']>;
  permissionToShareData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRequestIntegrationConnectionV2Args = {
  externalRef?: InputMaybe<Scalars['String']['input']>;
  externalRef2?: InputMaybe<Scalars['String']['input']>;
  integrationProviderCardId?: InputMaybe<Scalars['Uuid']['input']>;
  integrationProviderId: Scalars['Uuid']['input'];
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
  otpCode?: InputMaybe<Scalars['String']['input']>;
  permissionToShareData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRequestLatestPricesArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type MutationRequestQuoteRequestDeadlineExtensionArgs = {
  quoteRequestRecipientId: Scalars['Uuid']['input'];
};


export type MutationResendEmailSenderConfirmationArgs = {
  input: ResendEmailSenderConfirmationInput;
};


export type MutationResetSectionTemplateToDefaultArgs = {
  sectionTemplateId: Scalars['Uuid']['input'];
};


export type MutationRespondToJobInvitationArgs = {
  jobInvitationId: Scalars['Uuid']['input'];
  response: JobInvitationStatus;
};


export type MutationRestoreEstimateAccessControlArgs = {
  estimateAccessControlId: Scalars['Uuid']['input'];
};


export type MutationRestoreLeadArgs = {
  leadId: Scalars['Uuid']['input'];
};


export type MutationRevokeCatalogueAccessControlByIdArgs = {
  catalogueAccessControlId: Scalars['Uuid']['input'];
};


export type MutationRevokeCatalogueExportByIdArgs = {
  catalogueId: Scalars['Uuid']['input'];
};


export type MutationRevokeEstimateFromTemplateAccessForConnectionArgs = {
  model?: InputMaybe<ToggleEstimateTemplateAccessModelInput>;
};


export type MutationRevokeIntegrationConnectionArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
};


export type MutationRotatePlanArgs = {
  direction: PlanImageRotateDirection;
  planId: Scalars['Uuid']['input'];
};


export type MutationSaveErpTenantSettingArgs = {
  tenantSetting: ErpTenantSettingModelInput;
};


export type MutationSaveLayoutArgs = {
  layoutDto?: InputMaybe<LayoutDtoInput>;
};


export type MutationSaveLayoutOrderArgs = {
  layoutOrderDto?: InputMaybe<LayoutOrderDtoInput>;
};


export type MutationSaveLayoutSelectionArgs = {
  layoutSelectionDto?: InputMaybe<LayoutSelectionDtoInput>;
};


export type MutationSaveSectionArgs = {
  referenceObjectId: Scalars['Uuid']['input'];
  sectionDto?: InputMaybe<SectionDtoInput>;
  thumbnailContent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveSectionSettingsArgs = {
  sectionSettingsDto?: InputMaybe<SectionSettingDtoInput>;
  thumbnailContent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveTemplateSelectionArgs = {
  templateSelectionDto?: InputMaybe<TemplateSelectionDtoInput>;
};


export type MutationScalePlansArgs = {
  model?: InputMaybe<ScalePlansModelInput>;
};


export type MutationSelectEstimateSupplierStoreArgs = {
  input: SelectEstimateSupplierStoreInput;
};


export type MutationSendEmailArgs = {
  input: SendEmailInput;
};


export type MutationSendJobInvitationArgs = {
  input: SendJobInvitationInput;
};


export type MutationSendTakeOffToolQuoteRequestArgs = {
  input: SendTakeOffToolQuoteRequestInput;
};


export type MutationSendTaskReminderArgs = {
  input: SendTaskReminderInput;
};


export type MutationSendVerificationCodeToSpecifiedNumberArgs = {
  input: SendVerificationCodeToSpecifiedNumberInput;
};


export type MutationSetActivityFeedLikeArgs = {
  input: SetActivityFeedLikeInput;
};


export type MutationSetQuoteRequestRecipientAsDeclinedArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type MutationSetQuoteRequestRecipientAsPendingArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type MutationSetQuoteRequestRecipientAsQuotingArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type MutationSetUserAnalyticFilterOptionsArgs = {
  update?: InputMaybe<AnalyticFilterUpdateOptionsInput>;
};


export type MutationSetUserRoleArgs = {
  role: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};


export type MutationShareActivityFeedArgs = {
  input: ShareActivityFeedInput;
};


export type MutationShareLeadWithClientArgs = {
  model?: InputMaybe<ShareLeadWithClientModelInput>;
};


export type MutationShareSpecificationsToClientPortalArgs = {
  input: ShareSpecificationsToClientPortalInput;
};


export type MutationStartCatalogueImportArgs = {
  model?: InputMaybe<StartCatalogueImportModelInput>;
};


export type MutationStartCatalogueSyncArgs = {
  model?: InputMaybe<StartCatalogueSyncModelInput>;
};


export type MutationStartEstimateImportArgs = {
  model?: InputMaybe<StartEstimateImportModelInput>;
};


export type MutationSubmitRfqResponseArgs = {
  model?: InputMaybe<RfqSubmitQuoteModelInput>;
};


export type MutationSubscribeToCatalogueByIdArgs = {
  model?: InputMaybe<SubscribeToCatalogueModelInput>;
};


export type MutationSubscribeToEstimateTemplateArgs = {
  model?: InputMaybe<SubscribeToEstimateTemplateModelInput>;
};


export type MutationSyncEstimateCategorySpecificationsArgs = {
  input: SyncEstimateCategorySpecificationsInput;
};


export type MutationSyncEstimateDocumentsToRfqFilesArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type MutationSyncExternalInvoicePaymentsArgs = {
  input: SyncExternalInvoicePaymentsInput;
};


export type MutationSyncRfqFilesToEstimateDocumentsArgs = {
  recipientId: Scalars['Uuid']['input'];
};


export type MutationSyncScheduleWithEstimateArgs = {
  input: SyncScheduleWithEstimateInput;
};


export type MutationUnsubscribeFromCatalogueByIdArgs = {
  catalogueSubscriptionId: Scalars['Uuid']['input'];
};


export type MutationUpdateAccountingSyncSettingArgs = {
  updateAccountingSyncSetting: UpdateAccountingSyncSettingTypeInput;
};


export type MutationUpdateActivityFeedArgs = {
  input: UpdateActivityFeedInput;
};


export type MutationUpdateAllScheduleTaskProgressArgs = {
  input: UpdateAllScheduleTaskProgressInput;
};


export type MutationUpdateAvatarImageArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUpdateBuildingTypesArgs = {
  input: UpdateBuildingTypesInput;
};


export type MutationUpdateCatalogueByIdArgs = {
  model?: InputMaybe<UpdateCatalogueModelInput>;
};


export type MutationUpdateCatalogueCategoryByIdArgs = {
  model?: InputMaybe<UpdateCatalogueCategoryModelInput>;
};


export type MutationUpdateCatalogueItemByIdArgs = {
  model?: InputMaybe<UpdateCatalogueItemModelInput>;
};


export type MutationUpdateCatalogueItemPriceByIdArgs = {
  model?: InputMaybe<UpdateCatalogueItemPriceModelInput>;
};


export type MutationUpdateCatalogueItemPricesArgs = {
  model?: InputMaybe<UpdateCatalogueItemPricesModelInput>;
};


export type MutationUpdateCatalogueItemsCategoriesArgs = {
  excludeRecipes?: InputMaybe<Scalars['Boolean']['input']>;
  model?: InputMaybe<UpdateCatalogueItemsCategoriesModelInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCatalogueItemsSubCategoriesArgs = {
  excludeRecipes?: InputMaybe<Scalars['Boolean']['input']>;
  model?: InputMaybe<UpdateCatalogueItemsSubCategoriesModelInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCatalogueSubCategoryByIdArgs = {
  model?: InputMaybe<UpdateCatalogueSubCategoryModelInput>;
};


export type MutationUpdateClientsCustomerArgs = {
  updateOptions?: InputMaybe<UpdateCustomerOptionsInput>;
};


export type MutationUpdateCustomerIntegrationConnectionsArgs = {
  model?: InputMaybe<CustomerIntegrationConnectionRequestModelInput>;
};


export type MutationUpdateEmailSenderArgs = {
  input: UpdateEmailSenderInput;
};


export type MutationUpdateEstimateAvailabilityArgs = {
  model?: InputMaybe<EstimateAvailabilityUpdateModelInput>;
};


export type MutationUpdateEstimateCustomerArgs = {
  customerId: Scalars['Uuid']['input'];
  estimateId: Scalars['Uuid']['input'];
};


export type MutationUpdateEstimateCustomerDetailsArgs = {
  estimateId: Scalars['Uuid']['input'];
  model?: InputMaybe<UpdateEstimateCustomerDetailsModelInput>;
};


export type MutationUpdateEstimateDetailsArgs = {
  estimateId: Scalars['Uuid']['input'];
  model?: InputMaybe<UpdateEstimateDetailsModelInput>;
};


export type MutationUpdateEstimateSpecificationCategoryArgs = {
  order?: InputMaybe<Array<EstimateSpecificationModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  updateModel?: InputMaybe<UpdateEstimateSpecificationCategoryModelInput>;
  where?: InputMaybe<EstimateSpecificationModelFilterInput>;
};


export type MutationUpdateEstimateSpecificationCategoryNameV3Args = {
  input: UpdateEstimateSpecificationCategoryNameV3Input;
};


export type MutationUpdateEstimateSpecificationItemArgs = {
  estimateSpecificationModel?: InputMaybe<UpdateEstimateSpecificationModelInput>;
};


export type MutationUpdateIntegrationConnectionAccountManagerArgs = {
  accountManagerId?: InputMaybe<Scalars['Uuid']['input']>;
  integrationConnectionId: Scalars['Uuid']['input'];
};


export type MutationUpdateIntegrationConnectionCatalogueAutoSubscribeArgs = {
  autoSubscribe?: InputMaybe<UpdateIntegrationConnectionCatalogueAutoSubscribeInput>;
};


export type MutationUpdateIntegrationConnectionExternalRefArgs = {
  customerId: Scalars['Uuid']['input'];
  externalRef?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateIntegrationConnectionExternalRefV2Args = {
  customerId: Scalars['Uuid']['input'];
  externalRef?: InputMaybe<Scalars['String']['input']>;
  externalRef2?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateIntegrationConnectionNotificationArgs = {
  notificationSetting?: InputMaybe<UpdateIntegrationConnectionNotificationSettingInput>;
};


export type MutationUpdateIntegrationConnectionPermissionsArgs = {
  permissions?: InputMaybe<UpdateIntegrationConnectionPermissionsInput>;
};


export type MutationUpdateIntegrationConnectionQuoteRequestEmailArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
  quoteRequestEmail?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateIntegrationProviderByIdArgs = {
  model?: InputMaybe<UpdateIntegrationProviderModelInput>;
};


export type MutationUpdateInvoiceTermsAndConditionsArgs = {
  setting: UpdateJobPaymentSettingInput;
};


export type MutationUpdateJobCustomerArgs = {
  customerId: Scalars['Uuid']['input'];
  jobId: Scalars['Uuid']['input'];
};


export type MutationUpdateJobCustomerDetailsArgs = {
  jobId: Scalars['Uuid']['input'];
  model: UpdateJobCustomerDetailsModelInput;
};


export type MutationUpdateLeadArgs = {
  lead?: InputMaybe<LeadModelInput>;
};


export type MutationUpdateLeadProjectNameArgs = {
  model?: InputMaybe<UpdateLeadProjectNameModelInput>;
};


export type MutationUpdateOnboardSettingsArgs = {
  updates: Array<UpdateOnboardSettingInput>;
};


export type MutationUpdateOrderTermsAndConditionsArgs = {
  setting: UpdatePurchaseOrderSettingInput;
};


export type MutationUpdatePlanMeasurementsArgs = {
  model?: InputMaybe<Array<InputMaybe<UpdatePlanMeasurementModelInput>>>;
};


export type MutationUpdateQuoteRequestRecipientArgs = {
  recipient?: InputMaybe<QuoteRequestRecipientUpdateModelInput>;
};


export type MutationUpdateQuoteRequestRecipientOpenDateArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type MutationUpdateQuoteRequestRecipientV3Args = {
  recipient?: InputMaybe<QuoteRequestRecipientUpdateModelInput>;
};


export type MutationUpdateRecipeCostsFromCatalogueArgs = {
  catalogueId: Scalars['Uuid']['input'];
};


export type MutationUpdateRecipeItemByIdArgs = {
  model?: InputMaybe<UpdateRecipeItemModelInput>;
};


export type MutationUpdateScheduleStartDateArgs = {
  input: UpdateScheduleStartDateInput;
};


export type MutationUpdateScheduleTaskArgs = {
  input: UpdateScheduleTaskInput;
};


export type MutationUpdateSnapshotDescriptionArgs = {
  updateSnapshotDescription?: InputMaybe<UpdateSnapshotDescriptionInput>;
};


export type MutationUpdateSpecificationPresetArgs = {
  updateModel?: InputMaybe<UpdateSpecificationPresetInput>;
};


export type MutationUpdateThumbnailForLayoutArgs = {
  layoutId: Scalars['Uuid']['input'];
  thumbnailContent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateToDoItemStatusesArgs = {
  input: UpdateToDoItemStatusesInput;
};


export type MutationUpdateToDoItemsArgs = {
  input: UpdateToDoItemsInput;
};


export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};


export type MutationUploadFileToImagePlaceholderArgs = {
  input: UploadFileToImagePlaceholderInput;
};


export type MutationUpsertEstimateMeasurementArgs = {
  estimateId: Scalars['Uuid']['input'];
  model?: InputMaybe<CreateEstimateMeasurementModelInput>;
};


export type MutationUserToggleIsMobileVisibleToOthersArgs = {
  isMobileVisibleToOthers: Scalars['Boolean']['input'];
};


export type MutationVerifyOtpCodeArgs = {
  otp: Scalars['String']['input'];
  otpId: Scalars['Uuid']['input'];
  usage?: InputMaybe<OtpVerifyUsage>;
};

/** A segment of a collection. */
export type MyToDoItemsCollectionSegment = {
  __typename?: 'MyToDoItemsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ToDoItem>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type NewTenantRequestInput = {
  companyName: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  hasConsolidatedBilling?: InputMaybe<Scalars['Boolean']['input']>;
  parentTenantId?: InputMaybe<Scalars['Uuid']['input']>;
  phone: Scalars['String']['input'];
  referenceTenantId?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  resellerCode?: InputMaybe<Scalars['String']['input']>;
  skipTrial?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewUserRequestInput = {
  appRole?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  mobile: Scalars['String']['input'];
};

export type NonWorkingDay = {
  __typename?: 'NonWorkingDay';
  description?: Maybe<Scalars['String']['output']>;
  iD_NonWorkingDay: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  nonWorkingDate: Scalars['DateTime']['output'];
};

/** A segment of a collection. */
export type NonWorkingDayCollectionSegment = {
  __typename?: 'NonWorkingDayCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<NonWorkingDay>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type NonWorkingDayFilterInput = {
  and?: InputMaybe<Array<NonWorkingDayFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_NonWorkingDay?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  nonWorkingDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<NonWorkingDayFilterInput>>;
};

export type NonWorkingDayNotFound = Error & {
  __typename?: 'NonWorkingDayNotFound';
  message: Scalars['String']['output'];
};

export type NonWorkingDaySortInput = {
  description?: InputMaybe<SortEnumType>;
  iD_NonWorkingDay?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  nonWorkingDate?: InputMaybe<SortEnumType>;
};

export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  ids: Array<Scalars['Uuid']['output']>;
  message: Scalars['String']['output'];
};

export type Note = {
  __typename?: 'Note';
  dateEntered: Scalars['DateTime']['output'];
  iD_Note: Scalars['Uuid']['output'];
  noteText?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type NoteCreateModelInput = {
  noteText: Scalars['String']['input'];
  referenceId: Scalars['Uuid']['input'];
  source: NoteSource;
};

export type NoteFilterInput = {
  and?: InputMaybe<Array<NoteFilterInput>>;
  dateEntered?: InputMaybe<DateTimeOperationFilterInput>;
  iD_Note?: InputMaybe<UuidOperationFilterInput>;
  iD_Reference?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  noteText?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NoteFilterInput>>;
  source?: InputMaybe<StringOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type NoteSortInput = {
  dateEntered?: InputMaybe<SortEnumType>;
  iD_Note?: InputMaybe<SortEnumType>;
  iD_Reference?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  noteText?: InputMaybe<SortEnumType>;
  source?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export const NoteSource = {
  Catalogues: 'CATALOGUES',
  Clients: 'CLIENTS',
  Contacts: 'CONTACTS',
  Estimates: 'ESTIMATES',
  Jobs: 'JOBS',
  JobsSiteDiary: 'JOBS_SITE_DIARY',
  Leads: 'LEADS'
} as const;

export type NoteSource = typeof NoteSource[keyof typeof NoteSource];
/** A segment of a collection. */
export type NotesCollectionSegment = {
  __typename?: 'NotesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Note>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export const NoticeAction = {
  Dismiss: 'DISMISS',
  Unknown: 'UNKNOWN',
  View: 'VIEW'
} as const;

export type NoticeAction = typeof NoticeAction[keyof typeof NoticeAction];
export type NoticeDto = {
  __typename?: 'NoticeDto';
  actions?: Maybe<Array<NoticeAction>>;
  createdDate: Scalars['DateTime']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  module: NoticeModule;
  noticeId: Scalars['Uuid']['output'];
  objectId?: Maybe<Scalars['String']['output']>;
  severity: NoticeSeverity;
  tenantId?: Maybe<Scalars['Uuid']['output']>;
  textItems?: Maybe<Array<Maybe<NoticeTextItemDto>>>;
  type: NoticeType;
  userId?: Maybe<Scalars['Uuid']['output']>;
};

export const NoticeModule = {
  ApplicationIntegration: 'APPLICATION_INTEGRATION',
  Catalogues: 'CATALOGUES',
  Conversations: 'CONVERSATIONS',
  Estimates: 'ESTIMATES',
  Global: 'GLOBAL',
  Integrations: 'INTEGRATIONS',
  Jobs: 'JOBS',
  Rfq: 'RFQ',
  SupplierIntegrations: 'SUPPLIER_INTEGRATIONS',
  Unknown: 'UNKNOWN',
  Variations: 'VARIATIONS'
} as const;

export type NoticeModule = typeof NoticeModule[keyof typeof NoticeModule];
export const NoticeSeverity = {
  Info: 'INFO',
  Success: 'SUCCESS',
  Unknown: 'UNKNOWN',
  Warning: 'WARNING'
} as const;

export type NoticeSeverity = typeof NoticeSeverity[keyof typeof NoticeSeverity];
export type NoticeTextItemDto = {
  __typename?: 'NoticeTextItemDto';
  key: NoticeTextItemKey;
  value?: Maybe<Scalars['String']['output']>;
};

export const NoticeTextItemKey = {
  ApplicationIntegrationType: 'APPLICATION_INTEGRATION_TYPE',
  ApproverEmail: 'APPROVER_EMAIL',
  EstimateId: 'ESTIMATE_ID',
  MessageSenderUsername: 'MESSAGE_SENDER_USERNAME',
  ObjectDescription: 'OBJECT_DESCRIPTION',
  QuoteSnapshotEstimateDifference: 'QUOTE_SNAPSHOT_ESTIMATE_DIFFERENCE',
  Unknown: 'UNKNOWN'
} as const;

export type NoticeTextItemKey = typeof NoticeTextItemKey[keyof typeof NoticeTextItemKey];
export const NoticeType = {
  ConversationNewMessage: 'CONVERSATION_NEW_MESSAGE',
  EstimateTemplateShared: 'ESTIMATE_TEMPLATE_SHARED',
  IntegrationRequested: 'INTEGRATION_REQUESTED',
  IntegrationSyncSuccessful: 'INTEGRATION_SYNC_SUCCESSFUL',
  IntegrationSyncUnsuccessful: 'INTEGRATION_SYNC_UNSUCCESSFUL',
  QuoteAccepted: 'QUOTE_ACCEPTED',
  QuoteAcceptedDifferentTotal: 'QUOTE_ACCEPTED_DIFFERENT_TOTAL',
  RfqExtendingDeadlineRequired: 'RFQ_EXTENDING_DEADLINE_REQUIRED',
  RfqSent: 'RFQ_SENT',
  RfqSubmitted: 'RFQ_SUBMITTED',
  Unknown: 'UNKNOWN',
  VariationAccepted: 'VARIATION_ACCEPTED'
} as const;

export type NoticeType = typeof NoticeType[keyof typeof NoticeType];
export type NullableOfCatalogueClassificationOperationFilterInput = {
  eq?: InputMaybe<CatalogueClassification>;
  in?: InputMaybe<Array<InputMaybe<CatalogueClassification>>>;
  neq?: InputMaybe<CatalogueClassification>;
  nin?: InputMaybe<Array<InputMaybe<CatalogueClassification>>>;
};

export type NullableOfEstimateAvailabilityScopeOperationFilterInput = {
  eq?: InputMaybe<EstimateAvailabilityScope>;
  in?: InputMaybe<Array<InputMaybe<EstimateAvailabilityScope>>>;
  neq?: InputMaybe<EstimateAvailabilityScope>;
  nin?: InputMaybe<Array<InputMaybe<EstimateAvailabilityScope>>>;
};

export type NullableOfEstimateAvailabilityStatusOperationFilterInput = {
  eq?: InputMaybe<EstimateAvailabilityStatus>;
  in?: InputMaybe<Array<InputMaybe<EstimateAvailabilityStatus>>>;
  neq?: InputMaybe<EstimateAvailabilityStatus>;
  nin?: InputMaybe<Array<InputMaybe<EstimateAvailabilityStatus>>>;
};

export type NullableOfEstimateSpecificationShareStatusOperationFilterInput = {
  eq?: InputMaybe<EstimateSpecificationShareStatus>;
  in?: InputMaybe<Array<InputMaybe<EstimateSpecificationShareStatus>>>;
  neq?: InputMaybe<EstimateSpecificationShareStatus>;
  nin?: InputMaybe<Array<InputMaybe<EstimateSpecificationShareStatus>>>;
};

export type NullableOfIntegrationConnectionStatusOperationFilterInput = {
  eq?: InputMaybe<IntegrationConnectionStatus>;
  in?: InputMaybe<Array<InputMaybe<IntegrationConnectionStatus>>>;
  neq?: InputMaybe<IntegrationConnectionStatus>;
  nin?: InputMaybe<Array<InputMaybe<IntegrationConnectionStatus>>>;
};

export type NullableOfJobDeletionSourceOperationFilterInput = {
  eq?: InputMaybe<JobDeletionSource>;
  in?: InputMaybe<Array<InputMaybe<JobDeletionSource>>>;
  neq?: InputMaybe<JobDeletionSource>;
  nin?: InputMaybe<Array<InputMaybe<JobDeletionSource>>>;
};

export type NullableOfJobTypesOperationFilterInput = {
  eq?: InputMaybe<JobTypes>;
  in?: InputMaybe<Array<InputMaybe<JobTypes>>>;
  neq?: InputMaybe<JobTypes>;
  nin?: InputMaybe<Array<InputMaybe<JobTypes>>>;
};

export type NullableOfLeadStatusOperationFilterInput = {
  eq?: InputMaybe<LeadStatus>;
  in?: InputMaybe<Array<InputMaybe<LeadStatus>>>;
  neq?: InputMaybe<LeadStatus>;
  nin?: InputMaybe<Array<InputMaybe<LeadStatus>>>;
};

export type NullableOfScheduleTaskReminderTimeframeOperationFilterInput = {
  eq?: InputMaybe<ScheduleTaskReminderTimeframe>;
  in?: InputMaybe<Array<InputMaybe<ScheduleTaskReminderTimeframe>>>;
  neq?: InputMaybe<ScheduleTaskReminderTimeframe>;
  nin?: InputMaybe<Array<InputMaybe<ScheduleTaskReminderTimeframe>>>;
};

export type NullableOfSupplierQuoteResponseErrorCodesOperationFilterInput = {
  eq?: InputMaybe<SupplierQuoteResponseErrorCodes>;
  in?: InputMaybe<Array<InputMaybe<SupplierQuoteResponseErrorCodes>>>;
  neq?: InputMaybe<SupplierQuoteResponseErrorCodes>;
  nin?: InputMaybe<Array<InputMaybe<SupplierQuoteResponseErrorCodes>>>;
};

export type NullableOfTaskColourOperationFilterInput = {
  eq?: InputMaybe<TaskColour>;
  in?: InputMaybe<Array<InputMaybe<TaskColour>>>;
  neq?: InputMaybe<TaskColour>;
  nin?: InputMaybe<Array<InputMaybe<TaskColour>>>;
};

export type NullableOfToDoRelatedEntityTypeOperationFilterInput = {
  eq?: InputMaybe<ToDoRelatedEntityType>;
  in?: InputMaybe<Array<InputMaybe<ToDoRelatedEntityType>>>;
  neq?: InputMaybe<ToDoRelatedEntityType>;
  nin?: InputMaybe<Array<InputMaybe<ToDoRelatedEntityType>>>;
};

export type OnboardingRecordCreateOptionsInput = {
  hasDataToUpload: Scalars['Boolean']['input'];
  positionInBusiness?: InputMaybe<Scalars['String']['input']>;
  previousSoftwareUsage?: InputMaybe<Scalars['String']['input']>;
  problemsToSolve?: InputMaybe<Scalars['String']['input']>;
  projectTypes?: InputMaybe<Scalars['String']['input']>;
  projectsPerMonth?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingSetting = {
  __typename?: 'OnboardingSetting';
  bool?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  iD_Selection: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  key: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  number?: Maybe<Scalars['Float']['output']>;
  syncToCrm: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  text2?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type OnboardingSettingConnection = {
  __typename?: 'OnboardingSettingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OnboardingSettingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OnboardingSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OnboardingSettingEdge = {
  __typename?: 'OnboardingSettingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OnboardingSetting;
};

export type OnboardingSettingFilterInput = {
  and?: InputMaybe<Array<OnboardingSettingFilterInput>>;
  bool?: InputMaybe<BooleanOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<StringOperationFilterInput>;
  dateTime?: InputMaybe<DateTimeOperationFilterInput>;
  iD_Selection?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  key?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<StringOperationFilterInput>;
  number?: InputMaybe<FloatOperationFilterInput>;
  or?: InputMaybe<Array<OnboardingSettingFilterInput>>;
  syncToCrm?: InputMaybe<BooleanOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  text2?: InputMaybe<StringOperationFilterInput>;
};

export type OnboardingSettingSortInput = {
  bool?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateTime?: InputMaybe<SortEnumType>;
  iD_Selection?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  key?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  syncToCrm?: InputMaybe<SortEnumType>;
  text?: InputMaybe<SortEnumType>;
  text2?: InputMaybe<SortEnumType>;
};

export type OpenTakeOffToolSessionError = NotFoundError | PropertyError | TakeOffSessionExpiredError;

export type OpenTakeOffToolSessionInput = {
  sessionId: Scalars['Uuid']['input'];
};

export type OpenTakeOffToolSessionPayload = {
  __typename?: 'OpenTakeOffToolSessionPayload';
  errors?: Maybe<Array<OpenTakeOffToolSessionError>>;
  takeOffToolOpenSession?: Maybe<TakeOffToolOpenSession>;
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  closingTime: Scalars['String']['output'];
  openingTime: Scalars['String']['output'];
};

export type OpeningHoursFilterInput = {
  and?: InputMaybe<Array<OpeningHoursFilterInput>>;
  closingTime?: InputMaybe<StringOperationFilterInput>;
  openingTime?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OpeningHoursFilterInput>>;
};

export type OpeningHoursSortInput = {
  closingTime?: InputMaybe<SortEnumType>;
  openingTime?: InputMaybe<SortEnumType>;
};

export const OtpVerifyResultEnum = {
  Codeexpired: 'CODEEXPIRED',
  Codeincorrect: 'CODEINCORRECT',
  Codeiscorrect: 'CODEISCORRECT',
  Codeverifiedbefore: 'CODEVERIFIEDBEFORE',
  Invalidotpid: 'INVALIDOTPID',
  Mobilenumberverifiedbyanotheruser: 'MOBILENUMBERVERIFIEDBYANOTHERUSER',
  Mobilenumberverifiedflagupdated: 'MOBILENUMBERVERIFIEDFLAGUPDATED',
  Toomanyfailedattempts: 'TOOMANYFAILEDATTEMPTS',
  Usernotexist: 'USERNOTEXIST'
} as const;

export type OtpVerifyResultEnum = typeof OtpVerifyResultEnum[keyof typeof OtpVerifyResultEnum];
export const OtpVerifyUsage = {
  Mobileverification: 'MOBILEVERIFICATION'
} as const;

export type OtpVerifyUsage = typeof OtpVerifyUsage[keyof typeof OtpVerifyUsage];
export type OutgoingCatalogue = {
  __typename?: 'OutgoingCatalogue';
  activeItemCount?: Maybe<Scalars['Decimal']['output']>;
  canExport: Scalars['Boolean']['output'];
  catalogueType: CatalogueType;
  colour?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  dateModified: Scalars['DateTime']['output'];
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  deletedByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['DateTime']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  fromClassification?: Maybe<CatalogueClassification>;
  fromSupplier: Scalars['Boolean']['output'];
  fromTenantName?: Maybe<Scalars['String']['output']>;
  iD_Catalogue: Scalars['Uuid']['output'];
  iD_CatalogueSubscription?: Maybe<Scalars['Uuid']['output']>;
  iD_Contact?: Maybe<Scalars['Uuid']['output']>;
  iD_Related?: Maybe<Scalars['Uuid']['output']>;
  iD_Supplier?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  importProgress?: Maybe<Scalars['Decimal']['output']>;
  isCategorized: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  lastImport?: Maybe<CatalogueImport>;
  logoThumbnailUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  rowVersion: Scalars['String']['output'];
  scope: CatalogueScope;
  scopeHiddenBy?: Maybe<Scalars['Uuid']['output']>;
  scopeHiddenByUsername?: Maybe<Scalars['String']['output']>;
  scopeHiddenDate?: Maybe<Scalars['DateTime']['output']>;
  scopeManagedBy?: Maybe<Scalars['Uuid']['output']>;
  scopeManagedByUsername?: Maybe<Scalars['String']['output']>;
  scopeManagedDate?: Maybe<Scalars['DateTime']['output']>;
  scopePublicBy?: Maybe<Scalars['Uuid']['output']>;
  scopePublicByUsername?: Maybe<Scalars['String']['output']>;
  scopePublicDate?: Maybe<Scalars['DateTime']['output']>;
  shortCode?: Maybe<Scalars['String']['output']>;
  status: CatalogueStatus;
  statusArchivedBy?: Maybe<Scalars['Uuid']['output']>;
  statusArchivedByUsername?: Maybe<Scalars['String']['output']>;
  statusArchivedDate?: Maybe<Scalars['DateTime']['output']>;
  statusDraftBy?: Maybe<Scalars['Uuid']['output']>;
  statusDraftByUsername?: Maybe<Scalars['String']['output']>;
  statusDraftDate?: Maybe<Scalars['DateTime']['output']>;
  statusExpiredBy?: Maybe<Scalars['Uuid']['output']>;
  statusExpiredByUsername?: Maybe<Scalars['String']['output']>;
  statusExpiredDate?: Maybe<Scalars['DateTime']['output']>;
  statusPublishedBy?: Maybe<Scalars['Uuid']['output']>;
  statusPublishedByUsername?: Maybe<Scalars['String']['output']>;
  statusPublishedDate?: Maybe<Scalars['DateTime']['output']>;
  subscriptionStatus?: Maybe<Scalars['Int']['output']>;
  supplierDescription?: Maybe<Scalars['String']['output']>;
  syncEnabled?: Maybe<Scalars['Boolean']['output']>;
  syncMapping?: Maybe<Scalars['String']['output']>;
  syncTimeOfDayHour?: Maybe<Scalars['Short']['output']>;
  syncTimeOfDayMinute?: Maybe<Scalars['Short']['output']>;
  syncUrl?: Maybe<Scalars['String']['output']>;
  syncUseMapping?: Maybe<Scalars['Boolean']['output']>;
};

/** A connection to a list of items. */
export type OutgoingCatalogueConnection = {
  __typename?: 'OutgoingCatalogueConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OutgoingCatalogueEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<OutgoingCatalogue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OutgoingCatalogueEdge = {
  __typename?: 'OutgoingCatalogueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OutgoingCatalogue>;
};

export type OutgoingCatalogueFilterInput = {
  and?: InputMaybe<Array<OutgoingCatalogueFilterInput>>;
  canExport?: InputMaybe<BooleanOperationFilterInput>;
  catalogueType?: InputMaybe<CatalogueTypeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  dateModified?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  effectiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  expiryDate?: InputMaybe<DateTimeOperationFilterInput>;
  fromClassification?: InputMaybe<NullableOfCatalogueClassificationOperationFilterInput>;
  fromSupplier?: InputMaybe<BooleanOperationFilterInput>;
  fromTenantName?: InputMaybe<StringOperationFilterInput>;
  iD_Catalogue?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueSubscription?: InputMaybe<UuidOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_Related?: InputMaybe<UuidOperationFilterInput>;
  iD_Supplier?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OutgoingCatalogueFilterInput>>;
  scope?: InputMaybe<CatalogueScopeOperationFilterInput>;
  scopeHiddenBy?: InputMaybe<UuidOperationFilterInput>;
  scopeHiddenDate?: InputMaybe<DateTimeOperationFilterInput>;
  scopeManagedBy?: InputMaybe<UuidOperationFilterInput>;
  scopeManagedDate?: InputMaybe<DateTimeOperationFilterInput>;
  scopePublicBy?: InputMaybe<UuidOperationFilterInput>;
  scopePublicDate?: InputMaybe<DateTimeOperationFilterInput>;
  shortCode?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<CatalogueStatusOperationFilterInput>;
  statusArchivedBy?: InputMaybe<UuidOperationFilterInput>;
  statusArchivedDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusDraftBy?: InputMaybe<UuidOperationFilterInput>;
  statusDraftDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusExpiredBy?: InputMaybe<UuidOperationFilterInput>;
  statusExpiredDate?: InputMaybe<DateTimeOperationFilterInput>;
  statusPublishedBy?: InputMaybe<UuidOperationFilterInput>;
  statusPublishedDate?: InputMaybe<DateTimeOperationFilterInput>;
  subscriptionStatus?: InputMaybe<IntOperationFilterInput>;
  syncEnabled?: InputMaybe<BooleanOperationFilterInput>;
  syncMapping?: InputMaybe<StringOperationFilterInput>;
  syncTimeOfDayHour?: InputMaybe<ShortOperationFilterInput>;
  syncTimeOfDayMinute?: InputMaybe<ShortOperationFilterInput>;
  syncUrl?: InputMaybe<StringOperationFilterInput>;
  syncUseMapping?: InputMaybe<BooleanOperationFilterInput>;
};

export type OutgoingCatalogueSortInput = {
  canExport?: InputMaybe<SortEnumType>;
  catalogueType?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  dateModified?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  effectiveDate?: InputMaybe<SortEnumType>;
  expiryDate?: InputMaybe<SortEnumType>;
  fromClassification?: InputMaybe<SortEnumType>;
  fromSupplier?: InputMaybe<SortEnumType>;
  fromTenantName?: InputMaybe<SortEnumType>;
  iD_Catalogue?: InputMaybe<SortEnumType>;
  iD_CatalogueSubscription?: InputMaybe<SortEnumType>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_Related?: InputMaybe<SortEnumType>;
  iD_Supplier?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  scope?: InputMaybe<SortEnumType>;
  scopeHiddenBy?: InputMaybe<SortEnumType>;
  scopeHiddenDate?: InputMaybe<SortEnumType>;
  scopeManagedBy?: InputMaybe<SortEnumType>;
  scopeManagedDate?: InputMaybe<SortEnumType>;
  scopePublicBy?: InputMaybe<SortEnumType>;
  scopePublicDate?: InputMaybe<SortEnumType>;
  shortCode?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusArchivedBy?: InputMaybe<SortEnumType>;
  statusArchivedDate?: InputMaybe<SortEnumType>;
  statusDraftBy?: InputMaybe<SortEnumType>;
  statusDraftDate?: InputMaybe<SortEnumType>;
  statusExpiredBy?: InputMaybe<SortEnumType>;
  statusExpiredDate?: InputMaybe<SortEnumType>;
  statusPublishedBy?: InputMaybe<SortEnumType>;
  statusPublishedDate?: InputMaybe<SortEnumType>;
  subscriptionStatus?: InputMaybe<SortEnumType>;
  syncEnabled?: InputMaybe<SortEnumType>;
  syncMapping?: InputMaybe<SortEnumType>;
  syncTimeOfDayHour?: InputMaybe<SortEnumType>;
  syncTimeOfDayMinute?: InputMaybe<SortEnumType>;
  syncUrl?: InputMaybe<SortEnumType>;
  syncUseMapping?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type OverwriteEstimateSpecificationWithPresetCollectionSegment = {
  __typename?: 'OverwriteEstimateSpecificationWithPresetCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<EstimateSpecificationModel>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type OverwriteEstimateSpecificationWithPresetV3Error = NotFoundError | PropertyError | ValidationError;

export type OverwriteEstimateSpecificationWithPresetV3Input = {
  estimateId: Scalars['Uuid']['input'];
  presetId: Scalars['Uuid']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OverwriteEstimateSpecificationWithPresetV3Payload = {
  __typename?: 'OverwriteEstimateSpecificationWithPresetV3Payload';
  errors?: Maybe<Array<OverwriteEstimateSpecificationWithPresetV3Error>>;
  estimateSpecificationCollectionSegment?: Maybe<EstimateSpecificationCollectionSegment>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ParentNotFound = Error & {
  __typename?: 'ParentNotFound';
  message: Scalars['String']['output'];
};

/** A segment of a collection. */
export type ParentTasksCollectionSegment = {
  __typename?: 'ParentTasksCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScheduleTask>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PartialSuccessOfCopiedAccountingAttachment = {
  __typename?: 'PartialSuccessOfCopiedAccountingAttachment';
  failed: Array<CopiedAccountingAttachment>;
  failedCount: Scalars['Int']['output'];
  success: Array<CopiedAccountingAttachment>;
  successCount: Scalars['Int']['output'];
};

export type PatchEstimateModelInput = {
  iD_Estimate: Scalars['Uuid']['input'];
  isLegacySpecification?: InputMaybe<Scalars['Boolean']['input']>;
  specificationIntroduction?: InputMaybe<Scalars['String']['input']>;
};

export type PatchEstimateSpecificationV3Error = ClientPortalSyncError | FrozenSpecificationError | NotFoundError | PropertyError | ValidationError;

export type PatchEstimateSpecificationV3Input = {
  estimateSpecificationModel: EstimateSpecificationPatchInput;
};

export type PatchEstimateSpecificationV3Payload = {
  __typename?: 'PatchEstimateSpecificationV3Payload';
  errors?: Maybe<Array<PatchEstimateSpecificationV3Error>>;
  estimateSpecification?: Maybe<EstimateSpecification>;
};

export type PermissionDto = {
  __typename?: 'PermissionDto';
  name?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type PhoneCountryCode = {
  __typename?: 'PhoneCountryCode';
  callingCode: Scalars['String']['output'];
  country: Scalars['String']['output'];
};

export type Plan = {
  __typename?: 'Plan';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  estimate?: Maybe<Estimate>;
  iD_PageImage?: Maybe<Scalars['Uuid']['output']>;
  iD_Plan: Scalars['Uuid']['output'];
  iD_Reference: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  indexer: Scalars['Long']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isSampleData: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  planDescription?: Maybe<Scalars['String']['output']>;
  planLabel?: Maybe<Scalars['String']['output']>;
  planMeasurements?: Maybe<Array<Maybe<PlanMeasurement>>>;
  planOrder: Scalars['Byte']['output'];
  planOrder2: Scalars['Int']['output'];
  planStorageUrl?: Maybe<Scalars['String']['output']>;
  planmeasurements?: Maybe<Array<Maybe<PlanMeasurement>>>;
  scale: Scalars['Float']['output'];
  scaleRatio?: Maybe<Scalars['String']['output']>;
  scaleUnit?: Maybe<Scalars['String']['output']>;
  vScale: Scalars['Float']['output'];
};

/** A connection to a list of items. */
export type PlanConnection = {
  __typename?: 'PlanConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PlanEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Plan>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PlanEdge = {
  __typename?: 'PlanEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Plan>;
};

export type PlanFilterInput = {
  and?: InputMaybe<Array<PlanFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  estimate?: InputMaybe<EstimateFilterInput>;
  iD_PageImage?: InputMaybe<UuidOperationFilterInput>;
  iD_Plan?: InputMaybe<UuidOperationFilterInput>;
  iD_Reference?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isSampleData?: InputMaybe<BooleanOperationFilterInput>;
  metadata?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PlanFilterInput>>;
  planDescription?: InputMaybe<StringOperationFilterInput>;
  planLabel?: InputMaybe<StringOperationFilterInput>;
  planMeasurements?: InputMaybe<ListFilterInputTypeOfPlanMeasurementFilterInput>;
  planOrder?: InputMaybe<ByteOperationFilterInput>;
  planOrder2?: InputMaybe<IntOperationFilterInput>;
  planStorageUrl?: InputMaybe<StringOperationFilterInput>;
  scale?: InputMaybe<FloatOperationFilterInput>;
  scaleRatio?: InputMaybe<StringOperationFilterInput>;
  scaleUnit?: InputMaybe<StringOperationFilterInput>;
  vScale?: InputMaybe<FloatOperationFilterInput>;
};

export const PlanImageRotateDirection = {
  Flip: 'FLIP',
  Left: 'LEFT',
  Right: 'RIGHT'
} as const;

export type PlanImageRotateDirection = typeof PlanImageRotateDirection[keyof typeof PlanImageRotateDirection];
export type PlanLimitError = Error & {
  __typename?: 'PlanLimitError';
  message: Scalars['String']['output'];
};

export type PlanMeasurement = {
  __typename?: 'PlanMeasurement';
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  depth: Scalars['Float']['output'];
  depthUnit?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_Measurement?: Maybe<Scalars['Uuid']['output']>;
  iD_Plan: Scalars['Uuid']['output'];
  iD_PlanMeasurement: Scalars['Uuid']['output'];
  indexer: Scalars['Long']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isNegative: Scalars['Boolean']['output'];
  measurement: Scalars['Float']['output'];
  measurementUnit?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  pitch: Scalars['Float']['output'];
  plan?: Maybe<Plan>;
  points?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  shapeType?: Maybe<Scalars['String']['output']>;
};

export type PlanMeasurementFilterInput = {
  and?: InputMaybe<Array<PlanMeasurementFilterInput>>;
  color?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  depth?: InputMaybe<FloatOperationFilterInput>;
  depthUnit?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_Measurement?: InputMaybe<UuidOperationFilterInput>;
  iD_Plan?: InputMaybe<UuidOperationFilterInput>;
  iD_PlanMeasurement?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isNegative?: InputMaybe<BooleanOperationFilterInput>;
  measurement?: InputMaybe<FloatOperationFilterInput>;
  measurementUnit?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PlanMeasurementFilterInput>>;
  pitch?: InputMaybe<FloatOperationFilterInput>;
  plan?: InputMaybe<PlanFilterInput>;
  points?: InputMaybe<StringOperationFilterInput>;
  position?: InputMaybe<StringOperationFilterInput>;
  shapeType?: InputMaybe<StringOperationFilterInput>;
};

export type PlanSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  estimate?: InputMaybe<EstimateSortInput>;
  iD_PageImage?: InputMaybe<SortEnumType>;
  iD_Plan?: InputMaybe<SortEnumType>;
  iD_Reference?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isSampleData?: InputMaybe<SortEnumType>;
  metadata?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  planDescription?: InputMaybe<SortEnumType>;
  planLabel?: InputMaybe<SortEnumType>;
  planOrder?: InputMaybe<SortEnumType>;
  planOrder2?: InputMaybe<SortEnumType>;
  planStorageUrl?: InputMaybe<SortEnumType>;
  scale?: InputMaybe<SortEnumType>;
  scaleRatio?: InputMaybe<SortEnumType>;
  scaleUnit?: InputMaybe<SortEnumType>;
  vScale?: InputMaybe<SortEnumType>;
};

export type PositiveOrNullError = Error & {
  __typename?: 'PositiveOrNullError';
  message: Scalars['String']['output'];
};

export type PostSmsError = SmsLimitReachError | ValidationError;

export type PostSmsInput = {
  message: SmsMessageDtoInput;
};

export type PostSmsPayload = {
  __typename?: 'PostSmsPayload';
  errors?: Maybe<Array<PostSmsError>>;
  postSmsResult?: Maybe<Array<PostSmsResult>>;
};

export type PostSmsResult = {
  __typename?: 'PostSmsResult';
  recipient: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ProductFeatureDto = {
  __typename?: 'ProductFeatureDto';
  name?: Maybe<Scalars['String']['output']>;
  value: Scalars['Boolean']['output'];
};

export type ProductSearchResult = {
  __typename?: 'ProductSearchResult';
  productCode: Scalars['String']['output'];
  productId: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  standardSellPrice: Scalars['Decimal']['output'];
};

export type PropertyError = Error & {
  __typename?: 'PropertyError';
  message: Scalars['String']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
};

export type PublicHoliday = {
  __typename?: 'PublicHoliday';
  date: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  openingHours: OpeningHours;
};

export type PublicHolidayFilterInput = {
  and?: InputMaybe<Array<PublicHolidayFilterInput>>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  openingHours?: InputMaybe<OpeningHoursFilterInput>;
  or?: InputMaybe<Array<PublicHolidayFilterInput>>;
};

export type PublicTenantInfo = {
  __typename?: 'PublicTenantInfo';
  name: Scalars['String']['output'];
  tenantId: Scalars['Uuid']['output'];
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  contact: ContactView;
  iD_PurchaseOrder: Scalars['Uuid']['output'];
  orderStatus?: Maybe<Scalars['String']['output']>;
  purchaseOrderInvoices?: Maybe<PurchaseOrderInvoicesCollectionSegment>;
  purchaseOrderItems?: Maybe<PurchaseOrderItemsCollectionSegment>;
  supplierInvoiceRef?: Maybe<Scalars['String']['output']>;
};


export type PurchaseOrderPurchaseOrderInvoicesArgs = {
  order?: InputMaybe<Array<PurchaseOrderInvoiceSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderInvoiceFilterInput>;
};


export type PurchaseOrderPurchaseOrderItemsArgs = {
  order?: InputMaybe<Array<PurchaseOrderItemSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderItemFilterInput>;
};

export type PurchaseOrderFilterInput = {
  accSyncData?: InputMaybe<StringOperationFilterInput>;
  accountCode?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PurchaseOrderFilterInput>>;
  completedDate?: InputMaybe<DateTimeOperationFilterInput>;
  contact?: InputMaybe<ContactViewFilterInput>;
  costPlusOrder?: InputMaybe<IntOperationFilterInput>;
  costPlusPercentage?: InputMaybe<FloatOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  deliveryAddress1?: InputMaybe<StringOperationFilterInput>;
  deliveryAddress2?: InputMaybe<StringOperationFilterInput>;
  deliveryCityTown?: InputMaybe<StringOperationFilterInput>;
  deliveryCost?: InputMaybe<DecimalOperationFilterInput>;
  deliveryPostCode?: InputMaybe<StringOperationFilterInput>;
  deliveryState?: InputMaybe<StringOperationFilterInput>;
  deliveryTaxContent?: InputMaybe<StringOperationFilterInput>;
  deliveryTaxRates?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_JobPayment?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrder?: InputMaybe<UuidOperationFilterInput>;
  iD_Reference?: InputMaybe<UuidOperationFilterInput>;
  iD_Supplier?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  instructions?: InputMaybe<StringOperationFilterInput>;
  invoiceTax?: InputMaybe<DecimalOperationFilterInput>;
  invoiceTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  isCompleted?: InputMaybe<BooleanOperationFilterInput>;
  isCostShownOnPrint?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGlobalSynced?: InputMaybe<BooleanOperationFilterInput>;
  isPickup?: InputMaybe<BooleanOperationFilterInput>;
  isTaxFree?: InputMaybe<BooleanOperationFilterInput>;
  job?: InputMaybe<JobFilterInput>;
  jobPayment?: InputMaybe<JobPaymentFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PurchaseOrderFilterInput>>;
  orderDate?: InputMaybe<DateTimeOperationFilterInput>;
  orderNumber?: InputMaybe<IntOperationFilterInput>;
  orderStatus?: InputMaybe<StringOperationFilterInput>;
  orderTax?: InputMaybe<DecimalOperationFilterInput>;
  orderTotalExTax?: InputMaybe<DecimalOperationFilterInput>;
  orderTotalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  orderType?: InputMaybe<StringOperationFilterInput>;
  purchaseOrderInvoices?: InputMaybe<ListFilterInputTypeOfPurchaseOrderInvoiceFilterInput>;
  purchaseOrderItems?: InputMaybe<ListFilterInputTypeOfPurchaseOrderItemFilterInput>;
  receivedDate?: InputMaybe<DateTimeOperationFilterInput>;
  requiredByDate?: InputMaybe<DateTimeOperationFilterInput>;
  requiredByDateLocal?: InputMaybe<DateTimeOperationFilterInput>;
  supplierAttention?: InputMaybe<StringOperationFilterInput>;
  supplierInvoiceRef?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  taxRatesDescription?: InputMaybe<StringOperationFilterInput>;
  taxRatesShortCode?: InputMaybe<StringOperationFilterInput>;
};

export type PurchaseOrderInvoice = {
  __typename?: 'PurchaseOrderInvoice';
  iD_PurchaseOrder: Scalars['Uuid']['output'];
  iD_PurchaseOrderInvoice: Scalars['Uuid']['output'];
  isDeleted: Scalars['Boolean']['output'];
  purchaseOrderInvoiceItems?: Maybe<PurchaseOrderInvoiceItemsCollectionSegment>;
  reference?: Maybe<Scalars['String']['output']>;
};


export type PurchaseOrderInvoicePurchaseOrderInvoiceItemsArgs = {
  order?: InputMaybe<Array<PurchaseOrderInvoiceItemSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderInvoiceItemFilterInput>;
};

export type PurchaseOrderInvoiceFilterInput = {
  accSyncData?: InputMaybe<StringOperationFilterInput>;
  adjustment?: InputMaybe<DecimalOperationFilterInput>;
  amountsIncludeTax?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<PurchaseOrderInvoiceFilterInput>>;
  deliveryCost?: InputMaybe<DecimalOperationFilterInput>;
  deliveryTaxContent?: InputMaybe<StringOperationFilterInput>;
  deliveryTaxRates?: InputMaybe<StringOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrder?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrderInvoice?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  invoiceDate?: InputMaybe<DateTimeOperationFilterInput>;
  invoiceType?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PurchaseOrderInvoiceFilterInput>>;
  purchaseOrder?: InputMaybe<PurchaseOrderFilterInput>;
  purchaseOrderInvoiceItems?: InputMaybe<ListFilterInputTypeOfPurchaseOrderInvoiceItemFilterInput>;
  reference?: InputMaybe<StringOperationFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  totalIncTax?: InputMaybe<DecimalOperationFilterInput>;
};

export type PurchaseOrderInvoiceItem = {
  __typename?: 'PurchaseOrderInvoiceItem';
  iD_PurchaseOrderInvoice: Scalars['Uuid']['output'];
  iD_PurchaseOrderInvoiceItem: Scalars['Uuid']['output'];
  iD_PurchaseOrderItem: Scalars['Uuid']['output'];
  quantity: Scalars['Float']['output'];
  totalCost: Scalars['Decimal']['output'];
  unitCost: Scalars['Decimal']['output'];
};

export type PurchaseOrderInvoiceItemFilterInput = {
  and?: InputMaybe<Array<PurchaseOrderInvoiceItemFilterInput>>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  iD_PurchaseOrderInvoice?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrderInvoiceItem?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrderItem?: InputMaybe<UuidOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PurchaseOrderInvoiceItemFilterInput>>;
  purchaseOrderInvoice?: InputMaybe<PurchaseOrderInvoiceFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
  taxContent?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  totalCost?: InputMaybe<DecimalOperationFilterInput>;
  unitCost?: InputMaybe<DecimalOperationFilterInput>;
};

export type PurchaseOrderInvoiceItemSortInput = {
  costItemType?: InputMaybe<SortEnumType>;
  iD_PurchaseOrderInvoice?: InputMaybe<SortEnumType>;
  iD_PurchaseOrderInvoiceItem?: InputMaybe<SortEnumType>;
  iD_PurchaseOrderItem?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  purchaseOrderInvoice?: InputMaybe<PurchaseOrderInvoiceSortInput>;
  quantity?: InputMaybe<SortEnumType>;
  taxContent?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  totalCost?: InputMaybe<SortEnumType>;
  unitCost?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PurchaseOrderInvoiceItemsCollectionSegment = {
  __typename?: 'PurchaseOrderInvoiceItemsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PurchaseOrderInvoiceItem>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PurchaseOrderInvoiceSortInput = {
  accSyncData?: InputMaybe<SortEnumType>;
  adjustment?: InputMaybe<SortEnumType>;
  amountsIncludeTax?: InputMaybe<SortEnumType>;
  deliveryCost?: InputMaybe<SortEnumType>;
  deliveryTaxContent?: InputMaybe<SortEnumType>;
  deliveryTaxRates?: InputMaybe<SortEnumType>;
  iD_Job?: InputMaybe<SortEnumType>;
  iD_PurchaseOrder?: InputMaybe<SortEnumType>;
  iD_PurchaseOrderInvoice?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  invoiceDate?: InputMaybe<SortEnumType>;
  invoiceType?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  purchaseOrder?: InputMaybe<PurchaseOrderSortInput>;
  reference?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  total?: InputMaybe<SortEnumType>;
  totalIncTax?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PurchaseOrderInvoicesCollectionSegment = {
  __typename?: 'PurchaseOrderInvoicesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PurchaseOrderInvoice>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PurchaseOrderItem = {
  __typename?: 'PurchaseOrderItem';
  iD_PurchaseOrder: Scalars['Uuid']['output'];
  iD_PurchaseOrderItem: Scalars['Uuid']['output'];
  quantity: Scalars['Float']['output'];
  totalCost: Scalars['Decimal']['output'];
  unitCost: Scalars['Decimal']['output'];
};

export type PurchaseOrderItemFilterInput = {
  accSyncData?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PurchaseOrderItemFilterInput>>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_CostItem?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_JobItem?: InputMaybe<UuidOperationFilterInput>;
  iD_JobVariationTask?: InputMaybe<UuidOperationFilterInput>;
  iD_ParentTask?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrder?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrderItem?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isReceived?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PurchaseOrderItemFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  parentTask?: InputMaybe<StringOperationFilterInput>;
  purchaseOrder?: InputMaybe<PurchaseOrderFilterInput>;
  quantity?: InputMaybe<FloatOperationFilterInput>;
  showInSchedule?: InputMaybe<BooleanOperationFilterInput>;
  taxContent?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  totalCost?: InputMaybe<DecimalOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  unitCost?: InputMaybe<DecimalOperationFilterInput>;
};

export type PurchaseOrderItemSortInput = {
  accSyncData?: InputMaybe<SortEnumType>;
  costItemType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  iD_CostItem?: InputMaybe<SortEnumType>;
  iD_EstimateItem?: InputMaybe<SortEnumType>;
  iD_JobItem?: InputMaybe<SortEnumType>;
  iD_JobVariationTask?: InputMaybe<SortEnumType>;
  iD_ParentTask?: InputMaybe<SortEnumType>;
  iD_PurchaseOrder?: InputMaybe<SortEnumType>;
  iD_PurchaseOrderItem?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isReceived?: InputMaybe<SortEnumType>;
  itemCode?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  parentTask?: InputMaybe<SortEnumType>;
  purchaseOrder?: InputMaybe<PurchaseOrderSortInput>;
  quantity?: InputMaybe<SortEnumType>;
  showInSchedule?: InputMaybe<SortEnumType>;
  taxContent?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  totalCost?: InputMaybe<SortEnumType>;
  uOM?: InputMaybe<SortEnumType>;
  unitCost?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PurchaseOrderItemsCollectionSegment = {
  __typename?: 'PurchaseOrderItemsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PurchaseOrderItem>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PurchaseOrderSetting = {
  __typename?: 'PurchaseOrderSetting';
  iD_PurchaseOrderSetting: Scalars['Int']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  isTermsOnSeparatePage: Scalars['Boolean']['output'];
  orderNumber: Scalars['Int']['output'];
  orderTerms?: Maybe<Scalars['String']['output']>;
  orderTermsContractor?: Maybe<Scalars['String']['output']>;
  orderTermsWork?: Maybe<Scalars['String']['output']>;
};

export type PurchaseOrderSortInput = {
  accSyncData?: InputMaybe<SortEnumType>;
  accountCode?: InputMaybe<SortEnumType>;
  completedDate?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<ContactViewSortInput>;
  costPlusOrder?: InputMaybe<SortEnumType>;
  costPlusPercentage?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  deliveryAddress1?: InputMaybe<SortEnumType>;
  deliveryAddress2?: InputMaybe<SortEnumType>;
  deliveryCityTown?: InputMaybe<SortEnumType>;
  deliveryCost?: InputMaybe<SortEnumType>;
  deliveryPostCode?: InputMaybe<SortEnumType>;
  deliveryState?: InputMaybe<SortEnumType>;
  deliveryTaxContent?: InputMaybe<SortEnumType>;
  deliveryTaxRates?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_JobPayment?: InputMaybe<SortEnumType>;
  iD_PurchaseOrder?: InputMaybe<SortEnumType>;
  iD_Reference?: InputMaybe<SortEnumType>;
  iD_Supplier?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  instructions?: InputMaybe<SortEnumType>;
  invoiceTax?: InputMaybe<SortEnumType>;
  invoiceTotalIncTax?: InputMaybe<SortEnumType>;
  isCompleted?: InputMaybe<SortEnumType>;
  isCostShownOnPrint?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGlobalSynced?: InputMaybe<SortEnumType>;
  isPickup?: InputMaybe<SortEnumType>;
  isTaxFree?: InputMaybe<SortEnumType>;
  job?: InputMaybe<JobSortInput>;
  jobPayment?: InputMaybe<JobPaymentSortInput>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  orderDate?: InputMaybe<SortEnumType>;
  orderNumber?: InputMaybe<SortEnumType>;
  orderStatus?: InputMaybe<SortEnumType>;
  orderTax?: InputMaybe<SortEnumType>;
  orderTotalExTax?: InputMaybe<SortEnumType>;
  orderTotalIncTax?: InputMaybe<SortEnumType>;
  orderType?: InputMaybe<SortEnumType>;
  receivedDate?: InputMaybe<SortEnumType>;
  requiredByDate?: InputMaybe<SortEnumType>;
  requiredByDateLocal?: InputMaybe<SortEnumType>;
  supplierAttention?: InputMaybe<SortEnumType>;
  supplierInvoiceRef?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  taxRatesDescription?: InputMaybe<SortEnumType>;
  taxRatesShortCode?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PurchaseOrdersCollectionSegment = {
  __typename?: 'PurchaseOrdersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PurchaseOrder>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  accountingBillInvoices: AccountingInvoiceDtoPage;
  /** @deprecated use accountingBillInvoices */
  accountingBillInvoicesV2: AccountingInvoiceDtoPage;
  accountingOutgoingInvoices?: Maybe<AccountingOutgoingInvoicesCollectionSegment>;
  accountingSyncSetting?: Maybe<AccountingSyncSettingType>;
  activityFeed?: Maybe<ActivityFeed>;
  activityFeedAggregate: Array<ActivityFeedAggregate>;
  activityFeedComment?: Maybe<ActivityFeedComment>;
  activityFeedComments?: Maybe<ActivityFeedCommentCollectionSegment>;
  activityFeedImages?: Maybe<ActivityFeedImageCollectionSegment>;
  activityFeedLikes?: Maybe<ActivityFeedLikeCollectionSegment>;
  activityFeeds?: Maybe<ActivityFeedCollectionSegment>;
  analyticAvailableLocations?: Maybe<Array<Maybe<AnalyticLocation>>>;
  anyPublishedLeads: Scalars['Boolean']['output'];
  autoCcUsersForQuoteRequest: Array<AutoCcSearchResultModel>;
  autoCcdQuoteRequestRecipientEmails: Array<Scalars['String']['output']>;
  badges?: Maybe<BadgesDto>;
  builderStatisticsByCustomerId?: Maybe<JobStatisticRanges>;
  buildingTypeMetrics?: Maybe<BuildingTypeMetricsConnection>;
  buildingTypes?: Maybe<BuildingTypesCollectionSegment>;
  calculateScheduleTaskDuration: Scalars['Int']['output'];
  calculateScheduleTaskEndDate: Scalars['DateTime']['output'];
  catalogueAccessControls?: Maybe<QueryAccessControlConnection>;
  catalogueById?: Maybe<Catalogue>;
  catalogueCategoryByDescription?: Maybe<CatalogueCategory>;
  catalogueCategoryById?: Maybe<CatalogueCategory>;
  catalogueCategoryItems?: Maybe<Array<Maybe<CatalogueCategoryItem>>>;
  catalogueImportByBatchId?: Maybe<CatalogueImport>;
  catalogueImportByImportId?: Maybe<CatalogueImport>;
  catalogueImports?: Maybe<CatalogueImportConnection>;
  catalogueItemById?: Maybe<CatalogueItem>;
  catalogueItemPriceByItemId?: Maybe<CatalogueItemPrice>;
  catalogueItemPriceByPriceId?: Maybe<CatalogueItemPrice>;
  catalogueItemPrices?: Maybe<CatalogueItemPriceConnection>;
  catalogueItems?: Maybe<CatalogueItemConnection>;
  catalogueItemsById?: Maybe<Array<Maybe<CatalogueItem>>>;
  catalogueItemsByIdAsString?: Maybe<Scalars['String']['output']>;
  catalogueSubCategoryByDescription?: Maybe<CatalogueSubCategory>;
  catalogueSubCategoryById?: Maybe<CatalogueSubCategory>;
  catalogueSubscriptionById?: Maybe<CatalogueSubscription>;
  catalogueSubscriptions?: Maybe<CatalogueSubscriptionConnection>;
  catalogueTypeCounts?: Maybe<Array<Maybe<CatalogueTypeCount>>>;
  catalogues?: Maybe<CatalogueConnection>;
  cataloguesIsNewEndpoint?: Maybe<CataloguesGraphQlVersion>;
  checkCatalogueItemReference?: Maybe<CatalogueItemReference>;
  checkEstimateNumberExists: Scalars['Boolean']['output'];
  clientById?: Maybe<Customer>;
  clientByIds?: Maybe<Array<Maybe<Customer>>>;
  clientContactById?: Maybe<CustomerContact>;
  clientContactByIds?: Maybe<Array<Maybe<CustomerContact>>>;
  clientTypes?: Maybe<Array<Maybe<IdAndName>>>;
  connectedEstimateIds?: Maybe<Array<Scalars['Uuid']['output']>>;
  contact?: Maybe<Contact>;
  contactById: Contact;
  contactByIds: Array<Contact>;
  contacts?: Maybe<ContactsCollectionSegment>;
  conversations: Array<ConversationModel>;
  conversationsByLeadsId: Array<ConversationModel>;
  currentTenant: Tenant;
  currentUser: UserContextDto;
  currentVersion: VersionModel;
  customerById?: Maybe<Customer>;
  customerBySearch?: Maybe<Array<Maybe<CustomerContactSearchModel>>>;
  customerIntegrationConnection?: Maybe<CustomerIntegrationConnection>;
  customerPriceMatches?: Maybe<CatalogueSearchResult>;
  customers?: Maybe<CustomersCollectionSegment>;
  dashboardEstimateSummary: DashboardEstimateSummary;
  defaultPaymentTerms?: Maybe<Scalars['String']['output']>;
  deletedLayouts?: Maybe<Array<Maybe<LayoutDto>>>;
  email: EmailMessage;
  emailBlacklist?: Maybe<EmailBlacklist>;
  emailSender: EmailSender;
  erpTenantHistoryRuns?: Maybe<ErpTenantHistoryRunsCollectionSegment>;
  erpTenantSetting?: Maybe<IntegrationConnectionModel>;
  estimateAccessControls?: Maybe<EstimateAccessControlsCollectionSegment>;
  estimateCostingsBreakdown: Array<CostingsBreakdownResultModel>;
  estimateDetailsByCustomer?: Maybe<ClientEstimateDto>;
  estimateDetailsForMergeTags: EstimateModelForMergeTags;
  estimateItemById?: Maybe<EstimateItem>;
  estimateItemsByEstimateId?: Maybe<Array<Maybe<EstimateItem>>>;
  estimateMeasurements?: Maybe<Array<Maybe<EstimateMeasurement>>>;
  estimateMeasurementsByCustomer?: Maybe<Array<Maybe<EstimateMeasurement>>>;
  estimatePlans?: Maybe<PlanConnection>;
  estimatePlansByCustomer?: Maybe<Array<Maybe<Plan>>>;
  estimateSpecifications?: Maybe<EstimateSpecificationsCollectionSegment>;
  estimateSpecificationsV3?: Maybe<EstimateSpecificationsV3CollectionSegment>;
  estimateSupplierStores?: Maybe<Array<Maybe<EstimateSupplier>>>;
  estimateTemplatesForIntegrationConnection?: Maybe<EstimateTemplatesForIntegrationConnectionCollectionSegment>;
  estimates?: Maybe<EstimatesCollectionSegment>;
  estimatesByCustomer?: Maybe<EstimatesByCustomerCollectionSegment>;
  estimatesByIds: Array<Estimate>;
  estimatesWonMetrics?: Maybe<EstimatesWonMetricConnection>;
  eventRecords?: Maybe<EventRecordCollectionSegment>;
  exportGeneratedSmartEstimate?: Maybe<ExportFile>;
  fetchOrCreateRecipeCatalogueByName?: Maybe<Catalogue>;
  findDiscoverableTenants: Array<PublicTenantInfo>;
  findTenantRecipeByDescription?: Maybe<CatalogueItem>;
  forceUpgradeDetails?: Maybe<MobileAppForceUpgradeDto>;
  generateRecipeOptions?: Maybe<GenerateRecipeOptionsModel>;
  globalSearch?: Maybe<Array<Maybe<GlobalSearchIndexSearchResult>>>;
  hasAnyAcceptedClientPortalInvites: Scalars['Boolean']['output'];
  incomingIntegrationConnectionById?: Maybe<IntegrationConnection>;
  incomingIntegrationConnectionCatalogues?: Maybe<IncomingCatalogueConnection>;
  incomingIntegrationConnectionClients?: Maybe<IntegrationConnectionClientResponseModel>;
  incomingIntegrationConnectionCounts?: Maybe<Array<Maybe<IntegrationConnectionStatusCount>>>;
  incomingIntegrationConnectionEstimateTemplates?: Maybe<IncomingIntegrationConnectionEstimateTemplatesCollectionSegment>;
  incomingIntegrationConnections?: Maybe<IntegrationConnectionConnection>;
  individualsWithInviteStatus?: Maybe<Array<Maybe<IndividualInviteStatusModel>>>;
  integrationConnectionSuppliedDetails?: Maybe<IntegrationConnectionSuppliedDetails>;
  integrationProviderById?: Maybe<IntegrationProvider>;
  integrationProviders?: Maybe<IntegrationProviderConnection>;
  integrationProvidersByGroup?: Maybe<IntegrationProviderConnection>;
  invoiceAttachments?: Maybe<InvoiceAttachmentsCollectionSegment>;
  invoiceTermsAndConditions?: Maybe<JobPaymentSetting>;
  isCatalogueImportInProgress: Scalars['Boolean']['output'];
  isScheduleLocked: Scalars['Boolean']['output'];
  isSupplierCodeValid: Scalars['Boolean']['output'];
  job?: Maybe<Job>;
  jobCrew: Array<JobCrewDto>;
  jobDocumentsForCrew: Array<StorageFileSummaryDto>;
  jobInvitationDetails: JobInvitationDetailsDto;
  jobInvitations: Array<JobInvitationDto>;
  jobInvitationsForUser?: Maybe<JobInvitationsForUserCollectionSegment>;
  jobItem?: Maybe<JobItem>;
  jobItemsByJobId?: Maybe<JobItemsByJobIdCollectionSegment>;
  jobProgressMetrics?: Maybe<JobProgressMetricsConnection>;
  jobTypes?: Maybe<Array<Maybe<IdAndName>>>;
  jobVariations?: Maybe<JobVariationsCollectionSegment>;
  jobs?: Maybe<JobsCollectionSegment>;
  jobsByIds: Array<Job>;
  jobsForCrewByIds: Array<ExternalJob>;
  latestOneBuildPricing?: Maybe<Array<Maybe<LatestOneBuildPricingModel>>>;
  layout?: Maybe<LayoutDto>;
  layoutSelection?: Maybe<LayoutSelectionDto>;
  layouts?: Maybe<Array<Maybe<LayoutDto>>>;
  leadById?: Maybe<LeadModel>;
  leadByIds?: Maybe<Array<Maybe<LeadModel>>>;
  leadDetailsByCustomerId: Array<LeadDetailsForConversation>;
  leadList?: Maybe<LeadListCollectionSegment>;
  leadStatusesWithCount?: Maybe<Array<Maybe<LeadStatusWithCount>>>;
  /** @deprecated use leadList instead */
  leads?: Maybe<LeadModelConnection>;
  leadsByIds: Array<Lead>;
  leadsBySearch?: Maybe<LeadModelConnection>;
  leadsBySearchV2?: Maybe<LeadConnection>;
  leadsCountByCustomerContactId: Scalars['Int']['output'];
  leadsCountByCustomerId: Scalars['Int']['output'];
  legacyMessages?: Maybe<Array<Maybe<LegacyMessageDto>>>;
  listEmailSenders: Array<EmailSender>;
  listEmails: ItemPageResponseOfEmailListItem;
  loggedInUser?: Maybe<LoggedInUserModel>;
  matchCatalogueItems?: Maybe<Array<Maybe<MatchCatalogueItemResponseModel>>>;
  matchCatalogueItemsAsString?: Maybe<Scalars['String']['output']>;
  materialMetrics?: Maybe<MaterialMetricsConnection>;
  messagingUser: MessagingUserModel;
  myToDoItem?: Maybe<ToDoItem>;
  myToDoItems?: Maybe<MyToDoItemsCollectionSegment>;
  newTaskStartDate?: Maybe<Scalars['DateTime']['output']>;
  nonWorkingDays?: Maybe<NonWorkingDayCollectionSegment>;
  notes?: Maybe<NotesCollectionSegment>;
  noticesByModule?: Maybe<Array<Maybe<NoticeDto>>>;
  noticesByObjectId?: Maybe<Array<Maybe<NoticeDto>>>;
  noticesByObjectIds?: Maybe<Array<Maybe<NoticeDto>>>;
  noticesByTenant?: Maybe<Array<Maybe<NoticeDto>>>;
  onboardingProfile?: Maybe<UsersProfileData>;
  onboardingSettings?: Maybe<OnboardingSettingConnection>;
  orderTermsAndConditions?: Maybe<PurchaseOrderSetting>;
  outgoingIntegrationConnectionById?: Maybe<IntegrationConnection>;
  outgoingIntegrationConnectionCatalogues?: Maybe<OutgoingCatalogueConnection>;
  outgoingIntegrationConnectionCounts?: Maybe<Array<Maybe<IntegrationConnectionStatusCount>>>;
  outgoingIntegrationConnections?: Maybe<IntegrationConnectionConnection>;
  outgoingIntegrationConnectionsForQuoteRequest?: Maybe<Array<Maybe<IntegrationConnectionQuoteRequestDetailsModel>>>;
  phoneCountryCodes: Array<PhoneCountryCode>;
  purchaseOrders?: Maybe<PurchaseOrdersCollectionSegment>;
  quoteRequestGroups?: Maybe<Array<Maybe<QuoteRequestGroup>>>;
  quoteRequestItems?: Maybe<Array<Maybe<QuoteRequestItem>>>;
  quoteRequestRecipientByEstimateId?: Maybe<QuoteRequestRecipient>;
  quoteRequestRecipientGroups?: Maybe<Array<Maybe<QuoteRequestRecipientGroup>>>;
  quoteRequestRecipientGroupsByEstimateId?: Maybe<Array<Maybe<QuoteRequestRecipientGroup>>>;
  quoteRequestRecipientItems?: Maybe<Array<Maybe<QuoteRequestRecipientItem>>>;
  quoteRequestRecipients?: Maybe<QuoteRequestRecipientConnection>;
  quoteRequests?: Maybe<QuoteRequestConnection>;
  recentSearches?: Maybe<Array<Maybe<RecentSearch>>>;
  recipeCostCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recipeItemById?: Maybe<RecipeItem>;
  recipeItems?: Maybe<RecipeItemConnection>;
  recommendCatalogueItems?: Maybe<SearchCatalogueItemsModelV2>;
  referenceTenantScheduleTaskTemplates?: Maybe<ScheduleTaskTemplateCollectionSegment>;
  scheduleTaskContacts?: Maybe<ScheduleTaskContactsCollectionSegment>;
  scheduleTaskTemplates?: Maybe<ScheduleTaskTemplateCollectionSegment>;
  scheduleTasks?: Maybe<ScheduleTaskCollectionSegment>;
  scheduleTasksDependencies?: Maybe<ScheduleTaskDependencyCollectionSegment>;
  scheduleTasksDependency?: Maybe<ScheduleTaskDependency>;
  scheduleTasksGroupedByAssignee?: Maybe<ScheduleTasksGroupedByAssigneeCollectionSegment>;
  scheduleTasksGroupedByContactIds: Array<ScheduleTasksGroupedByContact>;
  scheduleTasksGroupedByReference?: Maybe<ScheduleTasksGroupedByReferenceCollectionSegment>;
  searchCatalogueCategories?: Maybe<Array<Maybe<CatalogueCategoryItem>>>;
  searchCatalogueItems?: Maybe<SearchCatalogueItemsModel>;
  searchCatalogueItemsV2?: Maybe<SearchCatalogueItemsModelV2>;
  searchCatalogues?: Maybe<Array<Maybe<SearchCatalogueModel>>>;
  searchOneBuildPricing?: Maybe<SearchOneBuildModel>;
  searchPurchaseOrders: SearchPurchaseOrdersResult;
  searchTenantsInHierarchy: Array<Tenant>;
  sections?: Maybe<Array<Maybe<SectionDto>>>;
  selectionCountForLayout: Scalars['Int']['output'];
  share?: Maybe<ShareDto>;
  shareByRecipient?: Maybe<ShareDto>;
  smartEstimateTemplates?: Maybe<SmartEstimateTemplateConnection>;
  smsHistories?: Maybe<SmsHistoryCollectionSegment>;
  snapshots: Array<SnapshotSummary>;
  specifications?: Maybe<SpecificationsCollectionSegment>;
  supplierStores?: Maybe<Array<Maybe<SupplierStore>>>;
  template?: Maybe<Estimate>;
  templates?: Maybe<TemplatesCollectionSegment>;
  tenantHasChildren: Scalars['Boolean']['output'];
  tenantRecipeCategoryItems?: Maybe<Array<Maybe<CatalogueCategoryItem>>>;
  tenantsInHierarchy: Array<Tenant>;
  toDoItem?: Maybe<ToDoItem>;
  toDoItemContacts?: Maybe<ToDoItemContactsCollectionSegment>;
  toDoItemContactsByItems?: Maybe<ToDoItemContactsByItemsCollectionSegment>;
  toDoItems?: Maybe<ToDoItemsCollectionSegment>;
  unacknowledgedClientConnections?: Maybe<Array<Maybe<ClientConnectionIdStatus>>>;
  userAnalyticFilterOptions?: Maybe<AnalyticFilterOptions>;
  userQuickStartAreaSettings: Array<UserQuickStartAreaSetting>;
  usersByUserId: Array<User>;
  usersInCurrentTenant: Array<User>;
  validateCustomerAndBranch: Scalars['String']['output'];
  webhook: WebhookRegistration;
  webhookEventLogs?: Maybe<WebhookEventLogConnection>;
  webhookEvents: Array<WebhookEventDefinition>;
  webhooks?: Maybe<WebhookRegistrationConnection>;
};


export type QueryAccountingBillInvoicesArgs = {
  contactName?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  provider: IntegrationProviderEnum;
};


export type QueryAccountingBillInvoicesV2Args = {
  contactName?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  provider: IntegrationProviderEnum;
};


export type QueryAccountingOutgoingInvoicesArgs = {
  order?: InputMaybe<Array<AccountingInvoiceDtoSortInput>>;
  provider: IntegrationProviderEnum;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountingInvoiceDtoFilterInput>;
};


export type QueryActivityFeedArgs = {
  where?: InputMaybe<ActivityFeedFilterInput>;
};


export type QueryActivityFeedAggregateArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ActivityFeedAggregateSortInput>>;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ActivityFeedFilterInput>;
};


export type QueryActivityFeedCommentArgs = {
  where?: InputMaybe<ActivityFeedCommentFilterInput>;
};


export type QueryActivityFeedCommentsArgs = {
  order?: InputMaybe<Array<ActivityFeedCommentSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityFeedCommentFilterInput>;
};


export type QueryActivityFeedImagesArgs = {
  order?: InputMaybe<Array<ActivityFeedImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityFeedImageFilterInput>;
};


export type QueryActivityFeedLikesArgs = {
  order?: InputMaybe<Array<ActivityFeedLikeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityFeedLikeFilterInput>;
};


export type QueryActivityFeedsArgs = {
  order?: InputMaybe<Array<ActivityFeedSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityFeedFilterInput>;
};


export type QueryAutoCcUsersForQuoteRequestArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type QueryAutoCcdQuoteRequestRecipientEmailsArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type QueryBadgesArgs = {
  module?: InputMaybe<NoticeModule>;
};


export type QueryBuilderStatisticsByCustomerIdArgs = {
  customerId: Scalars['Uuid']['input'];
};


export type QueryBuildingTypeMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  all?: Scalars['Boolean']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BuildingTypeMetricsSortInput>>;
  where?: InputMaybe<BuildingTypeMetricsFilterInput>;
};


export type QueryBuildingTypesArgs = {
  order?: InputMaybe<Array<BuildingTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BuildingTypeFilterInput>;
};


export type QueryCalculateScheduleTaskDurationArgs = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCalculateScheduleTaskEndDateArgs = {
  duration: Scalars['Int']['input'];
  start: Scalars['DateTime']['input'];
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCatalogueAccessControlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  catalogueId: Scalars['Uuid']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<QueryAccessControlSortInput>>;
  where?: InputMaybe<QueryAccessControlFilterInput>;
};


export type QueryCatalogueByIdArgs = {
  catalogueId: Scalars['Uuid']['input'];
};


export type QueryCatalogueCategoryByDescriptionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCatalogueCategoryByIdArgs = {
  catalogueCategoryId: Scalars['Uuid']['input'];
};


export type QueryCatalogueCategoryItemsArgs = {
  catalogueId?: InputMaybe<Scalars['Uuid']['input']>;
  excludeRecipes?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCatalogueImportByBatchIdArgs = {
  batchId: Scalars['Uuid']['input'];
};


export type QueryCatalogueImportByImportIdArgs = {
  catalogueImportId: Scalars['Uuid']['input'];
};


export type QueryCatalogueImportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  catalogueId: Scalars['Uuid']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CatalogueImportSortInput>>;
  where?: InputMaybe<CatalogueImportFilterInput>;
};


export type QueryCatalogueItemByIdArgs = {
  catalogueItemId: Scalars['Uuid']['input'];
};


export type QueryCatalogueItemPriceByItemIdArgs = {
  catalogueItemId: Scalars['Uuid']['input'];
};


export type QueryCatalogueItemPriceByPriceIdArgs = {
  catalogueItemPriceId: Scalars['Uuid']['input'];
};


export type QueryCatalogueItemPricesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CatalogueItemPriceSortInput>>;
  where?: InputMaybe<CatalogueItemPriceFilterInput>;
};


export type QueryCatalogueItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  catalogueId: Scalars['Uuid']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CatalogueItemSortInput>>;
  where?: InputMaybe<CatalogueItemFilterInput>;
};


export type QueryCatalogueItemsByIdArgs = {
  catalogueItemIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};


export type QueryCatalogueItemsByIdAsStringArgs = {
  catalogueItemIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};


export type QueryCatalogueSubCategoryByDescriptionArgs = {
  catalogueCategoryId: Scalars['Uuid']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCatalogueSubCategoryByIdArgs = {
  catalogueSubCategoryId: Scalars['Uuid']['input'];
};


export type QueryCatalogueSubscriptionByIdArgs = {
  catalogueSubscriptionId: Scalars['Uuid']['input'];
};


export type QueryCatalogueSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CatalogueSubscriptionSortInput>>;
  where?: InputMaybe<CatalogueSubscriptionFilterInput>;
};


export type QueryCataloguesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CatalogueSortInput>>;
  where?: InputMaybe<CatalogueFilterInput>;
};


export type QueryCheckCatalogueItemReferenceArgs = {
  catalogueItemId: Scalars['Uuid']['input'];
};


export type QueryCheckEstimateNumberExistsArgs = {
  number?: InputMaybe<Scalars['String']['input']>;
};


export type QueryClientByIdArgs = {
  clientId: Scalars['Uuid']['input'];
};


export type QueryClientByIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryClientContactByIdArgs = {
  customerContactId: Scalars['Uuid']['input'];
};


export type QueryClientContactByIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryContactArgs = {
  order?: InputMaybe<Array<ContactSortInput>>;
  where?: InputMaybe<ContactFilterInput>;
};


export type QueryContactByIdArgs = {
  contactId: Scalars['Uuid']['input'];
};


export type QueryContactByIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryContactsArgs = {
  order?: InputMaybe<Array<ContactSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactFilterInput>;
};


export type QueryConversationsArgs = {
  communicationType: CommunicationType;
  customerId?: InputMaybe<Scalars['Uuid']['input']>;
  leadId?: InputMaybe<Scalars['Uuid']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryConversationsByLeadsIdArgs = {
  communicationType: CommunicationType;
  leadIds: Array<Scalars['Uuid']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomerByIdArgs = {
  customerId: Scalars['Uuid']['input'];
};


export type QueryCustomerBySearchArgs = {
  customerId?: InputMaybe<Scalars['Uuid']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomerIntegrationConnectionArgs = {
  customerId: Scalars['Uuid']['input'];
  integrationConnectionId: Scalars['Uuid']['input'];
};


export type QueryCustomerPriceMatchesArgs = {
  customerId: Scalars['Uuid']['input'];
  integrationConnectionId: Scalars['Uuid']['input'];
  searchItems: Array<ErpCustomerItemPriceSearchItemModelInput>;
};


export type QueryCustomersArgs = {
  order?: InputMaybe<Array<CustomerSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type QueryEmailArgs = {
  id: Scalars['String']['input'];
};


export type QueryEmailSenderArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryErpTenantHistoryRunsArgs = {
  order?: InputMaybe<Array<ErpHistoryRunSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ErpHistoryRunFilterInput>;
};


export type QueryEstimateAccessControlsArgs = {
  estimateId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<QueryEstimateAccessControlSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QueryEstimateAccessControlFilterInput>;
};


export type QueryEstimateCostingsBreakdownArgs = {
  estimateId: Scalars['Uuid']['input'];
  estimateItemIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
};


export type QueryEstimateDetailsByCustomerArgs = {
  customerId: Scalars['Uuid']['input'];
  estimateId: Scalars['Uuid']['input'];
};


export type QueryEstimateDetailsForMergeTagsArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type QueryEstimateItemByIdArgs = {
  estimateItemId: Scalars['Uuid']['input'];
};


export type QueryEstimateItemsByEstimateIdArgs = {
  customerId: Scalars['Uuid']['input'];
  estimateId: Scalars['Uuid']['input'];
};


export type QueryEstimateMeasurementsArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type QueryEstimateMeasurementsByCustomerArgs = {
  customerId: Scalars['Uuid']['input'];
  estimateId: Scalars['Uuid']['input'];
};


export type QueryEstimatePlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  estimateId: Scalars['Uuid']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PlanSortInput>>;
  where?: InputMaybe<PlanFilterInput>;
};


export type QueryEstimatePlansByCustomerArgs = {
  customerId: Scalars['Uuid']['input'];
  estimateId: Scalars['Uuid']['input'];
};


export type QueryEstimateSpecificationsArgs = {
  estimateId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<EstimateSpecificationModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateSpecificationModelFilterInput>;
};


export type QueryEstimateSpecificationsV3Args = {
  estimateId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<EstimateSpecificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateSpecificationFilterInput>;
};


export type QueryEstimateSupplierStoresArgs = {
  estimateId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<EstimateSupplierSortInput>>;
  where?: InputMaybe<EstimateSupplierFilterInput>;
};


export type QueryEstimateTemplatesForIntegrationConnectionArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<EstimateSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateFilterInput>;
};


export type QueryEstimatesArgs = {
  options?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<EstimateSortInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<EstimateFilterInput>;
};


export type QueryEstimatesByCustomerArgs = {
  customerId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<EstimateSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateFilterInput>;
};


export type QueryEstimatesByIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryEstimatesWonMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<EstimatesWonMetricSortInput>>;
  where?: InputMaybe<EstimatesWonMetricFilterInput>;
};


export type QueryEventRecordsArgs = {
  order?: InputMaybe<Array<EventRecordSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventRecordFilterInput>;
};


export type QueryExportGeneratedSmartEstimateArgs = {
  logId: Scalars['Uuid']['input'];
  sessionId: Scalars['Uuid']['input'];
};


export type QueryFetchOrCreateRecipeCatalogueByNameArgs = {
  catalogueName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindDiscoverableTenantsArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['Uuid']['input']>;
  type: Scalars['String']['input'];
};


export type QueryFindTenantRecipeByDescriptionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
};


export type QueryForceUpgradeDetailsArgs = {
  upgradeCheckDto?: InputMaybe<MobileAppUpgradeCheckDtoInput>;
};


export type QueryGenerateRecipeOptionsArgs = {
  categoryDescription?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGlobalSearchArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  top: Scalars['Int']['input'];
  typesToSearch?: InputMaybe<Array<GlobalSearchItemType>>;
};


export type QueryIncomingIntegrationConnectionByIdArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
};


export type QueryIncomingIntegrationConnectionCataloguesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationConnectionId: Scalars['Uuid']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IncomingCatalogueSortInput>>;
  where?: InputMaybe<IncomingCatalogueFilterInput>;
};


export type QueryIncomingIntegrationConnectionClientsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  sortDesc: Scalars['Boolean']['input'];
  sortField?: InputMaybe<Scalars['String']['input']>;
  take: Scalars['Int']['input'];
};


export type QueryIncomingIntegrationConnectionEstimateTemplatesArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<IncomingEstimateTemplateDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IncomingEstimateTemplateDtoFilterInput>;
};


export type QueryIncomingIntegrationConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IntegrationConnectionSortInput>>;
  where?: InputMaybe<IntegrationConnectionFilterInput>;
};


export type QueryIndividualsWithInviteStatusArgs = {
  leadId: Scalars['Uuid']['input'];
};


export type QueryIntegrationProviderByIdArgs = {
  integrationProviderId: Scalars['Uuid']['input'];
};


export type QueryIntegrationProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IntegrationProviderSortInput>>;
  where?: InputMaybe<IntegrationProviderFilterInput>;
};


export type QueryIntegrationProvidersByGroupArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IntegrationProviderSortInput>>;
  where?: InputMaybe<IntegrationProviderFilterInput>;
};


export type QueryInvoiceAttachmentsArgs = {
  invoiceId: Scalars['String']['input'];
  order?: InputMaybe<Array<AccountingAttachmentSortInput>>;
  provider: IntegrationProviderEnum;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccountingAttachmentFilterInput>;
};


export type QueryIsScheduleLockedArgs = {
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
};


export type QueryIsSupplierCodeValidArgs = {
  catalogueId: Scalars['Uuid']['input'];
  catalogueItemId: Scalars['Uuid']['input'];
  supplierCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryJobArgs = {
  includeJobsAsCrew?: Scalars['Boolean']['input'];
  order?: InputMaybe<Array<JobSortInput>>;
  where?: InputMaybe<JobFilterInput>;
};


export type QueryJobCrewArgs = {
  jobId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<JobCrewDtoSortInput>>;
  where?: InputMaybe<JobCrewDtoFilterInput>;
};


export type QueryJobDocumentsForCrewArgs = {
  jobId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<StorageFileSummaryDtoSortInput>>;
  where?: InputMaybe<StorageFileSummaryDtoFilterInput>;
};


export type QueryJobInvitationDetailsArgs = {
  jobInvitationId: Scalars['Uuid']['input'];
};


export type QueryJobInvitationsArgs = {
  jobId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<JobInvitationDtoSortInput>>;
  where?: InputMaybe<JobInvitationDtoFilterInput>;
};


export type QueryJobInvitationsForUserArgs = {
  order?: InputMaybe<Array<JobInvitationDtoSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<JobInvitationStatus>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<JobInvitationDtoFilterInput>;
};


export type QueryJobItemArgs = {
  order?: InputMaybe<Array<JobItemSortInput>>;
  where?: InputMaybe<JobItemFilterInput>;
};


export type QueryJobItemsByJobIdArgs = {
  jobId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<JobItemSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<JobItemFilterInput>;
};


export type QueryJobProgressMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<JobProgressMetricsSortInput>>;
  where?: InputMaybe<JobProgressMetricsFilterInput>;
};


export type QueryJobVariationsArgs = {
  order?: InputMaybe<Array<JobVariationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<JobVariationFilterInput>;
};


export type QueryJobsArgs = {
  includeJobsAsCrew?: Scalars['Boolean']['input'];
  order?: InputMaybe<Array<JobSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<JobFilterInput>;
};


export type QueryJobsByIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryJobsForCrewByIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryLatestOneBuildPricingArgs = {
  county?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLayoutArgs = {
  layoutId: Scalars['Uuid']['input'];
};


export type QueryLayoutSelectionArgs = {
  referenceObjectId: Scalars['Uuid']['input'];
};


export type QueryLayoutsArgs = {
  referenceObjectId?: InputMaybe<Scalars['Uuid']['input']>;
};


export type QueryLeadByIdArgs = {
  leadId: Scalars['Uuid']['input'];
};


export type QueryLeadByIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryLeadDetailsByCustomerIdArgs = {
  customerId: Scalars['Uuid']['input'];
};


export type QueryLeadListArgs = {
  order?: InputMaybe<Array<LeadSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LeadFilterInput>;
};


export type QueryLeadStatusesWithCountArgs = {
  order?: InputMaybe<Array<LeadStatusWithCountSortInput>>;
  where?: InputMaybe<LeadStatusWithCountFilterInput>;
};


export type QueryLeadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<LeadModelSortInput>>;
  where?: InputMaybe<LeadModelFilterInput>;
};


export type QueryLeadsByIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryLeadsBySearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['Uuid']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<LeadModelSortInput>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<LeadModelFilterInput>;
};


export type QueryLeadsBySearchV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['Uuid']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<LeadSortInput>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<LeadFilterInput>;
};


export type QueryLeadsCountByCustomerContactIdArgs = {
  customerContactId: Scalars['Uuid']['input'];
};


export type QueryLeadsCountByCustomerIdArgs = {
  customerId: Scalars['Uuid']['input'];
};


export type QueryListEmailsArgs = {
  options?: InputMaybe<QueryEmailOptionsInput>;
};


export type QueryMatchCatalogueItemsArgs = {
  model?: InputMaybe<MatchCatalogueItemsModelInput>;
};


export type QueryMatchCatalogueItemsAsStringArgs = {
  model?: InputMaybe<MatchCatalogueItemsModelInput>;
};


export type QueryMaterialMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  all?: Scalars['Boolean']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<MaterialMetricsSortInput>>;
  where?: InputMaybe<MaterialMetricsFilterInput>;
};


export type QueryMyToDoItemArgs = {
  order?: InputMaybe<Array<ToDoItemSortInput>>;
  where?: InputMaybe<ToDoItemFilterInput>;
};


export type QueryMyToDoItemsArgs = {
  order?: InputMaybe<Array<ToDoItemSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ToDoItemFilterInput>;
};


export type QueryNewTaskStartDateArgs = {
  request: ScheduleTaskNewTaskStartDateRequestInput;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNonWorkingDaysArgs = {
  order?: InputMaybe<Array<NonWorkingDaySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NonWorkingDayFilterInput>;
};


export type QueryNotesArgs = {
  order?: InputMaybe<Array<NoteSortInput>>;
  referenceId: Scalars['Uuid']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoteFilterInput>;
};


export type QueryNoticesByModuleArgs = {
  module: NoticeModule;
};


export type QueryNoticesByObjectIdArgs = {
  objectId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNoticesByObjectIdsArgs = {
  module: NoticeModule;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryOnboardingSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OnboardingSettingSortInput>>;
  where?: InputMaybe<OnboardingSettingFilterInput>;
};


export type QueryOutgoingIntegrationConnectionByIdArgs = {
  integrationConnectionId: Scalars['Uuid']['input'];
};


export type QueryOutgoingIntegrationConnectionCataloguesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationConnectionId: Scalars['Uuid']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OutgoingCatalogueSortInput>>;
  where?: InputMaybe<OutgoingCatalogueFilterInput>;
};


export type QueryOutgoingIntegrationConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<IntegrationConnectionSortInput>>;
  where?: InputMaybe<IntegrationConnectionFilterInput>;
};


export type QueryPurchaseOrdersArgs = {
  order?: InputMaybe<Array<PurchaseOrderSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PurchaseOrderFilterInput>;
};


export type QueryQuoteRequestGroupsArgs = {
  quoteRequestId: Scalars['Uuid']['input'];
};


export type QueryQuoteRequestItemsArgs = {
  quoteRequestId: Scalars['Uuid']['input'];
};


export type QueryQuoteRequestRecipientByEstimateIdArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type QueryQuoteRequestRecipientGroupsArgs = {
  recipientId: Scalars['Uuid']['input'];
};


export type QueryQuoteRequestRecipientGroupsByEstimateIdArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type QueryQuoteRequestRecipientItemsArgs = {
  recipientId: Scalars['Uuid']['input'];
};


export type QueryQuoteRequestRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<QuoteRequestRecipientSortInput>>;
  quoteRequestId: Scalars['Uuid']['input'];
  where?: InputMaybe<QuoteRequestRecipientFilterInput>;
};


export type QueryQuoteRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<QuoteRequestSortInput>>;
  where?: InputMaybe<QuoteRequestFilterInput>;
};


export type QueryRecipeItemByIdArgs = {
  recipeItemId: Scalars['Uuid']['input'];
};


export type QueryRecipeItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  catalogueItemId: Scalars['Uuid']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<RecipeItemSortInput>>;
  where?: InputMaybe<RecipeItemFilterInput>;
};


export type QueryRecommendCatalogueItemsArgs = {
  model?: InputMaybe<RecommendCatalogueItemsCriteriaModelInput>;
};


export type QueryReferenceTenantScheduleTaskTemplatesArgs = {
  order?: InputMaybe<Array<ScheduleTaskTemplateSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskTemplateFilterInput>;
};


export type QueryScheduleTaskContactsArgs = {
  order?: InputMaybe<Array<ScheduleTaskContactSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskContactFilterInput>;
};


export type QueryScheduleTaskTemplatesArgs = {
  order?: InputMaybe<Array<ScheduleTaskTemplateSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskTemplateFilterInput>;
};


export type QueryScheduleTasksArgs = {
  order?: InputMaybe<Array<ScheduleTaskSortInput>>;
  referenceObjectId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};


export type QueryScheduleTasksDependenciesArgs = {
  order?: InputMaybe<Array<ScheduleTaskDependencySortInput>>;
  referenceObjectId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskDependencyFilterInput>;
};


export type QueryScheduleTasksDependencyArgs = {
  order?: InputMaybe<Array<ScheduleTaskDependencySortInput>>;
  referenceObjectId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  where?: InputMaybe<ScheduleTaskDependencyFilterInput>;
};


export type QueryScheduleTasksGroupedByAssigneeArgs = {
  model: TasksGroupedByAssigneeModelInput;
  order?: InputMaybe<Array<TasksGroupedByAssigneeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TasksGroupedByAssigneeFilterInput>;
};


export type QueryScheduleTasksGroupedByContactIdsArgs = {
  ids: Array<Scalars['Uuid']['input']>;
};


export type QueryScheduleTasksGroupedByReferenceArgs = {
  model: TasksGroupedByReferenceModelInput;
  order?: InputMaybe<Array<TasksGroupedByReferenceSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TasksGroupedByReferenceFilterInput>;
};


export type QuerySearchCatalogueCategoriesArgs = {
  model?: InputMaybe<SearchCatalogueCategoriesCriteriaModelInput>;
};


export type QuerySearchCatalogueItemsArgs = {
  model?: InputMaybe<SearchCatalogueItemsCriteriaModelInput>;
};


export type QuerySearchCatalogueItemsV2Args = {
  model?: InputMaybe<SearchCatalogueItemsCriteriaModelInput>;
};


export type QuerySearchCataloguesArgs = {
  model?: InputMaybe<SearchCataloguesCriteriaModelInput>;
};


export type QuerySearchOneBuildPricingArgs = {
  model?: InputMaybe<SearchOneBuildPricingModelInput>;
};


export type QuerySearchPurchaseOrdersArgs = {
  request: SearchPurchaseOrderRequestInput;
};


export type QuerySearchTenantsInHierarchyArgs = {
  searchText: Scalars['String']['input'];
};


export type QuerySectionsArgs = {
  layoutId: Scalars['Uuid']['input'];
};


export type QuerySelectionCountForLayoutArgs = {
  layoutId: Scalars['Uuid']['input'];
};


export type QueryShareArgs = {
  shareId: Scalars['Uuid']['input'];
  shareItemId: Scalars['Uuid']['input'];
};


export type QueryShareByRecipientArgs = {
  recipientId: Scalars['Uuid']['input'];
  shareId: Scalars['Uuid']['input'];
  shareItemId: Scalars['Uuid']['input'];
};


export type QuerySmartEstimateTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SmartEstimateTemplateSortInput>>;
  sessionId: Scalars['Uuid']['input'];
  where?: InputMaybe<SmartEstimateTemplateFilterInput>;
};


export type QuerySmsHistoriesArgs = {
  order?: InputMaybe<Array<SmsHistorySortInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SmsHistoryFilterInput>;
};


export type QuerySnapshotsArgs = {
  estimateId: Scalars['Uuid']['input'];
  order?: InputMaybe<Array<SnapshotSummarySortInput>>;
};


export type QuerySpecificationsArgs = {
  order?: InputMaybe<Array<SpecificationModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpecificationModelFilterInput>;
};


export type QuerySupplierStoresArgs = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  order?: InputMaybe<Array<SupplierStoreSortInput>>;
  supplierIdentifier?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<SupplierStoreFilterInput>;
};


export type QueryTemplateArgs = {
  estimateId: Scalars['Uuid']['input'];
};


export type QueryTemplatesArgs = {
  options?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<EstimateSortInput>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EstimateFilterInput>;
};


export type QueryToDoItemArgs = {
  order?: InputMaybe<Array<ToDoItemSortInput>>;
  where?: InputMaybe<ToDoItemFilterInput>;
};


export type QueryToDoItemContactsArgs = {
  order?: InputMaybe<Array<ToDoAssigneeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ToDoAssigneeFilterInput>;
};


export type QueryToDoItemContactsByItemsArgs = {
  order?: InputMaybe<Array<ToDoAssigneeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ToDoItemFilterInput>;
};


export type QueryToDoItemsArgs = {
  order?: InputMaybe<Array<ToDoItemSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ToDoItemFilterInput>;
};


export type QueryUsersByUserIdArgs = {
  ids: Array<InputMaybe<Scalars['Uuid']['input']>>;
};


export type QueryValidateCustomerAndBranchArgs = {
  branchCode?: InputMaybe<Scalars['String']['input']>;
  customerCode: Scalars['String']['input'];
};


export type QueryWebhookArgs = {
  webhookId: Scalars['Uuid']['input'];
};


export type QueryWebhookEventLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WebhookEventLogSortInput>>;
  where?: InputMaybe<WebhookEventLogFilterInput>;
};


export type QueryWebhooksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAccessControl = {
  __typename?: 'QueryAccessControl';
  aBN?: Maybe<Scalars['String']['output']>;
  aBNPrefix?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  addressCityTown?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPostCode?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  externalRef?: Maybe<Scalars['String']['output']>;
  iD_Catalogue?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueAccessControl: Scalars['Uuid']['output'];
  iD_ClientTenant?: Maybe<Scalars['Uuid']['output']>;
  iD_IntegrationConnection?: Maybe<Scalars['Uuid']['output']>;
  isActive: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isPreApproved: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  primaryContactEmailAddress?: Maybe<Scalars['String']['output']>;
  primaryContactName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<IntegrationConnectionStatus>;
};

/** A connection to a list of items. */
export type QueryAccessControlConnection = {
  __typename?: 'QueryAccessControlConnection';
  /** A list of edges. */
  edges?: Maybe<Array<QueryAccessControlEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<QueryAccessControl>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QueryAccessControlEdge = {
  __typename?: 'QueryAccessControlEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<QueryAccessControl>;
};

export type QueryAccessControlFilterInput = {
  aBN?: InputMaybe<StringOperationFilterInput>;
  aBNPrefix?: InputMaybe<StringOperationFilterInput>;
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  addressCityTown?: InputMaybe<StringOperationFilterInput>;
  addressCountry?: InputMaybe<StringOperationFilterInput>;
  addressPostCode?: InputMaybe<StringOperationFilterInput>;
  addressState?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<QueryAccessControlFilterInput>>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  externalRef?: InputMaybe<StringOperationFilterInput>;
  iD_Catalogue?: InputMaybe<UuidOperationFilterInput>;
  iD_CatalogueAccessControl?: InputMaybe<UuidOperationFilterInput>;
  iD_ClientTenant?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationConnection?: InputMaybe<UuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isPreApproved?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<QueryAccessControlFilterInput>>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  primaryContactEmailAddress?: InputMaybe<StringOperationFilterInput>;
  primaryContactName?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<NullableOfIntegrationConnectionStatusOperationFilterInput>;
};

export type QueryAccessControlSortInput = {
  aBN?: InputMaybe<SortEnumType>;
  aBNPrefix?: InputMaybe<SortEnumType>;
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  addressCityTown?: InputMaybe<SortEnumType>;
  addressCountry?: InputMaybe<SortEnumType>;
  addressPostCode?: InputMaybe<SortEnumType>;
  addressState?: InputMaybe<SortEnumType>;
  businessName?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  emailAddress?: InputMaybe<SortEnumType>;
  externalRef?: InputMaybe<SortEnumType>;
  iD_Catalogue?: InputMaybe<SortEnumType>;
  iD_CatalogueAccessControl?: InputMaybe<SortEnumType>;
  iD_ClientTenant?: InputMaybe<SortEnumType>;
  iD_IntegrationConnection?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isPreApproved?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  primaryContactEmailAddress?: InputMaybe<SortEnumType>;
  primaryContactName?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type QueryEmailOptionsInput = {
  continuationToken?: InputMaybe<Scalars['String']['input']>;
  currentUserOnly?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderByDesc?: InputMaybe<Scalars['Boolean']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  references?: InputMaybe<Array<EmailMessageReferenceInput>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEstimateAccessControl = {
  __typename?: 'QueryEstimateAccessControl';
  businessName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  externalRef?: Maybe<Scalars['String']['output']>;
  iD_Estimate: Scalars['Uuid']['output'];
  iD_EstimateAccessControl: Scalars['Uuid']['output'];
  iD_IntegrationConnection?: Maybe<Scalars['Uuid']['output']>;
  integrationConnectionStatus?: Maybe<IntegrationConnectionStatus>;
  isDeleted: Scalars['Boolean']['output'];
  isSubscribed: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  primaryContactName?: Maybe<Scalars['String']['output']>;
};

export type QueryEstimateAccessControlFilterInput = {
  and?: InputMaybe<Array<QueryEstimateAccessControlFilterInput>>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  deletedAt?: InputMaybe<DateTimeOperationFilterInput>;
  deletedBy?: InputMaybe<UuidOperationFilterInput>;
  externalRef?: InputMaybe<StringOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateAccessControl?: InputMaybe<UuidOperationFilterInput>;
  iD_IntegrationConnection?: InputMaybe<UuidOperationFilterInput>;
  integrationConnectionStatus?: InputMaybe<NullableOfIntegrationConnectionStatusOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isSubscribed?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<QueryEstimateAccessControlFilterInput>>;
  primaryContactName?: InputMaybe<StringOperationFilterInput>;
};

export type QueryEstimateAccessControlSortInput = {
  businessName?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  deletedAt?: InputMaybe<SortEnumType>;
  deletedBy?: InputMaybe<SortEnumType>;
  externalRef?: InputMaybe<SortEnumType>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_EstimateAccessControl?: InputMaybe<SortEnumType>;
  iD_IntegrationConnection?: InputMaybe<SortEnumType>;
  integrationConnectionStatus?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isSubscribed?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  primaryContactName?: InputMaybe<SortEnumType>;
};

export type QuoteRequest = {
  __typename?: 'QuoteRequest';
  attachments?: Maybe<Scalars['String']['output']>;
  calculationMethod: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateDeadline: Scalars['DateTime']['output'];
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  dateReleased?: Maybe<Scalars['DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  emailAccountId?: Maybe<Scalars['String']['output']>;
  estimate?: Maybe<Estimate>;
  iD_Estimate?: Maybe<Scalars['Uuid']['output']>;
  iD_InvitationPreset?: Maybe<Scalars['Uuid']['output']>;
  iD_Job?: Maybe<Scalars['Uuid']['output']>;
  iD_PurchaseOrder?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  iD_User?: Maybe<Scalars['Uuid']['output']>;
  iD_Variation?: Maybe<Scalars['Uuid']['output']>;
  includeRecipeQuantity: Scalars['Boolean']['output'];
  invitationMessage?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isStandalone: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  quoteRequestGroups?: Maybe<Array<Maybe<QuoteRequestGroup>>>;
  quoteRequestItems?: Maybe<Array<Maybe<QuoteRequestItem>>>;
  quoteRequestRecipeItems?: Maybe<Array<Maybe<QuoteRequestRecipeItem>>>;
  quoteRequestRecipientGroups?: Maybe<Array<Maybe<QuoteRequestRecipientGroup>>>;
  quoteRequestRecipientItems?: Maybe<Array<Maybe<QuoteRequestRecipientItem>>>;
  quoteRequestRecipientRecipeItems?: Maybe<Array<Maybe<QuoteRequestRecipientRecipeItem>>>;
  quoteRequestRecipients?: Maybe<Array<Maybe<QuoteRequestRecipient>>>;
  reminder1Days: Scalars['Boolean']['output'];
  reminder3Days: Scalars['Boolean']['output'];
  reminder7Days: Scalars['Boolean']['output'];
  requestNumber: Scalars['Int']['output'];
  scopeDescription?: Maybe<Scalars['String']['output']>;
  scopeItemType: Scalars['Int']['output'];
  showRecipeQty: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
  taxRates?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type QuoteRequestConnection = {
  __typename?: 'QuoteRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<QuoteRequestEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<QuoteRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuoteRequestEdge = {
  __typename?: 'QuoteRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<QuoteRequest>;
};

export type QuoteRequestFilterInput = {
  and?: InputMaybe<Array<QuoteRequestFilterInput>>;
  attachments?: InputMaybe<StringOperationFilterInput>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  dateCreated?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeadline?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeleted?: InputMaybe<DateTimeOperationFilterInput>;
  dateReleased?: InputMaybe<DateTimeOperationFilterInput>;
  dateUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  emailAccountId?: InputMaybe<StringOperationFilterInput>;
  estimate?: InputMaybe<EstimateFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_InvitationPreset?: InputMaybe<UuidOperationFilterInput>;
  iD_Job?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrder?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  iD_User?: InputMaybe<UuidOperationFilterInput>;
  iD_Variation?: InputMaybe<UuidOperationFilterInput>;
  includeRecipeQuantity?: InputMaybe<BooleanOperationFilterInput>;
  invitationMessage?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isStandalone?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<QuoteRequestFilterInput>>;
  quoteRequestGroups?: InputMaybe<ListFilterInputTypeOfQuoteRequestGroupFilterInput>;
  quoteRequestItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestItemFilterInput>;
  quoteRequestRecipeItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipeItemFilterInput>;
  quoteRequestRecipientGroups?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientGroupFilterInput>;
  quoteRequestRecipientItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientItemFilterInput>;
  quoteRequestRecipientRecipeItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientRecipeItemFilterInput>;
  quoteRequestRecipients?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientFilterInput>;
  reminder1Days?: InputMaybe<BooleanOperationFilterInput>;
  reminder3Days?: InputMaybe<BooleanOperationFilterInput>;
  reminder7Days?: InputMaybe<BooleanOperationFilterInput>;
  requestNumber?: InputMaybe<IntOperationFilterInput>;
  scopeDescription?: InputMaybe<StringOperationFilterInput>;
  scopeItemType?: InputMaybe<IntOperationFilterInput>;
  showRecipeQty?: InputMaybe<BooleanOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type QuoteRequestGroup = {
  __typename?: 'QuoteRequestGroup';
  description?: Maybe<Scalars['String']['output']>;
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest: Scalars['Uuid']['output'];
  iD_QuoteRequestGroup: Scalars['Uuid']['output'];
  order: Scalars['Int']['output'];
  quoteRequest?: Maybe<QuoteRequest>;
  quoteRequestItems?: Maybe<Array<Maybe<QuoteRequestItem>>>;
  quoteRequestRecipientGroups?: Maybe<Array<Maybe<QuoteRequestRecipientGroup>>>;
};

export type QuoteRequestGroupFilterInput = {
  and?: InputMaybe<Array<QuoteRequestGroupFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestGroup?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<QuoteRequestGroupFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  quoteRequest?: InputMaybe<QuoteRequestFilterInput>;
  quoteRequestItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestItemFilterInput>;
  quoteRequestRecipientGroups?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientGroupFilterInput>;
};

export type QuoteRequestItem = {
  __typename?: 'QuoteRequestItem';
  allowQtyEdit: Scalars['Boolean']['output'];
  calculationMethod: Scalars['Int']['output'];
  costItemType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_CostItem?: Maybe<Scalars['Uuid']['output']>;
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_JobItem?: Maybe<Scalars['Uuid']['output']>;
  iD_JobVariationTask?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest: Scalars['Uuid']['output'];
  iD_QuoteRequestGroup?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestItem: Scalars['Uuid']['output'];
  isRecipe: Scalars['Boolean']['output'];
  itemCode?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  quantity: Scalars['Decimal']['output'];
  quoteRequest?: Maybe<QuoteRequest>;
  quoteRequestGroup?: Maybe<QuoteRequestGroup>;
  quoteRequestRecipeItems?: Maybe<Array<Maybe<QuoteRequestRecipeItem>>>;
  quoteRequestRecipientItems?: Maybe<Array<Maybe<QuoteRequestRecipientItem>>>;
  taxRates?: Maybe<Scalars['String']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
};

export type QuoteRequestItemFilterInput = {
  allowQtyEdit?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<QuoteRequestItemFilterInput>>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_CostItem?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_JobItem?: InputMaybe<UuidOperationFilterInput>;
  iD_JobVariationTask?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestGroup?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestItem?: InputMaybe<UuidOperationFilterInput>;
  isRecipe?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<QuoteRequestItemFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  quoteRequest?: InputMaybe<QuoteRequestFilterInput>;
  quoteRequestGroup?: InputMaybe<QuoteRequestGroupFilterInput>;
  quoteRequestRecipeItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipeItemFilterInput>;
  quoteRequestRecipientItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientItemFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
};

export type QuoteRequestRecipeItem = {
  __typename?: 'QuoteRequestRecipeItem';
  calculationMethod: Scalars['Int']['output'];
  costItemType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_CostItem?: Maybe<Scalars['Uuid']['output']>;
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest: Scalars['Uuid']['output'];
  iD_QuoteRequestItem: Scalars['Uuid']['output'];
  iD_QuoteRequestRecipeItem: Scalars['Uuid']['output'];
  itemCode?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  quantity: Scalars['Decimal']['output'];
  quoteRequest?: Maybe<QuoteRequest>;
  quoteRequestItem?: Maybe<QuoteRequestItem>;
  quoteRequestRecipientRecipeItems?: Maybe<Array<Maybe<QuoteRequestRecipientRecipeItem>>>;
  taxRates?: Maybe<Scalars['String']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  uOMMinOrderQty?: Maybe<Scalars['Decimal']['output']>;
  uOMOrderIncrements?: Maybe<Scalars['Decimal']['output']>;
  uOMUnits?: Maybe<Scalars['Decimal']['output']>;
};

export type QuoteRequestRecipeItemFilterInput = {
  and?: InputMaybe<Array<QuoteRequestRecipeItemFilterInput>>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_CostItem?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipeItem?: InputMaybe<UuidOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<QuoteRequestRecipeItemFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  quoteRequest?: InputMaybe<QuoteRequestFilterInput>;
  quoteRequestItem?: InputMaybe<QuoteRequestItemFilterInput>;
  quoteRequestRecipientRecipeItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientRecipeItemFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  uOMMinOrderQty?: InputMaybe<DecimalOperationFilterInput>;
  uOMOrderIncrements?: InputMaybe<DecimalOperationFilterInput>;
  uOMUnits?: InputMaybe<DecimalOperationFilterInput>;
};

export type QuoteRequestRecipient = {
  __typename?: 'QuoteRequestRecipient';
  autoCcSource?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  dateAccepted?: Maybe<Scalars['DateTime']['output']>;
  dateDeclined?: Maybe<Scalars['DateTime']['output']>;
  dateNotified?: Maybe<Scalars['DateTime']['output']>;
  dateOpened?: Maybe<Scalars['DateTime']['output']>;
  datePending?: Maybe<Scalars['DateTime']['output']>;
  dateQuoting?: Maybe<Scalars['DateTime']['output']>;
  dateSubmitted?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailCC?: Maybe<Scalars['String']['output']>;
  estimate?: Maybe<Estimate>;
  extensionRequested?: Maybe<Scalars['Boolean']['output']>;
  extensionRequestedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  iD_Contact: Scalars['Uuid']['output'];
  iD_Estimate?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest: Scalars['Uuid']['output'];
  iD_QuoteRequestRecipient: Scalars['Uuid']['output'];
  isAcceptedQuote: Scalars['Boolean']['output'];
  isRegisteredForTax: Scalars['Boolean']['output'];
  isSelectedQuote: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quoteRequest?: Maybe<QuoteRequest>;
  quoteRequestDeadline?: Maybe<Scalars['DateTime']['output']>;
  quoteRequestRecipientGroups?: Maybe<Array<Maybe<QuoteRequestRecipientGroup>>>;
  quoteRequestRecipientItems?: Maybe<Array<Maybe<QuoteRequestRecipientItem>>>;
  quoteRequestRecipientRecipeItems?: Maybe<Array<Maybe<QuoteRequestRecipientRecipeItem>>>;
  quoteRequestScope?: Maybe<Scalars['String']['output']>;
  quoteStatus?: Maybe<Scalars['String']['output']>;
  responseText?: Maybe<Scalars['String']['output']>;
  step?: Maybe<Scalars['Int']['output']>;
  stepReached?: Maybe<Scalars['Int']['output']>;
  taxStatus: Scalars['Int']['output'];
  token?: Maybe<Scalars['String']['output']>;
  totalAmount: Scalars['Decimal']['output'];
};

/** A connection to a list of items. */
export type QuoteRequestRecipientConnection = {
  __typename?: 'QuoteRequestRecipientConnection';
  /** A list of edges. */
  edges?: Maybe<Array<QuoteRequestRecipientEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<QuoteRequestRecipient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuoteRequestRecipientEdge = {
  __typename?: 'QuoteRequestRecipientEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<QuoteRequestRecipient>;
};

export type QuoteRequestRecipientFilterInput = {
  and?: InputMaybe<Array<QuoteRequestRecipientFilterInput>>;
  autoCcSource?: InputMaybe<StringOperationFilterInput>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  dateAccepted?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeclined?: InputMaybe<DateTimeOperationFilterInput>;
  dateNotified?: InputMaybe<DateTimeOperationFilterInput>;
  dateOpened?: InputMaybe<DateTimeOperationFilterInput>;
  datePending?: InputMaybe<DateTimeOperationFilterInput>;
  dateQuoting?: InputMaybe<DateTimeOperationFilterInput>;
  dateSubmitted?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailCC?: InputMaybe<StringOperationFilterInput>;
  estimate?: InputMaybe<EstimateFilterInput>;
  extensionRequested?: InputMaybe<BooleanOperationFilterInput>;
  extensionRequestedAt?: InputMaybe<DateTimeOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_Estimate?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipient?: InputMaybe<UuidOperationFilterInput>;
  isAcceptedQuote?: InputMaybe<BooleanOperationFilterInput>;
  isRegisteredForTax?: InputMaybe<BooleanOperationFilterInput>;
  isSelectedQuote?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<QuoteRequestRecipientFilterInput>>;
  quoteRequest?: InputMaybe<QuoteRequestFilterInput>;
  quoteRequestRecipientGroups?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientGroupFilterInput>;
  quoteRequestRecipientItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientItemFilterInput>;
  quoteRequestRecipientRecipeItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientRecipeItemFilterInput>;
  quoteStatus?: InputMaybe<StringOperationFilterInput>;
  responseText?: InputMaybe<StringOperationFilterInput>;
  step?: InputMaybe<IntOperationFilterInput>;
  stepReached?: InputMaybe<IntOperationFilterInput>;
  taxStatus?: InputMaybe<IntOperationFilterInput>;
  token?: InputMaybe<StringOperationFilterInput>;
  totalAmount?: InputMaybe<DecimalOperationFilterInput>;
};

export type QuoteRequestRecipientGroup = {
  __typename?: 'QuoteRequestRecipientGroup';
  description?: Maybe<Scalars['String']['output']>;
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest: Scalars['Uuid']['output'];
  iD_QuoteRequestGroup?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestRecipient: Scalars['Uuid']['output'];
  iD_QuoteRequestRecipientGroup: Scalars['Uuid']['output'];
  isAddedByRecipient: Scalars['Boolean']['output'];
  orderNumber?: Maybe<Scalars['Int']['output']>;
  quoteRequest?: Maybe<QuoteRequest>;
  quoteRequestGroup?: Maybe<QuoteRequestGroup>;
  quoteRequestRecipient?: Maybe<QuoteRequestRecipient>;
  quoteRequestRecipientItems?: Maybe<Array<Maybe<QuoteRequestRecipientItem>>>;
  tax: Scalars['Decimal']['output'];
  total: Scalars['Decimal']['output'];
  totalIncTax: Scalars['Decimal']['output'];
};

export type QuoteRequestRecipientGroupFilterInput = {
  and?: InputMaybe<Array<QuoteRequestRecipientGroupFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestGroup?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipient?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipientGroup?: InputMaybe<UuidOperationFilterInput>;
  isAddedByRecipient?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<QuoteRequestRecipientGroupFilterInput>>;
  orderNumber?: InputMaybe<IntOperationFilterInput>;
  quoteRequest?: InputMaybe<QuoteRequestFilterInput>;
  quoteRequestGroup?: InputMaybe<QuoteRequestGroupFilterInput>;
  quoteRequestRecipient?: InputMaybe<QuoteRequestRecipientFilterInput>;
  quoteRequestRecipientItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientItemFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  totalIncTax?: InputMaybe<DecimalOperationFilterInput>;
};

export type QuoteRequestRecipientItem = {
  __typename?: 'QuoteRequestRecipientItem';
  calculationMethod: Scalars['Int']['output'];
  costItemType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest: Scalars['Uuid']['output'];
  iD_QuoteRequestItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestRecipient: Scalars['Uuid']['output'];
  iD_QuoteRequestRecipientGroup?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestRecipientItem: Scalars['Uuid']['output'];
  isAddedByRecipient: Scalars['Boolean']['output'];
  isRecipe: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  itemCode?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Decimal']['output']>;
  quoteRequest?: Maybe<QuoteRequest>;
  quoteRequestItem?: Maybe<QuoteRequestItem>;
  quoteRequestRecipient?: Maybe<QuoteRequestRecipient>;
  quoteRequestRecipientRecipeItems?: Maybe<Array<Maybe<QuoteRequestRecipientRecipeItem>>>;
  recipeItemsSubmitted: Scalars['Boolean']['output'];
  tax: Scalars['Decimal']['output'];
  taxRates?: Maybe<Scalars['String']['output']>;
  taxStatus: RfqItemTaxStatus;
  total: Scalars['Decimal']['output'];
  totalIncTax: Scalars['Decimal']['output'];
  uOM?: Maybe<Scalars['String']['output']>;
};

export type QuoteRequestRecipientItemFilterInput = {
  and?: InputMaybe<Array<QuoteRequestRecipientItemFilterInput>>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipient?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipientGroup?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipientItem?: InputMaybe<UuidOperationFilterInput>;
  isAddedByRecipient?: InputMaybe<BooleanOperationFilterInput>;
  isRecipe?: InputMaybe<BooleanOperationFilterInput>;
  isSelected?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<QuoteRequestRecipientItemFilterInput>>;
  orderNumber?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  quoteRequest?: InputMaybe<QuoteRequestFilterInput>;
  quoteRequestItem?: InputMaybe<QuoteRequestItemFilterInput>;
  quoteRequestRecipient?: InputMaybe<QuoteRequestRecipientFilterInput>;
  quoteRequestRecipientRecipeItems?: InputMaybe<ListFilterInputTypeOfQuoteRequestRecipientRecipeItemFilterInput>;
  recipeItemsSubmitted?: InputMaybe<BooleanOperationFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  taxStatus?: InputMaybe<RfqItemTaxStatusOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  totalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
};

export type QuoteRequestRecipientItemUpdateModelInput = {
  iD_QuoteRequestRecipientItem: Scalars['Uuid']['input'];
  isRecipe?: InputMaybe<Scalars['Boolean']['input']>;
  isSelected: Scalars['Boolean']['input'];
  quoteRequestRecipientRecipeItems?: InputMaybe<Array<InputMaybe<QuoteRequestRecipientRecipeItemUpdateModelInput>>>;
  tax: Scalars['Decimal']['input'];
  taxRates?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<RfqItemTaxStatus>;
  total: Scalars['Decimal']['input'];
  totalIncTax: Scalars['Decimal']['input'];
};

export type QuoteRequestRecipientRecipeItem = {
  __typename?: 'QuoteRequestRecipientRecipeItem';
  calculationMethod: Scalars['Int']['output'];
  costItemType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequest: Scalars['Uuid']['output'];
  iD_QuoteRequestRecipeItem?: Maybe<Scalars['Uuid']['output']>;
  iD_QuoteRequestRecipient: Scalars['Uuid']['output'];
  iD_QuoteRequestRecipientItem: Scalars['Uuid']['output'];
  iD_QuoteRequestRecipientRecipeItem: Scalars['Uuid']['output'];
  isAddedByRecipient: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  itemCode?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Decimal']['output']>;
  quoteRequest?: Maybe<QuoteRequest>;
  quoteRequestRecipeItem?: Maybe<QuoteRequestRecipeItem>;
  quoteRequestRecipient?: Maybe<QuoteRequestRecipient>;
  quoteRequestRecipientItem?: Maybe<QuoteRequestRecipientItem>;
  tax: Scalars['Decimal']['output'];
  taxRates?: Maybe<Scalars['String']['output']>;
  taxStatus: RfqItemTaxStatus;
  total: Scalars['Decimal']['output'];
  totalIncTax: Scalars['Decimal']['output'];
  uOM?: Maybe<Scalars['String']['output']>;
  uOMMinOrderQty?: Maybe<Scalars['Decimal']['output']>;
  uOMOrderIncrements?: Maybe<Scalars['Decimal']['output']>;
  uOMUnits?: Maybe<Scalars['Decimal']['output']>;
};

export type QuoteRequestRecipientRecipeItemFilterInput = {
  and?: InputMaybe<Array<QuoteRequestRecipientRecipeItemFilterInput>>;
  calculationMethod?: InputMaybe<IntOperationFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequest?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipeItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipient?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipientItem?: InputMaybe<UuidOperationFilterInput>;
  iD_QuoteRequestRecipientRecipeItem?: InputMaybe<UuidOperationFilterInput>;
  isAddedByRecipient?: InputMaybe<BooleanOperationFilterInput>;
  isSelected?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<QuoteRequestRecipientRecipeItemFilterInput>>;
  orderNumber?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  quoteRequest?: InputMaybe<QuoteRequestFilterInput>;
  quoteRequestRecipeItem?: InputMaybe<QuoteRequestRecipeItemFilterInput>;
  quoteRequestRecipient?: InputMaybe<QuoteRequestRecipientFilterInput>;
  quoteRequestRecipientItem?: InputMaybe<QuoteRequestRecipientItemFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  taxRates?: InputMaybe<StringOperationFilterInput>;
  taxStatus?: InputMaybe<RfqItemTaxStatusOperationFilterInput>;
  total?: InputMaybe<DecimalOperationFilterInput>;
  totalIncTax?: InputMaybe<DecimalOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  uOMMinOrderQty?: InputMaybe<DecimalOperationFilterInput>;
  uOMOrderIncrements?: InputMaybe<DecimalOperationFilterInput>;
  uOMUnits?: InputMaybe<DecimalOperationFilterInput>;
};

export type QuoteRequestRecipientRecipeItemUpdateModelInput = {
  iD_QuoteRequestRecipientRecipeItem: Scalars['Uuid']['input'];
  isSelected: Scalars['Boolean']['input'];
  tax: Scalars['Decimal']['input'];
  taxRates?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<RfqItemTaxStatus>;
  total: Scalars['Decimal']['input'];
  totalIncTax: Scalars['Decimal']['input'];
};

export type QuoteRequestRecipientSortInput = {
  autoCcSource?: InputMaybe<SortEnumType>;
  businessName?: InputMaybe<SortEnumType>;
  dateAccepted?: InputMaybe<SortEnumType>;
  dateDeclined?: InputMaybe<SortEnumType>;
  dateNotified?: InputMaybe<SortEnumType>;
  dateOpened?: InputMaybe<SortEnumType>;
  datePending?: InputMaybe<SortEnumType>;
  dateQuoting?: InputMaybe<SortEnumType>;
  dateSubmitted?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailCC?: InputMaybe<SortEnumType>;
  estimate?: InputMaybe<EstimateSortInput>;
  extensionRequested?: InputMaybe<SortEnumType>;
  extensionRequestedAt?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_QuoteRequest?: InputMaybe<SortEnumType>;
  iD_QuoteRequestRecipient?: InputMaybe<SortEnumType>;
  isAcceptedQuote?: InputMaybe<SortEnumType>;
  isRegisteredForTax?: InputMaybe<SortEnumType>;
  isSelectedQuote?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  quoteRequest?: InputMaybe<QuoteRequestSortInput>;
  quoteStatus?: InputMaybe<SortEnumType>;
  responseText?: InputMaybe<SortEnumType>;
  step?: InputMaybe<SortEnumType>;
  stepReached?: InputMaybe<SortEnumType>;
  taxStatus?: InputMaybe<SortEnumType>;
  token?: InputMaybe<SortEnumType>;
  totalAmount?: InputMaybe<SortEnumType>;
};

export type QuoteRequestRecipientUpdateModelInput = {
  iD_QuoteRequestRecipient: Scalars['Uuid']['input'];
  quoteRequestRecipientItems?: InputMaybe<Array<InputMaybe<QuoteRequestRecipientItemUpdateModelInput>>>;
  quoteStatus?: InputMaybe<Scalars['String']['input']>;
};

export type QuoteRequestSortInput = {
  attachments?: InputMaybe<SortEnumType>;
  calculationMethod?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  dateCreated?: InputMaybe<SortEnumType>;
  dateDeadline?: InputMaybe<SortEnumType>;
  dateDeleted?: InputMaybe<SortEnumType>;
  dateReleased?: InputMaybe<SortEnumType>;
  dateUpdated?: InputMaybe<SortEnumType>;
  emailAccountId?: InputMaybe<SortEnumType>;
  estimate?: InputMaybe<EstimateSortInput>;
  iD_Estimate?: InputMaybe<SortEnumType>;
  iD_InvitationPreset?: InputMaybe<SortEnumType>;
  iD_Job?: InputMaybe<SortEnumType>;
  iD_PurchaseOrder?: InputMaybe<SortEnumType>;
  iD_QuoteRequest?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  iD_User?: InputMaybe<SortEnumType>;
  iD_Variation?: InputMaybe<SortEnumType>;
  includeRecipeQuantity?: InputMaybe<SortEnumType>;
  invitationMessage?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isStandalone?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  reminder1Days?: InputMaybe<SortEnumType>;
  reminder3Days?: InputMaybe<SortEnumType>;
  reminder7Days?: InputMaybe<SortEnumType>;
  requestNumber?: InputMaybe<SortEnumType>;
  scopeDescription?: InputMaybe<SortEnumType>;
  scopeItemType?: InputMaybe<SortEnumType>;
  showRecipeQty?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  taxRates?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export const RfqItemTaxStatus = {
  Taxexcluded: 'TAXEXCLUDED',
  Taxexempt: 'TAXEXEMPT',
  Taxincluded: 'TAXINCLUDED'
} as const;

export type RfqItemTaxStatus = typeof RfqItemTaxStatus[keyof typeof RfqItemTaxStatus];
export type RfqItemTaxStatusOperationFilterInput = {
  eq?: InputMaybe<RfqItemTaxStatus>;
  in?: InputMaybe<Array<RfqItemTaxStatus>>;
  neq?: InputMaybe<RfqItemTaxStatus>;
  nin?: InputMaybe<Array<RfqItemTaxStatus>>;
};

export type RfqSubmitQuoteModelInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  estimateId: Scalars['Uuid']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RfqSubmitResponseModel = {
  __typename?: 'RFQSubmitResponseModel';
  quoteDateDeadline: Scalars['DateTime']['output'];
};

export type RecentSearch = {
  __typename?: 'RecentSearch';
  dateSearched: Scalars['DateTime']['output'];
  iD_RecentSearch: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  iD_User: Scalars['Uuid']['output'];
  searchText?: Maybe<Scalars['String']['output']>;
};

export type RecipeItem = {
  __typename?: 'RecipeItem';
  calculateRecipeItemActualQuantity: Scalars['Decimal']['output'];
  calculateRecipeItemTotal: Scalars['Boolean']['output'];
  catalogueItem?: Maybe<CatalogueItem>;
  costCategory?: Maybe<Scalars['String']['output']>;
  costItemType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  iD_CatalogueItem?: Maybe<Scalars['Uuid']['output']>;
  iD_ParentCatalogueItem?: Maybe<Scalars['Uuid']['output']>;
  iD_RecipeItem: Scalars['Uuid']['output'];
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  itemCode?: Maybe<Scalars['String']['output']>;
  manufCode?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  quantity?: Maybe<Scalars['Decimal']['output']>;
  rowVersion: Scalars['String']['output'];
  supplierCode?: Maybe<Scalars['String']['output']>;
  supplierMetadata?: Maybe<Scalars['String']['output']>;
  totalCost: Scalars['Decimal']['output'];
  uOM?: Maybe<Scalars['String']['output']>;
  uOMAlt1?: Maybe<Scalars['String']['output']>;
  uOMAlt1Conversion?: Maybe<Scalars['Decimal']['output']>;
  uOMAlt2?: Maybe<Scalars['String']['output']>;
  uOMAlt2Conversion?: Maybe<Scalars['Decimal']['output']>;
  uOMMinOrderQty?: Maybe<Scalars['Decimal']['output']>;
  uOMOrderIncrements?: Maybe<Scalars['Decimal']['output']>;
  uOMUnits?: Maybe<Scalars['Decimal']['output']>;
  unitCost?: Maybe<Scalars['Decimal']['output']>;
  unitCostIncMarkup?: Maybe<Scalars['Decimal']['output']>;
  units?: Maybe<Scalars['Decimal']['output']>;
  unitsExpression?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type RecipeItemConnection = {
  __typename?: 'RecipeItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RecipeItemEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<RecipeItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RecipeItemEdge = {
  __typename?: 'RecipeItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RecipeItem>;
};

export type RecipeItemFilterInput = {
  and?: InputMaybe<Array<RecipeItemFilterInput>>;
  catalogueItem?: InputMaybe<CatalogueItemFilterInput>;
  costCategory?: InputMaybe<StringOperationFilterInput>;
  costItemType?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  details?: InputMaybe<StringOperationFilterInput>;
  iD_CatalogueItem?: InputMaybe<UuidOperationFilterInput>;
  iD_ParentCatalogueItem?: InputMaybe<UuidOperationFilterInput>;
  iD_RecipeItem?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  manufCode?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RecipeItemFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  supplierCode?: InputMaybe<StringOperationFilterInput>;
  supplierMetadata?: InputMaybe<StringOperationFilterInput>;
  totalCost?: InputMaybe<DecimalOperationFilterInput>;
  uOM?: InputMaybe<StringOperationFilterInput>;
  uOMAlt1?: InputMaybe<StringOperationFilterInput>;
  uOMAlt1Conversion?: InputMaybe<DecimalOperationFilterInput>;
  uOMAlt2?: InputMaybe<StringOperationFilterInput>;
  uOMAlt2Conversion?: InputMaybe<DecimalOperationFilterInput>;
  uOMMinOrderQty?: InputMaybe<DecimalOperationFilterInput>;
  uOMOrderIncrements?: InputMaybe<DecimalOperationFilterInput>;
  unitCost?: InputMaybe<DecimalOperationFilterInput>;
  unitsExpression?: InputMaybe<StringOperationFilterInput>;
};

export type RecipeItemSortInput = {
  catalogueItem?: InputMaybe<CatalogueItemSortInput>;
  costCategory?: InputMaybe<SortEnumType>;
  costItemType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  details?: InputMaybe<SortEnumType>;
  iD_CatalogueItem?: InputMaybe<SortEnumType>;
  iD_ParentCatalogueItem?: InputMaybe<SortEnumType>;
  iD_RecipeItem?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  manufCode?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  rowVersion?: InputMaybe<SortEnumType>;
  supplierCode?: InputMaybe<SortEnumType>;
  supplierMetadata?: InputMaybe<SortEnumType>;
  totalCost?: InputMaybe<SortEnumType>;
  uOM?: InputMaybe<SortEnumType>;
  uOMAlt1?: InputMaybe<SortEnumType>;
  uOMAlt1Conversion?: InputMaybe<SortEnumType>;
  uOMAlt2?: InputMaybe<SortEnumType>;
  uOMAlt2Conversion?: InputMaybe<SortEnumType>;
  uOMMinOrderQty?: InputMaybe<SortEnumType>;
  uOMOrderIncrements?: InputMaybe<SortEnumType>;
  unitCost?: InputMaybe<SortEnumType>;
  unitsExpression?: InputMaybe<SortEnumType>;
};

export type RecipientDto = {
  __typename?: 'RecipientDto';
  email?: Maybe<Scalars['String']['output']>;
  identifiers: Array<IdentifierDto>;
  name?: Maybe<Scalars['String']['output']>;
  recipientId?: Maybe<Scalars['Uuid']['output']>;
  type: RecipientType;
};

export type RecipientDtoInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  identifiers: Array<IdentifierDtoInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  recipientId?: InputMaybe<Scalars['Uuid']['input']>;
  type: RecipientType;
};

export const RecipientType = {
  BxBuilder: 'BX_BUILDER',
  BxCustomerContact: 'BX_CUSTOMER_CONTACT',
  BxMerchant: 'BX_MERCHANT',
  BxTenant: 'BX_TENANT',
  ClientPortalUser: 'CLIENT_PORTAL_USER',
  Email: 'EMAIL',
  Unknown: 'UNKNOWN'
} as const;

export type RecipientType = typeof RecipientType[keyof typeof RecipientType];
export type RecommendCatalogueItemsCriteriaModelInput = {
  excludeItems: Scalars['Boolean']['input'];
  excludeRecipes: Scalars['Boolean']['input'];
  mode?: InputMaybe<SearchMode>;
  populateCatalogueFields?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  top: Scalars['Int']['input'];
};

export type ReferenceIdError = Error & {
  __typename?: 'ReferenceIdError';
  message: Scalars['String']['output'];
};

export type RegionalRestrictionInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterUserDeviceInput = {
  mobileDevice?: InputMaybe<MobileDeviceDtoInput>;
};

export type RegisterUserDevicePayload = {
  __typename?: 'RegisterUserDevicePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type RelatedEntityIdError = Error & {
  __typename?: 'RelatedEntityIdError';
  message: Scalars['String']['output'];
};

export type RemoveBuildingTypesError = NotFoundError;

export type RemoveBuildingTypesInput = {
  idsToRemove: Array<Scalars['Uuid']['input']>;
};

export type RemoveBuildingTypesPayload = {
  __typename?: 'RemoveBuildingTypesPayload';
  errors?: Maybe<Array<RemoveBuildingTypesError>>;
  removedIds?: Maybe<Array<Scalars['Uuid']['output']>>;
};

export type ReorderEstimateSpecificationModelInput = {
  iD_EstimateSpecification: Scalars['Uuid']['input'];
  newOrderNumber: Scalars['Float']['input'];
};

/** A segment of a collection. */
export type ReorderEstimateSpecificationsCategoryV3CollectionSegment = {
  __typename?: 'ReorderEstimateSpecificationsCategoryV3CollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EstimateSpecification>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReorderEstimateSpecificationsCategoryV3Error = ValidationError;

export type ReorderEstimateSpecificationsCategoryV3Input = {
  reorderModel: Array<ReorderEstimateSpecificationModelInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ReorderEstimateSpecificationsCategoryV3Payload = {
  __typename?: 'ReorderEstimateSpecificationsCategoryV3Payload';
  errors?: Maybe<Array<ReorderEstimateSpecificationsCategoryV3Error>>;
  reorderEstimateSpecificationsCategoryV3CollectionSegment?: Maybe<ReorderEstimateSpecificationsCategoryV3CollectionSegment>;
};

/** A segment of a collection. */
export type ReorderEstimateSpecificationsCollectionSegment = {
  __typename?: 'ReorderEstimateSpecificationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<EstimateSpecificationModel>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type RequestLatestPricesProvider = {
  __typename?: 'RequestLatestPricesProvider';
  description?: Maybe<Scalars['String']['output']>;
  integrationProviderId: Scalars['Uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type RequestLatestPricesResult = {
  __typename?: 'RequestLatestPricesResult';
  requests?: Maybe<Array<Maybe<RequestLatestPricesProvider>>>;
};

export type ResendEmailSenderConfirmationInput = {
  emailSenderId: Scalars['Uuid']['input'];
};

export type ResendEmailSenderConfirmationPayload = {
  __typename?: 'ResendEmailSenderConfirmationPayload';
  emailSender?: Maybe<EmailSender>;
};

export type ScalePlansModelInput = {
  iD_Plans?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  scale: Scalars['Float']['input'];
  scaleRatio?: InputMaybe<Scalars['String']['input']>;
  scaleUnit?: InputMaybe<Scalars['String']['input']>;
  vScale: Scalars['Float']['input'];
};

export type ScheduleLockedError = Error & {
  __typename?: 'ScheduleLockedError';
  message: Scalars['String']['output'];
};

export type ScheduleReference = Estimate | Job;

export type ScheduleTask = {
  __typename?: 'ScheduleTask';
  assignedResources?: Maybe<Scalars['String']['output']>;
  assignees?: Maybe<AssigneesCollectionSegment>;
  childInfo: ScheduleTaskChildInfo;
  colour: TaskColour;
  dependencies?: Maybe<Array<ScheduleTask>>;
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  effort?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  hasParent: Scalars['Boolean']['output'];
  iD_EstimateItem?: Maybe<Scalars['Uuid']['output']>;
  iD_Reference: Scalars['Uuid']['output'];
  iD_Task: Scalars['Uuid']['output'];
  internalNote?: Maybe<Scalars['String']['output']>;
  isCompleted: Scalars['Boolean']['output'];
  isCustomerMilestone: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isInvoicePoint?: Maybe<Scalars['Boolean']['output']>;
  isOrderPoint?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use ReminderMessage instead */
  note?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<ScheduleTask>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
  preferredStartTime?: Maybe<Scalars['DateTime']['output']>;
  progressPercent?: Maybe<Scalars['Int']['output']>;
  reference: ScheduleReference;
  referenceType: ScheduleTaskReference;
  reminder?: Maybe<ScheduleTaskReminderTimeframe>;
  reminderMessage?: Maybe<Scalars['String']['output']>;
  sendEmail: Scalars['Boolean']['output'];
  sendSms: Scalars['Boolean']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['DateTime']['output'];
  status?: Maybe<Scalars['String']['output']>;
  taskName: Scalars['String']['output'];
  taskNumberChild: Scalars['Int']['output'];
  taskNumberParent: Scalars['Int']['output'];
};


export type ScheduleTaskAssigneesArgs = {
  order?: InputMaybe<Array<ScheduleTaskContactSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskContactFilterInput>;
};


export type ScheduleTaskChildInfoArgs = {
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};


export type ScheduleTaskDurationArgs = {
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleTaskChildInfo = {
  __typename?: 'ScheduleTaskChildInfo';
  hasChildTask: Scalars['Boolean']['output'];
  hasDelayedChildTask: Scalars['Boolean']['output'];
};

/** A segment of a collection. */
export type ScheduleTaskCollectionSegment = {
  __typename?: 'ScheduleTaskCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScheduleTask>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScheduleTaskContact = {
  __typename?: 'ScheduleTaskContact';
  /** --FUSION ONLY PROPERTY-- */
  contact?: Maybe<Contact>;
  contactId: Scalars['Uuid']['output'];
  scheduleTask?: Maybe<ScheduleTask>;
  scheduleTaskContactId: Scalars['Uuid']['output'];
  scheduleTaskId: Scalars['Uuid']['output'];
};

export type ScheduleTaskContactCreateModelInput = {
  contactId: Scalars['Uuid']['input'];
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  scheduleTaskId: Scalars['Uuid']['input'];
};

export type ScheduleTaskContactDeleteModelInput = {
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  scheduleTaskContactId: Scalars['Uuid']['input'];
};

export type ScheduleTaskContactFilterInput = {
  and?: InputMaybe<Array<ScheduleTaskContactFilterInput>>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleTaskContactFilterInput>>;
  scheduleTask?: InputMaybe<ScheduleTaskFilterInput>;
  scheduleTaskContactId?: InputMaybe<UuidOperationFilterInput>;
  scheduleTaskId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type ScheduleTaskContactNotFound = Error & {
  __typename?: 'ScheduleTaskContactNotFound';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['Uuid']['output']>;
  message: Scalars['String']['output'];
};

export type ScheduleTaskContactSortInput = {
  contactId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  scheduleTask?: InputMaybe<ScheduleTaskSortInput>;
  scheduleTaskContactId?: InputMaybe<SortEnumType>;
  scheduleTaskId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type ScheduleTaskContactsCollectionSegment = {
  __typename?: 'ScheduleTaskContactsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScheduleTaskContact>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScheduleTaskCreateModelInput = {
  colour?: InputMaybe<TaskColour>;
  endDate: Scalars['DateTime']['input'];
  internalNote?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Uuid']['input']>;
  progressPercent?: InputMaybe<Scalars['Int']['input']>;
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  reminder?: InputMaybe<ScheduleTaskReminderTimeframe>;
  reminderMessage?: InputMaybe<Scalars['String']['input']>;
  sendEmail: Scalars['Boolean']['input'];
  startDate: Scalars['DateTime']['input'];
  taskId: Scalars['Uuid']['input'];
  taskName: Scalars['String']['input'];
  taskNumberOverride?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduleTaskCreateResult = {
  __typename?: 'ScheduleTaskCreateResult';
  deletedDependencyIds: Array<Scalars['Uuid']['output']>;
  scheduleTask: ScheduleTask;
};

export type ScheduleTaskDeleteModelInput = {
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  taskId: Scalars['Uuid']['input'];
};

export type ScheduleTaskDeleteResult = {
  __typename?: 'ScheduleTaskDeleteResult';
  deletedTaskIds: Array<Scalars['Uuid']['output']>;
  parent?: Maybe<ScheduleTask>;
};

export type ScheduleTaskDependenciesCreateModelInput = {
  dependencies: Array<ScheduleTaskDependencyModelInput>;
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
};

export type ScheduleTaskDependenciesDeleteModelInput = {
  dependencyIds: Array<Scalars['Uuid']['input']>;
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
};

export type ScheduleTaskDependency = {
  __typename?: 'ScheduleTaskDependency';
  dependencyType: DependencyType;
  iD_Dependency: Scalars['Uuid']['output'];
  iD_Reference: Scalars['Uuid']['output'];
  iD_SourceTask: Scalars['Uuid']['output'];
  iD_TargetTask: Scalars['Uuid']['output'];
};

/** A segment of a collection. */
export type ScheduleTaskDependencyCollectionSegment = {
  __typename?: 'ScheduleTaskDependencyCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScheduleTaskDependency>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScheduleTaskDependencyFilterInput = {
  and?: InputMaybe<Array<ScheduleTaskDependencyFilterInput>>;
  dependencyType?: InputMaybe<DependencyTypeOperationFilterInput>;
  iD_Dependency?: InputMaybe<UuidOperationFilterInput>;
  iD_Reference?: InputMaybe<UuidOperationFilterInput>;
  iD_SourceTask?: InputMaybe<UuidOperationFilterInput>;
  iD_TargetTask?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleTaskDependencyFilterInput>>;
  sourceTask?: InputMaybe<ScheduleTaskFilterInput>;
  targetTask?: InputMaybe<ScheduleTaskFilterInput>;
};

export type ScheduleTaskDependencyModelInput = {
  dependencyId: Scalars['Uuid']['input'];
  dependencyType: DependencyType;
  sourceId: Scalars['Uuid']['input'];
  targetId: Scalars['Uuid']['input'];
};

export type ScheduleTaskDependencyNotFound = Error & {
  __typename?: 'ScheduleTaskDependencyNotFound';
  message: Scalars['String']['output'];
};

export type ScheduleTaskDependencySortInput = {
  dependencyType?: InputMaybe<SortEnumType>;
  iD_Dependency?: InputMaybe<SortEnumType>;
  iD_Reference?: InputMaybe<SortEnumType>;
  iD_SourceTask?: InputMaybe<SortEnumType>;
  iD_TargetTask?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  sourceTask?: InputMaybe<ScheduleTaskSortInput>;
  targetTask?: InputMaybe<ScheduleTaskSortInput>;
};

export type ScheduleTaskDuplicateModelInput = {
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  taskId: Scalars['Uuid']['input'];
};

export type ScheduleTaskDuplicateResult = {
  __typename?: 'ScheduleTaskDuplicateResult';
  dependencies: Array<ScheduleTaskDependency>;
  tasks: Array<ScheduleTask>;
};

export type ScheduleTaskFilterInput = {
  actualCost?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<ScheduleTaskFilterInput>>;
  assignedResources?: InputMaybe<StringOperationFilterInput>;
  balanceCost?: InputMaybe<DecimalOperationFilterInput>;
  colour?: InputMaybe<NullableOfTaskColourOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  effort?: InputMaybe<StringOperationFilterInput>;
  endDate?: InputMaybe<DateTimeOperationFilterInput>;
  estimatedCost?: InputMaybe<DecimalOperationFilterInput>;
  hasParent?: InputMaybe<BooleanOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_EstimateItem?: InputMaybe<UuidOperationFilterInput>;
  iD_JobVariationTask?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrder?: InputMaybe<UuidOperationFilterInput>;
  iD_PurchaseOrderItem?: InputMaybe<UuidOperationFilterInput>;
  iD_Reference?: InputMaybe<UuidOperationFilterInput>;
  iD_Supplier?: InputMaybe<UuidOperationFilterInput>;
  iD_Task?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  iD_User?: InputMaybe<UuidOperationFilterInput>;
  iD_Variation?: InputMaybe<UuidOperationFilterInput>;
  indentLevel?: InputMaybe<IntOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  internalNote?: InputMaybe<StringOperationFilterInput>;
  isCompleted?: InputMaybe<BooleanOperationFilterInput>;
  isCustomerMilestone?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isHidden?: InputMaybe<BooleanOperationFilterInput>;
  isInvoicePoint?: InputMaybe<BooleanOperationFilterInput>;
  isOrderPoint?: InputMaybe<BooleanOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleTaskFilterInput>>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  preferredStartTime?: InputMaybe<DateTimeOperationFilterInput>;
  progressPercent?: InputMaybe<IntOperationFilterInput>;
  referenceType?: InputMaybe<ScheduleTaskReferenceOperationFilterInput>;
  reminder?: InputMaybe<NullableOfScheduleTaskReminderTimeframeOperationFilterInput>;
  reminderMessage?: InputMaybe<StringOperationFilterInput>;
  sendEmail?: InputMaybe<BooleanOperationFilterInput>;
  sendSms?: InputMaybe<BooleanOperationFilterInput>;
  sortOrder?: InputMaybe<IntOperationFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  taskName?: InputMaybe<StringOperationFilterInput>;
  taskNumberChild?: InputMaybe<IntOperationFilterInput>;
  taskNumberParent?: InputMaybe<IntOperationFilterInput>;
  timeZone?: InputMaybe<TimeZoneInfoFilterInput>;
};

export type ScheduleTaskMoveModelInput = {
  parentId?: InputMaybe<Scalars['Uuid']['input']>;
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  taskId: Scalars['Uuid']['input'];
  taskNumberChild: Scalars['Int']['input'];
  taskNumberParent: Scalars['Int']['input'];
};

export type ScheduleTaskMoveResult = {
  __typename?: 'ScheduleTaskMoveResult';
  deletedDependencyIds: Array<Scalars['Uuid']['output']>;
  tasks?: Maybe<ScheduleTaskCollectionSegment>;
};


export type ScheduleTaskMoveResultTasksArgs = {
  order?: InputMaybe<Array<ScheduleTaskSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};

export type ScheduleTaskNewTaskStartDateRequestInput = {
  parentId?: InputMaybe<Scalars['Uuid']['input']>;
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  targetTaskNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduleTaskNotFound = Error & {
  __typename?: 'ScheduleTaskNotFound';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['Uuid']['output']>;
  message: Scalars['String']['output'];
};

export const ScheduleTaskReference = {
  Estimate: 'ESTIMATE',
  Job: 'JOB'
} as const;

export type ScheduleTaskReference = typeof ScheduleTaskReference[keyof typeof ScheduleTaskReference];
export type ScheduleTaskReferenceOperationFilterInput = {
  eq?: InputMaybe<ScheduleTaskReference>;
  in?: InputMaybe<Array<ScheduleTaskReference>>;
  neq?: InputMaybe<ScheduleTaskReference>;
  nin?: InputMaybe<Array<ScheduleTaskReference>>;
};

export const ScheduleTaskReminderTimeframe = {
  FiveDaysBefore: 'FIVE_DAYS_BEFORE',
  FourDaysBefore: 'FOUR_DAYS_BEFORE',
  None: 'NONE',
  OneDayBefore: 'ONE_DAY_BEFORE',
  OneMonthBefore: 'ONE_MONTH_BEFORE',
  OneWeekBefore: 'ONE_WEEK_BEFORE',
  SameDay: 'SAME_DAY',
  ThreeDaysBefore: 'THREE_DAYS_BEFORE',
  ThreeWeeksBefore: 'THREE_WEEKS_BEFORE',
  TwoDaysBefore: 'TWO_DAYS_BEFORE',
  TwoWeeksBefore: 'TWO_WEEKS_BEFORE'
} as const;

export type ScheduleTaskReminderTimeframe = typeof ScheduleTaskReminderTimeframe[keyof typeof ScheduleTaskReminderTimeframe];
export type ScheduleTaskSortInput = {
  actualCost?: InputMaybe<SortEnumType>;
  assignedResources?: InputMaybe<SortEnumType>;
  balanceCost?: InputMaybe<SortEnumType>;
  colour?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  effort?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  estimatedCost?: InputMaybe<SortEnumType>;
  hasParent?: InputMaybe<SortEnumType>;
  iD_Contact?: InputMaybe<SortEnumType>;
  iD_EstimateItem?: InputMaybe<SortEnumType>;
  iD_JobVariationTask?: InputMaybe<SortEnumType>;
  iD_PurchaseOrder?: InputMaybe<SortEnumType>;
  iD_PurchaseOrderItem?: InputMaybe<SortEnumType>;
  iD_Reference?: InputMaybe<SortEnumType>;
  iD_Supplier?: InputMaybe<SortEnumType>;
  iD_Task?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  iD_User?: InputMaybe<SortEnumType>;
  iD_Variation?: InputMaybe<SortEnumType>;
  indentLevel?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  internalNote?: InputMaybe<SortEnumType>;
  isCompleted?: InputMaybe<SortEnumType>;
  isCustomerMilestone?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isHidden?: InputMaybe<SortEnumType>;
  isInvoicePoint?: InputMaybe<SortEnumType>;
  isOrderPoint?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  parentId?: InputMaybe<SortEnumType>;
  preferredStartTime?: InputMaybe<SortEnumType>;
  progressPercent?: InputMaybe<SortEnumType>;
  referenceType?: InputMaybe<SortEnumType>;
  reminder?: InputMaybe<SortEnumType>;
  reminderMessage?: InputMaybe<SortEnumType>;
  sendEmail?: InputMaybe<SortEnumType>;
  sendSms?: InputMaybe<SortEnumType>;
  sortOrder?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  taskName?: InputMaybe<SortEnumType>;
  taskNumberChild?: InputMaybe<SortEnumType>;
  taskNumberParent?: InputMaybe<SortEnumType>;
  timeZone?: InputMaybe<TimeZoneInfoSortInput>;
};

export type ScheduleTaskTemplate = {
  __typename?: 'ScheduleTaskTemplate';
  description: Scalars['String']['output'];
  iD_TaskTemplate: Scalars['Uuid']['output'];
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  referenceType: ScheduleTaskReference;
};

/** A segment of a collection. */
export type ScheduleTaskTemplateCollectionSegment = {
  __typename?: 'ScheduleTaskTemplateCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScheduleTaskTemplate>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScheduleTaskTemplateFilterInput = {
  and?: InputMaybe<Array<ScheduleTaskTemplateFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_TaskTemplate?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleTaskTemplateFilterInput>>;
  referenceType?: InputMaybe<ScheduleTaskReferenceOperationFilterInput>;
  scheduleTaskTemplateItemDependencies?: InputMaybe<ListFilterInputTypeOfScheduleTaskTemplateItemDependencyFilterInput>;
  scheduleTaskTemplateItems?: InputMaybe<ListFilterInputTypeOfScheduleTaskTemplateItemFilterInput>;
};

export type ScheduleTaskTemplateItemDependencyFilterInput = {
  and?: InputMaybe<Array<ScheduleTaskTemplateItemDependencyFilterInput>>;
  dependencyType?: InputMaybe<DependencyTypeOperationFilterInput>;
  iD_SourceTask?: InputMaybe<UuidOperationFilterInput>;
  iD_TargetTask?: InputMaybe<UuidOperationFilterInput>;
  iD_TaskTemplate?: InputMaybe<UuidOperationFilterInput>;
  iD_TaskTemplateItemDependency?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleTaskTemplateItemDependencyFilterInput>>;
  scheduleTaskTemplate?: InputMaybe<ScheduleTaskTemplateFilterInput>;
};

export type ScheduleTaskTemplateItemFilterInput = {
  allContactIds?: InputMaybe<ListUuidOperationFilterInput>;
  and?: InputMaybe<Array<ScheduleTaskTemplateItemFilterInput>>;
  colour?: InputMaybe<NullableOfTaskColourOperationFilterInput>;
  contactIds?: InputMaybe<ListUuidOperationFilterInput>;
  delayDays?: InputMaybe<IntOperationFilterInput>;
  duration?: InputMaybe<IntOperationFilterInput>;
  iD_Contact?: InputMaybe<UuidOperationFilterInput>;
  iD_Supplier?: InputMaybe<UuidOperationFilterInput>;
  iD_TaskTemplate?: InputMaybe<UuidOperationFilterInput>;
  iD_TaskTemplateItem?: InputMaybe<UuidOperationFilterInput>;
  internalNote?: InputMaybe<StringOperationFilterInput>;
  isCustomerMilestone?: InputMaybe<BooleanOperationFilterInput>;
  isParent?: InputMaybe<BooleanOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ScheduleTaskTemplateItemFilterInput>>;
  parentId?: InputMaybe<UuidOperationFilterInput>;
  reminder?: InputMaybe<NullableOfScheduleTaskReminderTimeframeOperationFilterInput>;
  reminderMessage?: InputMaybe<StringOperationFilterInput>;
  scheduleTaskTemplate?: InputMaybe<ScheduleTaskTemplateFilterInput>;
  sendEmail?: InputMaybe<BooleanOperationFilterInput>;
  sortOrder?: InputMaybe<IntOperationFilterInput>;
  taskName?: InputMaybe<StringOperationFilterInput>;
  taskNumberChild?: InputMaybe<IntOperationFilterInput>;
  taskNumberParent?: InputMaybe<IntOperationFilterInput>;
};

export type ScheduleTaskTemplateNotFound = Error & {
  __typename?: 'ScheduleTaskTemplateNotFound';
  message: Scalars['String']['output'];
};

export type ScheduleTaskTemplateSortInput = {
  description?: InputMaybe<SortEnumType>;
  iD_TaskTemplate?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  referenceType?: InputMaybe<SortEnumType>;
};

export type ScheduleTaskUpdateModelInput = {
  colour?: InputMaybe<TaskColour>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  internalNote?: InputMaybe<Scalars['String']['input']>;
  progressPercent?: InputMaybe<Scalars['Int']['input']>;
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  reminder?: InputMaybe<ScheduleTaskReminderTimeframe>;
  reminderMessage?: InputMaybe<Scalars['String']['input']>;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  taskId: Scalars['Uuid']['input'];
  taskName?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleTaskUpdateProgressModelInput = {
  progressPercent: Scalars['Int']['input'];
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
};

/** A segment of a collection. */
export type ScheduleTasksGroupedByAssigneeCollectionSegment = {
  __typename?: 'ScheduleTasksGroupedByAssigneeCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<TasksGroupedByAssignee>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScheduleTasksGroupedByContact = {
  __typename?: 'ScheduleTasksGroupedByContact';
  contactId?: Maybe<Scalars['Uuid']['output']>;
  tasks?: Maybe<TasksCollectionSegment>;
  uniqueTaskReferences?: Maybe<UniqueTaskReferencesCollectionSegment>;
};


export type ScheduleTasksGroupedByContactTasksArgs = {
  order?: InputMaybe<Array<ScheduleTaskContactSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};


export type ScheduleTasksGroupedByContactUniqueTaskReferencesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};

/** A segment of a collection. */
export type ScheduleTasksGroupedByReferenceCollectionSegment = {
  __typename?: 'ScheduleTasksGroupedByReferenceCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<TasksGroupedByReference>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ScheduleTemplateApplyModelInput = {
  copyDependencies: Scalars['Boolean']['input'];
  copyInternalNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Use CopyReminderMessages instead */
  copyNotes?: InputMaybe<Scalars['Boolean']['input']>;
  copyReminderMessages?: InputMaybe<Scalars['Boolean']['input']>;
  copyReminders: Scalars['Boolean']['input'];
  copyResource: Scalars['Boolean']['input'];
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  removeExisting: Scalars['Boolean']['input'];
  startDate: Scalars['DateTime']['input'];
  templateId: Scalars['Uuid']['input'];
};

export type ScheduleTemplateCreateModelInput = {
  description: Scalars['String']['input'];
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
};

export type ScheduleUpdateStartDateModelInput = {
  referenceId: Scalars['Uuid']['input'];
  referenceType: ScheduleTaskReference;
  startDate: Scalars['DateTime']['input'];
};

export type ScheduleUpdateStartDateResult = {
  __typename?: 'ScheduleUpdateStartDateResult';
  all?: Maybe<AllCollectionSegment>;
};


export type ScheduleUpdateStartDateResultAllArgs = {
  order?: InputMaybe<Array<ScheduleTaskSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};

export type SearchCatalogueCategoriesCriteriaModelInput = {
  catalogueId?: InputMaybe<Scalars['Uuid']['input']>;
  excludeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  excludeItems: Scalars['Boolean']['input'];
  excludeRecipes: Scalars['Boolean']['input'];
  mode?: InputMaybe<SearchMode>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type SearchCatalogueItemModel = {
  __typename?: 'SearchCatalogueItemModel';
  catalogueDescription?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  costItemType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_Catalogue?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueCategory?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueItem: Scalars['Uuid']['output'];
  iD_CatalogueSubCategory?: Maybe<Scalars['Uuid']['output']>;
  iD_Image?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isRecipe: Scalars['Boolean']['output'];
  itemPrice?: Maybe<CatalogueItemPrice>;
  subCategory?: Maybe<Scalars['String']['output']>;
  supplierCode?: Maybe<Scalars['String']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  unitCost: Scalars['Decimal']['output'];
};

export type SearchCatalogueItemModelV2 = {
  __typename?: 'SearchCatalogueItemModelV2';
  availability: CatalogueItemAvailability;
  catalogueCategory?: Maybe<CatalogueCategory>;
  catalogueContactId?: Maybe<Scalars['Uuid']['output']>;
  catalogueDescription?: Maybe<Scalars['String']['output']>;
  catalogueLogoThumbnailUrl?: Maybe<Scalars['String']['output']>;
  catalogueLogoUrl?: Maybe<Scalars['String']['output']>;
  catalogueName?: Maybe<Scalars['String']['output']>;
  catalogueSubCategory?: Maybe<CatalogueSubCategory>;
  catalogueSupplierDescription?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  costBreakdown?: Maybe<Scalars['String']['output']>;
  costItemType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  datasheetUrl?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateDeleted?: Maybe<Scalars['DateTime']['output']>;
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  fromTenantName?: Maybe<Scalars['String']['output']>;
  iD_Catalogue?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueCategory?: Maybe<Scalars['Uuid']['output']>;
  iD_CatalogueItem: Scalars['Uuid']['output'];
  iD_CatalogueSubCategory?: Maybe<Scalars['Uuid']['output']>;
  iD_Image?: Maybe<Scalars['Uuid']['output']>;
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  imageThumbnailUrl?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  instructionsUrl?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isRecipe: Scalars['Boolean']['output'];
  itemDetailsUrl?: Maybe<Scalars['String']['output']>;
  itemPrice?: Maybe<CatalogueItemPrice>;
  manufCode?: Maybe<Scalars['String']['output']>;
  manufDescription?: Maybe<Scalars['String']['output']>;
  manufRRP?: Maybe<Scalars['Decimal']['output']>;
  manufRange?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  quantity?: Maybe<Scalars['Decimal']['output']>;
  recipeItems?: Maybe<Array<Maybe<RecipeItem>>>;
  rowVersion: Scalars['String']['output'];
  searchDescription?: Maybe<Scalars['String']['output']>;
  searchSupplierCode?: Maybe<Scalars['String']['output']>;
  status: CatalogueItemStatus;
  subCategory?: Maybe<Scalars['String']['output']>;
  supplierCode?: Maybe<Scalars['String']['output']>;
  supplierMetadata?: Maybe<Scalars['String']['output']>;
  tempItemCode?: Maybe<Scalars['String']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  uOMAlt1?: Maybe<Scalars['String']['output']>;
  uOMAlt1Conversion?: Maybe<Scalars['Decimal']['output']>;
  uOMAlt2?: Maybe<Scalars['String']['output']>;
  uOMAlt2Conversion?: Maybe<Scalars['Decimal']['output']>;
  uOMMinOrderQty?: Maybe<Scalars['Decimal']['output']>;
  uOMOrderIncrements?: Maybe<Scalars['Decimal']['output']>;
  unitCost?: Maybe<Scalars['Decimal']['output']>;
  unitsExpression?: Maybe<Scalars['String']['output']>;
};

export type SearchCatalogueItemsCriteriaModelInput = {
  catalogueIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  categoryIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  excludeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  excludeItems: Scalars['Boolean']['input'];
  excludeRecipes: Scalars['Boolean']['input'];
  mode?: InputMaybe<SearchMode>;
  populateCatalogueFields?: InputMaybe<Scalars['Boolean']['input']>;
  skip: Scalars['Int']['input'];
  sortAscending: Scalars['Boolean']['input'];
  sortField?: InputMaybe<Scalars['String']['input']>;
  subCategoryIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
  top: Scalars['Int']['input'];
};

export type SearchCatalogueItemsModel = {
  __typename?: 'SearchCatalogueItemsModel';
  count: Scalars['Int']['output'];
  items?: Maybe<Array<Maybe<SearchCatalogueItemModel>>>;
};

export type SearchCatalogueItemsModelV2 = {
  __typename?: 'SearchCatalogueItemsModelV2';
  count: Scalars['Int']['output'];
  items?: Maybe<Array<Maybe<SearchCatalogueItemModelV2>>>;
};

export type SearchCatalogueModel = {
  __typename?: 'SearchCatalogueModel';
  description?: Maybe<Scalars['String']['output']>;
  iD_Catalogue?: Maybe<Scalars['Uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SearchCataloguesCriteriaModelInput = {
  excludeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  excludeItems: Scalars['Boolean']['input'];
  excludeRecipes: Scalars['Boolean']['input'];
  mode?: InputMaybe<SearchMode>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export const SearchMode = {
  Regex: 'REGEX',
  Wildcard: 'WILDCARD'
} as const;

export type SearchMode = typeof SearchMode[keyof typeof SearchMode];
export type SearchOneBuildItemModel = {
  __typename?: 'SearchOneBuildItemModel';
  categoryPath?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  costItemType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Uuid']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  uOM?: Maybe<Scalars['String']['output']>;
  unitCost: Scalars['Decimal']['output'];
};

export type SearchOneBuildModel = {
  __typename?: 'SearchOneBuildModel';
  count: Scalars['Int']['output'];
  items?: Maybe<Array<Maybe<SearchOneBuildItemModel>>>;
};

export type SearchOneBuildPricingModelInput = {
  county?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  top: Scalars['Int']['input'];
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type SearchPurchaseOrderRequestInput = {
  jobId?: InputMaybe<Scalars['Uuid']['input']>;
  jobItemId?: InputMaybe<Scalars['Uuid']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderByDesc: Scalars['Boolean']['input'];
  orderType?: InputMaybe<Scalars['String']['input']>;
  rowFetch: Scalars['Int']['input'];
  rowOffset: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Scalars['String']['input']>;
};

export type SearchPurchaseOrders = {
  __typename?: 'SearchPurchaseOrders';
  description?: Maybe<Scalars['String']['output']>;
  fromAccSyncImport: Scalars['Boolean']['output'];
  hasInvoiceAccSyncData: Scalars['Boolean']['output'];
  iD_PurchaseOrder: Scalars['Uuid']['output'];
  iD_Reference: Scalars['Uuid']['output'];
  job: SearchPurchaseOrdersJob;
  jobDescription?: Maybe<Scalars['String']['output']>;
  jobNumber?: Maybe<Scalars['String']['output']>;
  orderDate: Scalars['DateTime']['output'];
  orderNumber: Scalars['Int']['output'];
  orderStatus?: Maybe<Scalars['String']['output']>;
  orderTotalExTax?: Maybe<Scalars['Decimal']['output']>;
  orderType?: Maybe<Scalars['String']['output']>;
  requiredByDateLocal?: Maybe<Scalars['DateTime']['output']>;
  supplierDescription?: Maybe<Scalars['String']['output']>;
  supplierInvoiceRef?: Maybe<Scalars['String']['output']>;
};

export type SearchPurchaseOrdersJob = {
  __typename?: 'SearchPurchaseOrdersJob';
  description?: Maybe<Scalars['String']['output']>;
  fullDescription?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type SearchPurchaseOrdersResult = {
  __typename?: 'SearchPurchaseOrdersResult';
  inlineCount: Scalars['Int']['output'];
  results: Array<SearchPurchaseOrders>;
};

export type SectionDto = {
  __typename?: 'SectionDto';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  layoutId?: Maybe<Scalars['Uuid']['output']>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  sectionId?: Maybe<Scalars['Uuid']['output']>;
  sectionSettings?: Maybe<SectionSettingDto>;
  sectionTemplates?: Maybe<Array<Maybe<SectionTemplateDto>>>;
  type: SectionType;
};

export type SectionDtoInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  layoutId?: InputMaybe<Scalars['Uuid']['input']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  sectionId?: InputMaybe<Scalars['Uuid']['input']>;
  sectionSettings?: InputMaybe<SectionSettingDtoInput>;
  sectionTemplates?: InputMaybe<Array<InputMaybe<SectionTemplateDtoInput>>>;
  type: SectionType;
};

export type SectionSettingDto = {
  __typename?: 'SectionSettingDto';
  sectionId: Scalars['Uuid']['output'];
  settingData?: Maybe<Scalars['String']['output']>;
};

export type SectionSettingDtoInput = {
  sectionId: Scalars['Uuid']['input'];
  settingData?: InputMaybe<Scalars['String']['input']>;
};

export type SectionTemplateDto = {
  __typename?: 'SectionTemplateDto';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUsername?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  design?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isModified: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedByUsername?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  referenceSectionTemplateId?: Maybe<Scalars['Uuid']['output']>;
  sectionId?: Maybe<Scalars['Uuid']['output']>;
  sectionTemplateId?: Maybe<Scalars['Uuid']['output']>;
};

export type SectionTemplateDtoInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  design?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isModified: Scalars['Boolean']['input'];
  isSelected: Scalars['Boolean']['input'];
  modifiedAt?: InputMaybe<Scalars['DateTime']['input']>;
  modifiedBy?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  referenceSectionTemplateId?: InputMaybe<Scalars['Uuid']['input']>;
  sectionId?: InputMaybe<Scalars['Uuid']['input']>;
  sectionTemplateId?: InputMaybe<Scalars['Uuid']['input']>;
};

export const SectionType = {
  Coverpage: 'COVERPAGE',
  Quoteallowances: 'QUOTEALLOWANCES',
  Quotecontactus: 'QUOTECONTACTUS',
  Quoteditems: 'QUOTEDITEMS',
  Quoteletter: 'QUOTELETTER',
  Quotespecifications: 'QUOTESPECIFICATIONS',
  Quotesummary: 'QUOTESUMMARY',
  Quotetermsandconditions: 'QUOTETERMSANDCONDITIONS',
  Unset: 'UNSET'
} as const;

export type SectionType = typeof SectionType[keyof typeof SectionType];
export type SelectEstimateSupplierStoreError = NotFoundError;

export type SelectEstimateSupplierStoreInput = {
  estimateId: Scalars['Uuid']['input'];
  integrationProviderId: Scalars['Uuid']['input'];
  storeNumber: Scalars['String']['input'];
};

export type SelectEstimateSupplierStorePayload = {
  __typename?: 'SelectEstimateSupplierStorePayload';
  errors?: Maybe<Array<SelectEstimateSupplierStoreError>>;
  supplierStore?: Maybe<SupplierStore>;
};

export type SendEmailInput = {
  message: EmailMessageDtoInput;
};

export type SendEmailPayload = {
  __typename?: 'SendEmailPayload';
  emailSendResult?: Maybe<EmailSendResult>;
};

export type SendJobInvitationError = ValidationError;

export type SendJobInvitationInput = {
  request: JobInvitationRequestV2Input;
};

export type SendJobInvitationPayload = {
  __typename?: 'SendJobInvitationPayload';
  errors?: Maybe<Array<SendJobInvitationError>>;
  jobInvitationDto?: Maybe<JobInvitationDto>;
};

export type SendScheduleReminderModelInput = {
  referenceId: Scalars['Uuid']['input'];
  scheduleTaskId: Scalars['Uuid']['input'];
};

export type SendTakeOffToolQuoteRequestError = NotFoundError | PropertyError | TakeOffLimitError | TakeOffSessionExpiredError;

export type SendTakeOffToolQuoteRequestInput = {
  request: TakeOffToolQuoteRequestInput;
};

export type SendTakeOffToolQuoteRequestPayload = {
  __typename?: 'SendTakeOffToolQuoteRequestPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SendTakeOffToolQuoteRequestError>>;
};

export type SendTaskReminderError = ValidationError;

export type SendTaskReminderInput = {
  model: SendScheduleReminderModelInput;
};

export type SendTaskReminderPayload = {
  __typename?: 'SendTaskReminderPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SendTaskReminderError>>;
};

export type SendVerificationCodeResponse = {
  __typename?: 'SendVerificationCodeResponse';
  oneTimePasscodeId: Scalars['Uuid']['output'];
};

export type SendVerificationCodeToSpecifiedNumberError = SmsLimitReachError | ValidationError;

export type SendVerificationCodeToSpecifiedNumberInput = {
  mobilePhone: Scalars['String']['input'];
};

export type SendVerificationCodeToSpecifiedNumberPayload = {
  __typename?: 'SendVerificationCodeToSpecifiedNumberPayload';
  errors?: Maybe<Array<SendVerificationCodeToSpecifiedNumberError>>;
  sendVerificationCodeResponse?: Maybe<SendVerificationCodeResponse>;
};

export type SenderDto = {
  __typename?: 'SenderDto';
  email?: Maybe<Scalars['String']['output']>;
  identifiers: Array<IdentifierDto>;
  name?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['Uuid']['output']>;
  type: SenderType;
};

export type SenderDtoInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  identifiers: Array<IdentifierDtoInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['Uuid']['input']>;
  type: SenderType;
};

export const SenderType = {
  BxBuilder: 'BX_BUILDER',
  BxMerchant: 'BX_MERCHANT',
  BxTenant: 'BX_TENANT',
  ClientPortalUser: 'CLIENT_PORTAL_USER',
  Unknown: 'UNKNOWN'
} as const;

export type SenderType = typeof SenderType[keyof typeof SenderType];
export type SetActivityFeedLikeError = ActivityFeedStatusError | AlreadyLikedError | AlreadyUnlikedError | EntityNotFoundError | UnpublishedActivityFeedError | ValidationError;

export type SetActivityFeedLikeInput = {
  activityFeedId: Scalars['Uuid']['input'];
  isLiked: Scalars['Boolean']['input'];
  likeId: Scalars['Uuid']['input'];
};

export type SetActivityFeedLikePayload = {
  __typename?: 'SetActivityFeedLikePayload';
  activityFeedLike?: Maybe<ActivityFeedLike>;
  errors?: Maybe<Array<SetActivityFeedLikeError>>;
};

export type ShareActivityFeedError = ActivityFeedStatusError | ExceedingNoOfImagesError | FailedToCopyImageError | InsufficientActivityFeedInfoError | MaxLengthExceededError | SourceIdError | UnpublishedActivityFeedError | ValidationError;

export type ShareActivityFeedInput = {
  model: ActivityFeedShareModelInput;
};

export type ShareActivityFeedPayload = {
  __typename?: 'ShareActivityFeedPayload';
  activityFeed?: Maybe<ActivityFeed>;
  errors?: Maybe<Array<ShareActivityFeedError>>;
};

export type ShareDto = {
  __typename?: 'ShareDto';
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  documentType?: Maybe<DocumentTypes>;
  identifiers: Array<IdentifierDto>;
  isUsingDigitalStatus: Scalars['Boolean']['output'];
  items: Array<ShareItemDto>;
  message: MessageDto;
  recipients: Array<RecipientDto>;
  senders: Array<SenderDto>;
  shareId?: Maybe<Scalars['String']['output']>;
  shouldShareToMessages?: Maybe<Scalars['Boolean']['output']>;
  shouldShareToTimeline?: Maybe<Scalars['Boolean']['output']>;
  status: ShareStatus;
  timelineDescription?: Maybe<Scalars['String']['output']>;
  type: ShareType;
};

export type ShareItemDto = {
  __typename?: 'ShareItemDto';
  binaryUrl?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  identifiers: Array<IdentifierDto>;
  itemUri?: Maybe<Scalars['String']['output']>;
  shareItemId?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['Uuid']['output']>;
  sourceType?: Maybe<SourceTypes>;
  type: ShareItemType;
};

export type ShareItemDtoInput = {
  binaryUrl?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  downloadUrl?: InputMaybe<Scalars['String']['input']>;
  identifiers: Array<IdentifierDtoInput>;
  itemUri?: InputMaybe<Scalars['String']['input']>;
  shareItemId?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['Uuid']['input']>;
  sourceType?: InputMaybe<SourceTypes>;
  type: ShareItemType;
};

export const ShareItemType = {
  AppOnsite: 'APP_ONSITE',
  ClientPortalAdjustment: 'CLIENT_PORTAL_ADJUSTMENT',
  ClientPortalGeneric: 'CLIENT_PORTAL_GENERIC',
  ClientPortalInvoice: 'CLIENT_PORTAL_INVOICE',
  ClientPortalInvoiceSummary: 'CLIENT_PORTAL_INVOICE_SUMMARY',
  ClientPortalQuote: 'CLIENT_PORTAL_QUOTE',
  ClientPortalVariation: 'CLIENT_PORTAL_VARIATION',
  EmailInvoice: 'EMAIL_INVOICE',
  EmailInvoiceSummary: 'EMAIL_INVOICE_SUMMARY',
  EmailQuote: 'EMAIL_QUOTE',
  EmailVariation: 'EMAIL_VARIATION',
  Rfq: 'RFQ',
  Unknown: 'UNKNOWN'
} as const;

export type ShareItemType = typeof ShareItemType[keyof typeof ShareItemType];
export type ShareLeadWithClientModelInput = {
  customerContactId: Scalars['Uuid']['input'];
  leadId: Scalars['Uuid']['input'];
  messageBody?: InputMaybe<Scalars['String']['input']>;
  messageSubject?: InputMaybe<Scalars['String']['input']>;
};

export type ShareSpecificationsToClientPortalError = NotFoundError | PropertyError | ValidationError;

export type ShareSpecificationsToClientPortalInput = {
  estimateId: Scalars['Uuid']['input'];
};

export type ShareSpecificationsToClientPortalPayload = {
  __typename?: 'ShareSpecificationsToClientPortalPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<ShareSpecificationsToClientPortalError>>;
};

export const ShareStatus = {
  Approved: 'APPROVED',
  New: 'NEW',
  Unknown: 'UNKNOWN',
  Viewed: 'VIEWED'
} as const;

export type ShareStatus = typeof ShareStatus[keyof typeof ShareStatus];
export const ShareType = {
  Document: 'DOCUMENT',
  Invoice: 'INVOICE',
  Plan: 'PLAN',
  Quote: 'QUOTE',
  Unknown: 'UNKNOWN',
  Variation: 'VARIATION'
} as const;

export type ShareType = typeof ShareType[keyof typeof ShareType];
export type ShortOperationFilterInput = {
  eq?: InputMaybe<Scalars['Short']['input']>;
  gt?: InputMaybe<Scalars['Short']['input']>;
  gte?: InputMaybe<Scalars['Short']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Short']['input']>>>;
  lt?: InputMaybe<Scalars['Short']['input']>;
  lte?: InputMaybe<Scalars['Short']['input']>;
  neq?: InputMaybe<Scalars['Short']['input']>;
  ngt?: InputMaybe<Scalars['Short']['input']>;
  ngte?: InputMaybe<Scalars['Short']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Short']['input']>>>;
  nlt?: InputMaybe<Scalars['Short']['input']>;
  nlte?: InputMaybe<Scalars['Short']['input']>;
};

export type SimpleCatalogueModel = {
  __typename?: 'SimpleCatalogueModel';
  description?: Maybe<Scalars['String']['output']>;
  iD_Catalogue: Scalars['Uuid']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SmartEstimateCreateSession = {
  __typename?: 'SmartEstimateCreateSession';
  iD_Session: Scalars['Uuid']['output'];
};

export type SmartEstimateGenerateCategoryModelInput = {
  iD_Category: Scalars['Uuid']['input'];
  items?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  userPrompt?: InputMaybe<Scalars['String']['input']>;
};

export type SmartEstimateGenerateModelInput = {
  area: Scalars['Decimal']['input'];
  categories?: InputMaybe<Array<InputMaybe<SmartEstimateGenerateCategoryModelInput>>>;
  finalizer?: InputMaybe<Scalars['Boolean']['input']>;
  iD_Session: Scalars['Uuid']['input'];
  iD_Template: Scalars['Uuid']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  markup?: InputMaybe<Scalars['Decimal']['input']>;
  overrideFinalizerText?: InputMaybe<Scalars['String']['input']>;
  overrideGenerateText?: InputMaybe<Scalars['String']['input']>;
  rounding?: InputMaybe<Scalars['Boolean']['input']>;
  uOM?: InputMaybe<Scalars['String']['input']>;
  userPrompt?: InputMaybe<Scalars['String']['input']>;
  wholeEstimate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SmartEstimateGenerateReply = {
  __typename?: 'SmartEstimateGenerateReply';
  iD_Log: Scalars['Uuid']['output'];
  iD_Session: Scalars['Uuid']['output'];
};

export type SmartEstimateLimitError = Error & {
  __typename?: 'SmartEstimateLimitError';
  limitName: EstimateToolLimit;
  limitValue: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type SmartEstimateParametersModelInput = {
  excludeItems: Scalars['Boolean']['input'];
  iD_Session: Scalars['Uuid']['input'];
  iD_Template: Scalars['Uuid']['input'];
  overrideParametersPromptText?: InputMaybe<Scalars['String']['input']>;
  overrideSelectionsPromptText?: InputMaybe<Scalars['String']['input']>;
  userPrompt?: InputMaybe<Scalars['String']['input']>;
};

export type SmartEstimateParametersReply = {
  __typename?: 'SmartEstimateParametersReply';
  iD_Parameters: Scalars['Uuid']['output'];
  iD_Session: Scalars['Uuid']['output'];
};

export type SmartEstimateTemplate = {
  __typename?: 'SmartEstimateTemplate';
  categories?: Maybe<Array<SmartEstimateTemplateCategory>>;
  description?: Maybe<Scalars['String']['output']>;
  iD_Template: Scalars['Uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
};

export type SmartEstimateTemplateCategory = {
  __typename?: 'SmartEstimateTemplateCategory';
  description?: Maybe<Scalars['String']['output']>;
  iD_Category: Scalars['Uuid']['output'];
  items?: Maybe<Array<SmartEstimateTemplateCategoryItem>>;
  orderNumber: Scalars['Int']['output'];
};

export type SmartEstimateTemplateCategoryItem = {
  __typename?: 'SmartEstimateTemplateCategoryItem';
  costItemType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  iD_Item: Scalars['Uuid']['output'];
  orderNumber: Scalars['Int']['output'];
  uOM?: Maybe<Scalars['String']['output']>;
  units: Scalars['Decimal']['output'];
};

/** A connection to a list of items. */
export type SmartEstimateTemplateConnection = {
  __typename?: 'SmartEstimateTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SmartEstimateTemplateEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<SmartEstimateTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SmartEstimateTemplateEdge = {
  __typename?: 'SmartEstimateTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SmartEstimateTemplate>;
};

export type SmartEstimateTemplateFilterInput = {
  and?: InputMaybe<Array<SmartEstimateTemplateFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_Template?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SmartEstimateTemplateFilterInput>>;
  prompt?: InputMaybe<StringOperationFilterInput>;
};

export type SmartEstimateTemplateSortInput = {
  description?: InputMaybe<SortEnumType>;
  iD_Template?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  prompt?: InputMaybe<SortEnumType>;
};

export type SmsHistory = {
  __typename?: 'SmsHistory';
  dateSent?: Maybe<Scalars['DateTime']['output']>;
  decodedMessage: Scalars['String']['output'];
  iD_Customer?: Maybe<Scalars['Uuid']['output']>;
  iD_Reference?: Maybe<Scalars['Uuid']['output']>;
  iD_SMSHistory: Scalars['Uuid']['output'];
  iD_Tenant: Scalars['Uuid']['output'];
  iD_User: Scalars['Uuid']['output'];
  indexer: Scalars['Long']['output'];
  message?: Maybe<Scalars['String']['output']>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  sentSuccessfully: Scalars['Boolean']['output'];
};

/** A segment of a collection. */
export type SmsHistoryCollectionSegment = {
  __typename?: 'SmsHistoryCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SmsHistory>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type SmsHistoryFilterInput = {
  and?: InputMaybe<Array<SmsHistoryFilterInput>>;
  dateSent?: InputMaybe<DateTimeOperationFilterInput>;
  iD_Customer?: InputMaybe<UuidOperationFilterInput>;
  iD_Reference?: InputMaybe<UuidOperationFilterInput>;
  iD_SMSHistory?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  iD_User?: InputMaybe<UuidOperationFilterInput>;
  indexer?: InputMaybe<LongOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  mobileNumber?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SmsHistoryFilterInput>>;
  sentSuccessfully?: InputMaybe<BooleanOperationFilterInput>;
};

export type SmsHistorySortInput = {
  dateSent?: InputMaybe<SortEnumType>;
  iD_Customer?: InputMaybe<SortEnumType>;
  iD_Reference?: InputMaybe<SortEnumType>;
  iD_SMSHistory?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  iD_User?: InputMaybe<SortEnumType>;
  indexer?: InputMaybe<SortEnumType>;
  message?: InputMaybe<SortEnumType>;
  mobileNumber?: InputMaybe<SortEnumType>;
  sentSuccessfully?: InputMaybe<SortEnumType>;
};

export type SmsLimitReachError = Error & {
  __typename?: 'SmsLimitReachError';
  message: Scalars['String']['output'];
};

export type SmsMessageDtoInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  referenceId?: InputMaybe<Scalars['Uuid']['input']>;
  to?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SnapshotDescriptionEmptyError = Error & {
  __typename?: 'SnapshotDescriptionEmptyError';
  message: Scalars['String']['output'];
};

export type SnapshotSummary = {
  __typename?: 'SnapshotSummary';
  createdById: Scalars['Uuid']['output'];
  createdByName?: Maybe<Scalars['String']['output']>;
  dateMade: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  oldEstimateId: Scalars['Uuid']['output'];
  snapshotId: Scalars['Uuid']['output'];
  tenantId: Scalars['Uuid']['output'];
  totalIncMarkupTax?: Maybe<Scalars['Float']['output']>;
};

export type SnapshotSummarySortInput = {
  createdById?: InputMaybe<SortEnumType>;
  createdByName?: InputMaybe<SortEnumType>;
  dateMade?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  oldEstimateId?: InputMaybe<SortEnumType>;
  snapshotId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  totalIncMarkupTax?: InputMaybe<SortEnumType>;
};

export const SortEnumType = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortEnumType = typeof SortEnumType[keyof typeof SortEnumType];
export const SourceEntity = {
  Job: 'JOB'
} as const;

export type SourceEntity = typeof SourceEntity[keyof typeof SourceEntity];
export type SourceEntityOperationFilterInput = {
  eq?: InputMaybe<SourceEntity>;
  in?: InputMaybe<Array<SourceEntity>>;
  neq?: InputMaybe<SourceEntity>;
  nin?: InputMaybe<Array<SourceEntity>>;
};

export type SourceIdError = Error & {
  __typename?: 'SourceIdError';
  message: Scalars['String']['output'];
};

export const SourceTypes = {
  Estimate: 'ESTIMATE',
  EstimateSpecification: 'ESTIMATE_SPECIFICATION',
  Job: 'JOB',
  Lead: 'LEAD'
} as const;

export type SourceTypes = typeof SourceTypes[keyof typeof SourceTypes];
export type Specification = {
  __typename?: 'Specification';
  description?: Maybe<Scalars['String']['output']>;
  iD_Specification: Scalars['Uuid']['output'];
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  introduction?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  specificationItems?: Maybe<Array<Maybe<SpecificationItem>>>;
};

export type SpecificationItem = {
  __typename?: 'SpecificationItem';
  category?: Maybe<Scalars['String']['output']>;
  children: Array<SpecificationItem>;
  iD_Image?: Maybe<Scalars['Uuid']['output']>;
  iD_Specification?: Maybe<Scalars['Uuid']['output']>;
  iD_SpecificationItem: Scalars['Uuid']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isLegacy: Scalars['Boolean']['output'];
  isParent: Scalars['Boolean']['output'];
  orderNumber: Scalars['Float']['output'];
  parent?: Maybe<SpecificationItem>;
  parentId?: Maybe<Scalars['Uuid']['output']>;
  specification?: Maybe<Scalars['String']['output']>;
  specificationPreset?: Maybe<Specification>;
};

export type SpecificationItemModel = {
  __typename?: 'SpecificationItemModel';
  category?: Maybe<Scalars['String']['output']>;
  iD_Image?: Maybe<Scalars['Uuid']['output']>;
  iD_Specification?: Maybe<Scalars['Uuid']['output']>;
  iD_SpecificationItem: Scalars['Uuid']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  orderNumber: Scalars['Float']['output'];
  specification?: Maybe<Scalars['String']['output']>;
};

export type SpecificationItemModelFilterInput = {
  and?: InputMaybe<Array<SpecificationItemModelFilterInput>>;
  category?: InputMaybe<StringOperationFilterInput>;
  iD_Image?: InputMaybe<UuidOperationFilterInput>;
  iD_Specification?: InputMaybe<UuidOperationFilterInput>;
  iD_SpecificationItem?: InputMaybe<UuidOperationFilterInput>;
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<SpecificationItemModelFilterInput>>;
  orderNumber?: InputMaybe<FloatOperationFilterInput>;
  specification?: InputMaybe<StringOperationFilterInput>;
};

export type SpecificationModel = {
  __typename?: 'SpecificationModel';
  description?: Maybe<Scalars['String']['output']>;
  iD_Specification: Scalars['Uuid']['output'];
  iD_Tenant?: Maybe<Scalars['Uuid']['output']>;
  introduction?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  specificationItems?: Maybe<Array<Maybe<SpecificationItemModel>>>;
};

export type SpecificationModelFilterInput = {
  and?: InputMaybe<Array<SpecificationModelFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  iD_Specification?: InputMaybe<UuidOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  introduction?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<SpecificationModelFilterInput>>;
  specificationItems?: InputMaybe<ListFilterInputTypeOfSpecificationItemModelFilterInput>;
};

export type SpecificationModelSortInput = {
  description?: InputMaybe<SortEnumType>;
  iD_Specification?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  introduction?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type SpecificationsCollectionSegment = {
  __typename?: 'SpecificationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<SpecificationModel>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type StartCatalogueImportModelInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  iD_Batch: Scalars['Uuid']['input'];
  iD_Catalogue: Scalars['Uuid']['input'];
  iD_CatalogueImport: Scalars['Uuid']['input'];
  importType: CatalogueImportType;
  importingRows: Scalars['Int']['input'];
  jobId?: InputMaybe<Scalars['String']['input']>;
  originalRows: Scalars['Int']['input'];
  overwrite?: InputMaybe<Scalars['Boolean']['input']>;
  spaceId?: InputMaybe<Scalars['String']['input']>;
};

export type StartCatalogueSyncModelInput = {
  catalogueId: Scalars['Uuid']['input'];
};

export type StartEstimateImportModelInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  iD_Batch: Scalars['Uuid']['input'];
  iD_Estimate: Scalars['Uuid']['input'];
  iD_EstimateImport: Scalars['Uuid']['input'];
  importType: ImportType;
  importingRows: Scalars['Int']['input'];
  jobId?: InputMaybe<Scalars['String']['input']>;
  originalRows: Scalars['Int']['input'];
  spaceId?: InputMaybe<Scalars['String']['input']>;
};

export type StorageFileSummaryDto = {
  __typename?: 'StorageFileSummaryDto';
  contentLength?: Maybe<Scalars['Long']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  storageUrl?: Maybe<Scalars['String']['output']>;
};

export type StorageFileSummaryDtoFilterInput = {
  and?: InputMaybe<Array<StorageFileSummaryDtoFilterInput>>;
  contentLength?: InputMaybe<LongOperationFilterInput>;
  contentType?: InputMaybe<StringOperationFilterInput>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StorageFileSummaryDtoFilterInput>>;
  storageUrl?: InputMaybe<StringOperationFilterInput>;
};

export type StorageFileSummaryDtoSortInput = {
  contentLength?: InputMaybe<SortEnumType>;
  contentType?: InputMaybe<SortEnumType>;
  createdDate?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  storageUrl?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubscribeToCatalogueModelInput = {
  iD_Catalogue: Scalars['Uuid']['input'];
  iD_IntegrationConnection: Scalars['Uuid']['input'];
};

export type SubscribeToEstimateTemplateModelInput = {
  estimateId: Scalars['Uuid']['input'];
  integrationConnectionId: Scalars['Uuid']['input'];
  status: EstimateTemplateSubscriptionStatus;
};

export type SubscriptionInfoDto = {
  __typename?: 'SubscriptionInfoDto';
  status: Scalars['String']['output'];
};

export type SupplierQuoteResponse = {
  __typename?: 'SupplierQuoteResponse';
  errors?: Maybe<Array<Maybe<SupplierQuoteResponseError>>>;
  items?: Maybe<Array<Maybe<SupplierQuoteResponseItem>>>;
  quoteId?: Maybe<Scalars['String']['output']>;
};

export type SupplierQuoteResponseError = {
  __typename?: 'SupplierQuoteResponseError';
  errorCode: SupplierQuoteResponseErrorCodes;
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export const SupplierQuoteResponseErrorCodes = {
  Addressinvalid: 'ADDRESSINVALID',
  Customerphonenumberinvalid: 'CUSTOMERPHONENUMBERINVALID',
  Deliveryminimumquantitynotmet: 'DELIVERYMINIMUMQUANTITYNOTMET',
  Deliveryunavailable: 'DELIVERYUNAVAILABLE',
  Genericerror: 'GENERICERROR',
  Itemnotfound: 'ITEMNOTFOUND',
  Itemoutofstock: 'ITEMOUTOFSTOCK',
  Itempriceinvalid: 'ITEMPRICEINVALID',
  Supplierstore: 'SUPPLIERSTORE'
} as const;

export type SupplierQuoteResponseErrorCodes = typeof SupplierQuoteResponseErrorCodes[keyof typeof SupplierQuoteResponseErrorCodes];
export type SupplierQuoteResponseItem = {
  __typename?: 'SupplierQuoteResponseItem';
  errors?: Maybe<Array<Maybe<SupplierQuoteResponseError>>>;
  estimateItemId: Scalars['Uuid']['output'];
  integrationProviderId: Scalars['Uuid']['output'];
};

export type SupplierStore = {
  __typename?: 'SupplierStore';
  address: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  storeId: Scalars['String']['output'];
  tradingHours?: Maybe<TradingHours>;
  tradingHoursText?: Maybe<Scalars['String']['output']>;
};

export type SupplierStoreFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<SupplierStoreFilterInput>>;
  latitude?: InputMaybe<FloatOperationFilterInput>;
  longitude?: InputMaybe<FloatOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SupplierStoreFilterInput>>;
  storeId?: InputMaybe<StringOperationFilterInput>;
  supplierName?: InputMaybe<StringOperationFilterInput>;
  tradingHours?: InputMaybe<TradingHoursFilterInput>;
  tradingHoursText?: InputMaybe<StringOperationFilterInput>;
};

export type SupplierStoreSortInput = {
  address?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  storeId?: InputMaybe<SortEnumType>;
  supplierName?: InputMaybe<SortEnumType>;
  tradingHours?: InputMaybe<TradingHoursSortInput>;
  tradingHoursText?: InputMaybe<SortEnumType>;
};

export type SupplierStoresResponse = {
  __typename?: 'SupplierStoresResponse';
  stores: Array<SupplierStore>;
};

export type SyncEstimateCategorySpecificationsError = NotFoundError;

export type SyncEstimateCategorySpecificationsInput = {
  estimateId: Scalars['Uuid']['input'];
  estimateItemIds: Array<Scalars['Uuid']['input']>;
};

export type SyncEstimateCategorySpecificationsPayload = {
  __typename?: 'SyncEstimateCategorySpecificationsPayload';
  errors?: Maybe<Array<SyncEstimateCategorySpecificationsError>>;
  int?: Maybe<Scalars['Int']['output']>;
};

export type SyncExternalInvoicePaymentsError = ValidationError;

export type SyncExternalInvoicePaymentsInput = {
  jobId: Scalars['Uuid']['input'];
  provider: IntegrationProviderEnum;
};

export type SyncExternalInvoicePaymentsPayload = {
  __typename?: 'SyncExternalInvoicePaymentsPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<Array<SyncExternalInvoicePaymentsError>>;
};

export type SyncNowReponseModel = {
  __typename?: 'SyncNowReponseModel';
  isSuccess: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type SyncScheduleWithEstimateError = EntityNotFoundError | ScheduleLockedError;

export type SyncScheduleWithEstimateInput = {
  estimateId: Scalars['Uuid']['input'];
};

export type SyncScheduleWithEstimatePayload = {
  __typename?: 'SyncScheduleWithEstimatePayload';
  errors?: Maybe<Array<SyncScheduleWithEstimateError>>;
  isSynced?: Maybe<Scalars['Boolean']['output']>;
};

export const TakeOffLimit = {
  Measurements: 'MEASUREMENTS',
  Planmeasurements: 'PLANMEASUREMENTS',
  Planpages: 'PLANPAGES',
  Sendquotes: 'SENDQUOTES',
  Totaldailysessions: 'TOTALDAILYSESSIONS',
  Userdailysessions: 'USERDAILYSESSIONS'
} as const;

export type TakeOffLimit = typeof TakeOffLimit[keyof typeof TakeOffLimit];
export type TakeOffLimitError = Error & {
  __typename?: 'TakeOffLimitError';
  limitName: TakeOffLimit;
  limitValue: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type TakeOffSessionExpiredError = Error & {
  __typename?: 'TakeOffSessionExpiredError';
  message: Scalars['String']['output'];
  sessionId: Scalars['Uuid']['output'];
};

export type TakeOffToolCreateSession = {
  __typename?: 'TakeOffToolCreateSession';
  iD_Estimate: Scalars['Uuid']['output'];
  iD_Session: Scalars['Uuid']['output'];
  iD_TenantId: Scalars['Uuid']['output'];
  iD_UserId: Scalars['Uuid']['output'];
  measurementLimit: Scalars['Int']['output'];
  originator?: Maybe<TakeOffToolSessionOrigin>;
  pagesLimit: Scalars['Int']['output'];
  planMeasurementLimit: Scalars['Int']['output'];
  sendQuoteLimit: Scalars['Int']['output'];
};

export type TakeOffToolOpenSession = {
  __typename?: 'TakeOffToolOpenSession';
  hasPlans: Scalars['Boolean']['output'];
  iD_Estimate: Scalars['Uuid']['output'];
  iD_TenantId: Scalars['Uuid']['output'];
  iD_UserId: Scalars['Uuid']['output'];
  measurementLimit: Scalars['Int']['output'];
  originator?: Maybe<TakeOffToolSessionOrigin>;
  pagesLimit: Scalars['Int']['output'];
  planMeasurementLimit: Scalars['Int']['output'];
  sendQuoteCount: Scalars['Int']['output'];
  sendQuoteLimit: Scalars['Int']['output'];
};

export type TakeOffToolQuoteRequestInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  iD_Session: Scalars['Uuid']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profession?: InputMaybe<Scalars['String']['input']>;
  refValue?: InputMaybe<Scalars['String']['input']>;
  refValue2?: InputMaybe<Scalars['String']['input']>;
  saveToBuilder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TakeOffToolSessionOrigin = {
  __typename?: 'TakeOffToolSessionOrigin';
  hasPartner: Scalars['Boolean']['output'];
  hasSupplier: Scalars['Boolean']['output'];
  home1Text?: Maybe<Scalars['String']['output']>;
  home1Title?: Maybe<Scalars['String']['output']>;
  home2Text?: Maybe<Scalars['String']['output']>;
  home2Title?: Maybe<Scalars['String']['output']>;
  home3Text?: Maybe<Scalars['String']['output']>;
  home3Title?: Maybe<Scalars['String']['output']>;
  homeErrorUtm?: Maybe<Scalars['String']['output']>;
  homeMainText?: Maybe<Scalars['String']['output']>;
  homeMainTitle?: Maybe<Scalars['String']['output']>;
  homeUtm?: Maybe<Scalars['String']['output']>;
  locationExample?: Maybe<Scalars['String']['output']>;
  locationTitle?: Maybe<Scalars['String']['output']>;
  originator?: Maybe<Scalars['String']['output']>;
  originatorName?: Maybe<Scalars['String']['output']>;
  partnerText?: Maybe<Scalars['String']['output']>;
  partnerUrl?: Maybe<Scalars['String']['output']>;
  partnerWidth: Scalars['Int']['output'];
  refFieldName?: Maybe<Scalars['String']['output']>;
  refFieldName2?: Maybe<Scalars['String']['output']>;
  refFieldRegEx?: Maybe<Scalars['String']['output']>;
  refFieldRegEx2?: Maybe<Scalars['String']['output']>;
  requireRef: Scalars['Boolean']['output'];
  requireRef2: Scalars['Boolean']['output'];
  sendButtonText?: Maybe<Scalars['String']['output']>;
  sendQuoteDescription?: Maybe<Scalars['String']['output']>;
  sendQuoteEmail?: Maybe<Scalars['String']['output']>;
  sendQuoteTitle?: Maybe<Scalars['String']['output']>;
  signUpUtm?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type TakeOffUnknownOriginError = Error & {
  __typename?: 'TakeOffUnknownOriginError';
  message: Scalars['String']['output'];
  origin?: Maybe<Scalars['String']['output']>;
};

export const TaskColour = {
  Blue: 'BLUE',
  Brown: 'BROWN',
  DarkBlue: 'DARK_BLUE',
  DarkGray: 'DARK_GRAY',
  DarkGreen: 'DARK_GREEN',
  DarkPurple: 'DARK_PURPLE',
  DarkRed: 'DARK_RED',
  DarkTeal: 'DARK_TEAL',
  Gray: 'GRAY',
  Green: 'GREEN',
  LightBlue: 'LIGHT_BLUE',
  LightGray: 'LIGHT_GRAY',
  LightGreen: 'LIGHT_GREEN',
  LightPurple: 'LIGHT_PURPLE',
  LightRed: 'LIGHT_RED',
  LightTeal: 'LIGHT_TEAL',
  Orange: 'ORANGE',
  Purple: 'PURPLE',
  Red: 'RED',
  Teal: 'TEAL',
  Yellow: 'YELLOW'
} as const;

export type TaskColour = typeof TaskColour[keyof typeof TaskColour];
/** A segment of a collection. */
export type TasksCollectionSegment = {
  __typename?: 'TasksCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ScheduleTaskContact>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type TasksGroupedByAssignee = {
  __typename?: 'TasksGroupedByAssignee';
  businessName?: Maybe<Scalars['String']['output']>;
  contactId: Scalars['Uuid']['output'];
  end: Scalars['DateTime']['output'];
  name?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
  tasks?: Maybe<ScheduleTaskCollectionSegment>;
  totalTasks: Scalars['Int']['output'];
};


export type TasksGroupedByAssigneeTasksArgs = {
  order?: InputMaybe<Array<ScheduleTaskSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};

export type TasksGroupedByAssigneeFilterInput = {
  and?: InputMaybe<Array<TasksGroupedByAssigneeFilterInput>>;
  businessName?: InputMaybe<StringOperationFilterInput>;
  contactId?: InputMaybe<UuidOperationFilterInput>;
  end?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TasksGroupedByAssigneeFilterInput>>;
  start?: InputMaybe<DateTimeOperationFilterInput>;
  totalTasks?: InputMaybe<IntOperationFilterInput>;
};

export type TasksGroupedByAssigneeModelInput = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type TasksGroupedByAssigneeSortInput = {
  businessName?: InputMaybe<SortEnumType>;
  contactId?: InputMaybe<SortEnumType>;
  end?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  start?: InputMaybe<SortEnumType>;
  totalTasks?: InputMaybe<SortEnumType>;
};

export type TasksGroupedByReference = {
  __typename?: 'TasksGroupedByReference';
  description: Scalars['String']['output'];
  end: Scalars['DateTime']['output'];
  number: Scalars['String']['output'];
  parentTasks?: Maybe<ParentTasksCollectionSegment>;
  referenceDetails?: Maybe<ScheduleReference>;
  referenceId: Scalars['Uuid']['output'];
  referenceType: ScheduleTaskReference;
  start: Scalars['DateTime']['output'];
  totalTasks: Scalars['Int']['output'];
};


export type TasksGroupedByReferenceParentTasksArgs = {
  order?: InputMaybe<Array<ScheduleTaskSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};

export type TasksGroupedByReferenceFilterInput = {
  and?: InputMaybe<Array<TasksGroupedByReferenceFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  end?: InputMaybe<DateTimeOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TasksGroupedByReferenceFilterInput>>;
  referenceId?: InputMaybe<UuidOperationFilterInput>;
  referenceType?: InputMaybe<ScheduleTaskReferenceOperationFilterInput>;
  start?: InputMaybe<DateTimeOperationFilterInput>;
  totalTasks?: InputMaybe<IntOperationFilterInput>;
};

export type TasksGroupedByReferenceModelInput = {
  end: Scalars['DateTime']['input'];
  referenceType: ScheduleTaskReference;
  start: Scalars['DateTime']['input'];
};

export type TasksGroupedByReferenceSortInput = {
  description?: InputMaybe<SortEnumType>;
  end?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  referenceId?: InputMaybe<SortEnumType>;
  referenceType?: InputMaybe<SortEnumType>;
  start?: InputMaybe<SortEnumType>;
  totalTasks?: InputMaybe<SortEnumType>;
};

export const TaxCalculationMethod = {
  Legacy: 'LEGACY',
  Multitax: 'MULTITAX',
  Multitaxrecipes: 'MULTITAXRECIPES'
} as const;

export type TaxCalculationMethod = typeof TaxCalculationMethod[keyof typeof TaxCalculationMethod];
export type TaxCalculationMethodOperationFilterInput = {
  eq?: InputMaybe<TaxCalculationMethod>;
  in?: InputMaybe<Array<TaxCalculationMethod>>;
  neq?: InputMaybe<TaxCalculationMethod>;
  nin?: InputMaybe<Array<TaxCalculationMethod>>;
};

export type TemplateSelectionDtoInput = {
  layoutId: Scalars['Uuid']['input'];
  sectionId: Scalars['Uuid']['input'];
  sectionTemplateId: Scalars['Uuid']['input'];
};

/** A segment of a collection. */
export type TemplatesCollectionSegment = {
  __typename?: 'TemplatesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<Estimate>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Tenant = {
  __typename?: 'Tenant';
  companyName?: Maybe<Scalars['String']['output']>;
  isTrialAccount?: Maybe<Scalars['Boolean']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  metaAccess?: Maybe<TenantMetaAccess>;
  name?: Maybe<Scalars['String']['output']>;
  parentTenantId?: Maybe<Scalars['Uuid']['output']>;
  tenantId: Scalars['Uuid']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
  usersCount?: Maybe<Scalars['Int']['output']>;
};

export type TenantMetaAccess = {
  __typename?: 'TenantMetaAccess';
  hasRoles: Scalars['Boolean']['output'];
};

export type TenantModelForMergeTags = {
  __typename?: 'TenantModelForMergeTags';
  aBN?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  cityTown?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  licenses?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type TimeSpanOperationFilterInput = {
  eq?: InputMaybe<Scalars['TimeSpan']['input']>;
  gt?: InputMaybe<Scalars['TimeSpan']['input']>;
  gte?: InputMaybe<Scalars['TimeSpan']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  lt?: InputMaybe<Scalars['TimeSpan']['input']>;
  lte?: InputMaybe<Scalars['TimeSpan']['input']>;
  neq?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngt?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngte?: InputMaybe<Scalars['TimeSpan']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  nlt?: InputMaybe<Scalars['TimeSpan']['input']>;
  nlte?: InputMaybe<Scalars['TimeSpan']['input']>;
};

export type TimeZoneInfoFilterInput = {
  and?: InputMaybe<Array<TimeZoneInfoFilterInput>>;
  baseUtcOffset?: InputMaybe<TimeSpanOperationFilterInput>;
  daylightName?: InputMaybe<StringOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  hasIanaId?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TimeZoneInfoFilterInput>>;
  standardName?: InputMaybe<StringOperationFilterInput>;
  supportsDaylightSavingTime?: InputMaybe<BooleanOperationFilterInput>;
};

export type TimeZoneInfoSortInput = {
  baseUtcOffset?: InputMaybe<SortEnumType>;
  daylightName?: InputMaybe<SortEnumType>;
  displayName?: InputMaybe<SortEnumType>;
  hasIanaId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  standardName?: InputMaybe<SortEnumType>;
  supportsDaylightSavingTime?: InputMaybe<SortEnumType>;
};

export type TimeZoneNotFoundError = Error & {
  __typename?: 'TimeZoneNotFoundError';
  message: Scalars['String']['output'];
};

export type ToDoAssignee = {
  __typename?: 'ToDoAssignee';
  assignee?: Maybe<TodoAssignee>;
  assigneeId: Scalars['Uuid']['output'];
  toDoAssigneeId: Scalars['Uuid']['output'];
  toDoItem?: Maybe<ToDoItem>;
  toDoItemId: Scalars['Uuid']['output'];
};

export type ToDoAssigneeCreateModelInput = {
  assigneeId: Scalars['Uuid']['input'];
  assigneeType: ToDoAssigneeType;
  toDoItemId: Scalars['Uuid']['input'];
};

export type ToDoAssigneeCreateResult = {
  __typename?: 'ToDoAssigneeCreateResult';
  created: Array<ToDoAssignee>;
  total: Scalars['Int']['output'];
};

export type ToDoAssigneeDeleteModelInput = {
  toDoAssigneeId: Scalars['Uuid']['input'];
  toDoItemId: Scalars['Uuid']['input'];
};

export type ToDoAssigneeDeleteResult = {
  __typename?: 'ToDoAssigneeDeleteResult';
  deleted: Array<Scalars['Uuid']['output']>;
  total: Scalars['Int']['output'];
};

export type ToDoAssigneeFilterInput = {
  and?: InputMaybe<Array<ToDoAssigneeFilterInput>>;
  assigneeId?: InputMaybe<UuidOperationFilterInput>;
  assigneeType?: InputMaybe<ToDoAssigneeTypeOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<ToDoAssigneeFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  toDoAssigneeId?: InputMaybe<UuidOperationFilterInput>;
  toDoItem?: InputMaybe<ToDoItemFilterInput>;
  toDoItemId?: InputMaybe<UuidOperationFilterInput>;
};

export type ToDoAssigneeSortInput = {
  assigneeId?: InputMaybe<SortEnumType>;
  assigneeType?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  toDoAssigneeId?: InputMaybe<SortEnumType>;
  toDoItem?: InputMaybe<ToDoItemSortInput>;
  toDoItemId?: InputMaybe<SortEnumType>;
};

export const ToDoAssigneeType = {
  Buildxact: 'BUILDXACT',
  Onsite: 'ONSITE'
} as const;

export type ToDoAssigneeType = typeof ToDoAssigneeType[keyof typeof ToDoAssigneeType];
export type ToDoAssigneeTypeOperationFilterInput = {
  eq?: InputMaybe<ToDoAssigneeType>;
  in?: InputMaybe<Array<ToDoAssigneeType>>;
  neq?: InputMaybe<ToDoAssigneeType>;
  nin?: InputMaybe<Array<ToDoAssigneeType>>;
};

export type ToDoItem = {
  __typename?: 'ToDoItem';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<Scalars['Uuid']['output']>;
  completedByUser?: Maybe<UserDetails>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Uuid']['output']>;
  createdByUser?: Maybe<UserDetails>;
  description: Scalars['String']['output'];
  dueDate: Scalars['DateTime']['output'];
  isOverdue: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  modifiedBy?: Maybe<Scalars['Uuid']['output']>;
  modifiedByUser?: Maybe<UserDetails>;
  note?: Maybe<Scalars['String']['output']>;
  priority: ToDoPriority;
  relatedEntity?: Maybe<TodoReference>;
  relatedEntityId?: Maybe<Scalars['Uuid']['output']>;
  relatedEntityType?: Maybe<ToDoRelatedEntityType>;
  status: ToDoStatus;
  toDoItemAssignees?: Maybe<ToDoItemAssigneesCollectionSegment>;
  toDoItemId: Scalars['Uuid']['output'];
};


export type ToDoItemToDoItemAssigneesArgs = {
  order?: InputMaybe<Array<ToDoAssigneeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ToDoAssigneeFilterInput>;
};

export type ToDoItemAssigneeNotificationFilterInput = {
  and?: InputMaybe<Array<ToDoItemAssigneeNotificationFilterInput>>;
  or?: InputMaybe<Array<ToDoItemAssigneeNotificationFilterInput>>;
  sentDate?: InputMaybe<DateTimeOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  toDoAssignee?: InputMaybe<ToDoAssigneeFilterInput>;
  toDoAssigneeId?: InputMaybe<UuidOperationFilterInput>;
  toDoItem?: InputMaybe<ToDoItemFilterInput>;
  toDoItemAssigneeNotificationId?: InputMaybe<UuidOperationFilterInput>;
  toDoItemId?: InputMaybe<UuidOperationFilterInput>;
  type?: InputMaybe<ToDoItemAssigneeNotificationTypeOperationFilterInput>;
};

export const ToDoItemAssigneeNotificationType = {
  ItemAssigned: 'ITEM_ASSIGNED',
  ItemOverdue: 'ITEM_OVERDUE'
} as const;

export type ToDoItemAssigneeNotificationType = typeof ToDoItemAssigneeNotificationType[keyof typeof ToDoItemAssigneeNotificationType];
export type ToDoItemAssigneeNotificationTypeOperationFilterInput = {
  eq?: InputMaybe<ToDoItemAssigneeNotificationType>;
  in?: InputMaybe<Array<ToDoItemAssigneeNotificationType>>;
  neq?: InputMaybe<ToDoItemAssigneeNotificationType>;
  nin?: InputMaybe<Array<ToDoItemAssigneeNotificationType>>;
};

/** A segment of a collection. */
export type ToDoItemAssigneesCollectionSegment = {
  __typename?: 'ToDoItemAssigneesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ToDoAssignee>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type ToDoItemContactsByItemsCollectionSegment = {
  __typename?: 'ToDoItemContactsByItemsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ToDoAssignee>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type ToDoItemContactsCollectionSegment = {
  __typename?: 'ToDoItemContactsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ToDoAssignee>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ToDoItemCreateModelInput = {
  description: Scalars['String']['input'];
  dueDate: Scalars['DateTime']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  priority: ToDoPriority;
  relatedEntityId?: InputMaybe<Scalars['Uuid']['input']>;
  relatedEntityType?: InputMaybe<ToDoRelatedEntityType>;
  status: ToDoStatus;
  toDoItemId: Scalars['Uuid']['input'];
};

export type ToDoItemFilterInput = {
  and?: InputMaybe<Array<ToDoItemFilterInput>>;
  assignees?: InputMaybe<ListFilterInputTypeOfToDoAssigneeFilterInput>;
  completedAt?: InputMaybe<DateTimeOperationFilterInput>;
  completedBy?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dueDate?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  modifiedBy?: InputMaybe<UuidOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  notifications?: InputMaybe<ListFilterInputTypeOfToDoItemAssigneeNotificationFilterInput>;
  or?: InputMaybe<Array<ToDoItemFilterInput>>;
  priority?: InputMaybe<ToDoPriorityOperationFilterInput>;
  relatedEntityId?: InputMaybe<UuidOperationFilterInput>;
  relatedEntityType?: InputMaybe<NullableOfToDoRelatedEntityTypeOperationFilterInput>;
  status?: InputMaybe<ToDoStatusOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  toDoItemId?: InputMaybe<UuidOperationFilterInput>;
};

export type ToDoItemSortInput = {
  completedAt?: InputMaybe<SortEnumType>;
  completedBy?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  dueDate?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  priority?: InputMaybe<SortEnumType>;
  relatedEntityId?: InputMaybe<SortEnumType>;
  relatedEntityType?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  toDoItemId?: InputMaybe<SortEnumType>;
};

export type ToDoItemUpdateModelInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<ToDoPriority>;
  relatedEntityId?: InputMaybe<Scalars['Uuid']['input']>;
  relatedEntityType?: InputMaybe<ToDoRelatedEntityType>;
  status?: InputMaybe<ToDoStatus>;
  toDoItemId: Scalars['Uuid']['input'];
};

export type ToDoItemUpdateStatusModelInput = {
  status?: InputMaybe<ToDoStatus>;
  toDoItemId: Scalars['Uuid']['input'];
};

/** A segment of a collection. */
export type ToDoItemsCollectionSegment = {
  __typename?: 'ToDoItemsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<ToDoItem>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ToDoItemsNotFound = Error & {
  __typename?: 'ToDoItemsNotFound';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['Uuid']['output']>;
  message: Scalars['String']['output'];
};

export type ToDoJobCrewNotFound = Error & {
  __typename?: 'ToDoJobCrewNotFound';
  count: Scalars['Int']['output'];
  ids: Array<ToDoJobCrewNotFoundData>;
  message: Scalars['String']['output'];
};

export type ToDoJobCrewNotFoundData = {
  __typename?: 'ToDoJobCrewNotFoundData';
  jobId: Scalars['Uuid']['output'];
  userId: Scalars['Uuid']['output'];
};

export const ToDoPriority = {
  High: 'HIGH',
  Low: 'LOW',
  Medium: 'MEDIUM'
} as const;

export type ToDoPriority = typeof ToDoPriority[keyof typeof ToDoPriority];
export type ToDoPriorityOperationFilterInput = {
  eq?: InputMaybe<ToDoPriority>;
  in?: InputMaybe<Array<ToDoPriority>>;
  neq?: InputMaybe<ToDoPriority>;
  nin?: InputMaybe<Array<ToDoPriority>>;
};

export const ToDoRelatedEntityType = {
  Estimate: 'ESTIMATE',
  Job: 'JOB',
  Lead: 'LEAD'
} as const;

export type ToDoRelatedEntityType = typeof ToDoRelatedEntityType[keyof typeof ToDoRelatedEntityType];
export const ToDoStatus = {
  Complete: 'COMPLETE',
  InProgress: 'IN_PROGRESS',
  NotStarted: 'NOT_STARTED'
} as const;

export type ToDoStatus = typeof ToDoStatus[keyof typeof ToDoStatus];
export type ToDoStatusOperationFilterInput = {
  eq?: InputMaybe<ToDoStatus>;
  in?: InputMaybe<Array<ToDoStatus>>;
  neq?: InputMaybe<ToDoStatus>;
  nin?: InputMaybe<Array<ToDoStatus>>;
};

export type ToDoUserNotFound = Error & {
  __typename?: 'ToDoUserNotFound';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['Uuid']['output']>;
  message: Scalars['String']['output'];
};

export type TodoAssignee = TodoJobCrew | User;

export type TodoJobCrew = {
  __typename?: 'TodoJobCrew';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  firstname: Scalars['String']['output'];
  surname: Scalars['String']['output'];
  userId: Scalars['Uuid']['output'];
};

export type TodoReference = Estimate | ExternalJob | Job | Lead;

export type ToggleEstimateTemplateAccessModelInput = {
  estimateId: Scalars['Uuid']['input'];
  integrationConnectionId: Scalars['Uuid']['input'];
};

export type TradingHours = {
  __typename?: 'TradingHours';
  publicHolidays?: Maybe<Array<PublicHoliday>>;
  standard: DailyTradingHours;
  storeClosure?: Maybe<Array<Scalars['DateTime']['output']>>;
};

export type TradingHoursFilterInput = {
  and?: InputMaybe<Array<TradingHoursFilterInput>>;
  or?: InputMaybe<Array<TradingHoursFilterInput>>;
  publicHolidays?: InputMaybe<ListFilterInputTypeOfPublicHolidayFilterInput>;
  standard?: InputMaybe<DailyTradingHoursFilterInput>;
  storeClosure?: InputMaybe<ListDateTimeOperationFilterInput>;
};

export type TradingHoursSortInput = {
  standard?: InputMaybe<DailyTradingHoursSortInput>;
};

/** A segment of a collection. */
export type UniqueTaskReferencesCollectionSegment = {
  __typename?: 'UniqueTaskReferencesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<ScheduleReference>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type UnpublishedActivityFeedError = Error & {
  __typename?: 'UnpublishedActivityFeedError';
  message: Scalars['String']['output'];
};

export type UpdateAccountingSyncSettingTypeInput = {
  defaultInvoiceStatus?: InputMaybe<Scalars['String']['input']>;
  importAccountCodesList?: InputMaybe<Array<Scalars['String']['input']>>;
  importAttachmentsWithInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  importJobCategoryTrackingCategory?: InputMaybe<Scalars['String']['input']>;
  importJobTrackingCategory?: InputMaybe<Scalars['String']['input']>;
  invoiceAccountCode?: InputMaybe<Scalars['String']['input']>;
  isImportEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isInvoicesSync: Scalars['Boolean']['input'];
  isPurchaseOrderAccountCodeSeparate: Scalars['Boolean']['input'];
  isPurchaseOrderAttachmentsSync?: InputMaybe<Scalars['Boolean']['input']>;
  isPurchaseOrdersSync: Scalars['Boolean']['input'];
  jobTrackingCategory?: InputMaybe<Scalars['String']['input']>;
  organisationName?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderAccountCode?: InputMaybe<Scalars['String']['input']>;
  purchaseOrderAccountCodeContractor?: InputMaybe<Scalars['String']['input']>;
  syncStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  taxRateExpenseFree?: InputMaybe<Scalars['String']['input']>;
  taxRateExpenseTaxable?: InputMaybe<Scalars['String']['input']>;
  taxRateIncomeFree?: InputMaybe<Scalars['String']['input']>;
  taxRateIncomeTaxable?: InputMaybe<Scalars['String']['input']>;
  taxSettings?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateActivityFeedError = ActivityFeedStatusError | EntityNotFoundError | ExceedingNoOfImagesError | InsufficientActivityFeedInfoError | ListedDateError | MaxLengthExceededError | UnpublishedActivityFeedError;

export type UpdateActivityFeedInput = {
  activityModel: ActivityUpdateModelInput;
  imageDetails: Array<ImageUpdateModelInput>;
};

export type UpdateActivityFeedPayload = {
  __typename?: 'UpdateActivityFeedPayload';
  activityFeed?: Maybe<ActivityFeed>;
  errors?: Maybe<Array<UpdateActivityFeedError>>;
};

export type UpdateAllScheduleTaskProgressError = ValidationError;

export type UpdateAllScheduleTaskProgressInput = {
  model: ScheduleTaskUpdateProgressModelInput;
};

export type UpdateAllScheduleTaskProgressPayload = {
  __typename?: 'UpdateAllScheduleTaskProgressPayload';
  errors?: Maybe<Array<UpdateAllScheduleTaskProgressError>>;
  updatedScheduleTaskProgress?: Maybe<UpdatedScheduleTaskProgress>;
};

export type UpdateBuildingTypesError = NotFoundError;

export type UpdateBuildingTypesInput = {
  models: Array<BuildingTypeUpdateModelInput>;
};

export type UpdateBuildingTypesPayload = {
  __typename?: 'UpdateBuildingTypesPayload';
  buildingTypes?: Maybe<Array<BuildingType>>;
  errors?: Maybe<Array<UpdateBuildingTypesError>>;
};

export type UpdateCatalogueCategoryModelInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  iD_CatalogueCategory: Scalars['Uuid']['input'];
  orderNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCatalogueItemModelInput = {
  availability?: InputMaybe<CatalogueItemAvailability>;
  costBreakdown?: InputMaybe<Scalars['String']['input']>;
  costItemType?: InputMaybe<Scalars['String']['input']>;
  datasheetUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  iD_CatalogueCategory?: InputMaybe<Scalars['Uuid']['input']>;
  iD_CatalogueItem: Scalars['Uuid']['input'];
  iD_CatalogueSubCategory?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Image?: InputMaybe<Scalars['Uuid']['input']>;
  imageThumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  instructionsUrl?: InputMaybe<Scalars['String']['input']>;
  itemDetailsUrl?: InputMaybe<Scalars['String']['input']>;
  manufCode?: InputMaybe<Scalars['String']['input']>;
  manufDescription?: InputMaybe<Scalars['String']['input']>;
  manufRRP?: InputMaybe<Scalars['Decimal']['input']>;
  manufRange?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  recipeItems?: InputMaybe<Array<InputMaybe<UpdateRecipeItemModelInput>>>;
  status?: InputMaybe<CatalogueItemStatus>;
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  supplierMetadata?: InputMaybe<Scalars['String']['input']>;
  tempItemCode?: InputMaybe<Scalars['String']['input']>;
  uOM?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1Conversion?: InputMaybe<Scalars['Decimal']['input']>;
  uOMAlt2?: InputMaybe<Scalars['String']['input']>;
  uOMAlt2Conversion?: InputMaybe<Scalars['Decimal']['input']>;
  uOMMinOrderQty?: InputMaybe<Scalars['Decimal']['input']>;
  uOMOrderIncrements?: InputMaybe<Scalars['Decimal']['input']>;
  unitCost?: InputMaybe<Scalars['Decimal']['input']>;
};

export type UpdateCatalogueItemPriceModelInput = {
  iD_CatalogueItemPrice: Scalars['Uuid']['input'];
  price: Scalars['Decimal']['input'];
};

export type UpdateCatalogueItemPricesModelInput = {
  iD_Catalogue: Scalars['Uuid']['input'];
  iD_CatalogueCategory?: InputMaybe<Scalars['Uuid']['input']>;
  iD_CatalogueSubCategory?: InputMaybe<Scalars['Uuid']['input']>;
  operation: CatalogueItemPriceOperationType;
  percentage: Scalars['Decimal']['input'];
};

export type UpdateCatalogueItemsCategoriesModelInput = {
  iD_Catalogue?: InputMaybe<Scalars['Uuid']['input']>;
  iD_NewCategory?: InputMaybe<Scalars['Uuid']['input']>;
  iD_OldCategory?: InputMaybe<Scalars['Uuid']['input']>;
};

export type UpdateCatalogueItemsSubCategoriesModelInput = {
  iD_Catalogue?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Category?: InputMaybe<Scalars['Uuid']['input']>;
  iD_NewSubCategory?: InputMaybe<Scalars['Uuid']['input']>;
  iD_OldSubCategory?: InputMaybe<Scalars['Uuid']['input']>;
};

export type UpdateCatalogueModelInput = {
  colour?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  iD_Catalogue: Scalars['Uuid']['input'];
  iD_Contact?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Related?: InputMaybe<Scalars['Uuid']['input']>;
  iD_Supplier?: InputMaybe<Scalars['Uuid']['input']>;
  isCategorized?: InputMaybe<Scalars['Boolean']['input']>;
  logoThumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<CatalogueScope>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CatalogueStatus>;
  syncEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  syncMapping?: InputMaybe<Scalars['String']['input']>;
  syncTimeOfDayHour?: InputMaybe<Scalars['Short']['input']>;
  syncTimeOfDayMinute?: InputMaybe<Scalars['Short']['input']>;
  syncUrl?: InputMaybe<Scalars['String']['input']>;
  syncUseMapping?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCatalogueSubCategoryModelInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  iD_CatalogueSubCategory: Scalars['Uuid']['input'];
};

export type UpdateCustomerOptionsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  cityTown?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['DateTime']['input']>;
  defaultInvoiceDays?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalRef?: InputMaybe<Scalars['String']['input']>;
  externalRef2?: InputMaybe<Scalars['String']['input']>;
  iD_Customer: Scalars['Uuid']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailSenderInput = {
  emailSenderId: Scalars['Uuid']['input'];
  options: UpdateEmailSenderOptionsInput;
};

export type UpdateEmailSenderOptionsInput = {
  isShared: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  replyToEmail?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailSenderPayload = {
  __typename?: 'UpdateEmailSenderPayload';
  emailSender?: Maybe<EmailSender>;
};

export type UpdateEstimateCustomerDetailsModelInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  cityTown?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEstimateDetailsModelInput = {
  buildingType?: InputMaybe<Scalars['String']['input']>;
  datePrepared?: InputMaybe<Scalars['DateTime']['input']>;
  defaultMarkupRedistMethod?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  franchiseFee?: InputMaybe<Scalars['Decimal']['input']>;
  isPCPSRedistDefault?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  targetProfession?: InputMaybe<Scalars['String']['input']>;
  taxRate?: InputMaybe<Scalars['Float']['input']>;
};

/** A segment of a collection. */
export type UpdateEstimateSpecificationCategoryCollectionSegment = {
  __typename?: 'UpdateEstimateSpecificationCategoryCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<EstimateSpecificationModel>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type UpdateEstimateSpecificationCategoryModelInput = {
  iD_Estimate: Scalars['Uuid']['input'];
  originalCategory?: InputMaybe<Scalars['String']['input']>;
  updatedCategory?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEstimateSpecificationCategoryNameV3Error = NotFoundError | PropertyError | ValidationError;

export type UpdateEstimateSpecificationCategoryNameV3Input = {
  updateModel: EstimateSpecificationCategoryUpdateInput;
};

export type UpdateEstimateSpecificationCategoryNameV3Payload = {
  __typename?: 'UpdateEstimateSpecificationCategoryNameV3Payload';
  errors?: Maybe<Array<UpdateEstimateSpecificationCategoryNameV3Error>>;
  estimateSpecification?: Maybe<EstimateSpecification>;
};

export type UpdateEstimateSpecificationModelInput = {
  iD_EstimateSpecification: Scalars['Uuid']['input'];
  iD_Image?: InputMaybe<Scalars['Uuid']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  specification?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIntegrationConnectionCatalogueAutoSubscribeInput = {
  catalogueAutoSubscribe: Scalars['Boolean']['input'];
  iD_IntegrationConnection: Scalars['Uuid']['input'];
};

export type UpdateIntegrationConnectionNotificationSettingInput = {
  allowSharingEstimateNotification: Scalars['Boolean']['input'];
  iD_IntegrationConnection: Scalars['Uuid']['input'];
};

export type UpdateIntegrationConnectionPermissionsInput = {
  iD_IntegrationConnection: Scalars['Uuid']['input'];
  permissionToShareData: Scalars['Boolean']['input'];
};

export type UpdateIntegrationProviderModelInput = {
  autoCcEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalRefIsPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  featuredBackgroundColour?: InputMaybe<Scalars['String']['input']>;
  featuredTextColour?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  iD_IntegrationProvider: Scalars['Uuid']['input'];
  iD_Tenant?: InputMaybe<Scalars['Uuid']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDisplayed?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  refFieldName?: InputMaybe<Scalars['String']['input']>;
  refFieldName2?: InputMaybe<Scalars['String']['input']>;
  refFieldRegEx?: InputMaybe<Scalars['String']['input']>;
  refFieldRegEx2?: InputMaybe<Scalars['String']['input']>;
  regionalRestrictions?: InputMaybe<Array<InputMaybe<RegionalRestrictionInput>>>;
  requireRef?: InputMaybe<Scalars['Boolean']['input']>;
  requireRef2?: InputMaybe<Scalars['Boolean']['input']>;
  requiresOtpVerification?: InputMaybe<Scalars['Boolean']['input']>;
  smallLogoUrl?: InputMaybe<Scalars['String']['input']>;
  specificAutoCcEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  specificAutoCcEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updateRestrictionsType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJobCustomerDetailsModelInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  cityTown?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJobPaymentSettingInput = {
  isTermsOnSeparatePage: Scalars['Boolean']['input'];
  paymentTerms?: InputMaybe<Scalars['String']['input']>;
  showCostPlusItemisation: Scalars['Boolean']['input'];
  showOrderInstructions: Scalars['Boolean']['input'];
};

export type UpdateLeadProjectNameModelInput = {
  leadId: Scalars['Uuid']['input'];
  projectName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOnboardSettingInput = {
  bool?: InputMaybe<Scalars['Boolean']['input']>;
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Float']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text2?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlanMeasurementModelInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  depth: Scalars['Float']['input'];
  depthUnit?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  iD_PlanMeasurement: Scalars['Uuid']['input'];
  isNegative: Scalars['Boolean']['input'];
  measurement: Scalars['Float']['input'];
  measurementUnit?: InputMaybe<Scalars['String']['input']>;
  pitch: Scalars['Float']['input'];
  points?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  shapeType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePurchaseOrderSettingInput = {
  isTermsOnSeparatePage: Scalars['Boolean']['input'];
  orderTerms?: InputMaybe<Scalars['String']['input']>;
  orderTermsContractor?: InputMaybe<Scalars['String']['input']>;
  orderTermsWork?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRecipeItemModelInput = {
  costCategory?: InputMaybe<Scalars['String']['input']>;
  costItemType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  iD_CatalogueItem?: InputMaybe<Scalars['Uuid']['input']>;
  iD_ParentCatalogueItem?: InputMaybe<Scalars['Uuid']['input']>;
  iD_RecipeItem: Scalars['Uuid']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  manufCode?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Decimal']['input']>;
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  supplierMetadata?: InputMaybe<Scalars['String']['input']>;
  totalCost?: InputMaybe<Scalars['Decimal']['input']>;
  uOM?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1?: InputMaybe<Scalars['String']['input']>;
  uOMAlt1Conversion?: InputMaybe<Scalars['Decimal']['input']>;
  uOMAlt2?: InputMaybe<Scalars['String']['input']>;
  uOMAlt2Conversion?: InputMaybe<Scalars['Decimal']['input']>;
  uOMMinOrderQty?: InputMaybe<Scalars['Decimal']['input']>;
  uOMOrderIncrements?: InputMaybe<Scalars['Decimal']['input']>;
  unitCost?: InputMaybe<Scalars['Decimal']['input']>;
  unitsExpression?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScheduleStartDateError = TimeZoneNotFoundError;

export type UpdateScheduleStartDateInput = {
  model: ScheduleUpdateStartDateModelInput;
  timeZoneId: Scalars['String']['input'];
};

export type UpdateScheduleStartDatePayload = {
  __typename?: 'UpdateScheduleStartDatePayload';
  errors?: Maybe<Array<UpdateScheduleStartDateError>>;
  scheduleUpdateStartDateResult?: Maybe<ScheduleUpdateStartDateResult>;
};

export type UpdateScheduleTaskError = InvalidDateRange | InvalidNesting | InvalidTimeZoneId | ParentNotFound | ReferenceIdError | ScheduleLockedError | ScheduleTaskNotFound | ValidationError;

export type UpdateScheduleTaskInput = {
  model: ScheduleTaskUpdateModelInput;
  timeZoneId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateScheduleTaskPayload = {
  __typename?: 'UpdateScheduleTaskPayload';
  errors?: Maybe<Array<UpdateScheduleTaskError>>;
  updatedScheduleTasks?: Maybe<UpdatedScheduleTasks>;
};

export type UpdateSnapshotDescriptionError = SnapshotDescriptionEmptyError;

export type UpdateSnapshotDescriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  estimateId: Scalars['Uuid']['input'];
  snapshotId: Scalars['Uuid']['input'];
};

export type UpdateSnapshotDescriptionPayload = {
  __typename?: 'UpdateSnapshotDescriptionPayload';
  errors?: Maybe<Array<UpdateSnapshotDescriptionError>>;
  snapshotSummary?: Maybe<SnapshotSummary>;
};

export type UpdateSpecificationPresetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  iD_Specification: Scalars['Uuid']['input'];
  isDeleted: Scalars['Boolean']['input'];
};

export type UpdateToDoItemStatusesError = ToDoItemsNotFound;

export type UpdateToDoItemStatusesInput = {
  models: Array<ToDoItemUpdateStatusModelInput>;
};

export type UpdateToDoItemStatusesPayload = {
  __typename?: 'UpdateToDoItemStatusesPayload';
  errors?: Maybe<Array<UpdateToDoItemStatusesError>>;
  toDoItems?: Maybe<Array<ToDoItem>>;
};

export type UpdateToDoItemsError = ToDoItemsNotFound;

export type UpdateToDoItemsInput = {
  models: Array<ToDoItemUpdateModelInput>;
};

export type UpdateToDoItemsPayload = {
  __typename?: 'UpdateToDoItemsPayload';
  errors?: Maybe<Array<UpdateToDoItemsError>>;
  toDoItems?: Maybe<Array<ToDoItem>>;
};

export type UpdateWebhookError = WebhookRegistrationInvalidEndpointError | WebhookRegistrationInvalidEventError;

export type UpdateWebhookInput = {
  updateWebhookType: UpdateWebhookTypeInput;
};

export type UpdateWebhookPayload = {
  __typename?: 'UpdateWebhookPayload';
  errors?: Maybe<Array<UpdateWebhookError>>;
  webhookRegistration?: Maybe<WebhookRegistration>;
};

export type UpdateWebhookTypeInput = {
  description: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
  events: Array<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  shouldReceiveChildTenantEvents?: InputMaybe<Scalars['Boolean']['input']>;
  webhookId: Scalars['Uuid']['input'];
};

export type UpdatedScheduleTaskProgress = {
  __typename?: 'UpdatedScheduleTaskProgress';
  all?: Maybe<AllCollectionSegment>;
};


export type UpdatedScheduleTaskProgressAllArgs = {
  order?: InputMaybe<Array<ScheduleTaskSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};

export type UpdatedScheduleTasks = {
  __typename?: 'UpdatedScheduleTasks';
  all?: Maybe<AllCollectionSegment>;
  primary: ScheduleTask;
};


export type UpdatedScheduleTasksAllArgs = {
  order?: InputMaybe<Array<ScheduleTaskSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduleTaskFilterInput>;
};

export type UploadFileToImagePlaceholderError = ActivityFeedImageError | EntityNotFoundError | ValidationError;

export type UploadFileToImagePlaceholderInput = {
  activityFeedImageId: Scalars['Uuid']['input'];
  file: Scalars['Upload']['input'];
};

export type UploadFileToImagePlaceholderPayload = {
  __typename?: 'UploadFileToImagePlaceholderPayload';
  activityFeedImage?: Maybe<ActivityFeedImage>;
  errors?: Maybe<Array<UploadFileToImagePlaceholderError>>;
};

export const UriKey = {
  BuildxactTenant: 'BUILDXACT_TENANT',
  BuildxactUser: 'BUILDXACT_USER',
  ClientProject: 'CLIENT_PROJECT',
  ClientProjectDocument: 'CLIENT_PROJECT_DOCUMENT',
  CustomerContact: 'CUSTOMER_CONTACT',
  EmailAddress: 'EMAIL_ADDRESS',
  Estimate: 'ESTIMATE',
  EstimateSnapshot: 'ESTIMATE_SNAPSHOT',
  Invoice: 'INVOICE',
  Job: 'JOB',
  Lead: 'LEAD',
  Share: 'SHARE',
  ShareItem: 'SHARE_ITEM',
  Unknown: 'UNKNOWN',
  Variation: 'VARIATION'
} as const;

export type UriKey = typeof UriKey[keyof typeof UriKey];
export type User = {
  __typename?: 'User';
  avatarImageUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  surname: Scalars['String']['output'];
  tenants: Array<Tenant>;
  tenantsCount: Scalars['Int']['output'];
  userId: Scalars['Uuid']['output'];
  userName: Scalars['String']['output'];
};

export type UserAvatarDto = {
  __typename?: 'UserAvatarDto';
  avatarUrl?: Maybe<Scalars['String']['output']>;
};

export type UserContextDto = {
  __typename?: 'UserContextDto';
  appRoles?: Maybe<Array<Scalars['String']['output']>>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  currentSubscription?: Maybe<SubscriptionInfoDto>;
  currentTenant: Tenant;
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  featureFlags?: Maybe<Scalars['String']['output']>;
  features: Array<ProductFeatureDto>;
  firstname: Scalars['String']['output'];
  isMobileVisibleToOthers: Scalars['Boolean']['output'];
  mobile: Scalars['String']['output'];
  mobileVerified: Scalars['Boolean']['output'];
  permissions: Array<PermissionDto>;
  surname: Scalars['String']['output'];
  userId: Scalars['Uuid']['output'];
  userName: Scalars['String']['output'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  firstName: Scalars['String']['output'];
  iD_User: Scalars['Uuid']['output'];
  surname: Scalars['String']['output'];
};

export type UserQuickStartAreaSetting = {
  __typename?: 'UserQuickStartAreaSetting';
  areaDescription: Scalars['String']['output'];
  areaName: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  isAreaEnabled: Scalars['Boolean']['output'];
  quickStartAreaId: Scalars['Uuid']['output'];
};

export type UserQuickStartAreaSettingUpdateInput = {
  isAreaEnabled: Scalars['Boolean']['input'];
  quickStartAreaId: Scalars['Uuid']['input'];
};

export type UserViewFilterInput = {
  and?: InputMaybe<Array<UserViewFilterInput>>;
  appRole_Legacy?: InputMaybe<StringOperationFilterInput>;
  auth0UserId?: InputMaybe<StringOperationFilterInput>;
  avatarImageUrl?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailVerified?: InputMaybe<BooleanOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  hasEmailConnectionForGwUser?: InputMaybe<BooleanOperationFilterInput>;
  iD_Tenant?: InputMaybe<UuidOperationFilterInput>;
  iD_User?: InputMaybe<UuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isMobileVisibleToOthers?: InputMaybe<BooleanOperationFilterInput>;
  mobile?: InputMaybe<StringOperationFilterInput>;
  mobileVerified?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<UserViewFilterInput>>;
  startMfaReminderAt?: InputMaybe<DateTimeOperationFilterInput>;
  surname?: InputMaybe<StringOperationFilterInput>;
  userHasSetupPassword?: InputMaybe<BooleanOperationFilterInput>;
  userRole_Legacy?: InputMaybe<StringOperationFilterInput>;
  username?: InputMaybe<StringOperationFilterInput>;
};

export type UserViewSortInput = {
  appRole_Legacy?: InputMaybe<SortEnumType>;
  auth0UserId?: InputMaybe<SortEnumType>;
  avatarImageUrl?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailVerified?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  hasEmailConnectionForGwUser?: InputMaybe<SortEnumType>;
  iD_Tenant?: InputMaybe<SortEnumType>;
  iD_User?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isMobileVisibleToOthers?: InputMaybe<SortEnumType>;
  mobile?: InputMaybe<SortEnumType>;
  mobileVerified?: InputMaybe<SortEnumType>;
  startMfaReminderAt?: InputMaybe<SortEnumType>;
  surname?: InputMaybe<SortEnumType>;
  userHasSetupPassword?: InputMaybe<SortEnumType>;
  userRole_Legacy?: InputMaybe<SortEnumType>;
  username?: InputMaybe<SortEnumType>;
};

export type UsersProfileData = {
  __typename?: 'UsersProfileData';
  hasDataToUpload: Scalars['Boolean']['output'];
  onboardingRecordId: Scalars['Uuid']['output'];
  positionInBusiness?: Maybe<Scalars['String']['output']>;
  previousSoftwareUsage?: Maybe<Scalars['String']['output']>;
  problemsToSolve?: Maybe<Scalars['String']['output']>;
  projectTypes?: Maybe<Scalars['String']['output']>;
  projectsPerMonth?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Uuid']['output'];
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['Uuid']['input']>;
  gt?: InputMaybe<Scalars['Uuid']['input']>;
  gte?: InputMaybe<Scalars['Uuid']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Uuid']['input']>>>;
  lt?: InputMaybe<Scalars['Uuid']['input']>;
  lte?: InputMaybe<Scalars['Uuid']['input']>;
  neq?: InputMaybe<Scalars['Uuid']['input']>;
  ngt?: InputMaybe<Scalars['Uuid']['input']>;
  ngte?: InputMaybe<Scalars['Uuid']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Uuid']['input']>>>;
  nlt?: InputMaybe<Scalars['Uuid']['input']>;
  nlte?: InputMaybe<Scalars['Uuid']['input']>;
};

export const ValidImageExtensions = {
  Jpeg: 'JPEG',
  Jpg: 'JPG',
  Png: 'PNG',
  Webp: 'WEBP'
} as const;

export type ValidImageExtensions = typeof ValidImageExtensions[keyof typeof ValidImageExtensions];
export type ValidationError = Error & {
  __typename?: 'ValidationError';
  message: Scalars['String']['output'];
};

export type VersionModel = {
  __typename?: 'VersionModel';
  version: Scalars['String']['output'];
};

export type WebhookEventData = {
  __typename?: 'WebhookEventData';
  eventId: Scalars['Uuid']['output'];
  eventName: Scalars['String']['output'];
  tenantId: Scalars['Uuid']['output'];
};

export type WebhookEventDefinition = {
  __typename?: 'WebhookEventDefinition';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  group: Scalars['String']['output'];
  name: Scalars['String']['output'];
  testEventData: WebhookEventData;
};


export type WebhookEventDefinitionTestEventDataArgs = {
  tenantId: Scalars['Uuid']['input'];
};

export type WebhookEventLog = {
  __typename?: 'WebhookEventLog';
  eventData: Scalars['String']['output'];
  eventDateTime: Scalars['DateTime']['output'];
  eventName: Scalars['String']['output'];
  httpResponseCode: Scalars['Int']['output'];
  httpResponseContent?: Maybe<Scalars['String']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  registeredFranchiseId?: Maybe<Scalars['Uuid']['output']>;
  registeredTenantId?: Maybe<Scalars['Uuid']['output']>;
  sendAttempt: Scalars['Int']['output'];
  tenantId: Scalars['Uuid']['output'];
  webhookEventLogId: Scalars['Uuid']['output'];
  webhookId: Scalars['Uuid']['output'];
  webhookUrl: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type WebhookEventLogConnection = {
  __typename?: 'WebhookEventLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WebhookEventLogEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<WebhookEventLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebhookEventLogEdge = {
  __typename?: 'WebhookEventLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: WebhookEventLog;
};

export type WebhookEventLogFilterInput = {
  and?: InputMaybe<Array<WebhookEventLogFilterInput>>;
  eventData?: InputMaybe<StringOperationFilterInput>;
  eventDateTime?: InputMaybe<DateTimeOperationFilterInput>;
  eventName?: InputMaybe<StringOperationFilterInput>;
  httpResponseCode?: InputMaybe<IntOperationFilterInput>;
  httpResponseContent?: InputMaybe<StringOperationFilterInput>;
  isSuccessful?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<WebhookEventLogFilterInput>>;
  registeredFranchiseId?: InputMaybe<UuidOperationFilterInput>;
  registeredTenantId?: InputMaybe<UuidOperationFilterInput>;
  sendAttempt?: InputMaybe<IntOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  webhookEventLogId?: InputMaybe<UuidOperationFilterInput>;
  webhookId?: InputMaybe<UuidOperationFilterInput>;
  webhookUrl?: InputMaybe<StringOperationFilterInput>;
};

export type WebhookEventLogSortInput = {
  eventData?: InputMaybe<SortEnumType>;
  eventDateTime?: InputMaybe<SortEnumType>;
  eventName?: InputMaybe<SortEnumType>;
  httpResponseCode?: InputMaybe<SortEnumType>;
  httpResponseContent?: InputMaybe<SortEnumType>;
  isSuccessful?: InputMaybe<SortEnumType>;
  registeredFranchiseId?: InputMaybe<SortEnumType>;
  registeredTenantId?: InputMaybe<SortEnumType>;
  sendAttempt?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  webhookEventLogId?: InputMaybe<SortEnumType>;
  webhookId?: InputMaybe<SortEnumType>;
  webhookUrl?: InputMaybe<SortEnumType>;
};

export type WebhookRegistration = {
  __typename?: 'WebhookRegistration';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Uuid']['output'];
  description: Scalars['String']['output'];
  endpoint: Scalars['String']['output'];
  events: Array<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  shouldReceiveChildTenantEvents: Scalars['Boolean']['output'];
  tenantId: Scalars['Uuid']['output'];
  webhookId: Scalars['Uuid']['output'];
};

/** A connection to a list of items. */
export type WebhookRegistrationConnection = {
  __typename?: 'WebhookRegistrationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WebhookRegistrationEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<WebhookRegistration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WebhookRegistrationEdge = {
  __typename?: 'WebhookRegistrationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: WebhookRegistration;
};

export type WebhookRegistrationInvalidEndpointError = Error & {
  __typename?: 'WebhookRegistrationInvalidEndpointError';
  message: Scalars['String']['output'];
};

export type WebhookRegistrationInvalidEventError = Error & {
  __typename?: 'WebhookRegistrationInvalidEventError';
  message: Scalars['String']['output'];
};

export type WebhookRegistrationLimitError = Error & {
  __typename?: 'WebhookRegistrationLimitError';
  message: Scalars['String']['output'];
};
