import * as math from 'mathjs';

/*
 * Pitch here can also be called degrees or the angle of the shape
 */
export const PitchFactors = {
    ratioRegex: /^[1-9][0-9]*:+[1-9][0-9]*$/g,
    minPitch: 0, // degrees
    maxPitch: 90, // degrees

    /*
     * Returns the factor for the given pitch/degrees
     */
    getPitchFactor: function (degrees: number) {
        if (degrees <= 1) return 1;
        const radians = degrees / (180 / Math.PI);
        const factor = 1 / Math.cos(radians);
        return math.round(factor, 3);
    },

    /*
     * Converts a ratio to degrees
     */
    ratioToDegrees: function (ratio: string) {
        if (!PitchFactors.isValidRatio(ratio)) return 0;
        const values = ratio.split(':');
        // @ts-ignore TS2345
        const ratioX = parseInt(values[0]);
        // @ts-ignore TS2345
        const ratioY = parseInt(values[1]);
        const radians = Math.atan(ratioX / ratioY);
        const degrees = radians * (180 / Math.PI);
        return math.round(degrees, 2);
    },

    isValidRatio: function (ratio: string) {
        return ratio && new RegExp(PitchFactors.ratioRegex).test(ratio);
    },
};
