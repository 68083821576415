<div class="plan-canvas-wrapper" id="plan-scale-v2">
    <div class="scale-action-row">
        <span class="scale-action-heading">Lets scale your plan</span>
        <!-- Already scaled, not in the process of rescaling -->
        <div class="scale-action-col" *ngIf="isPlanScaled(selectedPlan) && !rescale">
            <!-- Manual -->
            <div class="d-flex gap-2 align-items-center" *ngIf="!selectedPlan!.ScaleRatio">
                <span class="scale-action-text">This plan has been manually scaled.</span>
                <div class="form-group">
                    <button type="button" id="btn-rescale-manual" class="btn-bx-secondary-stroked btn-large" (click)="doRescale()">
                        Rescale
                    </button>
                </div>
                <div class="form-group" *ngIf="selectedPlans.length > 1">
                    <button
                        type="button"
                        id="btn-apply-manual-scale-to-selected"
                        class="btn-bx-secondary-stroked btn-large"
                        (click)="copyManualScaleOfSelectedPlan('selected')">
                        Apply to selected plans
                    </button>
                </div>
                <div class="form-group" *ngIf="selectedPlans.length === 1 && plans.length > 1">
                    <button
                        type="button"
                        id="btn-apply-manual-scale-to-all"
                        class="btn-bx-secondary-stroked btn-large"
                        (click)="copyManualScaleOfSelectedPlan('all')">
                        Apply to all plans
                    </button>
                </div>
            </div>
            <!-- Auto -->
            <div class="d-flex gap-2 set-scale" *ngIf="selectedPlan?.ScaleRatio">
                <div class="form-group">
                    <select
                        class="form-control h-100"
                        id="select-scale-ratio"
                        [(ngModel)]="scaleRatio"
                        required
                        [ngModelOptions]="{ standalone: true }">
                        <option [ngValue]="null">Select a common scale</option>
                        <option *ngFor="let r of scaleRatios" [ngValue]="r">{{ r.name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <button type="button" id="btn-rescale-auto" class="btn-bx-secondary-stroked btn-large" (click)="doRescale()">
                        Rescale
                    </button>
                </div>
                <div class="form-group" *ngIf="scaleRatio && selectedPlan?.ScaleRatio !== scaleRatio.name">
                    <button
                        type="button"
                        class="btn-bx-secondary-stroked btn-large"
                        id="btn-apply-auto-scale-current"
                        [disabled]="!scaleRatio"
                        (click)="submitScaleRatio('this')">
                        Apply to this plan
                    </button>
                </div>
                <div class="form-group" *ngIf="scaleRatio && selectedPlans.length > 1">
                    <button
                        type="button"
                        class="btn-bx-secondary-stroked btn-large"
                        id="btn-apply-auto-scale-selected"
                        [disabled]="!scaleRatio"
                        (click)="submitScaleRatio('selected')">
                        Apply to selected plans
                    </button>
                </div>

                <div class="form-group" *ngIf="scaleRatio && selectedPlans.length === 1 && plans.length > 1">
                    <button
                        type="button"
                        class="btn-bx-secondary-stroked btn-large"
                        id="btn-apply-auto-scale-all"
                        [disabled]="!scaleRatio"
                        (click)="submitScaleRatio('all')">
                        Apply to all plans
                    </button>
                </div>
            </div>
        </div>
        <!-- Not scaled or Rescale - First Step -->

        <div class="scale-action-col" *ngIf="scaleStep === 'drawHorizontal' && (!isPlanScaled(selectedPlan) || rescale)">
            <div *ngIf="!canAutoScale()">
                <span class="scale-action-text"> Step 1: Draw a HORIZONTAL line on the plan below: </span>
            </div>
            <div class="form-inline set-scale" *ngIf="canAutoScale()">
                <span class="scale-action-text" *ngIf="canAutoScale() && manualScaleDraw">
                    Step 1: Draw a HORIZONTAL line on the plan below:
                    <button
                        type="button"
                        id="btn-cancel-horizontal-length"
                        class="btn-bx-secondary-stroked btn-large"
                        (click)="undoDrawingAndCancel('drawHorizontal')">
                        Cancel
                    </button>
                </span>

                <div class="form-group scale-options" *ngIf="!manualScaleDraw">
                    <select
                        class="form-control h-100"
                        id="rescale-select-scale-ratio"
                        [(ngModel)]="scaleRatio"
                        required
                        [ngModelOptions]="{ standalone: true }">
                        <option [ngValue]="null">Select a common scale</option>
                        <option *ngFor="let r of scaleRatios" [ngValue]="r">{{ r.name }}</option>
                    </select>
                    <span class="text" *ngIf="!scaleRatio">or</span>
                    <button
                        type="button"
                        id="manually-scale"
                        class="btn-bx-secondary-stroked btn-large"
                        *ngIf="!scaleRatio"
                        (click)="manualScaleDraw = true">
                        Manually scale
                    </button>
                    <div class="form-group" *ngIf="scaleRatio && selectedPlan?.ScaleRatio !== scaleRatio.name">
                        <button
                            type="button"
                            class="btn-bx-secondary-stroked btn-large"
                            id="btn-rescale-apply-auto-scale-current"
                            [disabled]="!scaleRatio"
                            (click)="submitScaleRatio('this')">
                            Apply to this plan
                        </button>
                    </div>
                    <div class="form-group" *ngIf="scaleRatio && selectedPlans.length > 1">
                        <button
                            type="button"
                            class="btn-bx-secondary-stroked btn-large"
                            id="btn-rescale-apply-auto-scale-select"
                            [disabled]="!scaleRatio"
                            (click)="submitScaleRatio('selected')">
                            Apply to selected plans
                        </button>
                    </div>
                    <div class="form-group" *ngIf="scaleRatio && selectedPlans.length === 1 && plans.length > 1">
                        <button
                            type="button"
                            class="btn-bx-secondary-stroked btn-large"
                            id="btn-rescale-apply-auto-scale-all"
                            [disabled]="!scaleRatio"
                            (click)="submitScaleRatio('all')">
                            Apply to all plans
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Manual scale - Second Step -->
        <div class="scale-action-col" *ngIf="scaleStep === 'enterHorizontal'">
            <span class="scale-action-text">Step 2: How long is your line?</span>
            <form name="secondStepForm">
                <div class="d-flex gap-2">
                    <div class="form-group">
                        <input
                            id="input-horizontal-length"
                            type="text"
                            bxWebNumeric
                            [decimals]="2"
                            [imperial]="isImperial"
                            class="form-control"
                            [(ngModel)]="horizontalLength"
                            [ngModelOptions]="{ standalone: true }"
                            required
                            bxWebSelectAllOnFocus />
                    </div>
                    <div class="form-group">
                        <select
                            id="select-scale-unit"
                            class="form-control h-100"
                            [(ngModel)]="scaleUnit"
                            required
                            (change)="scaleUnitChanged()"
                            [ngModelOptions]="{ standalone: true }">
                            <option *ngFor="let u of supportedUnits" [value]="u">{{ u }}</option>
                        </select>
                    </div>
                    <div class="d-flex gap-2">
                        <button
                            type="button"
                            id="btn-cancel-horizontal-length"
                            class="btn-bx-secondary-stroked btn-large"
                            (click)="changeStep('drawHorizontal')">
                            Cancel
                        </button>
                        <div class="vertical-line"></div>
                        <button
                            type="button"
                            id="btn-apply-horizontal-length"
                            class="btn-bx-secondary-stroked btn-large"
                            [disabled]="!horizontalLength"
                            (click)="submitHorizontalScale()">
                            Next
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="scale-action-col" *ngIf="scaleStep === 'drawVertical'">
            <span class="scale-action-text">Step 3: Draw a VERTICAL line on the plan below:</span>
            <button
                type="button"
                id="btn-cancel-vertical-drawing"
                class="btn-bx-secondary-stroked btn-large margin-left-15"
                (click)="resetScaleStep()">
                Cancel
            </button>
        </div>
        <div class="scale-action-col" *ngIf="scaleStep === 'enterVertical'">
            <span class="scale-action-text">Step 4: How long is your line?</span>
            <form class="form-inline d-flex gap-2 scale-step-4" name="fourthStepForm">
                <div class="form-group">
                    <input
                        id="input-vertical-length"
                        type="text"
                        bxWebNumeric
                        [decimals]="2"
                        [imperial]="isImperial"
                        class="form-control"
                        [(ngModel)]="verticalLength"
                        [ngModelOptions]="{ standalone: true }"
                        required
                        bxWebSelectAllOnFocus />
                </div>
                <div class="form-group">
                    <select
                        class="form-control h-100"
                        id="select-scale-unit-vertical"
                        [(ngModel)]="scaleUnit"
                        required
                        disabled
                        [ngModelOptions]="{ standalone: true }">
                        <option *ngFor="let u of supportedUnits" [value]="u">{{ u }}</option>
                    </select>
                </div>
                <div class="d-flex gap-2">
                    <button
                        type="button"
                        id="btn-cancel-vertical-length"
                        class="btn-bx-secondary-stroked btn-large"
                        (click)="changeStep('drawVertical')">
                        Cancel
                    </button>
                    <div class="vertical-line"></div>
                    <button
                        type="button"
                        id="btn-apply-vertical-scale-all"
                        class="btn-bx-secondary-stroked btn-large"
                        [disabled]="!verticalLength"
                        (click)="submitVerticalScale('this')">
                        Apply to this plan
                    </button>
                    <button
                        id="btn-apply-vertical-scale-selected"
                        type="button"
                        class="btn-bx-secondary-stroked btn-large"
                        *ngIf="selectedPlans.length > 1"
                        [disabled]="!verticalLength"
                        (click)="submitVerticalScale('selected')">
                        Apply to selected plans
                    </button>
                    <button
                        id="btn-apply-vertical-scale-all"
                        type="button"
                        class="btn-bx-secondary-stroked btn-large"
                        *ngIf="selectedPlans.length === 1 && plans.length > 1"
                        [disabled]="!verticalLength"
                        (click)="submitVerticalScale('all')">
                        Apply to all plans
                    </button>
                </div>
            </form>
        </div>

        <button
            type="button"
            [title]="!allPlansScaled ? 'Scale all plans to proceed.' : ''"
            [disabled]="!allPlansScaled"
            id="btn-start-measuring"
            class="btn-bx-primary btn-large"
            (click)="startMeasuring()">
            Start Measuring
        </button>
    </div>
    <div class="canvas-container">
        <div class="plans-column">
            <ng-scrollbar [visibility]="'hover'">
                <div class="plans-container">
                    <ul id="plans-list">
                        <li *ngFor="let plan of plans; let i = index" (click)="setSelectedPlan(plan, $event)" id="plan-{{ i }}">
                            <div id="plan-preview-{{ i }}" class="plan-container" [ngClass]="{ selected: isPlanSelected(plan) }">
                                <div class="plan-label">Page {{ i + 1 }}</div>
                                <div class="plan-scale">
                                    <span
                                        class="state label label-inner"
                                        [ngClass]="{ 'label-warning': !isPlanScaled(plan), 'label-success': isPlanScaled(plan) }">
                                        <span *ngIf="isPlanScaled(plan)">{{ !plan.ScaleRatio ? 'Manual' : plan.ScaleRatio }}</span>
                                        {{ isPlanScaled(plan) ? 'Scale' : 'Not Scaled' }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </ng-scrollbar>
        </div>
        <div class="canvas-column">
            <div class="verify-scale" *ngIf="scaleRatio && showTestScaleMessage">
                <span class="scale-action-text">{{ testScaleMsg }}</span>
            </div>
            <bx-web-take-off-canvas
                #takeOffCanvas
                [imageUrl]="selectedPlan?.PlanUrl || selectedPlan?.fileUrl"
                [drawingMode]="takeOffConfig.drawingMode"
                [shape]="takeOffConfig.shape"
                [unit]="takeOffConfig.unit"
                [horizontalScale]="takeOffConfig.horizontalScale"
                [verticalScale]="takeOffConfig.verticalScale"
                [showLabels]="takeOffConfig.showLabels"
                [mouseScaleStep]="takeOffConfig.mouseScaleStep"
                (drawingFinish)="drawingFinish($event)"
                (drawingStart)="drawingStart($event)"
                [showZoomSlider]="true"
                [showZoomScaleSlider]="true">
            </bx-web-take-off-canvas>
        </div>
    </div>
</div>
