<div #canvasWrapper class="base-canvas-wrapper">
    <div *ngIf="showZoomSlider" class="take-off-slider take-off-blue-slider take-off-slider-top-left">
        <mat-slider min="0.25" max="2.5" step="0.001" #ngSlider class="vertical"
            ><input
                title="Zoom in/out"
                matSliderThumb
                [value]="zoomSliderValue"
                (input)="onZoomSliderChange({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value })"
                #ngSliderThumb="matSliderThumb"
        /></mat-slider>
    </div>
    <div
        *ngIf="showZoomScaleSlider"
        class="take-off-slider take-off-grey-slider take-off-slider-bottom-left"
        tooltip="Zoom speed"
        tooltip-append-to-body="true">
        <mat-slider min="0.005" max="0.04" step="0.001" #ngSlider
            ><input
                title="Adjust mouse speed"
                matSliderThumb
                [value]="mouseScaleStep"
                (input)="onZoomScaleSliderChange({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value })"
                #ngSliderThumb="matSliderThumb"
        /></mat-slider>
    </div>
    <ng-content> </ng-content>
</div>
