import { DepthUnit, DrawingUnit, ScaleRatio, ScaleUnitName, Unit, UnitConversionFactors } from './constants';

export function IsImperial(unit: string): boolean {
    if (!unit) return false;
    const lu = unit.toLowerCase();
    return lu === ScaleUnitName.ft || GetImperial().includes(lu);
}

export function IsYard(unit: string): boolean {
    if (!unit) return false;
    const lu = unit.toLowerCase();
    return GetYards().includes(lu);
}

export function IsSquareOrCubic(unit: string): boolean {
    if (!unit) return false;
    const lu = unit.toLowerCase();
    return IsSquare(lu) || IsCubic(lu);
}

export function IsLineal(unit: string): boolean {
    if (!unit) return false;
    const lu = unit.toLowerCase();
    return lu === DrawingUnit.lm || lu === DrawingUnit.lf || lu === DrawingUnit.ly;
}

export function IsSquare(unit: string): boolean {
    if (!unit) return false;
    const lu = unit.toLowerCase();
    return lu === DrawingUnit.m2 || lu === DrawingUnit.sf || lu === DrawingUnit.sy;
}

export function GetLinealForSquare(unit: string): string {
    if (!unit) return DrawingUnit.lm;
    const lu = unit.toLowerCase();
    switch (lu) {
        case DrawingUnit.sf:
            return DrawingUnit.lf;
        case DrawingUnit.sy:
            return DrawingUnit.ly;
        default:
            return DrawingUnit.lm;
    }
}

export function IsCubic(unit: string): boolean {
    if (!unit) return false;
    const lu = unit.toLowerCase();
    return lu === DrawingUnit.m3 || lu === DrawingUnit.cf || lu === DrawingUnit.cy;
}

export function GetImperial(): string[] {
    return [DrawingUnit.cf, DrawingUnit.sf, DrawingUnit.lf];
}

export function GetMetric(): string[] {
    return [DrawingUnit.m3, DrawingUnit.m2, DrawingUnit.lm];
}

export function GetDefaultDepthUnit(unit: string) {
    return IsImperial(unit) || IsYard(unit) ? DepthUnit.ft : DepthUnit.mm;
}

export function IsDepthUnitImperial(unit: string) {
    return unit && unit.toLowerCase() === DepthUnit.ft;
}

/**
 * Allows for proper conversion of scale unit factors
 * For example, a drawing might be in sy (square yards)
 * with a dept of 3ft. 'ft' scale unit has a factor of 1 because
 * all imperial measurements are in feet. However, if the drawing
 * is in yards, then a depth factor of 1 is not correct.
 * This correctly returns the right depth factor depending on the unit
 * of the drawing.
 */
// export function GetDepthUnitFactor(scaleUnit: 'mm' | 'cm' | 'm' | 'ft', drawingUnit: string) {
//     let scaleFactor = ScaleUnit[scaleUnit];
//     const baseUnitForScale = GetBaseUnit(scaleUnit);
//     const baseUnitForDrawing = GetBaseUnit(drawingUnit);
//     return ConvertBetweenUnits(scaleFactor, baseUnitForScale, baseUnitForDrawing);
// }

/**
 * Yards are separate from GetImperial for a reason:
 * While a yard is an imperial mesurement, it is displayed
 * in the app as a standard decimal measurement.
 * Because IsImperial determines the display of ft/in fractions
 * such as 4'6" - we don't display yards this way, hence the
 * separation here.
 */
export function GetYards(): string[] {
    return [DrawingUnit.cy, DrawingUnit.sy, DrawingUnit.ly];
}

/**
 * Given a recognisable unit name, returns the base unit of measurement
 * This allows conversions to be done between base units, such as converting
 * metres to feet, feet to yards etc.
 */
export function GetBaseUnit(unit: string): Unit {
    switch (unit) {
        case DrawingUnit.lm:
        case DrawingUnit.m2:
        case DrawingUnit.m3:
        case ScaleUnitName.m:
        case ScaleUnitName.mm:
        case ScaleUnitName.cm:
            return 'm';
        case DrawingUnit.lf:
        case DrawingUnit.sf:
        case DrawingUnit.cf:
        case ScaleUnitName.ft:
            return 'ft';
        case DrawingUnit.sy:
        case DrawingUnit.cy:
        case DrawingUnit.ly:
            return 'yd';
        default:
            return 'ea';
    }
}

/**
 * Converts between units. For example, between metres and feet,
 * or feet and yards.
 */
export function ConvertBetweenUnits(value: number, from: Unit, to: Unit) {
    if (from === to || !value) return value;
    const cf = UnitConversionFactors.find((f) => f.from === from && f.to === to);
    return value * (cf?.factor || 1);
}

export function CalculateScaleForPdfPlan(points: number, dpi: number, ratio: ScaleRatio): { unit: string; value: number } | null {
    if (!points || !dpi) return null;
    const inches = points / 72;
    const mm = inches * 25.4;
    const ft = inches / 12;
    const resolution = Math.ceil(inches * dpi);
    const mmPerPixel = mm / resolution;
    const ftPerPixel = ft / resolution;
    const ratioMultiplier = parseInt(ratio.value.split(':')[1] as string);
    if (ratio.imperial) {
        const scaleValue = ftPerPixel * ratioMultiplier;
        return { unit: ScaleUnitName.ft, value: scaleValue };
    } else {
        const scaleValue = mmPerPixel * ratioMultiplier;
        return { unit: ScaleUnitName.mm, value: scaleValue };
    }
}
