import { Component, HostListener, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorBannerComponent } from './error/error-banner/error-banner.component';
import { lastUrlListener } from './route-guards/activate-route-guard';
import { PicInPicComponent } from '@bx-web/pic-in-pic';

@Component({
    standalone: true,
    imports: [RouterModule, CommonModule, ErrorBannerComponent, PicInPicComponent],
    selector: 'bx-erb-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    #router = inject(Router);

    constructor() {
        lastUrlListener(this.#router);
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: BeforeUnloadEvent) {
        const forbiddenUrls = ['/import', '/error'];
        if (!forbiddenUrls.some((url) => this.#router.url.includes(url))) {
            $event.preventDefault();
        }
    }
}
