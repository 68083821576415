import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionService, missingOriginatorError } from '../session.service';
import { AppStoreService } from '../app.store';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { catchError, first } from 'rxjs';
import { Uuid } from '@bx-web/graphql';
import { LoadingComponent } from '@bx-web/loading';
import { ErrorService } from '../error/error.service';

@Component({
    selector: 'bx-erb-home',
    standalone: true,
    imports: [CommonModule, LoadingComponent, RouterModule],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
    #sessionService = inject(SessionService);
    #appStore = inject(AppStoreService);
    #router = inject(Router);
    #route = inject(ActivatedRoute);
    #errorService = inject(ErrorService);

    $appSession = this.#appStore.currentSession.obs$;
    loading = true;

    ngOnInit() {
        this.#sessionService.loading$.subscribe((loading) => {
            if (!loading) this.loadingComplete();
        });
        const existingSessionId = this.#route.snapshot.queryParams['sessionId'];
        const requestOriginator = this.#route.snapshot.queryParams['origin'] ?? '';
        if (existingSessionId) {
            this.#openSession(existingSessionId);
        } else {
            if (!requestOriginator) {
                this.#errorService.currentErrorBannerMsg.set(missingOriginatorError);
                this.#sessionService.loading$.next(false);
                return;
            }
            this.#createNewSession(requestOriginator);
        }
    }

    #openSession(existingSessionId: Uuid) {
        this.#sessionService
            .openTakeOffSession(existingSessionId)
            .pipe(
                first(),
                catchError((error) => {
                    const errorMsg = 'Failed to open session! Please try again';
                    this.#errorService.currentErrorBannerMsg.set(errorMsg);
                    this.#sessionService.loading$.next(false);
                    throw error;
                })
            )
            .subscribe(({ errors }) => {
                if (errors?.length) {
                    const errorMsg = errors[0]?.message ?? 'Failed to open session';
                    this.#errorService.currentErrorBannerMsg.set(errorMsg);
                    this.#sessionService.loading$.next(false);
                }
            });
    }

    #createNewSession(requestOriginator: string) {
        this.#sessionService
            .createTakeOffSession(requestOriginator)
            .pipe(
                first(),
                catchError((error) => {
                    const errorMsg = 'Failed to create a new session! Please try again';
                    this.#errorService.currentErrorBannerMsg.set(errorMsg);
                    this.#sessionService.loading$.next(false);
                    throw error;
                })
            )
            .subscribe(({ errors }) => {
                if (errors?.length) {
                    const errorMsg = errors[0]?.message ?? 'Failed to create a new session.';
                    this.#errorService.currentErrorBannerMsg.set(errorMsg);
                    this.#sessionService.loading$.next(false);
                }
            });
    }

    loadingComplete() {
        this.loading = false;
        const hasError = !!this.#errorService.currentErrorBannerMsg.value();
        if (hasError) {
            this.#router.navigate(['error']);
            return;
        }

        const currentSession = this.#appStore.currentSession.value();
        const fromUrl = this.#route.snapshot.queryParams['from'] ?? 'import';
        this.#router.navigate([fromUrl], {
            queryParams: { origin: currentSession?.originator?.originator, sessionId: currentSession?.sessionId },
        });
    }
}
