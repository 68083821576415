import * as Types from '@bx-web/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdatePlanMeasurementsVariables = Types.Exact<{
  model?: Types.InputMaybe<Array<Types.UpdatePlanMeasurementModelInput> | Types.UpdatePlanMeasurementModelInput>;
}>;


export type UpdatePlanMeasurementsResult = { updatePlanMeasurements?: Types.Maybe<Array<Types.Maybe<Pick<Types.PlanMeasurement, 'iD_PlanMeasurement' | 'iD_Measurement' | 'description' | 'measurement' | 'shapeType' | 'position' | 'points' | 'color' | 'measurementUnit' | 'pitch' | 'depth' | 'depthUnit' | 'isNegative' | 'isDeleted'>>>> };


export const UpdatePlanMeasurements = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePlanMeasurements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"model"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePlanMeasurementModelInput"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePlanMeasurements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"model"},"value":{"kind":"Variable","name":{"kind":"Name","value":"model"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"iD_PlanMeasurement"}},{"kind":"Field","name":{"kind":"Name","value":"iD_Measurement"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"measurement"}},{"kind":"Field","name":{"kind":"Name","value":"shapeType"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"points"}},{"kind":"Field","name":{"kind":"Name","value":"color"}},{"kind":"Field","name":{"kind":"Name","value":"measurementUnit"}},{"kind":"Field","name":{"kind":"Name","value":"pitch"}},{"kind":"Field","name":{"kind":"Name","value":"depth"}},{"kind":"Field","name":{"kind":"Name","value":"depthUnit"}},{"kind":"Field","name":{"kind":"Name","value":"isNegative"}},{"kind":"Field","name":{"kind":"Name","value":"isDeleted"}}]}}]}}]} as unknown as DocumentNode<UpdatePlanMeasurementsResult, UpdatePlanMeasurementsVariables>;