import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[bxWebSelectAllOnFocus]',
    providers: [NgModel],
    standalone: true,
})
export class SelectAllOnFocusDirective {
    @Input() appSelectAllOnFocusFilter: string | null = null;
    @Input() selectionTimeout = 100;

    get ctrl() {
        return this.ngControl.control;
    }

    constructor(private ngControl: NgModel, private el: ElementRef) {}

    @HostListener('focus')
    onFocus() {
        if (this.appSelectAllOnFocusFilter !== null) {
            if (this.ctrl.value && this.ctrl.value === this.appSelectAllOnFocusFilter) {
                this.selectAll();
            }
        } else {
            this.selectAll();
        }
    }

    selectAll() {
        setTimeout(() => {
            const elem = <HTMLInputElement>this.el.nativeElement;
            // Only select if this is the focused element
            if (document.activeElement && document.activeElement === this.el.nativeElement) elem.select();
        }, this.selectionTimeout);
    }
}
