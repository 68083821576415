import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { combineLatest } from 'rxjs';
import { PlanConvertJobComponent } from './ui-estimate-plan-convert-job/plan-convert-job.component';
import { PlanFileDropperComponent } from './ui-plan-file-dropper/plan-file-dropper.component';
import {
    EstimatePlanConvertJob,
    EstimatePlanConvertJobFactory,
    EstimatePlanConvertJobStatus,
} from './domain/apis/estimate-plan-convert-job.factory';

@UntilDestroy()
@Component({
    selector: 'bx-web-plan-import',
    templateUrl: './plan-import.component.html',
    styleUrls: ['./plan-import.component.scss'],
    imports: [CommonModule, FormsModule, ProgressbarModule, PlanConvertJobComponent, PlanFileDropperComponent],
    standalone: true,
})
export class PlanImportComponent implements OnDestroy {
    @Output() importComplete = new EventEmitter<boolean>();
    @Output() newSession = new EventEmitter();
    @Input() estimateId = '';
    @Input() pageLimit = 0;
    public jobs: EstimatePlanConvertJob[] = [];
    public isImporting = false;
    public importFailed = false;
    public error = '';
    hideUploadBtn = false;

    constructor(private readonly estimatePlanConvertJobFactory: EstimatePlanConvertJobFactory) {}

    ngOnDestroy(): void {
        this.jobs.forEach((j) => j.dispose());
    }

    public onFilesSelected(selectedFiles: File[]) {
        this.error = '';
        if (selectedFiles.length + this.jobs.length > 1) this.error = 'Max 1 file allowed for import';
        selectedFiles.slice(0, 1 - this.jobs.length).forEach((file) => {
            try {
                const job = this.estimatePlanConvertJobFactory.createJob(file, this.estimateId, this.pageLimit);
                this.jobs.push(job);
                job.validate();
            } catch (e: unknown) {
                this.error = e instanceof Error ? e.message : 'An error occurred.';
                throw this.error;
            }
        });
    }

    private checkFileImportLimit() {
        this.error = this.jobs.length >= 2 ? 'Max 1 file allowed for import' : '';
    }

    public onDeleted(job: EstimatePlanConvertJob) {
        const index = this.jobs.indexOf(job);
        this.jobs.splice(index, 1);
        this.checkFileImportLimit();
        this.hideUploadBtn = false;
    }

    public allJobsValid() {
        return this.jobs?.length && !this.jobs.some((j) => !j.isValid);
    }

    public import() {
        this.error = '';
        this.isImporting = true;
        // Only import pending jobs
        const jobsToImport = this.jobs.filter(
            (j) =>
                j.getCurrentStatus().status === EstimatePlanConvertJobStatus.Pending ||
                j.getCurrentStatus().status === EstimatePlanConvertJobStatus.Error
        );
        const observables = jobsToImport.map((j) => j.status);
        combineLatest(observables).subscribe((events) => {
            const allCompleted = !events.some((e) => e.status !== EstimatePlanConvertJobStatus.Completed);
            const someInProgress = events.some(
                (e) => e.status === EstimatePlanConvertJobStatus.InProgress || e.status === EstimatePlanConvertJobStatus.Pending
            );
            if (allCompleted) {
                this.jobs.forEach((j) => j.dispose());
                this.importComplete.emit(true);
            }
            const someErrors = events.some((e) => e.status === EstimatePlanConvertJobStatus.Error);
            // Do something with error'd jobs
            if (!someInProgress) {
                this.isImporting = false;
                this.importFailed = someErrors;
                if (events.find((e) => e.error?.includes('You can only upload one file per session'))) this.hideUploadBtn = true;
            }
        });
        // Begin all jobs
        jobsToImport.forEach((j) => j.start());
    }
}
