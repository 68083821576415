import * as Types from '@bx-web/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeletePlanMeasurementsVariables = Types.Exact<{
  planMeasurementIds?: Types.InputMaybe<Array<Types.Scalars['Uuid']['input']> | Types.Scalars['Uuid']['input']>;
}>;


export type DeletePlanMeasurementsResult = Pick<Types.Mutation, 'deletePlanMeasurements'>;


export const DeletePlanMeasurements = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePlanMeasurements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"planMeasurementIds"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Uuid"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePlanMeasurements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"planMeasurementIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"planMeasurementIds"}}}]}]}}]} as unknown as DocumentNode<DeletePlanMeasurementsResult, DeletePlanMeasurementsVariables>;