import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { EstimateMeasurementVM } from './entities/estimate-measurement.model';
import { EstimateMeasurementDrawingStatus } from './entities/estimate-measurement-drawing-status.model';

@Injectable({
    providedIn: 'root',
})
export class EstimateMeasurementStoresService {
    public drawingUpdated$ = new Subject<boolean>();
    public saveMeasurement$ = new Subject<boolean>();

    // #region TODO:: remove when lg flag 'measurement-uom-setting' is deleted
    public readonly supportedUnits$: Observable<TakeOffSupportedUnit[]>;
    // @ts-ignore TS2345
    private supportedUnitsSource = new BehaviorSubject<TakeOffSupportedUnit[]>(null);
    public set supportedUnits(supportedUnits: TakeOffSupportedUnit[]) {
        this.supportedUnitsSource.next(supportedUnits);
    }
    // #endregion

    //#region Category
    public readonly measurementCategories$: Observable<EstimateMeasurementVM[]>;
    // @ts-ignore TS2345
    private measurementCategoriesSource = new BehaviorSubject<EstimateMeasurementVM[]>(null);

    public set measurementCategories(measurementCategories: EstimateMeasurementVM[]) {
        this.measurementCategoriesSource.next(measurementCategories);
        this.refreshMeasurementsDrawingsStatus();
    }

    public get snapshotMeasurementCategories(): EstimateMeasurementVM[] {
        return this.measurementCategoriesSource.value || [];
    }

    //#endregion Category

    //#region Measurement
    private readonly MIN_SLOW_MODE_NUM = 200;
    private totalMeasurementsNum = 0;
    public get isSlowMode(): boolean {
        return this.totalMeasurementsNum >= this.MIN_SLOW_MODE_NUM;
    }
    public set totalMeasurements(total: number) {
        this.totalMeasurementsNum = total;
    }

    public readonly selectedMeasurement$: Observable<EstimateMeasurementVM>;
    // @ts-ignore TS2345
    private selectedMeasurementSource = new BehaviorSubject<EstimateMeasurementVM>(undefined);
    public set selectedMeasurement(selectedMeasurement: EstimateMeasurementVM) {
        this.selectedMeasurementSource.next(selectedMeasurement);
        this.refreshMeasurementsDrawingsStatus();
    }
    public get snapshotSelectedMeasurement(): EstimateMeasurementVM {
        // @ts-ignore TS2322
        return this.selectedMeasurementSource.value ? { ...this.selectedMeasurementSource.value } : null;
    }

    public get snapshotEstimateMeasurementsWithDrawings(): EstimateMeasurementVM[] {
        return this.snapshotAllEstimateMeasurements.filter((m) => !!m.id_PlanMeasurement);
    }
    public get snapshotAllEstimateMeasurements(): EstimateMeasurementVM[] {
        return this.getAllEstimateMeasurements(this.snapshotMeasurementCategories);
    }

    public readonly measurementsDrawingStatus$: Observable<EstimateMeasurementDrawingStatus>;
    // @ts-ignore TS2345
    private measurementsDrawingStatusSource = new BehaviorSubject<EstimateMeasurementDrawingStatus>(null);

    public readonly deletedPlanMeasurementIds$: Observable<string[]>;
    // @ts-ignore TS2345
    private deletedPlanMeasurementIdsSource = new BehaviorSubject<string[]>(null);
    public set deletedPlanMeasurementIds(deletedPlanMeasurementIds: string[]) {
        this.deletedPlanMeasurementIdsSource.next(deletedPlanMeasurementIds);
    }
    //#endregion Measurement

    // @ts-ignore TS2322
    private id_estimate: string = null;
    public get estimateId(): string {
        return this.id_estimate;
    }
    public set estimateId(estimateId: string) {
        this.id_estimate = estimateId;
    }

    constructor() {
        this.supportedUnits$ = this.supportedUnitsSource.pipe(
            filter((supportedUnits) => !!supportedUnits),
            distinctUntilChanged()
        );

        this.measurementCategories$ = this.measurementCategoriesSource.pipe(
            filter((measurementCategories) => !!measurementCategories),
            map((measurementCategories) => measurementCategories.filter((measurementCategory) => !measurementCategory.isDeleted)),
            distinctUntilChanged()
        );

        this.selectedMeasurement$ = this.selectedMeasurementSource.pipe(
            filter((selectedMeasurement) => selectedMeasurement !== undefined),
            distinctUntilChanged()
        );

        this.measurementsDrawingStatus$ = this.measurementsDrawingStatusSource.pipe(
            filter((status) => !!status),
            distinctUntilChanged()
        );

        this.deletedPlanMeasurementIds$ = this.deletedPlanMeasurementIdsSource.pipe(
            filter((deletedPlanMeasurementIds) => !!deletedPlanMeasurementIds),
            distinctUntilChanged()
        );
    }

    //#region Category
    public getMeasurementCategoryById(categoryId: string): EstimateMeasurementVM {
        // @ts-ignore TS2322
        return categoryId ? this.snapshotMeasurementCategories.find((c) => c.id === categoryId) : null;
    }

    public updateMeasurementCategory(changed: EstimateMeasurementVM) {
        const categories = this.snapshotMeasurementCategories.map((category) => {
            const isFound = category.originalMeasurement ? category.id === changed.id : category.order === changed.order;

            return isFound ? changed : category;
        });
        this.measurementCategoriesSource.next(categories);
    }
    //#endregion Category

    //#region Measurement
    public addTotalMeasurements() {
        this.totalMeasurementsNum++;
    }

    public removeTotalMeasurements(removed = 1) {
        this.totalMeasurementsNum -= removed;
    }

    private getAllEstimateMeasurements(categories: EstimateMeasurementVM[]): EstimateMeasurementVM[] {
        let measurements: EstimateMeasurementVM[] = [];

        categories.forEach((category: EstimateMeasurementVM) => {
            measurements = [...measurements, ...(category.measurements || [category])];
        });

        return measurements;
    }

    public getEstimateMeasurementByOrder(categoryOrder: number, measurementOrder: number): EstimateMeasurementVM {
        if (!categoryOrder) {
            // @ts-ignore TS2322
            return this.snapshotMeasurementCategories.find((c) => c.order === measurementOrder);
        }

        const category = this.snapshotMeasurementCategories.find((c) => c.order === categoryOrder);
        if (!category) {
            // @ts-ignore TS2322
            return null;
        }

        // @ts-ignore TS2322
        return category.measurements.find((m) => m.order === measurementOrder);
    }

    private refreshMeasurementsDrawingsStatus() {
        const measurementsWithDrawings = this.snapshotEstimateMeasurementsWithDrawings;

        this.measurementsDrawingStatusSource.next({
            hasDrawing: measurementsWithDrawings.length > 0,
            hasShownDrawing: measurementsWithDrawings.some((m) => !!m.isMeasurementShown),
        });
    }
    //#endregion Measurement
}

export type TakeOffSupportedUnit = any;
